import React from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { compose, setDisplayName, withHandlers, withProps } from 'recompose';
import { flowRight } from 'lodash';
import { NItems as MFTBaseNItems, WithRegistry } from 'markformythree';
import { createNItems as createNItemsCommon } from '~/client-common/components/forms/NItems';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import { Button as ArcButton } from '../../components/Button';
import Row from '../../components/Row';
import Button, { buttonTypes } from '../../../../legacy-redux/components/core/Button';
import { iconTypes } from '../../components/Icon';

import { nItemFormDataIsEmpty, nItemIsEmpty } from '../../../../legacy-redux/helpers/formHelpers';
import reactReduxFormHelpers from '../../../../legacy-redux/helpers/reactReduxFormHelpers';
import testIds from '../../../../core/testIds';
import { getFieldName, fieldNameToTestId, arbiterInput } from '../../arbiter';
import Fieldset from './Fieldset';

const { connectRRFNItems, connectRRFNItem } = reactReduxFormHelpers;

const styles = {
    wrapper: 'mark43-n-items',
    fieldset: 'mark43-n-items-row-fieldset',
};

class _NItemsRow extends React.PureComponent {
    static childContextTypes = {
        nItemIndex: PropTypes.number,
    };

    getChildContext() {
        return {
            nItemIndex: this.props.index,
        };
    }

    setFirstInputRef = (firstInputRef) => (this.firstInputRef = firstInputRef);

    componentDidMount() {
        if (this.props.focusFirstInputOnMount && this.firstInputRef) {
            this.firstInputRef.focus();
        }
    }

    render() {
        return (
            <Row
                className={classNames(this.props.className, {
                    'no-label-margin':
                        this.props.index !== 0 && this.props.showLabelForFirstItemOnly,
                })}
                testId={this.props.testId}
            >
                {this.props.children(this.setFirstInputRef)}
            </Row>
        );
    }
}

const NItemsRow = styled(_NItemsRow)`
    position: relative;
    ${(props) => (props.showLabelForFirstItemOnly ? 'display: flex; align-items: center;' : '')};

    & + & {
        margin-top: ${(props) => props.useRowSpacing && '12px'};
    }
`;

const renderAddButton = (addText, addItem, maxItems, index) => (
    <Row className="mark43-n-items-add-row">
        {maxItems !== index + 1 ? (
            <Button
                className={buttonTypes.ICON_LINK}
                iconLeft={iconTypes.ADD}
                onClick={addItem}
                testId={testIds.NITEMS_ADD_ITEM}
            >
                {addText}
            </Button>
        ) : null}
    </Row>
);

function renderDeleteButton(removeItem, index, removeText) {
    return (
        <Button
            testId={testIds.NITEMS_REMOVE_ITEM}
            className={classNames(buttonTypes.FORM_ROW_ICON_LINK, buttonTypes.DELETE_ICON_LINK)}
            iconLeft={iconTypes.TRASH_CAN}
            onClick={removeItem(index)}
        >
            {removeText}
        </Button>
    );
}

/**
 * Return an NItems component based on a component for each item.
 * @param  {function} nItemIsEmpty Helper function to use in the returned
 *   component.
 * @return {function} `Component => Component`.
 */
export const createNItems = (nItemIsEmpty) =>
    compose(
        setDisplayName('NItems'),
        withProps(({ addItem, removeItem, addText, removeText = '', maxItems, items }) => ({
            renderDeleteButton: (index) => renderDeleteButton(removeItem, index, removeText),
            Fieldset,
            addButton: renderAddButton(addText, addItem, maxItems, items.length - 1),
            styles,
        })),
        createNItemsCommon(nItemIsEmpty)
    );

/**
 * NItems for redux-form. Use this component for a form field that is an array
 *   of unlimited items. One item is always shown even if the array has length
 *   0. By default, every item gets a delete button, except when there is only
 *   one item.
 * @param {Object}   props            Other props get spread into the parent
 *   div.
 * @param {Object[]} props.items      Must be a redux-form array.
 * @param {Object}   [props.defaultItemValue] An object to be passed to `addField`
 *   In our version of redux-form the values won't actually be assigned to the
 *   item's field values. Instead these have to be picked up within the forms
 *   reducer.
 * @param {string}   [props.addText]  Label for the optional button to add a new
 *   item. The add button is not needed when `addItemOnDirty` is `true`.
 * @param {string}   [props.removeText]  Label for the delete button to remove an
 *   item. The remove button has only an icon by default.
 * @param {boolean}  [props.addItemOnEmpty=true] Whether to automatically add an
 *   item when there are no items (usually on first render).
 * @param {boolean}  [props.addItemOnDirty=false] Whether to automatically add a
 *   new item when all the items are dirty and non-empty. The add button is
 *   shown only when all items are non-empty (after the last empty item gets
 *   removed).
 * @param {boolean}  [props.hideAddButtonOnEmptyItem=false] Whether to hide the
 *   add button when at least one item is empty. Do not use in conjunction with
 *   `addItemOnDirty`!
 * @param {integer}  [props.minimumNumberOfItems=1] How many default number of
 *   items should be filled before the user is allowed to add more items
 * @param {boolean}  [props.disabled=false] Whether the NItems form elements
 *   (add button, delete button) should be disabled/hidden. The form elements in
 *   the children don't get disabled with this prop.
 * @param {boolean}  [automaticallyIncludeDeleteButton=true] See `children`.
 * @param {function} props.children   Function that returns an element to render
 *   for each item, with arguments `(item, [index], [deleteButton], [removeItem])`. If you
 *   include the `deleteButton` argument, you decide exactly where to render the
 *   delete button. If you don't include it, a delete button gets automatically
 *   rendered after the children, inside a `Row`, unless you set
 *   `automaticallyIncludeDeleteButton` to be `false`.
 *   @param {number} props.maxItems Sets the maximum number of items that may be added.
 */
export default compose(
    withHandlers({
        addItem: ({ items, defaultItemValue }) => () => items.addField(defaultItemValue),
        removeItem: ({ items }) => (index) => () => items.removeField(index),
    }),
    createNItems(nItemIsEmpty)
)(Row);

/**
 * NItems for react-redux-form.
 * @param {Object}   props       Same props as `NItems`.
 * @param {Object[]} props.items Field values.
 */
export const RRFNItems = compose(
    connectRRFNItems,
    createNItems(nItemFormDataIsEmpty),
    connectRRFNItem
)(Row);

/**
 * @template T
 * @extends React.Component<Omit<MFTBaseNItems<T>['props'], 'addText'> & { defaultItemValue?: T | ({ form }: { form: _Form }) => T; showRemoveButtonOnFirstItems?: boolean, showLabelForFirstItemOnly?: boolean; disabled?: boolean; addText?: string; className?: string }>
 */
export class MFTNItems extends React.Component {
    static childContextTypes = {
        showLabelForFirstItemOnly: PropTypes.bool,
    };

    constructor(props) {
        super(props);
        this.newIndex = -1;
    }

    focusAddButton = () => {
        if (this.addButton) {
            if (this.addButton.button) {
                // Remove this when ARC_RELEASE_CYCLE_THREE_COMPONENTS is globally enabled
                this.addButton.button.focus();
            } else {
                this.addButton.focus();
            }
        }
    };

    setNewIndex = (index) => (this.newIndex = index);

    getChildContext() {
        return {
            showLabelForFirstItemOnly: this.props.showLabelForFirstItemOnly || false,
        };
    }

    render() {
        const props = this.props;
        return (
            <MFTBaseNItems
                addText="Add"
                renderRowContainer={({ itemElement, removeButtonElement, index, item }) => (
                    <NItemsRow
                        key={index}
                        index={index}
                        showLabelForFirstItemOnly={this.props.showLabelForFirstItemOnly}
                        item={item}
                        testId={props.rowTestId}
                        useRowSpacing={props.useRowSpacing}
                        focusFirstInputOnMount={this.newIndex === index}
                    >
                        {(setFirstInputRef) => (
                            <>
                                {props.autoFocusFirstInputOnAdd
                                    ? itemElement(setFirstInputRef)
                                    : itemElement}
                                {removeButtonElement}
                            </>
                        )}
                    </NItemsRow>
                )}
                renderRemoveButton={({ removeItem, index, items, removeText = '' }) => {
                    if (props.showRemoveButtonOnFirstItems && !props.disabled) {
                        return (
                            <RemoveButton
                                onClick={() => {
                                    removeItem();
                                    if (items.length === index + 1 || items.length === 2) {
                                        this.focusAddButton();
                                    }
                                }}
                                removeText={removeText}
                            />
                        );
                    }
                    // Don't show trash can on first item if it's the only item
                    return (
                        (index > 0 || items.length > 1) &&
                        !props.disabled && (
                            <RemoveButton
                                onClick={() => {
                                    removeItem();
                                    if (items.length === index + 1 || items.length === 2) {
                                        this.focusAddButton();
                                    }
                                }}
                                removeText={removeText}
                            />
                        )
                    );
                }}
                renderAddButton={({ addText, addItem, items }) =>
                    !props.disabled && (
                        <Row className="mark43-n-items-add-row">
                            <AddButton
                                onClick={() => {
                                    addItem();
                                    this.setNewIndex(items.length);
                                }}
                                setRef={(ref) => (this.addButton = ref)}
                            >
                                {addText}
                            </AddButton>
                        </Row>
                    )
                }
                {...props}
            />
        );
    }
}

function nItemsControl(Component) {
    function NItemsControl(props) {
        return (
            <WithRegistry
                render={({ registry }) => {
                    const form = registry.get(props.formName, props.formIndex);
                    return (
                        <Component
                            {...props}
                            getConfigurationForPath={form.getConfigurationForPath}
                        />
                    );
                }}
            />
        );
    }

    NItemsControl.propTypes = {
        formName: PropTypes.string.isRequired,

        // adding these proptypes below to prevent error TS2322
        // when using <ArbiterMFTNItems /> in .tsx files
        addItemOnEmpty: PropTypes.bool,
        addText: PropTypes.string,
        formIndex: PropTypes.number,
        path: PropTypes.string,
        childFieldKeys: PropTypes.arrayOf(PropTypes.string),
        render: PropTypes.func,
    };

    return NItemsControl;
}

/**
 * NItems for arbiter-markformythree forms. Same props as MFTNItems.
 *   One additional optional prop - props.formName. This allows N-Items wrappers fields
 *   to properly respond to arbiter field configurations.
 */
export const ArbiterMFTNItems = flowRight(
    nItemsControl,
    getFieldName,
    fieldNameToTestId,
    arbiterInput
)(MFTNItems);

/**
 * @typedef {object}   Props
 * @prop   {React.MouseEventHandler<HTMLButtonElement>}  [onClick]
 * @prop   {typeof iconTypes[keyof typeof iconTypes] | React.ReactNode}    [icon]
 * @prop   {React.Ref<HTMLButtonElement>}     [setRef]
 * @prop   {string}     [testId]
 * @prop   {Component} [children]
 * @prop   {string}    [className]
 * @prop   {boolean}    [isDisabled]
 *
 * @param {Props}    props
 */
export function AddButton({
    children,
    onClick,
    icon = iconTypes.ADD,
    className,
    testId,
    setRef,
    isDisabled,
    id,
}) {
    return (
        <FeatureFlagged
            flag="ARC_RELEASE_CYCLE_ONE_COMPONENTS"
            fallback={
                <Button
                    testId={testId || testIds.NITEMS_ADD_ITEM}
                    className={classNames(buttonTypes.ICON_LINK, className)}
                    iconLeft={icon}
                    onClick={onClick}
                    ref={setRef}
                    disabled={isDisabled}
                    id={id}
                >
                    {children}
                </Button>
            }
        >
            <ArcButton
                testId={testId || testIds.NITEMS_ADD_ITEM}
                className={className}
                // NOTE: So far only the 'Add' icon is used here
                leftIcon="Add"
                onClick={onClick}
                ref={setRef}
                variant="ghost"
                style={{ paddingLeft: 0 }}
                isDisabled={isDisabled}
                id={id}
            >
                {children}
            </ArcButton>
        </FeatureFlagged>
    );
}

/**
 * @typedef {object}   Props
 * @prop   {React.MouseEventHandler<HTMLButtonElement>}  [onClick]
 * @prop   {typeof iconTypes[keyof typeof iconTypes] | React.ReactNode}    [icon]
 * @prop   {string} [removeText]
 * @prop   {string}    [className]
 * @prop   {boolean}    [isDisabled]
 *
 * @param {Props}    props
 */
export function RemoveButton({
    onClick,
    className = '',
    icon = iconTypes.TRASH_CAN,
    removeText = '',
    isDisabled,
}) {
    return (
        <FeatureFlagged
            flag="ARC_RELEASE_CYCLE_THREE_COMPONENTS"
            fallback={
                <Button
                    testId={testIds.NITEMS_REMOVE_ITEM}
                    className={classNames(
                        buttonTypes.ICON_LINK,
                        className,
                        buttonTypes.DELETE_ICON_LINK
                    )}
                    iconLeft={icon}
                    onClick={onClick}
                    disabled={isDisabled}
                >
                    {removeText}
                </Button>
            }
        >
            <ArcButton
                size="sm"
                testId={testIds.NITEMS_REMOVE_ITEM}
                className={classNames(buttonTypes.DELETE_ICON_LINK, className)}
                // NOTE: So far only the 'TrashCan' icon is used here
                icon="TrashCan"
                onClick={onClick}
                isDisabled={isDisabled}
            >
                {removeText}
            </ArcButton>
        </FeatureFlagged>
    );
}
