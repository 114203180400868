import keyMirror from 'keymirror';

/**
 * Client-only forms that do not have any server validation rules. For forms
 *   that do, see FormEnum.
 * @type {Object}
 */
export default keyMirror({
    // advanced search
    ADVANCED_SEARCH_REPORTS: null,
    ADVANCED_SEARCH_CAD_TICKETS: null,
    ADVANCED_SEARCH_PERSONS: null,
    ADVANCED_SEARCH_ORGANIZATIONS: null,
    ADVANCED_SEARCH_VEHICLES: null,
    ADVANCED_SEARCH_PROPERTY: null,
    ADVANCED_SEARCH_EXPORT: null,
    ADVANCED_SEARCH_ATTACHMENTS: null,
    ADVANCED_SEARCH_COURT_ORDERS: null,

    // records
    ROUTING_LABELS: null,

    // reports
    ITEM_POPOVER: null,
    REPORT_CHANGE_OWNER: null,
    REPORT_REJECTION: null,
    REPORTS_DASHBOARD_SEARCH: null,
    ARREST_CHARGES_SIDE_PANEL_PRIOR_OFFENSE_SELECTOR: null,
    ARREST_UCR: null,
    OFFENSE_CODE_CHANGE_REASON: null,

    // record privacy
    REPORT_UNSEALING: null,

    // cases
    ALL_CASES_SEARCH: null,
    MY_CASES_SEARCH: null,
    UNASSIGNED_REPORTS_SEARCH: null,
    CASE_DETAILS: null,
    TASK_DETAILS: null,
    PHOTO_LINEUP_FILTER_FORM: null,

    // warrants
    WARRANT_DETAILS: null,
    WARRANTS_DASHBOARD_SEARCH: null,
    CONNECT_WARRANTS_SIDE_PANEL: null,

    // evidence
    LABEL_PRINTING: null,
    EVIDENCE_DOCUMENT_EXPORTING: null,
    FACILITY_ADMIN: null,
    STORAGE_ADMIN: null,
    EVIDENCE_DASHBOARD_SEARCH: null,
    EVIDENCE_DASHBOARD_EXPORT: null,
    EVIDENCE_INVENTORIES_DASHBOARD_SEARCH: null,
    RESPONSIBLE_OFFICER: null,
    STAFF_REMARK: null,

    // admin
    ADMIN_TEST_HANDLEBARS_FORM: null,
    ALERT_ADMIN: null,
    AUDIT_LOGS: null,
    AGENCY_PROFILE_ADMIN: null,
    SOCIETY_PROFILE_ADMIN: null,
    CALL_FOR_SERVICE_ADMIN: null,
    CASE_DEFINITIONS_ADMIN: null,
    CAD_BOLO_AUDIT_ADMIN: null,
    CAD_UNITS_ADMIN: null,
    CAD_DESKTOP_CONFIGS_ADMIN: null,
    CAD_CALL_TAKER_STATIONS_ADMIN: null,
    CAD_MESSAGING_AUDIT_ADMIN: null,
    CAD_SERVICE_ROTATIONS_ADMIN: null,
    CAD_MESSAGING_GROUPS_ADMIN: null,
    CAD_SERVICE_ROTATION_AUDIT_ADMIN: null,
    CAD_RAPID_SOS_ADMIN_FORM: null,
    CAD_ROTATIONS_LIST_ADMIN: null,
    CAD_SERVICE_PROVIDERS_ADMIN: null,
    CAD_SERVICE_TYPES_ADMIN: null,
    CAD_SERVICE_ROTATION_LISTS_ADMIN: null,
    CAD_SERVICE_ROTATION_LISTS_ENTRY_ADMIN: null,
    COMPLIANCE_DELETE_HISTORY: null,
    COMPLIANCE_EXPORT: null,
    COMPLIANCE_EXPORT_AUTHENTICATION_FORM: null,
    COMPLIANCE_HISTORY: null,
    COMPLIANCE_RESUBMISSION: null,
    DEPARTMENT_RESET: null,
    DEPARTMENT_SETUP: null,
    DEPARTMENT_SUBDOMAIN_UPDATE: null,
    REPORT_MODULES_ADMIN: null,
    FIELD_CREATION_ADMIN: null,
    PRINTING_TEMPLATES_ADMIN: null,
    REPORT_PRINTING_TEMPLATES_ADMIN: null,
    CASE_PRINTING_TEMPLATES_ADMIN: null,
    EDIT_ROLE_FILTER_USERS: null,
    EDIT_ROLE_FILTER_ABILITIES: null,
    EDIT_ROLE_USERS_MANAGE_STATUS: null,
    EDIT_ROLE_ABILITIES_MODULES: null,
    EDIT_ROLE_ABILITIES_ENABLED: null,
    EDIT_ROLE_NAME: null,
    LOCATION_CAUTIONS_ADMIN: null,
    LOCATION_CAUTIONS_SUBPREMISE_ADMIN: null,
    STREET_ALIASES_ADMIN: null,
    STREET_SEGMENTS_ADMIN: null,
    ATTRIBUTE_TYPE_CODE_TYPE_LINKS: null,
    USAGE_LOGS: null,
    FIELD_CONFIGURATION_ADMIN: null,
    RULES_ADMIN: null,
    WARRANT_PERMISSIONS: null,
    MASTER_ENTITIES: null,
    COMMAND_LINE_ARGUMENT: null,
    DISPATCH_AREAS_ADMIN: null,
    DUPLICATE_EVENT_SEARCH_ADMIN: null,
    SHORTCUT_KEYS: null,
    SUBDIVISIONS_SHAPEFILE_UPLOAD: null,
    SUBDIVISIONS_ADMIN: null,
    STATIONS_ADMIN: null,
    NEW_ROLE_ADMIN: null,
    ASSIGN_USER_ROLE: null,
    USERS_DASHBOARD_ADMIN: null,
    EXPORT_RELEASE_SETTINGS_ADMIN: null,
    FILLABLE_PDF_TEMPLATES_ADMIN_FORM: null,
    ADMIN_NEW_PDF_TEMPLATE_FORM: null,
    HOSPITAL_ADMIN_FORM: null,
    RULE_CONDITION_ADMIN_FORM: null,

    // support
    SUPPORT_SEARCH_SYNC_SYNC_ENTITY: null,
    SUPPORT_SEARCH_SYNC_TYPE: null,
    SUPPORT_SEARCH_SYNC_MULTI: null,
    CAD_UNIT_STATUS_TRANSITIONS: null,
    SUPPORT_ID_GENERATOR: null,
    SUPPORT_CLONE_ATTRIBUTE_CODES: null,

    // analysis
    LOOKER_DASHBOARD: null,

    // notifications
    NOTIFICATION_SETTINGS_FORM: null,

    FORM_SEQUENCE_INCREASE_MODAL: null,

    // permissions
    CASE_PERMISSIONS_FORM: null,
    ENTITY_PROFILE_PERMISSIONS_FORM: null,
    REPORT_PERMISSIONS_FORM: null,
    WARRANT_PERMISSIONS_FORM: null,
    SAVED_SEARCH_PERMISSIONS_FORM: null,
    EVIDENCE_LOCATION_PERMISSIONS_FORM: null,

    // clips
    CLIPS_WARRANT_FORM_NAME: null,
    CLIPS_REPORT_PREFILL_FORM: null,

    // saved search
    SAVED_SEARCH_CREATE_FORM: null,
    SAVED_SEARCH_SHARE_FORM: null,

    // search
    PERSON_SEARCH_TO_ADD_FORM: null,

    // tasks
    TASKS_DASHBOARD_SEARCH_FORM: null,
    FORM_CREATE_FOLDER: null,
    FORM_UPDATE_FOLDER: null,

    // entity profile
    ENTITY_PROFILE_REPORTS_FILTER: null,

    // Person Profile Involvement Type Form
    PERSON_PROFILE_INVOLVEMENT_TYPE: null,

    // emails
    SEND_EMAIL_FORM: null,

    // detention disposition
    DETENTION_DISPOSITION_FORM: null,

    // booking search
    SEARCH_BOOKINGS_FORM: null,

    // Diagram
    DIAGRAM_ASSET_ADMIN_FORM: null,
});
