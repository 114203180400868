import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { filter } from 'lodash';
import { lifecycleOptions, formEvents } from 'markformythree';
import { LinkTypesEnum } from '@mark43/rms-api';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import componentStrings from '~/client-common/core/strings/componentStrings';

import { createPersonProfileFormConfiguration } from '../state/forms/createPersonProfileFormConfiguration';
import {
    fetchAndBuildPersonProfileFormModel,
    setPersonProfileFormModelBySelectedLinkType,
} from '../state/ui';
import formsRegistry from '../../../../core/formsRegistry';
import ArbiterForm from '../../markformythree-arbiter/ArbiterForm';
import { SimpleLoading } from '../../../../legacy-redux/components/core/Loading';

import {
    uploadingFilesSelector,
    updateUploadingFiles,
    clearUploads,
    clearDeletedAttachments,
} from '../../../attachments/core/state/ui/inlineAttachmentsUploader';
import { getFormContextForLinkType } from '../utils/getFormContextForLinkType';
import { getCollapsibleSectionConfigForLinkType } from '../utils/getCollapsibleSectionConfigForLinkType';
import { focusFieldById } from '../utils/focusFieldById';
import { attributesToPrefetch } from '../state/forms/convertDexPersonToPersonProfile';
import { loadAttributesForType } from '../../attributes/state/ui/loadAttributesForType';
import { PersonProfileFormComponent } from './PersonProfileFormComponent';

const { PersonProfileForm: strings } = componentStrings.core;

class PersonProfileFormUnconnected extends React.Component {
    state = {
        // Profile loading is now asyncronous
        initialState: null,
        initialValidation: false,
    };

    componentDidMount() {
        const onSuccess = (formModel) => {
            this.setState({ initialState: formModel, initialValidation: true }, () => {
                if (this.props.focusFieldOnMount) {
                    focusFieldById(this.props.focusFieldOnMount);
                }
                if (
                    this.props.linkType === LinkTypesEnum.INVOLVED_PERSON_IN_REPORT &&
                    this.props.applicationSettings.RMS_PERSON_SIDE_PANEL_INVOLVEMENT_TYPE_ENABLED
                ) {
                    this.props.setPersonProfileFormModelBySelectedLinkType(
                        this.props.formConfigurationLinkType,
                        formModel
                    );
                }
            });
        };

        this.formConfiguration = createPersonProfileFormConfiguration();

        const promiseToResolve = this.props.dexPerson
            ? this.props.loadAttributesForType({
                  attributeType: attributesToPrefetch,
              })
            : Promise.resolve();

        promiseToResolve.then(() =>
            this.props.fetchAndBuildPersonProfileFormModel({
                personProfileId: this.props.id,
                personType: this.props.personType,
                ownerId: this.props.ownerId,
                ownerType: this.props.ownerType,
                nibrsOffenseCode: this.props.nibrsOffenseCode,
                dexPerson: this.props.dexPerson,
                cadProfileId: this.props.cadProfileId,
                onSuccess,
            })
        );
    }

    handleFileUploadChanges(uploadingFiles) {
        const activeUploadingFiles = filter(
            uploadingFiles,
            ({ file }) => !file.isDeleted && (file.isLoading || file.uploadFailed)
        );

        this.props.setSaveButtonDisabled(activeUploadingFiles.length > 0);

        const failedUploadingFiles = filter(
            uploadingFiles,
            ({ file }) => file.uploadFailed && !file.isDeleted
        );

        if (failedUploadingFiles.length === 0) {
            this.props.removePanelErrorMessage(
                strings.Attachments.attachmentUploadFailedPleaseTryAgain
            );
        } else {
            this.props.appendPanelErrorMessageIfNotExists(
                strings.Attachments.attachmentUploadFailedPleaseTryAgain
            );
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.uploadingFiles !== prevProps.uploadingFiles) {
            this.handleFileUploadChanges(this.props.uploadingFiles);
        }
    }

    componentWillUnmount() {
        // reset `InlineAttachmentsUploader`'s state as it's global
        this.props.updateUploadingFiles([]);
        this.props.clearUploads();
        this.props.clearDeletedAttachments();
        formsRegistry.unregister(getFormContextForLinkType(this.props.linkType));
        // technically this belongs within the redesign component but since
        // that component is going to go away as soon as we switch all customers
        // over to it, resetting sections here is more appropriate
        this.props.updateFormSectionState(
            getCollapsibleSectionConfigForLinkType(this.props.linkType)
        );
    }

    renderForm = (form) => {
        if (
            this.state.initialValidation &&
            this.props.applicationSettings.RMS_CAD_DATA_ENTITY_PREFILL_ENABLED
        ) {
            const validationResult = form.validate({ eventType: formEvents.FORM_SUBMIT });
            if (validationResult?.formErrors.length > 0) {
                this.props.appendPanelErrorMessageIfNotExists(validationResult?.formErrors);
            }
            this.setState({ initialState: this.state.initialState, initialValidation: false });
        }

        return (
            <PersonProfileFormComponent
                form={form}
                personType={this.props.personType}
                id={this.props.id}
                formSectionState={this.props.formSectionState}
                updateFormSectionState={this.props.updateFormSectionState}
                isEditingMasterProfile={this.props.isEditingMasterProfile}
                cadProfileId={this.props.cadProfileId}
            />
        );
    };

    render() {
        const formContext = getFormContextForLinkType(this.props.formConfigurationLinkType);

        return this.state.initialState ? (
            <ArbiterForm
                lifecycle={lifecycleOptions.REGISTER_AND_RETAIN}
                name={formContext}
                context={formContext}
                configuration={this.formConfiguration}
                render={this.renderForm}
                initialState={this.state.initialState}
            />
        ) : (
            <SimpleLoading />
        );
    }
}

export const PersonProfileForm = connect(
    createStructuredSelector({
        uploadingFiles: uploadingFilesSelector,
        applicationSettings: applicationSettingsSelector,
    }),
    {
        fetchAndBuildPersonProfileFormModel,
        updateUploadingFiles,
        clearUploads,
        clearDeletedAttachments,
        setPersonProfileFormModelBySelectedLinkType,
        loadAttributesForType,
    }
)(PersonProfileFormUnconnected);
