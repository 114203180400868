import React from 'react';
// import { useSelector } from 'react-redux';

// import * as fields from '~/client-common/core/enums/universal/fields';
// import { reportViewModelByIdSelector } from '~/client-common/core/domain/reports/state/ui';

// import { CardSection } from '../../../../../legacy-redux/components/core/card';
// import SummaryList from '../../../../../legacy-redux/components/summaries/SummaryList';
// import SummaryRow from '../../../../../legacy-redux/components/summaries/SummaryRow';
// import SummaryRowDate from '../../../../../legacy-redux/components/summaries/SummaryRowDate';

const TrafficCrashEventInfoSummary = ({}) => {
    return <></>;
};

export default TrafficCrashEventInfoSummary;
