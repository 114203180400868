import React from 'react';
import { useSelector } from 'react-redux';

import { dateTypeOptions } from '~/client-common/core/dates/utils/dateHelpers';
import { OFFENSE_TYPE_OFFENSE_CODE_FLAGS } from '~/client-common/core/domain/offense-codes/constants';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import useFields, { useCaseFieldName } from '~/client-common/core/fields/hooks/useFields';
import {
    combinedSubdivisionsLabelSelector,
    globalSequenceNumberLabelSelector,
} from '~/client-common/core/fields/state/config';
import {
    REPORT_REPORTING_EVENT_NUMBER,
    CASE_LOCAL_ID,
    DISPLAY_ONLY_CASE_TITLE_LABEL,
    DISPLAY_ONLY_CASE_SEARCH_DATE_TIME_RANGE_LABEL,
    DISPLAY_ONLY_CASE_SEARCH_LABELS_LABEL,
} from '~/client-common/core/enums/universal/fields';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import Row from '../../../core/components/Row';
import { RRFApprovalStatusForCaseSelect } from '../../../core/forms/components/selects/ApprovalStatusForCaseSelect';
import { RRFAttributeSelect } from '../../../core/forms/components/selects/AttributeSelect';
import { RRFCaseDefinitionSelect } from '../../../core/forms/components/selects/CaseDefinitionSelect';
import { RRFDateRangePicker } from '../../../core/forms/components/DateRangePicker';
import { RRFFieldset } from '../../../core/forms/components/Fieldset';
import { RRFBooleanSelect } from '../../../core/forms/components/selects/BooleanSelect';
import { RRFOffenseCodeSelect } from '../../../core/forms/components/selects/OffenseCodeSelect';
import { RRFSelect } from '../../../core/forms/components/selects/Select';
import { RRFText } from '../../../core/forms/components/Text';
import { RRFLabelsWithNoneAttrSelect } from '../../../core/forms/components/selects/LabelsWithNoneAttrSelect';
import { SubdivisionsSearch } from '../../../search/core/components/SubdivisionsSearch';

import fieldsetViewModel from '../state/forms/allCasesSearchCaseInformationFieldset';
import testIds from '../../../../core/testIds';

/**
 * Fieldset for case information in the All Cases search form.
 */
const AllCasesSearchCaseInformationFieldset = function () {
    const combinedSubdivisionsLabel = useSelector(combinedSubdivisionsLabelSelector);
    const globalSequenceNumberLabel = useSelector(globalSequenceNumberLabelSelector);

    const { singularCaseFieldName: caseFieldName } = useCaseFieldName();
    const caseEnhancementsEnabled = useSelector(applicationSettingsSelector)
        .RMS_INVESTIGATION_ENHANCEMENTS_PHASE_ONE;
    const fieldDisplayNames = useFields([
        REPORT_REPORTING_EVENT_NUMBER,
        CASE_LOCAL_ID,
        DISPLAY_ONLY_CASE_TITLE_LABEL,
        DISPLAY_ONLY_CASE_SEARCH_DATE_TIME_RANGE_LABEL,
        DISPLAY_ONLY_CASE_SEARCH_LABELS_LABEL,
    ]);

    const caseInfoRowWidth = !!caseEnhancementsEnabled ? 144 : 196;

    return (
        <RRFFieldset path="caseInformation" title={fieldsetViewModel.title(caseFieldName)}>
            <Row>
                <RRFSelect
                    path="dateType"
                    options={dateTypeOptions}
                    width={254}
                    clearable={false}
                />
                <RRFDateRangePicker
                    label={fieldDisplayNames.DISPLAY_ONLY_CASE_SEARCH_DATE_TIME_RANGE_LABEL}
                    width={254}
                    includeTime={true}
                    withinLastPeriodOptions={['PT12H', 'PT24H', 'PT48H', 'P7D', 'P28D']}
                    toDatePeriodOptions={['P1M', 'P1Y']}
                />
                <RRFBooleanSelect path="isOverdue" width={80} />
            </Row>
            <Row>
                <div data-test-id={testIds.CASE_DASHBOARD_CASE_TYPE_FILTER}>
                    <RRFCaseDefinitionSelect
                        width={caseInfoRowWidth}
                        path="caseDefinitionId"
                        includeExpired={true}
                    />
                </div>
                <RRFApprovalStatusForCaseSelect
                    width={caseInfoRowWidth}
                    path="approvalStatuses"
                    multiple={true}
                />
                <div data-test-id={testIds.CASE_DASHBOARD_CASE_STATUS_FILTER}>
                    <RRFAttributeSelect
                        width={caseInfoRowWidth}
                        path="caseStatusAttrIds"
                        attributeType="CASE_STATUS"
                        multiple={true}
                        includeExpired={true}
                    />
                </div>
                <div>
                    <RRFText
                        path="caseTitle"
                        label={fieldDisplayNames.DISPLAY_ONLY_CASE_TITLE_LABEL}
                        width={caseInfoRowWidth}
                    />
                </div>
                <FeatureFlagged flag="RMS_INVESTIGATION_ENHANCEMENTS_PHASE_ONE">
                    <RRFText
                        path="caseNumber"
                        label={fieldDisplayNames.CASE_LOCAL_ID}
                        width={caseInfoRowWidth}
                    />
                </FeatureFlagged>
            </Row>
            <Row>
                <FeatureFlagged
                    flag="RMS_INVESTIGATION_ENHANCEMENTS_PHASE_ONE"
                    fallback={
                        <RRFText
                            path="reportingEventNumber"
                            label={fieldDisplayNames.REPORT_REPORTING_EVENT_NUMBER}
                            width={196}
                        />
                    }
                >
                    <RRFText
                        path="reportIdentifier"
                        label={globalSequenceNumberLabel}
                        width={196}
                    />
                </FeatureFlagged>
                <RRFOffenseCodeSelect
                    multiple={true}
                    flags={OFFENSE_TYPE_OFFENSE_CODE_FLAGS}
                    includeExpired={true}
                    width={196}
                    path="offenseCodeIds"
                />
                <FeatureFlagged
                    flag="RMS_SUBDIVISION_SEARCH_ENHANCEMENTS_ENABLED"
                    fallback={
                        <RRFAttributeSelect
                            attributeType={[
                                'SUBDIVISION_DEPTH_1',
                                'SUBDIVISION_DEPTH_2',
                                'SUBDIVISION_DEPTH_3',
                                'SUBDIVISION_DEPTH_4',
                                'SUBDIVISION_DEPTH_5',
                            ]}
                            multiple={true}
                            includeExpired={true}
                            width={196}
                            label={combinedSubdivisionsLabel}
                            path="subdivisionAttrIds"
                        />
                    }
                />
                <SubdivisionsSearch width={196} />
            </Row>
            {
                <Row>
                    <RRFLabelsWithNoneAttrSelect
                        label={fieldDisplayNames.DISPLAY_ONLY_CASE_SEARCH_LABELS_LABEL}
                        multiple={true}
                        width={196}
                    />
                </Row>
            }
        </RRFFieldset>
    );
};

export default AllCasesSearchCaseInformationFieldset;
