import React from 'react';
import styled from 'styled-components';
import { Icon as ArcIcon, cssVar } from 'arc';

import ImgWithExifOrientation from '~/client-common/core/images/components/ImgWithExifOrientation';

import Icon, { iconTypes, iconSizes } from './Icon';

// Let's control the number of sizes here so that
// we have consistency with how this component
// appears across the app

export const profileImageSizes = {
    ENTITY_PROFILE: '120px',
    RECENT_ARRESTS: '85px',
    PERSON_PILL: '70px',
    PERSON_PILL_SMALL: '40px',
    IMAGE_CELL: '80px',
    QUICK_SEARCH: '65px',
    USER_PROFILE: '100px',
    INVOLVED_NAME: '90px',
    USER_SIGNATURE: '100px',
};

const ImageWithMaxHeightMaxWidth = styled(ImgWithExifOrientation)`
    max-height: 100%;
    max-width: 100%;
`;

// Default height / width to be responsive
const ImageContainer = styled.div`
    ${(props) =>
        !props.noBorder &&
        `border: 1px solid var(--arc-colors-border-default);
    `}
    overflow: hidden;
    border-radius: var(--arc-radii-md);
    flex-shrink: 0;
    position: relative;
    display: flex;
    align-items: center;
    z-index: 0;
`;

const ProfilePhotoImageContainer = styled(ImageContainer)`
    width: ${(props) => props.size};
    height: ${(props) => props.size};
    background-color: ${(props) => props.theme.colors.extraLightGrey};
    justify-content: center;
`;

const SignatureImageContainer = styled(ImageContainer)`
    height: ${(props) => props.size};
    justify-content: left;
    align-items: flex-start;
    width: 98%;
`;

const FullWidthIcon = styled(Icon)`
    width: ${(props) => props.iconSize};
    height: ${(props) => props.iconSize};

    svg {
        width: 100%;
        height: 100%;
    }
`;

// Use `iconSize` instead of `size` to avoid collisions with
// icon component, which only accepts `px` values as size
const ProfileIcon = ({ iconSize, className, iconType }) => (
    <FullWidthIcon iconSize={iconSize} className={className} type={iconType} color="mediumGrey" />
);

const PersonIcon = ({ isUnknown, iconSize, className }) => (
    <ProfileIcon
        className={className}
        iconSize={iconSize}
        iconType={isUnknown ? iconTypes.UNKNOWN_PERSON : iconTypes.PERSON}
    />
);

const OrganizationIcon = ({ iconSize, className }) => (
    <ProfileIcon className={className} iconSize={iconSize} iconType={iconTypes.ORGANIZATION} />
);

export const PersonStubIcon = ({ isUnknown, className }) => (
    <PersonIcon className={className} isUnknown={isUnknown} iconSize={`${iconSizes.MEDIUM}px`} />
);

// Somewhat arbitrary, but pulled off of person-pill (icon to div width ratio)
const iconPercent = `${(50 / 70) * 100}%`;

// TODO: We can use this component to consolidate / replace all of our other
// profile component (items, vehicles, orgs, etc...)
//
// Children is specifically for overlaying content over the image (only
// used for recent arrests right now)
const ProfileImage = ({
    url,
    children,
    size = '100%',
    className,
    noBorder,
    fallbackIcon,
    zIndex,
    ...props
}) => (
    <ProfilePhotoImageContainer
        className={className}
        size={size}
        url={url}
        noBorder={noBorder}
        {...props}
    >
        {url && <ImgWithExifOrientation src={url} isBackgroundImage={true} zIndex={zIndex} />}
        {!url && fallbackIcon}
        {children}
    </ProfilePhotoImageContainer>
);

export const PersonProfileImage = ({ isUnknown, ...props }) => (
    <ProfileImage
        {...props}
        fallbackIcon={<PersonIcon isUnknown={isUnknown} iconSize={iconPercent} />}
    />
);

const VehicleIcon = ({ iconSize, className }) => (
    <ProfileIcon className={className} iconSize={iconSize} iconType={iconTypes.VEHICLE} />
);

export const VehicleProfileImage = (props) => (
    <ProfileImage {...props} fallbackIcon={<VehicleIcon iconSize={iconPercent} />} />
);

export const OrganizationProfileImage = (props) => (
    <ProfileImage {...props} fallbackIcon={<OrganizationIcon iconSize={iconPercent} />} />
);

export const SignatureImage = ({ url, children, size = '100%', className, noBorder, zIndex }) => (
    <SignatureImageContainer className={className} size={size} noBorder={noBorder}>
        {url && <ImageWithMaxHeightMaxWidth src={url} isBackgroundImage={false} zIndex={zIndex} />}
        {children}
    </SignatureImageContainer>
);

const StyledProfileImage = styled(ProfileImage)`
    background-color: ${cssVar('arc.colors.raw.gray.200')};
`;

export const CaseProfileImage = (props) => (
    <StyledProfileImage
        {...props}
        noBorder
        fallbackIcon={
            <ArcIcon
                color="raw.gray.500"
                icon="Case"
                iconSize={iconPercent}
                boxSize={iconPercent}
            />
        }
    />
);
