import { ProductModuleEnum } from '@mark43/rms-api';
import React from 'react';
import { IndexRedirect, IndexRoute, Redirect, Route, Router } from 'react-router';

import { get, reduce } from 'lodash';
import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';
import errorCodeEnum from '~/client-common/core/enums/client/errorCodeEnum';

import store from '../core/store';
import Report from '../modules/reports/core/components/Report';
import ReportPage from '../modules/reports/core/components/ReportPage';
import ReportHistory from '../modules/reports/core/components/history/ReportHistory';
import ReportExports from '../modules/reports/core/components/ReportExports';
import ExportRedactionPage from '../modules/core/redaction/components/ExportRedactionPage';

import Cobalt from '../legacy-redux/components/core/Cobalt';
import PersonalDashboard from '../modules/personal-dashboard/components/PersonalDashboard';
import { ErrorPageWrapper } from '../modules/core/components/ErrorPageWrapper';
import AdvancedSearch from '../modules/search/core/components/AdvancedSearch';
import AdvancedSearchContainer from '../modules/search/core/components/AdvancedSearchContainer';
import AdvancedSearchExport from '../modules/search/core/components/AdvancedSearchExport';
import EntityProfile from '../modules/entity-profiles/core/components/EntityProfile';
import Evidence from '../modules/evidence/core/components/Evidence';
import EvidenceDashboard from '../modules/evidence/dashboard/containers/EvidenceDashboard';
import EvidenceDashboardExports from '../modules/evidence/dashboard/components/EvidenceDashboardExports';
import ChainOfCustodyExport from '../modules/evidence/chain-of-custody/components/ChainOfCustodyExport';
import ItemQueue from '../modules/evidence/item-queue/components/ItemQueue';
import InventoriesDashboard from '../modules/evidence/inventories/containers/InventoriesDashboard';
import Inventory from '../modules/evidence/inventory/containers/Inventory';
import Analysis from '../modules/analysis/core/components/Analysis';
import { LookerDashboard } from '../modules/analysis/dashboard/components/LookerDashboard';
import NotificationsDashboard from '../modules/notifications/dashboard/components/NotificationsDashboard';
import NotificationsDashboardInbox from '../modules/notifications/dashboard/components/NotificationsDashboardInbox';
import NotificationsDashboardArchive from '../modules/notifications/dashboard/components/NotificationsDashboardArchive';
import NotificationsDashboardAlerts from '../modules/notifications/dashboard/components/NotificationsDashboardAlerts';
import { NotificationSettings } from '../modules/notifications/settings/components/NotificationSettings';
import { MergeEntitiesDashboard } from '../modules/merge-entities/components';
import { mergeEntitiesOnEnter } from '../modules/merge-entities';
// import ReportReleaseTracking from '../modules/reports/core/components/ReportReleaseTracking';
import ReportsDashboardContainer from '../modules/reports/dashboard/components/ReportsDashboardContainer';
import {
    reportOnEnter,
    reportOnLeave,
    reportSnapshotOnEnter,
} from '../modules/reports/core/state/data/reportRouting';
import { onReportsEnter } from '../modules/reports/dashboard';
import { ReportModule } from '../modules/reports/dashboard/components/ReportModule';
import { reportSealingOnEnter } from '../modules/record-privacy/sealing';
import { SealingDashboard } from '../modules/record-privacy/sealing/components';
import { reportUnsealingOnEnter } from '../modules/record-privacy/unsealing';
import { UnsealingDashboard } from '../modules/record-privacy/unsealing/components';
import { courtOrderEditOnEnter } from '../modules/record-privacy/court-orders';
import { CourtOrderEditDashboard } from '../modules/record-privacy/court-orders/components';
import { reportChargeVacatingOnEnter } from '../modules/record-privacy/vacating';
import { ChargeVacatingDashboard } from '../modules/record-privacy/vacating/components';
import { onTasksEnter } from '../modules/tasks/dashboard';
import CasesContainer from '../modules/cases/core/components/CasesContainer';
import CaseContainer from '../modules/cases/core/components/CaseContainer';
import {
    caseAssociatedRecordsOnEnter,
    caseAttachmentFolderOnEnter,
    caseAttachmentsOnEnter,
    caseLinkedProfilesOnEnter,
    caseNoteFolderOnEnter,
    caseNoteOnEnter,
    caseNotesOnEnter,
    caseOnEnter,
    caseOnLeave,
    casesOnEnter,
    casesRedirectOnEnter,
    caseSummaryOnEnter,
    caseTaskListOnEnter,
} from '../modules/cases';
import MyCases from '../modules/cases/my-cases/components/MyCases';
import { onEnter as myCasesOnEnter } from '../modules/cases/my-cases';
import UnassignedReports from '../modules/cases/unassigned-reports/components/UnassignedReports';
import { onEnter as unassignedReportsOnEnter } from '../modules/cases/unassigned-reports';
import AllCases from '../modules/cases/all-cases/components/AllCases';
import { onEnter as allCasesOnEnter } from '../modules/cases/all-cases';
import CaseSummary from '../modules/cases/case-summary/components/CaseSummary';
import CaseNotes from '../modules/cases/case-notes/components/CaseNotes';
import CaseLinkedProfiles from '../modules/cases/linked-profiles/components/CaseLinkedProfiles';
import CaseTaskList from '../modules/cases/task-list/components/CaseTaskList';
import CaseAssociatedRecords from '../modules/cases/case-associated-records/components/CaseAssociatedRecords';
import CasePhotoLineups from '../modules/cases/case-photo-lineups/components/CasePhotoLineups';
import ComposeLineup from '../modules/cases/case-photo-lineups/components/ComposeLineup';
import CaseAttachments from '../modules/cases/case-attachments/components/CaseAttachments';
import CaseEFiles from '../modules/cases/case-efiles/components/CaseEFiles';
import CaseExports from '../modules/cases/core/components/CaseExports';
import BulkCasesExports from '../modules/cases/core/components/BulkCasesExports';
import CaseHistory from '../modules/cases/core/components/CaseHistory';
import { caseExportsOnEnter, caseHistoryOnEnter } from '../modules/cases/core';
import { eFileRoutes } from '../modules/e-files';
import {
    exportsOnEnter as entityProfileExportsOnEnter,
    getEntityProfilesComponents,
    getExportComponents as getEntityProfileExportComponents,
    getReportsComponents,
    getBookingsComponents,
    onBookingsSectionEnter,
    getCasesComponents,
    onEnter as entityProfilesOnEnter,
    onLeave as entityProfilesOnLeave,
    onReportsSectionEnter as entityProfileReportsSectionOnEnter,
    onSectionEnter as entityProfilesOnSectionEnter,
} from '../modules/entity-profiles';
import { WarrantsDashboard } from '../modules/warrants/dashboard/components';
import WarrantContainer from '../modules/warrants/warrant/components/WarrantContainer';
import WarrantPage from '../modules/warrants/warrant/components/WarrantPage';
import WarrantExports from '../modules/warrants/warrant/components/WarrantExports';
import WarrantDexReturns from '../modules/warrants/warrant/components/WarrantDexReturns';
import { WarrantHistory } from '../modules/warrants/warrant/components/WarrantHistory';
import {
    warrantExportsOnEnter,
    warrantHistoryOnEnter,
    warrantOnEnter,
    warrantOnLeave,
    warrantsDashboardOnEnter,
    warrantsOnEnter,
} from '../modules/warrants';
import { onEnter as advancedSearchOnEnter } from '../modules/search';
import { onEnter as evidenceDashboardOnEnter } from '../modules/evidence/dashboard';
import { onEnter as itemQueueOnEnter } from '../modules/evidence/item-queue';
import { onEnter as chainOfCustodyExportsOnEnter } from '../modules/evidence/chain-of-custody';
import { onEnter as inventoriesDashboardOnEnter } from '../modules/evidence/inventories';
import { onEnter as inventoryOnEnter } from '../modules/evidence/inventory';
import { settingsAdminOnEnter } from '../modules/admin/settings';
import {
    notificationsDashboardAlertsOnEnter,
    notificationsDashboardArchiveOnEnter,
    notificationsDashboardInboxOnEnter,
} from '../modules/notifications/dashboard';
import Notepad from '../modules/mobile/notepad/components/Notepad';
import NotepadCollection from '../modules/mobile/notepad/components/NotepadCollection';
import {
    AdminErrorBoundary,
    EvidenceErrorBoundary,
    ReportsErrorBoundary,
    WarrantsErrorBoundary,
} from '../modules/core/errors/components/ErrorBoundary';
import TasksDashboardContainer from '../modules/tasks/dashboard/components/TasksDashboardContainer';

import { Main as DragonMain } from '../modules/dragon/main';

import ComplianceDashboard from '../modules/compliance/components/ComplianceDashboard';
import ComplianceExport from '../modules/compliance/components/ComplianceExport';
import ComplianceExportHistory from '../modules/compliance/components/ComplianceExportHistory';
import ComplianceWrapper from '../modules/compliance/components/ComplianceWrapper';
import ComplianceExportTabsContainer from '../modules/compliance/components/ComplianceExportTabsContainer';
import ComplianceResubmission from '../modules/compliance/components/ComplianceResubmission';
import ClipsDashboardContainer from '../modules/clips/components/ClipsDashboardContainer';
import { clipsOnEnter } from '../modules/clips/state/ui';

import BriefingsDashboard from '../modules/briefings/components/BriefingsDashboard';
import BriefingPage from '../modules/briefings/components/BriefingPage';

import { DetentionDispositionDashboard } from '../modules/reports/detention-disposition/components/DetentionDispositionDashboard';

// START LEGACY NAVIGATION HELPERS
// These helpers allow us to hook URL/state changes to actions inside
// legacy views. They are a temporary fix, and no views based on React
// components should need these or anything like them
// END LEGACY NAVIGATION HELPERS
import { globalErrorSelector } from '../legacy-redux/selectors/globalSelectors';
import redirectToErrorPage from '../modules/core/utils/redirectToErrorPage';
import { setGlobalError } from '../legacy-redux/actions/globalActions';
import ExportLineup from '../modules/cases/case-photo-lineups/components/ExportLineup';
import LineupDetailsPage from '../modules/cases/case-photo-lineups/components/LineupDetailsPage';
import { BRIEFINGS_ROUTES } from '../modules/briefings/configuration/routes';
import { onBriefingsDashboardEnter } from '../modules/briefings/components';
import { FederatedSearch } from '../modules/federated-search/components/FederatedSearch';
import { BookingDetailsPage } from '../modules/bookings/components/BookingDetailsPage';
import { GoodDataDashboard } from '../modules/insights/dashboard/components/GoodDataDashboard';
import Insights from '../modules/insights/core/components/Insights';
import { enterNewRoute, NO_ROUTE_NAME } from './routerModule';
import routeLoadersFactory from './routeLoadersFactory';
import protectRoutes, { gateRoute } from './protectRoutes';
import routesConfig from './routesConfig';
import cobaltHistory from './cobaltHistory';

// create our route actions by providing a dispatch function bound to our redux
// store
const { loginFormOnEnter, logoutOnEnter } = routeLoadersFactory(store.dispatch.bind(store));

// grab the most deeply nested child page name, and in
// a case where there is never a page name provided we have a default
// value to indicate that
function parseKeyFromRoutes(routes, key, defaultValue) {
    return reduce(routes, (acc, route) => (route[key] ? route[key] : acc), defaultValue);
}

const warrantRoutes = gateRoute(
    {
        productModule: ProductModuleEnum.WARRANTS.name,
    },
    <Route
        {...routesConfig.WARRANTS}
        component={WarrantsErrorBoundary}
        path="/warrants"
        onEnter={warrantsOnEnter}
    >
        <IndexRedirect to="dashboard" />
        <Route
            {...routesConfig.WARRANTS_DASHBOARD}
            path="dashboard"
            component={WarrantsDashboard}
            onEnter={warrantsDashboardOnEnter}
        />
        <Route
            {...routesConfig.WARRANT}
            path=":warrantId"
            onEnter={warrantOnEnter}
            onLeave={warrantOnLeave}
            component={WarrantContainer}
        >
            <IndexRoute component={WarrantPage} />
            {gateRoute(
                {
                    ability: abilitiesEnum.WARRANTS.WARRANT_EXPORTS,
                },
                <Route
                    {...routesConfig.WARRANT_EXPORTS}
                    path="exports"
                    onEnter={warrantExportsOnEnter}
                    component={WarrantExports}
                />
            )}
            {gateRoute(
                {
                    featureFlag: 'RMS_WARRANT_HISTORY_ENABLED',
                },
                <Route
                    {...routesConfig.WARRANT_HISTORY}
                    path="history"
                    component={WarrantHistory}
                    onEnter={warrantHistoryOnEnter}
                />
            )}
            {gateRoute(
                {
                    featureFlag: 'RMS_WARRANTS_DATA_EXCHANGE_ENABLED',
                    ability: abilitiesEnum.WARRANTS.RMS_WARRANTS_DEX,
                },
                <Route path="returns" component={WarrantDexReturns} />
            )}
        </Route>
    </Route>
);

const caseDashboardRoutes = gateRoute(
    {
        productModule: ProductModuleEnum.CASES.name,
    },
    <Route {...routesConfig.CASES} path="/cases" component={CasesContainer} onEnter={casesOnEnter}>
        <IndexRoute onEnter={casesRedirectOnEnter} />
        <Route
            {...routesConfig.MY_CASES}
            path="mine"
            component={MyCases}
            onEnter={myCasesOnEnter}
        />
        <Route
            {...routesConfig.MY_CASES_EXPORTS}
            path="mine/exports"
            component={BulkCasesExports}
        />
        <Route path="unassigned" component={UnassignedReports} onEnter={unassignedReportsOnEnter} />
        <Route
            {...routesConfig.ALL_CASES}
            path="all(/:allCasesTab)"
            component={AllCases}
            onEnter={allCasesOnEnter}
        />
        <Route
            {...routesConfig.ALL_CASES_EXPORTS}
            path="all(/:allCasesTab)/exports"
            component={BulkCasesExports}
        />
        <Route path=":caseId" onEnter={caseOnEnter} onLeave={caseOnLeave} component={CaseContainer}>
            <IndexRoute component={CaseSummary} />
            <IndexRedirect to="summary" />
            <Route
                {...routesConfig.CASE_SUMMARY}
                path="summary"
                component={CaseSummary}
                onEnter={caseSummaryOnEnter}
            />
            {gateRoute(
                {
                    ability: abilitiesEnum.CASES.VIEW_NOTES,
                },
                <Route
                    {...routesConfig.CASE_NOTES}
                    path="notes"
                    component={CaseNotes}
                    onEnter={caseNotesOnEnter}
                >
                    <Route path=":noteId" onEnter={caseNoteOnEnter} />
                    {gateRoute(
                        {
                            featureFlag: 'RMS_CASE_FOLDERING_ENABLED',
                        },
                        <Route path="folder/:folderId" onEnter={caseNoteFolderOnEnter}>
                            <Route path=":noteId" onEnter={caseNoteOnEnter} />
                        </Route>
                    )}
                </Route>
            )}
            <Route
                {...routesConfig.CASE_LINKED_PROFILES}
                path="linked-profiles"
                component={CaseLinkedProfiles}
                onEnter={caseLinkedProfilesOnEnter}
            />
            {gateRoute(
                {
                    featureFlag: 'RMS_E_FILING_ENABLED',
                    ability: abilitiesEnum.EFILES.VIEW,
                },
                <Route {...routesConfig.CASE_E_FILES} path="e-files" component={CaseEFiles} />
            )}
            {gateRoute(
                {
                    ability: abilitiesEnum.CASES.VIEW_CASE_TASKS,
                },
                <Route
                    {...routesConfig.CASE_TASK_LIST}
                    path="task-list"
                    component={CaseTaskList}
                    onEnter={caseTaskListOnEnter}
                />
            )}
            <Route
                {...routesConfig.CASE_ATTACHMENTS}
                path="attachments"
                component={CaseAttachments}
                onEnter={caseAttachmentsOnEnter}
            >
                {gateRoute(
                    {
                        featureFlag: 'RMS_CASE_FOLDERING_ENABLED',
                    },
                    <Route path="folder/:folderId" onEnter={caseAttachmentFolderOnEnter} />
                )}
            </Route>
            <Route
                {...routesConfig.CASE_EXPORTS}
                path="exports"
                component={CaseExports}
                onEnter={caseExportsOnEnter}
            />
            <Route
                {...routesConfig.CASE_HISTORY}
                path="history"
                component={CaseHistory}
                onEnter={caseHistoryOnEnter}
            />
            <Route
                {...routesConfig.CASE_ASSOCIATED_RECORDS}
                path="associated-records"
                component={CaseAssociatedRecords}
                onEnter={caseAssociatedRecordsOnEnter}
            />
            {gateRoute(
                {
                    featureFlag: 'RMS_PHOTO_LINEUPS_ENABLED',
                    ability: abilitiesEnum.CASES.VIEW_GENERAL,
                },
                <Route
                    {...routesConfig.CASE_PHOTO_LINEUPS}
                    path="lineups"
                    component={CasePhotoLineups}
                />
            )}
            {gateRoute(
                {
                    featureFlag: 'RMS_PHOTO_LINEUPS_ENABLED',
                    ability: abilitiesEnum.CASES.EDIT_GENERAL,
                },
                <Route
                    {...routesConfig.CASE_PHOTO_LINEUPS}
                    path="lineups/:photoLineupId/compose"
                    component={ComposeLineup}
                />
            )}
            {gateRoute(
                {
                    featureFlag: 'RMS_PHOTO_LINEUPS_ENABLED',
                    ability: abilitiesEnum.CASES.EDIT_GENERAL,
                },
                <Route
                    {...routesConfig.CASE_PHOTO_LINEUPS}
                    path="lineups/:photoLineupId/exports"
                    component={ExportLineup}
                />
            )}
            {gateRoute(
                {
                    featureFlag: 'RMS_PHOTO_LINEUPS_ENABLED',
                    ability: abilitiesEnum.CASES.VIEW_GENERAL,
                },
                <Route
                    {...routesConfig.CASE_PHOTO_LINEUPS}
                    path="lineups/:photoLineupId"
                    component={LineupDetailsPage}
                />
            )}
        </Route>
    </Route>
);

const entityProfilesRoutes = (
    <Route
        {...routesConfig.ENTITY_PROFILE}
        path="/profiles/:entityType/:entityId"
        component={EntityProfile}
        onEnter={entityProfilesOnEnter}
        onLeave={entityProfilesOnLeave}
    >
        <IndexRedirect to="details" />
        <Route
            {...routesConfig.ENTITY_PROFILE_EXPORTS}
            path="exports"
            onEnter={entityProfileExportsOnEnter}
            getComponents={getEntityProfileExportComponents}
        />
        <Route
            path="reports"
            getComponents={getReportsComponents}
            onEnter={entityProfileReportsSectionOnEnter}
        />
        <Route path="cases" getComponents={getCasesComponents} />
        {gateRoute(
            {
                featureFlag: 'RMS_JMS_BOOKINGS_ENABLED',
            },
            <Route
                path="bookings"
                getComponents={getBookingsComponents}
                onEnter={onBookingsSectionEnter}
            />
        )}
        <Route
            path=":section"
            getComponents={getEntityProfilesComponents}
            onEnter={entityProfilesOnSectionEnter}
        />
    </Route>
);

const evidenceRoutes = gateRoute(
    {
        productModule: ProductModuleEnum.EVIDENCE.name,
    },
    <Route path="evidence" component={EvidenceErrorBoundary}>
        <IndexRedirect to="dashboard" />
        <Route component={Evidence}>
            <Route
                {...routesConfig.EVIDENCE_DASHBOARD}
                path="dashboard"
                component={EvidenceDashboard}
                onEnter={evidenceDashboardOnEnter}
            />
            <Route
                path="inventories"
                {...routesConfig.EVIDENCE_INVENTORIES_DASHBOARD}
                component={InventoriesDashboard}
                onEnter={inventoriesDashboardOnEnter}
            />
        </Route>

        {/**
         TODO - exception case where inventories/:id cannot be embedded as child of the inventories route
         as the EvidenceDashboard embeds all the children as part of its 2-column view (one of the columns
         will always show when viewing inventories/:id when it shouldn't be).
         */}
        <Route
            {...routesConfig.EVIDENCE_INVENTORY}
            path="inventories/:id"
            component={Inventory}
            onEnter={inventoryOnEnter}
        />
        <Route path="dashboard/exports" component={EvidenceDashboardExports} />
        <Route
            {...routesConfig.EVIDENCE_CHAIN_OF_CUSTODY_EXPORT}
            path=":itemId/exports"
            component={ChainOfCustodyExport}
            onEnter={chainOfCustodyExportsOnEnter}
        />
        <Route
            {...routesConfig.ITEM_QUEUE}
            path="item-queue"
            component={ItemQueue}
            onEnter={itemQueueOnEnter}
        />
    </Route>
);

const tasksRoutes = gateRoute(
    {
        ability: abilitiesEnum.CORE.VIEW_NON_CASE_TASKS,
        featureFlag: 'RMS_TASK_AND_REQUEST_TRACKING_ENABLED',
    },
    <Route
        {...routesConfig.TASKS_DASHBOARD}
        component={TasksDashboardContainer}
        onEnter={onTasksEnter}
        path="tasks"
    />
);

const analysisRoutes = gateRoute(
    {
        productModule: ProductModuleEnum.ANALYSIS.name,
        ability: abilitiesEnum.ANALYSIS.GENERAL,
    },
    <Route {...routesConfig.ANALYSIS} path="/analytics" component={Analysis}>
        <IndexRedirect to="dashboard" />
        <Route path="dashboard" component={LookerDashboard} />
        <Route path="dashboard/:dashboardId" component={LookerDashboard} />
    </Route>
);

const insightsRoutes = gateRoute(
    {
        productModule: ProductModuleEnum.INSIGHTS.name,
    },
    <Route {...routesConfig.INSIGHTS} path="/insights" component={Insights}>
        <IndexRedirect to="workspace/bc57fa9ff3d540e388c4d264a38bd9fc/dashboard" />
        <Route path="workspace/:workspaceId/dashboard" component={GoodDataDashboard} />
        <Route path="workspace/:workspaceId/dashboard/:dashboardId" component={GoodDataDashboard} />
    </Route>
);

const notificationsRoutes = gateRoute(
    {
        productModule: ProductModuleEnum.NOTIFICATIONS.name,
        abilities: abilitiesEnum.NOTIFICATIONS.CORE,
    },
    <Route path="/notifications" component={NotificationsDashboard}>
        <IndexRedirect to="inbox" />
        <Route
            {...routesConfig.NOTIFICATIONS_DASHBOARD_INBOX}
            path="inbox"
            component={NotificationsDashboardInbox}
            onEnter={notificationsDashboardInboxOnEnter}
        />
        <Route
            {...routesConfig.NOTIFICATIONS_DASHBOARD_ARCHIVE}
            path="archive"
            component={NotificationsDashboardArchive}
            onEnter={notificationsDashboardArchiveOnEnter}
        />
        <Route
            {...routesConfig.NOTIFICATIONS_DASHBOARD_ALERTS}
            path={'alerts'}
            component={NotificationsDashboardAlerts}
            onEnter={notificationsDashboardAlertsOnEnter}
        />
        <Route
            {...routesConfig.NOTIFICATION_SETTINGS}
            path={'settings'}
            component={NotificationSettings}
        />
    </Route>
);

const mobileRoutes = gateRoute(
    {
        featureFlag: 'RMS_MOBILE_ENABLED',
        ability: abilitiesEnum.REPORTING.FIELD_NOTES,
    },
    <Route path="/mobile" component={Notepad}>
        <IndexRedirect to="collections" />
        <Route {...routesConfig.MOBILE_COLLECTIONS} path="collections">
            <Route {...routesConfig.MOBILE_COLLECTIONS} path=":id" components={NotepadCollection} />
        </Route>
        <Route {...routesConfig.MOBILE_ARCHIVE} path="archive">
            <Route {...routesConfig.MOBILE_ARCHIVE} path=":id" components={NotepadCollection} />
        </Route>
    </Route>
);

const federatedSearchRoute = gateRoute(
    {
        productModule: ProductModuleEnum.RMS_DEX.name,
        ability: abilitiesEnum.RMS_DEX.RMS_DEX_MAKE_INQUIRIES,
    },
    gateRoute(
        {
            ability: abilitiesEnum.RMS_DEX.RMS_DEX_VIEW_RESULTS,
        },
        <Route
            {...routesConfig.FEDERATED_SEARCH}
            component={FederatedSearch}
            path="/federated-search"
        />
    )
);

const bookingDetailsRoute = gateRoute(
    {
        productModule: ProductModuleEnum.JMS.name,
        featureFlag: 'RMS_JMS_BOOKINGS_ENABLED',
    },
    <Route {...routesConfig.BOOKINGS} component={BookingDetailsPage} path={'/bookings/:id'} />
);

const clipsRoutes = gateRoute(
    {
        featureFlag: 'RMS_CLIPS_DEX_INTEGRATION_ENABLED',
        ability: abilitiesEnum.DATA_EXCHANGE.CLIPS_SUBMIT,
    },
    <Route
        {...routesConfig.CLIPS_SEND}
        path="/clips/:entityType/:entityId"
        component={ClipsDashboardContainer}
        onEnter={clipsOnEnter}
    />
);

const briefingsRoutes = gateRoute(
    {
        featureFlag: 'RMS_BRIEFING_ENABLED',
    },
    <>
        <Route
            {...routesConfig.BRIEFINGS_DASHBOARD}
            path={BRIEFINGS_ROUTES.root.path}
            component={BriefingsDashboard}
            onEnter={onBriefingsDashboardEnter}
        />
        <Route
            {...routesConfig.BRIEFING}
            path={BRIEFINGS_ROUTES.item.path}
            component={BriefingPage}
        />
    </>
);

function routerOnUpdate() {
    const name = parseKeyFromRoutes(this.router.routes, 'name', NO_ROUTE_NAME);
    const errorState = globalErrorSelector(store.getState());
    const errorCode = get(errorState, 'errorCode');
    const errorId = get(errorState, 'errorId');
    store.dispatch(enterNewRoute({ params: this.router.params, name }));
    if (errorCode || errorId) {
        store.dispatch(setGlobalError());
    }
}

// React Router Route definitions wrapped in the redux Provider so that the
// store is available to the tree for components that require it (smart
// components). Each `Route` provides an `onEnter` prop which we will use to
// fire any actions (data fetching, or even sync actions) that we want prior
// to navigating to a page. With this architecture, it is up to each component
// to determine how to handle cases where its data is not yet available. Given
// that we will have a finite number of route components, this seems like the
// best way to get the functionality we need and not pigeonhole ourselves in
// to forced shared behaviour between route components.
export const RouterComponent = () => (
    <Router
        history={cobaltHistory}
        onError={(err) => {
            // when a route fails to load, go to the generic error page
            // this error can come from our getComponents() functions among
            // other places
            store.dispatch(redirectToErrorPage({ errorCode: errorCodeEnum.NOT_FOUND }));
            throw err;
        }}
        onUpdate={routerOnUpdate}
    >
        <Route path="/" component={Cobalt}>
            <IndexRoute
                {...routesConfig.PERSONAL_DASHBOARD}
                component={PersonalDashboard}
                onEnter={loginFormOnEnter}
            />
            <Route component={DragonMain} path="dragon" />
            {/* BEGIN APP ROUTES */}
            {protectRoutes(
                <Route>
                    <Route component={ReportsErrorBoundary}>
                        {gateRoute(
                            {
                                ability: abilitiesEnum.REPORTING.VIEW_GENERAL,
                            },
                            <>
                                <Route
                                    {...routesConfig.REPORTS_DASHBOARD}
                                    path="/reports"
                                    component={ReportsDashboardContainer}
                                    onEnter={onReportsEnter}
                                />
                                {gateRoute(
                                    {
                                        featureFlag: 'RMS_REPORT_MODULES_ENABLED',
                                    },
                                    <Route path="/modules/:moduleUrl" component={ReportModule} />
                                )}
                                <Route
                                    {...routesConfig.REACT_REPORT}
                                    path="/reports/:reportId"
                                    component={Report}
                                    onEnter={reportOnEnter}
                                    onLeave={reportOnLeave}
                                >
                                    <IndexRoute component={ReportPage} />
                                    <Route
                                        {...routesConfig.REPORT_HISTORY}
                                        path="history"
                                        component={ReportHistory}
                                    />
                                    <Route
                                        {...routesConfig.REPORT_EXPORTS}
                                        path="exports"
                                        component={ReportExports}
                                    />
                                    {gateRoute(
                                        {
                                            ability: abilitiesEnum.CORE.REDACT_REPORT_EXPORTS,
                                        },
                                        <Route
                                            {...routesConfig.REPORT_EXPORT_REDACT}
                                            path="export/redact"
                                            component={ExportRedactionPage}
                                        />
                                    )}
                                    {gateRoute(
                                        { ability: abilitiesEnum.EDIT_DETENTION_DISPOSITION },
                                        <Route
                                            {...routesConfig.REPORT_DETENTION_DISPOSITION}
                                            path="/reports/:reportId/detention-disposition"
                                            component={DetentionDispositionDashboard}
                                        />
                                    )}
                                </Route>
                                {gateRoute(
                                    {
                                        ability: abilitiesEnum.REPORTING.EXECUTE_COURT_ORDERS,
                                    },
                                    <Route {...routesConfig.RECORD_PRIVACY}>
                                        <Route
                                            path="/reports/:reportId/sealing"
                                            onEnter={reportSealingOnEnter}
                                            component={SealingDashboard}
                                        />
                                        <Route
                                            path="/reports/:reportId/unsealing"
                                            onEnter={reportUnsealingOnEnter}
                                            component={UnsealingDashboard}
                                        />
                                        <Route
                                            path="/reports/:reportId/court-orders"
                                            onEnter={courtOrderEditOnEnter}
                                            component={CourtOrderEditDashboard}
                                        />
                                        <Route
                                            path="/reports/:reportId/charge-vacating"
                                            onEnter={reportChargeVacatingOnEnter}
                                            component={ChargeVacatingDashboard}
                                        />
                                    </Route>
                                )}
                                <Route
                                    {...routesConfig.REACT_REPORT}
                                    path="/reports/:reportId"
                                    component={Report}
                                >
                                    <Route
                                        {...routesConfig.REACT_REPORT_SNAPSHOT}
                                        path="snapshot"
                                        component={ReportPage}
                                        onEnter={reportSnapshotOnEnter}
                                    />
                                </Route>
                            </>
                        )}
                    </Route>
                    {caseDashboardRoutes}
                    {eFileRoutes}
                    {entityProfilesRoutes}
                    {gateRoute(
                        {
                            ability: abilitiesEnum.ADMIN.MERGE_PROFILES,
                        },
                        <Route
                            path="/merge-entities"
                            component={MergeEntitiesDashboard}
                            onEnter={mergeEntitiesOnEnter}
                        />
                    )}
                    <Route
                        {...routesConfig.ADVANCED_SEARCH}
                        path="/search"
                        component={AdvancedSearchContainer}
                    >
                        <Route
                            path=":searchType"
                            component={AdvancedSearch}
                            onEnter={advancedSearchOnEnter}
                        >
                            <Route path="exports" component={AdvancedSearchExport} />
                        </Route>
                    </Route>
                    <Route
                        path="/admin"
                        component={AdminErrorBoundary}
                        onEnter={settingsAdminOnEnter}
                        getIndexRoute={(location, cb) => {
                            import(
                                /* webpackChunkName: "admin-module" */
                                '../modules/admin/settings/components/SettingsAdmin'
                            ).then((component) =>
                                cb(
                                    null,
                                    <Route
                                        {...routesConfig.ADMIN_SETTINGS}
                                        component={component.default}
                                    />
                                )
                            );
                        }}
                        getChildRoutes={(location, cb) => {
                            import(
                                /* webpackChunkName: "admin-module" */
                                '../modules/admin'
                            ).then((routeFactory) => cb(null, routeFactory.default()));
                        }}
                    />
                    {analysisRoutes}
                    {insightsRoutes}
                    {notificationsRoutes}
                    {mobileRoutes}
                    {clipsRoutes}
                    <Route
                        path="/support"
                        getChildRoutes={(location, cb) => {
                            import(
                                /* webpackChunkName: "support-module" */
                                '../modules/support'
                            ).then((routeFactory) => cb(null, routeFactory.default()));
                        }}
                    />
                    <Route {...routesConfig.ERROR} path="/error" component={ErrorPageWrapper} />
                    {evidenceRoutes}
                    {warrantRoutes}
                    {tasksRoutes}
                    {briefingsRoutes}
                    {federatedSearchRoute}
                    {bookingDetailsRoute}
                    {/* ability check is in ComplianceWrapper
                    because there is more than one ability allowed at this route */}
                    <Route path="compliance" component={ComplianceWrapper}>
                        <IndexRedirect to="dashboard" />
                        <Route
                            {...routesConfig.COMPLIANCE_DASHBOARD}
                            path="dashboard"
                            component={ComplianceDashboard}
                        />
                        <Route path=":complianceExportType">
                            <IndexRedirect to="export" />
                            <Route component={ComplianceExportTabsContainer}>
                                <Route
                                    {...routesConfig.COMPLIANCE_TYPE_EXPORT}
                                    path="export"
                                    component={ComplianceExport}
                                />
                                {gateRoute(
                                    {
                                        featureFlag: 'RMS_COMPLIANCE_RESUBMISSION_WORKFLOW_ENABLED',
                                    },
                                    <Route
                                        {...routesConfig.COMPLIANCE_TYPE_RESUBMISSION}
                                        path="resubmission"
                                        component={ComplianceResubmission}
                                    />
                                )}
                            </Route>
                            <Route
                                {...routesConfig.COMPLIANCE_TYPE_EXPORT_HISTORY}
                                path="history"
                                component={ComplianceExportHistory}
                            />
                        </Route>
                    </Route>
                    <Route path="logout" onEnter={logoutOnEnter} />
                </Route>
            )}
            {/* END APP ROUTES */}
        </Route>
        {/* These routes have been relocated, but are not related to the new routing scheme mentioned below */}
        <Redirect from="/analysis" to="/analytics" />
        <Redirect from="/analysis/dashboard" to="/analytics/dashboard" />
        <Redirect from="/admin/nibrs/export" to="/compliance/NIBRS/export" />
        {/* Below this comment are all redirects from our old routing scheme to the new routing scheme */}
        <Redirect from="/dashboard" to="/" />
        <Redirect from="/reporting" to="/reports" />
        <Redirect from="/reporting/report/:id" to="/reports/:id" />
        <Redirect from="/person/:id" to="/persons/:id" />
        <Redirect from="/organization/:id" to="/organizations/:id" />
        <Redirect from="/user/:id" to="/admin/users/:id" />
        <Redirect from="/admin/role" to="/admin/roles" />
        <Redirect from="/admin/warrants/permissions" to="/admin/warrants/configuration" />
        <Redirect from="/admin/role/:id" to="/admin/roles/:id" />
        <Redirect from="/search/v2/:searchType" to="/search/:searchType" />
        <Redirect from="*" to="/error" query={{ errorMessage: 4 }} />
    </Router>
);
