import React from 'react';
import { Fieldset as MFTFieldset } from 'markformythree';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import componentStrings from '~/client-common/core/strings/componentStrings';

import { ArbiterMFTText } from '../../../../../core/forms/components/Text';
import FormRow from '../../../../../core/forms/components/FormRow';
import { ArbiterMFTBooleanSelect } from '../../../../../core/forms/components/selects/BooleanSelect';
import { SidePanelSection } from '../../../../../../legacy-redux/components/core/SidePanel';
import { HelpTextProps } from '../../../../../../legacy-redux/components/core/HelpText';

import { VehicleCautionsFieldset } from './VehicleCautionsFieldset';
import { VehicleLabelsFieldset } from './VehicleLabelsFieldset';

type AdditionalDetailsFieldsetPropsT = {
    helpTextCollisionBoundary: HelpTextProps['collisionBoundary'];
};

const additionalDetailsFieldset = componentStrings.search.fieldsets.AdditionalDetailsFieldset;

export const AdditionalDetailsFieldset: React.FC<AdditionalDetailsFieldsetPropsT> = ({
    helpTextCollisionBoundary,
}) => {
    return (
        <SidePanelSection title={additionalDetailsFieldset.title}>
            <FeatureFlagged
                flag="RMS_VEHICLE_CAUTIONS_ENHANCEMENTS_ENABLED"
                fallback={
                    <FeatureFlagged flag="RMS_VEHICLE_CAUTIONS_ENABLED">
                        <VehicleLabelsFieldset
                            helpTextCollisionBoundary={helpTextCollisionBoundary}
                        />
                    </FeatureFlagged>
                }
            >
                <VehicleCautionsFieldset helpTextCollisionBoundary={helpTextCollisionBoundary} />
            </FeatureFlagged>

            <MFTFieldset path="vehicle">
                <FormRow>
                    <ArbiterMFTText
                        helpTextCollisionBoundary={helpTextCollisionBoundary}
                        path="insuranceProviderName"
                        length="md"
                    />
                    <ArbiterMFTText
                        helpTextCollisionBoundary={helpTextCollisionBoundary}
                        path="insurancePolicyNumber"
                        length="md"
                    />
                </FormRow>
                <FormRow>
                    <ArbiterMFTBooleanSelect
                        helpTextCollisionBoundary={helpTextCollisionBoundary}
                        path="wasAntifreezeInVehicle"
                        length="md"
                    />
                    <ArbiterMFTBooleanSelect
                        helpTextCollisionBoundary={helpTextCollisionBoundary}
                        path="wasRadiatorTaggedAndDrained"
                        length="md"
                    />
                </FormRow>
                <ArbiterMFTText
                    helpTextCollisionBoundary={helpTextCollisionBoundary}
                    path="mileage"
                    length="md"
                />
            </MFTFieldset>
        </SidePanelSection>
    );
};
