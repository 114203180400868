import React, { useState } from 'react';
import { Form, lifecycleOptions, Observer } from 'markformythree';
import { Flex, FormRow, Spacer, VStack } from 'arc';
import { AttributeTypeEnum } from '@mark43/rms-api';

import componentStrings from '~/client-common/core/strings/componentStrings';
import { dateTimeFormats, formatISODate } from '~/client-common/core/dates/utils/dateHelpers';

import { CardSection } from '../../../../../legacy-redux/components/core/Card';
import { ArbiterMFTDatePicker } from '../../../../core/forms/components/DatePicker';
import { ArbiterMFTAttributeSelect } from '../../../../core/forms/components/selects/AttributeSelect';
import { ArbiterMFTCheckbox } from '../../../../core/forms/components/checkboxes/Checkbox';
import Text, { ArbiterMFTText } from '../../../../core/forms/components/Text';

import {
    formName,
    TrafficCrashEventInfoFormConfiguration,
    trafficCrashEventInfoFormConfiguration,
    validationEvents,
} from '../../state/forms/trafficCrashEventInfoForm';

const TrafficCrashEventInfoCardForm = () => {
    const [dayOfWeek, setDayOfWeek] = useState('');
    const strings = componentStrings.reports.core.TrafficCrashEventInfoCard;
    return (
        <Form
            configuration={trafficCrashEventInfoFormConfiguration}
            name={formName}
            lifecycle={lifecycleOptions.REGISTER_AND_RETAIN}
            onValidate={() => {
                return;
            }}
            validationEvents={validationEvents}
            render={() => (
                <CardSection>
                    <VStack alignItems="flex-start" paddingRight={4}>
                        <Flex width="100%">
                            <ArbiterMFTDatePicker includeTime path="crashEventDateUtc" />
                            <Spacer />
                            <Observer<
                                { crashEventDateUtc: string },
                                TrafficCrashEventInfoFormConfiguration
                            >
                                subscriptions={{ crashEventDateUtc: 'crashEventDateUtc' }}
                                render={({ crashEventDateUtc }) => {
                                    if (
                                        typeof crashEventDateUtc !== 'string' ||
                                        isNaN(new Date(crashEventDateUtc).getTime())
                                    ) {
                                        setDayOfWeek('');
                                    } else {
                                        setDayOfWeek(
                                            formatISODate(
                                                crashEventDateUtc,
                                                dateTimeFormats.dayOfWeek
                                            )
                                        );
                                    }
                                    return (
                                        <Text
                                            disabled
                                            value={dayOfWeek}
                                            label={strings.dayOfWeek}
                                            length="md"
                                        />
                                    );
                                }}
                            />
                        </Flex>
                        <ArbiterMFTAttributeSelect
                            length="md"
                            attributeType={AttributeTypeEnum.WEATHER}
                            path="weatherConditionAttrIds"
                        />
                        <FormRow hasIndent>
                            <ArbiterMFTText length="lg" path="weatherConditionOtherDescription" />
                        </FormRow>
                        <ArbiterMFTAttributeSelect
                            length="md"
                            attributeType={AttributeTypeEnum.QC_ROADWAY_SURFACE_CONDITION}
                            path="roadConditionAttrIds"
                        />
                        <FormRow hasIndent>
                            <ArbiterMFTText path="roadConditionOtherDescription" />
                        </FormRow>
                        <ArbiterMFTAttributeSelect
                            length="md"
                            attributeType={AttributeTypeEnum.QC_LIGHT_CONDITION}
                            path="lightConditionAttrIds"
                        />
                        <FormRow hasIndent>
                            <ArbiterMFTText path="lightConditionOtherDescription" />
                        </FormRow>
                        <ArbiterMFTAttributeSelect
                            attributeType={AttributeTypeEnum.QC_CRASH_SEVERITY}
                            path="crashSeverityAttrIds"
                        />
                        <FormRow hasIndent>
                            <ArbiterMFTText path="crashSeverityOtherDescription" />
                        </FormRow>
                        <ArbiterMFTAttributeSelect
                            attributeType={AttributeTypeEnum.CRASH_FIXED_OBJECT_TYPE}
                            path="fixedObjectTypeAttrIds"
                        />
                        <FormRow hasIndent>
                            <ArbiterMFTText path="fixedObjectTypeOtherDescription" />
                        </FormRow>
                        <ArbiterMFTAttributeSelect
                            attributeType={AttributeTypeEnum.QC_FIRST_HARMFUL_EVENT}
                            path="firstHarmfulEventAttrIds"
                        />
                        <FormRow hasIndent>
                            <ArbiterMFTText path="firstHarmfulEventOtherDescription" />
                        </FormRow>
                        <ArbiterMFTAttributeSelect
                            attributeType={AttributeTypeEnum.CRASH_CONTRIBUTING_FACTOR}
                            path="contributingFactorAttrIds"
                        />
                        <FormRow hasIndent>
                            <ArbiterMFTText path="contributingFactorOtherDescription" />
                        </FormRow>
                        <ArbiterMFTAttributeSelect
                            attributeType={AttributeTypeEnum.CRASH_MUNICIPALITY_CODE}
                            path="municipalityCodeAttrIds"
                        />
                        <FormRow hasIndent>
                            <ArbiterMFTText path="municipalityCodeOtherDescription" />
                        </FormRow>
                        <ArbiterMFTCheckbox path="isTemporaryTrafficControl" />
                    </VStack>
                </CardSection>
            )}
        />
    );
};

export default TrafficCrashEventInfoCardForm;
