import { AttributeTypeEnum, RefContextEnum } from '@mark43/rms-api';
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { Observer, lifecycleOptions } from 'markformythree';
import { attributeIsOtherSelector } from '~/client-common/core/domain/attributes/state/data';
import componentStrings from '~/client-common/core/strings/componentStrings';

import { CardSection, CardSubsection } from '../../../../../legacy-redux/components/core/Card';
import { ArbiterMFTAttributeSelect as AttributeSelect } from '../../../../core/forms/components/selects/AttributeSelect';
import { ArbiterMFTBooleanSelect as BooleanSelect } from '../../../../core/forms/components/selects/BooleanSelect';
import { ArbiterMFTDatePicker as DatePicker } from '../../../../core/forms/components/DatePicker';
import { ArbiterMFTText as Text } from '../../../../core/forms/components/Text';
import { ArbiterMFTTextArea as TextArea } from '../../../../core/forms/components/TextArea';
import IndentedFields from '../../../../core/components/IndentedFields';
import ArbiterForm from '../../../../core/markformythree-arbiter/ArbiterForm';
import Row from '../../../../core/components/Row';
import { reasonsForTowIncludeStolenSelector } from '../../state/forms/towVehicleForm';
import testIds from '../../../../../core/testIds';

const strings = componentStrings.reports.core.TowVehicleForm;

function TowVehicleCardForm({
    towedFromLocation,
    reportedStolenLocation,
    outsideRecoveryLocation,
    attributeIsOther,
    reasonsForTowIncludeStolen,
}) {
    return (
        <ArbiterForm
            lifecycle={lifecycleOptions.REGISTER_AND_RETAIN}
            name={RefContextEnum.FORM_TOW_VEHICLE.name}
            render={(form) => (
                <>
                    <CardSection>
                        <Row>
                            <AttributeSelect
                                path="towVehicleStatusAttrId"
                                length="lg"
                                attributeType={AttributeTypeEnum.TOW_VEHICLE_STATUS.name}
                            />
                        </Row>
                    </CardSection>
                    <CardSection
                        title={strings.sections.vehicle}
                        testId={testIds.TOW_VEHICLE_VEHICLE_SECTION}
                    >
                        <Row>
                            <TextArea path="remarksAndConditions" length="lg" />
                        </Row>
                    </CardSection>
                    <CardSection
                        title={strings.sections.towInformation}
                        testId={testIds.TOW_VEHICLE_TOW_INFORMATION_SECTION}
                    >
                        <Row>
                            <AttributeSelect
                                path="towCompanyCalledAttrId"
                                length="lg"
                                attributeType={AttributeTypeEnum.TOW_VEHICLE_TOW_COMPANY.name}
                            />
                        </Row>
                        <Observer
                            subscriptions={{
                                towCompanyCalledAttrId: 'towCompanyCalledAttrId',
                            }}
                            render={({ towCompanyCalledAttrId }) =>
                                attributeIsOther(towCompanyCalledAttrId) ? (
                                    <IndentedFields>
                                        <Row>
                                            <Text path="towCompanyCalledOther" length="lg" />
                                        </Row>
                                    </IndentedFields>
                                ) : null
                            }
                        />
                        <Row>
                            <DatePicker
                                path="towCompanyCalledDateUtc"
                                length="lg"
                                includeTime={true}
                            />
                        </Row>
                        <Row>
                            <DatePicker
                                path="vehicleWasTowedDateUtc"
                                length="md"
                                includeTime={true}
                            />
                        </Row>
                        <Row>{towedFromLocation(form)}</Row>
                        <Row>
                            <AttributeSelect
                                path="reasonsForTow"
                                multiple={true}
                                length="lg"
                                attributeType={AttributeTypeEnum.TOW_VEHICLE_REASON_FOR_TOW.name}
                            />
                        </Row>
                        <Observer
                            subscriptions={{
                                reasonsForTow: 'reasonsForTow',
                            }}
                            render={({ reasonsForTow }) =>
                                reasonsForTowIncludeStolen(reasonsForTow) ? (
                                    <IndentedFields>
                                        <CardSubsection title={strings.sections.stolen}>
                                            <Row>{reportedStolenLocation(form)}</Row>
                                            <Row>
                                                <Text path="originalRen" length="lg" />
                                            </Row>
                                            <Row>
                                                <DatePicker
                                                    path="dateOfTheft"
                                                    length="lg"
                                                    variant={DatePicker.variants.LOCAL_DATE}
                                                />
                                            </Row>
                                            <Row>
                                                <BooleanSelect path="wasLocateSent" length="md" />
                                            </Row>
                                            <Row>
                                                <BooleanSelect
                                                    path="wereImpoundsChecked"
                                                    length="md"
                                                />
                                            </Row>
                                            <Row>
                                                <BooleanSelect path="isImpounded" length="md" />
                                            </Row>
                                            <Row>
                                                <Row>
                                                    <BooleanSelect
                                                        path="wasOutsideRecovery"
                                                        length="md"
                                                    />
                                                </Row>
                                                <Observer
                                                    subscriptions={{
                                                        wasOutsideRecovery: 'wasOutsideRecovery',
                                                    }}
                                                    render={({ wasOutsideRecovery }) =>
                                                        wasOutsideRecovery ? (
                                                            <IndentedFields>
                                                                <Row>
                                                                    <Text
                                                                        path="outsideRecoveryAgency"
                                                                        length="lg"
                                                                    />
                                                                </Row>
                                                                <Row>
                                                                    <Text
                                                                        path="outsideRecoveryAgencyRen"
                                                                        length="lg"
                                                                    />
                                                                </Row>
                                                                <Row>
                                                                    {outsideRecoveryLocation(form)}
                                                                </Row>
                                                            </IndentedFields>
                                                        ) : null
                                                    }
                                                />
                                            </Row>
                                        </CardSubsection>
                                    </IndentedFields>
                                ) : null
                            }
                        />
                        <CardSubsection title={strings.sections.other}>
                            <Row>
                                <Row>
                                    <BooleanSelect path="wasOwnerContactAttempted" length="md" />
                                </Row>
                                <Observer
                                    subscriptions={{
                                        wasOwnerContactAttempted: 'wasOwnerContactAttempted',
                                    }}
                                    render={({ wasOwnerContactAttempted }) =>
                                        wasOwnerContactAttempted ? (
                                            <IndentedFields>
                                                <Row>
                                                    <TextArea path="messageLeftWith" length="lg" />
                                                </Row>
                                            </IndentedFields>
                                        ) : null
                                    }
                                />
                            </Row>
                            <Row>
                                <TextArea path="additionalNotes" length="lg" />
                            </Row>
                        </CardSubsection>
                    </CardSection>
                </>
            )}
        />
    );
}

const mapStateToProps = createStructuredSelector({
    attributeIsOther: attributeIsOtherSelector,
    reasonsForTowIncludeStolen: reasonsForTowIncludeStolenSelector,
});

export default compose(connect(mapStateToProps))(TowVehicleCardForm);
