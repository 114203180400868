import { AttributeTypeEnum, ComplianceGroupEnum, RefContextEnum } from '@mark43/rms-api';
// TODO: Handle legacy entity details.
import { groupBy, map, size } from 'lodash';
import { compose, withPropsOnChange } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

import React from 'react';
import { Form, lifecycleOptions } from 'markformythree';
import { booleanToYesNo } from '~/client-common/helpers/stringHelpers';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import { formatAttributeLinkViewModels } from '~/client-common/core/domain/attributes/state/ui';
import { formatAttributeWithOther } from '~/client-common/core/domain/attributes/utils/attributesHelpers';
import { offenseViewModelByIdSelector } from '~/client-common/core/domain/offenses/state/ui';
import { offenseAttributeViewModelsWhereSelector } from '~/client-common/core/domain/offense-attributes/state/ui';
import * as fields from '~/client-common/core/enums/universal/fields';
import { formatAttributeByIdSelector } from '~/client-common/core/domain/attributes/state/data';
import { offenseSubCrimeLinksByOffenseIdSelector } from '~/client-common/core/domain/offense-sub-crime-links/state/data';
import { formatOffenseCodeByIdSelector } from '~/client-common/core/domain/offense-codes/state/ui';
import componentStrings from '~/client-common/core/strings/componentStrings';

import SummaryRow from '../../../../../legacy-redux/components/summaries/SummaryRow';
import SummaryList from '../../../../../legacy-redux/components/summaries/SummaryList';
import SummaryRowDate from '../../../../../legacy-redux/components/summaries/SummaryRowDate';
import { CardSection, CardSubsection } from '../../../../../legacy-redux/components/core/Card';
import WithComplianceGroup from '../../../../core/components/WithComplianceGroup';
import { abilitiesEnum, OnlyWithAbility } from '../../../../core/abilities';
import testIds from '../../../../../core/testIds';
import { FormConfigurationRenderer } from '../../../../core/markformythree-arbiter/dynamic-fields/components/FormConfigurationRenderer';
import {
    CUSTOM_PROPERTIES_KEY_NAME,
    TNIBRS_PROPERTY_NAME,
} from '../../../../core/markformythree-arbiter/dynamic-fields/constants/constants';

const strings = componentStrings.reports.core.OffenseCard;

const actionAtSceneFieldName = fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_ACTION_AT_SCENE_ATTRIBUTE_ID;
const aggAssaultFieldName =
    fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_AGGRAVATED_ASSAULT_CIRCUMSTANCE_ATTRIBUTE_ID;
const homicideFieldName =
    fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_HOMICIDE_CIRCUMSTANCE_ATTRIBUTE_ID;
const biasMotivationFieldName =
    fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_BIAS_MOTIVATION_ATTRIBUTE_ID;
const criminalActivityCategoryFieldName =
    fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_CRIMINAL_ACTIVITY_CATEGORY_ATTRIBUTE_ID;
const gangInfoFieldName = fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_GANG_INFORMATION_ATTRIBUTE_ID;
const animalCrueltyCategoryFieldName =
    fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_ANIMAL_CRUELTY_CATEGORY_ATTRIBUTE_ID;
const abuseTypeFieldName = fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_ABUSE_TYPE_ATTRIBUTE_ID;
const policeActionFieldName = fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_POLICE_ACTION_ATTRIBUTE_ID;
const weaponOrForceInvolvedFieldName =
    fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_WEAPON_OR_FORCE_INVOLVED_ATTRIBUTE_ID;
const modusOperandiFieldName = fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_MODUS_OPERANDI_ATTRIBUTE_ID;
const contributingFactorsFieldName =
    fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_OFFENDER_CONTRIBUTING_FACTORS_ATTRIBUTE_ID;
const routeOfApproachFieldName =
    fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_ROUTE_OF_APPROACH_ATTRIBUTE_ID;
const typeOfSearchConductedByOffenderFieldName =
    fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_TYPE_OF_SEARCH_CONDUCTED_BY_OFFENDER_ATTRIBUTE_ID;
const implementWeaponInvolvedFieldName =
    fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_IMPLEMENT_WEAPON_INVOLVED_ATTRIBUTE_ID;
const securitySystemFieldName =
    fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_SECURITY_SYSTEM_ATTRIBUTE_ID;
const offenseStatisticFieldName =
    fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_OFFENSE_STATISTIC_ATTRIBUTE_ID;
const larcenyTypeFieldName = fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_LARCENY_TYPE_ATTRIBUTE_ID;
const internetInvolvementTypeFieldName =
    fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_INTERNET_INVOLVEMENT_TYPE_ATTRIBUTE_ID;
const suspectedMetalTheftFieldName =
    fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_SUSPECTED_METAL_THEFT_ATTRIBUTE_ID;
const meansOfApproachFieldName =
    fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_MEANS_OF_APPROACH_ATTRIBUTE_ID;
const methodOfEntryExitFieldName =
    fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_METHOD_OF_ENTRY_EXIT_ATTRIBUTE_ID;
const additionalOtherInfoFieldName =
    fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_ADDITIONAL_OTHER_INFORMATION_ATTRIBUTE_ID;
const vehicleMoFieldName = fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_VEHICLE_MO_ATTRIBUTE_ID;
const honorBasedAbuseTypeFieldName =
    fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_HONOR_BASED_ABUSE_TYPE_ATTRIBUTE_ID;
const pointOfEntryExitFieldName =
    fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_POINT_OF_ENTRY_EXIT_ATTRIBUTE_ID;
const distractionFraudActivityFieldName =
    fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_DISTRACTION_FRAUD_ACTIVITY_ATTRIBUTE_ID;
const safeguardingMoFieldName =
    fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_SAFEGUARDING_MO_ATTRIBUTE_ID;
const ukOffenseCodeCategoryName =
    fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_UK_OFFENSE_CODE_CATEGORY_ATTRIBUTE_ID;
const drugInvolvementFieldName =
    fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_DRUG_INVOLVEMENT_ATTRIBUTE_ID;
const offenseSuspectedAlcoholUseFieldName =
    fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_EXTENDED_OFFENSE_SUSPECTED_ALCOHOL_USE_ATTRIBUTE_ID;
const otherMosFieldName = fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_OTHER_MODUS_OPERANDI_ATTRIBUTE_ID;

const mapStateToProps = createStructuredSelector({
    formatAttributeById: formatAttributeByIdSelector,
    offenseViewModelById: offenseViewModelByIdSelector,
    offenseAttributeViewModelsWhere: offenseAttributeViewModelsWhereSelector,
    offenseSubCrimeLinksByOffenseId: offenseSubCrimeLinksByOffenseIdSelector,
    formatOffenseCodeById: formatOffenseCodeByIdSelector,
});

const mapPropsOnChange = ({
    formatOffenseCodeById,
    offenseId,
    offenseAttributeViewModelsWhere,
    offenseSubCrimeLinksByOffenseId,
    offenseViewModelById,
}) => {
    const offenseViewModel = offenseViewModelById(offenseId);
    const offenseAttributeViewModelsByAttrType = groupBy(
        offenseAttributeViewModelsWhere({ offenseId }),
        'attributeType'
    );

    const subCrimeOffenseCodeIds =
        offenseSubCrimeLinksByOffenseId(offenseId)?.map(({ offenseCodeId }) => offenseCodeId) || [];
    const offenseSubCrimeLinksDisplayString = formatOffenseCodeById({ id: subCrimeOffenseCodeIds });

    return {
        offenseViewModel,
        offenseAttributeViewModelsByAttrType,
        offenseSubCrimeLinksDisplayString,
    };
};

const OffenseCardSummary = compose(
    connect(mapStateToProps),
    withPropsOnChange(
        [
            // `mapStateToProps` props
            'offenseViewModelById',
            'offenseAttributeViewModelsWhere',
            'offenseSubCrimeLinksDisplayString',
            // props passed in
            'offenseId',
        ],
        mapPropsOnChange
    )
)(function _OffenseCardSummary({
    offenseAttributeViewModelsByAttrType,
    offenseViewModel,
    offenseSubCrimeLinksDisplayString,
    formatAttributeById,
    offenseFormIndex,
}) {
    const offenseViewModelProperties = getViewModelProperties(offenseViewModel);
    const formattedOffenseStatisticAttributes = formatAttributeLinkViewModels(
        offenseAttributeViewModelsByAttrType[AttributeTypeEnum.OFFENSE_STATISTIC.name]
    );

    return (
        <CardSection testId={testIds.CARD_SUMMARY}>
            <SummaryList labelWidth={160} contentWidth={380}>
                <SummaryRow fieldName={fields.OFFENSE_OFFENSE_CODE_ID}>
                    {offenseViewModelProperties.offenseCode}
                </SummaryRow>
                <SummaryRow fieldName={fields.OFFENSE_SUB_CRIME_LINK_OFFENSE_CODE_ID}>
                    {offenseSubCrimeLinksDisplayString}
                </SummaryRow>
                <SummaryRow fieldName={fields.DISPLAY_OFFENSE_START_END}>
                    {offenseViewModelProperties.offenseDate}
                </SummaryRow>
                <SummaryRowDate
                    fieldName={fields.CRIME_REPORTED_OFFENSE_DATE_UTC}
                    date={offenseViewModel.crimeReportedOffenseDateUtc}
                />
                <SummaryRowDate
                    fieldName={fields.CRIME_REPORTED_OFFENSE_END_DATE_UTC}
                    date={offenseViewModel.crimeReportedOffenseEndDateUtc}
                />
                <OnlyWithAbility has={abilitiesEnum.REPORTING.NIBRS_CODING}>
                    <SummaryRow fieldName={fields.DISPLAY_ONLY_OFFENSE_NIBRS_CODE_ID}>
                        {offenseViewModelProperties.nibrsOffenseCode}
                    </SummaryRow>
                </OnlyWithAbility>
                <SummaryRow fieldName={ukOffenseCodeCategoryName}>
                    {formatAttributeLinkViewModels(
                        offenseAttributeViewModelsByAttrType[
                            AttributeTypeEnum.UK_OFFENSE_CODE_CATEGORY.name
                        ]
                    )}
                </SummaryRow>
                <OnlyWithAbility has={abilitiesEnum.REPORTING.UCR}>
                    <SummaryRow fieldName={fields.DISPLAY_ONLY_OFFENSE_UCR_SUMMARY_CODE_CODE}>
                        {offenseViewModelProperties.ucrSummaryOffenseCode}
                    </SummaryRow>
                </OnlyWithAbility>
                <SummaryRow fieldName={larcenyTypeFieldName}>
                    {formatAttributeLinkViewModels(
                        offenseAttributeViewModelsByAttrType[AttributeTypeEnum.LARCENY_TYPE.name]
                    )}
                </SummaryRow>
                <WithComplianceGroup
                    complianceGroup={ComplianceGroupEnum.UNITED_KINGDOM.name}
                    fallback={
                        <SummaryRow fieldName={actionAtSceneFieldName}>
                            {formatAttributeLinkViewModels(
                                offenseAttributeViewModelsByAttrType[
                                    AttributeTypeEnum.ACTION_AT_SCENE.name
                                ]
                            )}
                        </SummaryRow>
                    }
                />
                <SummaryRow fieldName={fields.OFFENSE_INCLUDES_CARGO_THEFT}>
                    {booleanToYesNo(offenseViewModel.includesCargoTheft)}
                </SummaryRow>

                <SummaryRow fieldName={fields.OFFENSE_WAS_CARJACKING_INVOLVED}>
                    {booleanToYesNo(offenseViewModel.wasCarjackingInvolved)}
                </SummaryRow>
                <SummaryRow fieldName={fields.OFFENSE_IS_SUSPECTED_CHILD_SEXUAL_ABUSE}>
                    {booleanToYesNo(offenseViewModel.isSuspectedChildSexualAbuse)}
                </SummaryRow>
                <SummaryRow fieldName={fields.OFFENSE_IS_SUSPECTED_CHILD_SEXUAL_EXPLOITATION}>
                    {booleanToYesNo(offenseViewModel.isSuspectedChildSexualExploitation)}
                </SummaryRow>
                <SummaryRow fieldName={fields.OFFENSE_IS_SUSPECTED_CORROSIVE_BASED_OFFENSE}>
                    {booleanToYesNo(offenseViewModel.isSuspectedCorrosiveBasedOffense)}
                </SummaryRow>
                <SummaryRow fieldName={fields.OFFENSE_IS_HONOUR_BASED_OFFENCE}>
                    {booleanToYesNo(offenseViewModel.isHonourBasedOffence)}
                </SummaryRow>
                <SummaryRow fieldName={honorBasedAbuseTypeFieldName}>
                    {formatAttributeLinkViewModels(
                        offenseAttributeViewModelsByAttrType[
                            AttributeTypeEnum.HONOR_BASED_ABUSE_TYPE.name
                        ]
                    )}
                </SummaryRow>
                <SummaryRow fieldName={fields.OFFENSE_IS_SUSPECTED_OFFENSE_AGAINST_WOMEN_OR_GIRLS}>
                    {booleanToYesNo(offenseViewModel.isSuspectedOffenseAgainstWomenOrGirls)}
                </SummaryRow>
                <SummaryRow fieldName={fields.OFFENSE_IS_SUSPECTED_MODERN_SLAVERY}>
                    {booleanToYesNo(offenseViewModel.isSuspectedModernSlavery)}
                </SummaryRow>
                <SummaryRow fieldName={fields.OFFENSE_WAS_VEHICLE_BUMPED_ROBBED}>
                    {booleanToYesNo(offenseViewModel.wasVehicleBumpedRobbed)}
                </SummaryRow>
                <SummaryRow fieldName={fields.OFFENSE_WAS_WITNESS_PRESENT}>
                    {booleanToYesNo(offenseViewModel.wasWitnessPresent)}
                </SummaryRow>
                <SummaryRow fieldName={fields.OFFENSE_WAS_SHOOTING_INVOLVED}>
                    {booleanToYesNo(offenseViewModel.wasShootingInvolved)}
                </SummaryRow>
                <SummaryRow fieldName={fields.OFFENSE_WAS_COMPLETED}>
                    {offenseViewModelProperties.wasCompleted}
                </SummaryRow>
                <SummaryRow fieldName={fields.OFFENSE_ITEM_INVOLVEMENT_TYPE}>
                    {offenseViewModelProperties.itemInvolvementType}
                </SummaryRow>
                <SummaryRow fieldName={fields.OFFENSE_IS_SUSPECTED_HATE_CRIME}>
                    {booleanToYesNo(offenseViewModel.isSuspectedHateCrime)}
                </SummaryRow>
                <SummaryRow fieldName={fields.OFFENSE_BIAS_DESCRIPTION_ATTR_ID}>
                    {offenseViewModel.biasDescriptionAttrId &&
                        formatAttributeWithOther(
                            offenseViewModelProperties.biasDescriptionAttrId,
                            offenseViewModel.biasDescriptionOther,
                            ' - '
                        )}
                </SummaryRow>
                <SummaryRow fieldName={fields.OFFENSE_HATE_CRIME_ACT_ATTR_ID}>
                    {offenseViewModel.hateCrimeActAttrId &&
                        formatAttributeWithOther(
                            offenseViewModelProperties.hateCrimeActAttrId,
                            offenseViewModel.hateCrimeActOther,
                            ' - '
                        )}
                </SummaryRow>
                <SummaryRow fieldName={fields.OFFENSE_EVIDENCE_AVAILABLE_ATTR_ID}>
                    {formatAttributeById(offenseViewModel.evidenceAvailableAttrId)}
                </SummaryRow>
                <SummaryRow fieldName={fields.OFFENSE_OBJECTS_SYMBOLS_PRESENT_ATTR_ID}>
                    {formatAttributeById(offenseViewModel.objectsSymbolsPresentAttrId)}
                </SummaryRow>
                <SummaryRow fieldName={fields.OFFENSE_NUMBER_OF_WEAPONS_INVOLVED}>
                    {offenseViewModel.numberOfWeaponsInvolved}
                </SummaryRow>
                <SummaryRow fieldName={fields.OFFENSE_POLICE_ACTION_ATTR_ID}>
                    {formatAttributeById(offenseViewModel.policeActionAttrId)}
                </SummaryRow>
                <SummaryRow fieldName={biasMotivationFieldName}>
                    {formatAttributeLinkViewModels(
                        offenseAttributeViewModelsByAttrType[AttributeTypeEnum.BIAS_MOTIVATION.name]
                    )}
                </SummaryRow>
                <SummaryRow fieldName={aggAssaultFieldName}>
                    {formatAttributeLinkViewModels(
                        offenseAttributeViewModelsByAttrType[
                            AttributeTypeEnum.AGGRAVATED_ASSAULT_CIRCUMSTANCE.name
                        ]
                    )}
                </SummaryRow>
                <SummaryRow fieldName={fields.OFFENSE_IS_DOMESTIC_VIOLENCE}>
                    {booleanToYesNo(offenseViewModel.isDomesticViolence)}
                </SummaryRow>
                <SummaryRow fieldName={fields.OFFENSE_HAS_PROTECTION_ORDER}>
                    {booleanToYesNo(offenseViewModel.hasProtectionOrder)}
                </SummaryRow>
                <SummaryRow fieldName={fields.OFFENSE_PROTECTION_ORDER_STATUS_ATTR_ID}>
                    {offenseViewModelProperties.protectionOrderStatusAttrId &&
                        formatAttributeWithOther(
                            offenseViewModelProperties.protectionOrderStatusAttrId,
                            offenseViewModel.protectionOrderStatusOther,
                            ' - '
                        )}
                </SummaryRow>
                <SummaryRow fieldName={fields.OFFENSE_PROTECTION_ORDER_TYPE_ATTR_ID}>
                    {offenseViewModelProperties.protectionOrderTypeAttrId &&
                        formatAttributeWithOther(
                            offenseViewModelProperties.protectionOrderTypeAttrId,
                            offenseViewModel.protectionOrderTypeOther,
                            ' - '
                        )}
                </SummaryRow>
                <SummaryRow fieldName={fields.OFFENSE_PROTECTION_ORDER_ISSUING_COURT}>
                    {offenseViewModel.protectionOrderIssuingCourt}
                </SummaryRow>
                <SummaryRow fieldName={fields.OFFENSE_PROTECTION_ORDER_DOCKET_NUMBER}>
                    {offenseViewModel.protectionOrderDocketNumber}
                </SummaryRow>
                <SummaryRow fieldName={fields.OFFENSE_CHILDREN_INVOLVED}>
                    {booleanToYesNo(offenseViewModel.childrenInvolved)}
                </SummaryRow>
                <SummaryRow fieldName={fields.OFFENSE_CHILDREN_PRESENT}>
                    {booleanToYesNo(offenseViewModel.childrenPresent)}
                </SummaryRow>
                <SummaryRow
                    fieldName={fields.DISPLAY_ONLY_OFFENSE_INVOLVED_CHILDREN_N_ITEMS_WRAPPER}
                >
                    {map(offenseViewModelProperties.offenseInvolvedChildren, (child) => (
                        <div key={child}>{child}</div>
                    ))}
                </SummaryRow>
                <SummaryRow fieldName={fields.OFFENSE_PREVIOUS_COMPLAINTS_ATTR_ID}>
                    {offenseViewModelProperties.previousComplaintsAttrId &&
                        formatAttributeWithOther(
                            offenseViewModelProperties.previousComplaintsAttrId,
                            offenseViewModel.previousComplaintsOtherDescription,
                            ' - '
                        )}
                </SummaryRow>
                <SummaryRow fieldName={fields.OFFENSE_PRIOR_COURT_ORDERS_ATTR_ID}>
                    {offenseViewModelProperties.priorCourtOrdersAttrId &&
                        formatAttributeWithOther(
                            offenseViewModelProperties.priorCourtOrdersAttrId,
                            offenseViewModel.priorCourtOrdersOtherDescription,
                            ' - '
                        )}
                </SummaryRow>
                <SummaryRow fieldName={abuseTypeFieldName}>
                    {formatAttributeLinkViewModels(
                        offenseAttributeViewModelsByAttrType[AttributeTypeEnum.ABUSE_TYPE.name]
                    )}
                </SummaryRow>
                <SummaryRow fieldName={policeActionFieldName}>
                    {formatAttributeLinkViewModels(
                        offenseAttributeViewModelsByAttrType[AttributeTypeEnum.POLICE_ACTION.name]
                    )}
                </SummaryRow>
                <SummaryRow fieldName={fields.OFFENSE_REASON_FOR_NO_ARREST_ATTR_ID}>
                    {offenseViewModelProperties.reasonForNoArrestAttrId &&
                        formatAttributeWithOther(
                            offenseViewModelProperties.reasonForNoArrestAttrId,
                            offenseViewModel.reasonForNoArrestOtherDescription,
                            ' - '
                        )}
                </SummaryRow>
                <WithComplianceGroup
                    complianceGroup={ComplianceGroupEnum.UNITED_KINGDOM.name}
                    fallback={
                        <>
                            <SummaryRow fieldName={weaponOrForceInvolvedFieldName}>
                                {formatAttributeLinkViewModels(
                                    offenseAttributeViewModelsByAttrType[
                                        AttributeTypeEnum.WEAPON_OR_FORCE_INVOLVED.name
                                    ]
                                )}
                            </SummaryRow>
                            <SummaryRow fieldName={implementWeaponInvolvedFieldName}>
                                {formatAttributeLinkViewModels(
                                    offenseAttributeViewModelsByAttrType[
                                        AttributeTypeEnum.IMPLEMENT_WEAPON_INVOLVED.name
                                    ]
                                )}
                            </SummaryRow>
                            <SummaryRow fieldName={meansOfApproachFieldName}>
                                {formatAttributeLinkViewModels(
                                    offenseAttributeViewModelsByAttrType[
                                        AttributeTypeEnum.MEANS_OF_APPROACH.name
                                    ]
                                )}
                            </SummaryRow>
                            <SummaryRow fieldName={methodOfEntryExitFieldName}>
                                {formatAttributeLinkViewModels(
                                    offenseAttributeViewModelsByAttrType[
                                        AttributeTypeEnum.METHOD_OF_ENTRY_EXIT.name
                                    ]
                                )}
                            </SummaryRow>
                            <SummaryRow fieldName={pointOfEntryExitFieldName}>
                                {formatAttributeLinkViewModels(
                                    offenseAttributeViewModelsByAttrType[
                                        AttributeTypeEnum.POINT_OF_ENTRY_EXIT.name
                                    ]
                                )}
                            </SummaryRow>
                        </>
                    }
                />
                <SummaryRow fieldName={fields.OFFENSE_BURG_WAS_METHOD_OF_ENTRY_FORCED}>
                    {booleanToYesNo(offenseViewModel.burgWasMethodOfEntryForced)}
                </SummaryRow>
                <SummaryRow fieldName={fields.OFFENSE_ENTRY_POINT_ATTR_ID}>
                    {offenseViewModelProperties.entryPointAttrId}
                </SummaryRow>
                <SummaryRow fieldName={fields.OFFENSE_VEHICLE_ENTRY_POINT_ATTR_ID}>
                    {offenseViewModelProperties.vehicleEntryPointAttrId}
                </SummaryRow>
                <SummaryRow fieldName={fields.OFFENSE_DIRECTION_OF_ENTRY_1_ATTR_ID}>
                    {offenseViewModelProperties.directionOfEntry1AttrId}
                </SummaryRow>
                <SummaryRow fieldName={fields.OFFENSE_DIRECTION_OF_ENTRY_2_ATTR_ID}>
                    {offenseViewModelProperties.directionOfEntry2AttrId}
                </SummaryRow>
                <SummaryRow fieldName={fields.OFFENSE_BURG_NUMBER_OF_PREMISES_ENTERED}>
                    {offenseViewModel.burgNumberOfPremisesEntered}
                </SummaryRow>
                <SummaryRow fieldName={fields.OFFENSE_ARSON_BUILDING_INHABITED}>
                    {offenseViewModelProperties.arsonBuildingInhabited}
                </SummaryRow>
                <SummaryRow fieldName={fields.OFFENSE_JUSTIFIABLE_HOMICIDE_ATTR_ID}>
                    {/* `justifiableHomicideAttrId does not have an `other` */}
                    {offenseViewModelProperties.justifiableHomicideAttrId}
                </SummaryRow>
                <SummaryRow fieldName={fields.OFFENSE_WAS_CRIMINAL_KILLED_BY_OFFICER}>
                    {booleanToYesNo(offenseViewModel.wasCriminalKilledByOfficer)}
                </SummaryRow>
                <SummaryRow fieldName={fields.OFFENSE_NEGLIGENT_MANSLAUGHTER_ATTR_ID}>
                    {/* `negligentManslaughterAttrId does not have an `other` */}
                    {offenseViewModelProperties.negligentManslaughterAttrId}
                </SummaryRow>
                <SummaryRow fieldName={homicideFieldName}>
                    {formatAttributeLinkViewModels(
                        offenseAttributeViewModelsByAttrType[
                            AttributeTypeEnum.HOMICIDE_CIRCUMSTANCE.name
                        ]
                    )}
                </SummaryRow>
                <SummaryRow fieldName={criminalActivityCategoryFieldName}>
                    {formatAttributeLinkViewModels(
                        offenseAttributeViewModelsByAttrType[
                            AttributeTypeEnum.CRIMINAL_ACTIVITY_CATEGORY.name
                        ]
                    )}
                </SummaryRow>
                <SummaryRow fieldName={animalCrueltyCategoryFieldName}>
                    {formatAttributeLinkViewModels(
                        offenseAttributeViewModelsByAttrType[
                            AttributeTypeEnum.ANIMAL_CRUELTY_CATEGORY.name
                        ]
                    )}
                </SummaryRow>
                <SummaryRow fieldName={gangInfoFieldName}>
                    {formatAttributeLinkViewModels(
                        offenseAttributeViewModelsByAttrType[
                            AttributeTypeEnum.GANG_INFORMATION.name
                        ]
                    )}
                </SummaryRow>
                <Form
                    name={RefContextEnum.FORM_OFFENSE.name}
                    index={offenseFormIndex}
                    lifecycle={lifecycleOptions.REGISTER_AND_RETAIN}
                    render={(form) => {
                        return (
                            <FormConfigurationRenderer
                                form={form}
                                absoluteFormPath={`offense.${CUSTOM_PROPERTIES_KEY_NAME}`}
                                mode="SUMMARY"
                                __fieldsToInclude={[
                                    TNIBRS_PROPERTY_NAME.GANG_NAME_1,
                                    TNIBRS_PROPERTY_NAME.TYPE_OF_GANG_1_ATTR_ID,
                                    TNIBRS_PROPERTY_NAME.GANG_NAME_2,
                                    TNIBRS_PROPERTY_NAME.TYPE_OF_GANG_2_ATTR_ID,
                                    TNIBRS_PROPERTY_NAME.IS_USE_OF_FORCE,
                                    TNIBRS_PROPERTY_NAME.USE_OF_FORCE_NUMBER,
                                    TNIBRS_PROPERTY_NAME.IS_HOME_INVASION,
                                    TNIBRS_PROPERTY_NAME.IS_IDENTITY_THEFT,
                                    TNIBRS_PROPERTY_NAME.IS_CYBERCRIME,
                                ]}
                            />
                        );
                    }}
                />
                <SummaryRow fieldName={fields.OFFENSE_HAS_STREET_GANG}>
                    {booleanToYesNo(offenseViewModel.hasStreetGang)}
                </SummaryRow>
                <SummaryRow fieldName={fields.OFFENSE_HAS_OTHER_ORGANIZED_GROUP}>
                    {booleanToYesNo(offenseViewModel.hasOtherOrganizedGroup)}
                </SummaryRow>
                <SummaryRow fieldName={fields.OFFENSE_SUSPECTED_INTERNET_CYBER_INVOLVEMENT_ATTR_ID}>
                    {offenseViewModelProperties.suspectedInternetCyberInvolvementAttrId}
                </SummaryRow>
                <SummaryRow fieldName={fields.OFFENSE_SUSPECTED_COMPUTER_USE}>
                    {booleanToYesNo(offenseViewModel.suspectedComputerUse)}
                </SummaryRow>
                <WithComplianceGroup
                    complianceGroup={ComplianceGroupEnum.UNITED_KINGDOM.name}
                    fallback={
                        <SummaryRow fieldName={internetInvolvementTypeFieldName}>
                            {formatAttributeLinkViewModels(
                                offenseAttributeViewModelsByAttrType[
                                    AttributeTypeEnum.INTERNET_INVOLVEMENT_TYPE.name
                                ]
                            )}
                        </SummaryRow>
                    }
                />
                <SummaryRow fieldName={fields.OFFENSE_SUSPECTED_ALCOHOL_USE}>
                    {booleanToYesNo(offenseViewModel.suspectedAlcoholUse)}
                </SummaryRow>
                <SummaryRow fieldName={offenseSuspectedAlcoholUseFieldName}>
                    {formatAttributeLinkViewModels(
                        offenseAttributeViewModelsByAttrType[
                            AttributeTypeEnum.EXTENDED_OFFENSE_SUSPECTED_ALCOHOL_USE.name
                        ]
                    )}
                </SummaryRow>
                <SummaryRow fieldName={fields.OFFENSE_SUSPECTED_MARIJUANA_USE}>
                    {booleanToYesNo(offenseViewModel.suspectedMarijuanaUse)}
                </SummaryRow>
                <WithComplianceGroup
                    complianceGroup={ComplianceGroupEnum.UNITED_KINGDOM.name}
                    fallback={
                        <SummaryRow fieldName={drugInvolvementFieldName}>
                            {formatAttributeLinkViewModels(
                                offenseAttributeViewModelsByAttrType[
                                    AttributeTypeEnum.DRUG_INVOLVEMENT.name
                                ]
                            )}
                        </SummaryRow>
                    }
                />
                <SummaryRow fieldName={fields.OFFENSE_SUSPECTED_DRUG_USE}>
                    {booleanToYesNo(offenseViewModel.suspectedDrugUse)}
                </SummaryRow>
                <Form
                    name={RefContextEnum.FORM_OFFENSE.name}
                    index={offenseFormIndex}
                    lifecycle={lifecycleOptions.REGISTER_AND_RETAIN}
                    render={(form) => {
                        return (
                            <FormConfigurationRenderer
                                form={form}
                                absoluteFormPath={`offense.${CUSTOM_PROPERTIES_KEY_NAME}`}
                                mode="SUMMARY"
                                __fieldsToInclude={[
                                    TNIBRS_PROPERTY_NAME.IS_DRUG_RELATED,
                                    TNIBRS_PROPERTY_NAME.DRUG_TYPE_ATTR_ID,
                                    TNIBRS_PROPERTY_NAME.DRUG_PRECURSORS_ATTR_ID,
                                    TNIBRS_PROPERTY_NAME.ORIGIN_OF_DRUG_ATTR_ID,
                                ]}
                            />
                        );
                    }}
                />
                <SummaryRow fieldName={fields.OFFENSE_SUSPECTED_DRUG_TYPE_ATTR_ID}>
                    {offenseViewModelProperties.suspectedDrugTypeAttrId}
                </SummaryRow>
                <SummaryRow fieldName={fields.OFFENSE_SUSPECTED_GAMING_ACTIVITY}>
                    {booleanToYesNo(offenseViewModel.suspectedGamingActivity)}
                </SummaryRow>
                <WithComplianceGroup
                    complianceGroup={ComplianceGroupEnum.UNITED_KINGDOM.name}
                    fallback={
                        <>
                            <SummaryRow fieldName={modusOperandiFieldName}>
                                {formatAttributeLinkViewModels(
                                    offenseAttributeViewModelsByAttrType[
                                        AttributeTypeEnum.MODUS_OPERANDI.name
                                    ]
                                )}
                            </SummaryRow>
                            <SummaryRow fieldName={contributingFactorsFieldName}>
                                {formatAttributeLinkViewModels(
                                    offenseAttributeViewModelsByAttrType[
                                        AttributeTypeEnum.OFFENDER_CONTRIBUTING_FACTORS.name
                                    ]
                                )}
                            </SummaryRow>
                            <SummaryRow fieldName={routeOfApproachFieldName}>
                                {formatAttributeLinkViewModels(
                                    offenseAttributeViewModelsByAttrType[
                                        AttributeTypeEnum.ROUTE_OF_APPROACH.name
                                    ]
                                )}
                            </SummaryRow>
                            <SummaryRow fieldName={typeOfSearchConductedByOffenderFieldName}>
                                {formatAttributeLinkViewModels(
                                    offenseAttributeViewModelsByAttrType[
                                        AttributeTypeEnum.TYPE_OF_SEARCH_CONDUCTED_BY_OFFENDER.name
                                    ]
                                )}
                            </SummaryRow>
                            <SummaryRow fieldName={distractionFraudActivityFieldName}>
                                {formatAttributeLinkViewModels(
                                    offenseAttributeViewModelsByAttrType[
                                        AttributeTypeEnum.DISTRACTION_FRAUD_ACTIVITY.name
                                    ]
                                )}
                            </SummaryRow>
                        </>
                    }
                />
                <SummaryRow fieldName={securitySystemFieldName}>
                    {formatAttributeLinkViewModels(
                        offenseAttributeViewModelsByAttrType[AttributeTypeEnum.SECURITY_SYSTEM.name]
                    )}
                </SummaryRow>
                {size(formattedOffenseStatisticAttributes) > 0 && (
                    <SummaryRow fieldName={offenseStatisticFieldName}>
                        {formattedOffenseStatisticAttributes}
                    </SummaryRow>
                )}
                <SummaryRow fieldName={fields.OFFENSE_IS_ANTI_REPRODUCTIVE_RIGHTS_CRIME}>
                    {booleanToYesNo(offenseViewModel.isAntiReproductiveRightsCrime)}
                </SummaryRow>
                <SummaryRow fieldName={suspectedMetalTheftFieldName}>
                    {formatAttributeLinkViewModels(
                        offenseAttributeViewModelsByAttrType[
                            AttributeTypeEnum.SUSPECTED_METAL_THEFT.name
                        ]
                    )}
                </SummaryRow>
                <SummaryRow fieldName={additionalOtherInfoFieldName}>
                    {formatAttributeLinkViewModels(
                        offenseAttributeViewModelsByAttrType[
                            AttributeTypeEnum.ADDITIONAL_OTHER_INFORMATION.name
                        ]
                    )}
                </SummaryRow>
                <WithComplianceGroup
                    complianceGroup={ComplianceGroupEnum.UNITED_KINGDOM.name}
                    fallback={
                        <>
                            <SummaryRow fieldName={vehicleMoFieldName}>
                                {formatAttributeLinkViewModels(
                                    offenseAttributeViewModelsByAttrType[
                                        AttributeTypeEnum.VEHICLE_MO.name
                                    ]
                                )}
                            </SummaryRow>
                            <SummaryRow fieldName={safeguardingMoFieldName}>
                                {formatAttributeLinkViewModels(
                                    offenseAttributeViewModelsByAttrType[
                                        AttributeTypeEnum.SAFEGUARDING_MO.name
                                    ]
                                )}
                            </SummaryRow>
                            <SummaryRow fieldName={otherMosFieldName}>
                                {formatAttributeLinkViewModels(
                                    offenseAttributeViewModelsByAttrType[
                                        AttributeTypeEnum.OTHER_MODUS_OPERANDI.name
                                    ]
                                )}
                            </SummaryRow>
                        </>
                    }
                />
                <WithComplianceGroup complianceGroup={ComplianceGroupEnum.UNITED_KINGDOM.name}>
                    <CardSubsection title={strings.moKeywords}>
                        <OffenseMOKeywordsSummary
                            offenseAttributeViewModelsByAttrType={
                                offenseAttributeViewModelsByAttrType
                            }
                        />
                    </CardSubsection>
                </WithComplianceGroup>
            </SummaryList>
        </CardSection>
    );
});

const OffenseMOKeywordsSummary = ({ offenseAttributeViewModelsByAttrType }) => (
    <>
        <SummaryRow fieldName={actionAtSceneFieldName}>
            {formatAttributeLinkViewModels(
                offenseAttributeViewModelsByAttrType[AttributeTypeEnum.ACTION_AT_SCENE.name]
            )}
        </SummaryRow>
        <SummaryRow fieldName={contributingFactorsFieldName}>
            {formatAttributeLinkViewModels(
                offenseAttributeViewModelsByAttrType[
                    AttributeTypeEnum.OFFENDER_CONTRIBUTING_FACTORS.name
                ]
            )}
        </SummaryRow>
        <SummaryRow fieldName={internetInvolvementTypeFieldName}>
            {formatAttributeLinkViewModels(
                offenseAttributeViewModelsByAttrType[
                    AttributeTypeEnum.INTERNET_INVOLVEMENT_TYPE.name
                ]
            )}
        </SummaryRow>
        <SummaryRow fieldName={distractionFraudActivityFieldName}>
            {formatAttributeLinkViewModels(
                offenseAttributeViewModelsByAttrType[
                    AttributeTypeEnum.DISTRACTION_FRAUD_ACTIVITY.name
                ]
            )}
        </SummaryRow>
        <SummaryRow fieldName={drugInvolvementFieldName}>
            {formatAttributeLinkViewModels(
                offenseAttributeViewModelsByAttrType[AttributeTypeEnum.DRUG_INVOLVEMENT.name]
            )}
        </SummaryRow>
        <SummaryRow fieldName={otherMosFieldName}>
            {formatAttributeLinkViewModels(
                offenseAttributeViewModelsByAttrType[AttributeTypeEnum.OTHER_MODUS_OPERANDI.name]
            )}
        </SummaryRow>
        <SummaryRow fieldName={implementWeaponInvolvedFieldName}>
            {formatAttributeLinkViewModels(
                offenseAttributeViewModelsByAttrType[
                    AttributeTypeEnum.IMPLEMENT_WEAPON_INVOLVED.name
                ]
            )}
        </SummaryRow>
        <SummaryRow fieldName={meansOfApproachFieldName}>
            {formatAttributeLinkViewModels(
                offenseAttributeViewModelsByAttrType[AttributeTypeEnum.MEANS_OF_APPROACH.name]
            )}
        </SummaryRow>
        <SummaryRow fieldName={methodOfEntryExitFieldName}>
            {formatAttributeLinkViewModels(
                offenseAttributeViewModelsByAttrType[AttributeTypeEnum.METHOD_OF_ENTRY_EXIT.name]
            )}
        </SummaryRow>
        <SummaryRow fieldName={pointOfEntryExitFieldName}>
            {formatAttributeLinkViewModels(
                offenseAttributeViewModelsByAttrType[AttributeTypeEnum.POINT_OF_ENTRY_EXIT.name]
            )}
        </SummaryRow>
        <SummaryRow fieldName={routeOfApproachFieldName}>
            {formatAttributeLinkViewModels(
                offenseAttributeViewModelsByAttrType[AttributeTypeEnum.ROUTE_OF_APPROACH.name]
            )}
        </SummaryRow>
        <SummaryRow fieldName={safeguardingMoFieldName}>
            {formatAttributeLinkViewModels(
                offenseAttributeViewModelsByAttrType[AttributeTypeEnum.SAFEGUARDING_MO.name]
            )}
        </SummaryRow>
        <SummaryRow fieldName={modusOperandiFieldName}>
            {formatAttributeLinkViewModels(
                offenseAttributeViewModelsByAttrType[AttributeTypeEnum.MODUS_OPERANDI.name]
            )}
        </SummaryRow>
        <SummaryRow fieldName={typeOfSearchConductedByOffenderFieldName}>
            {formatAttributeLinkViewModels(
                offenseAttributeViewModelsByAttrType[
                    AttributeTypeEnum.TYPE_OF_SEARCH_CONDUCTED_BY_OFFENDER.name
                ]
            )}
        </SummaryRow>
        <SummaryRow fieldName={vehicleMoFieldName}>
            {formatAttributeLinkViewModels(
                offenseAttributeViewModelsByAttrType[AttributeTypeEnum.VEHICLE_MO.name]
            )}
        </SummaryRow>
        <SummaryRow fieldName={weaponOrForceInvolvedFieldName}>
            {formatAttributeLinkViewModels(
                offenseAttributeViewModelsByAttrType[
                    AttributeTypeEnum.WEAPON_OR_FORCE_INVOLVED.name
                ]
            )}
        </SummaryRow>
    </>
);

export default OffenseCardSummary;
