import Promise from 'bluebird';

import reportCardEnum from '~/client-common/core/enums/universal/reportCardEnum';

import { RootState } from '../../../../../legacy-redux/reducers/rootReducer';
import { RmsDispatch } from '../../../../../core/typings/redux';
import createCard from '../../utils/createCard';

const baseCard = createCard({
    anchor: 'traffic-crash-event-info-card',
    baseSelector: (state: RootState) => state.ui.report.trafficCrashEventInfoCard,
    name: reportCardEnum.CRASH_EVENT_INFO.name,
});

export default {
    ...baseCard,
    actionCreators: {
        ...baseCard.actionCreators,
        edit: () => (dispatch: RmsDispatch) =>
            dispatch(baseCard.actionCreators.transitionToEditMode()),
        save: () => (dispatch: RmsDispatch) => {
            dispatch(baseCard.actionCreators.savingSuccess());
            return Promise.resolve();
        },
    },
};
