import _, { map, slice, join, toUpper } from 'lodash';
import moment from 'moment';
import { joinTruthyValues } from '../../../../helpers/stringHelpers';
import { getViewModelProperties } from '../../../../helpers/viewModelHelpers';
import componentStrings from '../../../strings/componentStrings';

export const ssnRegex = /^(?!(000|666|9))\d{3}-(?!00)\d{2}-(?!0000)\d{4}$/; // https://www.ssa.gov/employer/randomization.html

const strings = componentStrings.core.personProfileHelpers;

export const UNKNOWN = strings.unknownName;

/**
 * @param  {Object} personProfile
 * @return {string} Display string.
 */
export function formatFullName({
    title,
    firstName,
    middleName,
    middleNameAnalyzed = '',
    lastName,
    suffix,
}) {
    const middleNameDisplay = middleName || middleNameAnalyzed;
    return joinTruthyValues(
        [
            title,
            nameFragmentOrUnknown(firstName),
            middleNameDisplay,
            nameFragmentOrUnknown(lastName),
            suffix,
        ],
        ' '
    );
}

export function formatShortName({ firstName, lastName } = {}) {
    return joinTruthyValues(
        [nameFragmentOrUnknown(firstName), nameFragmentOrUnknown(lastName)],
        ' '
    );
}

export function formatLastNameFirst({
    firstName,
    lastName,
    middleName,
    middleNameAnalyzed = '',
    suffix,
}) {
    const middleNameDisplay = middleName || middleNameAnalyzed;
    return joinTruthyValues(
        [
            nameFragmentOrUnknown(lastName),
            joinTruthyValues([nameFragmentOrUnknown(firstName), middleNameDisplay, suffix], ' '),
        ],
        ', '
    );
}

export function formatFirstAndLastInitial({ firstName, lastName }) {
    const firstInitial = firstName ? `${toUpper(firstName[0])}.` : '';
    const lastInitial = lastName ? `${toUpper(lastName[0])}.` : '';
    if (!firstName && !lastName) {
        return `${UNKNOWN} ${UNKNOWN}`;
    }
    return firstInitial + lastInitial;
}

export function formatIdentifyingMark(identifyingMarkViewModel) {
    const { identifyingMarkTypeAttrId, bodyPartAttrId } = getViewModelProperties(
        identifyingMarkViewModel
    );
    const { description } = identifyingMarkViewModel;
    return `${identifyingMarkTypeAttrId}${bodyPartAttrId ? `, ${bodyPartAttrId}` : ''}${
        description ? ` - ${description}` : ''
    }`;
}

export function formatPhones(phones) {
    return joinTruthyValues(
        map(
            phones,
            ({ phoneType, displayNumber, isPrimary, dateEffectiveFrom, dateEffectiveTo }) => {
                const isPrimaryDisplay = isPrimary && strings.primary;
                const dateEffectiveFromDisplay =
                    dateEffectiveFrom && `${strings.dateEffectiveFrom} ${dateEffectiveFrom}`;
                const dateEffectiveToDisplay =
                    dateEffectiveTo && `${strings.dateEffectiveTo} ${dateEffectiveTo}`;

                const phoneParameters = joinTruthyValues([
                    isPrimaryDisplay,
                    phoneType,
                    dateEffectiveFromDisplay,
                    dateEffectiveToDisplay,
                ]);

                return `${displayNumber} ${!!phoneParameters ? `(${phoneParameters})` : ''}`;
            }
        )
    );
}

export function formatEmails(emails) {
    return joinTruthyValues(
        map(emails, ({ emailType, emailAddress, dateEffectiveFrom, dateEffectiveTo }) => {
            const dateEffectiveFromDisplay =
                dateEffectiveFrom && `${strings.dateEffectiveFrom} ${dateEffectiveFrom}`;
            const dateEffectiveToDisplay =
                dateEffectiveTo && `${strings.dateEffectiveTo} ${dateEffectiveTo}`;

            const emailParameters = joinTruthyValues([
                emailType,
                dateEffectiveFromDisplay,
                dateEffectiveToDisplay,
            ]);

            return `${emailAddress} ${!!emailParameters ? `(${emailParameters})` : ''}`;
        })
    );
}

/**
 * Format the display string for a NIBRS offense code.
 * @param  {string | null | undefined} code
 * @return {string}
 */
function nameFragmentOrUnknown(nameFragment) {
    // if this part of the name is truthy, then use it
    // otherwise, display the unknown text
    return nameFragment || UNKNOWN;
}

/**
 * @param  {Object[]} attendingPhysicians
 * @return {string} Display string.
 */
export function formatAttendingPhysicians(attendingPhysicians) {
    return _(attendingPhysicians).map('name').compact().join(', ');
}

export function formatDobAgeRange({
    dateOfBirth,
    dateOfBirthRangeStart,
    dateOfBirthRangeEnd,
    dobText = '',
}) {
    if (dateOfBirth) {
        return joinTruthyValues([dateOfBirth, dobText], ' ');
    } else if (dateOfBirthRangeStart || dateOfBirthRangeEnd) {
        return formatAgeRange({ dateOfBirthRangeStart, dateOfBirthRangeEnd });
    } else {
        return '';
    }
}

export function formatAgeRange({ dateOfBirthRangeStart, dateOfBirthRangeEnd }) {
    const ageRangeMin = dateOfBirthRangeEnd ? moment().diff(dateOfBirthRangeEnd, 'years') : null;
    const ageRangeMax = dateOfBirthRangeStart
        ? moment().diff(dateOfBirthRangeStart, 'years')
        : null;

    return `${[ageRangeMin || '?', '-', ageRangeMax || '?'].join(' ')} years old`;
}

export function formatAgeRangeNumbersOnly({
    dateOfReference,
    dateOfBirthRangeStart,
    dateOfBirthRangeEnd,
}) {
    const ageRangeMin = dateOfBirthRangeEnd
        ? moment(dateOfReference).diff(dateOfBirthRangeEnd, 'years')
        : null;
    const ageRangeMax = dateOfBirthRangeStart
        ? moment(dateOfReference).diff(dateOfBirthRangeStart, 'years')
        : null;

    return [ageRangeMin || '?', '-', ageRangeMax || '?'].join(' ');
}

export function calculateAge(dateOfReference, dateOfBirth) {
    return !!dateOfBirth ? moment(dateOfReference).diff(dateOfBirth, 'years') : null;
}

/**
 * formats to Race / Ethnicity
 * @return {String}                         The formatted string
 */
export function formatRaceEthnicity({ raceAttrId, ethnicityAttrId }) {
    return joinTruthyValues([raceAttrId, ethnicityAttrId], ' / ');
}

export function formatPlaceOfBirth(placeOfBirth, birthState, birthStateAbbr) {
    if (placeOfBirth && birthState) {
        return `${placeOfBirth}, ${birthStateAbbr}`;
    } else {
        // if we only have the state, might as well say the whole state
        return placeOfBirth || birthState || '';
    }
}

function getFeet(heightInInches) {
    return Math.floor(heightInInches / 12);
}

function getInches(heightInInches) {
    return heightInInches % 12;
}

/**
 * Format a display string for a person's height given in inches.
 * @param  {number} inches
 * @return {string}
 */
export function formatHeight(inches) {
    if (!_.isNumber(inches)) {
        return '';
    }

    return `${_.floor(inches / 12)}′${inches % 12}″`;
}

export function formatHeightFull({ height, heightRangeMin, heightRangeMax }) {
    let heightDisplay = '';

    const heightFeet = height ? getFeet(height) : '';
    const heightInches = height ? getInches(height) : '';

    const heightMaxFeet = heightRangeMax ? getFeet(heightRangeMax) : '';
    const heightMaxInches = heightRangeMax ? getInches(heightRangeMax) : '';

    const heightMinFeet = heightRangeMin ? getFeet(heightRangeMin) : '';
    const heightMinInches = heightRangeMin ? getInches(heightRangeMin) : '';

    if (height) {
        heightDisplay = `${heightFeet}' ${heightInches}"`;
    }
    // now also add the range
    let minHeight = '';
    let maxHeight = '';
    if (heightMinFeet) {
        minHeight = `${heightMinFeet}' ${heightMinInches}" min`;
    }
    if (heightMaxFeet) {
        maxHeight = `${heightMaxFeet}' ${heightMaxInches}" max`;
    }
    if (minHeight || maxHeight) {
        heightDisplay = height
            ? `${heightDisplay} (${minHeight || '?'} -  ${maxHeight || '?'})`
            : `${minHeight || '?'} -  ${maxHeight || '?'}`;
    }
    return heightDisplay;
}

export function formatWeight(weight) {
    if (!_.isNumber(weight)) {
        return '';
    }
    return `${weight} lbs`;
}

export function formatWeightFull({ weight, weightRangeMin, weightRangeMax }) {
    // if there's no weight, but there is a range, show that
    let weightDisplay = weight ? `${weight} lbs` : '';
    if (weightRangeMin || weightRangeMax) {
        const displayMin = weightRangeMin ? `${weightRangeMin} lbs` : '?';
        const displayMax = weightRangeMax ? `${weightRangeMax} lbs` : '?';
        weightDisplay = weight
            ? `${weightDisplay} (${displayMin} - ${displayMax})`
            : `${displayMin} - ${displayMax}`;
    }
    return weightDisplay;
}

// Check that both exist because if both are undefined, this test will pass
export function isMasterProfile({ masterPersonId, id } = {}) {
    return !!(masterPersonId && id && masterPersonId === id);
}

export function isIdentified({ masterPersonId } = {}) {
    return !!masterPersonId;
}

export function isUnknown({ firstName, lastName } = {}) {
    return !firstName || !lastName;
}

export function isUnknownAndIdentified({ firstName, lastName, masterPersonId } = {}) {
    return isUnknown({ firstName, lastName }) && isIdentified({ masterPersonId });
}

export function buildPersonProfileLink({ masterPersonId }) {
    return masterPersonId ? `/profiles/persons/${masterPersonId}` : null;
}

// Expects an array of strings
export function buildPersonFullName(quickSearchQuery) {
    if (quickSearchQuery.length < 2) {
        return {};
    }

    let firstName;
    let middleName;
    let lastName;
    if (quickSearchQuery[0].charAt(quickSearchQuery[0].length - 1) === ',') {
        // bond, james
        firstName = quickSearchQuery[1];
        lastName = (quickSearchQuery[0] || '').slice(0, -1);
        middleName = join(slice(quickSearchQuery, 2), ' ');
    } else {
        // james bond
        firstName = quickSearchQuery[0];
        lastName = quickSearchQuery[quickSearchQuery.length - 1];
        middleName = join(slice(quickSearchQuery, 1, -1), ' ');
    }
    return {
        firstName,
        middleName,
        lastName,
    };
}
