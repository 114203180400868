import { BulkImportRequest } from '@mark43/rms-api';
import { createResource } from '../../../../../lib/resources/Resource';
import { req } from '../../../../../lib/ajax';

const CAD_BASE_URL = '/cad/api';
const VALIDATE_URL = `bulk/upload/validate`;
const IMPORT_URL = `bulk/upload/import`;

export default createResource({
    name: 'Admin Bulk Imports Resource',
    methods: {
        /**
         * Validate that a file has valid contents for the bulk import type. (RMS/API)
         */
        validateBulkImport(bulkImportRequest: BulkImportRequest) {
            return req({
                method: 'POST',
                url: VALIDATE_URL,
                data: bulkImportRequest,
            });
        },

        validateBulkImportCadAdmin(bulkImportRequest: BulkImportRequest) {
            return req({
                baseUrl: CAD_BASE_URL,
                method: 'POST',
                url: VALIDATE_URL,
                data: bulkImportRequest,
            });
        },

        /**
         * Import contents from validated file. (RMS/API)
         */
        bulkImport(bulkImportRequest: BulkImportRequest) {
            return req({
                method: 'POST',
                url: IMPORT_URL,
                data: bulkImportRequest,
            });
        },

        bulkImportCadAdmin(bulkImportRequest: BulkImportRequest) {
            return req({
                baseUrl: CAD_BASE_URL,
                method: 'POST',
                url: IMPORT_URL,
                data: bulkImportRequest,
            });
        },
    },
});
