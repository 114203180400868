import { RefContextEnum } from '@mark43/rms-api';
import React from 'react';
import { useSelector } from 'react-redux';

import { reportCardTitleByReportIdAndCardIdSelector } from '~/client-common/core/domain/report-definitions/state/data';
import reportCardEnum from '~/client-common/core/enums/universal/reportCardEnum';
import testIds from '../../../../../core/testIds';
import Card from '../../../../../legacy-redux/components/core/Card';
import { RMSArbiterProvider } from '../../../../core/arbiter';
import reportLegacyMetadataCard from '../../state/ui/reportLegacyMetadataCard';
import withCard from '../../utils/withCard';
import ReportLegacyMetadataCardSummary from './ReportLegacyMetadataCardSummary';

const ReportLegacyMetadataCard: React.FC<{ currentReportId: number }> = ({ currentReportId }) => {
    const reportCardTitleByReportIdAndCardId = useSelector(
        reportCardTitleByReportIdAndCardIdSelector
    );

    const cardTitle = reportCardTitleByReportIdAndCardId(
        currentReportId,
        reportCardEnum.LEGACY_INFO.id
    );

    return (
        <Card
            testId={testIds.EVENT_CARD}
            className={'report-legacy-metadata-card'}
            anchor={'report-legacy-metadata-card'}
            title={cardTitle}
            renderContent={(summaryMode) => {
                if (summaryMode) {
                    return <ReportLegacyMetadataCardSummary reportId={currentReportId} />;
                } else {
                    return null;
                }
            }}
            summaryMode={true}
        />
    );
};

const ReportLegacyMetadataCardWrapper: React.FC<{
    currentReportId: number;
}> = (props) => {
    return (
        <RMSArbiterProvider context={RefContextEnum.FORM_REPORT_LEGACY_METADATA.name}>
            {() => <ReportLegacyMetadataCard {...props} />}
        </RMSArbiterProvider>
    );
};

export default withCard(reportLegacyMetadataCard)(ReportLegacyMetadataCardWrapper);
