import React from 'react';
import styled, { withTheme } from 'styled-components';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { formatCaseDefinitionByIdSelector } from '~/client-common/core/domain/case-definitions/state/data/';
import { formatAttributeByIdSelector } from '~/client-common/core/domain/attributes/state/data';
import { allRoleFormatsByRoleIdSelector } from '~/client-common/core/domain/roles/state/data';

import { FormattedDate } from '~/client-common/core/dates/components';
import { useCaseFieldName } from '~/client-common/core/fields/hooks/useFields';
import { CaseApprovalStatusIcon as _CaseApprovalStatusIcon } from '../../../../records/core/components/ApprovalStatusIcon';
import QuickSearchResultSectionItemTitle from '../QuickSearchResultSectionItemTitle';
import Highlighter from '../QuickSearchHighlighter';
import WrappedFormattedDate from '../WrappedFormattedDate';
import FieldDisplay from '../layout/FieldDisplay';
import ExternalDepartmentName from '../ExternalDepartmentName';

import {
    getCaseTitle,
    getCaseAssignedTo,
    getCaseStatus,
} from '../../../../records/core/helpers/caseStringFormatters';

const Wrapper = styled.div`
    padding: 10px 10px 10px 40px;
    position: relative;
`;

const CaseApprovalStatusIcon = styled(_CaseApprovalStatusIcon)`
    position: absolute;
    top: 10px;
    left: 10px;
`;

const CaseDetailsWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
`;

const Seperator = styled.div`
    margin: 0 5px;
    color: ${({ theme }) => theme.colors.mediumLightGrey};
`;

const QuickSearchResultSectionCaseItem = ({
    query,
    item,
    formatAttributeById,
    allRoleFormatsByRoleId,
    formatCaseDefinitionById,
    theme,
    externalDepartmentName,
    testId,
    testType,
}) => {
    const { singularCaseFieldName: caseFieldName } = useCaseFieldName();

    const searchWords = [query];
    const title =
        item.shortTitle ||
        getCaseTitle({
            localId: item.caseNumber || item.localId,
            caseDefinition: formatCaseDefinitionById(item.caseDefinitionId),
            caseFieldName,
        });

    return (
        <Wrapper data-test-id={testId} data-test-type={testType}>
            <CaseApprovalStatusIcon approvalStatus={item.approvalStatus} />
            <QuickSearchResultSectionItemTitle>
                <Highlighter searchWords={searchWords} textToHighlight={title} />{' '}
                <ExternalDepartmentName theme={theme} departmentName={externalDepartmentName} />
            </QuickSearchResultSectionItemTitle>
            <WrappedFormattedDate
                date={item.createdDateUtc}
                format={FormattedDate.FORMATS.FORM_DATE}
            />
            <CaseDetailsWrapper>
                {!externalDepartmentName && (
                    <FieldDisplay theme={theme} width="auto">
                        {getCaseAssignedTo({
                            assigneeRole:
                                item.assigneeRoleId &&
                                allRoleFormatsByRoleId(item.assigneeRoleId)
                                    .fullNameWithFirstInitialAndIdNumber,
                            assignedPersonnelUnit: formatAttributeById(
                                item.assignedPersonnelUnitAttrId
                            ),
                        })}
                    </FieldDisplay>
                )}
                <Seperator>|</Seperator>
                <FieldDisplay theme={theme}>
                    {getCaseStatus({
                        caseStatus: formatAttributeById(item.currentStatusAttrId),
                    })}
                </FieldDisplay>
            </CaseDetailsWrapper>
        </Wrapper>
    );
};

export default compose(
    connect((state) => ({
        formatCaseDefinitionById: formatCaseDefinitionByIdSelector(state),
        formatAttributeById: formatAttributeByIdSelector(state),
        allRoleFormatsByRoleId: allRoleFormatsByRoleIdSelector(state),
    })),
    withTheme
)(QuickSearchResultSectionCaseItem);
