import { AttributeTypeEnum, Vehicle } from '@mark43/rms-api';
import {
    FederatedSearchVehicle,
    FederatedSearchBoat,
    QueryableEntityType,
} from 'mark43-federated-search';
import compact from 'lodash/compact';

import { attributesByTypeSelector } from '~/client-common/core/domain/attributes/state/data';
import {
    searchForVehicleMakes,
    storeVehicleMakes,
    vehicleMakesSelector,
} from '~/client-common/core/domain/vehicle-makes/state/data';
import {
    searchForVehicleModels,
    storeVehicleModels,
    vehicleModelsSelector,
} from '~/client-common/core/domain/vehicle-models/state/data';
import { generateIdNumber } from '~/client-common/helpers/arrayHelpers';
import { ModuleShape } from '~/client-common/redux/state';

import { RmsDispatch } from '../../../../../../core/typings/redux';
import { loadAttributesForType } from '../../../../../core/attributes/state/ui/loadAttributesForType';
import { RootState } from '../../../../../../legacy-redux/reducers/rootReducer';

export type DexItemType = FederatedSearchVehicle | FederatedSearchBoat;

const VEHICLE_MAKES_SIZE = 100;
const VEHICLE_MODELS_SIZE = 100;

const loadAttributes = () => (dispatch: RmsDispatch) => {
    return dispatch(
        loadAttributesForType({
            attributeType: [
                AttributeTypeEnum.STATE.name,
                AttributeTypeEnum.VEHICLE_BODY_STYLE.name,
                AttributeTypeEnum.ITEM_COLOR.name,
            ],
        })
    );
};

const loadAndStoreVehicleMakes = (vehicleMakeNcicCode: string) => {
    return async (dispatch: RmsDispatch) => {
        return dispatch(
            // @ts-expect-error not typed yet
            searchForVehicleMakes({
                from: 0,
                size: VEHICLE_MAKES_SIZE,
                q: vehicleMakeNcicCode,
            })
            // @ts-expect-error  not typed yet
        ).then((vehicleMakes) => {
            if (vehicleMakes.length > 0) {
                dispatch(storeVehicleMakes(vehicleMakes));
            }
        });
    };
};

const loadAndStoreVehicleModels = (vehicleModelNcicCode: string, vehicleMakeId: number) => {
    return async (dispatch: RmsDispatch) => {
        return dispatch(
            // @ts-expect-error not typed yet
            searchForVehicleModels({
                from: 0,
                size: VEHICLE_MODELS_SIZE,
                q: vehicleModelNcicCode,
                vehicleMakeId,
            })
            // @ts-expect-error not typed yet
        ).then(({ vehicleModels }) => {
            if (vehicleModels.length > 0) {
                dispatch(storeVehicleModels(vehicleModels));
            }
        });
    };
};

const mapDexVehicleToRmsVehicle =
    (vehicle: FederatedSearchVehicle) =>
    async (dispatch: RmsDispatch, getState: () => RootState) => {
        if (vehicle.vehicleMakeNcicCode) {
            await dispatch(loadAndStoreVehicleMakes(vehicle.vehicleMakeNcicCode));
        }

        const vehicleMakes = vehicleMakesSelector(getState()) as ModuleShape<{
            ncicCode: string;
            id: number;
        }>;
        const vehicleMakeId = Object.values(vehicleMakes).find(
            (vehicleMake) => vehicleMake.ncicCode === vehicle.vehicleMakeNcicCode
        )?.id;

        if (vehicle.vehicleModelNcicCode && vehicleMakeId) {
            await dispatch(loadAndStoreVehicleModels(vehicle.vehicleModelNcicCode, vehicleMakeId));
        }

        const vehicleModels = vehicleModelsSelector(getState()) as ModuleShape<{
            modelName: string;
            id: number;
        }>;
        const vehicleModelId = Object.values(vehicleModels).find(
            (vehicleModel) => vehicleModel.modelName === vehicle.vehicleModelNcicCode
        )?.id;

        const selectAttributesByType = attributesByTypeSelector(getState());

        const stateAttributes = selectAttributesByType(AttributeTypeEnum.STATE.name);
        const itemColorAttributes = selectAttributesByType(AttributeTypeEnum.ITEM_COLOR.name);

        const registrationStateAttrId = stateAttributes.find(
            (attr) => attr.abbr === vehicle.registrationState
        )?.id;

        const bodyStyleAttributes = selectAttributesByType(
            AttributeTypeEnum.VEHICLE_BODY_STYLE.name
        );
        const bodyStyleAttrId = bodyStyleAttributes.find(
            (attr) => attr.abbr === vehicle.bodyStyle
        )?.id;

        const primaryColorAttrId = itemColorAttributes.find(
            (attr) => attr.abbr === vehicle.vehicleColor
        )?.id;

        const rmsVehicle: Partial<Vehicle> = {
            // Because FederatedSearchVehicle entity doesn't have a
            // unique ID field and all of its fields are partial,
            // we must generate id for the item ourselves.
            // Since uuidv4 creates a unique string we must come up with a
            // custom function which would generate a number.
            id: generateIdNumber(),
            registrationYear: vehicle.registrationYear,
            yearOfManufacture: vehicle.yearOfManufacture,
            tag: vehicle.plateNumber,
            vinNumber: vehicle.vinNumber,
            registrationType: vehicle.registrationType,
            registrationStateAttrId,
            vehicleMakeId,
            vehicleModelId,
            bodyStyleAttrId,
            primaryColorAttrId,
        };

        return rmsVehicle;
    };

const mapDexBoatToRmsVehicle =
    (boat: FederatedSearchBoat) => async (dispatch: RmsDispatch, getState: () => RootState) => {
        const selectAttributesByType = attributesByTypeSelector(getState());

        const stateAttributes = selectAttributesByType(AttributeTypeEnum.STATE.name);

        const registrationStateAttrId = stateAttributes.find(
            (attr) => attr.abbr === boat.registrationState
        )?.id;

        const rmsVehicle: Partial<Vehicle> = {
            // Because FederatedSearchBoat entity doesn't have a
            // unique ID field and all of its fields are partial,
            // we must generate id for the item ourselves.
            // Since uuidv4 creates a unique string we must come up with a
            // custom function which would generate a number.
            id: generateIdNumber(),
            tag: boat.registrationNumber,
            registrationStateAttrId,
        };

        return rmsVehicle;
    };

const isVehicle = (dexItem: DexItemType): dexItem is FederatedSearchVehicle => {
    return dexItem.entityType === QueryableEntityType.VEHICLE;
};

const isBoat = (dexItem: DexItemType): dexItem is FederatedSearchBoat => {
    return dexItem.entityType === QueryableEntityType.BOAT;
};

const mapDexItemToRmsVehicle = (dexItem: DexItemType) => async (dispatch: RmsDispatch) => {
    if (isVehicle(dexItem)) {
        return dispatch(mapDexVehicleToRmsVehicle(dexItem));
    } else if (isBoat(dexItem)) {
        return dispatch(mapDexBoatToRmsVehicle(dexItem));
    } else {
        return undefined;
    }
};

export const mapDexItemsToRmsVehicles = (dexItems: DexItemType[]) => {
    return async (dispatch: RmsDispatch) => {
        await dispatch(loadAttributes());

        const rmsVehicles = await Promise.all(
            dexItems.map((dexItem) => dispatch(mapDexItemToRmsVehicle(dexItem)))
        );

        const rmsVehiclesTruthy = compact(rmsVehicles);

        return rmsVehiclesTruthy;
    };
};
