import PropTypes from 'prop-types';
import { Input as ArcInput, InputProps } from 'arc';
import React from 'react';
import styled from 'styled-components';
import _, { toString } from 'lodash';
import classNames from 'classnames';
import { simpleControl } from 'markformythree';
import keyCodeEnum from '~/client-common/core/enums/client/keyCodeEnum';
import strings from '~/client-common/core/strings/globalStrings';
import { Field } from '~/client-common/core/fields/state/config';
import LegacyButton, { buttonTypes } from '../../../../legacy-redux/components/core/Button';
import { InputLoading as _InputLoading } from '../../../../legacy-redux/components/core/Loading';
import reactReduxFormHelpers from '../../../../legacy-redux/helpers/reactReduxFormHelpers';
import { placeholder, inputFocus } from '../../styles/mixins';
import { arbiterMFTInput } from '../../arbiter';
import { Button } from '../../components/Button';
import testIds from '../../../../core/testIds';
import FormElement, { FormElementWidthOrLengthProps } from './FormElement';

const { connectRRFInput } = reactReduxFormHelpers;

// TNP-328: Remove when DeprecatedText is Removed
const InputLoading = styled(_InputLoading)`
    right: 5px;
`;
const FlexWrapper = styled.div`
    display: flex;
    gap: var(--arc-space-3);
`;
const StyledButton = styled(Button)`
    flex-shrink: 0;
`;
// Future work: remove DeprecatedInput to upgrade to ArcTextArea
export const DeprecatedInput = styled.input`
    border-radius: 4px;
    box-shadow: inset 0 1px 1px ${(props) => props.theme.colors.lightGrey};
    border: 1px solid ${(props) => props.theme.colors.lightGrey};
    padding: 6px 6px 5px;
    color: ${(props) => props.theme.colors.text};
    background-color: ${(props) => props.theme.colors.fieldBg};
    float: left;
    width: 100%;
    box-sizing: border-box;
    outline: none;

    &.fuzzy-matching-enabled {
        background-color: ${(props) => props.theme.colors.lightBlue};
    }

    &.error {
        border: 1px solid ${(props) => props.theme.colors.red};
        box-shadow: none;
    }

    &:focus {
        ${inputFocus};
    }

    /*
     * In case we have styled this with 'withComponent' as a div
     * and want to mimic 'disabled' styling that normally only 'inputs' have
     */
    &.disabled,
    &:disabled {
        background-color: var(--arc-colors-surface-background);
    }
    ${(props) => placeholder(props.theme.colors.placeholder)};
`;

const StyledArcInput = styled(ArcInput)`
    width: 100%;
    &.fuzzy-matching-enabled input {
        background-color: ${(props) => props.theme.colors.lightBlue};
    }
`;

const TextGoButton = styled.div`
    position: absolute;
    margin-top: -11px;
    right: -70px;
    top: 0;
`;

export type ValueType = string | number | undefined;

type BaseTextProps = {
    value?: ValueType;
    alterValue?: (value: string) => string;
    label?: string;
    helpText?: string; // requires a label to also be present
    autoFocus?: boolean;
    isRequired?: boolean;
    fieldName?: Field;
    /**
     * Whether to disable the default behavior of automatically adjusting the cursor (selection) position in the input
     * as the user types. For implementation details, see the other comment below.
     *
     * For typical usages of Text in forms, ignore this prop.
     *
     * If a Text will re-mount while the value persists, set this prop to true. Otherwise, the cursor (selection)
     * position will reset to 0 (the left of the input string) when the component re-mounts.
     */
    autoCursorDisabled?: boolean;

    isPassword?: boolean;
    disabled?: boolean;
    maxLength?: number;
    testId?: string;
    textInputOnly?: boolean;
    placeholder?: string;
    id?: string;

    onClick?: () => void;
    onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    onQueryChange?: (value: ValueType) => void;
    onPressEnter?: (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onChange?: (value: ValueType) => void;
    onFocus?: (value: ValueType) => void;
    onBlur?: (value: ValueType) => void;
    setRef?: (input: HTMLInputElement | HTMLTextAreaElement | null) => void;

    loading?: boolean;
    goButton?: boolean;
    goButtonText?: boolean;

    style?: React.CSSProperties;
    className?: string;

    touched?: boolean;
    error?: boolean;
    textAlign?: 'left' | 'right' | 'center';
    size?: 'sm' | 'md';
    leftAddon?: InputProps['leftAddon'];
    leftIcon?: InputProps['leftIcon'];
    isClearable?: boolean;
};
export type Props = BaseTextProps & FormElementWidthOrLengthProps;

const contextTypes = {
    onInputChange: PropTypes.func,
    onInputFocus: PropTypes.func,
    onInputBlur: PropTypes.func,
};

// Only use for MultiTextInput, all other Texte inputs are ArcInput
class DeprecatedText extends React.Component<Props> {
    componentDidMount() {
        // autofocus is not supported in ie9
        if (this.props.autoFocus) {
            this.focusInput();
        }
    }

    static contextTypes = contextTypes;

    static displayName = 'Text';

    textInput: HTMLInputElement | null = null;

    onChange(e: React.ChangeEvent<HTMLInputElement>) {
        const value = this.props.alterValue
            ? this.props.alterValue(e.target.value)
            : e.target.value;
        // call custom handler
        if (this.props.onChange) {
            this.props.onChange(value);
        }
        // search on type action handler
        if (this.props.onQueryChange) {
            this.props.onQueryChange(value);
        }
        // call context handler
        if (this.context.onInputChange) {
            this.context.onInputChange(value);
        }
    }

    onFocus() {
        if (this.props.onFocus) {
            this.props.onFocus(this.props.value); // call custom handler
        }
        if (this.context.onInputFocus) {
            // call context handler
            this.context.onInputFocus(this.props.value);
        }
    }

    onBlur() {
        if (this.props.onBlur) {
            this.props.onBlur(this.props.value); // call custom handler
        }
        if (this.context.onInputBlur) {
            // call context handler
            this.context.onInputBlur(this.props.value);
        }
    }

    focusInput() {
        if (this.textInput) {
            this.textInput.focus();
        }
    }

    handleKeyPress(e: React.KeyboardEvent<HTMLInputElement>) {
        if (e.keyCode === keyCodeEnum.ENTER && this.props.onPressEnter) {
            this.props.onPressEnter(e);
        } else {
            this.props.onKeyDown?.(e);
        }
    }

    getInputRef = () => {
        return this.textInput;
    };

    setInputRef = (input: HTMLInputElement | null) => {
        this.textInput = input;
        if (this.props.setRef) {
            this.props.setRef(input);
        }
    };

    render() {
        const style = {
            width: _.isNumber(this.props.width) ? `${this.props.width}px` : this.props.width,
            ...this.props.style,
        };

        const classes = classNames(
            'mark43-form-text',
            {
                error: this.props.touched && this.props.error,
            },
            this.props.className
        );

        const valIsEmpty =
            _.isNull(this.props.value) ||
            _.isUndefined(this.props.value) ||
            this.props.value === '';

        if (this.props.textInputOnly) {
            return (
                <div className="mark43-form-element element-only" style={style}>
                    <DeprecatedInput
                        className={classes}
                        ref={this.setInputRef}
                        type={this.props.isPassword ? 'password' : 'text'}
                        value={valIsEmpty ? '' : this.props.value}
                        onChange={this.onChange.bind(this)}
                        placeholder={this.props.placeholder}
                        onFocus={this.onFocus.bind(this)}
                        onBlur={this.onBlur.bind(this)}
                        onKeyDown={this.handleKeyPress.bind(this)}
                        disabled={this.props.disabled}
                        maxLength={this.props.maxLength}
                        autoFocus={this.props.autoFocus}
                        data-test-id={this.props.testId || testIds.TEXT_INPUT}
                        id={this.props.id}
                    />
                </div>
            );
        } else {
            return (
                <FormElement {...this.props}>
                    <DeprecatedInput
                        className={classes}
                        ref={this.setInputRef}
                        type={this.props.isPassword ? 'password' : 'text'}
                        value={valIsEmpty ? '' : this.props.value}
                        onChange={this.onChange.bind(this)}
                        placeholder={this.props.placeholder}
                        onFocus={this.onFocus.bind(this)}
                        onBlur={this.onBlur.bind(this)}
                        onKeyDown={this.handleKeyPress.bind(this)}
                        disabled={this.props.disabled}
                        maxLength={this.props.maxLength}
                        autoFocus={this.props.autoFocus}
                        // we allow specific test ids to be provided if this is useful, otherwise
                        // fall back to a default
                        data-test-id={testIds.TEXT_INPUT}
                        id={this.props.id}
                    />
                    {this.props.loading && <InputLoading />}
                    {this.props.goButton && (
                        <TextGoButton>
                            <LegacyButton
                                className={buttonTypes.SMALL_GO}
                                onClick={this.props.onClick}
                                disabled={this.props.disabled}
                                testId={testIds.GENERIC_TEXT_GO_BUTTON}
                            >
                                {this.props.goButtonText || strings.goButtonText}
                            </LegacyButton>
                        </TextGoButton>
                    )}
                </FormElement>
            );
        }
    }
}
function TextAsArcText(
    props: Props,
    context: {
        onInputChange?: (value: ValueType) => void;
        onInputFocus?: (value: ValueType) => void;
        onInputBlur?: (value: ValueType) => void;
    }
) {
    const inputRef = React.useRef<HTMLInputElement | HTMLTextAreaElement | null>(null);

    const cursorRef = React.useRef(0);
    React.useEffect(() => {
        /**
         * Reset the cursor position in the input to be the correct position from state.
         *
         * When the user types a character in the input, if the Arc Input component re-renders with the old value before
         * re-rendering again with the new value, then the cursor unexpectedly jumps to the end of the input. Setting
         * the cursor position here prevents that problem.
         *
         * Notes:
         * - This issue happens only with RRFText, which connects Arc Input to RRF state.
         * - This issue does not happen with RRF + DeprecatedText.
         * - This issue does not happen with MFT + Arc Input.
         * - This issue would not happen with uncontrolled inputs. All our input components are controlled.
         * - While we should fix any re-rendering problems, this is a safety check on top of that.
         */
        const input = inputRef.current;
        const current = cursorRef.current;
        if (!props.autoCursorDisabled && input) {
            input.setSelectionRange(current, current);
        }
    }, [props.autoCursorDisabled, props.value]);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (typeof e.target.selectionStart === 'number') {
            // remember cursor position
            cursorRef.current = e.target.selectionStart;
        }

        const value = props.alterValue ? props.alterValue(e.target.value) : e.target.value;
        // call custom handler
        props.onChange?.(value);

        // search on type action handler
        props.onQueryChange?.(value);

        // call context handler
        context.onInputChange?.(value);
    };
    const onFocus = () => {
        props.onFocus?.(props.value); // call custom handler

        // call context handler
        context.onInputFocus?.(props.value);
    };
    const onBlur = () => {
        props.onBlur?.(props.value); // call custom handler

        // call context handler
        context.onInputBlur?.(props.value);
    };

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.keyCode === keyCodeEnum.ENTER && props.onPressEnter) {
            props.onPressEnter(e);
        } else {
            props.onKeyDown?.(e);
        }
    };
    const setInputRef = (input: HTMLInputElement | HTMLTextAreaElement | null) => {
        inputRef.current = input;
        props.setRef?.(input);
    };

    const style = {
        width: _.isNumber(props.width) ? `${props.width}px` : props.width,
        textAlign: 'textAlign' in props ? props.textAlign : 'center',
        ...props.style,
    };

    const error = props.touched && props.error;

    if (props.textInputOnly) {
        return (
            <div className="mark43-form-element element-only" style={style}>
                <StyledArcInput
                    {...props}
                    isInvalid={error}
                    ref={setInputRef}
                    type={props.isPassword ? 'password' : 'text'}
                    // Input should always be controlled
                    value={toString(props.value)}
                    isDisabled={props.disabled}
                    data-test-id={props.testId || testIds.TEXT_INPUT}
                    isLoading={props.loading}
                    onKeyDown={handleKeyPress}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onChange={onChange}
                />
            </div>
        );
    } else {
        return (
            <FormElement {...props}>
                <FlexWrapper>
                    <StyledArcInput
                        {...props}
                        isInvalid={error}
                        ref={setInputRef}
                        type={props.isPassword ? 'password' : 'text'}
                        // Input should always be controlled
                        value={toString(props.value)}
                        onKeyDown={handleKeyPress}
                        isDisabled={props.disabled}
                        // we allow specific test ids to be provided if this is useful, otherwise
                        // fall back to a default
                        data-test-id={testIds.TEXT_INPUT}
                        isLoading={props.loading}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        onChange={onChange}
                    />
                    {props.goButton && (
                        <StyledButton
                            style={{ flexShrink: 0 }}
                            isTextTransformNone
                            onClick={props.onClick}
                            disabled={props.disabled}
                            testId={testIds.GENERIC_TEXT_GO_BUTTON}
                        >
                            {props.goButtonText || strings.goButtonText}
                        </StyledButton>
                    )}
                </FlexWrapper>
            </FormElement>
        );
    }
}

TextAsArcText.contextTypes = contextTypes;

function DepText(props: Props) {
    return <DeprecatedText {...props} />;
}

export default function Text(props: Props) {
    return <TextAsArcText {...props} />;
}

// @ts-expect-error client-common to client RND-7529
export const RRFText = connectRRFInput(Text);
export const ArbiterMFTText = arbiterMFTInput(Text);
export const MFTText = simpleControl(Text);
// DeprecatedArbiterMFTText and DeprecatedMFTText are only used for MultiTextInput ( input within input not release yet)
// When ARC releases the component, please delete the lines below and all usage should use arc
export const DeprecatedArbiterMFTText = arbiterMFTInput(DepText);
export const DeprecatedMFTText = simpleControl(DepText);
