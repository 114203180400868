import {
    DashboardAreaEnum,
    DashboardSectionEnum,
    EntityTypeEnum,
    ProductModuleEnum,
    UsageActionEnum,
    UsageCompletionEnum,
    UsageSourceModuleEnum,
    DashboardSettingsView,
    DashboardSectionEnumType,
} from '@mark43/rms-api';

import React, { useReducer } from 'react';
import styled from 'styled-components';
import { Stack, mediaQueries } from 'arc';
import { useDispatch } from 'react-redux';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import _ from 'lodash';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import componentStrings from '~/client-common/core/strings/componentStrings';
import ProductModuled from '~/client-common/core/domain/product-modules/components/ProductModuled';
import { Button } from '../../core/components/Button';
import testIds from '../../../core/testIds';
import { abilitiesEnum, OnlyWithAbility } from '../../core/abilities';
import { createUsageLog } from '../../admin/usage-logs/state/data';
import personalDashboardResource from '../resources/personalDashboardResource';
import {
    defaultSettings,
    fillDashboardSidePanel,
} from '../state/forms/personalDashboardSettingsForm';
import withDraggable from '../drag-and-drop/withDraggable';
import { RmsDispatch } from '../../../core/typings/redux';
import { useViewport } from '../../core/utils/useViewport';
import { useScreenBreakpoint } from '../../core/utils/useScreenBreakpoint';

import { BaseScrollableUnderSubheader } from '../../core/components/ScrollableUnderSubheader';
import Subheader from '../../core/components/Subheader';
import { PageContent } from '../../core/components/PageContent';
import Page from '../../core/components/Page';
import RecentlyViewed from './RecentlyViewed';
import RecentSavedSearches from './RecentSavedSearches';
import RecentAlerts from './RecentAlerts';
import ActionRequiredReports from './ActionRequiredReports';
import RecentArrests from './RecentArrests';
import ExternalLinks from './ExternalLinks';
import MyCadEvents from './MyCadEvents';
import MyTasks from './MyTasks';
import RecentWarrants from './RecentWarrants';
import ItemsPendingReview from './ItemsPendingReview';
import { DashboardSettingsSidePanel } from './DashboardSettingsSidePanel';
import { DraggableMyDashboardCases } from './MyDashboardCases';
import { MyWarrants } from './MyWarrants';
import { MyBriefings } from './MyBriefings';
import TargetProfilesDashboardCard from './TargetProfilesDashboardCard';

const LeftColumn = styled.div`
    flex: 1;
    min-width: 0;
    display: flex;
    flex-direction: column;
    gap: var(--arc-space-6);

    @media (min-width: ${mediaQueries.lg}) {
        flex: 0 1 33%;
        min-width: 20rem;
    }
`;

const RightColumn = styled.div`
    flex: 1;
    min-width: 0;
`;

const StyledPageContent = styled(PageContent)`
    background-color: var(--arc-colors-surface-canvas);
    border: none;
    padding-top: var(--arc-space-2);

    @media (min-width: ${mediaQueries.md}) {
        padding-top: var(--arc-space-8);
    }
`;
const strings = componentStrings.personalDashboard;

type PersonalDashboardState = {
    settings: DashboardSettingsView | undefined;
    loading?: boolean;
    sidePanelErrorMessage?: string;
};

const PersonalDashboard = () => {
    useViewport();
    const { isMobile } = useScreenBreakpoint();

    const initialState = {
        settings: undefined,
        loading: true,
        sidePanelErrorMessage: '',
    };

    const [state, reducerDispatch] = useReducer(
        (state: PersonalDashboardState, action: PersonalDashboardState) => ({
            ...state,
            ...action,
        }),
        initialState
    );

    const dispatch = useDispatch<RmsDispatch>();
    const createPersonalDashUsageLog = React.useCallback(
        () =>
            dispatch(
                createUsageLog({
                    action: UsageActionEnum.VIEWED_PERSONAL_DASHBOARD.name,
                    primaryEntityType: EntityTypeEnum.REPORT.name, // chosen randomly, per Stein
                    completion: UsageCompletionEnum.SUCCEEDED.name,
                    sourceModule: UsageSourceModuleEnum.RMS_GENERAL.name,
                })
            ),
        [dispatch]
    );

    const filterAndGetSections = (
        sectionSettings:
            | DashboardSettingsView['cardSections']
            | DashboardSettingsView['sidebarSections']
    ) => {
        return _.reject(sectionSettings, (setting) => setting.isHidden).map(({ section }, index) =>
            mapSectionToComponent(section, index)
        );
    };

    const onDragEnd = (result: DropResult) => {
        const { source, destination, draggableId: dashboardSectionId } = result;

        if (!destination) {
            return;
        }

        if (source.droppableId !== destination.droppableId) {
            return;
        }

        const { settings } = state;

        const settingsKey =
            destination.droppableId === DashboardAreaEnum.LEFT_SIDEBAR.name
                ? 'sidebarSections'
                : 'cardSections';

        const sections = settings ? settings[settingsKey] : [];

        const visibleSections = _.filter(sections, (section) => !section.isHidden);

        // Get the item that is being swapped from visible sections.
        const draggedDashboardItem = _.find(sections, (el) => el.section === dashboardSectionId);
        const swappedItem = visibleSections[destination.index];

        const sourceIndex = _.findIndex(sections, (el) => el.section === dashboardSectionId);

        const destinationIndex = _.findIndex(sections, { section: swappedItem.section });

        const newSections = [...sections];

        newSections.splice(sourceIndex, 1);

        if (draggedDashboardItem) {
            newSections.splice(destinationIndex, 0, draggedDashboardItem);
        }

        /*
            Refactoring the whole dashboard to reflect the ordinals is more than
            a Hackathon week. This is the happy medium for now.
        */
        const updatedOrdinals = _.map(newSections, (section, index) => {
            const returnValue = {
                ...section,
                ordinal: `${index + 1}`,
            };
            return returnValue;
        });

        const hasLayoutChanged = !_.isEqual(updatedOrdinals, newSections);

        if (hasLayoutChanged && settings) {
            const newSettings = {
                ...settings,
                [settingsKey]: updatedOrdinals,
            };

            reducerDispatch({
                settings: newSettings,
            });

            personalDashboardResource.upsertSettings(newSettings);
        }
    };

    const mapSectionToComponent = (section?: DashboardSectionEnumType, index?: string | number) => {
        switch (section) {
            case DashboardSectionEnum.ACTION_REQUIRED_REPORTS.name:
                return withDraggable(
                    DashboardSectionEnum.ACTION_REQUIRED_REPORTS.name,
                    index
                )(ActionRequiredReports);
            case DashboardSectionEnum.MY_CAD_EVENTS.name:
                return (
                    <OnlyWithAbility
                        has={abilitiesEnum.CORE.SEARCH_GENERAL}
                        key={DashboardSectionEnum.MY_CAD_EVENTS.name}
                    >
                        {withDraggable(DashboardSectionEnum.MY_CAD_EVENTS.name, index)(MyCadEvents)}
                    </OnlyWithAbility>
                );

            case DashboardSectionEnum.ITEMS_PENDING_REVIEW.name:
                return (
                    <ProductModuled
                        productModule={ProductModuleEnum.EVIDENCE.name}
                        key={DashboardSectionEnum.ITEMS_PENDING_REVIEW.name}
                    >
                        {withDraggable(
                            DashboardSectionEnum.ITEMS_PENDING_REVIEW.name,
                            index
                        )(ItemsPendingReview)}
                    </ProductModuled>
                );
            case DashboardSectionEnum.RECENT_ARRESTS.name:
                return withDraggable(
                    DashboardSectionEnum.RECENT_ARRESTS.name,
                    index
                )(RecentArrests);

            case DashboardSectionEnum.MY_TASKS.name:
                return (
                    <FeatureFlagged
                        key={DashboardSectionEnum.MY_TASKS.name}
                        flag="RMS_TASK_AND_REQUEST_TRACKING_ENABLED"
                    >
                        <OnlyWithAbility has={abilitiesEnum.CORE.VIEW_NON_CASE_TASKS}>
                            {withDraggable(DashboardSectionEnum.MY_TASKS.name, index)(MyTasks)}
                        </OnlyWithAbility>
                    </FeatureFlagged>
                );
            case DashboardSectionEnum.MY_CASES.name:
                return (
                    <OnlyWithAbility
                        has={abilitiesEnum.CORE.SEARCH_GENERAL}
                        key={DashboardSectionEnum.MY_CASES.name}
                    >
                        {withDraggable(
                            DashboardSectionEnum.MY_CASES.name,
                            index
                        )(DraggableMyDashboardCases)}
                    </OnlyWithAbility>
                );
            case DashboardSectionEnum.RECENT_WARRANTS.name:
                return (
                    <FeatureFlagged
                        flag="RMS_WARRANT_CARDS_ENABLED"
                        key={DashboardSectionEnum.RECENT_WARRANTS.name}
                    >
                        <OnlyWithAbility has={abilitiesEnum.CORE.VIEW_GENERAL}>
                            {withDraggable(
                                DashboardSectionEnum.RECENT_WARRANTS.name,
                                index
                            )(RecentWarrants)}
                        </OnlyWithAbility>
                    </FeatureFlagged>
                );
            case DashboardSectionEnum.MY_WARRANTS.name:
                return (
                    <FeatureFlagged
                        flag="RMS_WARRANT_CARDS_ENABLED"
                        key={DashboardSectionEnum.MY_WARRANTS.name}
                    >
                        <OnlyWithAbility has={abilitiesEnum.CORE.SEARCH_GENERAL}>
                            {withDraggable(
                                DashboardSectionEnum.MY_WARRANTS.name,
                                index
                            )(MyWarrants)}
                        </OnlyWithAbility>
                    </FeatureFlagged>
                );
            case DashboardSectionEnum.MY_BRIEFINGS.name:
                return (
                    <FeatureFlagged
                        flag="RMS_BRIEFING_ENABLED"
                        key={DashboardSectionEnum.MY_BRIEFINGS.value}
                    >
                        {withDraggable(DashboardSectionEnum.MY_BRIEFINGS.name, index)(MyBriefings)}
                    </FeatureFlagged>
                );

            case DashboardSectionEnum.TARGET_PROFILES.name:
                return (
                    <FeatureFlagged
                        flag="RMS_TARGET_PROFILES_ENABLED"
                        key={DashboardSectionEnum.TARGET_PROFILES.name}
                    >
                        {withDraggable(
                            DashboardSectionEnum.TARGET_PROFILES.name,
                            index
                        )(TargetProfilesDashboardCard)}
                    </FeatureFlagged>
                );

            // LEFT SECTION CARDS
            case DashboardSectionEnum.RECENTLY_VIEWED.name:
                return withDraggable(
                    DashboardSectionEnum.RECENTLY_VIEWED.name,
                    index
                )(RecentlyViewed);
            case DashboardSectionEnum.EXTERNAL_LINKS.name:
                return (
                    <FeatureFlagged
                        flag="RMS_PERSONAL_DASHBOARD_V2_EXTERNAL_LINKS"
                        key={DashboardSectionEnum.EXTERNAL_LINKS.name}
                    >
                        <>
                            {withDraggable(
                                DashboardSectionEnum.EXTERNAL_LINKS.name,
                                index
                            )(ExternalLinks)}
                        </>
                    </FeatureFlagged>
                );
            case DashboardSectionEnum.RECENT_SAVED_SEARCHES.name:
                return (
                    <OnlyWithAbility
                        has={abilitiesEnum.CORE.SEARCH_GENERAL}
                        key={DashboardSectionEnum.RECENT_SAVED_SEARCHES.name}
                    >
                        {withDraggable(
                            DashboardSectionEnum.RECENT_SAVED_SEARCHES.name,
                            index
                        )(RecentSavedSearches)}
                    </OnlyWithAbility>
                );
            case DashboardSectionEnum.RECENT_ALERTS.name:
                return (
                    <OnlyWithAbility
                        has={abilitiesEnum.NOTIFICATIONS.CORE}
                        key={DashboardSectionEnum.RECENT_ALERTS.name}
                    >
                        {withDraggable(
                            DashboardSectionEnum.RECENT_ALERTS.name,
                            index
                        )(RecentAlerts)}
                    </OnlyWithAbility>
                );

            default:
                return;
        }
    };

    const saveSettingsSuccess = (settings: DashboardSettingsView) => {
        reducerDispatch({
            settings,
        });
    };

    React.useEffect(() => {
        createPersonalDashUsageLog();
        personalDashboardResource
            .getSettings({
                hideLoadingBar: true,
            })
            .then((settings) => {
                reducerDispatch({
                    settings,
                    loading: false,
                });
            })
            .catch((error) => {
                reducerDispatch({
                    settings: defaultSettings,
                    loading: false,
                    sidePanelErrorMessage: error.message,
                });
            });
        // We only want this to run once to get initial state, so leaving off the dependency array.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { settings } = state;

    const leftSidebarComponents = settings && filterAndGetSections(settings.sidebarSections);
    const rightCardsComponents = settings && filterAndGetSections(settings.cardSections);

    const dashboard = (
        <OnlyWithAbility has={abilitiesEnum.CORE.VIEW_GENERAL}>
            <Stack direction={{ base: 'column-reverse', lg: 'row' }} gap="var(--arc-space-6)">
                <LeftColumn>
                    <Droppable
                        droppableId={DashboardAreaEnum.LEFT_SIDEBAR.name}
                        type={DashboardAreaEnum.LEFT_SIDEBAR.name}
                    >
                        {(provided) => {
                            return (
                                <Stack
                                    spacing="var(--arc-space-6)"
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                >
                                    {leftSidebarComponents}
                                    {provided.placeholder}
                                </Stack>
                            );
                        }}
                    </Droppable>
                    <OnlyWithAbility has={abilitiesEnum.CORE.EDIT_PERSONAL_DASHBOARD}>
                        <DashboardSettingsSidePanel
                            userLayoutSettings={state.settings}
                            saveSettingsSuccess={saveSettingsSuccess}
                            initialErrorMessage={state.sidePanelErrorMessage}
                            renderButton={({
                                overlayBase: { open },
                                setCloseFocusRefs,
                            }: {
                                overlayBase: { open: () => void };
                                setCloseFocusRefs: () => void;
                            }) => (
                                <Button
                                    leadingVisual="Settings"
                                    isTextTransformNone
                                    testId={testIds.PERSONAL_DASHBOARD_SETTINGS_BUTTON}
                                    onClick={() => {
                                        fillDashboardSidePanel(state.settings);
                                        open();
                                    }}
                                    ref={setCloseFocusRefs}
                                >
                                    {strings.settings}
                                </Button>
                            )}
                        />
                    </OnlyWithAbility>
                </LeftColumn>
                <RightColumn>
                    <Droppable
                        droppableId={DashboardAreaEnum.RIGHT_CARDS.name}
                        type={DashboardAreaEnum.RIGHT_CARDS.name}
                    >
                        {(provided) => {
                            return (
                                <Stack
                                    spacing="var(--arc-space-6)"
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                >
                                    {rightCardsComponents}
                                    {provided.placeholder}
                                </Stack>
                            );
                        }}
                    </Droppable>
                </RightColumn>
            </Stack>
        </OnlyWithAbility>
    );

    return (
        <Page>
            {isMobile && <Subheader title={componentStrings.core.navigation.dashboard} />}
            <BaseScrollableUnderSubheader>
                <StyledPageContent fullscreen>
                    <DragDropContext onDragEnd={onDragEnd}>
                        {!state.loading && dashboard}
                    </DragDropContext>
                </StyledPageContent>
            </BaseScrollableUnderSubheader>
        </Page>
    );
};
export default PersonalDashboard;
