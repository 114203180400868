import { createSelector } from 'reselect';
import { keys, mapValues, omit, size } from 'lodash';
import { useSelector } from 'react-redux';

import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import {
    DISPLAY_ONLY_E_FILE,
    DISPLAY_ONLY_NAME_OF_EVIDENCE_MODULE,
    DISPLAY_ONLY_PHOTO_LINEUP,
    DISPLAY_ONLY_OFFENSE,
} from '~/client-common/core/enums/universal/fields';
import { prettify } from '~/client-common/helpers/stringHelpers';
import { RootState } from '../legacy-redux/reducers/rootReducer';

// unique string for each route
const routesConfig = {
    ADMIN_AGENCY_PROFILES: {
        title: 'Agencies',
    },
    ADMIN_AGENCY_PROFILE_NEW: {
        title: 'New Agency',
    },
    ADMIN_ALERTS: {
        title: 'Manage Alerts',
    },
    ADMIN_ATTRIBUTES: {
        title: 'Attributes',
    },
    ADMIN_ATTRIBUTES_ATTRIBUTE: {
        title: 'Manage Attributes',
    },
    ADMIN_ATTRIBUTES_ATTRIBUTE_TYPE: {
        title: 'Manage Attributes',
    },
    ADMIN_ATTRIBUTES_ATTRIBUTE_TYPE_HISTORY: {
        title: 'Attribute History',
    },
    ADMIN_ATTRIBUTES_LEGACY: {
        title: 'Manage Attributes',
    },
    ADMIN_ATTRIBUTES_NEW_ATTRIBUTE: {
        title: 'New Attribute',
    },
    ADMIN_ATTRIBUTE_TYPE_CODE_TYPE_LINKS: {
        title: 'Attribute Code Types',
    },
    ADMIN_RELATIONSHIP_TYPES_CONFIGURATION: {
        title: 'Relationship Types',
    },
    ADMIN_BULK_ENTITY_PERMISSIONS: {
        title: 'Bulk Update Permissions',
    },
    ADMIN_BULK_ENTITY_PERMISSIONS_EDIT: {
        title: 'Bulk Update Permissions',
    },
    ADMIN_CAD_DEBUG_REPORTS: {
        title: 'CAD Debug Reports',
    },
    ADMIN_CAD_DEBUG_REPORT: {
        title: 'CAD Debug Report',
    },
    ADMIN_CAD_CONTACT_LISTS: {
        title: 'CAD Contact Lists',
    },
    ADMIN_CAD_UNITS: {
        title: 'Units',
    },
    ADMIN_CAD_UNIT_BULK_LOGON: {
        title: 'Log On Bulk Units',
    },
    ADMIN_CAD_UNITS_NEW: {
        title: 'New CAD Unit',
    },
    ADMIN_CAD_ALL_UNITS_HISTORY: {
        title: 'All Units History',
    },
    ADMIN_CAD_UNITS_HISTORY: {
        title: 'Units History',
    },
    ADMIN_CAD_UNITS_UNIT: {
        title: 'Manage CAD Unit',
    },
    ADMIN_CAD_MESSAGING_AUDIT: {
        title: 'Messaging Audit',
    },
    ADMIN_CAD_RAPID_SOS: {
        title: 'RapidSOS',
    },
    ADMIN_CAD_BOLO_AUDIT: {
        title: 'BOLO Notification Audit',
    },
    ADMIN_CAD_SERVICE_ROTATION_AUDIT: {
        title: 'Service Rotation Audit',
    },
    ADMIN_CAD_CALL_TAKER_STATIONS: {
        title: 'Call Taker Stations',
    },
    ADMIN_CAD_CALL_TAKER_STATION_NEW: {
        title: 'New CAD Call Taker Station',
    },
    ADMIN_CAD_ALL_CALL_TAKER_STATIONS_HISTORY: {
        title: 'All Call Taker Stations History',
    },
    ADMIN_CAD_CALL_TAKER_STATIONS_HISTORY: {
        title: 'Call Taker Stations History',
    },
    ADMIN_CAD_DESKTOP_CONFIGS: {
        title: 'Desktop Configs',
    },
    ADMIN_CAD_DESKTOP_CONFIGS_CONFIG: {
        title: 'Manage Desktop Configs',
    },
    ADMIN_CAD_DESKTOP_CONFIGS_NEW: {
        title: 'New CAD Desktop Config',
    },
    ADMIN_CAD_ALL_DESKTOP_CONFIGS_HISTORY: {
        title: 'All Desktop Configs History',
    },
    ADMIN_CAD_DESKTOP_CONFIGS_HISTORY: {
        title: 'Desktop Configs History',
    },
    ADMIN_CAD_DESKTOP_CONFIGS_DESKTOP_CONFIG: {
        title: 'Manage CAD Desktop Config',
    },
    ADMIN_CAD_MESSAGING_GROUPS: {
        title: 'Messaging Groups',
        newname: 'New Group',
    },
    ADMIN_CAD_MESSAGING_GROUPS_NEW: {
        title: 'New Messaging Group',
    },
    ADMIN_CAD_SERVICE_ROTATIONS: {
        title: 'Service Rotations',
    },
    ADMIN_CAD_SERVICE_ROTATIONS_NEW: {
        title: 'New CAD Service Rotation Rotation',
        name: 'New',
    },
    ADMIN_CAD_SERVICE_ROTATIONS_NEW_LOCATION: {
        title: 'New Location',
    },
    ADMIN_CAD_SERVICE_ROTATION_LIST_ENTRYS_NEW_LOCATION: {
        title: 'New Location',
    },
    ADMIN_CAD_ALL_SERVICE_ROTATIONS_HISTORY: {
        title: 'All Service Rotations History',
    },
    ADMIN_CAD_SERVICE_ROTATIONS_HISTORY: {
        title: 'Service Rotations History',
    },
    ADMIN_CAD_SERVICE_ROTATIONS_SERVICE_ROTATION: {
        title: 'Manage CAD Service Rotation',
    },
    ADMIN_CAD_SERVICE_PROVIDERS: {
        title: 'Service Rotation Provider',
    },
    ADMIN_CAD_SERVICE_PROVIDERS_NEW: {
        title: 'New CAD Service Rotation Provider',
    },
    ENHANCED_ADMIN_CAD_SERVICE_PROVIDERS_NEW: {
        title: 'New CAD Service Rotation Provider',
    },
    ADMIN_CAD_ALL_SERVICE_PROVIDERS_HISTORY: {
        title: 'ALL CAD Service Rotation Provider',
    },
    ADMIN_CAD_SERVICE_PROVIDERS_SERVICE_PROVIDER: {
        title: 'Service Rotation Provider',
    },
    ENHANCED_ADMIN_CAD_SERVICE_PROVIDERS_SERVICE_PROVIDER: {
        title: 'Service Rotation Provider',
    },
    ADMIN_CAD_SERVICE_PROVIDERS_SERVICE_TYPE: {
        title: 'Service Rotation Provider',
    },

    ADMIN_CAD_SERVICE_TYPES: {
        title: 'Service Rotation Types',
    },
    ADMIN_CAD_SERVICE_TYPES_NEW: {
        title: 'New CAD Service Rotation Type',
        name: 'New',
    },
    ADMIN_CAD_ALL_SERVICE_TYPES_HISTORY: {
        title: 'All Service Rotation Types History',
    },
    ADMIN_CAD_SERVICE_TYPES_HISTORY: {
        title: 'Service Rotation Types History',
        name: 'New',
    },
    ADMIN_CAD_SERVICE_TYPES_SERVICE_TYPE: {
        title: 'Manage CAD Service Rotation Type',
    },

    ADMIN_CAD_ROTATION_LISTS: {
        title: 'Service Rotation Lists',
    },
    ADMIN_CAD_ROTATION_LISTS_HISTORY: {
        title: 'Service Rotation List History',
    },
    ADMIN_CAD_ROTATION_LISTS_SERVICE_TYPE: {
        title: 'Service Rotation Lists Service Type',
    },
    ADMIN_CAD_ROTATION_LISTS_ROTATION_LIST: {
        title: 'Service Rotation Lists Rotation List',
    },
    ADMIN_CAD_ROTATION_LISTS_NEW_SUB_PREMISE: {
        title: 'Service Rotation Lists New Sub-Premise',
    },

    ADMIN_CONSORTIUM: {
        title: 'Manage Consortium',
    },
    ADMIN_CONSORTIUM_EDIT: {
        title: 'Edit Consortium',
    },
    ADMIN_CONSORTIUM_NEW: {
        title: 'Add Consortium',
    },
    ADMIN_CONSORTIUM_ADD_DEPARTMENT: {
        title: 'Add Department',
    },
    ADMIN_CONSORTIUM_EDIT_DEPARTMENT: {
        title: 'Edit Department',
    },
    ADMIN_SHORTCUT_KEYS: {
        title: 'Shortcut Keys',
    },
    ADMIN_SHORTCUT_KEYS_NEW: {
        title: 'New Shortcut Key',
    },
    ADMIN_STREET_ALIASES: {
        title: 'Street Aliases',
    },
    ADMIN_STREET_ALIASES_NEW: {
        title: 'New Street Alias',
    },
    ADMIN_STREET_ALIAS: {
        title: 'Manage Street Alias',
    },
    ADMIN_STREET_SEGMENTS: {
        title: 'Street Segments',
    },
    ADMIN_STREET_SEGMENTS_NEW: {
        title: 'New Street Segment',
    },
    ADMIN_STREET_SEGMENT: {
        title: 'Manage Street Segment',
    },
    ADMIN_CALL_FOR_SERVICE: {
        title: 'Call For Service Types',
    },
    ADMIN_CALL_FOR_SERVICE_EDIT: {
        title: 'Edit Call For Service',
    },
    ADMIN_CALL_FOR_SERVICE_NEW: {
        title: 'New Call For Service',
    },
    ADMIN_ALL_CALL_FOR_SERVICES_HISTORY: {
        title: 'All Call For Services History',
    },
    ADMIN_CALL_FOR_SERVICE_HISTORY: {
        title: 'Call For Service History',
    },
    ADMIN_HOSPITAL_EDIT: {
        title: 'Hospitals',
    },
    ADMIN_MASTER_ENTITIES: {
        title: 'Default Permissions',
    },
    ADMIN_MASTER_ENTITIES_ENTITY: {
        title: 'Manage Master Entity',
    },
    ADMIN_DATA_SOURCES: {
        title: 'Data Sources',
    },
    ADMIN_GLOBAL_DATA_SOURCES: {
        title: 'Global Data Sources Manager',
    },
    ADMIN_QUERY_FORMS: {
        title: 'Query Forms',
    },
    ADMIN_DEX_PDF_TEMPLATES: {
        title: 'DEx PDFs',
    },
    ADMIN_FEDERATED_SEARCH_DEVICES: {
        title: 'DEx Devices',
    },
    ADMIN_DEX_LOG: {
        title: 'DEx Log',
    },
    PERSON_DEDUPLICATION_DASHBOARD: {
        title: 'Person Deduplication',
    },
    ADMIN_WARRANT_CONFIGURATION: {
        title: 'Warrant Configuration',
    },
    ADMIN_WARRANT_ACTIVITY_MAPPINGS: {
        title: 'Warrant Activity Mappings',
    },
    ADMIN_CASE_DEFINITIONS: {
        title: 'Case Types',
    },
    ADMIN_CASE_DEFINITIONS_DEFINITION: {
        title: 'Manage Case Types',
    },
    ADMIN_CASE_DEFINITIONS_NEW: {
        title: 'New Case Type',
    },
    ADMIN_CASE_PRINTING_TEMPLATES: {
        title: 'Case Printing Config',
    },
    ADMIN_CASE_PRINTING_TEMPLATES_NEW: {
        title: 'Create New Case Template Config',
    },
    ADMIN_CASE_PRINTING_TEMPLATES_TEMPLATE: {
        title: 'Case Printing Config',
    },
    ADMIN_CUSTOM_REPORTS: {
        title: 'Report Types',
    },
    ADMIN_CUSTOM_REPORTS_NEW: {
        title: 'New Custom Report',
    },
    ADMIN_REPORT_MODULES: {
        title: 'Report Modules',
    },
    ADMIN_DEPARTMENT_PROFILE: {
        title: 'Department Profile',
    },
    ADMIN_DEPARTMENT_RESET: {
        title: 'Department Reset',
    },
    ADMIN_DEPARTMENT_SUBDOMAIN_UPDATE: {
        title: 'Department Subdomain Update',
    },
    ADMIN_DEPARTMENT_SETUP: {
        title: 'Department Setup',
    },
    ADMIN_DEX_DEVICES: {
        title: 'DEX Devices',
    },
    ADMIN_EDIT_ROLE: {
        title: 'Edit Role',
    },
    ADMIN_EVENT_LABELS: {
        title: 'Event Labels',
    },
    ADMIN_EVENT_LABELS_EDIT: {
        title: 'Edit Label',
    },
    ADMIN_EVENT_LABELS_NEW: {
        title: 'New Label',
    },
    ADMIN_ALL_EVENT_LABELS_HISTORY: {
        title: 'All Event Labels History',
    },
    ADMIN_EVENT_LABEL_HISTORY: {
        title: 'Event Label History',
    },
    ADMIN_REQUEST_ASSISTANCE_PROFILES: {
        title: 'Request Assistance Profiles',
    },
    ADMIN_REQUEST_ASSISTANCE_PROFILES_EDIT: {
        title: 'Edit Request Assistance Profile',
    },
    ADMIN_REQUEST_ASSISTANCE_PROFILES_NEW: {
        title: 'New Request Assistance Profile',
    },
    ADMIN_ALL_REQUEST_ASSISTANCE_PROFILES_HISTORY: {
        title: 'All Request Assistance Profiles History',
    },
    ADMIN_REQUEST_ASSISTANCE_PROFILE_HISTORY: {
        title: 'Request Assistance Profile History',
    },
    ADMIN_DEFAULT_LOCATION_SETTINGS_AND_REGIONAL_PREFERENCES: {
        title: 'Default Location Settings and Regional Preferences',
    },
    ADMIN_EXPORT_PRESETS: {
        title: 'Export Presets',
    },
    ADMIN_EXPORT_PRESETS_NEW: {
        title: 'New Export Preset',
    },
    ADMIN_EXPORT_RELEASE_SETTINGS: {
        title: 'Export Release Settings',
    },
    ADMIN_EVIDENCE_CONFIGURATION: {
        title: (evidenceModuleName: string) => `${prettify(evidenceModuleName)} Configuration`,
    },
    ADMIN_EVIDENCE_RETENTION_POLICIES: {
        title: 'Retention Policies',
    },
    ADMIN_EVIDENCE_RETENTION_POLICY_NEW: {
        title: 'New Retention Policy',
    },
    ADMIN_EVIDENCE_RETENTION_POLICY: {
        title: 'Retention Policy',
    },
    ADMIN_EVIDENCE_LOCATIONS: {
        title: 'Storage Locations',
    },
    ADMIN_EVIDENCE_PRINTING_TEMPLATES: {
        title: (evidenceModuleName: string) => `${prettify(evidenceModuleName)} Documents`,
    },
    ADMIN_EVIDENCE_CHAIN_EVENT_TYPES: {
        title: 'Chain Event Types',
    },
    ADMIN_EVIDENCE_PRINTING_TEMPLATE_NEW: {
        title: (evidenceModuleName: string) => `New ${prettify(evidenceModuleName)} Document`,
    },
    ADMIN_EVIDENCE_PRINTING_TEMPLATE: {
        title: (evidenceModuleName: string) => `${prettify(evidenceModuleName)} Document`,
    },
    ADMIN_EXTERNAL_LINKS: {
        title: 'External Links',
    },
    ADMIN_EXTERNAL_LINKS_NEW: {
        title: 'New External Link',
    },
    ADMIN_FIELD_CONFIGURATION: {
        title: 'Fields',
    },
    ADMIN_LOCATION_CAUTIONS: {
        title: 'Location Cautions',
    },
    ADMIN_POINT_LOCATION: {
        title: 'Point Locations',
    },
    ADMIN_LOCATION_CAUTIONS_HISTORY: {
        title: 'Location Caution History',
    },
    ADMIN_LOCATION_CAUTIONS_SUB_PREMISE: {
        title: 'Location Cautions Sub-Premise',
    },
    ADMIN_LOCATION_CAUTIONS_NEW_SUB_PREMISE: {
        title: 'Location Cautions New Sub-Premise',
    },
    ADMIN_GEO_CAUTIONS: {
        title: 'Location Cautions',
    },
    ADMIN_GEO_CAUTIONS_IMPORT_VALIDATION: {
        title: 'Location Cautions',
    },
    ADMIN_LOCATION_ALIASES: {
        title: 'Location Aliases',
    },
    ADMIN_LOCATION_ALIASES_ALIAS: {
        title: 'Location Aliases',
    },
    ADMIN_LOCATION_ALIASES_NEW_ALIAS: {
        title: 'New Location Alias',
    },
    ADMIN_GEO_ALIASES: {
        title: 'Location Aliases',
    },
    ADMIN_GEO_ALIASES_ALIAS: {
        title: 'Location Aliases',
    },
    ADMIN_GEO_ALIASES_NEW_ALIAS: {
        title: 'New Location Alias',
    },
    ADMIN_LOCATION_CAUTIONS_NEW_LOCATION: {
        title: 'New Location',
    },
    ADMIN_LOGIN_BACKGROUND: {
        title: 'Change Login Background',
    },
    ADMIN_NARRATIVE_GUIDES: {
        title: 'Guides',
    },
    ADMIN_NARRATIVE_GUIDES_NEW: {
        title: 'New Guide',
    },
    ADMIN_NIBRS_WORKSPACE: {
        title: 'NIBRS Workspace',
    },
    ADMIN_NIBRS_EXPORT_HISTORY: {
        title: 'NIBRS Export History',
    },
    ADMIN_OFFENSE_CODES: {
        title: (offenseDisplayName: string) => `${offenseDisplayName} Codes`,
    },
    ADMIN_OFFENSE_CODE: {
        title: (offenseDisplayName: string) => `${offenseDisplayName} Code`,
    },
    ADMIN_OFFENSE_CODE_NEW: {
        title: (offenseDisplayName: string) => `New ${offenseDisplayName} Code`,
    },
    ADMIN_RIPA_OFFENSE_CODES: {
        title: 'RIPA Codes Update',
    },
    ADMIN_RADIO_CHANNELS: {
        title: 'Radio Channels',
    },
    ADMIN_RADIO_CHANNEL_EDIT: {
        title: 'Radio Channel',
    },
    ADMIN_ALL_RADIO_CHANNELS_HISTORY: {
        title: 'All Radio Channels History',
    },
    ADMIN_RADIO_CHANNEL_HISTORY: {
        title: 'Radio Channel History',
    },
    ADMIN_RADIO_CHANNEL_NEW: {
        title: 'New Radio Channel',
    },
    ADMIN_PREFERENCES: {
        title: 'Preferences',
    },
    ADMIN_ERROR_REPORTS: {
        title: 'Error Reports',
    },
    ADMIN_COMMAND_LINE: {
        title: 'Command Line',
    },
    ADMIN_COMMAND_LINE_NEW: {
        title: 'New Command Line Argument',
    },
    ADMIN_DISPATCH_AREAS: {
        title: 'Dispatch Areas',
    },
    ADMIN_DISPATCH_AREAS_NEW: {
        title: 'New Dispatch Area',
    },
    ADMIN_DUPLICATE_EVENT_SEARCH: {
        title: 'Duplicate Event Search',
    },
    ADMIN_MENTIONS_CONFIGURATION: {
        title: 'Narrative Mentions Configuration',
    },
    ADMIN_PRINTING_TEMPLATES: {
        title: 'Printing Templates Admin',
    },
    ADMIN_PRINTING_TEMPLATES_NEW: {
        title: 'Create New Printing Template',
    },
    ADMIN_PRINTING_TEMPLATE: {
        title: 'Printing Templates Admin',
    },
    ADMIN_PRODUCT_MODULES: {
        title: 'Product Modules Admin',
    },
    ADMIN_SUBMISSION_MESSAGE_TYPES: {
        title: 'Submission Messages',
    },
    ADMIN_REPORT_PRINTING_TEMPLATES: {
        title: 'Report Printing Config',
    },
    ADMIN_REPORT_PRINTING_TEMPLATES_NEW: {
        title: 'Create New Report Template Config',
    },
    ADMIN_REPORT_PRINTING_TEMPLATES_TEMPLATE: {
        title: 'Report Printing Config',
    },
    ADMIN_ROLES: {
        title: 'Roles',
        hasLegacySubheader: true,
    },
    ADMIN_RULES: {
        title: 'Rules',
    },
    ADMIN_SECURITY_IPS: {
        title: 'Department IP Addresses',
    },
    ADMIN_SETTINGS: {
        title: 'Admin Settings',
    },
    ADMIN_SHAPEFILES: {
        title: 'Shapefiles',
    },
    ADMIN_STREET_CENTERLINES: {
        title: 'Street Centerlines',
    },
    ADMIN_SOCIETY_PROFILES: {
        title: 'Society Profiles',
    },
    ADMIN_SOCIETY_PROFILE_NEW: {
        title: 'New Society Profile',
    },
    ADMIN_STATIONS: {
        title: 'Stations',
    },
    ADMIN_STATIONS_NEW: {
        title: 'New Station',
    },
    ADMIN_SUBDIVISIONS: {
        title: 'Subdivisions',
    },
    ADMIN_GEO_SUBDIVISIONS: {
        title: 'Subdivisions',
    },
    ADMIN_USAGE_LOGS: {
        title: 'Usage Logs',
    },
    ADMIN_USERS: {
        title: 'Users',
        hasLegacySubheader: true,
    },
    ADMIN_USER_DISABLING: {
        title: 'Mass User Disabling',
    },
    ADMIN_USER_PROFILE: {
        title: 'User Profile',
    },
    ADMIN_VEHICLE_MAKE_MODEL: {
        title: 'Vehicle Makes and Models',
    },
    ADVANCED_SEARCH: {
        title: 'Advanced Search',
    },
    ADVANCED_SEARCH_PERSONS: {
        title: 'Advanced Persons Search',
    },
    ADVANCED_SEARCH_REPORTS: {
        title: 'Advanced Reports Search',
    },
    ADVANCED_SEARCH_BOOKING: {
        title: 'Advanced Booking Search',
    },
    ANALYSIS: {
        title: 'Analytics',
    },
    CASES: {
        title: 'Cases',
    },
    CASE_ASSOCIATED_RECORDS: {
        title: 'Case Associated Records',
    },
    CASE_ATTACHMENTS: {
        title: 'Case Attachments',
    },
    CASE_EXPORTS: {
        title: 'Case Exports',
    },
    CASE_HISTORY: {
        title: 'Case History',
    },
    CASE_LINKED_PROFILES: {
        title: 'Case Linked Profiles',
    },
    CASE_E_FILES: {
        title: (efilesFieldName: string) => `Case ${prettify(efilesFieldName)}`,
    },
    CASE_NOTES: {
        title: 'Case Notes',
    },
    CASE_PHOTO_LINEUPS: {
        title: (photoLineUpFieldName: string) => `Case ${prettify(photoLineUpFieldName)}`,
    },
    CASE_SUMMARY: {
        title: 'Case Summary',
    },
    CASE_TASK_LIST: {
        title: 'Case Task List',
    },
    MY_CASES: {
        title: 'My Cases',
    },
    MY_CASES_EXPORTS: {
        title: 'My Cases Bulk Exports',
    },
    ALL_CASES: {
        title: 'All Cases',
    },
    ALL_CASES_EXPORTS: {
        title: 'All Cases Bulk Exports',
    },
    E_FILES: {
        title: (eFileFieldName: string) => `${prettify(eFileFieldName)}`,
    },
    ENTITY_PROFILE: {
        title: 'Profile',
    },
    ENTITY_PROFILE_EXPORTS: {
        title: 'Profile Exports',
    },
    ERROR: {
        title: 'Error',
    },
    EVIDENCE_DASHBOARD: {
        title: (evidenceModuleName: string) => `${prettify(evidenceModuleName)} Dashboard`,
    },
    EVIDENCE_CHAIN_OF_CUSTODY_EXPORT: {
        title: 'Chain of Custody Export',
    },
    EVIDENCE_INVENTORIES_DASHBOARD: {
        title: 'Inventories and Audits',
    },
    EVIDENCE_INVENTORY: {
        title: 'Inventory/Audit',
    },
    EVIDENCE_ITEM: {
        title: (evidenceModuleName: string) => `${prettify(evidenceModuleName)} Item`,
    },
    EVIDENCE_REPORTING: {
        title: (evidenceModuleName: string) => `${prettify(evidenceModuleName)} Reporting`,
    },
    EVIDENCE_STORAGE_LOCATION: {
        title: (evidenceModuleName: string) => `${prettify(evidenceModuleName)} Storage Location`,
    },
    INSIGHTS: {
        title: 'Insights',
    },
    ITEM_QUEUE: {
        title: 'Item Basket',
    },
    MY_ACCOUNT: {
        // Not a real route yet, but it should be.
        // We should have /account be equivalent to /admin/users/:myUserId
        title: 'My Account',
    },
    MOBILE_ARCHIVE: {
        title: 'Mobile - Archive',
    },
    MOBILE_COLLECTIONS: {
        title: 'Mobile - Collections',
    },
    NOTIFICATIONS_DASHBOARD_INBOX: {
        title: 'Notifications Inbox',
    },
    NOTIFICATIONS_DASHBOARD_ARCHIVE: {
        title: 'Notifications Archive',
    },
    NOTIFICATIONS_DASHBOARD_ALERTS: {
        title: 'Alerts',
    },
    NOTIFICATION_SETTINGS: {
        title: 'Notification Settings',
    },
    ORGANIZATION_PROFILE: {
        title: 'Organization Profile',
    },
    PERSON_PROFILE: {
        title: 'Person Profile',
    },
    PERSONAL_DASHBOARD: {
        title: 'Dashboard',
    },
    REACT_REPORT: {
        title: 'Report',
    },
    REACT_REPORT_SNAPSHOT: {
        title: 'Report Snapshot',
    },
    RECORD_PRIVACY: {
        title: 'Court Order',
    },
    REPORT: {
        title: 'Report',
        hasLegacySubheader: true,
    },
    REPORT_DETENTION_DISPOSITION: {
        title: 'Detention Disposition',
    },
    REPORT_EXPORTS: {
        title: 'Report Exports',
        hasLegacySubheader: true,
    },
    REPORT_EXPORT_REDACT: {
        title: 'Report Export Redact',
        hasLegacySubheader: true,
    },
    REPORT_HISTORY: {
        title: 'Report History',
        hasLegacySubheader: true,
    },
    REPORT_SNAPSHOT: {
        title: 'Report Snapshot',
        hasLegacySubheader: true,
    },
    REPORTS_DASHBOARD: {
        title: 'Reports',
    },
    SUPPORT_SSO_CONFIGURATION: {
        title: 'Single Sign On Configuration',
    },
    SUPPORT_ARMADA_COMMANDS: {
        title: 'Armada Commands',
    },
    SUPPORT_ARMADA_SCHEDULES: {
        title: 'Armada Schedules',
    },
    SUPPORT_BLACKSMITH_OLD: {
        title: 'Blacksmith Old',
    },
    SUPPORT_BLACKSMITH_INTEGRATION_OLD: {
        title: 'Blacksmith Integrations Old',
    },
    SUPPORT_BLACKSMITH: {
        title: 'Blacksmith',
    },
    SUPPORT_BLACKSMITH_INTEGRATION: {
        title: 'Blacksmith Integrations',
    },
    SUPPORT_PARTNERSHIP_REAL_TIME_ACTIONS: {
        title: 'Partnership Real Time Actions',
    },
    SUPPORT_PARTNERSHIP_REAL_TIME_ACTION: {
        title: 'Partnership Real Time Action',
    },
    SUPPORT_PARTNERSHIP_REAL_TIME_ACTION_NEW: {
        title: 'New Partnership Real Time Action',
    },
    SUPPORT_PLAYGROUND: {
        title: 'Playground',
    },
    SUPPORT_SEARCH_SYNC: {
        title: 'Search Sync',
    },
    SUPPORT_CAD_UNIT_STATUS_TRANSITIONS: {
        title: 'Unit Status Transitions',
    },
    SUPPORT_CAD_UNIT_STATUS_TRANSITIONS_EDIT: {
        title: 'Unit Status Transitions',
    },
    SUPPORT_ID_GENERATOR: {
        title: 'ID Generator',
    },
    SUPPORT_ID_GENERATOR_AGENCY_SELECT: {
        title: 'ID Generator',
    },
    SUPPORT_ID_GENERATOR_EDIT: {
        title: 'ID Generator',
    },
    SUPPORT_CODE_TYPES: {
        title: 'Code Types',
    },
    SUPPORT_CODE_TYPE_NEW: {
        title: 'New Code Type',
    },
    SUPPORT_CODE_TYPE_CLONE: {
        title: 'Clone Code Type',
    },
    SUPPORT_CODE_TYPE: {
        title: 'Code Type',
    },
    SUPPORT_ATTRIBUTE_CODES: {
        title: 'Clone Attribute Codes',
    },
    ADMIN_APPLICATION_SETTINGS: {
        title: 'Application Settings',
    },
    ADMIN_PDF: { title: 'PDFs' },
    ADMIN_PDF_EXPORT_PREFERENCES: {
        title: 'PDF Export Preferences',
    },
    ADMIN_FILLABLE_PDF_TEMPLATE: {
        title: 'Fillable PDFs',
    },
    ADMIN_FILLABLE_PDF_VIEW_HANDLEBARS: {
        title: 'View Handlebars',
    },
    WARRANTS: {
        title: 'Warrants',
    },
    WARRANTS_DASHBOARD: {
        title: 'Warrants',
    },
    WARRANT: {
        title: 'Warrant',
    },
    WARRANT_HISTORY: {
        title: 'Warrant History',
    },
    WARRANT_EXPORTS: {
        title: 'Warrant Exports',
    },
    TASKS_DASHBOARD: {
        title: 'Tasks Dashboard',
    },
    ADMIN_RESPONSE_PLANS: {
        title: 'Response Plans',
    },
    ADMIN_WARRANT_NUMBER_CONFIGURATION: {
        title: 'Warrant Number Configuration',
    },
    ADMIN_WARRANT_NUMBER_CONFIGURATION_NEW: {
        title: 'Create Warrant Number Configuration',
    },
    ADMIN_WARRANT_NUMBER_CONFIGURATION_EDIT: {
        title: 'Edit Warrant Number Configuration',
    },
    COMPLIANCE_DASHBOARD: {
        title: 'Compliance Dashboard',
    },
    COMPLIANCE_TYPE_EXPORT: {
        title: 'Compliance Export',
    },
    COMPLIANCE_TYPE_RESUBMISSION: {
        title: 'Compliance Resubmissions',
    },
    COMPLIANCE_TYPE_EXPORT_HISTORY: {
        title: 'Compliance Export History',
    },
    INTERNAL_ADMIN_PAGES: {
        // not an RMS route, but an external page
        title: 'Internal Admin Page',
    },
    WORKFLOW_ADMIN: {
        // not an RMS route, but an external page
        title: 'Workflow Admin Page (alpha testing)',
    },
    ADMIN_UNIT_TIMERS: {
        title: 'Unit Timers',
    },
    ADMIN_UNIT_TIMERS_EDIT: {
        title: 'Edit Unit Timer',
    },
    ADMIN_UNIT_TIMERS_NEW: {
        title: 'New Unit Timer',
    },
    ADMIN_ALL_UNIT_TIMERS_HISTORY: {
        title: 'All Unit Timers History',
    },
    ADMIN_UNIT_TIMERS_HISTORY: {
        title: 'Unit Timer History',
    },
    CLIPS_SEND: {
        title: 'Send to CLIPS',
    },
    ADMIN_SCHEDULED_EVENTS: { title: 'Scheduled Events' },
    CAD_CHANNELS: { title: 'CAD Channels' },
    ADMIN_COURTS: { title: 'Courts' },
    BRIEFINGS_DASHBOARD: {
        title: 'Briefings',
    },
    BRIEFING: {
        title: 'Briefing',
    },
    GPS_RETENTION_ADMIN: { title: 'GPS Retention' },
    FEDERATED_SEARCH: { title: 'Federated Search' },
    BOOKINGS: { title: 'Booking Details' },
    ADMIN_DIAGRAM_ASSETS: { title: 'Diagram Assets' },
} as const;

type RouteConfigKey = keyof typeof routesConfig;
const validateKeyInDevEnv = (routeConfigKey: string) => {
    // to validate invalid text input from js file, if user chose not to use ROUTE_KEYS[key]
    if (process.env.NODE_ENV === 'development') {
        if (!keys(routesConfig).includes(routeConfigKey)) {
            throw new Error(`routesConfig doesn't contain this key ${routeConfigKey}.`);
        }
    }
};

// @ts-expect-error In Lodash methods like mapValues and mapKeys, the real type of key is string. One way to fix this is to just define the strings inside the routeConfigs objects, like we do in other enums.
export const ROUTE_KEYS: Record<RouteConfigKey, RouteConfigKey> = mapValues(
    routesConfig,
    (obj, routeConfigKey: RouteConfigKey) => routeConfigKey
);

const selectRouteTitle = createSelector(
    [
        (_state: RootState, routeConfigKey: RouteConfigKey) => {
            validateKeyInDevEnv(routeConfigKey);
            return routeConfigKey;
        },
        formatFieldByNameSelector,
    ],
    (routeConfigKey, formatFieldByName) => {
        switch (routeConfigKey) {
            case 'ADMIN_OFFENSE_CODES':
            case 'ADMIN_OFFENSE_CODE':
            case 'ADMIN_OFFENSE_CODE_NEW': {
                const offenseDisplayName = formatFieldByName(DISPLAY_ONLY_OFFENSE);
                return routesConfig[routeConfigKey].title(offenseDisplayName);
            }
            case 'ADMIN_EVIDENCE_CONFIGURATION':
            case 'ADMIN_EVIDENCE_PRINTING_TEMPLATES':
            case 'ADMIN_EVIDENCE_PRINTING_TEMPLATE_NEW':
            case 'ADMIN_EVIDENCE_PRINTING_TEMPLATE':
            case 'EVIDENCE_DASHBOARD':
            case 'EVIDENCE_ITEM':
            case 'EVIDENCE_REPORTING':
            case 'EVIDENCE_STORAGE_LOCATION': {
                const evidenceModuleName = formatFieldByName(DISPLAY_ONLY_NAME_OF_EVIDENCE_MODULE);
                return routesConfig[routeConfigKey].title(evidenceModuleName);
            }
            case 'CASE_PHOTO_LINEUPS': {
                const photoLineUpFieldName = formatFieldByName(DISPLAY_ONLY_PHOTO_LINEUP);
                return routesConfig[routeConfigKey].title(photoLineUpFieldName);
            }
            case 'E_FILES':
            case 'CASE_E_FILES': {
                const eFilesFieldName = formatFieldByName(DISPLAY_ONLY_E_FILE);
                return routesConfig[routeConfigKey].title(eFilesFieldName);
            }
            default: {
                return routesConfig[routeConfigKey].title;
            }
        }
    },
    {
        memoizeOptions: {
            maxSize: size(routesConfig), // will memorize all routeConfigure routeTitle
        },
    }
);

// using curryRight will got an incorrect argument with createStructuredSelector
export const selectRouteTitleByKey = (routeConfigKey: RouteConfigKey) => (state: RootState) =>
    selectRouteTitle(state, routeConfigKey);
export const useRouteTitle = (routeConfigKey: RouteConfigKey) =>
    useSelector(selectRouteTitleByKey(routeConfigKey));

export default mapValues(routesConfig, (obj, key) => {
    return {
        // title is not a valid prop in <Route/>,
        // and title is a dynamic value and must be obtained through selectRouteTitle,
        // for example page title will be get by page component.
        ...omit(obj, 'title'),
        name: key,
    };
});
