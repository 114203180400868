import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Box, VStack } from 'arc';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { InsightsContext } from '../../core/components/InsightsContext';
import { logError } from '../../../../core/logging';

type GoodDataDashboardParams = {
    workspaceId?: string;
    dashboardId?: string;
};

export const GoodDataDashboard: React.FC<
    RouteComponentProps<GoodDataDashboardParams, Record<string, never>>
> = ({ params }) => {
    const applicationSettings = useSelector(applicationSettingsSelector);
    const goodDataIframeRef = useRef<HTMLIFrameElement>(null);
    const { setGoodDataIframeLoaded, addGoodDataEventCallback, removeGoodDataEventCallback } =
        useContext(InsightsContext) || {};

    const [workspaceId] = useState<string | null>(
        params.workspaceId
            ? decodeURIComponent(params.workspaceId)
            : 'bc57fa9ff3d540e388c4d264a38bd9fc'
    );
    const [dashboardId] = useState<string | null>(
        params.dashboardId ? decodeURIComponent(params.dashboardId) : ''
    );

    const [iframeSrc, setIframeSrc] = useState<string>(
        `https://gooddata.us-gov-aws-1.qa.internal-mark43.io/dashboards/embedded/#/workspace/${workspaceId}/dashboard/${dashboardId}?apiTokenAuthentication=true${applicationSettings.RMS_GOODDATA_EMBED_PARAMETERS}`
    );

    const handleTokenExpiration = useCallback(() => {
        const postMessageStructure = {
            gdc: {
                product: 'dashboard',
                event: {
                    name: 'setApiToken',
                    data: {
                        token: applicationSettings.RMS_GOODDATA_API_KEY,
                    },
                },
            },
        };
        const origin = '*';
        if (goodDataIframeRef.current && goodDataIframeRef.current.contentWindow) {
            goodDataIframeRef.current.contentWindow.postMessage(postMessageStructure, origin);
        }
    }, [applicationSettings.RMS_GOODDATA_API_KEY]);

    useEffect(() => {
        if (
            applicationSettings.RMS_GOODDATA_API_KEY &&
            addGoodDataEventCallback &&
            removeGoodDataEventCallback
        ) {
            addGoodDataEventCallback('listeningForApiToken', handleTokenExpiration);
            addGoodDataEventCallback('apiTokenIsAboutToExpire', handleTokenExpiration);

            return () => {
                removeGoodDataEventCallback('listeningForApiToken', handleTokenExpiration);
                removeGoodDataEventCallback('apiTokenIsAboutToExpire', handleTokenExpiration);
            };
        }
        return;
    }, [
        applicationSettings.RMS_GOODDATA_API_KEY,
        addGoodDataEventCallback,
        removeGoodDataEventCallback,
        handleTokenExpiration,
    ]);

    useEffect(() => {
        const goodDataIframe = goodDataIframeRef.current;

        if (goodDataIframe && setGoodDataIframeLoaded) {
            goodDataIframe.onload = () => {
                setGoodDataIframeLoaded(true);
                handleTokenExpiration();
            };
            goodDataIframe.onerror = () => {
                setGoodDataIframeLoaded(false);
                logError('GoodData Iframe failed to load.');
            };

            return () => {
                goodDataIframe.onload = null;
                goodDataIframe.onerror = null;
            };
        }
        return;
    }, [handleTokenExpiration, setGoodDataIframeLoaded]);

    useEffect(() => {
        setIframeSrc(
            `https://gooddata.us-gov-aws-1.qa.internal-mark43.io/dashboards/embedded/#/workspace/${workspaceId}/dashboard/${dashboardId}?apiTokenAuthentication=true${applicationSettings.RMS_GOODDATA_EMBED_PARAMETERS}`
        );
    }, [applicationSettings.RMS_GOODDATA_EMBED_PARAMETERS, workspaceId, dashboardId]);

    return (
        <VStack spacing={0} align="stretch" h="100%" w="100%">
            <Box as="iframe" ref={goodDataIframeRef} src={iframeSrc} flexGrow={1} />
        </VStack>
    );
};
