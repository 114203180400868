import { combineReducers } from 'redux';

import currentViewersUiReducer from '~/client-common/core/domain/current-viewers/state/ui';
import usageLogsUiReducer from '~/client-common/core/domain/usage-logs/state/ui';
import { countriesUiReducer } from '~/client-common/core/domain/countries/state/ui';
import recentHydratedItemProfileIdsReducer from '~/client-common/core/domain/item-profiles/state/ui';
import usersDashboardUiReducer from '../../modules/admin/users/state/ui';
import entityProfilesUiReducer from '../../modules/entity-profiles/core/state/ui';
import departmentSetupAdminUiReducer from '../../modules/admin/department-setup/state/ui';
import unassignedReportsUiReducer from '../../modules/cases/unassigned-reports/state/ui';
import myCasesUiReducer from '../../modules/cases/my-cases/state/ui';
import allCasesUiReducer from '../../modules/cases/all-cases/state/ui';
import batchOperationsUiReducer from '../../modules/cases/batch-operations/state/ui';
import currentCaseUiReducer from '../../modules/cases/core/state/ui';
import currentCaseReviewUiReducer from '../../modules/cases/case-review/state/ui';
import currentCaseLinkedProfilesReducer from '../../modules/cases/linked-profiles/state/ui';
import currentCaseTasksReducer from '../../modules/cases/task-list/state/ui';
import currentCaseAttachmentsReducer from '../../modules/cases/case-attachments/state/ui';
import currentCaseAssociatedRecordsReducer from '../../modules/cases/case-associated-records/state/ui';
import caseDefinitionsAdminUiReducer from '../../modules/admin/case-definitions/state/ui';
import warrantConfigurationAdminUiReducer from '../../modules/admin/warrant-configuration/state/ui';
import warrantActivityStatusLinksAdminUiReducer from '../../modules/admin/warrant-activity-status-links/state/ui';
import masterEntitiesAdminUiReducer from '../../modules/admin/master-entities/state/ui';
import agencyProfilesAdminUiReducer from '../../modules/admin/agency-profiles/state/ui';
import societyProfilesAdminUiReducer from '../../modules/admin/society-profiles/state/ui';
import consortiumAdminUiReducer from '../../modules/admin/consortium/state/ui';
import warrantNumberConfigurationAdminUiReducer from '../../modules/admin/warrant-number-configurations/state/ui';
import landingBackgroundAdminUiReducer from '../../modules/admin/landing-background/state/ui';
import cadContactListAdminUiReducer from '../../modules/admin/cad-contact-lists/state/ui';
import cadUnitsAdminUiReducer from '../../modules/admin/cad-units/state/ui';
import cadDesktopConfigsAdminUiReducer from '../../modules/admin/cad-desktop-configs/state/ui';
import cadCallTakerStationsAdminUiReducer from '../../modules/admin/cad-call-taker-stations/state/ui';
import cadMessagingGroupsAdminUiReducer from '../../modules/admin/cad-messaging-groups/state/ui';
import cadServiceTypesAdminUiReducer from '../../modules/admin/cad-service-rotation-types/state/ui';
import cadServiceProvidersAdminUiReducer from '../../modules/admin/cad-service-rotation-providers/state/ui';
import cadServiceProviderAddOrEditSuspensionFormUiReducer from '../../modules/admin/cad-service-rotation-providers/state/ui/AddOrEditSuspensionFormUi';
import cadRotationListsAdminUiReducer from '../../modules/admin/cad-service-rotation-lists/state/ui';
import bulkEntityPermissionsAdminUiReducer from '../../modules/admin/bulk-entity-permissions/state/ui';
import { cadChannelsAdminReducer } from '../../modules/admin/cad-channels/state/ui';

import vehicleMakeModelAdminUiReducer from '../../modules/admin/vehicle-make-model/state/ui';
import preferencesAdminUiReducer from '../../modules/admin/preferences/state/ui';
import commandLineAdminUiReducer from '../../modules/admin/command-line/state/ui';
import dispatchAreasAdminUiReducer from '../../modules/admin/dispatch-areas/state/ui';
import duplicateEventSearchAdminUiReducer from '../../modules/admin/duplicate-event-search/state/ui';
import shortcutKeysAdminUiReducer from '../../modules/admin/shortcut-keys/state/ui';
import callForServiceAdminUiReducer from '../../modules/admin/call-for-services/state/ui';
import evidenceUiReducer from '../../modules/evidence';
import evidenceFacilitiesAdminUiReducer from '../../modules/admin/evidence-facilities/state/ui';
import currentUserUiReducer from '../../modules/core/current-user/state/ui';
import evidenceConfigAdminUiReducer from '../../modules/admin/evidence-config/state/ui';
import evidenceRetentionPoliciesAdminUiReducer from '../../modules/admin/evidence-retention-policies/state/ui';
import evidencePrintingTemplatesAdminUiReducer from '../../modules/admin/evidence-printing-templates/state/ui';
import evidenceChainEventTypesAdminUiReducer from '../../modules/admin/evidence-chain-event-types/state/ui';
import analysisDashboardUiReducer from '../../modules/analysis/dashboard/state/ui';
import notificationsUiReducer from '../../modules/notifications';
import tasksUiReducer from '../../modules/tasks';
import briefingsUiReducer from '../../modules/briefings';
import { FederatedSearchUIReducer } from '../../modules/federated-search/state/ui';
import printingTemplatesAdminUiReducer from '../../modules/admin/printing-templates/state/ui';
import reportPrintingTemplatesAdminUiReducer from '../../modules/admin/report-printing-templates/state/ui';
import casePrintingTemplatesAdminUiReducer from '../../modules/admin/case-printing-templates/state/ui';
import rolesAdminUiReducer from '../../modules/admin/roles/state/ui';
import streetAliasesAdminUiReducer from '../../modules/admin/street-aliases/state/ui';
import pointLocationAdminUiReducer from '../../modules/admin/point-locations/state/ui';
import locationCautionsAdminUiReducer from '../../modules/admin/location-cautions/state/ui';
import streetSegmentsAdminUiReducer from '../../modules/admin/street-segments/state/ui';
import { duplicateNamesReducer } from '../../modules/core/duplicate-names/state/ui';
import quickSearchReducer from '../../modules/search/quick-search/state/ui';
import releaseTrackingUiReducer from '../../modules/core/pdf-exports/release-tracking/state/ui';
import { reportsUiReducer } from '../../modules/reports';
import accountSwitcherUiReducer from '../../modules/core/account-switcher/state/ui';

import usageLogsAdminUiReducer from '../../modules/admin/usage-logs/state/ui';
import supportSearchSyncUiReducer from '../../modules/support/search-sync/state/ui';
import blacksmithUiReducer from '../../modules/support/blacksmith/state/ui';
import attributeTypeCodeTypeLinksAdminReducer from '../../modules/admin/attribute-type-code-type-links/state/ui';
import fieldConfigurationsAdminUiReducer from '../../modules/admin/field-configurations/state/ui';
import rulesAdminUiReducer from '../../modules/admin/rules/state/ui';
import warrantsUiReducer from '../../modules/warrants/warrantsUiReducer';
import attachmentsUiReducer from '../../modules/attachments/core/state/ui';
import cadUnitStatusTransitionsUiReducer from '../../modules/support/cad-unit-status-transitions/state/ui';
import applicationSettingAdminUiReducer from '../../modules/admin/application-settings/state/ui';
import fillablePdfTemplatesAdminUiReducer from '../../modules/admin/fillable-pdf-templates/state/ui';
import courtsAdminUiReducer from '../../modules/admin/courts/state/ui';
import gpsRetentionAdminUiReducer from '../../modules/admin/cad-gps-retention/state/ui';
import supportIdGeneratorUiReducer from '../../modules/support/id-generator/state/ui';
import reportSealingUiReducer from '../../modules/record-privacy/sealing/state/ui';
import reportUnsealingUiReducer from '../../modules/record-privacy/unsealing/state/ui';
import courtOrderEditUiReducer from '../../modules/record-privacy/court-orders/state/ui';
import chargeVacatingUiReducer from '../../modules/record-privacy/vacating/state/ui';
import attributeLoadingStateUiReducer from '../../modules/core/attributes/state/ui';
import exportReleaseSettingAdminUiReducer from '../../modules/admin/export-release-settings/state/ui';
import productModulesReducer from '../../modules/support/product-modules/state/ui';
import subdivisionsV2AdminReducer from '../../modules/admin/subdivisions/state/ui';
import mergeEntitiesReducer from '../../modules/merge-entities/state/ui';
import stationsReducer from '../../modules/admin/stations/state/ui';
import recentEntitiesReducer from '../../modules/core/recent-entities/state/ui';
import externalLinksReducer from '../../modules/admin/external-links/state/ui';
import narrativeGuidesReducer from '../../modules/admin/narrative-guides/state/ui';
import exportPresetsAdminUiReducer from '../../modules/admin/export-presets/state/ui';
import complianceUiReducer from '../../modules/compliance/state/ui';
import clipsUiReducer from '../../modules/clips/state/ui';
import { geoAliasesAdminReducer } from '../../modules/admin/geo-aliases/reducer';
import { imageGalleryUiReducer } from './imageGalleryReducers';
import { alertsUIReducer } from './alertsReducers';
import { customReportsAdminUiReducer } from './customReportsAdminReducers';
import { renSearchModalUiReducer } from './renSearchModalReducers';
import { lastCreatedReportIdReducer, reportUiReducer } from './reportReducers';
import { shapefilesAdminUiReducer } from './shapefilesAdminReducers';
import { locationAliasesAdminUiReducer } from './locationAliasesAdminReducers';
import { boxUiReducer } from './boxReducers';
import { departmentIPsAdminUiReducer } from './departmentIPsAdminReducers';
import { attributesAdminUiReducer } from './attributesAdminReducers';
import { userProfileAdminUiReducer } from './userProfileAdminReducers';
import { recordNumberChangeUiReducer } from './recordNumberChangeReducers';
import { renChangeUiReducer } from './renChangeReducers';
import { entityPermissionsUiReducer } from './entityPermissionsReducers';
import { armadaUiReducer } from './armadaReducers';
import { searchResultsUiReducer } from './searchResultsReducers';
import { advancedSearchUiReducer } from './advancedSearchReducers';
import { exportsUiReducer } from './exportsReducers';
import { globalErrorReducer, globalUiReducer } from './globalReducers';

export default combineReducers({
    // core
    alerts: alertsUIReducer,
    box: boxUiReducer,
    currentUser: currentUserUiReducer,
    exports: exportsUiReducer,
    global: globalUiReducer,
    imageGallery: imageGalleryUiReducer,
    renSearch: renSearchModalUiReducer,
    duplicateNames: duplicateNamesReducer,
    error: globalErrorReducer,

    // internal
    armada: armadaUiReducer,

    // admin
    // TODO import single combined reducer from modules/admin/index
    attributesAdmin: attributesAdminUiReducer,
    callForServiceAdmin: callForServiceAdminUiReducer,
    caseDefinitionsAdmin: caseDefinitionsAdminUiReducer,
    warrantConfigurationAdmin: warrantConfigurationAdminUiReducer,
    masterEntitiesAdmin: masterEntitiesAdminUiReducer,
    agencyProfilesAdmin: agencyProfilesAdminUiReducer,
    societyProfilesAdmin: societyProfilesAdminUiReducer,
    consortiumAdmin: consortiumAdminUiReducer,
    warrantNumberConfigurationAdmin: warrantNumberConfigurationAdminUiReducer,
    landingBackgroundAdmin: landingBackgroundAdminUiReducer,
    cadUnitsAdmin: cadUnitsAdminUiReducer,
    cadContactListsAdmin: cadContactListAdminUiReducer,
    cadDesktopConfigsAdmin: cadDesktopConfigsAdminUiReducer,
    cadCallTakerStationsAdmin: cadCallTakerStationsAdminUiReducer,
    cadMessagingGroups: cadMessagingGroupsAdminUiReducer,
    cadServiceProviders: cadServiceProvidersAdminUiReducer,
    cadServiceProviderAddOrEditSuspensionForm: cadServiceProviderAddOrEditSuspensionFormUiReducer,
    cadServiceTypes: cadServiceTypesAdminUiReducer,
    cadRotationListsAdmin: cadRotationListsAdminUiReducer,
    usersDashboardAdmin: usersDashboardUiReducer,
    warrantActivityStatusLinksAdmin: warrantActivityStatusLinksAdminUiReducer,
    bulkEntityPermissionsAdmin: bulkEntityPermissionsAdminUiReducer,
    cadChannelsAdmin: cadChannelsAdminReducer,

    vehicleMakeModelAdmin: vehicleMakeModelAdminUiReducer,
    preferencesAdmin: preferencesAdminUiReducer,
    commandLineAdmin: commandLineAdminUiReducer,
    dispatchAreasAdmin: dispatchAreasAdminUiReducer,
    duplicateEventSearchAdmin: duplicateEventSearchAdminUiReducer,
    shortcutKeysAdmin: shortcutKeysAdminUiReducer,
    customReportsAdmin: customReportsAdminUiReducer,
    departmentIPsAdmin: departmentIPsAdminUiReducer,
    departmentSetupAdmin: departmentSetupAdminUiReducer,
    entityPermissions: entityPermissionsUiReducer,
    evidenceConfigAdmin: evidenceConfigAdminUiReducer,
    evidenceRetentionPoliciesAdmin: evidenceRetentionPoliciesAdminUiReducer,
    evidenceFacilitiesAdmin: evidenceFacilitiesAdminUiReducer,
    evidencePrintingTemplatesAdmin: evidencePrintingTemplatesAdminUiReducer,
    evidenceChainEventTypesAdmin: evidenceChainEventTypesAdminUiReducer,
    locationAliasesAdmin: locationAliasesAdminUiReducer,
    geoAliasesAdmin: geoAliasesAdminReducer,
    renChange: renChangeUiReducer,
    recordNumberChange: recordNumberChangeUiReducer,
    shapefilesAdmin: shapefilesAdminUiReducer,
    userProfileAdmin: userProfileAdminUiReducer,
    printingTemplatesAdmin: printingTemplatesAdminUiReducer,
    reportPrintingTemplatesAdmin: reportPrintingTemplatesAdminUiReducer,
    casePrintingTemplatesAdmin: casePrintingTemplatesAdminUiReducer,
    rolesAdmin: rolesAdminUiReducer,
    streetAliasesAdmin: streetAliasesAdminUiReducer,
    locationCautionsAdmin: locationCautionsAdminUiReducer,
    pointLocationAdmin: pointLocationAdminUiReducer,
    streetSegmentsAdmin: streetSegmentsAdminUiReducer,
    usageLogsAdmin: usageLogsAdminUiReducer,
    attributeTypeCodeTypeLinksAdmin: attributeTypeCodeTypeLinksAdminReducer,
    fieldConfigurationsAdmin: fieldConfigurationsAdminUiReducer,
    rulesAdmin: rulesAdminUiReducer,
    applicationSettingsAdmin: applicationSettingAdminUiReducer,
    mergeEntitiesDashboard: mergeEntitiesReducer,
    exportReleaseSettingAdmin: exportReleaseSettingAdminUiReducer,
    subdivisionsV2Admin: subdivisionsV2AdminReducer,
    stationsAdmin: stationsReducer,
    externalLinksAdmin: externalLinksReducer,
    narrativeGuidesAdmin: narrativeGuidesReducer,
    fillablePdfTemplatesAdmin: fillablePdfTemplatesAdminUiReducer,
    courtsAdmin: courtsAdminUiReducer,
    gpsRetentionAdmin: gpsRetentionAdminUiReducer,
    exportPresetsAdmin: exportPresetsAdminUiReducer,

    // support
    supportSearchSync: supportSearchSyncUiReducer,
    blacksmith: blacksmithUiReducer,
    cadUnitStatusTransitions: cadUnitStatusTransitionsUiReducer,
    supportIdGenerator: supportIdGeneratorUiReducer,
    productModules: productModulesReducer,

    // advanced-search
    advancedSearch: advancedSearchUiReducer,

    // analysis
    analysisDashboard: analysisDashboardUiReducer,

    // TODO move this to modules/cases/index
    cases: combineReducers({
        myCases: myCasesUiReducer,
        unassignedReports: unassignedReportsUiReducer,
        allCases: allCasesUiReducer,
        currentCase: currentCaseUiReducer,
        caseReview: currentCaseReviewUiReducer,
        linkedProfiles: currentCaseLinkedProfilesReducer,
        tasks: currentCaseTasksReducer,
        attachments: currentCaseAttachmentsReducer,
        associatedRecords: currentCaseAssociatedRecordsReducer,
        batchOperations: batchOperationsUiReducer,
    }),

    // entity-profiles
    entityProfiles: entityProfilesUiReducer,

    // evidence
    evidence: evidenceUiReducer,

    // reports
    report: reportUiReducer,
    lastCreatedReportId: lastCreatedReportIdReducer,

    // new reports dashboard
    reports: reportsUiReducer,

    // record privacy
    reportSealing: reportSealingUiReducer,
    reportUnsealing: reportUnsealingUiReducer,
    courtOrderEdit: courtOrderEditUiReducer,
    chargeVacating: chargeVacatingUiReducer,

    // notifications
    notifications: notificationsUiReducer,

    // tasks
    tasks: tasksUiReducer,

    // briefings
    briefings: briefingsUiReducer,

    // account-switcher
    accountSwitcher: accountSwitcherUiReducer,

    // usage-logs
    usageLogs: usageLogsUiReducer,

    // warrants
    warrants: warrantsUiReducer,

    // current-viewers
    currentViewers: currentViewersUiReducer,

    // quick-search
    quickSearch: quickSearchReducer,

    // relesase-tracking
    releaseTracking: releaseTrackingUiReducer,

    // attachments
    attachments: attachmentsUiReducer,

    attributeLoadingState: attributeLoadingStateUiReducer,
    countries: countriesUiReducer,

    // deprecated
    searchResults: searchResultsUiReducer,
    // end deprecated

    recentHydratedItemProfileIds: recentHydratedItemProfileIdsReducer,
    recentEntities: recentEntitiesReducer,

    compliance: complianceUiReducer,

    clips: clipsUiReducer,

    federatedSearch: FederatedSearchUIReducer,
});
