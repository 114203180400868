import React from 'react';
import { ExportPreset } from '@mark43/rms-api';
import styled from 'styled-components';
import {
    flatMap,
    uniq,
    difference,
    filter,
    partition,
    flatten,
    reduce,
    isEmpty,
    concat,
    map,
} from 'lodash';
import { useSelector } from 'react-redux';
import useFields from '~/client-common/core/fields/hooks/useFields';
import { DISPLAY_ONLY_PERSONNEL_REPORT_OWNER_LABEL } from '~/client-common/core/enums/universal/fields';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { exportPresetsByIdSelector } from '~/client-common/core/domain/export-presets/state/data';
import { isCoverSheet } from '~/client-common/helpers/exportHelpers';
import {
    exportPresetToPacketValues,
    transformPacketOptionsForReportPage,
} from '../../../../legacy-redux/selectors/exportsSelectors';
import testIds from '../../../../core/testIds';
import { PacketOption } from '../../../exports/core/utils/exportHelpers';
import CheckboxTree, { unflatten } from '../../../core/forms/components/checkboxes/CheckboxTree';
import Row from '../../../core/components/Row';
import Select from '../../../core/forms/components/selects/Select';
import { exportPresetsForExportPageOptionsSelector } from '../../../admin/export-presets/state/data';
import { sortOptionsByExportPresetReportTypes } from '../../../exports/core/utils/sortOptionsByExportPresetReportTypes';
import { Tree } from './ReportExportsCheckboxTree';
import {
    SortedReportExportsCheckboxTree,
    buildNodesFromOptions,
} from './SortedReportExportsCheckboxTree';

const strings = componentStrings.reports.core.ReportFormExportsOptions;

const FlexColumn = styled.div`
    flex: 1 1 100%;
    padding-bottom: var(--arc-space-4);
`;

const StyledSelect = styled(Select)`
    margin-bottom: 30px;
` as typeof Select;

const StyledCheckboxTree = styled(CheckboxTree)`
    margin-bottom: 30px;
`;

/*
 * Helper function to figure out given a nested packet value from the tree,
 * what is the associated root parent value
 */
function buildChildValueToRootNodeValueMap(
    tree: Tree[],
    rootNodeValue?: string,
    childValueToRootNodeValueMap: Record<string, string> = {}
): Record<string, string> {
    // Traverse the tree, and store the root parent node for each nested node
    return reduce(
        tree,
        (acc, node) => {
            // If we are iterating the top level
            // then don't set anyything -- just
            // iterate through the leaves
            if (!rootNodeValue) {
                return buildChildValueToRootNodeValueMap(
                    node.children,
                    node.value,
                    childValueToRootNodeValueMap
                );
            } else {
                acc[node.value] = rootNodeValue;
                return buildChildValueToRootNodeValueMap(node.children, rootNodeValue, acc);
            }
        },
        childValueToRootNodeValueMap
    );
}

function sortValuesBySortedTree(values: string[], sortedTree: Tree[]) {
    const childValueToRootNodeValueMap = buildChildValueToRootNodeValueMap(sortedTree);
    const sortedResult = flatMap(sortedTree, (tree) => {
        const rootValue = tree.value;

        // Find all the values that have `rootValue`
        const valuesForTree = filter(values, (currentResult) => {
            return childValueToRootNodeValueMap[currentResult] === rootValue;
        });

        // If the value is a coversheet,
        // it needs to be ordered first
        return flatten(partition(valuesForTree, isCoverSheet));
    });
    // Once we have our `sortedResult`, let's just merge it
    // back with the raw results to make sure we didn't miss anything
    // (although the difference should be an empty array)
    // and then uniq it to ensure that nothing was picked up twice
    return uniq(concat(sortedResult, difference(values, sortedResult)));
}

export type ExpandCollapseState = {
    [value: string]: boolean;
};

export const ReportFormExportsOptions: React.FC<{
    packets: { onChange: (optionValues: string[]) => void };
    packetOptions: PacketOption[];
    globalExportOptions: React.ReactElement;
    handlePacketsChange: (value: string[]) => void;
    entityId: number;
    handleExportOptionsChange: (arg: ExportPreset) => void;
    selectedExportPresetId: number | undefined;
    setExportPresetId: (arg?: number) => void;
}> = ({
    packets,
    packetOptions,
    globalExportOptions,
    handlePacketsChange,
    entityId,
    handleExportOptionsChange,
    selectedExportPresetId,
    setExportPresetId,
}) => {
    const enabledExportPresets = useSelector(exportPresetsForExportPageOptionsSelector);
    const exportPresetsById = useSelector(exportPresetsByIdSelector);

    const [expandCollapseState, setExpandCollapseState] = React.useState<ExpandCollapseState>({});

    const exportPreset = exportPresetsById(selectedExportPresetId);

    const ownerDisplayValue = useFields(DISPLAY_ONLY_PERSONNEL_REPORT_OWNER_LABEL)[DISPLAY_ONLY_PERSONNEL_REPORT_OWNER_LABEL];

    const transformedPacketOptions = React.useMemo(
        () => transformPacketOptionsForReportPage(packetOptions, ownerDisplayValue),
        [packetOptions, ownerDisplayValue]
    );

    const handleExportPresetChange = (exportPresetId?: number | null) => {
        handleDeselectAll();
        setExportPresetId(exportPresetId || undefined);
        const exportPreset = exportPresetsById(exportPresetId || undefined);
        if (!!exportPreset) {
            // sort here for form model change using packets.onChange
            // and sort in SortedReportExportsCheckboxTree for UI update
            const sortedOptions = sortOptionsByExportPresetReportTypes(
                transformedPacketOptions,
                exportPreset
            );
            const packetValuesToAdd = exportPresetToPacketValues(
                sortedOptions,
                exportPreset,
                entityId
            );
            const nodes = buildNodesFromOptions(sortedOptions, entityId, ownerDisplayValue);
            const tree = unflatten(undefined, nodes);

            packets.onChange(packetValuesToAdd);
            handleExportOptionsChange(exportPreset);
            const childValueToRootNodeValueMap = buildChildValueToRootNodeValueMap(tree);
            setExpandCollapseState(
                reduce<string, Record<string, boolean>>(
                    packetValuesToAdd,
                    (acc, packetValue) => {
                        const rootNodeValue = childValueToRootNodeValueMap[packetValue];
                        acc[rootNodeValue] = true;
                        return acc;
                    },
                    {}
                )
            );
        }
    };

    const handleSelectAll = (sortedTree: Tree[]) => {
        const optionsValues = map(
            filter(transformedPacketOptions, (option) => {
                return !option.disabled;
            }),
            'value'
        );
        packets.onChange(sortValuesBySortedTree(optionsValues, sortedTree));
        setExportPresetId(undefined);
    };

    const handleDeselectAll = () => {
        packets.onChange([]);
        setExportPresetId(undefined);
    };

    // Just wraps the original `handlePacketsChange` function
    // and takes the array of `values` passed in,
    // and reorders them to match the order of the sorted tree
    const handlePacketsChangeWithSortOrder = React.useCallback(
        (result: string[]) => (sortedTree: Tree[]) => {
            return handlePacketsChange(sortValuesBySortedTree(result, sortedTree));
        },
        [handlePacketsChange]
    );

    return (
        <FlexColumn className="clearfix">
            {!isEmpty(enabledExportPresets) && (
                <Row>
                    <StyledSelect
                        length="md"
                        label={strings.exportPreset}
                        clearable={false}
                        value={selectedExportPresetId}
                        options={enabledExportPresets}
                        onChange={handleExportPresetChange}
                    />
                </Row>
            )}
            <StyledCheckboxTree
                {...packets}
                onChange={handlePacketsChangeWithSortOrder}
                options={transformedPacketOptions}
                testId={testIds.EXPORTS_INCLUDE_IN_EXPORT_CHECKBOX}
                render={({
                    value,
                    options,
                    onChange,
                }: {
                    value: string[];
                    options: PacketOption[];
                    onChange: Parameters<typeof SortedReportExportsCheckboxTree>[0]['onChange'];
                }) => {
                    return (
                        <SortedReportExportsCheckboxTree
                            expandCollapseState={expandCollapseState}
                            setExpandCollapseState={setExpandCollapseState}
                            value={value}
                            onChange={onChange}
                            options={options}
                            entityId={entityId}
                            handleSelectAll={handleSelectAll}
                            handleDeselectAll={handleDeselectAll}
                            exportPresetSort={typeof selectedExportPresetId !== 'undefined'}
                            exportPreset={exportPreset}
                        />
                    );
                }}
            />
            {globalExportOptions}
        </FlexColumn>
    );
};
