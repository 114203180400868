import keyMirror from 'keymirror';

export const personProfilePaths = keyMirror({
    id: null,
    masterPersonId: null,
    firstNameUnknown: null,
    lastNameUnknown: null,
    firstName: null,
    middleName: null,
    lastName: null,
    motherName: null,
    motherMaidenName: null,
    fatherName: null,
    title: null,
    suffix: null,
    dateOfBirth: null,
    isDobUnknown: null,
    age: null,
    dateOfEmancipation: null,
    placeOfBirth: null,
    ageRangeLabel: null,
    ageMin: null,
    ageMax: null,
    isUnborn: null,
    expectedDateOfBirth: null,
    isExpectedDateOfBirthUnknown: null,
    isDead: null,
    dateOfDeathUtc: null,
    verificationOfDeathAttrId: null,
    isJuvenile: null,
    infantAge: null,
    isPregnantAttrId: null,
    ssn: null,
    showAdditionalHeightFields: null,
    heightLabel: null,
    heightMinLabel: null,
    heightMaxLabel: null,
    height: null,
    heightFeet: null,
    heightInches: null,
    heightMinFeet: null,
    heightMinInches: null,
    heightMaxFeet: null,
    heightMaxInches: null,
    showAdditionalWeightFields: null,
    weight: null,
    weightRangeMin: null,
    weightRangeMax: null,
    clothing: null,
    isNonDisclosureRequest: null,
    fbiUcn: null,
    stateIdNumber: null,
    details: null,
    needsInterpreter: null,
    sexAttrId: null,
    raceAttrId: null,
    ethnicityAttrId: null,
    citizenshipAttrId: null,
    eyeColorAttrId: null,
    skinToneAttrId: null,
    hairColorAttrId: null,
    hairStyleAttrId: null,
    hairLengthAttrId: null,
    facialHairTypeAttrId: null,
    visionAttrId: null,
    buildAttrId: null,
    maritalStatusAttrId: null,
    religionAttrId: null,
    religionOther: null,
    sexualOrientationAttrId: null,
    croId: null,
    pncId: null,
    nationalIdNumber: null,
    isVulnerable: null,
    dateVulnerableFrom: null,
    dateVulnerableTo: null,
    sexualOrientationOther: null,
    personProbations: null,
    birthStateAttrId: null,
    isResidentOfJurisdictionAttrId: null,
    dlCountry: null,
    dlNumber: null,
    dlTypeAttrId: null,
    dlTypeOther: null,
    dlStateAttrId: null,
    dlStatusAttrId: null,
    dlEndorsementAttrId: null,
    priorHistoryOfDomesticViolence: null,
    emergencyContacts: null,
    isPhoneNumberNotGiven: null,
    nibrsCodeCode: null,
    nibrsOffenseCodeId: null,
    isAllegedGangMember: null,
    gangName: null,
    gangCriteria: null,
    phoneNumbers: null,
    emails: null,
    monikers: null,
    passports: null,
    employmentHistories: null,
    militaryHistories: null,
    schoolHistories: null,
    identifiers: null,
    identifyingMarks: null,
    homeAddresses: null,
    workAddresses: null,
    otherAddresses: null,
    injuries: null,
    showMedicalTreatment: null,
    medicalTreatment: null,
    attributes: null,
    cautions: null,
});
