let resource;

export function buildResource(Resource, req) {
    resource = new Resource({
        name: 'DEX Devices Resource',
        methods: {
            /**
             * @return {Promise}
             */
            getDexDevices() {
                return req({
                    method: 'GET',
                    baseUrl: '/dex/api',
                    url: 'dex_devices',
                });
            },
            deleteDexDevice(dexDeviceIds) {
                return req({
                    method: 'POST',
                    baseUrl: '/dex/api',
                    url: 'dex_devices/delete',
                    data: dexDeviceIds,
                });
            },
            createDexDevices(dexDevices) {
                return req({
                    method: 'POST',
                    baseUrl: '/dex/api',
                    url: 'dex_devices/bulk',
                    data: dexDevices,
                });
            },
            updateDexDevices(dexDevices) {
                return req({
                    method: 'PUT',
                    baseUrl: '/dex/api',
                    url: 'dex_devices',
                    data: dexDevices,
                });
            },
        },
    });
}

const resourceGetter = () => resource;
export default resourceGetter;
