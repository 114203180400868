import { AttributeTypeEnum } from '@mark43/rms-api';
import { toUpper } from 'lodash';
import React from 'react';

import { Fieldset as MFTFieldset } from 'markformythree';
import componentStrings from '~/client-common/core/strings/componentStrings';

import FormRow from '../../../../../core/forms/components/FormRow';
import { ArbiterMFTAttributeSelect } from '../../../../../core/forms/components/selects/AttributeSelect';
import { ArbiterMFTYearSelect } from '../../../../../core/forms/components/selects/YearSelect';
import { SidePanelSection } from '../../../../../../legacy-redux/components/core/SidePanel';
import { ArbiterMFTText } from '../../../../../core/forms/components/Text';
import { HelpTextProps } from '../../../../../../legacy-redux/components/core/HelpText';

type VehicleRegistrationFieldsetPropsT = {
    helpTextCollisionBoundary: HelpTextProps['collisionBoundary'];
};

const vehicleFieldsetStrings = componentStrings.search.fieldsets.VehicleFieldset;

export const VehicleRegistrationFieldset: React.FC<VehicleRegistrationFieldsetPropsT> = ({
    helpTextCollisionBoundary,
}) => {
    return (
        <SidePanelSection title={vehicleFieldsetStrings.sections.vehicleRegistration}>
            <MFTFieldset path="vehicle">
                <FormRow>
                    <ArbiterMFTText
                        alterValue={toUpper}
                        helpTextCollisionBoundary={helpTextCollisionBoundary}
                        path="vinNumber"
                        length="md"
                    />
                    <ArbiterMFTText
                        alterValue={toUpper}
                        helpTextCollisionBoundary={helpTextCollisionBoundary}
                        path="tag"
                        length="md"
                    />
                </FormRow>
                <FormRow>
                    <ArbiterMFTYearSelect
                        helpTextCollisionBoundary={helpTextCollisionBoundary}
                        path="registrationYear"
                        length="md"
                    />
                    <ArbiterMFTAttributeSelect
                        helpTextCollisionBoundary={helpTextCollisionBoundary}
                        path="registrationStateAttrId"
                        length="md"
                        attributeType={AttributeTypeEnum.STATE.name}
                    />
                </FormRow>
                <ArbiterMFTText
                    helpTextCollisionBoundary={helpTextCollisionBoundary}
                    path="registrationType"
                />
            </MFTFieldset>
        </SidePanelSection>
    );
};
