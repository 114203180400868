import React, { useContext, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cssVar, Text, Box } from 'arc';
import {
    FederatedSearchExternalReadDataContext,
    QueryableEntityType,
} from 'mark43-federated-search';
import { EntityTypeEnum, Vehicle } from '@mark43/rms-api';

import componentStrings from '~/client-common/core/strings/componentStrings';
import itemSidePanelOperationEnum from '~/client-common/core/enums/client/itemSidePanelOperationEnum';
import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';

import { currentReportSelector } from '../../../../../legacy-redux/selectors/reportSelectors';
import {
    openItemSidePanel,
    generateSidePanelFormDataFromRmsVehicle,
} from '../../state/ui/itemSidePanel';
import { VehiclePillSmall } from './VehiclePillSmall';
import { useMapDexItemsToRmsVehicles } from './hooks';

const strings = componentStrings.core.VehicleQuickAdd.dex;

export const AddItemFromDex = () => {
    const { resultForConsumerApp = {} } = useContext(FederatedSearchExternalReadDataContext) || {};
    const currentReport = useSelector(currentReportSelector);
    const dispatch = useDispatch();

    const dexItems = useMemo(
        () => [
            ...(resultForConsumerApp[QueryableEntityType.VEHICLE] || []),
            ...(resultForConsumerApp[QueryableEntityType.BOAT] || []),
        ],
        [resultForConsumerApp]
    );

    const { rmsVehicles, loading, error } = useMapDexItemsToRmsVehicles(dexItems);

    const createOnPillClickHandler = (rmsVehicle: Partial<Vehicle>) => () => {
        dispatch(
            // @ts-expect-error `openItemSidePanel` is not typed yet
            openItemSidePanel({
                itemTypeAttrId: globalAttributes.itemType.vehicle,
                isVehicle: true,
                ownerId: currentReport?.id,
                reportingEventNumber: currentReport?.reportingEventNumber,
                ownerType: EntityTypeEnum.REPORT.name,
                operation: itemSidePanelOperationEnum.CREATE,
                isFormHidden: false,
                defaultValues: generateSidePanelFormDataFromRmsVehicle(rmsVehicle),
            })
        );
    };

    return (
        !loading && (
            <div>
                <Text
                    fontSize="small"
                    color="tertiary"
                    sx={{ mb: cssVar('arc.space.2') }}
                >
                    {strings.addItem}
                </Text>
                {error && (
                    <Text variant="headingXs" color="negative">
                        {strings.genericLoadError}
                    </Text>
                )}
                {rmsVehicles.length > 0 &&
                    rmsVehicles.map((vehicle) => (
                        <Box
                            display="grid"
                            gridTemplateColumns="1fr 1fr"
                            gap={cssVar('arc.space.2')}
                            key={vehicle.id}
                        >
                            <VehiclePillSmall
                                vehicle={vehicle}
                                onClick={createOnPillClickHandler(vehicle)}
                            />
                        </Box>
                    ))}
            </div>
        )
    );
};
