import { FieldTypeEnum } from '@mark43/rms-api';
import {
    DISPLAY_MERGE_ENTITIES_DL_GROUPING,
    DISPLAY_MERGE_ENTITIES_DECEASED_GROUPING,
    DISPLAY_MERGE_ENTITIES_UNBORN_GROUPING,
    DISPLAY_MERGE_ENTITIES_HEIGHT_GROUPING,
    DISPLAY_MERGE_ENTITIES_PERSON_GANG_TRACKING_EXPIRATION_DATE_UTC,
    DISPLAY_MERGE_ENTITIES_PERSON_GANG_TRACKING_GROUPING,
    DISPLAY_MERGE_ENTITIES_WEIGHT_GROUPING,
    DISPLAY_MERGE_ENTITIES_DATE_OF_BIRTH_GROUPING,
    PERSON_PROFILE_BIRTH_STATE_ATTR_ID,
    DISPLAY_SUSPECTED_GANG_AFFILIATION,
    PERSON_PROFILE_BUILD_ATTR_ID,
    PERSON_PROFILE_CITIZENSHIP_ATTR_ID,
    PERSON_PROFILE_DATE_OF_BIRTH,
    PERSON_PROFILE_DATE_OF_BIRTH_RANGE_END,
    PERSON_PROFILE_DATE_OF_BIRTH_RANGE_START,
    PERSON_PROFILE_DATE_OF_DEATH_UTC,
    PERSON_PROFILE_DATE_OF_EMANCIPATION,
    PERSON_PROFILE_DL_ENDORSEMENT_ATTR_ID,
    PERSON_PROFILE_DL_NUMBER,
    PERSON_PROFILE_DL_STATE_ATTR_ID,
    PERSON_PROFILE_DL_STATUS_ATTR_ID,
    PERSON_PROFILE_DL_TYPE_ATTR_ID,
    PERSON_PROFILE_DL_TYPE_OTHER,
    PERSON_PROFILE_ETHNICITY_ATTR_ID,
    PERSON_PROFILE_EYE_COLOR_ATTR_ID,
    PERSON_PROFILE_FACIAL_HAIR_TYPE_ATTR_ID,
    PERSON_PROFILE_FBI_UCN,
    PERSON_PROFILE_FIRST_NAME,
    PERSON_PROFILE_HAIR_COLOR_ATTR_ID,
    PERSON_PROFILE_HAIR_LENGTH_ATTR_ID,
    PERSON_PROFILE_HAIR_STYLE_ATTR_ID,
    PERSON_PROFILE_HEIGHT,
    PERSON_PROFILE_HEIGHT_RANGE_MAX,
    PERSON_PROFILE_HEIGHT_RANGE_MIN,
    PERSON_PROFILE_IS_DEAD,
    PERSON_PROFILE_IS_RESIDENT_OF_JURISDICTION_ATTR_ID,
    PERSON_PROFILE_IS_JUVENILE,
    PERSON_PROFILE_LAST_NAME,
    PERSON_PROFILE_MARITAL_STATUS_ATTR_ID,
    PERSON_PROFILE_MIDDLE_NAME,
    PERSON_PROFILE_NEEDS_INTERPRETER,
    PERSON_PROFILE_PLACE_OF_BIRTH,
    PERSON_PROFILE_PRIOR_HISTORY_OF_DOMESTIC_VIOLENCE,
    PERSON_PROFILE_RACE_ATTR_ID,
    PERSON_PROFILE_SEX_ATTR_ID,
    PERSON_PROFILE_SKIN_TONE_ATTR_ID,
    PERSON_PROFILE_SSN,
    PERSON_PROFILE_STATE_ID_NUMBER,
    PERSON_PROFILE_SUFFIX,
    PERSON_PROFILE_TITLE,
    PERSON_PROFILE_VISION_ATTR_ID,
    PERSON_PROFILE_WEIGHT,
    PERSON_PROFILE_WEIGHT_RANGE_MAX,
    PERSON_PROFILE_WEIGHT_RANGE_MIN,
    PERSON_PROFILE_IS_UNBORN,
    PERSON_PROFILE_EXPECTED_DATE_OF_BIRTH,
} from '~/client-common/core/enums/universal/fields';

const { STRING, DATE, ATTRIBUTE, BOOLEAN, INTEGER } = FieldTypeEnum;

/**
 * This array describes all mergeable properties of the person profile model.
 * Additionally the order of these keys defines the order in which
 * fields are going to be rendered on the merge entities dashboard.
 */
const personProfileMergeableFields = [
    {
        prop: 'firstName',
        field: PERSON_PROFILE_FIRST_NAME,
        type: STRING,
    },
    {
        prop: 'middleName',
        field: PERSON_PROFILE_MIDDLE_NAME,
        type: STRING,
    },
    {
        prop: 'lastName',
        field: PERSON_PROFILE_LAST_NAME,
        type: STRING,
    },
    {
        prop: 'suffix',
        field: PERSON_PROFILE_SUFFIX,
        type: STRING,
    },
    {
        prop: 'title',
        field: PERSON_PROFILE_TITLE,
        type: STRING,
    },
    {
        // this is a fake prop which is always going to yield `undefined`
        // as value when it's being picked. This is fine because
        // merging will filter it out automatically. We need
        // to have a unique prop here because that's what's driving
        // the radio button inputs and storage of selections
        prop: DISPLAY_MERGE_ENTITIES_DATE_OF_BIRTH_GROUPING,
        field: DISPLAY_MERGE_ENTITIES_DATE_OF_BIRTH_GROUPING,
        relatedFields: [
            {
                prop: 'dateOfBirth',
                field: PERSON_PROFILE_DATE_OF_BIRTH,
                type: DATE,
            },
            {
                prop: 'dateOfBirthRangeStart',
                field: PERSON_PROFILE_DATE_OF_BIRTH_RANGE_START,
                type: DATE,
            },
            {
                prop: 'dateOfBirthRangeEnd',
                field: PERSON_PROFILE_DATE_OF_BIRTH_RANGE_END,
                type: DATE,
            },
        ],
    },
    {
        prop: 'isJuvenile',
        field: PERSON_PROFILE_IS_JUVENILE,
        type: BOOLEAN,
    },
    {
        prop: 'ssn',
        field: PERSON_PROFILE_SSN,
        type: STRING,
    },
    {
        prop: DISPLAY_MERGE_ENTITIES_DL_GROUPING,
        field: DISPLAY_MERGE_ENTITIES_DL_GROUPING,
        relatedFields: [
            {
                prop: 'dlTypeAttrId',
                field: PERSON_PROFILE_DL_TYPE_ATTR_ID,
                type: ATTRIBUTE,
            },
            {
                prop: 'dlStateAttrId',
                field: PERSON_PROFILE_DL_STATE_ATTR_ID,
                type: ATTRIBUTE,
            },
            {
                prop: 'dlNumber',
                field: PERSON_PROFILE_DL_NUMBER,
                type: STRING,
            },
            {
                prop: 'dlStatusAttrId',
                field: PERSON_PROFILE_DL_STATUS_ATTR_ID,
                type: ATTRIBUTE,
            },
            {
                prop: 'dlEndorsementAttrId',
                field: PERSON_PROFILE_DL_ENDORSEMENT_ATTR_ID,
                type: ATTRIBUTE,
            },
            {
                prop: 'dlTypeOther',
                field: PERSON_PROFILE_DL_TYPE_OTHER,
                type: STRING,
            },
        ],
    },
    {
        prop: 'fbiUcn',
        field: PERSON_PROFILE_FBI_UCN,
        type: STRING,
    },
    {
        prop: 'stateIdNumber',
        field: PERSON_PROFILE_STATE_ID_NUMBER,
        type: STRING,
    },
    {
        prop: 'sexAttrId',
        field: PERSON_PROFILE_SEX_ATTR_ID,
        type: ATTRIBUTE,
    },
    {
        prop: 'raceAttrId',
        field: PERSON_PROFILE_RACE_ATTR_ID,
        type: ATTRIBUTE,
    },
    {
        prop: 'ethnicityAttrId',
        field: PERSON_PROFILE_ETHNICITY_ATTR_ID,
        type: ATTRIBUTE,
    },
    {
        prop: 'placeOfBirth',
        field: PERSON_PROFILE_PLACE_OF_BIRTH,
        type: STRING,
    },
    {
        prop: 'birthStateAttrId',
        field: PERSON_PROFILE_BIRTH_STATE_ATTR_ID,
        type: ATTRIBUTE,
    },
    {
        prop: 'citizenshipAttrId',
        field: PERSON_PROFILE_CITIZENSHIP_ATTR_ID,
        type: ATTRIBUTE,
    },
    {
        prop: 'isResidentOfJurisdictionAttrId',
        field: PERSON_PROFILE_IS_RESIDENT_OF_JURISDICTION_ATTR_ID,
        type: ATTRIBUTE,
    },
    {
        prop: DISPLAY_MERGE_ENTITIES_HEIGHT_GROUPING,
        field: DISPLAY_MERGE_ENTITIES_HEIGHT_GROUPING,
        relatedFields: [
            {
                prop: 'height',
                field: PERSON_PROFILE_HEIGHT,
                type: INTEGER,
            },
            {
                prop: 'heightRangeMin',
                field: PERSON_PROFILE_HEIGHT_RANGE_MIN,
                type: INTEGER,
            },
            {
                prop: 'heightRangeMax',
                field: PERSON_PROFILE_HEIGHT_RANGE_MAX,
                type: INTEGER,
            },
        ],
    },
    {
        prop: DISPLAY_MERGE_ENTITIES_WEIGHT_GROUPING,
        field: DISPLAY_MERGE_ENTITIES_WEIGHT_GROUPING,
        relatedFields: [
            {
                prop: 'weight',
                field: PERSON_PROFILE_WEIGHT,
                type: INTEGER,
            },
            {
                prop: 'weightRangeMin',
                field: PERSON_PROFILE_WEIGHT_RANGE_MIN,
                type: INTEGER,
            },
            {
                prop: 'weightRangeMax',
                field: PERSON_PROFILE_WEIGHT_RANGE_MAX,
                type: INTEGER,
            },
        ],
    },
    {
        prop: 'skinToneAttrId',
        field: PERSON_PROFILE_SKIN_TONE_ATTR_ID,
        type: ATTRIBUTE,
    },
    {
        prop: 'eyeColorAttrId',
        field: PERSON_PROFILE_EYE_COLOR_ATTR_ID,
        type: ATTRIBUTE,
    },
    {
        prop: 'hairColorAttrId',
        field: PERSON_PROFILE_HAIR_COLOR_ATTR_ID,
        type: ATTRIBUTE,
    },
    {
        prop: 'hairStyleAttrId',
        field: PERSON_PROFILE_HAIR_STYLE_ATTR_ID,
        type: ATTRIBUTE,
    },
    {
        prop: 'hairLengthAttrId',
        field: PERSON_PROFILE_HAIR_LENGTH_ATTR_ID,
        type: ATTRIBUTE,
    },
    {
        prop: 'facialHairTypeAttrId',
        field: PERSON_PROFILE_FACIAL_HAIR_TYPE_ATTR_ID,
        type: ATTRIBUTE,
    },
    {
        prop: 'visionAttrId',
        field: PERSON_PROFILE_VISION_ATTR_ID,
        type: ATTRIBUTE,
    },
    {
        prop: 'buildAttrId',
        field: PERSON_PROFILE_BUILD_ATTR_ID,
        type: ATTRIBUTE,
    },
    {
        prop: 'maritalStatusAttrId',
        field: PERSON_PROFILE_MARITAL_STATUS_ATTR_ID,
        type: ATTRIBUTE,
    },
    {
        prop: 'needsInterpreter',
        field: PERSON_PROFILE_NEEDS_INTERPRETER,
        type: BOOLEAN,
    },
    {
        prop: 'priorHistoryOfDomesticViolence',
        field: PERSON_PROFILE_PRIOR_HISTORY_OF_DOMESTIC_VIOLENCE,
        type: BOOLEAN,
    },
    {
        prop: 'dateOfEmancipation',
        field: PERSON_PROFILE_DATE_OF_EMANCIPATION,
        type: DATE,
    },
    {
        prop: DISPLAY_MERGE_ENTITIES_DECEASED_GROUPING,
        field: DISPLAY_MERGE_ENTITIES_DECEASED_GROUPING,
        relatedFields: [
            {
                prop: 'isDead',
                field: PERSON_PROFILE_IS_DEAD,
                type: BOOLEAN,
            },
            {
                prop: 'dateOfDeathUtc',
                field: PERSON_PROFILE_DATE_OF_DEATH_UTC,
                type: DATE,
            },
        ],
    },
    {
        prop: DISPLAY_MERGE_ENTITIES_UNBORN_GROUPING,
        field: DISPLAY_MERGE_ENTITIES_UNBORN_GROUPING,
        relatedFields: [
            {
                prop: 'isUnborn',
                field: PERSON_PROFILE_IS_UNBORN,
                type: BOOLEAN,
            },
            {
                prop: 'expectedDateOfBirth',
                field: PERSON_PROFILE_EXPECTED_DATE_OF_BIRTH,
                type: DATE,
            },
        ],
    },
    {
        prop: DISPLAY_MERGE_ENTITIES_PERSON_GANG_TRACKING_GROUPING,
        field: DISPLAY_MERGE_ENTITIES_PERSON_GANG_TRACKING_GROUPING,
        relatedFields: [
            {
                prop: 'suspectedGangAffiliationAttrId',
                field: DISPLAY_SUSPECTED_GANG_AFFILIATION,
                type: ATTRIBUTE,
                format: true,
            },
            {
                prop: 'expirationDateUtc',
                field: DISPLAY_MERGE_ENTITIES_PERSON_GANG_TRACKING_EXPIRATION_DATE_UTC,
                type: DATE,
                format: true,
            },
        ],
    },
];

export default personProfileMergeableFields;
