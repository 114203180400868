import { AttributeTypeEnum } from '@mark43/rms-api';

import React from 'react';
import { Fieldset as MFTFieldset, Observer, Fields } from 'markformythree';
import useFields from '~/client-common/core/fields/hooks/useFields';
import { DISPLAY_ONLY_VEHICLE_SIDE_PANEL_IMPOUND_INFORMATION_LABEL } from '~/client-common/core/enums/universal/fields';

import { SidePanelSection } from '../../../../../../legacy-redux/components/core/SidePanel';
import IndentedFields from '../../../../../core/components/IndentedFields';
import Row from '../../../../../core/components/Row';
import { ArbiterMFTAttributeSelect } from '../../../../../core/forms/components/selects/AttributeSelect';
import { ArbiterMFTBooleanSelect } from '../../../../../core/forms/components/selects/BooleanSelect';
import { ArbiterMFTText } from '../../../../../core/forms/components/Text';
import testIds from '../../../../../../core/testIds';

function ImpoundInfoFieldset({ helpTextCollisionBoundary, isImpoundDisabled }) {
    const impoundInformationLabel = useFields(
        DISPLAY_ONLY_VEHICLE_SIDE_PANEL_IMPOUND_INFORMATION_LABEL
    )[DISPLAY_ONLY_VEHICLE_SIDE_PANEL_IMPOUND_INFORMATION_LABEL];
    return (
        <Observer
            subscriptions={{
                isImpoundedHidden: ['isImpounded', Fields.HIDDEN],
            }}
            render={({ isImpoundedHidden }) =>
                !isImpoundedHidden && (
                    <SidePanelSection
                        title={impoundInformationLabel}
                        testId={testIds.ITEM_SIDE_PANEL_IMPOUND_INFO_SECTION}
                    >
                        <Row>
                            <ArbiterMFTBooleanSelect
                                disabled={isImpoundDisabled}
                                helpTextCollisionBoundary={helpTextCollisionBoundary}
                                path="isImpounded"
                                length="sm"
                            />
                        </Row>
                        <MFTFieldset path="impoundInfo">
                            <IndentedFields>
                                <Row>
                                    <ArbiterMFTAttributeSelect
                                        helpTextCollisionBoundary={helpTextCollisionBoundary}
                                        path="towingCompanyAttrId"
                                        attributeType={
                                            AttributeTypeEnum.TOW_VEHICLE_TOW_COMPANY.name
                                        }
                                        length="lg"
                                    />
                                </Row>
                                <IndentedFields>
                                    <Row>
                                        <ArbiterMFTText
                                            helpTextCollisionBoundary={helpTextCollisionBoundary}
                                            path="towingCompanyOther"
                                            length="md"
                                        />
                                    </Row>
                                </IndentedFields>
                                <Row>
                                    <ArbiterMFTText
                                        helpTextCollisionBoundary={helpTextCollisionBoundary}
                                        path="towingNumber"
                                        length="lg"
                                    />
                                </Row>
                                <Row>
                                    <ArbiterMFTText
                                        helpTextCollisionBoundary={helpTextCollisionBoundary}
                                        path="towingLocation"
                                        length="lg"
                                    />
                                </Row>
                                <Row>
                                    <ArbiterMFTBooleanSelect
                                        helpTextCollisionBoundary={helpTextCollisionBoundary}
                                        path="wasVehicleSearched"
                                        length="md"
                                    />
                                    <ArbiterMFTBooleanSelect
                                        helpTextCollisionBoundary={helpTextCollisionBoundary}
                                        path="wasVehicleSearchConsentedTo"
                                        length="md"
                                    />
                                </Row>
                            </IndentedFields>
                        </MFTFieldset>
                    </SidePanelSection>
                )
            }
        />
    );
}

/**
 * Fieldset in the item entry v2 form that appears depending on a HIDE rule.
 */
export default ImpoundInfoFieldset;
