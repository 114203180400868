import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
    ConsolidatedLocationView,
    LinkTypesEnumType,
    LocationTypeEnum,
    LocationSourceEnum,
} from '@mark43/rms-api';
import { createGlobalStyle } from 'styled-components';
import {
    Box,
    cssVar,
    Button,
    Text,
    Stack,
    VStack,
    Popover,
    PopoverAnchor,
    PopoverContent,
    EmptyState,
    Spinner,
} from 'arc';
import { useSelector } from 'react-redux';
import { Map, MapRefShapeT } from '~/client-common/core/maps';
import componentStrings from '~/client-common/core/strings/componentStrings';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import getLocationsResource from '~/client-common/core/domain/locations/resources/locationsResource';
import Pill from '../../components/Pill';
import { STANDARD_ZOOM } from '../constants';
import { submitSearchForm } from '../state/ui';
import { useDisabledAddLocation } from '../hooks/useDisabledAddLocation';
import formsRegistry from '../../../../core/formsRegistry';
import { currentUserDepartmentProfileSelector } from '../../current-user/state/ui';
import testIds from '../../../../core/testIds';
import SubPremiseSearchInstruction from './SubPremiseSearchInstruction';
import LocationPill from './LocationPill';
import LocationSearchInput, { LOCATION_SEARCH_FORM } from './LocationSearchInput';

const strings = componentStrings.core.LocationSidePanel;

const GlobalStyle = createGlobalStyle`
    .dark [data-test-id=${testIds.LOCATION_SIDE_PANEL}],
    [data-test-id=${testIds.LOCATION_SIDE_PANEL}] {
        /**
         * Location map should flow to the edge of the sidepanel
         */
        .react-side-panel-content-wrapper {
            padding: 0;
            overflow: hidden;
        }

        /**
         * Ensure the sidepanel contents are expanding to fill the full height of the sidepanel
         */
        .react-side-panel-content {
            height: 100%;
            > * {
                height: 100%;
            }
        }
        /**
         * Don't display the spinner in the input because we are handling it in the popover
         */
        .location-side-panel-search-input {
            .chakra-spinner {
                display: none;

            }
        }
    }
`;

const AddNewLocationButton = (
    props: Pick<LocationMapSearchScreenProps, 'linkType' | 'onClickCreateLocation'> & {
        isFullWidth?: boolean;
    }
) => {
    const disabledAddLocation = useDisabledAddLocation(props.linkType);
    return (
        <Button
            data-testid={testIds.LOCATION_SIDE_PANEL_NEW_LOCATION}
            isFullWidth={props.isFullWidth}
            leadingVisual="Add"
            variant="ghost"
            disabled={disabledAddLocation}
            onClick={props.onClickCreateLocation}
            style={{
                justifyContent: 'flex-start',
            }}
        >
            {strings.addNew}
        </Button>
    );
};

type LocationMapSearchScreenProps = {
    onEditLocation: (location: ConsolidatedLocationView) => void;
    onClickCreateLocation: () => void;
    linkType: LinkTypesEnumType;
    globalSelectedLocation?: ConsolidatedLocationView;
    globalSearchResults: ConsolidatedLocationView[];
    setGlobalSearchResults: (searchResults: ConsolidatedLocationView[]) => void;
    setErrors: (errors: unknown) => void;
    renderAfterInput: React.ReactElement;
};

export const LocationMapSearchScreen = (props: LocationMapSearchScreenProps) => {
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const [isLoadingPin, setIsLoadingPin] = useState(false);

    // We track the selected location locally and "globally" to the side panel
    const [locationToCreate, setLocationToCreate] = useState<
        | {
              type: 'EXACT';
              location: ConsolidatedLocationView;
          }
        | {
              type: 'NEARBY';
              location: ConsolidatedLocationView;
              pointLocation?: ConsolidatedLocationView;
          }
        | {
              type: 'POINT';
              pointLocation: ConsolidatedLocationView;
          }
        | {
              type: 'EMPTY';
          }
    >();
    const [popoverMode, setPopoverMode] = useState<'INITIAL' | 'RESULTS' | 'LOADING'>('INITIAL');

    // By default, the map will be centered around the department's location
    const currentUserDepartmentProfile = useSelector(currentUserDepartmentProfileSelector);
    const departmentLocationBiasCenter = useMemo(() => {
        return {
            lat: currentUserDepartmentProfile?.locationBias.centerLat || 0,
            lng: currentUserDepartmentProfile?.locationBias.centerLng || 0,
        };
    }, [
        currentUserDepartmentProfile?.locationBias.centerLat,
        currentUserDepartmentProfile?.locationBias.centerLng,
    ]);

    const mapRef = React.useRef<MapRefShapeT>(null);

    const centerMap = useCallback((latitude: number, longitude: number) => {
        // Clear the map pin
        mapRef.current?.clearSelectedLocations();
        mapRef.current?.clearNearestLocations();
        // Set the nearby pin
        mapRef.current?.setSelectedLocation({
            lat: latitude,
            lng: longitude,
        });
        // Center the map around the pin
        mapRef.current?.updateMap({
            target: {
                lat: latitude,
                lng: longitude,
            },
            zoom: STANDARD_ZOOM,
        });
    }, []);

    const { setErrors } = props;
    const ensureCoordinates = useCallback(async (location: ConsolidatedLocationView) => {
        if (location.latitude && location.longitude) {
            return location;
        } else if (location.source === LocationSourceEnum.POSTCODER.name) {
            try {
                const locationsResource = getLocationsResource();
                const classifiedLocation: ConsolidatedLocationView = await locationsResource.retrieveAndClassifyPostcoder(location);
                return classifiedLocation;
            } catch (error) {
                setErrors(['Encountered an issue with location classification'])
                return null;
            }
        }
        return null;
    }, [setErrors]);

    const handleClickResult = useCallback(async (
            selectedLocation: ConsolidatedLocationView
        ) => {
            setIsPopoverOpen(false);
            await mapRef.current?.ready();
            const location = (await ensureCoordinates(selectedLocation)) || selectedLocation;
            if (location?.latitude && location?.longitude) {
                centerMap(location.latitude, location.longitude);
            }
            setLocationToCreate({ location, type: 'EXACT' });
        },
        [ensureCoordinates, centerMap]
    );

    const [portalRef, setPortalRef] = useState<HTMLDivElement | null>(null);

    const inputRef = useRef<HTMLInputElement>();

    useEffect(
        // This runs every time `props.globalSelectedLocation`
        // changes, but we only change it when we navigate away from the screen
        // Then, when we navigate back to the screen and the component mounts,
        // this will run
        function focusGlobalSelectedLocationOnMount() {
            if (props.globalSelectedLocation) {
                handleClickResult(props.globalSelectedLocation);
            }
        },
        [handleClickResult, props.globalSelectedLocation]
    );

    useEffect(function initializePopoverStateOnMount() {
        formsRegistry.maybeDeferredOperation(
            LOCATION_SEARCH_FORM,
            undefined,
            (locationSearchForm) => {
                setPopoverMode(!locationSearchForm.get('searchQuery') ? 'INITIAL' : 'RESULTS');
            }
        );
    }, []);

    const popoverContent = (() => {
        if (popoverMode === 'INITIAL') {
            return (
                <Stack
                    style={{
                        padding: cssVar('arc.space.4'),
                    }}
                >
                    <FeatureFlagged
                        flag="SUBPREMISE_SUPPORT_ENABLED"
                        fallback={<Text>{strings.searchHelpText}</Text>}
                    >
                        <SubPremiseSearchInstruction />
                    </FeatureFlagged>
                </Stack>
            );
        } else if (popoverMode === 'LOADING') {
            return (
                <div style={{ padding: cssVar('arc.space.6') }}>
                    <EmptyState title="" visual={<Spinner size="md" />} />
                </div>
            );
        } else {
            if (props.globalSearchResults.length) {
                return (
                    <>
                        {props.globalSearchResults.map((location, idx) => (
                            <div key={idx}>
                                <LocationPill
                                    testId={testIds.LOCATION_SIDE_PANEL_LOCATION_RESULT}
                                    onClick={() => handleClickResult(location)}
                                    location={location}
                                    mode="condensed"
                                />
                            </div>
                        ))}
                        <AddNewLocationButton
                            isFullWidth={true}
                            linkType={props.linkType}
                            onClickCreateLocation={props.onClickCreateLocation}
                        />
                    </>
                );
            } else {
                const emptyStateProps: React.ComponentProps<typeof EmptyState> = {
                    subtitle: strings.searchEmptyStateHelpText,
                    visual: 'Location',
                    title: strings.searchEmptyState,
                    actions: [
                        <AddNewLocationButton
                            key="add-location-button"
                            isFullWidth={false}
                            linkType={props.linkType}
                            onClickCreateLocation={props.onClickCreateLocation}
                        />,
                    ],
                };
                return (
                    <div style={{ padding: cssVar('arc.space.6') }}>
                        <EmptyState {...emptyStateProps} />
                    </div>
                );
            }
        }
    })();

    const mapResults = (() => {
        return locationToCreate || isLoadingPin ? (
            <div
                style={{
                    borderTopLeftRadius: cssVar('arc.space.3'),
                    borderTopRightRadius: cssVar('arc.space.3'),
                    position: 'absolute',
                    width: '100%',
                    bottom: '0',
                    backgroundColor: cssVar('arc.colors.surface.background'),
                    boxShadow: '0px -4px 6px -1px #0000001A',
                    padding: cssVar('arc.space.4'),
                }}
            >
                {isLoadingPin ? (
                    <Pill loading={true} />
                ) : locationToCreate?.type === 'EXACT' ? (
                    <LocationPill
                        location={locationToCreate.location}
                        onClick={() => {
                            props.onEditLocation(locationToCreate.location);
                        }}
                    />
                ) : (
                    // If the location type is `nearby` or `point`, or no location was found
                    <VStack gap={4} spacing={0}>
                        <Button
                            isFullWidth
                            leadingVisual="Add"
                            size="lg"
                            onClick={() => {
                                if (
                                    locationToCreate?.type !== 'EMPTY' &&
                                    locationToCreate?.pointLocation
                                ) {
                                    props.onEditLocation(locationToCreate.pointLocation);
                                } else {
                                    props.onClickCreateLocation();
                                }
                            }}
                        >
                            {strings.addNew}
                        </Button>
                        {locationToCreate?.type === 'NEARBY' ? (
                            <VStack
                                gap={1}
                                spacing={0}
                                style={{
                                    width: '100%',
                                    alignItems: 'flex-start',
                                }}
                            >
                                {locationToCreate.type === 'NEARBY' ? (
                                    <Text variant="bodyMd">{strings.mapNearestLocation}</Text>
                                ) : null}
                                <Box style={{ width: '100%' }}>
                                    <LocationPill
                                        location={locationToCreate.location}
                                        onClick={() => {
                                            props.onEditLocation(locationToCreate.location);
                                        }}
                                    />
                                </Box>
                            </VStack>
                        ) : (
                            <Text variant="bodyMd">{strings.mapEmptyState}</Text>
                        )}
                    </VStack>
                )}
            </div>
        ) : null;
    })();

    const handleMapClick = (e: __esri.ViewClickEvent) => {
        if (e?.mapPoint && e.mapPoint?.longitude && e.mapPoint?.latitude) {
            mapRef?.current?.clearNearestLocations();
            mapRef?.current?.setSelectedLocation({
                lat: e.mapPoint.latitude,
                lng: e.mapPoint.longitude,
            });

            setIsLoadingPin(true);
            submitSearchForm({
                query: `${e.mapPoint.latitude}, ${e.mapPoint.longitude}`,
                onSuccess: (results: ConsolidatedLocationView[]) => {
                    setIsLoadingPin(false);

                    const firstResult = results[0];
                    const firstResultType = firstResult?.type;
                    const firstResultResolverSource = firstResult?.resolverSource;
                    const nearestAddress =
                        (firstResultType === LocationTypeEnum.ADDRESS.name ||
                            firstResultType === LocationTypeEnum.INTERSECTION.name ||
                            firstResultType === LocationTypeEnum.POINT.name) &&
                        firstResultResolverSource !== LocationSourceEnum.LAT_LONG.name
                            ? firstResult
                            : undefined;

                    const nearestAddressLatitude = nearestAddress?.latitude;
                    const nearestAddressLongitude = nearestAddress?.longitude;

                    const nearestAddressIsValid = !!(
                        nearestAddress &&
                        nearestAddressLatitude &&
                        nearestAddressLongitude
                    );

                    const pointLocation = results.find(
                        (location) => location.resolverSource === LocationSourceEnum.LAT_LONG.name
                    );

                    // If we found a nearest location, we should set this
                    // as the primary location to create
                    if (nearestAddressIsValid) {
                        setLocationToCreate({
                            location: nearestAddress,
                            type: 'NEARBY',
                            pointLocation,
                        });
                        mapRef?.current?.setNearestLocation({
                            lat: nearestAddressLatitude,
                            lng: nearestAddressLongitude,
                        });
                    } else if (pointLocation) {
                        setLocationToCreate({
                            type: 'POINT',
                            pointLocation,
                        });
                    } else {
                        setLocationToCreate({
                            type: 'EMPTY',
                        });
                    }
                },
                onError: (errors: unknown) => {
                    setIsLoadingPin(false);
                    props.setErrors(errors);
                },
            });
        }
    };

    return (
        <>
            <GlobalStyle />
            <Stack
                style={{ display: 'flex', height: '100%', flexDirection: 'column' }}
                spacing={0}
                gap={0}
            >
                <Stack style={{ padding: cssVar('arc.sizes.4') }}>
                    <div style={{ position: 'relative' }} ref={setPortalRef}>
                        <Popover hasArrow={false} isOpen={isPopoverOpen}>
                            <LocationSearchInput
                                onInputOverride={(value: string) => {
                                    const onSuccess = (results: ConsolidatedLocationView[]) => {
                                        props.setGlobalSearchResults(results);
                                        setPopoverMode('RESULTS');
                                        props.setErrors([]);
                                    };

                                    // If the popver isn't open by the time we started typing,
                                    // then open it
                                    setIsPopoverOpen(true);

                                    if (!value || value.length < 4) {
                                        if (!value) {
                                            setPopoverMode('INITIAL');
                                        }
                                        return;
                                    }

                                    setPopoverMode('LOADING');

                                    props.setErrors([]);
                                    return submitSearchForm({
                                        query: value,
                                        onSuccess,
                                        onError: props.setErrors,
                                    });
                                }}
                                setRef={(input: HTMLInputElement) => {
                                    inputRef.current = input;
                                }}
                                onClick={() => {
                                    setIsPopoverOpen(true);
                                }}
                                storeResults={(results: ConsolidatedLocationView[]) => {
                                    props.setGlobalSearchResults(results);
                                    setIsPopoverOpen(true);
                                }}
                                setErrors={props.setErrors}
                            />
                            <PopoverAnchor asChild>
                                <div />
                            </PopoverAnchor>
                            <PopoverContent
                                portalProps={{
                                    container: portalRef,
                                }}
                                align="start"
                                hasPadding={false}
                                onEscapeKeyDown={() => setIsPopoverOpen(false)}
                                onInteractOutside={(e) => {
                                    if (e.target !== inputRef.current) {
                                        setIsPopoverOpen(false);
                                    }
                                }}
                                onOpenAutoFocus={(e) => e.preventDefault()}
                            >
                                <div
                                    style={{
                                        width: inputRef.current?.getBoundingClientRect().width,
                                        maxHeight: '400px',
                                        overflow: 'auto',
                                    }}
                                >
                                    {popoverContent}
                                </div>
                            </PopoverContent>
                        </Popover>
                    </div>
                    {props.renderAfterInput}
                </Stack>
                <div style={{ flex: 1, width: '100%' }}>
                    <Map
                        mapMode="streets-navigation-vector"
                        mapRef={mapRef}
                        defaultCenter={departmentLocationBiasCenter}
                        onClick={handleMapClick}
                        enableMove
                        enableZoom
                        zoom={STANDARD_ZOOM}
                        zoomForUpdate={STANDARD_ZOOM}
                        zoomButtonStyle="arc"
                    />
                </div>
                {mapResults}
            </Stack>
        </>
    );
};
