import React, { useEffect, useRef, useState, useContext } from 'react';
import { defer, indexOf, map, size, take, without, isFunction } from 'lodash';

import { connect, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import {
    FederatedSearchExternalReadDataContext,
    QueryableEntityType,
} from 'mark43-federated-search';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import { getNextFocusableElement } from '~/client-common/core/keyboardFocus/helpers';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { isUnknown } from '~/client-common/core/domain/person-profiles/utils/personProfilesHelpers';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { PersonQuickAddContext } from '../../context/PersonQuickAddContext';
import loadingStateEnum from '../../context/constants/loadingStateEnum';
import { requestRecentPersonsOrOrganizations } from '../../../../legacy-redux/actions/recentEntitiesActions';
import Icon, { iconTypes, iconSizes } from '../../components/Icon';
import { Button as ArcButton } from '../../components/Button';
import Button, { buttonTypes } from '../../../../legacy-redux/components/core/Button';
import { fetchAndBuildPersonProfileFormModel } from '../state/ui';
import {
    quickAddPerson,
    quickAddUnknownPerson,
    quickAddUnknownPersonForPole,
    SCREENS,
    sortedRecentElasticPersonsForContextSelector,
} from '../state/data';
import { KNOWN_PERSON, UNKNOWN_PERSON } from '../config/personProfileFormPersonTypes';
import { getFormContextForLinkType } from '../utils/getFormContextForLinkType';
import { RMSArbiterProvider } from '../../arbiter';
import QuickAddInstruction, {
    QuickAddInstructionContainer,
} from '../../components/QuickAddInstruction';
import { QuickAddGrid } from '../../components/QuickAddGrid';
import testIds from '../../../../core/testIds';
import { PersonSidePanel } from './PersonSidePanel';
import PersonPillSmall, { NO_INFO_KNOWN_ID } from './PersonPillSmall';

const strings = componentStrings.core;
const INITIAL_NUM_PERSONS_TO_SHOW = 4;

const PersonQuickAddContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    box-sizing: border-box;
    padding-right: 15px;
`;

const ExpandButtonContainer = styled.div`
    display: flex;
    width: 100%;
    margin-right: 15px;
    justify-content: center;
`;

const ExpandButton = styled(Button)`
    padding: 5px;
    margin: 5px auto 0;

    & > .react-icon-right {
        margin-left: 10px;
    }
`;

const PersonQuickAdd = ({
    className,
    renForRecents,
    contextType,
    contextId,
    ownerType,
    ownerId,
    linkType,
    showNoInfoKnown,
    onAddSuccess,
    requestRecentPersons,
    quickAddPerson,
    quickAddUnknownPerson,
    quickAddUnknownPersonForPole,
    personOverlayId,
    nibrsOffenseCode,
    setCancelFocusRef,
    onClickIdentify,
    sortedRecentElasticPersonsForContext,
    stubbedLinks,
}) => {
    const {
        isPersonStale,
        setIsPersonStale,
        personLoadingState,
        setPersonLoadingState,
    } = PersonQuickAddContext.useContainer();
    const expandedRef = useRef(null);
    const [isExpanded, setIsExpanded] = useState(false);
    const [workingEntityIds, setWorkingEntityIds] = useState([]);
    const formContext = getFormContextForLinkType(linkType);
    const applicationSettings = useSelector(applicationSettingsSelector);

    const { resultForConsumerApp } = useContext(FederatedSearchExternalReadDataContext) || {};
    const dexPersonResults = resultForConsumerApp?.[QueryableEntityType.PERSON];

    useEffect(() => {
        if (personLoadingState === loadingStateEnum.DO_LOAD) {
            setPersonLoadingState((prevLoadingState) => {
                if (prevLoadingState === loadingStateEnum.DO_LOAD) {
                    defer(() =>
                        requestRecentPersons({
                            renForRecents,
                            ownerType,
                            ownerId,
                            onComplete: () => {
                                setPersonLoadingState(loadingStateEnum.READY);
                                setIsPersonStale(false);
                            },
                        })
                    );
                    return loadingStateEnum.IN_PROGRESS;
                } else {
                    return prevLoadingState;
                }
            });
        }
    }, [
        personLoadingState,
        renForRecents,
        ownerType,
        ownerId,
        requestRecentPersons,
        setIsPersonStale,
        setPersonLoadingState,
    ]);

    useEffect(() => {
        if (isPersonStale) {
            setPersonLoadingState((prevLoadingState) =>
                prevLoadingState === loadingStateEnum.READY
                    ? loadingStateEnum.DO_LOAD
                    : prevLoadingState
            );
        }
    }, [isPersonStale, setPersonLoadingState]);

    useEffect(() => {
        if (isExpanded && expandedRef.current) {
            const wrappedInstance = expandedRef.current;
            if (!!wrappedInstance) {
                if (isFunction(wrappedInstance.focus)) {
                    wrappedInstance.focus();
                }
            }
        }
    }, [isExpanded, expandedRef]);

    const recentPersons = sortedRecentElasticPersonsForContext({
        renForRecents,
        ownerType,
        ownerId,
        linkType,
        contextId,
        stubbedLinks,
    });

    const recentPersonsCount = size(recentPersons);
    const initialNumPersonsToShow = showNoInfoKnown
        ? INITIAL_NUM_PERSONS_TO_SHOW - 1
        : INITIAL_NUM_PERSONS_TO_SHOW;
    const expandedRecentPersonsCount = isExpanded ? recentPersonsCount : initialNumPersonsToShow;

    return (
        <RMSArbiterProvider context={formContext}>
            {(arbiterInstance) => (
                <PersonQuickAddContainer className={className}>
                    {dexPersonResults?.length && (
                        <>
                            <QuickAddInstructionContainer>
                                {strings.QuickAddInstruction.addPersonFromDex}
                            </QuickAddInstructionContainer>
                            {dexPersonResults.map((dexPerson, i) => {
                                const elasticPerson = {
                                    ...dexPerson,
                                    lastName: dexPerson.lastName || dexPerson.name,
                                    dateOfBirth:
                                        dexPerson.dateOfBirth &&
                                        new Date(dexPerson.dateOfBirth).toISOString(),
                                    sexAttrDetail: {
                                        displayValue: dexPerson.sex,
                                    },
                                    raceAttrDetail: {
                                        displayValue: dexPerson.race,
                                    },
                                };
                                return (
                                    <QuickAddGrid key={i}>
                                        <PersonSidePanel
                                            overlayId={personOverlayId}
                                            renderButton={({ overlayBase: { open } }) => {
                                                return (
                                                    <PersonPillSmall
                                                        testId={testIds.QUICK_ADD_PERSON_PILL}
                                                        elasticPerson={elasticPerson}
                                                        onClick={open}
                                                    />
                                                );
                                            }}
                                            dexPerson={dexPerson}
                                            contextId={contextId}
                                            contextType={contextType}
                                            ownerId={ownerId}
                                            linkType={linkType}
                                            ownerType={ownerType}
                                        />
                                    </QuickAddGrid>
                                );
                            })}
                        </>
                    )}
                    {recentPersonsCount > 0 && (
                        <QuickAddInstruction
                            renForRecents={renForRecents}
                            type={strings.QuickAddInstruction.people}
                        />
                    )}
                    {recentPersonsCount > 0 || showNoInfoKnown ? (
                        <QuickAddGrid>
                            {recentPersonsCount > 0 &&
                                map(
                                    take(recentPersons, expandedRecentPersonsCount),
                                    (recentPerson, index) => (
                                        <PersonPillSmall
                                            key={recentPerson.id}
                                            testId={testIds.QUICK_ADD_PERSON_PILL}
                                            elasticPerson={recentPerson}
                                            disabled={
                                                indexOf(workingEntityIds, recentPerson.id) >= 0
                                            }
                                            ref={
                                                index === INITIAL_NUM_PERSONS_TO_SHOW
                                                    ? expandedRef
                                                    : null
                                            }
                                            onClick={(ref) => {
                                                setWorkingEntityIds([
                                                    ...workingEntityIds,
                                                    recentPerson.id,
                                                ]);
                                                setCancelFocusRef(ref);
                                                const nextFocusableElement = getNextFocusableElement(
                                                    ref.current
                                                );
                                                const personType = isUnknown(recentPerson)
                                                    ? UNKNOWN_PERSON
                                                    : KNOWN_PERSON;
                                                quickAddPerson({
                                                    entityId: recentPerson.id,
                                                    linkType,
                                                    ownerType,
                                                    ownerId,
                                                    contextType,
                                                    contextId,
                                                    nibrsOffenseCode,
                                                    onAddSuccess,
                                                    arbiterInstance,
                                                    formContext,
                                                    personOverlayId,
                                                    personType,
                                                    onComplete: () => {
                                                        setIsPersonStale(true);
                                                        setWorkingEntityIds(
                                                            without(
                                                                workingEntityIds,
                                                                recentPerson.id
                                                            )
                                                        );
                                                        if (nextFocusableElement) {
                                                            nextFocusableElement.focus();
                                                        }
                                                    },
                                                });
                                            }}
                                            onClickIdentify={(ref) => {
                                                onClickIdentify({
                                                    entityId: recentPerson.id,
                                                    personOverlayId,
                                                });
                                                setCancelFocusRef(ref);
                                            }}
                                        />
                                    )
                                )}
                            {showNoInfoKnown && (
                                <PersonPillSmall
                                    testId={testIds.QUICK_ADD_UNKNOWN_PILL}
                                    elasticPerson={{ id: NO_INFO_KNOWN_ID }}
                                    disabled={indexOf(workingEntityIds, NO_INFO_KNOWN_ID) >= 0}
                                    onClick={() => {
                                        setWorkingEntityIds([
                                            ...workingEntityIds,
                                            NO_INFO_KNOWN_ID,
                                        ]);
                                        if (applicationSettings.RMS_POLE_DATA_CAPTURE_ENABLED) {
                                            quickAddUnknownPersonForPole({
                                                linkType,
                                                ownerType,
                                                ownerId,
                                                contextType,
                                                contextId,
                                                onAddSuccess,
                                                onComplete: () => {
                                                    setIsPersonStale(true);
                                                    setWorkingEntityIds(
                                                        without(workingEntityIds, NO_INFO_KNOWN_ID)
                                                    );
                                                },
                                            });
                                        } else {
                                            quickAddUnknownPerson({
                                                linkType,
                                                ownerType,
                                                ownerId,
                                                contextType,
                                                contextId,
                                                onAddSuccess,
                                                onComplete: () => {
                                                    setIsPersonStale(true);
                                                    setWorkingEntityIds(
                                                        without(workingEntityIds, NO_INFO_KNOWN_ID)
                                                    );
                                                },
                                            });
                                        }
                                    }}
                                />
                            )}
                        </QuickAddGrid>
                    ) : null}
                    {!isExpanded && recentPersonsCount > initialNumPersonsToShow && (
                        <ExpandButtonContainer>
                            <FeatureFlagged
                                flag="ARC_RELEASE_CYCLE_ONE_COMPONENTS"
                                fallback={
                                    <ExpandButton
                                        className={buttonTypes.ICON_LINK}
                                        onClick={() => setIsExpanded(true)}
                                        iconRight={
                                            <Icon
                                                color="cobaltBlue"
                                                type={iconTypes.OPEN}
                                                size={iconSizes.MEDIUM}
                                            />
                                        }
                                        testId={testIds.PERSON_QUICK_ADD_EXPAND_BUTTON}
                                    >
                                        {strings.PersonQuickAdd.more(
                                            recentPersonsCount - initialNumPersonsToShow
                                        )}
                                    </ExpandButton>
                                }
                            >
                                <ArcButton
                                    onClick={() => setIsExpanded(true)}
                                    rightIcon="Open"
                                    variant="ghost"
                                    testId={testIds.PERSON_QUICK_ADD_EXPAND_BUTTON}
                                >
                                    {strings.PersonQuickAdd.more(
                                        recentPersonsCount - initialNumPersonsToShow
                                    )}
                                </ArcButton>
                            </FeatureFlagged>
                        </ExpandButtonContainer>
                    )}
                </PersonQuickAddContainer>
            )}
        </RMSArbiterProvider>
    );
};

const openIdentifySearch = ({ personOverlayId, entityId }) => (
    dispatch,
    getState,
    { overlayStore }
) => {
    dispatch(
        overlayStore.open(personOverlayId, {
            entityId,
            screenStack: [
                {
                    screen: SCREENS.IDENTIFY_UNKNOWN_SEARCH,
                    screenState: {
                        idToIdentify: entityId,
                    },
                },
            ],
        })
    );
};

const mapDispatchToProps = (dispatch) => ({
    requestRecentPersons: ({ renForRecents, ownerType, ownerId, onComplete }) => {
        return dispatch(
            requestRecentPersonsOrOrganizations({
                renForRecents,
                ownerType,
                ownerId,
                isOrg: false,
            })
        ).finally(() => {
            if (onComplete) {
                onComplete();
            }
        });
    },
    quickAddPerson: ({
        entityId,
        linkType,
        ownerType,
        ownerId,
        contextType,
        contextId,
        nibrsOffenseCode,
        onAddSuccess,
        onComplete,
        arbiterInstance,
        formContext,
        personOverlayId,
        personType,
    }) => {
        dispatch(
            fetchAndBuildPersonProfileFormModel({
                personProfileId: entityId,
                personType,
                linkType,
                ownerId,
                ownerType,
                nibrsOffenseCode,
                onSuccess: (formModel) => {
                    return dispatch(
                        quickAddPerson({
                            formModel,
                            contextType,
                            contextId,
                            ownerType,
                            ownerId,
                            linkType,
                            entityId,
                            onAddSuccess,
                            onComplete,
                            arbiterInstance,
                            formContext,
                            personOverlayId,
                        })
                    );
                },
            })
        );
    },
    quickAddUnknownPerson: ({
        entityId,
        linkType,
        ownerType,
        ownerId,
        contextType,
        contextId,
        onAddSuccess,
        onComplete,
    }) => {
        dispatch(
            quickAddUnknownPerson({
                contextId,
                contextType,
                linkType,
                entityId,
                ownerId,
                ownerType,
                onAddSuccess,
                onComplete,
            })
        );
    },
    quickAddUnknownPersonForPole: ({
        linkType,
        ownerType,
        ownerId,
        contextType,
        contextId,
        onAddSuccess,
        onComplete,
        nibrsOffenseCode,
        formContext,
        personOverlayId,
        arbiterInstance,
    }) => {
        dispatch(
            fetchAndBuildPersonProfileFormModel({
                personProfileId: undefined,
                personType: UNKNOWN_PERSON,
                linkType,
                ownerId,
                ownerType,
                nibrsOffenseCode,
                onSuccess: (formModel) => {
                    return dispatch(
                        quickAddUnknownPersonForPole({
                            formModel,
                            contextId,
                            contextType,
                            linkType,
                            entityId: formModel.id,
                            ownerId,
                            ownerType,
                            onAddSuccess,
                            onComplete,
                            formContext,
                            personOverlayId,
                            arbiterInstance,
                        })
                    );
                },
            })
        );
    },
    onClickIdentify: ({ entityId, personOverlayId }) =>
        dispatch(openIdentifySearch({ entityId, personOverlayId })),
});

const mapStateToProps = createStructuredSelector({
    sortedRecentElasticPersonsForContext: sortedRecentElasticPersonsForContextSelector,
});

export default connect(mapStateToProps, mapDispatchToProps)(PersonQuickAdd);
