import { EntityTypeEnumType } from '@mark43/rms-api';
import { createFormConfiguration, createFieldset, createNItems, createField } from 'markformythree';
import * as fields from '~/client-common/core/enums/universal/fields';

export const cautionFormConfiguration = createFormConfiguration({
    id: createField<number>({}),
    cautionAttrId: createField<number[]>({
        fieldName: fields.CAUTION_ATTRIBUTE_TYPE_VEHICLE_LABEL_ATTRIBUTES_CAUTION_ATTR_ID,
    }),
    cautionOther: createField<string>({
        fieldName: fields.CAUTION_ATTRIBUTE_TYPE_VEHICLE_LABEL_ATTRIBUTES_CAUTION_OTHER,
    }),
    dateEffectiveFrom: createField<string>({
        fieldName: fields.CAUTION_ATTRIBUTE_TYPE_VEHICLE_LABEL_ATTRIBUTES_DATE_EFFECTIVE_FROM,
    }),
    dateEffectiveTo: createField<string>({
        fieldName: fields.CAUTION_ATTRIBUTE_TYPE_VEHICLE_LABEL_ATTRIBUTES_DATE_EFFECTIVE_TO,
    }),
    description: createField<string>({
        fieldName: fields.CAUTION_ATTRIBUTE_TYPE_VEHICLE_LABEL_ATTRIBUTES_DESCRIPTION,
    }),
    provenance: createField<string>({
        fieldName: fields.CAUTION_ATTRIBUTE_TYPE_VEHICLE_LABEL_ATTRIBUTES_PROVENANCE,
    }),
});

export const formConfiguration = createFormConfiguration({
    itemTypeAttrId: createField<number>({
        fieldName: fields.ITEM_PROFILE_ITEM_TYPE_ATTR_ID,
    }),
    firearm: createFieldset({
        fields: {
            serialNumber: createField<string>({
                fieldName: fields.ITEM_PROFILE_ITEM_TYPE_FIREARM_SERIAL_NUMBER,
            }),
            serialNumberUnknown: createField<boolean>({
                fieldName: fields.DISPLAY_ONLY_ITEM_PROFILE_ITEM_TYPE_FIREARM_SERIAL_NUMBER_UNKNOWN,
            }),
            itemCategoryAttrId: createField<number>({
                fieldName: fields.ITEM_PROFILE_ITEM_TYPE_FIREARM_ITEM_CATEGORY_ATTR_ID,
            }),
            description: createField<string>({
                fieldName: fields.ITEM_PROFILE_ITEM_TYPE_FIREARM_DESCRIPTION,
            }),
            firearmMakeAttrId: createField<number>({
                fieldName: fields.FIREARM_FIREARM_MAKE_ATTR_ID,
            }),
            itemModel: createField<string>({
                fieldName: fields.ITEM_PROFILE_ITEM_TYPE_FIREARM_ITEM_MODEL,
            }),
            registrationNumber: createField<string>({
                fieldName: fields.FIREARM_REGISTRATION_NUMBER,
            }),
            finishAttrId: createField<number>({
                fieldName: fields.FIREARM_FINISH_ATTR_ID,
            }),
            stockAttrId: createField<number>({
                fieldName: fields.FIREARM_STOCK_ATTR_ID,
            }),
            gripAttrId: createField<number>({
                fieldName: fields.FIREARM_GRIP_ATTR_ID,
            }),
            caliber: createField<string>({
                fieldName: fields.FIREARM_CALIBER,
            }),
            numberOfShots: createField<string>({
                fieldName: fields.FIREARM_NUMBER_OF_SHOTS,
            }),
            barrelLength: createField<number>({
                fieldName: fields.FIREARM_BARREL_LENGTH,
            }),
            alterationIndicated: createField<boolean>({
                fieldName: fields.FIREARM_ALTERATION_INDICATED,
            }),
            isRenderedSafe: createField<boolean>({
                fieldName: fields.FIREARM_IS_RENDERED_SAFE,
            }),
            renderedSafeByOfficerId: createField<number>({
                fieldName: fields.FIREARM_RENDERED_SAFE_BY_OFFICER_ID,
            }),
            scopeAttachedAttrId: createField<number>({
                fieldName: fields.FIREARM_SCOPE_ATTACHED_ATTR_ID,
            }),
            indemnityObtainedAttrId: createField<number>({
                fieldName: fields.FIREARM_INDEMNITY_OBTAINED_ATTR_ID,
            }),
            compensationRequiredAttrId: createField<number>({
                fieldName: fields.FIREARM_COMPENSATION_REQUIRED_ATTR_ID,
            }),
            ncicFirearmCaliberAttrId: createField<number>({
                fieldName: fields.FIREARM_NCIC_FIREARM_CALIBER_ATTR_ID,
            }),
            atfManufacturerId: createField<number>({
                fieldName: fields.FIREARM_ATF_MANUFACTURER_ID,
            }),
        },
    }),
    drugs: createFieldset({
        fields: {
            description: createField<string>({
                fieldName: fields.ITEM_PROFILE_ITEM_TYPE_DRUGS_DESCRIPTION,
            }),
            itemCategoryAttrId: createField<number>({
                fieldName: fields.ITEM_PROFILE_ITEM_TYPE_DRUGS_ITEM_CATEGORY_ATTR_ID,
            }),
            isCounterfeit: createField<boolean>({
                fieldName: fields.ITEM_PROFILE_IS_COUNTERFEIT,
            }),
            isPrecursorChemical: createField<boolean>({
                fieldName: fields.ITEM_PROFILE_IS_PRECURSOR_CHEMICAL,
            }),
            wasContainerOpen: createField<boolean>({
                fieldName: fields.PROPERTY_STATUS_WAS_CONTAINER_OPEN,
            }),
            containerAmountRemaining: createField<string>({
                fieldName: fields.PROPERTY_STATUS_CONTAINER_AMOUNT_REMAINING,
            }),
            itemMake: createField<string>({
                fieldName: fields.ITEM_PROFILE_ITEM_TYPE_DRUGS_ITEM_MAKE,
            }),
            primaryColorAttrId: createField<number>({
                fieldName: fields.ITEM_PROFILE_ITEM_TYPE_DRUGS_PRIMARY_COLOR_ATTR_ID,
            }),
        },
    }),
    otherItem: createFieldset({
        fields: {
            itemCategoryAttrId: createField<number>({
                fieldName: fields.ITEM_PROFILE_ITEM_TYPE_ITEM_ITEM_CATEGORY_ATTR_ID,
            }),
            description: createField<string>({
                fieldName: fields.ITEM_PROFILE_ITEM_TYPE_ITEM_DESCRIPTION,
            }),
            primaryColorAttrId: createField<number>({
                fieldName: fields.ITEM_PROFILE_ITEM_TYPE_ITEM_PRIMARY_COLOR_ATTR_ID,
            }),
            secondaryColorAttrId: createField<number>({
                fieldName: fields.ITEM_PROFILE_ITEM_TYPE_ITEM_SECONDARY_COLOR_ATTR_ID,
            }),
            size: createField<string>({
                fieldName: fields.ITEM_PROFILE_SIZE,
            }),
            serialNumber: createField<string>({
                fieldName: fields.ITEM_PROFILE_ITEM_TYPE_ITEM_SERIAL_NUMBER,
            }),
            itemMake: createField<string>({
                fieldName: fields.ITEM_PROFILE_ITEM_TYPE_ITEM_ITEM_MAKE,
            }),
            itemModel: createField<string>({
                fieldName: fields.ITEM_PROFILE_ITEM_TYPE_ITEM_ITEM_MODEL,
            }),
        },
    }),
    vehicle: createFieldset({
        fields: {
            itemCategoryAttrId: createField<number>({
                fieldName: fields.ITEM_PROFILE_ITEM_TYPE_VEHICLE_ITEM_CATEGORY_ATTR_ID,
            }),
            vehicleMakeAttrId: createField<number>({
                fieldName: fields.VEHICLE_VEHICLE_MAKE_ATTR_ID,
            }),
            vehicleMakeId: createField<number>({
                fieldName: fields.VEHICLE_VEHICLE_MAKE_ID,
            }),
            vehicleModelAttrId: createField<number>({
                fieldName: fields.VEHICLE_VEHICLE_MODEL_ATTR_ID,
            }),
            vehicleModelId: createField<number>({
                fieldName: fields.VEHICLE_VEHICLE_MODEL_ID,
            }),
            itemMake: createField<string>({
                fieldName: fields.ITEM_PROFILE_ITEM_TYPE_VEHICLE_ITEM_MAKE,
            }),
            itemModel: createField<string>({
                fieldName: fields.ITEM_PROFILE_ITEM_TYPE_VEHICLE_ITEM_MODEL,
            }),
            description: createField<string>({
                fieldName: fields.ITEM_PROFILE_ITEM_TYPE_VEHICLE_DESCRIPTION,
            }),
            vinNumber: createField<string>({
                fieldName: fields.VEHICLE_VIN_NUMBER,
            }),
            tag: createField<string>({
                fieldName: fields.VEHICLE_TAG,
            }),
            yearOfManufacture: createField<number>({
                fieldName: fields.VEHICLE_YEAR_OF_MANUFACTURE,
            }),
            primaryColorAttrId: createField<number>({
                fieldName: fields.ITEM_PROFILE_ITEM_TYPE_VEHICLE_PRIMARY_COLOR_ATTR_ID,
            }),
            secondaryColorAttrId: createField<number>({
                fieldName: fields.ITEM_PROFILE_ITEM_TYPE_VEHICLE_SECONDARY_COLOR_ATTR_ID,
            }),
            bodyStyleAttrId: createField<number>({
                fieldName: fields.VEHICLE_BODY_STYLE_ATTR_ID,
            }),
            bodyStyleOther: createField<string>({
                fieldName: fields.VEHICLE_BODY_STYLE_OTHER,
            }),
            registrationYear: createField<number>({
                fieldName: fields.VEHICLE_REGISTRATION_YEAR,
            }),
            registrationStateAttrId: createField<number>({
                fieldName: fields.VEHICLE_REGISTRATION_STATE_ATTR_ID,
            }),
            registrationType: createField<string>({
                fieldName: fields.VEHICLE_REGISTRATION_TYPE,
            }),
            mileage: createField<number>({
                fieldName: fields.VEHICLE_MILEAGE,
            }),
            insuranceProviderName: createField<string>({
                fieldName: fields.VEHICLE_INSURANCE_PROVIDER_NAME,
            }),
            insurancePolicyNumber: createField<string>({
                fieldName: fields.VEHICLE_INSURANCE_POLICY_NUMBER,
            }),
            wasAntifreezeInVehicle: createField<boolean>({
                fieldName: fields.VEHICLE_WAS_ANTIFREEZE_IN_VEHICLE,
            }),
            wasRadiatorTaggedAndDrained: createField<boolean>({
                fieldName: fields.VEHICLE_WAS_RADIATOR_TAGGED_AND_DRAINED,
            }),
            vehicleModelHasBodyStyleOptions: createField<boolean>({
                fieldName: fields.DISPLAY_ONLY_VEHICLE_MODEL_HAS_BODY_STYLE_OPTIONS,
            }),
        },
    }),
    sharedItemDetails: createFieldset({
        fields: {
            isBiohazard: createField<boolean>({
                fieldName: fields.ITEM_PROFILE_IS_BIOHAZARD,
            }),
            biohazardDescription: createField<string>({
                fieldName: fields.ITEM_PROFILE_BIOHAZARD_DESCRIPTION,
            }),
            firearmQuantity: createField<number>({
                fieldName: fields.PROPERTY_STATUS_ITEM_TYPE_FIREARM_QUANTITY,
            }),
            drugQuantity: createField<number>({
                fieldName: fields.PROPERTY_STATUS_ITEM_TYPE_DRUGS_QUANTITY,
            }),
            otherItemQuantity: createField<string>({
                fieldName: fields.PROPERTY_STATUS_ITEM_TYPE_ITEM_QUANTITY,
            }),
            isQuantityUnknown: createField<boolean>({
                fieldName: fields.PROPERTY_STATUS_IS_QUANTITY_UNKNOWN,
            }),
            measurementUnitsAttrId: createField<number>({
                fieldName: fields.PROPERTY_STATUS_MEASUREMENT_UNITS_ATTR_ID,
            }),
            drugQuantityTwo: createField<number>({
                fieldName: fields.PROPERTY_STATUS_QUANTITIES_ITEM_TYPE_DRUGS_QUANTITY_TWO,
            }),
            measurementUnitsTwoAttrId: createField<number>({
                fieldName:
                    fields.PROPERTY_STATUS_QUANTITIES_ITEM_TYPE_DRUGS_MEASUREMENT_UNITS_TWO_ATTR_ID,
            }),
            drugQuantityThree: createField<number>({
                fieldName: fields.PROPERTY_STATUS_QUANTITIES_ITEM_TYPE_DRUGS_QUANTITY_THREE,
            }),
            measurementUnitsThreeAttrId: createField<number>({
                fieldName:
                    fields.PROPERTY_STATUS_QUANTITIES_ITEM_TYPE_DRUGS_MEASUREMENT_UNITS_THREE_ATTR_ID,
            }),
            returnToOwnerAttrId: createField<number>({
                fieldName: fields.ITEM_PROFILE_RETURN_TO_OWNER_ATTR_ID,
            }),
            drugDataSourceAttrId: createField<number>({
                fieldName: fields.ITEM_ATTRIBUTE_ATTRIBUTE_TYPE_DRUGS_DATA_SOURCE_ATTRIBUTE_ID,
            }),
            itemIdentifiers: createNItems({
                fields: {
                    id: createField<number>({}),
                    itemIdentifierTypeAttrId: createField<number>({
                        fieldName: fields.ITEM_IDENTIFIER_ITEM_IDENTIFIER_TYPE_ATTR_ID,
                    }),
                    itemIdentifierTypeOtherDesc: createField<string>({
                        fieldName: fields.ITEM_IDENTIFIER_ITEM_IDENTIFIER_TYPE_OTHER_DESC,
                    }),
                    identifier: createField<string>({
                        fieldName: fields.ITEM_IDENTIFIER_IDENTIFIER,
                    }),
                },
            }),
            wasNcicCheckMadeForIdentifiableProperty: createField<boolean>({
                fieldName: fields.PROPERTY_STATUS_WAS_NCIC_CHECK_MADE_FOR_IDENTIFIABLE_PROPERTY,
            }),
            ncicSearchNumbers: createField<number>({
                fieldName: fields.PROPERTY_STATUS_NCIC_SEARCH_NUMBERS,
            }),
            ncicNumber: createField<string>({
                fieldName: fields.PROPERTY_STATUS_NCIC_NUMBER,
            }),
        },
    }),
    nameItemLinks: createNItems({
        fields: {
            id: createField<number>({}), // used to update existing nameItemLinks
            entityType: createField<EntityTypeEnumType>({}), // used to create/update nameItemLinks
            linkType: createField<EntityTypeEnumType>({}), // deprecated, here for backwards compatibility
            nameId: createField<number>({
                fieldName: fields.NAME_ITEM_LINK_NAME_ID,
            }),
            nameItemAssociationAttrId: createField<number>({
                fieldName: fields.NAME_ITEM_LINK_NAME_ITEM_ASSOCIATION_ATTR_ID,
            }),
            nameItemAssociationOther: createField<string>({
                fieldName: fields.NAME_ITEM_LINK_NAME_ITEM_ASSOCIATION_OTHER,
            }),
            dateEffectiveFrom: createField<string>({
                fieldName: fields.NAME_ITEM_LINK_DATE_EFFECTIVE_FROM,
            }),
            dateEffectiveTo: createField<string>({
                fieldName: fields.NAME_ITEM_LINK_DATE_EFFECTIVE_TO,
            }),
            proofOfOwnershipAttrId: createField<number>({
                fieldName: fields.NAME_ITEM_LINK_PROOF_OF_OWNERSHIP_ATTR_ID,
            }),
            proofOfOwnershipOther: createField<string>({
                fieldName: fields.NAME_ITEM_LINK_PROOF_OF_OWNERSHIP_OTHER,
            }),
        },
    }),
    propertyStatuses: createNItems({
        fields: {
            id: createField<number>({}), // used to update existing propertyStatuses
            propertyStatusAttrId: createField<number>({
                fieldName: fields.PROPERTY_STATUS_PROPERTY_STATUS_ATTR_ID,
            }),
            vehicleRecoveryTypeAttrId: createField<number>({
                fieldName: fields.PROPERTY_STATUS_VEHICLE_RECOVERY_TYPE_ATTR_ID,
            }),
            statusDateUtc: createField<string>({
                fieldName: fields.PROPERTY_STATUS_STATUS_DATE_UTC,
            }),
            declaredValue: createField<number>({
                fieldName: fields.PROPERTY_STATUS_DECLARED_VALUE,
            }),
            declaredValueUnknown: createField<boolean>({
                fieldName: fields.PROPERTY_STATUS_DECLARED_VALUE_UNKNOWN,
            }),
            marijuanaTypeAttrId: createField<number>({
                fieldName: fields.PROPERTY_STATUS_MARIJUANA_TYPE_ATTR_ID,
            }),
            marijuanaQuantity: createField<number>({
                fieldName: fields.PROPERTY_STATUS_MARIJUANA_QUANTITY,
            }),
            forfeitureValue: createField<number>({
                fieldName: fields.PROPERTY_STATUS_FORFEITURE_VALUE,
            }),
        },
    }),
    impoundInfo: createFieldset({
        fields: {
            towingCompanyAttrId: createField<number>({
                fieldName: fields.PROPERTY_STATUS_TOWING_COMPANY_ATTR_ID,
            }),
            towingCompanyOther: createField<string>({
                fieldName: fields.PROPERTY_STATUS_TOWING_COMPANY_OTHER,
            }),
            towingNumber: createField<string>({
                fieldName: fields.PROPERTY_STATUS_TOWING_NUMBER,
            }),
            towingLocation: createField<string>({
                fieldName: fields.PROPERTY_STATUS_TOWING_LOCATION,
            }),
            wasVehicleSearched: createField<boolean>({
                fieldName: fields.PROPERTY_STATUS_WAS_VEHICLE_SEARCHED,
            }),
            wasVehicleSearchConsentedTo: createField<boolean>({
                fieldName: fields.PROPERTY_STATUS_WAS_VEHICLE_SEARCH_CONSENTED_TO,
            }),
        },
    }),
    isImpounded: createField<boolean>({
        fieldName: fields.PROPERTY_STATUS_IS_IMPOUNDED,
    }),
    attributeLinks: createFieldset({
        fields: {
            modelNumberLocationAttrIds: createField<number[]>({
                fieldName:
                    fields.ITEM_ATTRIBUTE_ATTRIBUTE_TYPE_FIREARM_MODEL_NUMBER_LOCATION_ATTRIBUTE_ID,
            }),
            serialNumberLocationAttrIds: createField<number[]>({
                fieldName:
                    fields.ITEM_ATTRIBUTE_ATTRIBUTE_TYPE_FIREARM_SERIAL_NUMBER_LOCATION_ATTRIBUTE_ID,
            }),
            vehicleCautionAttrIds: createField<number[]>({
                fieldName:
                    fields.ITEM_ATTRIBUTE_ATTRIBUTE_TYPE_VEHICLE_LABEL_ATTRIBUTES_ATTRIBUTE_ID,
            }),
            vehicleCautionDescription: createField<string>({
                fieldName:
                    fields.ITEM_ATTRIBUTE_ATTRIBUTE_TYPE_VEHICLE_LABEL_ATTRIBUTES_DESCRIPTION,
            }),
            vehicleAdditionalSecondaryColorAttrIds: createField<number[]>({
                fieldName:
                    fields.ITEM_PROFILE_ITEM_TYPE_VEHICLE_ADDITIONAL_SECONDARY_COLORS_ATTR_ID_LINK,
            }),
        },
    }),
    cautions: createNItems({
        fields: {
            ...cautionFormConfiguration,
        },
    }),
    isInPoliceCustody: createField<boolean>({
        fieldName: fields.PROPERTY_STATUS_IS_IN_POLICE_CUSTODY,
    }),
    custodyStatus: createFieldset({
        fields: {
            reasonForPoliceCustodyAttrId: createField<number>({
                fieldName: fields.PROPERTY_STATUS_REASON_FOR_POLICE_CUSTODY_ATTR_ID,
            }),
            policeCustodyTypeEntityType: createField<EntityTypeEnumType>({
                fieldName: fields.PROPERTY_STATUS_POLICE_CUSTODY_TYPE_ENTITY_TYPE,
            }),
            storageFacility: createField<string>({
                fieldName: fields.PROPERTY_STATUS_STORAGE_FACILITY,
            }),
            storageLocation: createField<string>({
                fieldName: fields.PROPERTY_STATUS_STORAGE_LOCATION,
            }),
            storageLocationId: createField<number>({
                fieldName: fields.ITEM_FACILITY_LINK_STORAGE_LOCATION_ID,
            }),
            infieldTransferByOfficerId: createField<number>({
                fieldName: fields.PROPERTY_STATUS_INFIELD_TRANSFER_BY_OFFICER_ID,
            }),
            exhibitNumber: createField<string>({
                fieldName: fields.ITEM_PROFILE_EXHIBIT_NUMBER,
            }),
        },
    }),
    recoveryInfo: createFieldset({
        fieldName: fields.DISPLAY_ONLY_ITEM_RECOVERY_INFO_WRAPPER,
        fields: {
            recoveredByOfficerId: createField<number>({
                fieldName: fields.PROPERTY_STATUS_RECOVERED_BY_OFFICER_ID,
            }),
            recoveredByOtherName: createField<string>({
                fieldName: fields.PROPERTY_STATUS_RECOVERED_BY_OTHER_NAME,
            }),
            recoveredDateUtc: createField<string>({
                fieldName: fields.PROPERTY_STATUS_RECOVERED_DATE_UTC,
            }),
            collectedFromEntityType: createField<EntityTypeEnumType>({
                fieldName: fields.PROPERTY_STATUS_COLLECTED_FROM_ENTITY_TYPE,
            }),
            collectedVehicleRegistrationNumber: createField<string>({
                fieldName: fields.PROPERTY_STATUS_COLLECTED_VEHICLE_REGISTRATION_NUMBER,
            }),
            collectedVehicleStateAttrId: createField<number>({
                fieldName: fields.PROPERTY_STATUS_COLLECTED_VEHICLE_STATE_ATTR_ID,
            }),
            locationEntityLink: createFieldset({
                fields: {
                    locationId: createField<number>({
                        fieldName:
                            fields.LOCATION_ENTITY_LINK_LINK_TYPE_PROPERTY_RECOVERED_LOCATION_LOCATION_ID,
                    }),
                    positionAttrId: createField<number>({
                        fieldName:
                            fields.LOCATION_ENTITY_LINK_LINK_TYPE_PROPERTY_RECOVERED_LOCATION_POSITION_ATTR_ID,
                    }),
                    description: createField<string>({
                        fieldName:
                            fields.LOCATION_ENTITY_LINK_LINK_TYPE_PROPERTY_RECOVERED_LOCATION_DESCRIPTION,
                    }),
                },
            }),
            intakePerson: createField<string>({
                fieldName: fields.PROPERTY_STATUS_INTAKE_PERSON,
            }),
            ownerNotified: createField<boolean>({
                fieldName: fields.PROPERTY_STATUS_OWNER_NOTIFIED,
            }),
            notifiedDateUtc: createField<string>({
                fieldName: fields.PROPERTY_STATUS_NOTIFIED_DATE_UTC,
            }),
            notifierName: createField<string>({
                fieldName: fields.PROPERTY_STATUS_NOTIFIER_NAME,
            }),
            statementOfFacts: createField<string>({
                fieldName: fields.PROPERTY_STATUS_STATEMENT_OF_FACTS,
            }),
        },
    }),
    itemLocations: createFieldset({
        fields: {
            otherLocations: createNItems({
                fields: {
                    positionAttrId: createField<number>({
                        fieldName: fields.LOCATION_ENTITY_LINK_ITEM_LOCATION_POSITION_ATTR_ID,
                    }),
                    description: createField<string>({
                        fieldName: fields.LOCATION_ENTITY_LINK_ITEM_LOCATION_DESCRIPTION,
                    }),
                },
            }),
        },
    }),
    offenseDateUtc: createField<string>({
        fieldName: fields.OFFENSE_OFFENSE_DATE_UTC,
    }),
});

export type HydratedItemFormConfiguration = typeof formConfiguration;
