import reportCardEnum from '~/client-common/core/enums/universal/reportCardEnum';

import { RmsDispatch } from '../../../../../core/typings/redux';
import { RootState } from '../../../../../legacy-redux/reducers/rootReducer';
import createCard from '../../utils/createCard';

// Note: We are adding type assertions to work around the fact that our `createCard`
// abstraction has not been converted to typescript. This work is best suited in another PR
// https://mark43.atlassian.net/browse/RND-18624

const baseCard = createCard({
    anchor: 'traffic-crash-location-card',
    baseSelector: (state: RootState) => state.ui.report.trafficCrashLocationCard,
    name: reportCardEnum.CRASH_LOCATION_INFO.name,
});

export default {
    ...baseCard,
    actionCreators: {
        ...baseCard.actionCreators,
        edit: () => (dispatch: RmsDispatch) =>
            dispatch(baseCard.actionCreators.transitionToEditMode()),
        save: () => (dispatch: RmsDispatch) => {
            dispatch(baseCard.actionCreators.savingSuccess());
            return Promise.resolve();
        },
    },
};
