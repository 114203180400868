import { BatchOperationItemStatusEnum } from '@mark43/rms-api';
import React from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { map, noop } from 'lodash';
import componentStrings from '~/client-common/core/strings/componentStrings';
import FormattedDate from '~/client-common/core/dates/components/FormattedDate';

import { useCaseFieldName } from '~/client-common/core/fields/hooks/useFields';
import { casesBatchOperationsSelector } from '../state/ui';
import testIds from '../../../../core/testIds';

const strings = componentStrings.cases.core.BulkBanner;

const FAILED = 'FAILED';

const bannerStateToBackgroundColor = ({ bannerState, theme }) => {
    switch (bannerState) {
        case BatchOperationItemStatusEnum.READY.name:
        case BatchOperationItemStatusEnum.PROCESSING.name:
            return theme.colors.lightYellow;
        case BatchOperationItemStatusEnum.COMPLETE.name:
        case BatchOperationItemStatusEnum.COMPLETE_WITH_ERROR.name:
            return theme.colors.lightGreen;
        case FAILED:
            return 'var(--arc-colors-negative-default)';
        default:
            return theme.colors.white;
    }
};

const bannerStateToBorderColor = ({ bannerState, theme }) => {
    switch (bannerState) {
        case BatchOperationItemStatusEnum.READY.name:
        case BatchOperationItemStatusEnum.PROCESSING.name:
            return theme.colors.brightYellow;
        case BatchOperationItemStatusEnum.COMPLETE.name:
        case BatchOperationItemStatusEnum.COMPLETE_WITH_ERROR.name:
            return 'var(--arc-colors-positive-default)';
        case FAILED:
            return 'var(--arc-colors-negative-default)';
        default:
            return theme.colors.white;
    }
};

const bannerStateToBannerTitle = (bannerState) => {
    switch (bannerState) {
        case BatchOperationItemStatusEnum.READY.name:
        case BatchOperationItemStatusEnum.PROCESSING.name:
            return strings.inProgressTitle;
        case BatchOperationItemStatusEnum.COMPLETE.name:
            return strings.successfulTitle;
        case BatchOperationItemStatusEnum.COMPLETE_WITH_ERROR.name:
            return strings.partialSuccessTitle;
        case FAILED:
            return strings.failedTitle;
        default:
            return noop;
    }
};

const bannerStateToBannerDescription = ({
    bannerState,
    errorCount,
    operationCount,
    casesDisplayName,
}) => {
    switch (bannerState) {
        case BatchOperationItemStatusEnum.READY.name:
        case BatchOperationItemStatusEnum.PROCESSING.name:
            return strings.inProgressDescription;
        case BatchOperationItemStatusEnum.COMPLETE.name:
            return strings.successfulDescription;
        case BatchOperationItemStatusEnum.COMPLETE_WITH_ERROR.name:
            return strings.partialSuccessDescription(errorCount, operationCount);
        case FAILED:
            return strings.failedDescription(casesDisplayName);
        default:
            return '';
    }
};

const BannerContainer = styled.div`
    margin-bottom: 11px;
    padding-top: 14px;
`;

const Banner = styled.div`
    background-color: ${bannerStateToBackgroundColor};
    font-family: ${(props) => props.theme.fontFamilies.proximaNova};
    font-size: var(--arc-fontSizes-sm);
    padding: 17px 20px 17px 20px;
    margin-top: 15px;
    border: 1px solid ${bannerStateToBorderColor};
    border-radius: 4px;
`;

const BannerTitle = styled.div`
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
`;

const BannerDate = styled.span`
    font-style: italic;
    font-weight: ${(props) => props.theme.fontWeights.regular};
    float: right;
`;

const BatchBanners = ({ batchOperations }) => {
    const { pluralCaseFieldName: casesDisplayName } = useCaseFieldName();
    return (
        <BannerContainer>
            {map(batchOperations, ({ id, status, startDateUtc, operationCount, errorCount }) => {
                const bannerState = operationCount === errorCount ? FAILED : status;
                return (
                    <Banner bannerState={bannerState} key={id} data-test-id={testIds.BATCH_BANNER}>
                        <BannerTitle>
                            {bannerStateToBannerTitle(bannerState)(operationCount)}
                            <BannerDate>
                                {`${strings.startedAt} `}
                                <FormattedDate
                                    date={startDateUtc}
                                    format={FormattedDate.FORMATS.FORM_TIME}
                                />
                            </BannerDate>
                        </BannerTitle>
                        {bannerStateToBannerDescription({
                            bannerState,
                            errorCount,
                            operationCount,
                            casesDisplayName,
                        })}
                    </Banner>
                );
            })}
        </BannerContainer>
    );
};

const mapStateToProps = createStructuredSelector({
    batchOperations: casesBatchOperationsSelector,
});

export default connect(mapStateToProps)(BatchBanners);
