import { EntityTypeEnum, ProductModuleEnum, OperationTypeEnum } from '@mark43/rms-api';
import React, { useCallback, useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose, withPropsOnChange } from 'recompose';
import { withRouter } from 'react-router';
import styled from 'styled-components';

import {
    FederatedSearchExternalReadDataContext,
    FederatedSearchExternalWriteDataContext,
} from 'mark43-federated-search';
import ProductModuled from '~/client-common/core/domain/product-modules/components/ProductModuled';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { itemHasChainEventsSelector } from '~/client-common/core/domain/chain-events/state/data';
import OnlyWithEntityPermission from '~/client-common/core/domain/entity-permissions/components/OnlyWithEntityPermission';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import { FederatedSearchSidePanel } from '../../../../federated-search/components/FederatedSearchSidePanel';
import { SCREENS } from '../../../../core/locations/state/ui';
import { useOverlayStore } from '../../../../core/overlays/hooks/useOverlayStore';
import { OnlyWithAbility, abilitiesEnum } from '../../../../core/abilities';
import Button, { buttonTypes } from '../../../../../legacy-redux/components/core/Button';
import Icon, { iconTypes } from '../../../../core/components/Icon';
import GenericPermissionsModal from '../../../../core/permissions/components/GenericPermissionsModal';
import {
    currentEntityProfileTypeSelector,
    currentEntityProfileIdSelector,
    currentEntityProfileUserOperationTypesSelector,
    currentEntityProfileHasVisiblePermissionsSelector,
    currentEntityProfileIsEditableSelector,
    currentEntityProfileDepartmentIdSelector,
    personDetailsViewModelSelector,
} from '../../state/ui';
import { currentUserDepartmentIdSelector } from '../../../../core/current-user/state/ui';
import EvidenceItemMenu from '../../../../evidence/core/components/EvidenceItemMenu';
import RecordsHeaderButton from '../../../../records/core/components/header/RecordsHeaderButton';
import RecordsHeaderPermissionsButton from '../../../../records/core/components/header/RecordsHeaderPermissionsButton';
import RecordsHeaderExportsButton from '../../../../records/core/components/header/RecordsHeaderExportsButton';
import RecordsHeaderCurrentViewersToggle from '../../../../records/core/components/header/RecordsHeaderCurrentViewersToggle';
import routesConfig from '../../../../../routing/routesConfig';
import testIds from '../../../../../core/testIds';

const strings = componentStrings.entityProfiles.EntityProfileHeaderMenu;

const HeaderMenu = styled.div`
    float: right;
    display: flex;
    flex-shrink: 0;
    justify-content: flex-end;
    gap: var(--arc-space-1);
    flex-wrap: wrap;
`;

const Container = styled.span`
    display: inline-flex;
`;

const HeaderInlineWrapper = styled.div`
    display: inline-block;
`;

function EntityProfileHeaderMenu({
    entityType,
    entityId,
    itemHasChainEvents,
    userOperationTypes,
    editProfileSidePanel,
    visiblePermissions,
    isEditable,
    isExternalProfile,
    entityName,
    isHidden,
}) {
    const overlayId = overlayIdEnum.ENTITY_PROFILE_PERMISSIONS_OVERLAY;

    const { resultForConsumerApp } = useContext(FederatedSearchExternalReadDataContext) || {};
    const { resetResultForConsumerApp } = useContext(FederatedSearchExternalWriteDataContext) || {};
    const overlayStore = useOverlayStore();

    const addDexPersonToProfile = useCallback(
        (dexPerson) => {
            overlayStore.close(overlayIdEnum.DEX_SIDE_PANEL);
            overlayStore.open(overlayIdEnum.PERSON_OVERLAY_ENTITY_PROFILE_PERSON_HEADER, {
                ownerId: entityId,
                dexPerson,
                onCloseAction: resetResultForConsumerApp,
                screenStack: [
                    {
                        screen: SCREENS.PROFILE_EDIT,
                        screenState: {
                            currentScreen: SCREENS.PROFILE_EDIT,
                        },
                    },
                ],
            });
        },
        [entityId, overlayStore, resetResultForConsumerApp]
    );

    useEffect(() => {
        if (resultForConsumerApp?.PERSON?.[0]) {
            addDexPersonToProfile(resultForConsumerApp.PERSON?.[0]);
        }
    }, [addDexPersonToProfile, resultForConsumerApp?.PERSON]);

    const showSearchFromDex =
        !isExternalProfile &&
        isEditable &&
        editProfileSidePanel &&
        entityType === EntityTypeEnum.PERSON_PROFILE.name;

    return (
        <HeaderMenu>
            {showSearchFromDex && (
                <ProductModuled productModule={ProductModuleEnum.RMS_DEX.name}>
                    <OnlyWithAbility
                        hasOneOf={[
                            abilitiesEnum.RMS_DEX.RMS_DEX_MAKE_INQUIRIES,
                            abilitiesEnum.RMS_DEX.RMS_DEX_VIEW_RESULTS,
                        ]}
                    >
                        <FederatedSearchSidePanel
                            addDexPersonToProfile={addDexPersonToProfile}
                            renderButton={() => (
                                <Button
                                    className={buttonTypes.ICON}
                                    onClick={() => {
                                        overlayStore.open(overlayIdEnum.DEX_SIDE_PANEL);
                                    }}
                                >
                                    <Icon size={18} type={iconTypes.DATA_EXHANGE_INQUIRIES} />
                                </Button>
                            )}
                        />
                    </OnlyWithAbility>
                </ProductModuled>
            )}
            {!isExternalProfile &&
                visiblePermissions &&
                entityType === EntityTypeEnum.PERSON_PROFILE.name && (
                    <RecordsHeaderExportsButton
                        exportRouteName={routesConfig.ENTITY_PROFILE_EXPORTS.name}
                        path={`/profiles/persons/${entityId}`}
                    />
                )}
            {!isExternalProfile && visiblePermissions && (
                <Container>
                    {isHidden ? (
                        <RecordsHeaderPermissionsButton />
                    ) : (
                        <GenericPermissionsModal
                            buttonElement={<RecordsHeaderPermissionsButton overlayId={overlayId} />}
                            entityId={entityId}
                            entityType={entityType}
                            overlayId={overlayId}
                            permissionSet={userOperationTypes}
                            title={strings.permissionModalTitle(entityName)}
                        />
                    )}
                </Container>
            )}

            <RecordsHeaderCurrentViewersToggle entityType={entityType} entityId={entityId} />
            {itemHasChainEvents &&
                !isExternalProfile &&
                visiblePermissions &&
                entityType === EntityTypeEnum.ITEM_PROFILE.name && (
                    <ProductModuled productModule={ProductModuleEnum.EVIDENCE.name}>
                        <OnlyWithAbility has={abilitiesEnum.EVIDENCE.VIEW_GENERAL}>
                            <EvidenceItemMenu masterItemId={entityId} />
                        </OnlyWithAbility>
                    </ProductModuled>
                )}
            {!isExternalProfile && isEditable && editProfileSidePanel && (
                <OnlyWithEntityPermission
                    permissionSet={userOperationTypes}
                    has={OperationTypeEnum.WRITE.name}
                >
                    <OnlyWithAbility has={abilitiesEnum.CORE.EDIT_GENERAL}>
                        <HeaderInlineWrapper>
                            {editProfileSidePanel(({ open, setCloseFocusRefs }) => (
                                <RecordsHeaderButton overlay={strings.edit}>
                                    <OpenSidePanelWithQueryParamEffect
                                        open={open}
                                        enabled={isEditable}
                                    />
                                    <Button
                                        className={buttonTypes.ICON}
                                        iconLeft={
                                            <Icon
                                                size={18}
                                                type={iconTypes.EDIT}
                                                color="cobaltBlue"
                                            />
                                        }
                                        onClick={open}
                                        ref={setCloseFocusRefs}
                                        testId={testIds.EDIT_BUTTON}
                                    />
                                </RecordsHeaderButton>
                            ))}
                        </HeaderInlineWrapper>
                    </OnlyWithAbility>
                </OnlyWithEntityPermission>
            )}
        </HeaderMenu>
    );
}

const SHOW_SIDE_PANEL_QUERY_PARAM = 'showSidePanel';

/**
 * When the `showSidePanel` query parameter is present,
 * show the edit side panel
 *
 * This is primarily for integration with the JMS so that we can seamlessly
 * link out to the edit side panel
 *
 * Currently, this specifically only works for contexted profiles with a BOOKING parent entity type
 * as well as master profiles
 */
const OpenSidePanelWithQueryParamEffect = withRouter(({ open, router, enabled }) => {
    const showSidePanel = SHOW_SIDE_PANEL_QUERY_PARAM in router.location.query;
    React.useEffect(() => {
        // If the `showSidePanel` query parmeter exists, and this effect is `enabled`
        // then automatically open the side panel
        if (enabled && showSidePanel && open) {
            open();
        }
    }, [open, showSidePanel, enabled]);
    return null;
});

const mapStateToProps = createStructuredSelector({
    itemHasChainEvents: itemHasChainEventsSelector,
    entityType: currentEntityProfileTypeSelector,
    entityId: currentEntityProfileIdSelector,
    userOperationTypes: currentEntityProfileUserOperationTypesSelector,
    visiblePermissions: currentEntityProfileHasVisiblePermissionsSelector,
    isEditable: currentEntityProfileIsEditableSelector,
    currentUserDepartmentId: currentUserDepartmentIdSelector,
    currentEntityProfileDepartmentId: currentEntityProfileDepartmentIdSelector,
    applicationSettings: applicationSettingsSelector,
    personDetailsViewModel: personDetailsViewModelSelector,
});

/**
 * The menu in the header of an entity profile.
 */
export default compose(
    connect(mapStateToProps),
    withPropsOnChange(
        [
            'itemHasChainEvents',
            'entityId',
            'currentUserDepartmentId',
            'currentEntityProfileDepartmentId',
        ],
        ({
            itemHasChainEvents,
            entityId,
            currentUserDepartmentId,
            currentEntityProfileDepartmentId,
        }) => ({
            isExternalProfile: currentEntityProfileDepartmentId !== currentUserDepartmentId,
            itemHasChainEvents: itemHasChainEvents(entityId),
        })
    )
)(EntityProfileHeaderMenu);
