/**
 * Settings config. Type of the config is
 * { [name: string]: {name: string, type: string} }
 *
 * names are the name of the application setting sent down by the server. Type is an enum
 * of 'STRING' | 'BOOLEAN' | 'NUMBER'. Since application settings are raw strings, it's up
 * to the client to parse the types.
 *
 * STRING: (rawValue) => rawValue
 * NUMBER: (rawValue) => parseFloat(rawValue, 10)
 * BOOLEAN: (rawValue) => !!(rawValue && rawValue.trim().toLowerCase() !== false) // 'false' or '' is false, everything else is true
 */
export default {
    /**
     * RMS - Enable Account Switcher feature, allowing users to switch between departments without logging out
     */
    ACCOUNT_SWITCHER_ENABLED: {
        name: 'account_switcher.enabled',
        type: 'BOOLEAN',
    },
    /**
     * Enable sending analytics data to mixpanel
     */
    ANALYTICS_ENABLED: {
        name: 'analytics.tracking.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - Reports - Display "Advised of Rights" section on Arrest Card
     */
    ARRESTS_SHOW_ADVISED_RIGHTS: {
        name: 'arrests.show_advised_rights',
        type: 'BOOLEAN',
    },
    CAD_USE_AEN_FOR_REN: {
        name: 'cad.use_aen_for_ren',
        type: 'BOOLEAN',
    },
    /**
     * ZDeveloper Only - CAD
     * note: this is the DIS integration port, not CAD specific
     */
    CAD_DESKTOP_INTEGRATION_PORT: {
        name: 'cad.desktop.integration.port',
        type: 'STRING',
    },
    /**
     * CAD AMR (External Agencies)
     */
    CAD_EXTERNAL_AGENCIES_ENABLED: {
        name: 'cad.external_agencies.enabled',
        type: 'BOOLEAN',
    },
    /**
     * CAD Allow Unit GPS in place of Location
     */
    ALLOW_UNIT_GPS_FOR_LOCATION: {
        name: 'cad.allow_unit_gps_for_location',
        type: 'BOOLEAN',
    },
    /**
     * CAD Use Flat Earth Endpoints
     */
    USE_FLAT_EARTH: {
        name: 'cad.use_flat_earth.enabled',
        type: 'BOOLEAN',
    },
    /**
     * CAD ProQA Integration
     */
    CAD_PRO_QA_ENABLED: {
        name: 'cad.pro_qa.enabled',
        type: 'BOOLEAN',
    },
    /**
     * CAD ProQA port
     */
    CAD_PRO_QA_PORT: {
        name: 'cad.pro_qa.port',
        type: 'STRING',
    },
    /**
     * CAD ProQA Fire Integration
     */
    CAD_PRO_QA_FIRE_ENABLED: {
        name: 'cad.pro_qa.fire.enabled',
        type: 'BOOLEAN',
    },
    /**
     * CAD ProQA Fire port
     */
    CAD_PRO_QA_FIRE_PORT: {
        name: 'cad.pro_qa.fire.port',
        type: 'STRING',
    },
    /**
     * CAD - Radius threshold for location cautions in feet
     */
    CAD_LOCATION_CAUTION_RADIUS: {
        name: 'cad.location.caution.radius',
        type: 'STRING',
    },
    /**
     * ZDeveloper Only - CAD
     */
    CAD_SERVICE_ROTATION_ENABLED: {
        name: 'cad.service_rotation.enabled',
        type: 'BOOLEAN',
    },
    /**
     * CAD - Enable CAD Call Station Feature for inboxes.
     */
    MESSAGING_CALL_STATION_ENABLED: {
        name: 'cad.messaging.call_station_feature.enabled',
        type: 'BOOLEAN',
    },
    /**
     * CAD - Enable CAD product, required for any CAD access
     */
    CAD_ENABLED: {
        name: 'cad.enabled',
        type: 'BOOLEAN',
    },
    /**
     * ZDEVELOPER ONLY - cad.data.exchange.enabled
     */
    CAD_DATA_EXCHANGE_ENABLED: {
        name: 'cad.data.exchange.enabled',
        type: 'BOOLEAN',
    },
    /**
     * ZDEVELOPER ONLY - CAD - cad.indexed_db.enabled
     */
    CAD_INDEXED_DB_ENABLED: {
        name: 'cad.indexed_db.enabled',
        type: 'BOOLEAN',
    },
    /**
     * CAD - mobile only features
     */
    CAD_MOBILE_ENABLED: {
        name: 'cad.mobile.enabled',
        type: 'BOOLEAN',
    },
    /**
     * CAD - cad.multiagency.enabled
     */
    CAD_MULTIAGENCY_ENABLED: {
        name: 'cad.multiagency.enabled',
        type: 'BOOLEAN',
    },
    /**
     * CAD - Enabling turns off area code validation on CAD Events
     */
    CAD_PHONE_AREA_CODE_OPTIONAL: {
        name: 'cad.phone.area_code.optional',
        type: 'BOOLEAN',
    },
    CAD_STAFF_COUNT_ENABLED: {
        name: 'cad.staff_count.enabled',
        type: 'BOOLEAN',
    },
    /**
     * CAD - Subject Data
     */
    CAD_STOP_DATA_COLLECTION_ENABLED: {
        name: 'stop.data.collection.enabled',
        type: 'BOOLEAN',
    },
    /**
     * CAD - Displays the preference that enables dispatch to self initiate units without members logged in
     */
    CAD_SELF_INITIATE_UNIT_WITHOUT_MEMBERS_ENABLED: {
        name: 'cad.self.initiate.unit.without.members.enabled',
        type: 'BOOLEAN',
    },
    /**
     * CAD - Exposes all of the response plans-related FE code.
     */
    CAD_RESPONSE_PLANS_ENABLED: {
        name: 'cad.response_plans.enabled',
        type: 'BOOLEAN',
    },
    /**
     * CAD - Enables the use of the alarm level features in the app.
     */
    CAD_AGENCY_EVENT_ALARM_LEVEL_ENABLED: {
        name: 'cad.agency_event_alarm_level.enabled',
        type: 'BOOLEAN',
    },
    /**
     * CAD - Enables the ability to automatically log out the user when a Fatal Exception occurs.
     */
    CAD_FORCE_LOGOUT_ENABLED: {
        name: 'cad.force_logout.enabled',
        type: 'BOOLEAN',
    },
    /**
     *  CAD - Enables new version of unit attributes feature.
     */
    CAD_UNIT_ATTRIBUTES_V2_ENABLED: {
        name: 'cad.unit_attributes_v2.enabled',
        type: 'BOOLEAN',
    },
    /**
     * CAD - Enables unit timers feature.
     */
    CAD_UNIT_TIMERS_ENABLED: {
        name: 'cad.unit_timers.enabled',
        type: 'BOOLEAN',
    },
    /**
     * CAD - Enables observability metrics collection.
     */
    CAD_OBSERVABILITY_ENABLED: {
        name: 'cad.observability.enabled',
        type: 'BOOLEAN',
    },
    CAD_WEB_NUDGE_ENABLED: {
        name: 'cad.web_nudge.enabled',
        type: 'BOOLEAN',
    },
    /**
     * CAD/DEX - Enables Dex purpose code feature
     */
    DEX_PURPOSE_CODE_ENABLED: {
        name: 'dex.purpose_code.enabled',
        type: 'BOOLEAN',
    },
    DEX_QUERY_UPDATE_ENABLED: {
        name: 'dex.query_update.enabled',
        type: 'BOOLEAN',
    },
    /**
     * CAD - Enables BOLOs.
     */
    CAD_BOLOS_ENABLED: {
        name: 'cad.bolos.enabled',
        type: 'BOOLEAN',
    },
    /** CAD - Enables the ability to send Debug Snapshots.
     */
    CAD_DEBUG_MODAL_ENABLED: {
        name: 'cad.debug_modal.enabled',
        type: 'BOOLEAN',
    },
    /**
     * CAD - Enables RapidSOS integration. (Assuming the department has configured it appropriately)
     */
    CAD_AML_ENABLED: {
        name: 'cad.aml.enabled',
        type: 'BOOLEAN',
    },
    /**
     * AML data provider.
     */
    CAD_AML_SOURCE: {
        name: 'cad.aml.source',
        type: 'STRING',
    },
    /**
     * CAD - Enables call taking enhancements (call queue, call status, etc).
     */
    CAD_CALL_TAKING_ENHANCEMENTS_ENABLED: {
        name: 'cad.call_taking_enhancements.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - Cautions will appear on PDF export print outs"
     */
    CAUTION_PDFEXPORT_ENABLED: {
        name: 'rms.caution_pdfexport.enabled',
        type: 'BOOLEAN',
    },
    /**
     * ZDeveloper Only - Platform
     */
    DUPLICATES_ENABLED: {
        name: 'duplicates.enabled',
        type: 'BOOLEAN',
    },
    ENHANCED_SERVICE_ROTATION: {
        name: 'cad.enhanced_service_rotation.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - Evidence - Use the browser label printing system
     */
    EVIDENCE_LABELS_V3_ENABLED: {
        name: 'evidence.labelsv3.enabled',
        type: 'BOOLEAN',
    },
    /*
     * RMS - Enable/Disable Printing Evidence Labels Before Report Submission
     */
    RMS_EVIDENCE_LABELS_FIRST_ENABLED: {
        name: 'rms.evidence_labels_first.enabled',
        type: 'BOOLEAN',
    },
    /**
     * ESRI Map - Use ESRI map instead of Google map in RMS
     */
    ESRI_MAPS_ENABLED: {
        name: 'cad.esri_maps.enabled',
        type: 'BOOLEAN',
    },
    /**
     * ZDeveloper Only - RMS - Show specific error codes on error page
     */
    ERROR_PAGE_SPECIFIC_CODE_ENABLED: {
        name: 'rms.error_page_specific_code.enabled',
        type: 'BOOLEAN',
    },
    /**
     * DEVELOPER ONLY - export_release_settings.enabled
     */
    EXPORT_RELEASE_SETTINGS_ENABLED: {
        name: 'export_release_settings.enabled',
        type: 'BOOLEAN',
    },
    /**
     * ZDeveloper Only - CAD
     */
    MAPBOX_ACCESS_TOKEN: {
        name: 'rms.mapbox_token',
        type: 'STRING',
    },
    /**
     * ZDeveloper Only - RMS - nibrs.property_logic.enabled
     */
    NIBRS_PROPERTY_LOGIC_ENABLED: {
        name: 'nibrs.property_logic.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - PDF Exports - font size used for cell contents. Recommend 12. Please keep between 8 and 16.
     */
    PDF_EXPORTS_CONTENT_FONT_SIZE: {
        name: 'pdf_exports.content_font_size',
        type: 'NUMBER',
    },
    /**
     * RMS - PDF Exports - Use department name and logo on PDF export.
     */
    PDF_EXPORTS_DISPLAY_DEPARTMENT_NAME: {
        name: 'pdf_exports.display_department_name.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - PDF Exports - font size used for cell labels. Recommend 8. Please keep between 6 and 12.
     */
    PDF_EXPORTS_LABEL_FONT_SIZE: {
        name: 'pdf_exports.label_font_size',
        type: 'NUMBER',
    },
    PDF_EXPORTS_NARRATIVE_FONT_SIZE: {
        name: 'pdf_exports.narrative_font_size',
        type: 'NUMBER',
    },
    /**
     * RMS - PDF Exports - font size used for narratives. Recommend 14. Please keep between 10 and 20.
     */
    PDF_EXPORTS_ARREST_CHARGE_OPTIONS_ENABLED: {
        name: 'export.options.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - PDF Exports - Enable Release Tracking when generating PDFs
     */
    RELEASE_TRACKING_ENABLED: {
        name: 'release_tracking.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - PDF Exports - Enable automatic addition of user signatures when generating PDFs
     */
    PDF_EXPORTS_SHOW_SIGNATURE_IMAGES: {
        name: 'pdf_exports.show_signature_images',
        type: 'BOOLEAN',
    },
    /**
     * RMS - PDF Exports - Enable building and rendering PDF exports via PDFNext from within RMS
     */
    RMS_PDF_NEXT_INTEGRATION_ENABLED: {
        name: 'rms.pdf_next_integration.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - Print Current Report - Include Associated Records summary table for report export
     */
    RMS_ASSOCIATED_RECORDS_SUMMARY: {
        name: 'rms.associated_records_summary',
        type: 'BOOLEAN',
    },
    /**
     * RMS - Persons - Enable Gang Tracking functionality to track person gang involvement and gang criteria on reports, and view gang affiliation on master person profiles
     */
    RMS_GANG_TRACKING_ENABLED: {
        name: 'rms.gang_tracking.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - Persons - The number of years that are set for adults when Gang Tracking is enabled
     */
    RMS_ADULT_GANG_TRACKING_YEARS: {
        name: 'rms.adult_gang_tracking_years',
        type: 'NUMBER',
    },
    /**
     * RMS - Persons - The number of years that are set for juveniles when Gang Tracking is enabled
     */
    RMS_JUVENILE_GANG_TRACKING_YEARS: {
        name: 'rms.juvenile_gang_tracking_years',
        type: 'NUMBER',
    },
    /**
     * RMS - Item Entry v2 - Enable NIBRS validation on the Offense Card
     */
    RMS_ITEM_ENTRY_V2_NIBRS_VALIDATION: {
        name: 'rms.item_entry_v2.nibrs_validation',
        type: 'BOOLEAN',
    },
    /**
     * Does the agency trust us to merge their multiple offenses that occurred at the same time and place (with the same
     * NIBRS code) to one offense as per NIBRS requirement
     */
    RMS_NIBRS_MERGE_DUPLICATE_NIBRS_OFFENSES_ENABLED: {
        name: 'rms.nibrs_merge_duplicate_offenses.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - NIBRS Report Segment Generation: generate NIBRS report segments at the report-level upon supervisor approval
     */
    RMS_NIBRS_REPORT_SEGMENT_GENERATION_ENABLED: {
        name: 'rms.nibrs_report_segment_generation.enabled',
        type: 'BOOLEAN',
    },
    RMS_NIBRS_EXPORT_USE_GENERATED_SEGMENTS_ENABLED: {
        name: 'rms.nibrs_export_use_generated_segments.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - Mobile - Enable mobile functionality. Required for users to log into this department on the RMS Mobile App.
     */
    RMS_MOBILE_ENABLED: {
        name: 'rms.mobile.enabled',
        type: 'BOOLEAN',
    },
    /**
     * ZDeveloper Only - RMS - Personal Dashboard Recently Viewed Widget (not ready for prod)
     */
    RMS_PERSONAL_DASHBOARD_V2_EXTERNAL_LINKS: {
        name: 'rms.personal_dashboard_v2.external_links',
        type: 'BOOLEAN',
    },
    /**
     * RMS - Warrants Cards - Enable viewing of cards on dashboard
     */
    RMS_WARRANT_CARDS_ENABLED: {
        name: 'rms.warrant_cards.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - Warrant History - Enable viewing of history for warrants
     */
    RMS_WARRANT_HISTORY_ENABLED: {
        name: 'rms.warrant_history.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - Ignores the content of non disclosure field when determining what a PDF should render
     */
    RMS_IGNORE_NON_DISCLOSURE: {
        name: 'rms.ignore_non-disclosure',
        type: 'BOOLEAN',
    },
    /**
     * RMS - Enable smart input REN format handling
     */
    RMS_REN_FORMATTING_ENABLED: {
        name: 'rms.report.ren_formatting.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - Feature Flag for supporting the creation of records without a required REN
     */
    RMS_REPORT_RECORDS_WITHOUT_REN_ENABLED: {
        name: 'rms.report.records_without_ren.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - Feature Flag for auto-generating an agency-defined REN sequence that originates in the RMS, not a CAD system
     */
    RMS_AUTO_GENERATED_REN_ENABLED: {
        name: 'rms.rms_auto_generated_ren.enabled',
        type: 'BOOLEAN',
    },
    RMS_REPORTS_ROUTING_LABELS_REQUIRED_ON_REPORT_APPROVAL_ENABLED: {
        name: 'rms.reports.routing_labels_required_on_report_approval.enabled',
        type: 'BOOLEAN',
    },
    RMS_REPORT_MODULES_ENABLED: {
        name: 'rms.report_modules.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - Enable Saved Search Sharing
     */
    RMS_SAVED_SEARCH_SHARING_ENABLED: {
        name: 'rms.saved_search_sharing.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - ZDeveloper Only - RMS - Subscriptions
     */
    RMS_SUBSCRIPTIONS_ENABLED: {
        name: 'rms.subscriptions.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - ZDeveloper Only - RMS - Task & Request Tracking
     */
    RMS_TASK_AND_REQUEST_TRACKING_ENABLED: {
        name: 'rms.tasks.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - Task Entity Links
     */
    RMS_TASK_ENTITY_LINKS_ENABLED: {
        name: 'rms.task_entity_links.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - Tasks - Users with the Task Entity Linking Limit ability cannot link more than this number of entities to a
     * task. Users without the ability are not affected by this limit.
     */
    RMS_TASK_ENTITY_LINKING_LIMIT: {
        name: 'rms.task_entity_linking_limit',
        type: 'NUMBER',
    },
    /**
     * RMS - rms.toggle_field_labels.enabled
     */
    RMS_TOGGLE_FIELD_LABELS_ENABLED: {
        name: 'rms.toggle_field_labels.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - Enable Data Exchange for Warrants
     */
    RMS_WARRANTS_DATA_EXCHANGE_ENABLED: {
        name: 'rms.warrants_data_exchange.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - When enabled, checks the “Hide External Agency Results” checkbox in quick search
     */
    RMS_CONSORTIUM_QUICK_SEARCH_FILTER: {
        name: 'rms.consortium_quick_search_filter',
        type: 'BOOLEAN',
    },
    /**
     * RMS - Replaces the word "Arrest" with "Custody" when defendant is juvenile
     */
    RMS_JUVENILE_ARREST_REPORT_TITLE: {
        name: 'rms.juvenile.arrest_report.title',
        type: 'BOOLEAN',
    },
    /**
     * Allow warrants numbers to be autogenerated
     */
    WARRANTS_AUTO_GEN_NUMBER_ENABLED: {
        name: 'warrants.auto_gen_number.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - Prevent users from exporting information that belong to other tenants
     */
    RMS_CONSORTIUM_CSV_EXPORT_FILTER: {
        name: 'rms.consortium_csv_export_filter',
        type: 'BOOLEAN',
    },
    /**
     * RMS - Utilize the @ symbol to add report data fields directly in the narrative
     */
    RMS_MENTIONS_IN_NARRATIVE_ENABLED: {
        name: 'rms.mentions_in_narrative.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - Reports - Allow approved reports to be rejected
     */
    RMS_DISPLAY_REJECT_BUTTON_ENABLED: {
        name: 'rms.display_reject_button.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - Offenses - Pre-fill "Includes Cargo Theft" to "No" when it appears on offenses.
     */
    RMS_INCLUDES_CARGO_THEFT_DEFAULT_NO_ENABLED: {
        name: 'rms.includes_cargo_theft.default_no.enabled',
        type: 'BOOLEAN',
    },

    /**
     * zDeveloper Only (RMS) - All user facing changes for luna release 2 that are not part of a larger feature
     */
    RMS_LUNA_RELEASE_2020_2_ENABLED: {
        name: 'rms.luna_release_2020_2.enabled',
        type: 'BOOLEAN',
    },
    // region `Dragon` settings
    /**
     * Dragon - RMS - Creatable, configurable, custom forms.
     */
    RMS_DRAGON_ENABLED: {
        name: 'rms.dragon.enabled',
        type: 'BOOLEAN',
    },
    /**
     * Dragon - Search enabled
     */
    DRAGON_SEARCH_ENABLED: {
        name: 'dragon.search.enabled',
        type: 'BOOLEAN',
    },
    // endregion `Dragon` settings
    /**
     * RMS - Boolean Search
     */
    RMS_BOOLEAN_SEARCH_ENABLED: {
        name: 'rms.boolean_search.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - Wildcard Search
     */
    RMS_WILDCARD_SEARCH_ENABLED: {
        name: 'rms.wildcard_search.enabled',
        type: 'BOOLEAN',
    },
    /**
     * zDeveloper Only - RMS Family violence fields and rules
     */
    RMS_FAMILY_VIOLENCE_FIELDS_AND_RULES_ENABLED: {
        name: 'rms.family_violence.fields_and_rules.enabled',
        type: 'BOOLEAN',
    },
    /*
     * RMS - Person Deduplication
     */
    RMS_PERSON_DEDUPLICATION_ENABLED: {
        name: 'rms.person_deduplication.enabled',
        type: 'BOOLEAN',
    },
    /*
     * ZDeveloper Only - RMS - Inline Narrative Comments
     */
    RMS_INLINE_NARRATIVE_COMMENTS_ENABLED: {
        name: 'rms.inline_narrative_comments.enabled',
        type: 'BOOLEAN',
    },
    /*
     * How long to wait for each instance of the TinyMCE editor to initialize. If this setting has no value, the app uses a default value.
     */
    RMS_TINYMCE_INIT_TIMEOUT: {
        name: 'rms.tinymce_init_timeout',
        type: 'NUMBER',
    },
    /**
     * RMS - Warrant number configuration preference
     */
    RMS_WARRANT_ID_CONFIGURATION_TYPE: {
        name: 'rms.warrant_id_configuration.type',
        type: 'STRING',
    },
    /**
     * ZDeveloper Only -- RMS - Overlay Enhancements
     */
    RMS_OVERLAY_ENHANCEMENTS_ENABLED: {
        name: 'rms.overlay_enhancements.enabled',
        type: 'BOOLEAN',
    },
    RMS_CONFIGURABLE_RELATIONSHIPS_ENABLED: {
        name: 'rms.configurable_relationships.enabled',
        type: 'BOOLEAN',
    },
    RMS_WASHINGTON_DC_ARREST_NUMBER_GENERATION_ENABLED: {
        name: 'rms.washington_dc_arrest_number_generation.enabled',
        type: 'BOOLEAN',
    },
    /**
     * NIBRS - Show or hide export zero report segment checkbox in NIBRS workspace
     */
    NIBRS_EXPORT_ZERO_REPORT_SEGMENT_CHECKBOX_ENABLED: {
        name: 'nibrs.export_zero_report_segment_checkbox.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - Enable/Disable Error Enhancements
     */
    RMS_REPORT_WRITING_ERROR_ENHANCEMENTS_ENABLED: {
        name: 'rms.report_writing.error_enhancements.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - Enables External and Internal Case Statuses
     */
    RMS_CLEARANCE_IMPROVEMENTS_ENABLED: {
        name: 'rms.clearance_improvements.enabled',
        type: 'BOOLEAN',
    },
    /**
     * ARC - 2021 Release Cycle One Components
     */
    ARC_RELEASE_CYCLE_ONE_COMPONENTS: {
        name: 'arc.rc_one_components.enabled',
        type: 'BOOLEAN',
    },
    /**
     * ARC - 2021 Release Cycle Three Components
     */
    ARC_RELEASE_CYCLE_THREE_COMPONENTS: {
        name: 'arc.rc_three_components.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - Enable Dynamic Reports
     */
    RMS_DYNAMIC_REPORT_ENABLED: {
        name: 'rms.dynamic_report.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - Report writing fields will be marked as required'
     */
    RMS_REPORT_WRITING_SHOW_REQUIRED_FIELDS_ENABLED: {
        name: 'rms.report_writing.show_required_fields.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - Disable Restricted Offense / Incident Locations
     */
    RMS_WASHINGTON_DC_RESTRICT_OFFENSEINCIDENT_ADDRESS_ENABLED: {
        name: 'rms.washington_dc.restrict_offenseincident_addresses.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - Enable/Disable Use of Force Enhancements
     */
    RMS_USE_OF_FORCE_ENHANCEMENTS_ENABLED: {
        name: 'rms.uof_enhancements.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - NIBRS Consolidation
     */
    RMS_NIBRS_CONSOLIDATION_ENABLED: {
        name: 'rms.nibrs_consolidation.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - Enable async workflow for NIBRS exports (no user-facing changes)
     */
    RMS_NIBRS_EXPORT_ASYNC_ENABLED: {
        name: 'rms.nibrs_export_async.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - Delete compliance history
     */
    RMS_DELETE_COMPLIANCE_HISTORY_ENABLED: {
        name: 'rms.delete_compliance_history.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - Officer Report Snapshot
     */
    RMS_OFFICER_REPORT_SNAPSHOT_ENABLED: {
        name: 'rms.officer_report_snapshot.enabled',
        type: 'BOOLEAN',
    },
    /**
     * CAD - CLI Request/Response
     */
    CLI_REQUEST_RESPONSE_ENABLED: {
        name: 'cad.cli.request_response.enabled',
        type: 'BOOLEAN',
    },
    /**
     * CAD - Enable Radio Channel Display on Agency Event PDF Export
     */
    CAD_RADIO_CHANNEL_PDF_EXPORT_ENABLED: {
        name: 'cad.radio_channel.pdf_export.enabled',
        type: 'BOOLEAN',
    },
    /**
     * CAD - Enable Associate Events
     */
    CAD_ASSOCIATED_EVENTS_ENABLED: {
        name: 'cad.associated_events.enabled',
        type: 'BOOLEAN',
    },
    /**
     * CAD - Enable 2021 RC4 Scheduled Events Enhancements
     */
    CAD_IMPROVED_SCHEDULED_EVENTS_ENABLED: {
        name: 'cad.improved_scheduled_events.enabled',
        type: 'BOOLEAN',
    },
    /**
     * Default activation buffer time for scheduled events
     */
    SCHEDULED_EVENTS_DEFAULT_BUFFER_MINUTES: {
        name: 'cad.scheduled_events_default_buffer_minutes',
        type: 'STRING',
    },
    /**
     * CAD - Exposes all of the geofencing-related FE code.
     */
    CAD_GEOFENCING_ENABLED: {
        name: 'cad.geofencing.enabled',
        type: 'BOOLEAN',
    },
    /**
     * Locations - Exposes the measurement controls for the ArcGIS (Esri) map.
     */
    LOCATIONS_MEASUREMENTS_ENABLED: {
        name: 'locations.measurements.enabled',
        type: 'BOOLEAN',
    },
    /**
     * CAD - Toggle for enabling CAD to RMS Multi-REN.
     */
    CAD_MULTI_REN_GENERATION_ENABLED: {
        name: 'cad.multi_ren_generation.enabled',
        type: 'BOOLEAN',
    },
    /**
     * CAD - This defines the upper limit of the number of Multi-RENs that can be generated per agency event.
     */
    CAD_AGENCY_EVENT_MULTI_REN_LIMIT: {
        name: 'cad.agency_event_multi_ren_limit',
        type: 'NUMBER',
    },
    /**
     * RMS - Toggle for rendering Report Page Size as 'A4' instead of 'US Letter'
     */
    RMS_PAGE_SIZE_IS_A4_ENABLED: {
        name: 'rms.report_page_size_is_a4',
        type: 'BOOLEAN',
    },
    /**
     * Evidence - Toggle for enabling evidence auto location update during audit
     */
    EVIDENCE_AUTO_LOCATION_UPDATE_ENABLED: {
        name: 'evidence.auto_location_update_enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - Toggle for using new OMS workflow that allows editing/deleting OMS reports provided
     * they are the only in-progress OMS report for the original O/I report
     */
    RMS_NEW_OMS_WORKFLOW_ENABLED: {
        name: 'rms.new_oms_workflow.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - Toggle to use evidence location perms when adding items to basket or filtering storage locations
     */
    RMS_USE_EVD_LOCATION_PERMS_ENABLED: {
        name: 'rms.use_evd_location_perms.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - Storage Location Search Improvements
     */
    STORAGE_LOCATION_SEARCH_IMPROVEMENTS_ENABLED: {
        name: 'rms.storage_location_search_improvements.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - Toggle to use case foldering within attachments and notes (for now)
     */
    RMS_CASE_FOLDERING_ENABLED: {
        name: 'rms.case_foldering.enabled',
        type: 'BOOLEAN',
    },
    /**
     * CAD - Exposes the request for assistance flow
     */
    CAD_REQUEST_FOR_ASSISTANCE_ENABLED: {
        name: 'cad.request_for_assistance.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - Compliance Resubmission Workflow
     */
    RMS_COMPLIANCE_RESUBMISSION_WORKFLOW_ENABLED: {
        name: 'rms.compliance_resubmission_workflow.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - CLIPS Dex Integration
     */
    RMS_CLIPS_DEX_INTEGRATION_ENABLED: {
        name: 'rms.dex_clips_integration.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS -  for bulk update notification
     */
    RMS_BULK_UPDATE_NOTIFICATION_ENABLED: {
        name: 'rms.bulk_update_notification.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - Bulk Export Case
     */
    RMS_BULK_EXPORT_CASE_ENABLED: {
        name: 'rms.bulk_export_case.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - Cases - This defines the upper limit of how many cases can be exported at a time.
     * The Bulk Export Case feature flag must be enabled.
     */
    RMS_BULK_EXPORT_CASE_LIMIT: {
        name: 'rms.bulk_export_case_limit',
        type: 'NUMBER',
    },
    /**
     * DEX - Initiate Event from Dex Results
     */
    DEX_INITIATE_EVENT_ENABLED: {
        name: 'dex.initiate_event.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - Use of Force - Enabling this on will hide officer demographic information on Use of Force reports
     */
    RMS_USE_OF_FORCE_HIDE_OFFICER_DEMOGRAPHICS: {
        name: 'rms.uof_hide_officer_demographics.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - Department Admin - Enabling this will allow internal support users to update a department's subdomain. Adding FF as a precaution
     * as it's only safe to use this feature for AWS departments at the moment.
     */
    RMS_UPDATE_DEPARTMENT_SUBDOMAIN: {
        name: 'rms.update_department_subdomain.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - task enhancements - Enabling this will add the following enhancements to tasks: priorities, types, and updates.
     */
    RMS_TASK_ENHANCEMENTS_ENABLED: {
        name: 'rms.task_enhancements.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - Delete Department Permissions - Enabling this will allow users with the given ability to delete department permissions
     * on reports, cases, and the administration page
     */
    RMS_REMOVE_DEPARTMENT_PERMISSIONS_ENABLED: {
        name: 'rms.remove_department_permissions.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - Photo Lineups Enabled - Enabling this will allow users, with the given ability, to utilize photo lineups under a case
     */
    RMS_PHOTO_LINEUPS_ENABLED: {
        name: 'rms.photo_lineups.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - Photo Lineups - Sets the mininum number of photos required to export a photo lineup
     */
    RMS_MIN_PHOTO_LINEUP_SLOTS: {
        name: 'rms.min_photo_lineup_slots',
        type: 'NUMBER',
    },
    /**
     * RMS - Photo Lineups - Enables the option for simultaneous ordering when exporting a photo lineup
     */
    RMS_SIMULTANEOUS_LINEUPS_ENABLED: {
        name: 'rms.simultaneous_lineups.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - Subdivision Search Enhancements
     */
    RMS_SUBDIVISION_SEARCH_ENHANCEMENTS_ENABLED: {
        name: 'rms.subdivision_search_enhancements.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - CAD Channels Admin Page (allow to access /#/admin/cad-channels page)
     */
    RMS_CAD_CHANNELS_ADMIN_PAGE_ENABLED: {
        name: 'rms.cad_channels_admin_page.enabled',
        type: 'BOOLEAN',
    },
    /**
     * ESRI - ESRI_GEOCODING_SERVICE_ENABLED
     */
    ESRI_GEOCODING_SERVICE_ENABLED: {
        name: 'esri.geocoding_service.enabled',
        type: 'BOOLEAN',
    },
    /**
     * Non hierarchical subdivisions.
     */
    LOCATIONS_NON_HIER_CLASSIFICATION_ENABLED: {
        name: 'locations.non_hier_classification.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - replace item side panel recovery information section withe collection information section
     */
    COLLECTION_INFORMATION_SECTION_ENABLED: {
        name: 'rms.item_side_panel_collection_information.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - NSWPF side panel fields for nswpf
     */
    RMS_SIDE_PANEL_FIELDS_NSWPF_ENABLED: {
        name: 'rms.item_side_panel_fields_nswpf.enabled',
        type: 'BOOLEAN',
    },
    /**
     * Enable Pendo (pendo.io) in RMS. This setting should only be enabled in the production environment.
     */
    RMS_PENDO_ENABLED: {
        name: 'rms.pendo.enabled',
        type: 'BOOLEAN',
    },
    /**
     * Geo aliases.
     */
    GEO_ALIAS_ENABLED: {
        name: 'locations.geo_alias.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - i18n
     */
    RMS_I18N_ENABLED: {
        name: 'rms.i18n.enabled',
        type: 'BOOLEAN',
    },
    /**
     * Arc One Components
     */
    ARC_ONE_COMPONENTS_ENABLED: {
        name: 'arc.rc_one_components.enabled',
        type: 'BOOLEAN',
    },
    /**
     * Arc Three Components
     */
    ARC_THREE_COMPONENTS_ENABLED: {
        name: 'arc.rc_three_components.enabled',
        type: 'BOOLEAN',
    },
    /**
     * Hide Street segments admin page.
     */
    HIDE_STREET_SEGMENTS_ADMIN_ENABLED: {
        name: 'locations.hide_street_segments_admin.enabled',
        type: 'BOOLEAN',
    },
    /*
     * CAD and RMS - The multi agency flag.
     */
    MULTI_AGENCY_SUBDIVISIONS_ENABLED: {
        name: 'locations.multi_agency_subdivisions.enabled',
        type: 'BOOLEAN',
    },
    /**
     * Warrant Dashboard Sorting
     */
    RMS_WARRANT_SORTING_ENABLED: {
        name: 'rms.warrant_sorting.enabled',
        type: 'BOOLEAN',
    },
    /**
     * CAD Limits: Additional infos allowed on a single event.
     */
    CAD_LIMIT_ADDITIONAL_INFO_PER_EVENT: {
        name: 'cad.limit.additional_info_per_event',
        type: 'NUMBER',
    },
    /**
     * CAD Limits: Number of open event panels allowed.
     */
    CAD_LIMIT_OPEN_EVENT_PANEL_COUNT: {
        name: 'cad.limit.open_event_panel_count',
        type: 'NUMBER',
    },
    RMS_REPORT_MODAL_REDESIGN_ENABLED: {
        name: 'rms.report_modal_redesign.enabled',
        type: 'BOOLEAN',
    },
    /**
     * CAD Limits: Assigned units allowed on a single event.
     */
    CAD_AGENCY_EVENT_ASSIGNED_UNITS_LIMIT: {
        name: 'cad.limit.agency_event_assigned_unit',
        type: 'NUMBER',
    },
    /**
    /**
     * CAD Limits: Reporting Party
     */
    CAD_REPORTING_PARTY_LIMIT: {
        name: 'cad.limit.reporting_party',
        type: 'NUMBER',
    },
    /*
     * CAD and RMS - The new single subdivisions admin page flag.
     */
    NEW_SINGLE_AGENCY_SUBDIVISIONS_ENABLED: {
        name: 'locations.new_single_agency_subdivisions.enabled',
        type: 'BOOLEAN',
    },
    /**
     * Reverse Geocoding
     */
    REVERSE_GEOCODE_RADIUS_FT: {
        name: 'locations.geo_address_points_reverse_geocode_radius_ft',
        type: 'NUMBER',
    },
    /**
     * RMS - Report Export Hide Statically Hidden Fields
     */
    RMS_REPORT_EXPORT_HIDE_STATICALLY_HIDDEN_FIELDS_ENABLED: {
        name: 'rms.report_exports_hide_statically_hidden_fields.enabled',
        type: 'BOOLEAN',
    },
    /**
     * Reverse geocode max result size
     */
    REVERSE_GEOCODE_MAX_RESULT_SIZE: {
        name: 'locations.geo_address_points_reverse_geocode_max_size',
        type: 'NUMBER',
    },
    /**
     * Location points max result size
     */
    POINT_LOCATION_MAX_RESULT_SIZE: {
        name: 'locations.points_max_size',
        type: 'NUMBER',
    },
    /**
     * Locations centerline max result size
     */
    CENTERLINE_MAX_RESULT_SIZE: {
        name: 'locations.center_line_max_size',
        type: 'NUMBER',
    },
    /**
     * Location points max result size
     */
    ALIAS_MAX_RESULT_SIZE: {
        name: 'locations.aliases_max_size',
        type: 'NUMBER',
    },
    /**
     * Location resolution max result size
     */
    TOTAL_RESOLVER_RESULTS_SIZE: {
        name: 'locations.total_max_size',
        type: 'NUMBER',
    },
    /**
     * Esri resolution max result size
     */
    ESRI_RESOLVER_RESULTS_SIZE: {
        name: 'locations.esri_max_size',
        type: 'NUMBER',
    },
    /**
     * CAD Limits: Vehicle
     */
    CAD_VEHICLE_LIMIT: {
        name: 'cad.limit.vehicle',
        type: 'NUMBER',
    },
    /**
     * CAD Limits: Tow Vehicles
     */
    CAD_TOW_VEHICLE_LIMIT: {
        name: 'cad.limit.tow_vehicle',
        type: 'NUMBER',
    },
    /**
     * CAD Involved persons and vehicles
     */
    CAD_INVOLVED_PERSONS_AND_VEHICLES_ENABLED: {
        name: 'cad.involved_persons_and_vehicles.enabled',
        type: 'BOOLEAN',
    },
    /**
     * Toggle to display vehicle labels
     */
    RMS_VEHICLE_CAUTIONS_ENABLED: {
        name: 'rms.vehicle_cautions.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - Enable person cautions with more detail
     */
    RMS_PERSON_CAUTIONS_ENHANCEMENTS_ENABLED: {
        name: 'rms.person_cautions_enhancements.enabled',
        type: 'BOOLEAN',
    },
    /**
     * Toggle to display vehicle cautions with more detail
     */
    RMS_VEHICLE_CAUTIONS_ENHANCEMENTS_ENABLED: {
        name: 'rms.vehicle_cautions_enhancements.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - Allow user to add unknown victim via quick add button
     */
    RMS_UNKNOWN_VICTIM_QUICK_ADD_ENABLED: {
        name: 'rms.unknown_victim_quick_add.enabled',
        type: 'BOOLEAN',
    },
    /**
     * CAD: Enable display of ID Image in DEX Results
     */
    CAD_DISPLAY_ID_IMAGE_IN_DEX_RESULTS_ENABLED: {
        name: 'cad.display_id_image_in_dex_results.enabled',
        type: 'BOOLEAN',
    },
    /**
     * Blocks all access to NIBRS exports on the compliance dashboard
     */
    RMS_NIBRS_DISABLE_NIBRS_DASHBOARD_ENABLED: {
        name: 'rms.nibrs_dashboard_disabled.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - Export Case Tasks
     */
    RMS_EXPORT_CASE_TASKS: {
        name: 'rms.export_case_tasks',
        type: 'BOOLEAN',
    },
    /**
     * RMS - Allow user to configure birth status on a person profile
     */
    RMS_UNBORN_PERSON_CAPTURE_ENABLED: {
        name: 'rms.unborn_person_capture.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - Person Involvment Type Enabled
     */
    RMS_PERSON_SIDE_PANEL_INVOLVEMENT_TYPE_ENABLED: {
        name: 'rms.person_side_panel_involvement_type.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - Vulnerable Person Capture
     */
    RMS_VULNERABLE_PERSONS_CAPTURE_ENABLED: {
        name: 'rms.vulnerable_persons_capture.enabled',
        type: 'BOOLEAN',
    },

    /**
     * RMS - UK POLE
     */
    RMS_POLE_DATA_CAPTURE_ENABLED: {
        name: 'rms.pole_data_capture.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - Support international phone dialing codes
     */
    RMS_DIALING_CODE_DISPLAYED_ENABLED: {
        name: 'rms.dialing_code_displayed.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - Relationship Prefill Enabled
     * RMS - Relationship Prefill
     */
    RMS_RELATIONSHIP_PREFILL_ENABLED: {
        name: 'rms.relationship_prefill.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - ARC Datepicker Enabled
     */
    RMS_ARC_DATEPICKER_ENABLED: {
        name: 'rms.arc_datepicker.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - Reports - Limit count of vehicles allowed on a citation report to one
     */
    RMS_REPORTS_CITATION_REPORT_LIMIT_TO_ONE_VEHICLE: {
        name: 'rms.citation_report_limit_to_one_vehicle.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - Offense/Incident Quick Add Person/Orgs Enabled
     */
    RMS_OI_QUICK_ADD_ENABLED: {
        name: 'rms.oi_quick_add.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - Reports - Toggle auto-fill pop-up modal
     */
    RMS_EVENT_SUPP_INFO_PREFILL_ENABLED: {
        name: 'rms.event_supp_info_prefill.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - RIPA 2024 Cutover Date (YYYY-MM-DD)
     */
    RMS_RIPA_2024_CUTOVER_DATE: {
        name: 'rms.ripa_2024_cutover_date',
        type: 'DATE',
    },
    /**
     * RMS - NIBRS Crimes Against Society Require a Society Victim
     */
    RMS_NIBRS_SOCIETY_OFFENSE_REQUIRES_SOCIETY_VICTIM_ENABLED: {
        name: 'rms.nibrs_society_offense_requires_society_victim.enabled',
        type: 'BOOLEAN',
    },
    /**
     * Evidence - CPS Reports - Paginate Property Card
     */
    EVIDENCE_PAGINATE_HYDRATED_ITEMS_ENABLED: {
        name: 'evidence.paginate_hydrated_items.enabled',
        type: 'BOOLEAN',
    },
    /**
     * Evidence - CPS Reports - Property Card Pagination size.
     */
    EVIDENCE_CPS_REPORT_PAGINATION_SIZE: {
        name: 'evidence.cps_item_pagination_size',
        type: 'NUMBER',
    },
    /**
     * RMS - Investigation Enhancements Phase 1
     */
    RMS_INVESTIGATION_ENHANCEMENTS_PHASE_ONE: {
        name: 'rms.investigation_enhancements_p1.enabled',
        type: 'BOOLEAN',
    },
    /**
     * CAD - Use agency department configuration for REN generation
     */
    CAD_USE_AGENCY_DEPT_CONFIG_FOR_REN: {
        name: 'cad.use_agency_department_config_for_ren.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - Enables File Upload Statuses
     */
    RMS_FEDRAMP_FILE_UPLOAD_STATUSES_ENABLED: {
        name: 'rms.fedramp.file_upload_statuses.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - NIBRS - Crimes Against Society Allow non-society victims
     */
    RMS_NIBRS_SOCIETY_OFFENSE_ALLOW_NONSOCIETY_VICTIMS_ENABLED: {
        name: 'rms.nibrs_crime_against_society_allow_nonsociety_victims.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - Enables vehicle merging
     */
    RMS_VEHICLE_MERGING_ENABLED: {
        name: 'rms.vehicle_merging.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - NIBRS NO_MUTUALLY_EXCLUSIVE_OFFENSES_FOR_SAME_VICTIM shows on report and blocks submission
     */
    RMS_NIBRS_NO_MUTUALLY_EXCLUSIVE_OFFENSES_FOR_SAME_VICTIM_SHOW_ON_REPORT_ENABLED: {
        name: 'rms.nibrs_mutually_exclusive_offense_error_show_on_report.enabled',
        type: 'BOOLEAN',
    },
    /**
     * Evidence - Configurable Evidence Label Epic.
     */
    EVIDENCE_CONFIGURABLE_EVIDENCE_LABEL_ENABLED: {
        name: 'evidence.configurable_evidence_label.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - E-Filing
     */
    RMS_E_FILING_ENABLED: {
        name: 'rms.efiling.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - ARC Left navigation enabled
     */
    RMS_ARC_NAVIGATION_ENABLED: {
        name: 'rms.arc_navigation.enabled',
        type: 'BOOLEAN',
    },
    /**
     * NY-NIBRS Hide Substance Abuse field for Victims
     */
    RMS_NY_NIBRS_HIDE_VICTIM_SUBSTANCE_ABUSE_ENABLED: {
        name: 'rms.ny_nibrs_hide_victim_substance_abuse.enabled',
        type: 'BOOLEAN',
    },
    /**
     * Evidence - Filter and Sort Updates
     */
    EVIDENCE_FILTER_SORT_UPDATES: {
        name: 'evidence.evd_filter_sort_updates.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - Associate Records within the same REN
     */
    RMS_ASSOCIATE_RECORDS_WITHIN_SAME_REN: {
        name: 'rms.associate_records_within_same_ren.enabled',
        type: 'BOOLEAN',
    },
    /**
     * Esri - global map api key
     */
    ESRI_MAPS_API_KEY: {
        name: 'esri.maps.api_key',
        type: 'STRING',
    },
    /**
     * RMS - Enable Mandatory Investigations for Offense/Incident Reports
     */
    RMS_MANDATORY_INVESTIGATIONS_FOR_OFFENSE_INCIDENT_REPORTS: {
        name: 'rms.mandatory_investigations_for_offense_incident_reports.enabled',
        type: 'BOOLEAN',
    },
    /*
     * RMS - MA EACC enabled
     */
    RMS_MA_EACC_ENABLED: {
        name: 'rms.ma_eacc.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS- Offense Code Wording
     */
    RMS_OFFENSE_CODE_WORDING: {
        name: 'rms.offense_code_wording.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - Entity Suggestion and Prefill
     */
    RMS_CAD_DATA_ENTITY_PREFILL_ENABLED: {
        name: 'rms.cad_data_entity_prefill.enabled',
        type: 'BOOLEAN',
    },
    /*
     * RMS - Generic External Submissions
     */
    RMS_GENERIC_EXTERNAL_SUBMISSIONS_ENABLED: {
        name: 'rms.generic_external_submissions.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - Court Entity enabled
     */
    RMS_COURT_ENTITY_ENABLED: {
        name: 'rms.court_entity.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - MA Charge Citation enabled
     */
    RMS_MA_CHARGE_CITATION_ENABLED: {
        name: 'rms.ma_charge_citation.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - Enable Briefing functionality
     */
    RMS_BRIEFING_ENABLED: {
        name: 'rms.briefing.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - ADD EXPORTS TO CURRENT REPORT
     */
    RMS_ADD_EXPORTS_TO_CURRENT_REPORT_ENABLED: {
        name: 'rms.add_exports_to_current_report.enabled',
        type: 'BOOLEAN',
    },
    /**
     * Enable Subpremise Support in CAD and RMS
     */
    SUBPREMISE_SUPPORT_ENABLED: {
        name: 'locations.subpremise_support.enabled',
        type: 'BOOLEAN',
    },
    /**
     * Evidence - Inventory item pagination
     */
    EVIDENCE_INVENTORY_ITEM_PAGINATION_ENABLED: {
        name: 'evidence.inventory_item_pagination.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - Enable ANPR improvements
     */
    RMS_ANPR_IMPROVEMENTS_ENABLED: {
        name: 'rms.anpr_improvements.enabled',
        type: 'BOOLEAN',
    },
    /**
     * Security - Invalidate refresh token after one use and issue a new one
     */
    SECURITY_INVALIDATE_REFRESH_TOKEN: {
        name: 'security.invalidate_refresh_token.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - This setting controls if disabled field/rule configuration contexts are loaded upon launching RMS FE
     */
    RMS_INCLUDE_DISABLED_FIELDS_AND_RULES: {
        name: 'rms.include_disabled_fields_and_rules.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - Enable Target Profiles
     */
    RMS_TARGET_PROFILES_ENABLED: {
        name: 'rms.target_profiles.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - Enabled case entity links creation
     */
    RMS_CASE_ENTITY_LINKS_ENABLED: {
        name: 'rms.case.entity.links.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - Enable location sidepanel search via map
     */
    LOCATIONS_LOCATION_SELECTION_ON_MAP_ENABLED: {
        name: 'locations.location_selection_on_map.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - Allows a migration information card to be configured on new report types
     */
    RMS_LEGACY_MIGRATION_INFO_CARD_ENABLED: {
        name: 'rms.legacy_migration_info_card.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - Crash Diagram
     */
    RMS_CRASH_DIAGRAM_ENABLED: {
        name: 'rms.crash_diagram.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - Digital Signatures
     */
    RMS_DIGITAL_SIGNATURES_ENABLED: {
        name: 'rms.digital_signatures.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - Search full text containing spaces
     */
    RMS_SEARCH_FULL_TEXT_WITH_SPACES_IN_SEARCH: {
        name: 'rms.search_full_text_containing_spaces.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - ETrace
     */
    RMS_ETRACE_ATF_INTEGRATION_ENABLED: {
        name: 'rms.etrace_atf_integration.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - Enable Direct Emails
     */
    RMS_DIRECT_EMAILS_ENABLED: {
        name: 'rms.direct_emails.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - Enable JMS Bookings Search And View
     */
    RMS_JMS_BOOKINGS_ENABLED: {
        name: 'rms.jms_bookings.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - Enable individual report selection for cases
     */
    RMS_INDIVIDUAL_REPORT_SELECTION_ENABLED: {
        name: 'rms.individual_report_selection.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - Looker Iframe Event Listener
     */
    RMS_LOOKER_IFRAME_LISTENER_ENABLED: {
        name: 'rms.looker_iframe_listener.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - Looker Share Dashboard URL
     */
    RMS_LOOKER_SHARE_DASHBOARD_URL_ENABLED: {
        name: 'rms.looker_share_dashboard_url.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - Looker Kill Queries On Page Transition
     */
    RMS_LOOKER_KILL_QUERIES_ON_PAGE_TRANSITION_ENABLED: {
        name: 'rms.looker_kill_queries_on_page_transition.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - Looker Active Queries Panel
     */
    RMS_LOOKER_ACTIVE_QUERIES_PANEL_ENABLED: {
        name: 'rms.looker_active_queries_panel.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - GoodData API key to authenticate GoodData embed
     */
    RMS_GOODDATA_API_KEY: {
        name: 'rms.gooddata_api_key',
        type: 'STRING',
    },
    /**
     * RMS - GoodData API key to authenticate GoodData embed
     */
    RMS_GOODDATA_EMBED_PARAMETERS: {
        name: 'rms.gooddata_embed_parameters',
        type: 'STRING',
    },
    /**
     * RMS - Enable possibility to hide report owners by report type
     */
    RMS_HIDABLE_REPORT_OWNERS_ENABLED: {
        name: 'rms.hidable_report_owners.enabled',
        type: 'BOOLEAN',
    },
    /**
     * CAD - Hospitals Enabled
     */
    CAD_HOSPITALS_ENABLED: {
        name: 'cad.hospitals.enabled',
        type: 'BOOLEAN',
    },
    /**
     * CAD - Map Rewrite Cluster Limit In Pixels
     */
    CAD_MAP_CLUSTER_RADIUS_PIXELS: {
        name: 'cad.map_cluster_radius.pixels',
        type: 'NUMBER',
    },
    /**
     * CAD - Enable Component and E2e Testing For CAD-Next Map
     */
    CAD_MAP_TESTS_ENABLED: {
        name: 'cad.map_tests.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - Evidence Dashboard Refresh
     */
    RMS_EVIDENCE_DASHBOARD_REFRESH_ENABLED: {
        name: 'rms.evidence_dashboard_refresh.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - Evidence Dashboard Filters Refresh
     */
    RMS_EVIDENCE_DASHBOARD_FILTERS_REFRESH_ENABLED: {
        name: 'rms.evidence_dashboard_filters_refresh.enabled',
        type: 'BOOLEAN',
    },
    DEX_SIMULATION_MODE_ENABLED: {
        name: 'dex.simulation_mode.enabled',
        type: 'BOOLEAN',
    },
    /**
     * RMS - User with MFA Config Dashboard Enabled
     */
    RMS_USER_WITH_MFA_CONFIG_DASHBOARD_ENABLED: {
        name: 'rms.user_with_mfa_config_dashboard.enabled',
        type: 'BOOLEAN',
    },
} as const;
