import { RefContextEnum } from '@mark43/rms-api';
import _, { flatMap } from 'lodash';
import { createFormConfiguration } from 'markformythree';
import {
    DISPOSITION_EVENT_COMMENT,
    DISPOSITION_EVENT_MANUAL_RETENTION_PERIOD,
    CREATE_DISPOSITION_EVENT_VIEW_DISPOSITION_APPROVAL_LEVEL,
    CREATE_DISPOSITION_EVENT_VIEW_MANUAL_RETENTION_PERIOD,
    CREATE_DISPOSITION_EVENT_VIEW_SPECIFIC_DATE,
    DISPLAY_ONLY_PERIOD_UNIT,
} from '~/client-common/core/enums/universal/fields';
import { dispositionEventsByMasterItemIdsSelector } from '~/client-common/core/domain/disposition-events/state/data';
import { highestPriorityDispositionApprovalLevel } from '../../helpers/resetRetentionPolicyHelpers';

export const initialState = {
    terminalDispositionApprovalLevel: undefined,
    manualRetentionDays: undefined,
    specificDate: undefined,
    comment: undefined,
    periodUnit: undefined,
    periodAmount: undefined,
    isSpecificDate: undefined,
    isDuration: undefined,
};

export const formConfiguration = createFormConfiguration({
    terminalDispositionApprovalLevel: {
        fieldName: CREATE_DISPOSITION_EVENT_VIEW_DISPOSITION_APPROVAL_LEVEL,
    },
    manualRetentionDays: {
        fieldName: DISPOSITION_EVENT_MANUAL_RETENTION_PERIOD,
    },
    comment: {
        fieldName: DISPOSITION_EVENT_COMMENT,
    },
    specificDate: { fieldName: CREATE_DISPOSITION_EVENT_VIEW_SPECIFIC_DATE },
    periodUnit: { fieldName: DISPLAY_ONLY_PERIOD_UNIT },
    periodAmount: { fieldName: CREATE_DISPOSITION_EVENT_VIEW_MANUAL_RETENTION_PERIOD },
    isSpecificDate: {},
    isDuration: {},
});

export function fillResetRetentionForm(masterItemIds) {
    return (dispatch, getState, { formsRegistry }) => {
        const state = getState();
        const dispositionEvents = dispositionEventsByMasterItemIdsSelector(state)(masterItemIds);

        const selectedDispositionApprovalLevels = _.uniq(
            flatMap(dispositionEvents, 'dispositionState.dispositionApprovalLevel')
        );

        const approvalLevel = highestPriorityDispositionApprovalLevel(
            selectedDispositionApprovalLevels
        );

        formsRegistry.maybeDeferredOperation(
            RefContextEnum.FORM_EVIDENCE_RESET_RETENTION_POLICY.name,
            undefined,
            (form) => {
                form.set('terminalDispositionApprovalLevel', approvalLevel);
            }
        );
    };
}
