import _, { omit, get, map, compact } from 'lodash';
import { ReportTitleFormatEnum, DynamicCardTypeEnum, EntityTypeEnum } from '@mark43/rms-api';

import Promise from 'bluebird';
import getIdFormatConfigurationResource from '~/client-common/core/domain/id-format-configurations/resources/idFormatConfigurationResource';
import {
    convertReportApprovalLevelToReportDefinitionFields,
    convertReportDefinitionFieldsToReportApprovalLevelClientEnum,
} from '~/client-common/helpers/reportApprovalLevelHelpers';
import {
    reportDefaultRoleLinksByReportDefinitionIdSelector,
    replaceReportDefaultRoleLinksWhere,
    NEXUS_STATE_PROP as REPORT_DEFAULT_ROLE_LINKS_NEXUS_STATE_PROP,
} from '~/client-common/core/domain/report-default-role-links/state/data';
import {
    NEXUS_STATE_PROP as ID_FORMAT_CONFIGURATIONS_NEXUS_STATE_PROP,
    idFormatConfigurationByIdSelector,
} from '~/client-common/core/domain/id-format-configurations/state/data';
import {
    recordSequenceTypeToIdFormatConfigurationSelector,
    idFormatConfigurationToRecordSequenceType,
} from '~/client-common/core/domain/id-format-configurations/state/ui';
import { loadConsortiumData } from '~/client-common/core/domain/full-consortium-view/state/data';
import { NEXUS_STATE_PROP as REPORT_DEFINITIONS_NEXUS_STATE_PROP } from '~/client-common/core/domain/report-definitions/state/data';
import componentStrings from '~/client-common/core/strings/componentStrings';
import reportCardEnum from '~/client-common/core/enums/universal/reportCardEnum';

import recordSequenceTypeEnum from '~/client-common/core/enums/client/recordSequenceTypeEnum';

import {
    uiSelector,
    currentReportDefinitionIdSelector,
    currentReportCardIdSelector,
    cardLinkFormsSelector,
    getFilledDownloadablesForms,
} from '../selectors/customReportsAdminSelectors';

import { currentUserDepartmentIdSelector } from '../../modules/core/current-user/state/ui';
import { addExternalDepartmentFlags } from '../helpers/permissionHelpers';
import Resource from '../resources/reportDefinitionResource';
import { augmentCardLinkFormsWithSelectedInformationCardInfo } from '../helpers/customReportsAdminHelpers';
import actionTypes from './types/customReportsAdminActionTypes';

const strings = componentStrings.admin.customreports;

function onPageLoadStart() {
    return {
        type: actionTypes.PAGE_LOAD_START,
    };
}

function onPageLoadSuccess() {
    return {
        type: actionTypes.PAGE_LOAD_SUCCESS,
    };
}

function onPageLoadFailure(err) {
    return {
        type: actionTypes.PAGE_LOAD_FAILURE,
        payload: err.message,
    };
}

function loadRolePermissionsStart() {
    return {
        type: actionTypes.LOAD_ROLE_PERMISSIONS_START,
    };
}

function loadRolePermissionsSuccess(id, rolePermissions) {
    return {
        type: actionTypes.LOAD_ROLE_PERMISSIONS_SUCCESS,
        payload: { id, rolePermissions },
    };
}

function loadRolePermissionsFailure(err) {
    return {
        type: actionTypes.LOAD_ROLE_PERMISSIONS_FAILURE,
        payload: err.message,
    };
}

function saveRolePermissionsStart() {
    return {
        type: actionTypes.SAVE_ROLE_PERMISSIONS_START,
    };
}

function saveRolePermissionsSuccess(id, rolePermissions) {
    return {
        type: actionTypes.SAVE_ROLE_PERMISSIONS_SUCCESS,
        payload: { id, rolePermissions },
    };
}

// All reports should be initialized with the default report title format
const initialState = { reportTitleFormat: ReportTitleFormatEnum.DEFAULT_REPORT_TYPE_NAME.name };

export function pageLoad(id) {
    return (dispatch, getState, dependencies) => {
        const currentDepartmentId = currentUserDepartmentIdSelector(getState());
        dispatch(onPageLoadStart());

        return Promise.all([
            dispatch(loadConsortiumData()),
            Resource.getReportDefinitions({ excludeCps: false }),
            Resource.getReportDefaultRoleLinksByDeptId(currentDepartmentId),
            getIdFormatConfigurationResource().getIdFormatConfigurations(),
        ])
            .then(([, reportDefinitions, reportDefaultRoleLinks, idFormatConfigurations]) => {
                // THIS WILL CLEAR ALL DATA ON ANY PAGE LOAD
                // (except for `initialState`)
                dispatch(selectNewReportDefinition(initialState));

                dispatch(
                    dependencies.nexus.withEntityItems(
                        {
                            [REPORT_DEFINITIONS_NEXUS_STATE_PROP]: reportDefinitions,
                            [REPORT_DEFAULT_ROLE_LINKS_NEXUS_STATE_PROP]: reportDefaultRoleLinks,
                            [ID_FORMAT_CONFIGURATIONS_NEXUS_STATE_PROP]: idFormatConfigurations,
                        },
                        onPageLoadSuccess()
                    )
                );

                if (id) {
                    return dispatch(selectReportDefinition(id));
                } else {
                    const newReportTypeDefaultCardConfiguration = compact([
                        {
                            cardId: reportCardEnum.EVENT_INFO.id,
                            isRequired: false,
                            isHidden: false,
                            title: 'Event Information',
                        },
                        {
                            cardId: reportCardEnum.SUPPLEMENT_INFO.id,
                            isRequired: false,
                            isHidden: true,
                            title: 'Supplement Information',
                        },
                        {
                            cardId: reportCardEnum.LEGACY_INFO.id,
                            isRequired: false,
                            isHidden: true,
                            title: 'Migration Information',
                        },
                        {
                            cardId: reportCardEnum.RELATIONSHIPS.id,
                            isRequired: false,
                            isHidden: false,
                            title: 'Relationships',
                        },
                        {
                            cardId: reportCardEnum.INVOLVED_PROFILES.id,
                            isRequired: false,
                            isHidden: false,
                            title: 'Involved Profiles',
                        },
                        {
                            cardId: reportCardEnum.NARRATIVE.id,
                            isRequired: false,
                            isHidden: false,
                            title: 'Narrative',
                        },
                        {
                            cardId: reportCardEnum.ATTACHMENTS.id,
                            isRequired: false,
                            isHidden: false,
                            title: 'Attachments',
                        },
                        {
                            cardId: reportCardEnum.APPROVALS.id,
                            isRequired: true,
                            isHidden: false,
                            title: 'Approvals',
                        },
                        {
                            cardId: reportCardEnum.COURT_CASE.id,
                            isRequired: false,
                            isHidden: false,
                            title: 'Court Case',
                        },
                        {
                            cardId: reportCardEnum.PROPERTY.id,
                            isRequired: false,
                            isHidden: false,
                            title: 'Property',
                        },
                        {
                            cardId: reportCardEnum.VEHICLE.id,
                            isRequired: false,
                            isHidden: false,
                            title: 'Vehicle',
                        },
                        {
                            cardId: reportCardEnum.REPORT_STATUS_COMMENTS.id,
                            isRequired: true,
                            isHidden: false,
                            title: 'Report Status & Comments',
                        },
                        {
                            cardId: reportCardEnum.STOP.id,
                            isRequired: false,
                            isHidden: true,
                            title: 'Stop',
                        },
                        {
                            cardId: reportCardEnum.SUMMARY_NARRATIVE.id,
                            isRequired: false,
                            isHidden: false,
                            title: 'Summary Narrative',
                        },
                    ]);
                    dispatch(storeReportDefinitionCardLinks(newReportTypeDefaultCardConfiguration));
                }
            })
            .catch((err) => dispatch(onPageLoadFailure(err)));
    };
}

function selectNewReportDefinition(initialState) {
    return {
        type: actionTypes.SELECT_NEW_REPORT_DEFINITION,
        payload: initialState,
    };
}

export function rawSelectReportDefinition(definition) {
    return {
        type: actionTypes.SELECT_REPORT_DEFINITION,
        payload: definition,
    };
}

function loadDefaultRoleLinks(defaultRoleLinks) {
    return {
        type: actionTypes.LOAD_DEFAULT_ROLE_LINKS,
        payload: defaultRoleLinks,
    };
}

function selectReportDefinition(id) {
    return (dispatch, getState, dependencies) => {
        dispatch(loadRolePermissionsStart());
        return Promise.all([
            Resource.getReportDefinition(id),
            Resource.getReportCreationRolePermissions(id),
            Resource.getReportDefinitionTitleFormatsByDefinitionId(id),
        ])
            .then(([definition, permissions, reportDefinitionTitleFormats]) => {
                const state = getState();
                dispatch(
                    dependencies.nexus.withEntityItems(
                        {
                            [REPORT_DEFINITIONS_NEXUS_STATE_PROP]: [definition],
                            reportDefinitionTitleFormats,
                        },
                        { type: actionTypes.SELECT_REPORT_DEFINITION_SUCESS }
                    )
                );
                const defaultRoleLinks = reportDefaultRoleLinksByReportDefinitionIdSelector(state)(
                    id
                );
                const augmentedDefaultRoleLinks = addExternalDepartmentFlags(
                    defaultRoleLinks,
                    state
                );
                const idFormatConfig = idFormatConfigurationByIdSelector(state)(
                    definition.idFormatConfigurationId
                );

                const reportApprovalLevel = convertReportDefinitionFieldsToReportApprovalLevelClientEnum(
                    definition
                );

                const selectedDefinition = {
                    ...initialState,
                    ...definition,
                    recordSequenceType: idFormatConfigurationToRecordSequenceType(
                        idFormatConfig,
                        definition.isAutogenerated
                    ),
                    ...(idFormatConfig
                        ? {
                              isGenerateRandomSequenceEnabled: !!idFormatConfig.isGenerateRandomSequenceEnabled,
                              sequenceMaxLength: idFormatConfig.sequenceMaxLength,
                              formatDateString: idFormatConfig.formatDateString,
                              showCharacterPrefix:
                                  !!idFormatConfig.textBeforeDate ||
                                  !!idFormatConfig.textBeforeSequence,
                              showDatePrefix: !!idFormatConfig.formatDateString,
                              textBeforeDate: idFormatConfig.textBeforeDate,
                              textBeforeSequence: idFormatConfig.textBeforeSequence,
                          }
                        : {}),
                    reportApprovalLevel,
                };

                dispatch(loadDefaultRoleLinks(augmentedDefaultRoleLinks));
                dispatch(loadRolePermissionsSuccess(id, permissions));
                dispatch(rawSelectReportDefinition(selectedDefinition));
            })
            .catch((err) => dispatch(loadRolePermissionsFailure(err)));
    };
}

function saveReportDefinitionSuccess(report) {
    return {
        type: actionTypes.SAVE_REPORT_DEFINITION_SUCCESS,
        payload: report,
    };
}

function saveReportDefinitionFailure(err) {
    return {
        type: actionTypes.SAVE_REPORT_DEFINITION_FAILURE,
        payload: err,
    };
}

function saveDownloadablesSuccess(downloadables, reportDefinitionId) {
    return {
        type: actionTypes.SAVE_DOWNLOADABLES_SUCCESS,
        payload: { downloadables, reportDefinitionId },
    };
}

function saveDownloadablesFailure(err) {
    return {
        type: actionTypes.SAVE_DOWNLOADABLES_FAILURE,
        payload: err,
    };
}

export function saveStart() {
    return {
        type: actionTypes.SAVE_DOWNLOADABLE_START,
    };
}

function saveSuccess() {
    return {
        type: actionTypes.SAVE_REPORT_DEFINITION_AND_DOWNLOADABLE_SUCCESS,
    };
}

function storeHydratedReportDefinition(definition) {
    return {
        type: actionTypes.STORE_HYDRATED_REPORT_DEFINTION,
        payload: definition,
    };
}

function setRoleLinks(definition, defaultRoleLinks) {
    return (dispatch) => {
        const definitionId = definition.id;
        return Resource.setReportDefaultRoleLinks(
            definitionId,
            _(defaultRoleLinks)
                .map((permission) => ({
                    reportDefinitionId: definitionId,
                    roleId: permission.roleId,
                    operationType: permission.operationType,
                }))
                .filter('roleId') // Remove blank line
                .filter('operationType')
                .value()
        )
            .then((defaultRoleLinks) =>
                dispatch(
                    replaceReportDefaultRoleLinksWhere(
                        { reportDefinitionId: definitionId },
                        defaultRoleLinks
                    )
                )
            )
            .catch((err) => dispatch(saveReportDefinitionFailure(err)));
    };
}

function storeReportDefinitionCardLinks(cardLinks) {
    return {
        type: actionTypes.STORE_REPORT_DEFINITION_CARD_LINKS,
        payload: cardLinks,
    };
}

export function saveReportDefinitionCardLinksFailure(err) {
    return {
        type: actionTypes.SAVE_REPORT_DEFINITION_CARD_LINKS_FAILURE,
        payload: err,
    };
}

function saveReportDefinitionCardLinks(definitionId, cardLinks) {
    return (dispatch) => {
        return Resource.setReportDefinitionCardLinks(definitionId, cardLinks)
            .then((updatedLinks) => dispatch(storeReportDefinitionCardLinks(updatedLinks)))
            .catch((err) => dispatch(saveReportDefinitionCardLinksFailure(err.message)));
    };
}

function upsertDownloadables(downloadableFormData, reportCardId) {
    return Resource.upsertDownloadables(downloadableFormData, reportCardId);
}

function getDownloadableFormData(state, reportCardId) {
    const ui = uiSelector(state);
    return _.map(getFilledDownloadablesForms(state), (downloadableForm, index) => {
        const displayName = downloadableForm.displayName;
        const downloadable = ui.downloadables[index];
        return {
            ...downloadable,
            displayName,
            reportCardId,
        };
    });
}

export const hasCustomReportIdFormat = (recordSequenceType) =>
    recordSequenceType === recordSequenceTypeEnum.AUTO_GENERATED ||
    recordSequenceType === recordSequenceTypeEnum.FORMATTED_FREE_TEXT;

export function createReportDefinition(definition, router) {
    return (dispatch, getState, dependencies) => {
        const state = getState();

        const idFormatConfiguration = recordSequenceTypeToIdFormatConfigurationSelector(state)(
            definition.recordSequenceType,
            definition.idFormatConfigurationId
        );

        const informationCardId = definition.informationCardId;
        const downloadableTitle = definition.downloadablesCardName;
        const cardLinkForms = augmentCardLinkFormsWithSelectedInformationCardInfo(
            cardLinkFormsSelector(state)(definition.name),
            informationCardId
        );

        const idConfigurationFields = [
            'recordSequenceType',
            'isGenerateRandomSequenceEnabled',
            'sequenceMaxLength',
            'formatDateString',
            'textBeforeDate',
            'textBeforeSequence',
            'showDatePrefix',
            'showCharacterPrefix',
        ];

        const reportApprovalLevel = definition.reportApprovalLevel;

        const reportApprovalLevelReportDefinitionFields = convertReportApprovalLevelToReportDefinitionFields(
            reportApprovalLevel
        );

        const definitionToCreate = {
            ...omit(definition, idConfigurationFields),
            idFormatConfigurationId: get(idFormatConfiguration, 'id'),
            isAutogenerated:
                definition.recordSequenceType === recordSequenceTypeEnum.AUTO_GENERATED,
            ...reportApprovalLevelReportDefinitionFields,
        };

        // the following fields should only be filled if sequence type has custom report id format
        const newIdFormatConfiguration = hasCustomReportIdFormat(definition.recordSequenceType)
            ? {
                  ...idFormatConfiguration,
                  entityType: EntityTypeEnum.REPORT.name,
                  sequenceMaxLength: definition.sequenceMaxLength,
                  formatDateString: definition.showDatePrefix ? definition.formatDateString : null,
                  textBeforeDate: definition.textBeforeDate,
                  textBeforeSequence: definition.textBeforeSequence,
                  isGenerateRandomSequenceEnabled:
                      definition.recordSequenceType === recordSequenceTypeEnum.AUTO_GENERATED
                          ? definition.isGenerateRandomSequenceEnabled
                          : undefined,
              }
            : idFormatConfiguration;

        dispatch(saveStart());

        return Resource.upsertReportDefinitionBundle({
            reportDefinition: definitionToCreate,
            idFormatConfiguration: newIdFormatConfiguration,
        })
            .then((reportDefinitionBundle) => {
                const { reportDefinition, idFormatConfiguration } = reportDefinitionBundle;
                dispatch(
                    dependencies.nexus.withEntityItems(
                        {
                            [ID_FORMAT_CONFIGURATIONS_NEXUS_STATE_PROP]: [idFormatConfiguration],
                        },
                        saveReportDefinitionSuccess(reportDefinition)
                    )
                );
                const definitionId = reportDefinition.id;
                const roleLinks = dispatch(
                    setRoleLinks(reportDefinition, definitionToCreate.defaultRoleLinks)
                );
                const defaultDownloadablesCardData = {
                    title: reportDefinition.name,
                    allowMultiple: false,
                    isRequired: false,
                    isHidden: false,
                    dynamicCardType: DynamicCardTypeEnum.DOWNLOADABLES.name,
                };

                const setCardLinks = Resource.createReportDefinitionCard(
                    defaultDownloadablesCardData
                ).then((newDownloadablesCard) => {
                    const downloadableCardLink = {
                        cardId: newDownloadablesCard.id,
                        definitionId,
                        isRequired: false,
                        isHidden: false,
                        title: downloadableTitle,
                    };

                    // Add definitionId to all the default card links now that we've created the report definition
                    const defaultCardLinks = map(cardLinkForms, (cardLinkForm) => ({
                        ...cardLinkForm,
                        definitionId,
                    }));
                    const allCardLinks = [...defaultCardLinks, downloadableCardLink];

                    const setReportDefinitionCardLinks = Resource.setReportDefinitionCardLinks(
                        definitionId,
                        allCardLinks
                    );

                    return setReportDefinitionCardLinks
                        .then((updatedLinks) => {
                            dispatch(storeReportDefinitionCardLinks(updatedLinks));
                            const downloadableFormData = getDownloadableFormData(
                                state,
                                newDownloadablesCard.id
                            );
                            const upsertDownloadablesRequest = upsertDownloadables(
                                downloadableFormData,
                                newDownloadablesCard.id
                            );
                            return upsertDownloadablesRequest;
                        })
                        .then((upsertDownloadablesResponse) => {
                            return Resource.getReportDefinition(definitionId)
                                .then((definition) =>
                                    dispatch(storeHydratedReportDefinition(definition))
                                )
                                .then(() =>
                                    dispatch(
                                        saveDownloadablesSuccess(
                                            upsertDownloadablesResponse,
                                            definitionId
                                        )
                                    )
                                );
                        })
                        .catch((err) => {
                            dispatch(saveDownloadablesFailure(err));
                        });
                });

                dispatch(saveRolePermissionsStart());
                const newReportCreationRolePermissions = reportDefinition.isRestricted
                    ? definition.reportCreationRolePermissions
                    : [];
                const rolePermissions = saveRolePermissions(
                    reportDefinition.departmentId,
                    definitionId,
                    newReportCreationRolePermissions
                );

                return Promise.all([roleLinks, setCardLinks, rolePermissions])
                    .then((result) => dispatch(saveRolePermissionsSuccess(definitionId, result[2])))
                    .then(() => reportDefinition);
            })
            .then((definition) => {
                dispatch(saveSuccess());
                router.push(`/admin/reports/${definition.id}`);
            })
            .catch((e) => {
                dispatch(saveReportDefinitionFailure(e));
            });
    };
}

export function getReportDefaultRoleLinksByDeptId(deptId) {
    return Resource.getReportDefaultRoleLinksByDeptId(deptId);
}

export function saveReportDefinition(definition, router) {
    return (dispatch, getState, dependencies) => {
        const state = getState();
        const departmentId = currentUserDepartmentIdSelector(state);
        const id = currentReportDefinitionIdSelector(state);
        const reportCardId = currentReportCardIdSelector(state);
        const informationCardId = definition.informationCardId;
        const downloadableTitle = definition.downloadablesCardName;
        const cardLinkForms = augmentCardLinkFormsWithSelectedInformationCardInfo(
            cardLinkFormsSelector(state)(definition.name),
            informationCardId
        );
        const idFormatConfiguration = recordSequenceTypeToIdFormatConfigurationSelector(state)(
            definition.recordSequenceType,
            definition.idFormatConfigurationId
        );

        const idConfigurationFields = [
            'recordSequenceType',
            'isGenerateRandomSequenceEnabled',
            'sequenceMaxLength',
            'formatDateString',
            'textBeforeDate',
            'textBeforeSequence',
            'showDatePrefix',
            'showCharacterPrefix',
        ];

        const reportApprovalLevel = definition.reportApprovalLevel;

        const reportApprovalLevelReportDefinitionFields = convertReportApprovalLevelToReportDefinitionFields(
            reportApprovalLevel
        );

        const updatedDefinition = {
            ...omit(definition, idConfigurationFields),
            departmentId,
            id,
            idFormatConfigurationId: get(idFormatConfiguration, 'id'),
            isAutogenerated:
                definition.recordSequenceType === recordSequenceTypeEnum.AUTO_GENERATED,
            ...reportApprovalLevelReportDefinitionFields,
        };

        // the following fields should only be filled if sequence type has custom report id format
        const updatedIdFormatConfiguration = hasCustomReportIdFormat(definition.recordSequenceType)
            ? {
                  ...idFormatConfiguration,
                  entityType: EntityTypeEnum.REPORT.name,
                  sequenceMaxLength: definition.sequenceMaxLength,
                  formatDateString: definition.showDatePrefix ? definition.formatDateString : null,
                  textBeforeDate:
                      definition.showDatePrefix && definition.showCharacterPrefix
                          ? definition.textBeforeDate
                          : null,
                  textBeforeSequence: definition.showCharacterPrefix
                      ? definition.textBeforeSequence
                      : null,
                  isGenerateRandomSequenceEnabled:
                      definition.recordSequenceType === recordSequenceTypeEnum.AUTO_GENERATED
                          ? definition.isGenerateRandomSequenceEnabled
                          : undefined,
              }
            : idFormatConfiguration;

        dispatch(saveStart());
        // update the report definition
        const updateReportDefinition = Resource.upsertReportDefinitionBundle({
            reportDefinition: updatedDefinition,
            idFormatConfiguration: updatedIdFormatConfiguration,
        })
            .then((response) => {
                dispatch(
                    dependencies.nexus.withEntityItems(
                        {
                            [ID_FORMAT_CONFIGURATIONS_NEXUS_STATE_PROP]: [
                                response.idFormatConfiguration,
                            ],
                        },
                        saveReportDefinitionSuccess(response.reportDefinition)
                    )
                );

                if (!reportCardId) {
                    // set downloadable data card
                    const defaultDownloadablesCardData = {
                        title: updatedDefinition.name,
                        allowMultiple: false,
                        isRequired: false,
                        isHidden: false,
                        dynamicCardType: DynamicCardTypeEnum.DOWNLOADABLES.name,
                    };
                    // create new report defintion Card for downloadable
                    Resource.createReportDefinitionCard(defaultDownloadablesCardData).then(
                        (newDownloadablesCard) => {
                            const newReportCardId = newDownloadablesCard.id;
                            // the new Downloadables card comes back without a definitionId and an 'id' that reps cardId.. this puts it in correct format
                            const newReportDefCard = _.assign(
                                _.omit(newDownloadablesCard, ['id']),
                                {
                                    cardId: newReportCardId,
                                    definitionId: id,
                                    title: downloadableTitle,
                                }
                            );
                            // add the new report definition card in the right format to the existing reportDefinitionCards
                            const updateReportDefCards = [...cardLinkForms, newReportDefCard];
                            const setReportDefinitionCardLinks = Resource.setReportDefinitionCardLinks(
                                id,
                                updateReportDefCards
                            );
                            return setReportDefinitionCardLinks
                                .then((updatedLinks) => {
                                    dispatch(storeReportDefinitionCardLinks(updatedLinks));
                                    const downloadableFormData = getDownloadableFormData(
                                        state,
                                        newDownloadablesCard.id
                                    );
                                    const upsertDownloadablesRequest = upsertDownloadables(
                                        downloadableFormData,
                                        newDownloadablesCard.id
                                    );
                                    return upsertDownloadablesRequest;
                                })
                                .then((upsertDownloadablesResponse) => {
                                    return Resource.getReportDefinition(id)
                                        .then((definition) =>
                                            dispatch(storeHydratedReportDefinition(definition))
                                        )
                                        .then(() =>
                                            dispatch(
                                                saveDownloadablesSuccess(
                                                    upsertDownloadablesResponse,
                                                    id
                                                )
                                            )
                                        );
                                })
                                .catch((err) => {
                                    dispatch(saveDownloadablesFailure(err));
                                });
                        }
                    );
                } else {
                    const downloadableFormData = getDownloadableFormData(state, reportCardId);
                    upsertDownloadables(downloadableFormData, reportCardId)
                        .then((response) => dispatch(saveDownloadablesSuccess(response, id)))
                        .catch((err) => dispatch(saveDownloadablesFailure(err)));
                    dispatch(saveReportDefinitionCardLinks(id, cardLinkForms));
                }
            })
            .catch((err) => {
                dispatch(saveReportDefinitionFailure(err));
            });

        const roleLinks = dispatch(
            setRoleLinks(updatedDefinition, [
                ...updatedDefinition.defaultRoleLinks,
                ...updatedDefinition.defaultExternalDepartmentRoleLinks,
            ])
        );

        dispatch(saveRolePermissionsStart());
        const newReportCreationRolePermissions = updatedDefinition.isRestricted
            ? updatedDefinition.reportCreationRolePermissions
            : [];
        const rolePermissions = saveRolePermissions(
            departmentId,
            id,
            newReportCreationRolePermissions
        );

        return Promise.all([updateReportDefinition, roleLinks, rolePermissions])
            .then((result) => dispatch(saveRolePermissionsSuccess(id, result[2])))
            .then(() => Resource.getReportDefinition(id))
            .then((definition) => dispatch(storeHydratedReportDefinition(definition)))
            .then(() => {
                dispatch(saveSuccess());
                router.push(`/admin/reports/${id}`);
            })
            .catch((e) => {
                dispatch(saveReportDefinitionFailure(e));
            });
    };
}

function saveRolePermissions(deptId, definitionId, rolePermissions) {
    const mappedRolePermissions = _(rolePermissions)
        .map((id) => ({
            departmentId: deptId,
            reportDefinitionId: definitionId,
            roleId: id,
        }))
        .value();
    return Resource.setReportCreationRolePermissions(definitionId, mappedRolePermissions);
}

export function addDownloadableSuccess(fileData) {
    return {
        type: actionTypes.ADD_DOWNLOADABLE_SUCCESS,
        payload: fileData,
    };
}

export function addDownloadableFailure(err) {
    const errorMessage =
        err.status === 413 ? strings.fileTooLargeError : strings.genericDownloadablesError;

    return {
        type: actionTypes.ADD_DOWNLOADABLE_FAILURE,
        payload: errorMessage,
    };
}

export function removeDownloadable(index) {
    return {
        type: actionTypes.REMOVE_DOWNLOADABLE,
        payload: index,
    };
}
