import {
    InferFormDataShape,
    createField,
    createFormConfiguration,
    createNItems,
} from 'markformythree';
import { Detention } from '@mark43/rms-api';

import * as fields from '~/client-common/core/enums/universal/fields';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';

import { ChargeViewModelT } from '../../../core/components/charges/ChargeSummaryView';

export const detentionDispositionFieldsetFormConfiguration = createFormConfiguration({
    id: createField<number>({}),
    chargeId: createField<number>({}),
    displayOnlyChargeTitle: createField<string>({}),
    departmentId: createField<number>({}),
    reportId: createField<number>({}),
    nonFilingReasonCodeAttrId: createField<number | undefined>({
        fieldName: fields.DETENTION_NON_FILING_REASON_CODE_ATTR_ID,
    }),
    nonFilingTypeAttrId: createField<number | undefined>({
        fieldName: fields.DETENTION_NON_FILING_TYPE_ATTR_ID,
    }),
    originalCourtDistrictNumber: createField<string | undefined>({
        fieldName: fields.DETENTION_ORIGINAL_COURT_DISTRICT_NUMBER,
    }),
    originalCourtCaseNumber: createField<string | undefined>({
        fieldName: fields.DETENTION_ORIGINAL_COURT_CASE_NUMBER,
    }),
});

export const detentionDispositionFormConfiguration = createFormConfiguration({
    detentionDispositions: createNItems({
        fields: detentionDispositionFieldsetFormConfiguration,
    }),
});

export type DetentionDispositionFormConfiguration = typeof detentionDispositionFormConfiguration;
export type DetentionDispositionFormModel = InferFormDataShape<DetentionDispositionFormConfiguration>;
export type DetentionDispositionFieldsetFormModel = InferFormDataShape<
    typeof detentionDispositionFieldsetFormConfiguration
>;

export const convertToFormModel = ({
    chargeViewModels,
    detentions,
    reportId,
}: {
    chargeViewModels: ChargeViewModelT[];
    detentions: Detention[];
    reportId: number;
}) => {
    return {
        detentionDispositions: chargeViewModels.map((chargeViewModel) => {
            const viewModelProperties = getViewModelProperties(chargeViewModel);

            const detentionForCharge = detentions.find(
                (detention) => detention.chargeId === chargeViewModel.id
            );
            const displayOnlyChargeTitle = `Charge ${chargeViewModel.offenseOrder}: ${viewModelProperties.chargeOffenseCode}`;
            if (detentionForCharge) {
                const {
                    chargeId,
                    departmentId,
                    id,
                    nonFilingReasonCodeAttrId,
                    nonFilingTypeAttrId,
                    originalCourtCaseNumber,
                    originalCourtDistrictNumber,
                } = detentionForCharge;
                return {
                    displayOnlyChargeTitle,
                    departmentId,
                    chargeId,
                    id,
                    nonFilingReasonCodeAttrId,
                    nonFilingTypeAttrId,
                    originalCourtCaseNumber,
                    originalCourtDistrictNumber,
                    reportId: detentionForCharge.reportId,
                };
            } else {
                return {
                    displayOnlyChargeTitle,
                    chargeId: chargeViewModel.id,
                    departmentId: chargeViewModel.departmentId,
                    nonFillingReasonCodeAttrId: undefined,
                    nonFilingTypeAttrId: undefined,
                    originalCourtDistrictNumber: undefined,
                    originalCourtCaseNumber: undefined,
                    reportId,
                };
            }
        }),
    };
};

export const convertFromFormModel = ({
    formModel,
}: {
    formModel: DetentionDispositionFormModel;
}): Partial<Detention>[] => {
    const result =
        formModel.detentionDispositions?.map(
            ({
                chargeId,
                departmentId,
                id,
                nonFilingReasonCodeAttrId,
                nonFilingTypeAttrId,
                originalCourtCaseNumber,
                originalCourtDistrictNumber,
                reportId,
            }) => ({
                chargeId,
                departmentId,
                id,
                nonFilingTypeAttrId,
                nonFilingReasonCodeAttrId,
                originalCourtCaseNumber,
                originalCourtDistrictNumber,
                reportId,
            })
        ) || [];
    return result;
};
