import { filter, includes } from 'lodash';
import React from 'react';
import { connect, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import {
    formatFieldByNameSelector,
    combinedSubdivisionsLabelSelector,
    globalSequenceNumberLabelSelector,
} from '~/client-common/core/fields/state/config';
import { consortiumDepartmentLinksAvailableSelector } from '~/client-common/core/domain/consortium-link-view/state/ui';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import componentStrings from '~/client-common/core/strings/componentStrings';
import * as fields from '~/client-common/core/enums/universal/fields';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import Row from '../../../core/components/Row';
import { RRFFieldset } from '../../../core/forms/components/Fieldset';
import { RRFAsyncText } from '../../../core/forms/components/AsyncText';
import { RRFCustomReportClassificationAttrSelect } from '../../../core/forms/components/selects/CustomReportClassificationAttrSelect';
import { RRFLabelsWithNoneAttrSelect } from '../../../core/forms/components/selects/LabelsWithNoneAttrSelect';
import { RRFAttributeSelect } from '../../../core/forms/components/selects/AttributeSelect';
import { RRFCaseStatusSelect } from '../../../core/forms/components/selects/CaseStatusSelect';
import { RRFReportDefinitionSelect } from '../../../core/forms/components/selects/ReportDefinitionSelect';
import { RRFAgencyProfileSelect } from '../../../core/forms/components/selects/AgencyProfileSelect';
import { RRFConsortiumDepartmentSelect } from '../../../core/forms/components/selects/ConsortiumDepartmentSelect';
import { SubdivisionsSearch } from '../../../search/core/components/SubdivisionsSearch';
import { reportDefinitionIdsInCurrentReportModuleSelector } from '../state/ui/reportModuleSearch';

const strings = componentStrings.reports.dashboard.ReportDetailsFieldset;

const FlexRow = styled.div`
    display: flex;
`;

const RRFAgencyProfileSelectRight = styled(RRFAgencyProfileSelect)`
    && {
        margin-right: 0;
    }
`;

const RRFConsortiumDepartmentSelectRight = styled(RRFConsortiumDepartmentSelect)`
    && {
        margin-right: 0;
    }
`;

const RRFCaseStatusSelectRight = styled(RRFCaseStatusSelect)`
    && {
        margin-right: 0;
    }
`;

const RRFAttributeSelectRight = styled(RRFAttributeSelect)`
    && {
        margin-right: 0;
    }
`;

const ReportDetailsFieldset = function ({
    combinedSubdivisionsLabel,
    globalSequenceNumberLabel,
    consortiumDepartmentLinksAvailable,
    hideAgencySelect,
    onSubmit,
    applicationSettings,
    formatFieldByName,
}) {
    const agencySelectProps = {
        path: 'agencyIds',
        multiple: true,
        length: 'md',
        onChange: onSubmit,
    };

    const showAgencySelectInNextRow = consortiumDepartmentLinksAvailable && !hideAgencySelect;
    const CaseStatusSelectComponent = showAgencySelectInNextRow
        ? RRFCaseStatusSelectRight
        : RRFCaseStatusSelect;

    const OffenseCaseStatusSelectComponent = showAgencySelectInNextRow
        ? RRFAttributeSelect
        : RRFAttributeSelectRight;

    const renderOffenseCaseStatusSelectComponent = () => {
        return (
            <OffenseCaseStatusSelectComponent
                attributeType="OFFENSE_CASE_STATUS"
                path="offenseCaseStatusAttrId"
                label={formatFieldByName(fields.OFFENSE_CASE_STATUS_CASE_STATUS_ATTR_ID)}
                includeExpired={true}
                length="md"
                onChange={onSubmit}
            />
        );
    };

    const recordNumberPath = applicationSettings.RMS_REPORT_RECORDS_WITHOUT_REN_ENABLED
        ? 'globalSequenceNumber'
        : 'reportingEventNumber';

    const reportDefinitionIds = useSelector(reportDefinitionIdsInCurrentReportModuleSelector);
    const filterReportDefinitionOptions = React.useCallback(
        (options) => {
            if (!reportDefinitionIds || reportDefinitionIds.length === 0) {
                return options;
            }
            return filter(options, ({ value }) => includes(reportDefinitionIds, value));
        },
        [reportDefinitionIds]
    );

    return (
        <RRFFieldset path="reportDetails" title={strings.title}>
            <FlexRow>
                <RRFAsyncText
                    label={globalSequenceNumberLabel}
                    length="md"
                    path={recordNumberPath}
                    asyncAction={onSubmit}
                    typeaheadThrottle={600}
                    fieldNameAlternate={fields.REPORT_REPORTING_EVENT_NUMBER}
                />
                <RRFReportDefinitionSelect
                    length="md"
                    path="reportDefinitions"
                    multiple={true}
                    onChange={onSubmit}
                    fieldNameAlternate={fields.REPORT_REPORT_DEFINITION_ID}
                    filterOptions={
                        reportDefinitionIds && reportDefinitionIds.length > 0
                            ? filterReportDefinitionOptions
                            : undefined
                    }
                />
                <FeatureFlagged
                    flag="RMS_SUBDIVISION_SEARCH_ENHANCEMENTS_ENABLED"
                    fallback={
                        <RRFAttributeSelect
                            attributeType={[
                                'SUBDIVISION_DEPTH_1',
                                'SUBDIVISION_DEPTH_2',
                                'SUBDIVISION_DEPTH_3',
                                'SUBDIVISION_DEPTH_4',
                                'SUBDIVISION_DEPTH_5',
                            ]}
                            label={combinedSubdivisionsLabel}
                            multiple={true}
                            includeExpired={true}
                            length="md"
                            path="subdivisionAttrIds"
                            onChange={onSubmit}
                        />
                    }
                />
                {consortiumDepartmentLinksAvailable && (
                    <RRFConsortiumDepartmentSelectRight
                        path="departmentIds"
                        multiple={true}
                        length="md"
                        onChange={onSubmit}
                    />
                )}
                {!consortiumDepartmentLinksAvailable && !hideAgencySelect && (
                    <RRFAgencyProfileSelectRight {...agencySelectProps} />
                )}
            </FlexRow>
            <FlexRow>
                {showAgencySelectInNextRow && <RRFAgencyProfileSelect {...agencySelectProps} />}
                <RRFCustomReportClassificationAttrSelect
                    label={formatFieldByName(
                        fields.DISPLAY_ONLY_REPORT_DETAILS_INCIDENT_OR_OFFENSE_CLASSIFICATION_LABEL
                    )}
                    multiple={true}
                    length="md"
                    onChange={onSubmit}
                />
                <RRFLabelsWithNoneAttrSelect multiple={true} length="md" onChange={onSubmit} />
            </FlexRow>
            <FlexRow>
                <CaseStatusSelectComponent
                    label={formatFieldByName(fields.REPORT_CASE_STATUS_CASE_STATUS_ATTR_ID)}
                    length="md"
                    includeExpired={true}
                    onChange={onSubmit}
                />
                {!showAgencySelectInNextRow && renderOffenseCaseStatusSelectComponent()}
            </FlexRow>
            {showAgencySelectInNextRow && <Row>{renderOffenseCaseStatusSelectComponent()}</Row>}
            <SubdivisionsSearch length="sm" onChange={onSubmit} />
        </RRFFieldset>
    );
};

const mapStateToProps = createStructuredSelector({
    combinedSubdivisionsLabel: combinedSubdivisionsLabelSelector,
    globalSequenceNumberLabel: globalSequenceNumberLabelSelector,
    consortiumDepartmentLinksAvailable: consortiumDepartmentLinksAvailableSelector,
    applicationSettings: applicationSettingsSelector,
    formatFieldByName: formatFieldByNameSelector,
});

export default connect(mapStateToProps)(ReportDetailsFieldset);
