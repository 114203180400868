import { RefContextEnum, LocationView } from '@mark43/rms-api';
import { createFormConfiguration, createField, createFieldset } from 'markformythree';
import * as fields from '~/client-common/core/enums/universal/fields';

export const TRAFFIC_CRASH_LOCATION_PATH = 'location';
export const TRAFFIC_CRASH_LONGITUDE_PATH = `${TRAFFIC_CRASH_LOCATION_PATH}.longitude`;
export const TRAFFIC_CRASH_LATITUDE_PATH = `${TRAFFIC_CRASH_LOCATION_PATH}.latitude`;

export const formName = RefContextEnum.FORM_CRASH_LOCATION_INFO.name;

export const trafficCrashLocationFormConfiguration = createFormConfiguration({
    [TRAFFIC_CRASH_LOCATION_PATH]: createFieldset({
        fieldName: fields.DISPLAY_ONLY_TRAFFIC_CRASH_LOCATION_WRAPPER,
        fields: {
            locationId: createField<number>({
                fieldName: fields.LOCATION_VIEW_ID,
            }),
            longitude: createField<number>({
                fieldName: fields.LOCATION_VIEW_LONGITUDE,
            }),
            latitude: createField<number>({
                fieldName: fields.LOCATION_VIEW_LATITUDE,
            }),
        },
    }),
});

export const convertToFormModel = (locationView: LocationView) => {
    return {
        locationId: locationView.id,
        longitude: locationView.longitude,
        latitude: locationView.latitude,
    };
};
