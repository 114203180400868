import _, { noop, size } from 'lodash';
import { formEvents } from 'markformythree';
import invariant from 'invariant';
import Promise from 'bluebird';
import { ruleTypes } from 'arbiter-mark43';

import hotkeysActionEnum from '~/client-common/core/enums/client/hotkeysActionEnum';
import { blurActiveElement } from '~/client-common/core/keyboardFocus/helpers';
import {
    buildDistinctErrorMessages,
    buildValidationPromise,
    getErrorMessagesFromErrors,
    validateRRFOnForm,
} from '../../helpers/validationHelpers';
import { scrollToCardAtOffsetAndFocusFirstElement } from '../../utils/cardHelpers';
import { validateLinkedPersonForms } from '../../helpers/validateLinkedPersonForms';
import { validateArrestChargesForm } from '../../helpers/validateArrestChargesForm';

const genericSaveError = 'Failed to save, please try again';

// **********
// Init/Reset actions
// **********
export const initializeCardForm = (formModule) => {
    return (dispatch) => {
        dispatch(formModule.actionCreators.setValidity(undefined, true));

        // this creates a cache for the form
        dispatch(
            formModule.actionCreators.validate(
                { dryRun: true },
                {
                    skipTypes: [
                        ruleTypes.VALIDATION,
                        ruleTypes.HIDE,
                        ruleTypes.FIELD_CONSTRAINT,
                        ruleTypes.CONDITIONAL,
                    ],
                }
            )
        );
        // this will hide fields of the form so it looks correct when first opened
        dispatch(formModule.actionCreators.validate({ ruleTypes: [ruleTypes.HIDE] }));
    };
};

/**
 * Reset card and optionally transition it to edit mode.
 * @param {editMode} boolean
 * @param {object}   module     Card module
 * @param {object}   options    Options to pass over to card module action creators
 */
export const resetCard = (params = {}) => {
    const { module, editMode, options = {} } = params;

    return (dispatch) => {
        dispatch(module.actionCreators.resetState(options));
        if (editMode) {
            dispatch(module.actionCreators.transitionToEditMode(options));
        }
    };
};

/**
 * Bulk initialize cards and optionally transition all cards to edit mode.
 * @param {object}     cardModule     Card module
 * @param {boolean}    editMode       Should the card be initialized in edit mode
 * @param {object}     options        Options to pass over to the card modules action creators
 */
export const initCards = (params = {}) => {
    const { cardModule, editMode, options = {} } = params;

    return (dispatch) => {
        dispatch(cardModule.actionCreators.resetCardsState());
        dispatch(cardModule.actionCreators.initCardsState(options));
        if (editMode) {
            dispatch(cardModule.actionCreators.transitionCardsToEditMode(options));
        }
    };
};

export const validateCard = ({ card, formModule, formComponent, options = {} }) => {
    return (dispatch) => {
        const cardIndex = { index: options.index };
        const additionalFormsToValidate = options.additionalFormsToValidate;
        invariant(
            !!card && !!(formModule || formComponent),
            'Must provide both a card and either a formModule or formComponent to validateCard.'
        );
        let reduxValidationObject;
        // this represents validations in linked forms
        // which we want to validate at the same time as this form
        const additionalFormsValidationResults = [];
        if (formComponent) {
            // in markformythree forms, we're provided the form instance itself, and call its validate method
            reduxValidationObject = formComponent.validate({
                eventType: formEvents.FORM_SUBMIT,
            });
            if (additionalFormsToValidate?.length) {
                additionalFormsValidationResults.push(
                    ...dispatch(
                        validateLinkedPersonForms({
                            additionalFormsToValidate,
                            offenseFormIndex: options.index,
                        })
                    )
                );
                additionalFormsValidationResults.push(
                    ...dispatch(
                        validateArrestChargesForm({
                            additionalFormsToValidate,
                        })
                    )
                );
            }
            // markformythree uses a formErrors property instead of panelErrors, and we just
            // copy the value over so that `validateRRFOnForm` below will work
            reduxValidationObject.panelErrors = reduxValidationObject.formErrors;
        } else {
            // in other forms leveraging arbiter, we call validate via the form module
            reduxValidationObject = dispatch(
                formModule.actionCreators.validate({}, { forceFieldLevelEffects: true })
            );
        }
        const reduxValidationResult = validateRRFOnForm(reduxValidationObject, genericSaveError, {
            keepTemplateString: true,
        });
        const validationResult = {
            isValid: reduxValidationResult.isValid && !additionalFormsValidationResults.length,
            panelErrorMessages: buildDistinctErrorMessages(
                reduxValidationResult.panelErrorMessages
            ).concat(additionalFormsValidationResults),
        };
        if (!validationResult.isValid || additionalFormsValidationResults.length) {
            dispatch(
                card.actionCreators.setErrorMessages(validationResult.panelErrorMessages, cardIndex)
            );
        }
        return buildValidationPromise(validationResult);
    };
};

export const submitCard = ({
    card,
    formComponent,
    formModule,
    // this function can return an array of promises which
    // is concatenated onto our validation promises array.
    // this needs to be done _after_ we trigger a form submission,
    // so that MFT has time to clear out values for hidden fields.
    // Only after that as happened we can retrieve the cleaned form state
    getPromisesForSubmission,
    promises = [],
    onSavingSuccess = noop,
    options = {},
}) => {
    return (dispatch) => {
        const cardIndex = { index: options.index };
        invariant(
            !!card && !!(formModule || formComponent),
            'Must provide both a card and either a formModule or formComponent to submitCard.'
        );
        dispatch(card.actionCreators.savingStart(cardIndex));

        let errorMessagesToThrow = [];

        return (formComponent
            ? // in this case, we have an instsance of a markformythree <Form />,
              // we wrap the Promise returned from `submit` in `Promise.resolve()` because
              // it's important that we are working with a bluebird Promise instance since
              // not all browsers used by our users have Promise.prototype.finally, which
              // is used downstream
              Promise.resolve(formComponent.submit({ customEventType: options.customEventType }))
            : // in this case, we have a formModule
              // We cannot pass our promises here to `submit`.  This is
              // because `submit` will `reject` with the Redux panel
              // errors only.  This is an issue for us here -- _both_
              // redux and KO validation could fail, or just one of the two,
              // or neither.  We need to do things serially, so that we can
              // collect error messages from both sets of validation... in
              // other words, we need to handle validation manually.
              dispatch(
                  formModule.actionCreators.submit(
                      // handle the `callback` in `submit`.  No need to
                      // return a `Promise` as we're handling things manually.
                      noop,
                      { forceSubmit: true }
                  )
              )
        )
            .then(() => {
                // If we made it here, that means Redux validation passed.
                // Do our manual validation & "actual" submit.
                return Promise.all(
                    getPromisesForSubmission
                        ? [...promises, ...getPromisesForSubmission()]
                        : promises
                )
                    .then(() => {
                        // If we made it here, then KO validation passed
                        // and submit was successful.
                        dispatch(
                            card.actionCreators.savingSuccess({
                                ...cardIndex,
                                summaryMode:
                                    options.changeToSummaryModeAfterSubmission &&
                                    !!options.changeToSummaryModeAfterSubmission,
                            })
                        );
                        // Call our success callback
                        onSavingSuccess();
                    })
                    .catch((koPanelErrorMessages) => {
                        // KO validation failed _or_ there was a network
                        // request error when submitting.
                        const koErrorMessages = getErrorMessagesFromErrors(
                            koPanelErrorMessages,
                            genericSaveError
                        );
                        dispatch(card.actionCreators.savingFailure(koErrorMessages, cardIndex));
                        // gather error messages to throw errors to parent Card
                        errorMessagesToThrow = _(errorMessagesToThrow)
                            .concat(koErrorMessages)
                            .value();
                    });
            })
            .catch((reduxPanelErrorMessages) => {
                // If we made it here, that means Redux validation failed.
                // Still, we submit and perform KO validation to collect
                // error messages.
                // markformythree passes a wrapper around the validation result itself,
                // and so we duck type to see if we're receiving a markformythree error and, if so,
                // we access the validation result on it
                const errorObject = reduxPanelErrorMessages.validationResult
                    ? reduxPanelErrorMessages.validationResult
                    : reduxPanelErrorMessages;

                const reduxErrorMessages = getErrorMessagesFromErrors(
                    errorObject,
                    genericSaveError,
                    { keepTemplateString: true }
                );

                return Promise.all(
                    getPromisesForSubmission
                        ? [...promises, ...getPromisesForSubmission()]
                        : promises
                )
                    .then(() => {
                        // If we made it here, then Redux failed while
                        // KO succeeded.
                        dispatch(card.actionCreators.savingFailure(reduxErrorMessages, cardIndex));
                        // gather error messages to throw errors to parent Card
                        errorMessagesToThrow = _(errorMessagesToThrow)
                            .concat(reduxErrorMessages)
                            .value();
                    })
                    .catch((koPanelErrorMessages) => {
                        // If we made it here, then both Redux and KO
                        // failed.
                        const koErrorMessages = getErrorMessagesFromErrors(
                            koPanelErrorMessages,
                            genericSaveError
                        );
                        const mergedErrorMessages = _(reduxErrorMessages)
                            .concat(koErrorMessages)
                            .value();
                        const distinctErrorMessages = buildDistinctErrorMessages(
                            mergedErrorMessages
                        );
                        dispatch(
                            card.actionCreators.savingFailure(distinctErrorMessages, cardIndex)
                        );
                        // gather error messages to throw errors to parent Card
                        errorMessagesToThrow = _(errorMessagesToThrow)
                            .concat(distinctErrorMessages)
                            .value();
                    });
            })
            .finally(() => {
                if (size(errorMessagesToThrow) > 0) {
                    const distinctErrorMessagesToThrow = buildDistinctErrorMessages(
                        errorMessagesToThrow
                    );
                    throw distinctErrorMessagesToThrow;
                }
            });
    };
};

// **********
// helpers
// **********

export const cardScrollHotKeyConfigForAnchor = (anchor) => {
    return {
        [hotkeysActionEnum.SCROLL_TO_NEXT_CARD.name]: {
            handler: () => {
                blurActiveElement();
                scrollToCardAtOffsetAndFocusFirstElement({
                    startingAnchor: anchor,
                    offset: 1,
                });
            },
        },
        [hotkeysActionEnum.SCROLL_TO_PREVIOUS_CARD.name]: {
            handler: () => {
                blurActiveElement();
                scrollToCardAtOffsetAndFocusFirstElement({
                    startingAnchor: anchor,
                    offset: -1,
                });
            },
        },
    };
};
