import { createSelector } from 'reselect';
import { get, map, uniq, concat } from 'lodash';

import { reportsSelector, reportsWhereSelector } from '../../../reports/state/data';
import { reportShortTitlesWhereSelector } from '../../../report-short-titles/state/data';
import { warrantsWhereSelector } from '../../../warrants/state/data';
import { elasticWarrantsWhereSelector } from '../../../elastic-warrants/state/data';
import { caseByIdSelector } from '../../../cases/state/data';
import { applicationSettingsSelector } from '../../../settings/state/data';

export const reportIdsForRenSelector = createSelector(
    reportsWhereSelector,
    reportShortTitlesWhereSelector,
    (reportsWhere, reportShortTitlesWhere) => (
        reportingEventNumber: string,
        departmentId?: number
    ) => {
        return uniq(
            concat(
                map(reportsWhere({ reportingEventNumber, departmentId }), 'id'),
                map(reportShortTitlesWhere({ reportingEventNumber, departmentId }), 'reportId')
            )
        );
    }
);

export const reportIdsForCaseIdSelector = createSelector(
    reportIdsForRenSelector,
    reportsSelector,
    caseByIdSelector,
    applicationSettingsSelector,
    (reportIdsForRen, reports, caseById, applicationSettings) => (caseId: number) => {
        const c = caseById(caseId);
        const departmentId = get(c, 'departmentId');

        const caseEnhancementsEnabled = !!applicationSettings.RMS_INVESTIGATION_ENHANCEMENTS_PHASE_ONE;
        if (caseEnhancementsEnabled) {
            // All the reports in the redux store can be returned since when entering a report,
            // any reports in the store are cleared and loaded with only those existing on the case
            return map(reports, 'id');
        } else {
            const caseRen = get(c, 'reportingEventNumber');
            return caseRen ? reportIdsForRen(caseRen, departmentId) : [];
        }
    }
);

// checks all possible data sources for safety
const warrantIdsForRenSelector = createSelector(
    warrantsWhereSelector,
    elasticWarrantsWhereSelector,
    (warrantsWhere, elasticWarrantsWhere) => (
        reportingEventNumber: string,
        departmentId?: number
    ) => {
        const warrantIds = map(warrantsWhere({ reportingEventNumber, departmentId }), 'id');
        const elasticWarrantIds = map(
            elasticWarrantsWhere({ reportingEventNumber, departmentId }),
            'id'
        );

        // A line checking warrantTitles for ids has been removed because it always returned []
        // See: TW-1505 8-10-2020

        const allWarrantIds = uniq(warrantIds.concat(elasticWarrantIds));
        return allWarrantIds;
    }
);

export const warrantIdsForCaseIdSelector = createSelector(
    warrantIdsForRenSelector,
    caseByIdSelector,
    (warrantIdsForRen, caseById) => (caseId: number) => {
        const c = caseById(caseId);
        const caseRen = get(c, 'reportingEventNumber');
        const departmentId = get(c, 'departmentId');
        return caseRen ? warrantIdsForRen(caseRen, departmentId) : [];
    }
);
