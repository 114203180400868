import { DexDevice } from '@mark43/dex-api';
import createNormalizedModule from '../../../../utils/createNormalizedModule';
import buildDexDevicesResource from '../../resources/dexDevicesResource';
import { ClientCommonAction } from '../../../../../redux/types';

export const NEXUS_STATE_PROP = 'dexDevices';

export const dexDevicesModule = createNormalizedModule<DexDevice>({
    type: NEXUS_STATE_PROP,
});

const LOAD_DEX_DEVICES_START = 'dex-devices/LOAD_DEX_DEVICES_START';
const LOAD_DEX_DEVICES_SUCCESS = 'dex-devices/LOAD_DEX_DEVICES_SUCCESS';
const LOAD_DEX_DEVICES_FAILURE = 'dex-devices/LOAD_DEX_DEVICES_FAILURE';

const DELETE_DEX_DEVICE_START = 'dex-devices/DELETE_DEX_DEVICE_START';
const DELETE_DEX_DEVICE_SUCCESS = 'dex-devices/DELETE_DEX_DEVICE_SUCCESS';
const DELETE_DEX_DEVICE_FAILURE = 'dex-devices/DELETE_DEX_DEVICE_FAILURE';

function loadDexDevicesStart() {
    return { type: LOAD_DEX_DEVICES_START };
}

function loadDexDevicesSuccess(dexDevices: DexDevice[]) {
    return { type: LOAD_DEX_DEVICES_SUCCESS, payload: dexDevices };
}

function loadDexDevicesFailure(errorMessage: string) {
    return { type: LOAD_DEX_DEVICES_FAILURE, payload: errorMessage, error: true };
}

function deleteDexDeviceStart() {
    return { type: DELETE_DEX_DEVICE_START };
}

function deleteDexDeviceSuccess() {
    return { type: DELETE_DEX_DEVICE_SUCCESS };
}

function deleteDexDeviceFailure(errorMessage: string) {
    return { type: DELETE_DEX_DEVICE_FAILURE, payload: { errorMessage } };
}

export function loadDexDevices(): ClientCommonAction<Promise<DexDevice[]>> {
    return function (dispatch) {
        dispatch(loadDexDevicesStart());
        const resource = buildDexDevicesResource();
        return resource
            .getDexDevices()
            .then((dexDevices: DexDevice[]) => {
                dispatch(
                    dexDevicesModule.withEntityItems(
                        {
                            [NEXUS_STATE_PROP]: dexDevices,
                        },
                        loadDexDevicesSuccess(dexDevices)
                    )
                );

                return dexDevices;
            })
            .catch((error: Error) => {
                dispatch(loadDexDevicesFailure(error.message));
                throw error;
            });
    };
}

export function deleteDexDevice(dexDeviceId: number): ClientCommonAction<Promise<void>> {
    return function (dispatch) {
        dispatch(deleteDexDeviceStart());
        const resource = buildDexDevicesResource();
        return resource
            .deleteDexDevice([dexDeviceId])
            .then(() => {
                dispatch(dexDevicesModule.actionCreators.deleteEntity(dexDeviceId));
                dispatch(deleteDexDeviceSuccess());
                return dexDeviceId;
            })
            .catch((error: Error) => {
                dispatch(deleteDexDeviceFailure(error.message));
                throw error;
            });
    };
}

export const dexDevicesSelector = dexDevicesModule.selectors.entitiesSelector;

// REDUCER
export default dexDevicesModule.reducerConfig;
