import _ from 'lodash';

export const checkPhoneLength = (phoneNumber) => {
    if (_.isNull(phoneNumber) || _.isUndefined(phoneNumber) || phoneNumber === '') {
        return true;
    }
    if (!_.isString(phoneNumber)) {
        return false;
    }
    // split on possible extension flags
    const splitNumber = phoneNumber.split(/#|x\.?|extension|ext\.?|ex\.?/);
    let phoneNum = '';
    let ext = '';
    if (splitNumber.length > 1) {
        // if there's an extension
        for (let i = 0; i < splitNumber.length - 1; i++) {
            // phone num is anything before extension
            phoneNum += splitNumber[i];
        }
        if (_.trim(splitNumber[splitNumber.length - 1]).match(/[^0-9]/g)) {
            // if ext contains a non digit char, then counts as part of phone num
            phoneNum += splitNumber[splitNumber.length - 1];
        } else {
            // else ext contains only numbers so trim the white space
            ext = _.trim(splitNumber[splitNumber.length - 1]);
        }
        // get rid of non digits for counting
        phoneNum = phoneNum.replace(/[^0-9]/g, '');
    } else {
        // if no extension flag
        phoneNum = splitNumber[0].replace(/[^0-9]/g, '');
    }
    return phoneNum.length < 15 && ext.length <= 8;
};

export const checkPhoneValidCharacters = (phoneNumber) => {
    return /^[-()+. 0-9]+$/.test(phoneNumber);
};
