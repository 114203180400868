import React from 'react';

import componentStrings from '~/client-common/core/strings/componentStrings';
import Page from '../../../core/components/Page';
import Subheader from '../../../core/components/Subheader';
import UnderSubheader from '../../../core/components/UnderSubheader';
import { AnalyticsErrorBoundary } from '../../../core/errors/components/ErrorBoundary';
import { InsightsContextProvider } from './InsightsContext';

const strings = componentStrings.insights.core.Insights;

export default function Insights({ children }: { children: React.ReactNode }) {
    return (
        <InsightsContextProvider>
            <AnalyticsErrorBoundary>
                <Page className="insights">
                    <Subheader fullscreen title={strings.title} />
                    <UnderSubheader fullscreen={true}>{children}</UnderSubheader>
                </Page>
            </AnalyticsErrorBoundary>
        </InsightsContextProvider>
    );
}
