import React, { ReactNode, useCallback } from 'react';
import {
    FederatedSearchClient,
    FederatedSearchPerson,
    QueryableEntityType,
} from 'mark43-federated-search';
import OverlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import testIds from '../../../core/testIds';
import { OverlayBaseHelper } from '../../core/components/OverlayBaseHelper';
import SidePanel from '../../core/overlays/components/SidePanel';

const dexTitle = componentStrings.core.navigation.dex;

export const FederatedSearchSidePanel = ({
    renderButton,
    addDexPersonToReport,
    addDexPersonToProfile,
}: {
    renderButton: () => ReactNode;
    addDexPersonToReport?: (dexPersonProfile: FederatedSearchPerson) => void;
    addDexPersonToProfile?: (dexPersonProfile: FederatedSearchPerson) => void;
}) => {
    const overlayId = OverlayIdEnum.DEX_SIDE_PANEL;
    const addToReport = useCallback(
        ({ result }) => {
            if (result?.[QueryableEntityType.PERSON]?.[0] && addDexPersonToReport) {
                addDexPersonToReport(result?.[QueryableEntityType.PERSON]?.[0]);
            }
        },
        [addDexPersonToReport]
    );

    const addToProfile = useCallback(
        ({ result }) => {
            if (result?.[QueryableEntityType.PERSON]?.[0] && addDexPersonToProfile) {
                addDexPersonToProfile(result?.[QueryableEntityType.PERSON]?.[0]);
            }
        },
        [addDexPersonToProfile]
    );

    return (
        <OverlayBaseHelper id={overlayId} renderButton={renderButton}>
            {({ overlayBase }) => (
                <SidePanel
                    id={overlayId}
                    title={dexTitle}
                    testId={testIds.DEX_SIDE_PANEL}
                    closePanel={() => {
                        overlayBase.close();
                    }}
                    saveText={null}
                >
                    {/* Calculate inline height for side panel. Height - header, padding, footer */}
                    <div style={{ height: 'calc(100vh - 41px - 40px - 64px)' }}>
                        <FederatedSearchClient
                            buttonsConfiguration={{
                                report: addDexPersonToReport && {
                                    addToReport,
                                },
                                profile: addDexPersonToProfile && {
                                    addToProfile,
                                },
                            }}
                        />
                    </div>
                </SidePanel>
            )}
        </OverlayBaseHelper>
    );
};
