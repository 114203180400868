import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import reportCardEnum from '~/client-common/core/enums/universal/reportCardEnum';
import { reportCardTitleByReportIdAndCardIdSelector } from '~/client-common/core/domain/report-definitions/state/data';

import Card from '../../../../../legacy-redux/components/core/Card';
import { registerCard } from '../../utils/cardsRegistry';
import testIds from '../../../../../core/testIds';
import { RMSArbiterProvider } from '../../../../core/arbiter';
import trafficCrashEventInfoCard from '../../state/ui/trafficCrashEventInfoCard';
import { formName } from '../../state/forms/trafficCrashEventInfoForm';
import withCard from '../../utils/withCard';
import { ReportCardProps } from '../../types';
import TrafficCrashEventInfoCardForm from './TrafficCrashEventInfoCardForm';
import TrafficCrashEventInfoSummary from './TrafficCrashEventInfoSummary';

const TrafficCrashEventInfoCard: React.FC<ReportCardProps> = ({
    card,
    currentReportId,
    editCallback,
    onEdit,
    onSave,
}) => {
    const reportCardTitleByReportIdAndCardId = useSelector(
        reportCardTitleByReportIdAndCardIdSelector
    );

    useEffect(() => {
        const unregisterCard = registerCard({
            cardModule: trafficCrashEventInfoCard,
            onSave,
            index: currentReportId,
        });

        return () => {
            unregisterCard();
        };
    }, [currentReportId, onSave]);

    const cardTitle = reportCardTitleByReportIdAndCardId(
        currentReportId,
        reportCardEnum.CRASH_EVENT_INFO.id
    );

    const onCardEdit = () => {
        editCallback(() => onEdit({ index: currentReportId }));
    };

    return (
        <Card
            testId={testIds.CRASH_EVENT_INFO_CARD}
            canEdit={card.canEditEventInfoReportCardStatus.canEditReportCard}
            onEdit={onCardEdit}
            onSave={onSave}
            summaryMode={card.summaryMode}
            className="traffic-crash-event-info-card"
            anchor="traffic-crash-event-info-card"
            title={cardTitle}
            renderContent={(summaryMode) => {
                return summaryMode ? (
                    <TrafficCrashEventInfoSummary />
                ) : (
                    <TrafficCrashEventInfoCardForm />
                );
            }}
        />
    );
};

const TrafficCrashEventInfoCardWrapper: React.FC<ReportCardProps> = (props) => {
    return (
        <RMSArbiterProvider context={formName}>
            {() => <TrafficCrashEventInfoCard {...props} />}
        </RMSArbiterProvider>
    );
};

export default withCard(trafficCrashEventInfoCard)(TrafficCrashEventInfoCardWrapper);
