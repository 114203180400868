import React from 'react';
import { cssVar, Input, EmptyState } from 'arc';
import { Text } from '@arc/typography';
import { Skeleton } from '@arc/skeleton';
import { Flex } from '@arc/layout';

import styled from 'styled-components';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { formatFileName } from '../helpers';
import { arrows, signs, vehicles, other, streetBackgrounds } from '../assets';
import {
    CrashDiagramAssetType,
    CrashDiagramAssetLookupType,
    crashDiagramAssetTypes,
    ASSET_SEARCH_DELAY,
} from '../config';
import { useFetchSVGAsset, useSearchAssets } from '../hooks';
import { useCrashDiagram } from '../context/CrashDiagramContext';
import { SearchState } from '../hooks/search-assets-reducer';
import { Widget } from './Widget';
import { NoImagePlaceHolder } from './shared';

const crashDiagramModalStrings = componentStrings.dragon.crashDiagram.CrashDiagramModal;

const ScrollableAssetsPanelWrapper = styled.div`
    background-color: var(--arc-colors-surface-foreground);
    height: 14.69rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-x: auto;
    position: absolute;
    z-index: 1;
    border-top: 1px solid var(--arc-colors-border-default);
    border-bottom: 1px solid var(--arc-colors-border-default);
    border-radius: var(--arc-radii-base);
    padding: var(--arc-space-3);
`;

const AssetsContainer = styled.div`
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    height: 100%;
`;

const StyledInput = styled(Input)`
    width: 12.5rem;
`;

const data: CrashDiagramAssetLookupType = {
    ARROWS: arrows,
    SIGNS: signs,
    VEHICLES: vehicles,
    STREET_BACKGROUNDS: streetBackgrounds,
    OTHER: other,
};

const AssetWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    padding-left: ${cssVar('arc.space.2')};
    padding-right: ${cssVar('arc.space.2')};
`;

const DiagramAssetWrapper = styled(AssetWrapper)`
    padding: ${cssVar('arc.space.4')};
`;

const BackgroundWrapper = styled.div`
    width: 100%;
    height: fit-content;
    display: inline-block;
`;

const DiagramBackgroundAsset = styled(LazyLoadImage)`
    max-width: 100px;
    width: 100px;
    max-height: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: var(--arc-radii-base);
    filter: brightness(85%) contrast(135%);
`;

type AssetPanelProps = {
    onClick?: (assetUrl: string) => void;
    assetType: CrashDiagramAssetType;
    render: (args: { labelComponent: React.ReactNode; src: string }) => React.ReactNode;
};

const AssetsPanel: React.FC<AssetPanelProps> = ({ assetType, render, onClick }) => {
    const initialState: SearchState = {
        searchTerm: '',
        filteredAssets: data[assetType],
        isLoading: false,
    };
    const { filteredAssets, searchTerm, isLoading, handleChange } = useSearchAssets(
        initialState,
        ASSET_SEARCH_DELAY
    );

    if (!assetType) {
        return null;
    }

    const Wrapper =
        assetType === crashDiagramAssetTypes.STREET_BACKGROUNDS
            ? DiagramAssetWrapper
            : AssetWrapper;

    return (
        <ScrollableAssetsPanelWrapper>
            <StyledInput
                onChange={handleChange}
                value={searchTerm}
                leftIcon="Search"
                isClearable
                isLoading={isLoading}
            />
            <AssetsContainer>
                {filteredAssets.length === 0 && !isLoading ? (
                    <Flex justifyContent="center" alignItems="center" width="100%">
                        <EmptyState title="" subtitle={crashDiagramModalStrings.noResultsFound} />
                    </Flex>
                ) : (
                    filteredAssets.map((src, index) => {
                        return (
                            <Wrapper
                                key={index}
                                onClick={() => {
                                    if (onClick) {
                                        onClick(src);
                                    }
                                }}
                            >
                                {render({
                                    labelComponent: <AssetLabel label={formatFileName(src)} />,
                                    src,
                                })}
                            </Wrapper>
                        );
                    })
                )}
            </AssetsContainer>
        </ScrollableAssetsPanelWrapper>
    );
};

type AssetLabelProps = {
    label: string;
};

const AssetLabel: React.FC<AssetLabelProps> = ({ label }) => {
    return (
        <Text align="center" variant="caption" fontWeight="bold">
            {label}
        </Text>
    );
};

const BackgroundThumbnail: React.FC<{ src: string }> = ({ src }) => {
    const { setBackground, backgroundImage } = useCrashDiagram();
    const { svgDataUrl: backgroundWidgetSVGDataUrl, isLoading: isFetchingBackgroundWidget } =
        useFetchSVGAsset({
            value: src,
            assetKey: src,
        });

    const { svgDataUrl: backgroundImageSVGDataUrl, isLoading: isFetchingBackgroundImage } =
        useFetchSVGAsset({
            value: backgroundImage,
            assetKey: backgroundImage,
        });

    const isLoading = isFetchingBackgroundWidget || isFetchingBackgroundImage;

    if (!backgroundWidgetSVGDataUrl && !isLoading) {
        return <NoImagePlaceHolder />;
    }

    const isSelected = backgroundImageSVGDataUrl === backgroundWidgetSVGDataUrl;

    return (
        <Skeleton isLoaded={!isLoading}>
            <BackgroundWrapper>
                <DiagramBackgroundAsset
                    src={backgroundWidgetSVGDataUrl}
                    onClick={() => {
                        if (backgroundWidgetSVGDataUrl) {
                            setBackground(backgroundWidgetSVGDataUrl);
                        }
                    }}
                    effect="opacity"
                    style={
                        isSelected
                            ? {
                                  border: 'var(--arc-borders-md) var(--arc-colors-selected-default)',
                              }
                            : undefined
                    }
                />
            </BackgroundWrapper>
        </Skeleton>
    );
};

const ScrollableBackgroundPanel = () => {
    return (
        <AssetsPanel
            assetType={crashDiagramAssetTypes.STREET_BACKGROUNDS}
            render={({ labelComponent, src }) => {
                return (
                    <>
                        <BackgroundThumbnail src={src} />
                        {labelComponent}
                    </>
                );
            }}
        />
    );
};

type ScrollableAssetsProps = { assetType?: CrashDiagramAssetType };

export const ScrollableAssetsPanel: React.FC<ScrollableAssetsProps> = ({ assetType }) => {
    if (!assetType) {
        return null;
    }

    if (assetType === crashDiagramAssetTypes.STREET_BACKGROUNDS) {
        return <ScrollableBackgroundPanel />;
    }

    return (
        <AssetsPanel
            assetType={assetType}
            render={({ labelComponent, src }) => {
                return (
                    <>
                        <Widget src={src} />
                        {labelComponent}
                    </>
                );
            }}
        />
    );
};
