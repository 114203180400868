import { AttributeTypeEnum, EntityTypeEnum } from '@mark43/rms-api';

import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';

import { ArcPriorityEnum } from '../../components/tags/helpers';

export type OmitFromUnion<Type, Key extends keyof Type> = Type extends Type
    ? Omit<Type, Key>
    : never;
export type CautionEntityTypes =
    | typeof EntityTypeEnum.PERSON_PROFILE.name
    | typeof EntityTypeEnum.VEHICLE.name;
export type CautionEntityProfileTypes = 'persons' | 'vehicles';
export interface CautionComputedData {
    label: string;
    priority: ArcPriorityEnum;
}
export interface PersonCustomCautionsData {
    isActiveTarget?: boolean;
    isJuvenile?: boolean;
    isSuspectedGangMember?: boolean;
    isVulnerable?: boolean;
    dateVulnerableTo?: string;
    hasPotentialActiveWarrant?: boolean;
}
export interface VehicleCustomCautionsData {
    isStolen?: boolean;
    isActiveTarget?: boolean;
}

const globalPriorityAttributes = globalAttributes.personLabelPriority;
const { priority1, priority2, priority3, priority4 } = globalPriorityAttributes;

export const DEFAULT_ARC_PRIORITY = ArcPriorityEnum.PriorityThree;
export const PERSON_PROFILE_FORM_ADD_CAUTION_BUTTON_ID = 'add-caution-item-button';

export const entityTypeToCautionAttributeTypes = {
    [EntityTypeEnum.PERSON_PROFILE.name]: {
        valueAttrType: AttributeTypeEnum.PERSON_LABEL_ATTRIBUTES.name,
        categoryAttrType: AttributeTypeEnum.PERSON_LABEL_CATEGORY.name,
    },
    [EntityTypeEnum.VEHICLE.name]: {
        valueAttrType: AttributeTypeEnum.VEHICLE_LABEL_ATTRIBUTES.name,
        categoryAttrType: AttributeTypeEnum.VEHICLE_LABEL_CATEGORY.name,
    },
} as const;

export const priorityAttrIdToArcPriority = {
    [priority1]: ArcPriorityEnum.PriorityOne,
    [priority2]: ArcPriorityEnum.PriorityTwo,
    [priority3]: ArcPriorityEnum.PriorityThree,
    [priority4]: ArcPriorityEnum.PriorityFour,
} as const;

export const entityTypeToEntityProfileType = {
    [EntityTypeEnum.PERSON_PROFILE.name]: 'persons',
    [EntityTypeEnum.VEHICLE.name]: 'vehicles',
};

export const entityProfileTypeToEntityType = {
    persons: EntityTypeEnum.PERSON_PROFILE.name,
    vehicles: EntityTypeEnum.VEHICLE.name,
};

export const cautionFormFields = [
    'id',
    'cautionAttrId',
    'cautionOther',
    'dateEffectiveFrom',
    'dateEffectiveTo',
    'description',
    'provenance',
] as const;
