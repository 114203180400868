import React, { useEffect, useRef } from 'react';
import _ from 'lodash';
import { isElementOfType } from 'react-dom/test-utils';
import ReactDOM from 'react-dom';
import { compose, lifecycle, toClass, withState } from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import $ from 'jquery';
import createElement from '../../modules/core/utils/recompose.createElement';

import { windowHeightSelector } from '../selectors/globalSelectors';
import { headerHeight, subheaderHeight } from '../configs/globalConfig';

export { renderOnlyIf } from '~/client-common/helpers/reactHelpers';

let globalScrollHandlerIndex = 0;

/**
 * Higher-order component that binds our global scroll handler (using jQuery) on
 *   the content element when the base component is mounted, and unbinds when
 *   unmounted. The reason we don't just bind the handler on DOM ready is due to
 *   performance problems with components that re-render on scroll when they
 *   shouldn't (we try to also fix the re-rendering problem on those components
 *   themselves, but it's hard to avoid those mistakes everywhere).
 *
 * This HOC is needed when either `clippable()` or `contentScrollTopSelector` is
 *   used.
 *
 * Each instance of this handler has a unique index in order to not conflict with
 *   each other.
 *
 * Todo is to rewrite this HOC as a hook without jQuery.
 * @param  {Component} Component
 * @return {Component}
 */
export const withContentScrollHandler = compose(
    withState('scrollValue', 'setScroll', 0),
    lifecycle({
        componentDidMount() {
            // Throttle will not work without the function reference staying constant
            // i.e. cannot write a inline function in the event handler
            const setScroll = (event) => {
                this.props.setScroll(event.target.scrollTop);
            };
            this.index = globalScrollHandlerIndex;
            globalScrollHandlerIndex++;
            $('.mark43-content, .mark43-scrollable-under-subheader').on(
                `scroll.content${this.index}`,
                _.throttle(setScroll, 16)
            );
        },
        componentWillUnmount() {
            $('.mark43-content, .mark43-scrollable-under-subheader').off(
                `scroll.content${this.index}`
            );
        },
    })
);

export function useOnClickOutside(ref, handler) {
    const handlerRef = useRef(handler);

    useEffect(() => {
        handlerRef.current = handler;
    });

    useEffect(
        () => {
            if (!handler) {
                return;
            }

            const listener = (event) => {
                if (!ref.current || !handlerRef.current || ref.current.contains(event.target)) {
                    return;
                }

                handlerRef.current(event);
            };

            document.addEventListener('click', listener, false);

            return () => {
                document.removeEventListener('click', listener, false);
            };
        },
        /* eslint-disable react-hooks/exhaustive-deps */
        [!handler]
    );
}

/**
 * HOC which gives a component "clipping" behaviors. More or less it describes
 *   the relationship between the viewport and the elements boundaries. This
 *   will be deprecated in favour of a React component version soon, i.e.
 *   `<Clippable>{(isClippedTop, isClippedBottom}) => <Children/>}</Clippable>`.
 * @typedef {object}  Options
 * @prop    {boolean} [subheader]
 * @prop    {number}  [constantOffset]
 * @prop    {number}  [bottomBuffer]
 * @prop    {number}  [topBuffer]
 * @param   {Props}  options
 * @return  {function} Higher-order component.
 */

export function clippable({
    // this is the config object passed to clippable
    // constantOffset = headerHeight + subheaderHeight,
    subheader = false,
    constantOffset = subheader ? subheaderHeight : headerHeight,
    bottomBuffer = 0,
    topBuffer = 0,
} = {}) {
    return (BaseComponent) => {
        const baseComponent = toClass(BaseComponent);
        const defaultClipObject = {
            clipConstantOffset: undefined,
            isClippedTop: false,
            isClippedBottom: false,
            clipAtTopLimit: false,
            clipAtBottomLimit: false,
        };
        const Clazz = class extends React.Component {
            constructor(props) {
                super(props);
                this.state = {};
            }
            setChildRect() {
                // don't update state when the component is not being rendered
                // in the DOM, which can happen when it's in an unselected tab
                if (!this.baseComponentInstance) {
                    return;
                }
                const childRect = ReactDOM.findDOMNode(this.baseComponentInstance) // eslint-disable-line react/no-find-dom-node
                    .getBoundingClientRect();
                if (childRect.top !== 0 && childRect.bottom !== 0) {
                    this.setState({ childRect });
                }
            }
            componentDidMount() {
                this.setChildRect();
            }
            UNSAFE_componentWillReceiveProps() {
                this.setChildRect();
            }
            render() {
                const { children, ...otherProps } = this.props;
                return createElement(
                    baseComponent,
                    {
                        ref: (c) => {
                            this.baseComponentInstance = c;
                        },
                        clippable: this.state.childRect
                            ? {
                                  clipConstantOffset: constantOffset,
                                  isClippedTop: this.state.childRect.top < constantOffset,
                                  isClippedBottom:
                                      this.state.childRect.bottom > this.props.windowHeight,
                                  clipAtTopLimit:
                                      this.state.childRect.top + topBuffer >
                                      this.props.windowHeight,
                                  clipAtBottomLimit:
                                      this.state.childRect.bottom - constantOffset - bottomBuffer <
                                      0,
                                  clipTopBuffer: topBuffer,
                                  clipBottomBuffer: bottomBuffer,
                              }
                            : defaultClipObject,
                        ...otherProps,
                    },
                    children
                );
            }
        };
        return compose(
            withContentScrollHandler,
            connect(
                createStructuredSelector({
                    windowHeight: windowHeightSelector,
                })
            )
        )(Clazz);
    };
}

/**
 * @see https://facebook.github.io/react/docs/test-utils.html
 */
export { isElementOfType };
