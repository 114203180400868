import { AttributeTypeEnum, EmailTypeEnum } from '@mark43/rms-api';
import React from 'react';
import { head, get, compact, map, filter } from 'lodash';

import { Observer, Fields } from 'markformythree';
import styled from 'styled-components';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import componentStrings from '~/client-common/core/strings/componentStrings';
import {
    getOtherLocationTypesLabelMap,
    otherPersonLocationTypes,
} from '~/client-common/helpers/linkTypesHelpers';
import Row from '../../components/Row';
import { responsiveStack } from '../../styles/mixins';
import { ArbiterMFTDatePicker } from '../../forms/components/DatePicker';
import { ArbiterMFTTextArea } from '../../forms/components/TextArea';
import LocationSummary from '../../../records/core/components/summaries/LocationSummary';
import { ArbiterMFTText } from '../../forms/components/Text';
import { ArbiterMFTPhoneNumberInput } from '../../forms/components/PhoneNumberInput';
import { ArbiterMFTCheckbox } from '../../forms/components/checkboxes/Checkbox';
import { ArbiterMFTBooleanButtonRadio } from '../../forms/components/button-radios/BooleanButtonRadio';
import { ArbiterMFTAttributeSelect } from '../../forms/components/selects/AttributeSelect';
import { MFTEntityProfileFormImageUploader } from '../../names/components/EntityProfileFormImageUploader';
import {
    phoneOptions,
    renderRemoveButtonWithLabelOnFirstItemOnly,
    renderRemoveButton,
} from '../../names/components/nameFormNItemsRenderers';
import testIds from '../../../../core/testIds';
import { locationEntityLinkSummaryVariants } from '../../../records/core/components/summaries/locations/LocationEntityLinkSummary';
import { ExpandableFormLabel, LabelExpander } from '../../components/ExpandableFormLabel';
import FormRow from '../../forms/components/FormRow';
import FormGroup from '../../forms/components/FormGroup';
import IndentedFields from '../../components/IndentedFields';
import { ArbiterMFTSelect } from '../../forms/components/selects/Select';
import {
    isOtherPhoneType,
    DefaultDateEffectiveFromToCurrent,
    isOtherEmailType,
} from '../../names/util/nameFormHelpers';
import { ArbiterMFTCountrySelect } from '../../forms/components/selects/CountrySelect';
import SummaryRow from '../../../../legacy-redux/components/summaries/SummaryRow';
import { IdentifyingMarksUploader } from './IdentifyingMarksUploader';
import { FormSection } from './FormSection';

const placeholders = componentStrings.core.genericPlaceholders;
const strings = componentStrings.core.PersonProfileForm.nameFormNItemsRenderers;

const emailOptions = map(EmailTypeEnum, (emailType) => {
    return {
        value: emailType.value,
        display: emailType.value,
    };
});

const TransparentFormSection = styled(FormSection)`
    background: transparent;
    padding: 0;
`;

const ResponsiveStack = styled.div`
    flex: 1;
    width: 100%;
    ${responsiveStack('sm')};
`;

const FlexRow = styled.div`
    display: flex;
    width: 100%;
    gap: var(--arc-space-3);
`;

const FlexRowWithMargin = styled.div`
    display: flex;
    width: 100%;
    margin-bottom: var(--arc-space-2);
`;

export function renderLocationNItems({
    locationBundleWithUnknownLocationId,
    renderDescription,
    formPath,
    locationSummaryTitle,
}) {
    return (
        <FormGroup>
            <LocationSummary
                locationBundle={{
                    location: locationBundleWithUnknownLocationId.location,
                    unknownLocationId: locationBundleWithUnknownLocationId.unknownLocationId,
                    locationEntityLink: head(locationBundleWithUnknownLocationId.entityLinks),
                }}
                // omit the fields we are showing in the form so that they don't show up twice
                locationEntityLinkSummaryVariant={
                    locationEntityLinkSummaryVariants.WITHOUT_DESCRIPTION_AND_START_DATE_UTC
                }
                title={locationSummaryTitle}
            />
            <ArbiterMFTDatePicker path="startDateUtc" />
            <ArbiterMFTDatePicker path="endDateUtc" />
            <ArbiterMFTAttributeSelect
                path="positionAttrId"
                attributeType={AttributeTypeEnum.LOCATION_POSITION.name}
                length="md"
            />
            {renderDescription && (
                <ExpandableFormLabel
                    formPathsToObserve={[`${formPath}.description`]}
                    expanderContent={(showContent) => (
                        <LabelExpander
                            position="bottom"
                            onClick={showContent}
                            data-test-id={testIds.PERSON_SIDE_PANEL_ADD_NOTES}
                        >
                            {strings.addNotes}
                        </LabelExpander>
                    )}
                >
                    <ArbiterMFTTextArea path="description" />
                </ExpandableFormLabel>
            )}
        </FormGroup>
    );
}

export function renderHomeAddressLocationNItems({
    locationBundleWithUnknownLocationId,
    formPath,
    locationSummaryTitle,
}) {
    return renderLocationNItems({
        formPath,
        locationBundleWithUnknownLocationId,
        renderDescription: true,
        locationSummaryTitle,
    });
}

export function renderOtherAddressLocationNItemsSummary({ locationBundles }, formatFieldByName) {
    const otherLocationTypesLabelMap = getOtherLocationTypesLabelMap(formatFieldByName);
    return map(otherPersonLocationTypes, (locationType) => {
        const matchingLocations = filter(
            locationBundles,
            (bundle) => bundle.locationEntityLink.linkType === locationType
        );
        return (
            matchingLocations.length > 0 && (
                <SummaryRow label={otherLocationTypesLabelMap[locationType]}>
                    {map(matchingLocations, (otherLocation) => {
                        return (
                            <LocationSummary
                                key={otherLocation.location.id}
                                locationBundle={otherLocation}
                            />
                        );
                    })}
                </SummaryRow>
            )
        );
    });
}

export function renderEmploymentHistoriesNItems(form) {
    return (
        <FormGroup>
            <ArbiterMFTText path="employerName" />
            <ArbiterMFTText path="employerAddress" />
            <FormRow>
                <ArbiterMFTText path="occupation" length="md" />
                <FeatureFlagged
                    flag="RMS_DIALING_CODE_DISPLAYED_ENABLED"
                    fallback={
                        <ArbiterMFTText
                            placeholder={placeholders.phoneNumbers}
                            path="displayNumber"
                            length="md"
                        />
                    }
                />
            </FormRow>
            <FeatureFlagged flag="RMS_DIALING_CODE_DISPLAYED_ENABLED">
                <ArbiterMFTPhoneNumberInput path="displayNumber" form={form} />
            </FeatureFlagged>
            <Row>
                <ArbiterMFTDatePicker
                    path="dateStart"
                    variant={ArbiterMFTDatePicker.variants.LOCAL_DATE}
                />
                <ArbiterMFTDatePicker
                    path="dateEnd"
                    variant={ArbiterMFTDatePicker.variants.LOCAL_DATE}
                />
            </Row>
        </FormGroup>
    );
}

export function renderMilitaryHistoriesNItems() {
    return (
        <FormGroup>
            <ArbiterMFTAttributeSelect
                path="militaryBranchAttrId"
                attributeType={AttributeTypeEnum.MILITARY_BRANCH.name}
            />
            <Row>
                <ArbiterMFTDatePicker
                    path="dateStart"
                    variant={ArbiterMFTDatePicker.variants.LOCAL_DATE}
                />
                <ArbiterMFTDatePicker
                    path="dateEnd"
                    variant={ArbiterMFTDatePicker.variants.LOCAL_DATE}
                />
            </Row>
        </FormGroup>
    );
}

export function renderSchoolHistoriesNItems(form) {
    return (
        <FormGroup>
            <ArbiterMFTText path="schoolName" />

            <ArbiterMFTText path="schoolAddress" />
            <FormRow>
                <ArbiterMFTText path="grade" length="md" />
                <ArbiterMFTText path="status" length="md" />
            </FormRow>
            <FeatureFlagged
                flag="RMS_DIALING_CODE_DISPLAYED_ENABLED"
                fallback={
                    <ArbiterMFTText
                        placeholder={placeholders.phoneNumbers}
                        path="displayNumber"
                        length="md"
                    />
                }
            >
                <ArbiterMFTPhoneNumberInput path="displayNumber" form={form} length="md" />
            </FeatureFlagged>
        </FormGroup>
    );
}

export function renderIdentifyingMarksNItems(item) {
    const images = compact([get(item, 'imageId.image')]);

    return (
        <FormGroup>
            <FormRow>
                <ArbiterMFTAttributeSelect
                    path="identifyingMarkTypeAttrId"
                    attributeType={AttributeTypeEnum.IDENTIFYING_MARK_TYPE.name}
                    length="md"
                />
                <ArbiterMFTAttributeSelect
                    path="bodyPartAttrId"
                    attributeType={AttributeTypeEnum.BODY_PART.name}
                    length="md"
                />
            </FormRow>
            <ArbiterMFTText path="description" />
            <MFTEntityProfileFormImageUploader
                path="imageId"
                images={images}
                children={({ imageUploadApi }) => (
                    <IdentifyingMarksUploader imageUploadApi={imageUploadApi} />
                )}
            />
        </FormGroup>
    );
}

export function renderMonikersNItems(props) {
    return (
        <FlexRowWithMargin>
            <ResponsiveStack>
                <ArbiterMFTText path="moniker" />
                <ArbiterMFTDatePicker
                    path="dateOfBirthOther"
                    variant={ArbiterMFTDatePicker.variants.LOCAL_DATE}
                />
            </ResponsiveStack>
            {renderRemoveButtonWithLabelOnFirstItemOnly(props)}
        </FlexRowWithMargin>
    );
}

export function renderPassportsNItems({ removeItem }) {
    return (
        <TransparentFormSection removeItem={removeItem}>
            <ArbiterMFTText path="number" length="md" />
            <Row>
                <ArbiterMFTDatePicker
                    path="dateOfExpiry"
                    variant={ArbiterMFTDatePicker.variants.LOCAL_DATE}
                />
                <ArbiterMFTDatePicker
                    path="dateOfIssue"
                    variant={ArbiterMFTDatePicker.variants.LOCAL_DATE}
                />
            </Row>
            <ArbiterMFTCountrySelect path="country" namesOnly={true} length="lg" />
        </TransparentFormSection>
    );
}

export function renderPersonPhoneNumbersNItems({ index, form, removeItem }) {
    const path = `phoneNumbers[${index}]`;

    return (
        <FlexRow>
            <FormGroup>
                <FormRow>
                    <ArbiterMFTSelect path="phoneType" length="md" options={phoneOptions} />
                    <FeatureFlagged
                        flag="RMS_DIALING_CODE_DISPLAYED_ENABLED"
                        fallback={
                            <ArbiterMFTText
                                placeholder={placeholders.phoneNumbers}
                                length="md"
                                path="displayNumber"
                            />
                        }
                    />
                </FormRow>
                <FeatureFlagged flag="RMS_DIALING_CODE_DISPLAYED_ENABLED">
                    <ArbiterMFTPhoneNumberInput path="displayNumber" form={form} />
                </FeatureFlagged>
                <Observer
                    subscriptions={{
                        phoneType: `${path}.phoneType`,
                    }}
                    render={({ phoneType }) =>
                        isOtherPhoneType(phoneType) ? (
                            <ArbiterMFTText path="phoneTypeOther" length="md" />
                        ) : null
                    }
                />
                <ArbiterMFTText path="provenance" />
                <Row>
                    <Observer
                        subscriptions={{
                            id: ['id', Fields.MODEL],
                            dateNonPrefillConditions: [`${path}.dateEffectiveFrom`, [Fields.DIRTY]],
                        }}
                        render={({ id, dateNonPrefillConditions }) => {
                            return (
                                <DefaultDateEffectiveFromToCurrent
                                    entityId={id}
                                    form={form}
                                    NItemPath={path}
                                    dateNonPrefillConditions={dateNonPrefillConditions}
                                />
                            );
                        }}
                    />
                    <ArbiterMFTDatePicker
                        path="dateEffectiveTo"
                        variant={ArbiterMFTDatePicker.variants.LOCAL_DATE}
                    />
                </Row>
                <ArbiterMFTCheckbox path="isPrimary" length="md" />
                <ArbiterMFTCheckbox path="isSafeContact" />
                <FormRow hasIndent>
                    <ArbiterMFTText path="safeContactDetails" length="lg" />
                </FormRow>
            </FormGroup>
            {renderRemoveButton({ removeItem })}
        </FlexRow>
    );
}

export function renderPersonEmailsNItems(props) {
    const path = `emails[${props.index}]`;
    return (
        <FlexRow>
            <FormGroup>
                <FormRow>
                    <ArbiterMFTSelect path="emailType" length="md" options={emailOptions} />
                    <ArbiterMFTText path="emailAddress" length="md" />
                </FormRow>
                <Observer
                    subscriptions={{
                        emailType: `${path}.emailType`,
                    }}
                    render={({ emailType }) =>
                        isOtherEmailType(emailType) ? (
                            <ArbiterMFTText path="emailTypeOther" length="md" />
                        ) : null
                    }
                />
                <ArbiterMFTText path="provenance" />
                <FormRow display="flexWrap">
                    <Observer
                        subscriptions={{
                            id: ['id', Fields.MODEL],
                            dateNonPrefillConditions: [`${path}.dateEffectiveFrom`, [Fields.DIRTY]],
                        }}
                        render={({ id, dateNonPrefillConditions }) => {
                            return (
                                <DefaultDateEffectiveFromToCurrent
                                    entityId={id}
                                    form={props.form}
                                    NItemPath={path}
                                    dateNonPrefillConditions={dateNonPrefillConditions}
                                />
                            );
                        }}
                    />
                    <ArbiterMFTDatePicker
                        path="dateEffectiveTo"
                        variant={ArbiterMFTDatePicker.variants.LOCAL_DATE}
                    />
                </FormRow>
                <ArbiterMFTCheckbox path="isPrimary" length="md" />
                <ArbiterMFTCheckbox path="isSafeContact" />
                <FormRow hasIndent>
                    <ArbiterMFTText path="safeContactDetails" length="lg" />
                </FormRow>
            </FormGroup>
            {renderRemoveButton(props)}
        </FlexRow>
    );
}

export function renderInjuriesNItems() {
    return (
        <>
            <ArbiterMFTAttributeSelect
                path="injuryTypeAttrId"
                attributeType={AttributeTypeEnum.INJURY_CATEGORY.name}
            />
            <ArbiterMFTAttributeSelect
                path="injurySeverityAttrId"
                attributeType={AttributeTypeEnum.QC_INJURY_SEVERITY.name}
            />
            <ArbiterMFTAttributeSelect
                path="bodyPartAttrId"
                attributeType={AttributeTypeEnum.BODY_PART.name}
            />
            <ArbiterMFTText path="description" />
            <ArbiterMFTAttributeSelect
                path="weaponsUsedAttrId"
                attributeType={AttributeTypeEnum.WEAPON_OR_FORCE_INVOLVED.name}
            />
            <FormRow hasIndent>
                <ArbiterMFTBooleanButtonRadio path="wasWeaponSeized" length="lg" />
            </FormRow>
        </>
    );
}

export function renderAttendingPhysiciansNItems(props) {
    return (
        <>
            <ArbiterMFTText path="name" />
            {renderRemoveButtonWithLabelOnFirstItemOnly(props)}
        </>
    );
}

export function renderProbationsNItems() {
    return (
        <>
            <FormRow>
                <ArbiterMFTAttributeSelect
                    path="probationTypeAttrId"
                    attributeType={AttributeTypeEnum.PROBATION_TYPE.name}
                    length="md"
                />
                <ArbiterMFTText path="probationOfficer" length="md" />
            </FormRow>
            <ArbiterMFTText path="miscDescription" length="md" />
        </>
    );
}

export const renderCautionsNItems = ({ item, removeItem, hasDeleteCautionsAbility }) => {
    const canDeleteCaution = hasDeleteCautionsAbility || !item.id;
    return (
        <FormSection removeItem={canDeleteCaution ? removeItem : undefined}>
            <ArbiterMFTAttributeSelect
                path="cautionAttrId"
                attributeType={AttributeTypeEnum.PERSON_LABEL_ATTRIBUTES.name}
                testId={testIds.PERSON_PROFILE_CAUTION_ITEM_TYPE}
            />
            <IndentedFields>
                <ArbiterMFTText
                    path="cautionOther"
                    testId={testIds.PERSON_PROFILE_CAUTION_ITEM_TYPE_OTHER}
                />
            </IndentedFields>
            <Row>
                <ArbiterMFTDatePicker path="dateEffectiveFrom" />
                <ArbiterMFTDatePicker path="dateEffectiveTo" />
            </Row>
            <ArbiterMFTTextArea path="description" />
            <ArbiterMFTTextArea path="provenance" />
        </FormSection>
    );
};
