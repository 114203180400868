import { DynamicCardTypeEnum } from '@mark43/rms-api';

import _, { get } from 'lodash';
import { normalize } from '~/client-common/helpers/dataHelpers';
import reportCardEnum from '~/client-common/core/enums/universal/reportCardEnum';
import actionTypes from '../actions/types/customReportsAdminActionTypes';

import { setFormValues, clearFormValues } from '../helpers/formReducerHelpers';
import {
    customReportsAdminFormFields,
    customReportsAdminDownloadablesFormFields,
    customReportsAdminCardLinksFormFields,
} from '../configs/adminConfig';

import { isExternalDepartment } from '../helpers/permissionHelpers';
import { getInformationCardIdFromCardLinks } from '../helpers/customReportsAdminHelpers';

const initialUiState = {
    selectedReportDefinitionId: null,
    editingDownloadableIds: [],
    downloadables: {},
    reportCardId: null,
    saving: false,
    formError: null,
    showDownloadables: true,
    cardLinks: [],
};

let uniqDownloadablesCount = -1;

export function customReportsAdminUiReducer(state = initialUiState, action) {
    switch (action.type) {
        case actionTypes.PAGE_LOAD_SUCCESS:
            return {
                ...state,
                selectedReportDefinitionId: null,
            };
        case actionTypes.PAGE_LOAD_FAILURE:
            return {
                ...state,
                saving: false,
                selectedReportDefinitionId: null,
                formError: action.payload,
            };
        case actionTypes.SAVE_DOWNLOADABLE_START:
            return {
                ...state,
                saving: true,
                formError: null,
            };
        case actionTypes.SAVE_REPORT_DEFINITION_AND_DOWNLOADABLE_SUCCESS:
            return {
                ...state,
                saving: false,
                formError: null,
            };
        case actionTypes.SAVE_REPORT_DEFINITION_FAILURE:
        case actionTypes.SAVE_DOWNLOADABLES_FAILURE:
            return {
                ...state,
                saving: false,
                formError: action.payload.message,
            };
        case actionTypes.SELECT_REPORT_DEFINITION:
            const reportDefinition = action.payload;
            if (!reportDefinition) {
                return initialUiState;
            }
            const downloadableCards = _.filter(
                reportDefinition.cards,
                (card) => card.dynamicCardType === DynamicCardTypeEnum.DOWNLOADABLES.name
            );
            const cardLinks = reportDefinition.cardLinks;

            let reportCardId = null;
            let downloadableIds = [];
            let downloadables = [];
            if (downloadableCards.length > 0) {
                // TODO right now, only downloadables card will need a report card id
                reportCardId = downloadableCards[0].id;
                // TODO make this support multiple dynamic cards later
                if (downloadableCards[0].dynamicProperties.downloadables.length > 0) {
                    downloadables = downloadableCards[0].dynamicProperties.downloadables;
                    downloadableIds = _.map(
                        downloadableCards[0].dynamicProperties.downloadables,
                        (downloadable) => downloadable.id
                    );
                }
            }
            return {
                ...state,
                selectedReportDefinitionId: reportDefinition ? reportDefinition.id : null,
                editingDownloadableIds: downloadableIds,
                downloadables: normalize(downloadables),
                formError: null,
                cardLinks,
                reportCardId,
            };
        case actionTypes.SAVE_DOWNLOADABLES_SUCCESS:
            return {
                ...state,
                editingDownloadableIds: _(action.payload.downloadables).map('id').value(),
                downloadables: _(action.payload.downloadables).mapKeys('id').value(),
            };
        case actionTypes.ADD_DOWNLOADABLE_SUCCESS:
            let newId = --uniqDownloadablesCount;
            const newDownloadables = {};
            _.forEach(action.payload, (fileUploadResponse) => {
                newDownloadables[newId] = {
                    file: fileUploadResponse.file,
                    fileId: fileUploadResponse.file.id,
                };
                newId = --uniqDownloadablesCount;
            });
            return {
                ...state,
                saving: false,
                editingDownloadableIds: [
                    ...state.editingDownloadableIds,
                    ...Object.keys(newDownloadables),
                ],
                downloadables: {
                    ...state.downloadables,
                    ...newDownloadables,
                },
            };
        case actionTypes.ADD_DOWNLOADABLE_FAILURE:
            return {
                ...state,
                saving: false,
                formError: action.payload,
            };
        case actionTypes.REMOVE_DOWNLOADABLE:
            return {
                ...state,
                editingDownloadableIds: _.without(state.editingDownloadableIds, action.payload),
            };

        case actionTypes.SELECT_NEW_REPORT_DEFINITION:
            return {
                ...state,
                editingDownloadableIds: [],
                downloadables: {},
                showDownloadables: true,
            };

        case actionTypes.LOAD_ROLE_PERMISSIONS_FAILURE:
            return {
                ...state,
                saving: false,
                formError: action.payload,
            };
        case actionTypes.STORE_REPORT_DEFINITION_CARD_LINKS:
            return {
                ...state,
                cardLinks: action.payload,
            };
        case actionTypes.SAVE_REPORT_DEFINITION_CARD_LINKS_FAILURE:
            return {
                ...state,
                saving: false,
                formError: action.payload,
            };
        default:
            return state;
    }
}

const convertDefaultRoleLinksDataStateToFormState = (defaultRoleLinks) =>
    _.map(defaultRoleLinks, (defaultRoleLink) =>
        setFormValues({}, defaultRoleLink, ['operationType', 'roleId'])
    );

const convertRolePermissionsDataStateToFormState = (rolePermissions) => {
    return { value: _.map(rolePermissions, 'roleId') };
};

export function customReportsAdminFormReducer(state, action) {
    switch (action.type) {
        case actionTypes.SELECT_NEW_REPORT_DEFINITION:
            const clearedFormValues = clearFormValues(state);
            // Merge in the default values to the cleared form
            const formValuesWithDefaults = setFormValues(clearedFormValues, action.payload);
            return formValuesWithDefaults;

        case actionTypes.SELECT_REPORT_DEFINITION:
            const reportDefinition = action.payload;
            const cardLinks = get(reportDefinition, 'cardLinks');
            const informationCardId = getInformationCardIdFromCardLinks(cardLinks);
            const downloadablesCardName = reportDefinition.name;

            if (reportDefinition) {
                // they actually chose one
                return {
                    ...state,
                    ...setFormValues(
                        state,
                        {
                            ...action.payload,
                            informationCardId,
                            downloadablesCardName,
                        },
                        _.without(customReportsAdminFormFields, 'reportCreationRolePermissions')
                    ),
                };
            }
            // otherwise, new template (todo we don't support this yet)
            return clearFormValues(state);
        case actionTypes.LOAD_DEFAULT_ROLE_LINKS:
            return {
                ...state,
                defaultExternalDepartmentRoleLinks: convertDefaultRoleLinksDataStateToFormState(
                    _.filter(action.payload, isExternalDepartment)
                ),
                defaultRoleLinks: convertDefaultRoleLinksDataStateToFormState(
                    _.reject(action.payload, isExternalDepartment)
                ),
            };
        case actionTypes.LOAD_ROLE_PERMISSIONS_SUCCESS:
        case actionTypes.SAVE_ROLE_PERMISSIONS_SUCCESS:
            return {
                ...state,
                reportCreationRolePermissions: convertRolePermissionsDataStateToFormState(
                    action.payload.rolePermissions
                ),
            };
        case actionTypes.STORE_REPORT_DEFINITION_CARD_LINKS:
            return {
                ...state,
                ...setFormValues(
                    state,
                    {
                        informationCardId: getInformationCardIdFromCardLinks(action.payload),
                    },
                    ['informationCardId']
                ),
            };
        default:
            return state;
    }
}

export function customReportsAdminDownloadablesFormReducer(state, action) {
    switch (action.type) {
        case actionTypes.SAVE_DOWNLOADABLES_SUCCESS:
            const savedDownloadables = action.payload.downloadables;
            if (savedDownloadables.length > 0) {
                return _(savedDownloadables)
                    .mapKeys('id')
                    .mapValues((downloadable) =>
                        setFormValues(
                            state[downloadable.id],
                            downloadable,
                            customReportsAdminDownloadablesFormFields
                        )
                    )
                    .value();
            }
            return {};
        case actionTypes.SELECT_REPORT_DEFINITION:
            // the state is form data and can be cleared by returning an empty object
            const reportDefinition = action.payload;
            if (!reportDefinition) {
                return clearFormValues(state);
            }
            // TODO later, will need to support multiple dynamic card types
            const downloadableCards = _.filter(
                reportDefinition.cards,
                (card) => card.dynamicCardType === 'DOWNLOADABLES'
            );
            if (downloadableCards.length > 0) {
                return _(downloadableCards[0].dynamicProperties.downloadables)
                    .mapKeys('id')
                    .mapValues((downloadable) =>
                        setFormValues(
                            state[downloadable.id],
                            downloadable,
                            customReportsAdminDownloadablesFormFields
                        )
                    )
                    .value();
            }
            return {};
        default:
            return state;
    }
}

export function customReportsAdminCardLinksFormReducer(state, action) {
    switch (action.type) {
        case actionTypes.SELECT_REPORT_DEFINITION:
            const reportDefinition = action.payload;
            if (!reportDefinition) {
                return clearFormValues(state);
            }
            const cardLinks = _.get(reportDefinition, 'cardLinks');
            return setCardLinkFormState(state, cardLinks);
        case actionTypes.STORE_REPORT_DEFINITION_CARD_LINKS:
            return setCardLinkFormState(state, action.payload);
        default:
            return state;
    }
}

function setCardLinkFormState(state, cardLinks) {
    const filteredCardLinks = _(cardLinks)
        .filter((cardLink) => cardLink.cardId !== reportCardEnum.APPROVALS.id)
        .value();
    if (filteredCardLinks.length > 0) {
        return _(filteredCardLinks)
            .mapKeys('cardId')
            .mapValues((optionalLink) =>
                setFormValues(
                    state[optionalLink.cardId],
                    optionalLink,
                    customReportsAdminCardLinksFormFields
                )
            )
            .value();
    }
    return {};
}
