import { EntityTypeEnum, NibrsOffenseCodeEnum } from '@mark43/rms-api';
import { filter, identity, isEmpty, pickBy, sortBy, get, isFunction } from 'lodash';
import React, { createRef } from 'react';
import styled from 'styled-components';

import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import getOrganizationProfileResource from '~/client-common/core/domain/organization-profiles/resources/organizationProfileResource';
import {
    DISPLAY_ONLY_ORGANIZATION_LABEL,
    DISPLAY_ONLY_PERSON_LABEL,
} from '~/client-common/core/enums/universal/fields';

import RecordCardNameSummaries from '../../records/core/components/summaries/RecordCardNameSummaries';
import { PersonSuggestionPopover } from '../../reports/core/components/entity-prefill/PersonSuggestionPopover';
import testIds from '../../../core/testIds';
import { PersonSidePanel } from '../persons/components/PersonSidePanel';
import { OrganizationSidePanel } from '../organizations/components/OrganizationSidePanel';
import _PersonQuickAdd from '../persons/components/PersonQuickAdd';
import _OrganizationQuickAdd from '../organizations/components/OrganizationQuickAdd';
import { shouldUseStubLink } from '../names/util/nameSaveFormHelpers';
import { AddNameButton } from './AddNameButton';

const strings = componentStrings.reports.core.NameSummaryViewWrapper;

const PersonQuickAdd = styled(_PersonQuickAdd)`
    margin-top: 7px;
    margin-bottom: 17px;

    &:empty {
        margin: 0;
    }
`;

const OrganizationQuickAdd = styled(_OrganizationQuickAdd)`
    margin-top: 7px;
    margin-bottom: 17px;

    &:empty {
        margin: 0;
    }
`;


const onlyOneEntity = (show) =>
    !show ||
    (show.people && !show.organizations && !show.societies && !show.noInfoKnown) ||
    (!show.people && show.organizations && !show.societies && !show.noInfoKnown);

class NameSummaryView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showConnectButtons: onlyOneEntity(this.props.show),
        };
        this.deleteButtons = [];
        this.nextEditButton = createRef();
        this.addPersonButtonRef = createRef();
        this.addOrganizationButtonRef = createRef();
    }

    getNameLinks = () => {
        const {
            nameId,
            linkType,
            contextType,
            contextId,
            parentEntityType,
            parentId,
            entityType,
            nameReportLinksWhere,
        } = this.props;
        if (this.props.stubbedNameItemLinks) {
            return { nameLinks: this.props.stubbedNameItemLinks };
        } else if (shouldUseStubLink({ contextId, contextType })) {
            // defendant in arrest and warrant subjects do not have nameReportLinks,
            // so we don't pass a `contextId`/`contextType` for them, but
            // we do pass `nameId` so that we properly mock the data
            return {
                nameLinks: nameId
                    ? [
                          {
                              linkType,
                              nameId,
                              linkTypeSequenceNumber: 1,
                              entityType: EntityTypeEnum.PERSON_PROFILE.name,
                          },
                      ]
                    : [],
            };
        } else if (parentEntityType === EntityTypeEnum.REPORT.name) {
            const filter = pickBy(
                {
                    reportId: parentId,
                    linkType,
                    contextType,
                    contextId,
                    entityType,
                },
                identity
            );
            return {
                nameLinks: sortBy(nameReportLinksWhere(filter), 'linkTypeSequenceNumber'),
            };
        } else {
            return {};
        }
    };

    showConnectButtons = () => {
        this.setState({ showConnectButtons: true }, this.focusFirstConnectButton);
    };

    hideConnectButtons = () => {
        this.setState({ showConnectButtons: false }, this.focusAddButton);
    };

    onAddSuccess = (nameReportLink) => {
        this.props.addNameReportLink(nameReportLink);
        this.props.setIsLocationStale(true);
        this.hideConnectButtons();
    };

    getNameSidePanelProps = (linkType) => ({
        linkType,
        onAddSuccess: this.onAddSuccess,
        contextType: this.props.contextType,
        contextId: this.props.contextId,
        renForRecents: this.props.renForRecents,
        ownerId: this.props.parentId,
        ownerType: this.props.parentEntityType,
        hideRecentEntities: !this.props.hideQuickAdd,
        saveRef: this.nextEditButton,
        nibrsOffenseCode: this.props.nibrsOffenseCode,
    });

    addSocietyProfile = (societyProfileId, onSuccess) => {
        getOrganizationProfileResource()
            .addOrganizationLink(
                societyProfileId,
                EntityTypeEnum.REPORT.name,
                this.props.parentId
            )
            .then((organizationProfile) => {
                this.props.storeOrganizationProfiles(organizationProfile);

                this.props
                    .addSocietyProfileLink({
                        nameId: organizationProfile.id,
                        contextType: this.props.contextType,
                        entityType: EntityTypeEnum.ORGANIZATION_PROFILE.name,
                        contextId: this.props.contextId,
                        linkType: this.props.linkType,
                        reportId: this.props.parentId,
                    })
                    .then((nameReportLink) => {
                        if (isFunction(onSuccess)) {
                            onSuccess();
                        }
                        return this.props.addNameReportLink(nameReportLink);
                    })
                    .catch(() =>
                        this.createHandleLinkError({ type: 'add' })({
                            entityType: EntityTypeEnum.ORGANIZATION_PROFILE.name,
                        })
                    );
            })
            .catch((err) => {
                if (this.props.onConnectError) {
                    this.props.onConnectError(err);
                }
            });
        this.setState({ showConnectButtons: false }, this.focusAddButton);
    };

    handleSocietyProfileClick = (e, societyProfileId) =>
        this.addSocietyProfile(societyProfileId);

    removeLink = (nameReportLink, index) => {
        // this is for warrant subjects and arrest defendants
        // they are not NRLs, so attempting to remove one would break this flow
        if (this.props.onDelete) {
            const runAfterDelete = () => {
                this.props.setIsLocationStale(true);
                this.focusAddButton(nameReportLink.entityType);
            };
            const onDeleteResult = this.props.onDelete(nameReportLink, index);
            if (typeof onDeleteResult?.then === 'function') {
                return onDeleteResult.then(runAfterDelete);
            } else {
                runAfterDelete();
                return;
            }
        } else {
            const { nameLinks } = this.getNameLinks();
            const removeParams = {
                nameReportLink,
                index,
                onSuccess: this.handleRemoveLinkSuccess(nameLinks),
                onError: this.createHandleLinkError(),
            };
            return this.removeNameReportLink(removeParams);
        }
    };

    handleRemoveLinkSuccess = (nameLinks) => (nameReportLink, index) => {
        // if success, also remove from the form state
        if (this.props.form && this.props.formPath) {
            this.props.removeNameReportLinkFromForm(this.props.formPath, index);
        } else if (this.props.onRemoveSuccess) {
            this.props.onRemoveSuccess(nameReportLink, index);
        }
        // If we are removing the last item in the list
        // then focus back on the add button
        if (nameLinks.length - 1 === index) {
            this.focusAddButton(nameReportLink.entityType);
        } else if (this.deleteButtons[index]) {
            if (this.deleteButtons[index].button) {
                // Remove this when ARC_RELEASE_CYCLE_ONE_COMPONENTS is globally enabled
                this.deleteButtons[index].button.focus();
            } else {
                this.deleteButtons[index].focus();
            }
        }
        if (
            nameReportLink.entityType === EntityTypeEnum.PERSON_PROFILE.name &&
            isFunction(this.props.personQuickAddCallback)
        ) {
            this.props.personQuickAddCallback();
        } else if (
            nameReportLink.entityType === EntityTypeEnum.ORGANIZATION_PROFILE.name &&
            isFunction(this.props.orgQuickAddCallback)
        ) {
            this.props.orgQuickAddCallback();
        }

        this.props.setIsLocationStale(true);
    };

    createHandleLinkError = ({ type } = {}) => (nameReportLink) => {
        return this.props.openErrorModal({
            paragraphs: [
                `Failed to successfully ${type === 'add' ? 'add' : 'remove'} ${
                    nameReportLink.entityType === EntityTypeEnum.PERSON_PROFILE.name
                        ? 'person'
                        : 'organization'
                }, please try again`,
            ],
        });
    };

    setFirstConnectButton = (cond) => (ref) => {
        if (cond) {
            this.firstConnectButton = ref;
        }
    };

    setDeleteButton = (index) => (ref) => (this.deleteButtons[index] = ref);

    focusAddButton = (entityType) => {
        // Need to check if `button` is defined for the
        // edge case where only one `nameReportLink` is allowed
        if (this.addButton) {
            if (this.addButton.button) {
                // Remove this when ARC_RELEASE_CYCLE_ONE_COMPONENTS is globally enabled
                this.addButton.button.focus();
            } else {
                this.addButton.focus();
            }
        } else if (
            entityType === EntityTypeEnum.PERSON_PROFILE.name &&
            this.addPersonButtonRef.current
        ) {
            if (this.addPersonButtonRef.current.button) {
                // Remove this when ARC_RELEASE_CYCLE_ONE_COMPONENTS is globally enabled
                this.addPersonButtonRef.current.button.focus();
            } else {
                this.addPersonButtonRef.current.focus();
            }
        } else if (
            entityType === EntityTypeEnum.ORGANIZATION_PROFILE.name &&
            this.addOrganizationButtonRef.current
        ) {
            if (this.addOrganizationButtonRef.current.button) {
                // Remove this when ARC_RELEASE_CYCLE_ONE_COMPONENTS is globally enabled
                this.addOrganizationButtonRef.current.button.focus();
            } else {
                this.addOrganizationButtonRef.current.focus();
            }
        }
    };

    focusFirstConnectButton = () => {
        if (this.firstConnectButton) {
            if (this.firstConnectButton.button) {
                // Remove this when ARC_RELEASE_CYCLE_ONE_COMPONENTS is globally enabled
                this.firstConnectButton.button.focus();
            } else {
                this.firstConnectButton.focus();
            }
        }
    };

    showAddButton = () => {
        const { nameLinks } = this.getNameLinks();
        return !this.props.limitToOne || isEmpty(nameLinks);
    };

    getPersonOverlayId = () => {
        const { personOverlayIdPrefix, linkType } = this.props;
        return personOverlayIdPrefix
            ? `${personOverlayIdPrefix}.${linkType}`
            : `${overlayIdEnum.PERSON_OVERLAY_NAME_SUMMARY_VIEW_WRAPPER}.${linkType}`;
    };

    getOrganizationOverlayId = () => {
        const { organizationOverlayIdPrefix, linkType } = this.props;
        return organizationOverlayIdPrefix
            ? `${organizationOverlayIdPrefix}.${linkType}`
            : `${overlayIdEnum.ORGANIZATION_OVERLAY_NAME_SUMMARY_VIEW_WRAPPER}.${linkType}`;
    };

    showAddPersonButton = () => {
        return this.showAddButton() && this.props.show.people;
    };

    renderPersonSidePanelButton = (
        { overlayBase: { open }, setCancelFocusRef },
        showQuickAdd
    ) =>
        this.showAddPersonButton() && (
            <>
                <FeatureFlagged
                    flag="RMS_CAD_DATA_ENTITY_PREFILL_ENABLED"
                    fallback={
                        <AddNameButton
                            testId={testIds.NAME_SUMMARY_VIEW_ADD_PERSON}
                            onClick={() => {
                                open();
                                setCancelFocusRef(this.addPersonButtonRef);
                            }}
                            ref={(ref) => {
                                this.setFirstConnectButton(true)(ref);
                                this.addPersonButtonRef.current = ref;
                            }}
                            onlyOption={onlyOneEntity(this.props.show)}
                            showQuickAdd={showQuickAdd}
                        >
                            {this.props.show && this.props.show.organizations
                                ? this.props.formatFieldByName(DISPLAY_ONLY_PERSON_LABEL)
                                : strings.addButtonText(this.props.addNameButtonText)}
                        </AddNameButton>
                    }
                >
                    <PersonSuggestionPopover
                        buttonLabel={strings.addButtonText(this.props.addNameButtonText)}
                        personOverlayId={this.getPersonOverlayId()}
                        contextType={this.props.contextType}
                        contextId={this.props.contextId}
                    />
                </FeatureFlagged>
                {showQuickAdd && (
                    <PersonQuickAdd
                        renForRecents={this.props.renForRecents}
                        contextType={this.props.contextType}
                        contextId={this.props.contextId}
                        ownerType={this.props.parentEntityType}
                        ownerId={this.props.parentId}
                        linkType={this.props.linkType}
                        showNoInfoKnown={get(this.props, 'show.noInfoKnown')}
                        onAddSuccess={this.props.addNameReportLink}
                        personOverlayId={this.getPersonOverlayId()}
                        nibrsOffenseCode={this.props.nibrsOffenseCode}
                        setCancelFocusRef={setCancelFocusRef}
                        stubbedLinks={filter(this.props.stubbedNameItemLinks, {
                            entityType: EntityTypeEnum.PERSON_PROFILE.name,
                        })}
                        quickAddLayout={this.props.quickAddLayout}
                    />
                )}
            </>
        );

    renderPersonSidePanelButtonWithoutQuickAdd = (overlayProps) =>
        this.renderPersonSidePanelButton(overlayProps, false);
    renderPersonSidePanelButtonWithQuickAdd = (overlayProps) =>
        this.renderPersonSidePanelButton(overlayProps, !this.props.hideQuickAdd);

    renderOrganizationSidePanelButton = (
        { overlayBase: { open }, setCancelFocusRef },
        showQuickAdd
    ) =>
        this.showAddButton() && (
            <>
                {(!this.props.nibrsCrimeAgainstSociety ||
                    this.props.nibrsOffenseCode.code === NibrsOffenseCodeEnum.TR.code) && (
                    <AddNameButton
                        testId={testIds.NAME_SUMMARY_VIEW_ADD_ORGANIZATION}
                        onClick={() => {
                            open();
                            setCancelFocusRef(this.addOrganizationButtonRef);
                        }}
                        ref={(ref) => {
                            this.setFirstConnectButton(
                                !this.props.show.people && !this.props.show.noInfoKnown
                            )(ref);
                            this.addOrganizationButtonRef.current = ref;
                        }}
                        onlyOption={onlyOneEntity(this.props.show)}
                        showQuickAdd={showQuickAdd}
                    >
                        {this.props.show.people
                            ? strings.nameSocietyOptions.organization(
                                  this.props.formatFieldByName(DISPLAY_ONLY_ORGANIZATION_LABEL)
                              )
                            : this.props.addNameButtonText}
                    </AddNameButton>
                )}

                {showQuickAdd && (
                    <OrganizationQuickAdd
                        renForRecents={this.props.renForRecents}
                        contextType={this.props.contextType}
                        contextId={this.props.contextId}
                        ownerType={this.props.parentEntityType}
                        ownerId={this.props.parentId}
                        linkType={this.props.linkType}
                        onAddSuccess={this.props.addNameReportLink}
                        showSocieties={get(this.props.show, 'societies')}
                        setCancelFocusRef={setCancelFocusRef}
                        organizationOverlayId={this.getOrganizationOverlayId()}
                        onSocietyProfileClick={this.addSocietyProfile}
                        stubbedLinks={filter(this.props.stubbedNameItemLinks, {
                            entityType: EntityTypeEnum.ORGANIZATION_PROFILE.name,
                        })}
                        quickAddLayout={this.props.quickAddLayout}
                        societyOffenseRequiresSocietyVictim={
                            this.props.societyOffenseRequiresSocietyVictim
                        }
                        nibrsCrimeAgainstSociety={this.props.nibrsCrimeAgainstSociety}
                    />
                )}
            </>
        );
    renderOrganizationSidePanelButtonWithoutQuickAdd = (overlayProps) =>
        this.renderOrganizationSidePanelButton(overlayProps, false);
    renderOrganizationSidePanelButtonWithQuickAdd = (overlayProps) =>
        this.renderOrganizationSidePanelButton(overlayProps, !this.props.hideQuickAdd);

    removeNameReportLink = ({ nameReportLink, index, onSuccess, onError }) => {
        this.props
            .deleteNameReportLink(nameReportLink)
            .then(() => {
                if (onSuccess) {
                    return onSuccess(nameReportLink, index);
                }
                return nameReportLink;
            })
            .catch(() => {
                if (onError) {
                    return onError(nameReportLink);
                }
            });
    };

    render() {
        const {
            summaryMode,
            summaryModeItem,
            parentEntityType,
            parentId,
            contextType,
            contextId,
            renderAdditionalItem,
            linkType,
            show = { people: true }, // if no `show` prop is passed in, we show only "+ person" by default
            renForRecents,
            className,
        } = this.props;

        const { nameLinks } = this.getNameLinks();

        const personOverlayId = this.getPersonOverlayId();

        const organizationOverlayId = this.getOrganizationOverlayId();

        const nameSidePanelProps = this.getNameSidePanelProps(linkType);
        const { people: showPeople, organizations: showOrgs } = show;

        return (
            <div className={className}>
                <RecordCardNameSummaries
                    personOverlayIdPrefix={personOverlayId}
                    organizationOverlayIdPrefix={organizationOverlayId}
                    summaryModeItem={summaryModeItem}
                    summaryMode={summaryMode}
                    nameLinks={nameLinks}
                    parentEntityType={parentEntityType}
                    parentId={parentId}
                    contextType={contextType}
                    contextId={contextId}
                    renForRecents={renForRecents}
                    renderAdditionalItem={renderAdditionalItem}
                    onDelete={this.removeLink}
                    setDeleteButton={this.setDeleteButton}
                    nextEditButtonRef={this.nextEditButton}
                    nibrsOffenseCode={this.props.nibrsOffenseCode}
                    showExpandCollapseButton={this.props.showExpandCollapseButton}
                    showMugshotThumbnail={this.props.showMugshotThumbnail}
                />
                {!summaryMode && (
                    <>
                        {showPeople && (
                            <>
                                <PersonSidePanel
                                    key={personOverlayId}
                                    overlayId={personOverlayId}
                                    renderButton={this.renderPersonSidePanelButtonWithQuickAdd}
                                    {...nameSidePanelProps}
                                />
                            </>
                        )}
                        {showOrgs && (
                            <>
                                <OrganizationSidePanel
                                    key={organizationOverlayId}
                                    overlayId={organizationOverlayId}
                                    renderButton={
                                        this.renderOrganizationSidePanelButtonWithQuickAdd
                                    }
                                    {...nameSidePanelProps}
                                />
                            </>
                        )}
                    </>
                )}
            </div>
        );
    }
}

export { NameSummaryView };
