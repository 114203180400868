import _, {
    difference,
    pick,
    find,
    first,
    get,
    mapKeys,
    invert,
    startsWith,
    chain,
    upperFirst,
} from 'lodash';

import dateTypeEnum from '~/client-common/core/enums/client/dateTypeEnum';
import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import fieldTypeClientEnum from '~/client-common/core/enums/client/fieldTypeClientEnum';
import { dateTypeOptions } from '~/client-common/core/dates/utils/dateHelpers';
import {
    includeLocationSubdivisions,
    getSubdivisionsFromLocation,
    getElasticQuerySubdivisionAttrIds,
} from '~/client-common/core/domain/locations/utils/subdivisionHelpers';

import { createFormModule } from '../../../../core/forms';
import { filterFormData, buildFormModel } from '../../../../../legacy-redux/helpers/formHelpers';
import targetProfileSearchFieldset from '../../../target-profile/state/forms/targetProfileSearchFieldset';
import allCasesSearchPersonnelFieldsetViewModel from './allCasesSearchPersonnelFieldset';
import allCasesSearchCaseInformationFieldsetViewModel from './allCasesSearchCaseInformationFieldset';

const { DEPARTMENT } = fieldTypeClientEnum;

export const allCasesSearchFormFieldViewModels = {
    personnel: allCasesSearchPersonnelFieldsetViewModel,
    caseInformation: allCasesSearchCaseInformationFieldsetViewModel,
    targetProfile: targetProfileSearchFieldset,
    departmentIds: {
        key: 'departmentIds',
        type: DEPARTMENT,
    },
};

const elasticQueryDateFields = [
    'assignedStartDateUtc',
    'assignedEndDateUtc',
    'assignedToDatePeriod',
    'assignedWithinLastPeriod',
    'createdStartDateUtc',
    'createdEndDateUtc',
    'createdToDatePeriod',
    'createdWithinLastPeriod',
    'dueStartDateUtc',
    'dueEndDateUtc',
    'dueToDatePeriod',
    'dueWithinLastPeriod',
    'updatedStartDateUtc',
    'updatedEndDateUtc',
    'updatedToDatePeriod',
    'updatedWithinLastPeriod',
];

const dueDateRelatedDateFields = [
    'dueStartDateUtc',
    'dueEndDateUtc',
    'dueToDatePeriod',
    'dueWithinLastPeriod',
];

const dateTypePrefixes = ['assigned', 'updated', 'created', 'due'];

const dateTypeToFieldPrefix = {
    [dateTypeEnum.ASSIGNED]: 'assigned',
    [dateTypeEnum.MODIFIED]: 'updated',
    [dateTypeEnum.CREATED]: 'created',
    [dateTypeEnum.DUE]: 'due',
};

const dateFieldPrefixToDateType = invert(dateTypeToFieldPrefix);

const dateRangeFields = ['toDatePeriod', 'withinLastPeriod', 'startDateUtc', 'endDateUtc'];

/**
 * Use values provided in the `dateType` field and by the DateRangePicker
 *   to determine which fields to include in our ElasticCaseQuery
 * @method dateFormFieldsToElasticQueryFields
 * @param  {Object} formModel
 * @return {Object}
 */
function dateFormFieldsToElasticQueryFields({ dateType, ...otherFields } = {}) {
    const relevantFields = pick(otherFields, ...dateRangeFields);

    return mapKeys(
        relevantFields,
        (val, key) => `${dateTypeToFieldPrefix[dateType]}${upperFirst(key)}`
    );
}

/**
 * Convert the given search query model to form state. While the query is flat,
 *   the form state has a nested structure.
 * @param  {Object} [elasticQuery] Search query model.
 * @return {Object} Form state.
 */
export function convertAllCasesSearchElasticQueryToFormModel(elasticQuery = {}) {
    const dateFields =
        elasticQuery.isOverdue === null
            ? elasticQueryDateFields
            : difference(elasticQueryDateFields, dueDateRelatedDateFields);

    const firstDateField = _(elasticQuery).pick(dateFields).keys().head();

    const dateType = find(dateFieldPrefixToDateType, (dateType, dateFieldPrefix) =>
        startsWith(firstDateField, dateFieldPrefix)
    );

    const dateFieldsOnly = pick(elasticQuery, dateFields);

    const mappedDateFields = mapKeys(dateFieldsOnly, (val, key) => {
        return chain(dateTypePrefixes)
            .reduce((acc, dateTypePrefix) => acc.replace(dateTypePrefix, ''), key)
            .camelCase()
            .value();
    });

    const hasRoutingLabel = get(elasticQuery, 'hasRoutingLabel');

    const subdivisions = getElasticQuerySubdivisionAttrIds(elasticQuery, 'involvedLocations');

    const targetProfileReviewDateRangeQuery = elasticQuery.targetProfileReviewDateRangeQuery || {};
    const targetProfileDateFieldsOnly = pick(targetProfileReviewDateRangeQuery, ...dateRangeFields);

    return buildFormModel(
        {
            personnel: {
                assigneeRoleIds: elasticQuery.assigneeRoleIds,
                hasAssignee: [elasticQuery.hasAssignee],
                includeAssistingInvestigators: elasticQuery.includeAssistingInvestigators,
                supervisorRoleIds: elasticQuery.supervisorRoleIds,
                hasSupervisor: [elasticQuery.hasSupervisor],
                assignedPersonnelUnitAttrIds: elasticQuery.assignedPersonnelUnitAttrIds,
            },
            caseInformation: {
                currentStatusAttrIds: elasticQuery.currentStatusAttrIds,
                caseDefinitionId: first(elasticQuery.caseDefinitionIds),
                caseTitle: elasticQuery.title,
                caseNumber: first(elasticQuery.caseNumbers),
                reportingEventNumber: first(elasticQuery.reportingEventNumbers),
                reportIdentifier: first(elasticQuery.reportIdentifiers),
                approvalStatuses: elasticQuery.approvalStatuses,
                ...subdivisions,
                isOverdue: elasticQuery.isOverdue,
                dateType: dateType || first(dateTypeOptions).value,
                offenseCodeIds: elasticQuery.offenseCodeIds,
                routingLabelAttrIds: elasticQuery.routingLabelAttrIds,
                hasRoutingLabel: [hasRoutingLabel],
                caseStatusAttrIds: elasticQuery.caseStatusAttrIds,
                ...mappedDateFields,
            },
            targetProfile: {
                isTargetProfile: elasticQuery.isTargetProfile,
                targetProfileCategoryAttrIds: elasticQuery.targetProfileCategoryAttrIds,
                targetProfilePriorityAttrIds: elasticQuery.targetProfilePriorityAttrIds,
                ...targetProfileDateFieldsOnly,
            },
            departmentIds: elasticQuery.departmentIds,
        },
        allCasesSearchFormFieldViewModels
    );
}

/**
 * Flatten the given form model state into a search query model which can be
 *   sent to the server for searching.
 * @param  {Object} [formModel]
 * @return {Object} Search query model.
 */
export function convertAllCasesSearchFormModelToElasticQuery(formModel = {}) {
    const personnel = formModel.personnel || {};
    const caseInformation = formModel.caseInformation || {};
    const targetProfile = formModel.targetProfile || {};
    const targetProfileDateRangeFields = pick(targetProfile, ...dateRangeFields);
    const dateFields = dateFormFieldsToElasticQueryFields(caseInformation);
    const hasRoutingLabel = first(get(formModel.caseInformation, 'hasRoutingLabel'));

    return filterFormData(
        {
            ...dateFields,
            currentStatusAttrIds: caseInformation.currentStatusAttrIds,
            assigneeRoleIds: personnel.assigneeRoleIds,
            /* used to configure logic for hasAssignee field in ES
             *  if 'No Assignee' from dropdown is selected, personnel.hasAssignee is ["false"], otherwise it is null
             */
            hasAssignee: first(personnel.hasAssignee),
            includeAssistingInvestigators: personnel.includeAssistingInvestigators,
            supervisorRoleIds: personnel.supervisorRoleIds,
            hasSupervisor: first(personnel.hasSupervisor),
            assignedPersonnelUnitAttrIds: personnel.assignedPersonnelUnitAttrIds,
            caseDefinitionIds: [caseInformation.caseDefinitionId],
            approvalStatuses: caseInformation.approvalStatuses,
            title: caseInformation.caseTitle,
            caseNumbers: [caseInformation.caseNumber],
            reportingEventNumbers: [caseInformation.reportingEventNumber],
            reportIdentifiers: [caseInformation.reportIdentifier],
            involvedLocations: [
                ...(includeLocationSubdivisions(formModel.caseInformation)
                    ? [getSubdivisionsFromLocation(formModel.caseInformation)]
                    : []),
            ],
            isOverdue: caseInformation.isOverdue,
            departmentIds: formModel.departmentIds,
            offenseCodeIds: caseInformation.offenseCodeIds || [],
            routingLabelAttrIds: caseInformation.routingLabelAttrIds,
            caseStatusAttrIds: caseInformation.caseStatusAttrIds,
            hasRoutingLabel,
            isTargetProfile: targetProfile.isTargetProfile,
            targetProfileReviewDateRangeQuery: targetProfileDateRangeFields,
            targetProfileCategoryAttrIds: targetProfile.targetProfileCategoryAttrIds,
            targetProfilePriorityAttrIds: targetProfile.targetProfilePriorityAttrIds,
            relatedPersons: formModel.relatedPersons,
            relatedOrganizations: formModel.relatedOrganizations,
            relatedVehicles: formModel.relatedVehicles,
        },
        allCasesSearchFormFieldViewModels
    );
}

const allCasesSearchForm = createFormModule({
    formName: formClientEnum.ALL_CASES_SEARCH,
    fieldViewModels: allCasesSearchFormFieldViewModels,
    convertToFormModel: convertAllCasesSearchElasticQueryToFormModel,
    convertFromFormModel: convertAllCasesSearchFormModelToElasticQuery,
});

/**
 * Module of the all cases search/filter form.
 * @type {Object}
 */
export default allCasesSearchForm;
