import { AttributeTypeEnum } from '@mark43/rms-api';
import { includes, filter } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import {
    combinedSubdivisionsLabelSelector,
    formatFieldByNameSelector,
} from '~/client-common/core/fields/state/config';
import {
    REPORT_REPORTING_EVENT_NUMBER,
    DISPLAY_ONLY_CASE_SEARCH_REPORT_APPROVAL_STATUS_LABEL,
    DISPLAY_ONLY_EVENT_DATE_TIME_RANGE_LABEL,
    DISPLAY_ONLY_CASE_SEARCH_REPORT_CREATED_DATE_TIME_RANGE_LABEL,
    DISPLAY_ONLY_CASE_SEARCH_LABELS_LABEL,
    OFFENSE_CASE_STATUS_CASE_STATUS_ATTR_ID,
} from '~/client-common/core/enums/universal/fields';
import { OFFENSE_TYPE_OFFENSE_CODE_FLAGS } from '~/client-common/core/domain/offense-codes/constants';

import Row from '../../../core/components/Row';
import { RRFApprovalStatusSelect } from '../../../core/forms/components/selects/ClientApprovalStatusSelect';
import { RRFFieldset } from '../../../core/forms/components/Fieldset';
import { RRFAttributeSelect } from '../../../core/forms/components/selects/AttributeSelect';
import { RRFText } from '../../../core/forms/components/Text';
import { RRFDateRangePicker } from '../../../core/forms/components/DateRangePicker';
import { RRFLabelsWithNoneAttrSelect } from '../../../core/forms/components/selects/LabelsWithNoneAttrSelect';
import {
    activeTabKeySelector,
    tabKeys,
    isCaseStatusAttributeParentIdValidForWillNotInvestigate,
} from '../state/ui';

import fieldsetViewModel from '../state/forms/unassignedReportsSearchReportDetailsFieldset';
import { RRFBooleanSelect } from '../../../core/forms/components/selects/BooleanSelect';
import { RRFOffenseCodeSelect } from '../../../core/forms/components/selects/OffenseCodeSelect';
import { SubdivisionsSearch } from '../../../search/core/components/SubdivisionsSearch';
import testIds from '../../../../core/testIds';
import { RRFOffenseCaseStatusAttrSelect } from '../../../core/forms/components/selects/OffenseCaseStatusSelect';

// configs
const mapStateToProps = createStructuredSelector({
    combinedSubdivisionsLabel: combinedSubdivisionsLabelSelector,
    formatFieldByName: formatFieldByNameSelector,
    activeTabKey: activeTabKeySelector,
});

/**
 * Fieldset for "report ids" in the Unassigned Reports search form.
 */
const UnassignedReportsSearchReportDetailsFieldset = function ({
    combinedSubdivisionsLabel,
    formatFieldByName,
    activeTabKey,
}) {
    const caseStatusFilter = (caseStatusOptions) => {
        return filter(caseStatusOptions, ({ parentId }) => {
            // If on the `NEW` tab, only show caseStatuses
            // corresponding to `open`, `closed` or `unfounded`
            if (activeTabKey === tabKeys.NEW) {
                return includes(
                    [
                        globalAttributes.caseStatus.open,
                        globalAttributes.caseStatus.closed,
                        globalAttributes.caseStatus.unfounded,
                    ],
                    parentId
                );
            }
            // Otherwise, only show `caseStatuses`
            // corresponding to `inactive`
            else {
                return isCaseStatusAttributeParentIdValidForWillNotInvestigate(parentId);
            }
        });
    };

    return (
        <RRFFieldset path="reportDetails" title={fieldsetViewModel.title} highlightOnFocus={false}>
            <Row>
                <RRFText
                    width={300}
                    label={formatFieldByName(REPORT_REPORTING_EVENT_NUMBER)}
                    path="reportingEventNumber"
                />
                <FeatureFlagged
                    flag="RMS_SUBDIVISION_SEARCH_ENHANCEMENTS_ENABLED"
                    fallback={
                        <RRFAttributeSelect
                            attributeType={[
                                'SUBDIVISION_DEPTH_1',
                                'SUBDIVISION_DEPTH_2',
                                'SUBDIVISION_DEPTH_3',
                                'SUBDIVISION_DEPTH_4',
                                'SUBDIVISION_DEPTH_5',
                            ]}
                            multiple={true}
                            includeExpired={true}
                            width={300}
                            label={combinedSubdivisionsLabel}
                            path="subdivisionAttrIds"
                        />
                    }
                />
            </Row>
            <Row>
                <div data-test-id={testIds.CASE_DASHBOARD_REPORT_APPROVAL_STATUS_FILTER}>
                    <RRFApprovalStatusSelect
                        path="clientApprovalStatuses"
                        label={formatFieldByName(
                            DISPLAY_ONLY_CASE_SEARCH_REPORT_APPROVAL_STATUS_LABEL
                        )}
                        width={300}
                    />
                </div>
                <RRFAttributeSelect
                    multiple={true}
                    path="caseStatusAttrIds"
                    filterOptions={caseStatusFilter}
                    attributeType={AttributeTypeEnum.CASE_STATUS.name}
                    width={300}
                />
            </Row>
            <Row>
                <RRFOffenseCaseStatusAttrSelect
                    label={formatFieldByName(OFFENSE_CASE_STATUS_CASE_STATUS_ATTR_ID)}
                    width={300}
                />
            </Row>
            <Row>
                <RRFDateRangePicker
                    label={formatFieldByName(DISPLAY_ONLY_EVENT_DATE_TIME_RANGE_LABEL)}
                    width={300}
                    includeTime={true}
                    withinLastPeriodOptions={['PT12H', 'PT24H', 'PT48H', 'P7D', 'P28D']}
                    toDatePeriodOptions={['P1M', 'P1Y']}
                />
                <RRFLabelsWithNoneAttrSelect
                    label={formatFieldByName(DISPLAY_ONLY_CASE_SEARCH_LABELS_LABEL)}
                    multiple={true}
                    width={300}
                />
            </Row>
            <Row>
                <RRFDateRangePicker
                    label={formatFieldByName(
                        DISPLAY_ONLY_CASE_SEARCH_REPORT_CREATED_DATE_TIME_RANGE_LABEL
                    )}
                    width={300}
                    includeTime={true}
                    withinLastPeriodOptions={['PT12H', 'PT24H', 'PT48H', 'P7D', 'P28D']}
                    toDatePeriodOptions={['P1M', 'P1Y']}
                    paths={{
                        withinLastPeriod: 'createdDateRangeQuery.withinLastPeriod',
                        toDatePeriod: 'createdDateRangeQuery.toDatePeriod',
                        startDateUtc: 'createdDateRangeQuery.startDateUtc',
                        endDateUtc: 'createdDateRangeQuery.endDateUtc',
                    }}
                />
                <div data-test-id={testIds.CASE_DASHBOARD_HAS_LINKED_ARREST_FILTER}>
                    <RRFBooleanSelect path="hasArrest" width={300} />
                </div>
            </Row>
            <Row>
                <RRFOffenseCodeSelect
                    multiple={true}
                    flags={OFFENSE_TYPE_OFFENSE_CODE_FLAGS}
                    includeExpired={true}
                    width={300}
                    path="offenseCodeIds"
                />
                <RRFAttributeSelect
                    multiple={true}
                    path="offenseClassificationAttrIds"
                    attributeType={AttributeTypeEnum.OFFENSE_CLASSIFICATION.name}
                    width={300}
                />
            </Row>
            <Row>
                <RRFBooleanSelect path="isSuspectedHateCrime" width={300} />
                <RRFBooleanSelect path="isDomesticViolence" width={300} />
            </Row>
            <SubdivisionsSearch width={196} />
        </RRFFieldset>
    );
};

export default connect(mapStateToProps)(UnassignedReportsSearchReportDetailsFieldset);
