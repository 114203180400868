import { EntityTypeEnum, ProductModuleEnum } from '@mark43/rms-api';
import { map, values, get, findLast, indexOf, reduce, concat, slice } from 'lodash';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import styled from 'styled-components';
import { sortedChargesWithDetentionsForReportIdSelector } from '~/client-common/core/domain/charges/state/ui';

import sortRelatedRecordsEnum from '~/client-common/core/enums/client/reportSidebarSortRelatedRecordsEnum';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { reportLabelAttributeViewModelsForReportIdSelector } from '~/client-common/core/domain/report-attributes/state/ui';
import componentStrings from '~/client-common/core/strings/componentStrings';
import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';
import { REPORT_REPORTING_EVENT_NUMBER } from '~/client-common/core/enums/universal/fields';
import ProductModuled from '~/client-common/core/domain/product-modules/components/ProductModuled';
import { isProductModuleActiveSelector } from '~/client-common/core/domain/product-modules/state/data';
import { courtOrdersByReportIdSelector } from '~/client-common/core/domain/court-orders/state/data';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import { linkedReportShortTitleViewModelsForReportSelector } from '~/client-common/core/domain/report-short-titles/state/ui';
import sortTypeEnum from '~/client-common/core/enums/universal/sortTypeEnum';
import reportCardEnum from '~/client-common/core/enums/universal/reportCardEnum';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';

import { embeddedReportShortTitlesSelector } from '../../../modules/reports/core/state/ui/arrestBlock';
import { SCROLL_SPY_OFFSET } from '../../configs/reportsConfig';
import _ApprovalStatusIcon from '../../../modules/records/core/components/ApprovalStatusIcon';
import { LegacyReportSidebarScrollSpy } from '../core/ScrollSpy';
import RecordSidebarSection from '../../../modules/records/core/components/sidebar/RecordSidebarSection';
import RoutingLabelsSection from '../../../modules/records/core/components/sidebar/RoutingLabelsSection';
import CourtOrdersSection from '../../../modules/records/core/components/sidebar/CourtOrdersSection';
import LinkedCases from '../../../modules/records/core/components/sidebar/LinkedCases';
import LinkedReports from '../../../modules/records/core/components/sidebar/LinkedReports';
import LinkedWarrants from '../../../modules/records/core/components/sidebar/LinkedWarrants';
import LinkedEFiles from '../../../modules/records/core/components/sidebar/LinkedEFiles';
import { useEFilesRelatedToEntity } from '../../../modules/e-files/hooks/useEFilesRelatedToEntity';
import { useScreenBreakpoint } from '../../../modules/core/utils/useScreenBreakpoint';

import SortOptions, {
    SortOption,
} from '../../../modules/records/core/components/sidebar/SortOptions';
import { ARREST_BLOCK_ANCHOR } from '../../../modules/reports/core/components/ArrestBlock';
import {
    linkedCaseTitleViewModelsSelector,
    linkedWarrantTitleViewModelsSelector,
    approvalStatusSelector,
    currentReportSelector,
} from '../../selectors/reportSelectors';
import {
    currentUserHasAbilitySelector,
    currentUserDepartmentIdSelector,
} from '../../../modules/core/current-user/state/ui';
import { doesVacatedChargeExistForReportIdSelector } from '../../../modules/record-privacy/vacating/state/ui';
import {
    isInvolvedPersonSealedForReportIdSelector,
    doesSealedChargeExistForReportIdSelector,
} from '../../../modules/record-privacy/sealing/state/ui';
import { reportCardsSidebarSelector } from '../../../modules/reports/core/state/ui/reportSidebar';
import testIds from '../../../core/testIds';
import { DetentionSection } from '../../../modules/records/core/components/sidebar/DetentionSection';
import ReportSidebarCardsScrollSpy, { ReportCard } from './ReportSidebarCardsScrollSpy';

const strings = componentStrings.reports.ReportSidebar;

const reportSidebarSelector = createStructuredSelector({
    linkedReportShortTitleViewModelsForReport: linkedReportShortTitleViewModelsForReportSelector,
    linkedCaseTitleViewModels: linkedCaseTitleViewModelsSelector,
    linkedWarrantTitleViewModels: linkedWarrantTitleViewModelsSelector,
    approvalStatus: approvalStatusSelector,
    reportCardsSidebar: reportCardsSidebarSelector,
    isProductModuleActive: isProductModuleActiveSelector,
    reportLabelAttributeViewModelsForReportId: reportLabelAttributeViewModelsForReportIdSelector,
    currentReport: currentReportSelector,
    currentUserHasAbility: currentUserHasAbilitySelector,
    currentUserDepartmentId: currentUserDepartmentIdSelector,
    formatFieldByName: formatFieldByNameSelector,
    courtOrdersByReportId: courtOrdersByReportIdSelector,
    isInvolvedPersonSealedForReportId: isInvolvedPersonSealedForReportIdSelector,
    doesSealedChargeExistForReportId: doesSealedChargeExistForReportIdSelector,
    doesVacatedChargeExistForReportId: doesVacatedChargeExistForReportIdSelector,
    embeddedReportShortTitles: embeddedReportShortTitlesSelector,
    sortedChargesWithDetentionsForReportId: sortedChargesWithDetentionsForReportIdSelector,
    applicationSettings: applicationSettingsSelector,
});

const ApprovalStatusIcon = styled(_ApprovalStatusIcon)`
    margin-right: 6px;
    vertical-align: middle;
`;

const ReportTitle = styled.div`
    margin-top: 10px;
`;

const getActiveDisplay = (value) => {
    switch (value) {
        case sortRelatedRecordsEnum.ALPHABETICAL:
            return strings.sortOptions.alphabetical.label;
        case sortRelatedRecordsEnum.CREATED_DATE:
            return strings.sortOptions.createdDateUtc.label;
        case sortRelatedRecordsEnum.MODIFIED_DATE:
            return strings.sortOptions.modifiedDateUtc.label;
        default:
            return strings.sortOptions.createdDateUtc.label;
    }
};

function ReportSidebar({
    approvalStatus,
    linkedReportShortTitleViewModelsForReport,
    linkedCaseTitleViewModels,
    linkedWarrantTitleViewModels,
    reportCardsSidebar,
    isProductModuleActive,
    reportLabelAttributeViewModelsForReportId,
    currentReport = {},
    currentUserHasAbility,
    currentUserDepartmentId,
    courtOrdersByReportId,
    sortedChargesWithDetentionsForReportId,
    disabled,
    formatFieldByName,
    isInvolvedPersonSealedForReportId,
    doesSealedChargeExistForReportId,
    doesVacatedChargeExistForReportId,
    embeddedReportShortTitles,
    applicationSettings,
}) {
    const canViewWarrants = currentUserHasAbility(abilitiesEnum.WARRANTS.VIEW_GENERAL);
    const canViewEFiles = currentUserHasAbility(abilitiesEnum.EFILES.VIEW);
    const { isMobile } = useScreenBreakpoint();

    const reportRen = get(currentReport, 'reportingEventNumber');
    const reportId = get(currentReport, 'id');
    const reportCards = reportCardsSidebar(currentReport);
    const linkedReportShortTitleViewModels = linkedReportShortTitleViewModelsForReport(
        currentReport || {}
    );
    const [sortType, setSortType] = useState(sortTypeEnum.DATE_LEAST_RECENT_TO_MOST_RECENT);
    const [activeValue, setActiveValue] = useState(sortRelatedRecordsEnum.CREATED_DATE);

    const { eFileTitles } = useEFilesRelatedToEntity({
        entityId: reportId,
        entityType: EntityTypeEnum.REPORT.name,
    });

    const activeDisplay = getActiveDisplay(activeValue);

    const handleSortOptionClick = ({ value, sortOptions }) => {
        setSortType(sortOptions[0].sortType);
        setActiveValue(value);
    };

    const handleSortTypeClick = (sortType) => {
        setSortType(sortType);
    };

    let allAnchors = map(reportCards, 'anchor');

    if (embeddedReportShortTitles && applicationSettings.RMS_DYNAMIC_REPORT_ENABLED) {
        // This grabs all of the report cards from any number of embedded reports into one array so that their anchors can be added to allAnchors
        const embeddedReportSidebarCards = reduce(
            embeddedReportShortTitles,
            (embeddedReportCards, embeddedReportShortTitle) => {
                const nextEmbeddedReportCards = reportCardsSidebar(embeddedReportShortTitle, true);
                const allEmbeddedReportCards = concat(embeddedReportCards, nextEmbeddedReportCards);
                return allEmbeddedReportCards;
            },
            []
        );
        // embeddedReportCard is a pseudo card that allows an embedded report to be rendered when ReportCards maps through all the cards
        const embeddedReportCard = { isEmbeddedReport: true, anchor: ARREST_BLOCK_ANCHOR };
        // finds the last offense or incident card
        const lastOffenseCard = findLast(
            reportCards,
            ({ cardName }) =>
                (cardName === reportCardEnum.OFFENSE.name) |
                (cardName === reportCardEnum.INCIDENT.name)
        );
        // grabs the index of the last offense or incident card
        const indexOfLastOffenseCard = lastOffenseCard && indexOf(reportCards, lastOffenseCard);
        // grabs all the anchors of all the cards, including the embedded ones - this allows for the ScrollSpy to work properly
        allAnchors = map(
            [
                ...slice(reportCards, 0, indexOfLastOffenseCard + 1),
                ...embeddedReportSidebarCards,
                ...slice(reportCards, indexOfLastOffenseCard + 1),
            ],
            'anchor'
        );
        // splices pesudo embedded report card into reportCards array
        reportCards.splice(indexOfLastOffenseCard + 1, 0, embeddedReportCard);
    }

    const hasRelatedRecords =
        linkedCaseTitleViewModels.length +
            values(linkedReportShortTitleViewModels).length +
            (isProductModuleActive(ProductModuleEnum.WARRANTS.name)
                ? linkedWarrantTitleViewModels.length
                : 0) +
            eFileTitles.length >
        0;
    const renDisplayName = formatFieldByName(REPORT_REPORTING_EVENT_NUMBER);
    const title =
        applicationSettings.RMS_INVESTIGATION_ENHANCEMENTS_PHASE_ONE && reportRen
            ? strings.renTitle(renDisplayName, reportRen || '')
            : strings.title;

    const relatedRecords = hasRelatedRecords && (
        <RecordSidebarSection
            title={title}
            sectionTestId={testIds.LINKED_RECORDS_HEADER}
            sortPopoutMenu={
                <SortOptions
                    display={activeDisplay}
                    activeValue={activeValue}
                    activeSortType={sortType}
                    onOptionClick={handleSortOptionClick}
                    onSortTypeClick={handleSortTypeClick}
                >
                    <SortOption
                        display={strings.sortOptions.alphabetical.label}
                        value={sortRelatedRecordsEnum.ALPHABETICAL}
                        columnKey={sortRelatedRecordsEnum.ALPHABETICAL}
                        sortOptions={[
                            {
                                display: strings.sortOptions.alphabetical.ascendingSort,
                                sortType: sortTypeEnum.ALPHABETICAL_A_TO_Z,
                            },
                            {
                                display: strings.sortOptions.alphabetical.descendingSort,
                                sortType: sortTypeEnum.ALPHABETICAL_Z_TO_A,
                            },
                        ]}
                    />
                    <SortOption
                        display={strings.sortOptions.createdDateUtc.label}
                        value={sortRelatedRecordsEnum.CREATED_DATE}
                        columnKey={sortRelatedRecordsEnum.CREATED_DATE}
                        sortOptions={[
                            {
                                display: strings.sortOptions.createdDateUtc.ascendingSort,
                                sortType: sortTypeEnum.DATE_LEAST_RECENT_TO_MOST_RECENT,
                            },
                            {
                                display: strings.sortOptions.createdDateUtc.descendingSort,
                                sortType: sortTypeEnum.DATE_MOST_RECENT_TO_LEAST_RECENT,
                            },
                        ]}
                    />
                </SortOptions>
            }
        >
            <FeatureFlagged flag="RMS_E_FILING_ENABLED">
                <LinkedEFiles
                    eFileTitles={eFileTitles}
                    sortType={sortType}
                    activeValue={activeValue}
                    canView={canViewEFiles}
                />
            </FeatureFlagged>
            <LinkedCases caseTitleViewModels={linkedCaseTitleViewModels} />
            <LinkedReports
                reportShortTitleViewModels={linkedReportShortTitleViewModels}
                sortType={sortType}
                activeValue={activeValue}
            />
            <ProductModuled productModule={ProductModuleEnum.WARRANTS.name}>
                <LinkedWarrants
                    warrantTitleViewModels={linkedWarrantTitleViewModels}
                    canViewWarrants={canViewWarrants}
                    sortType={sortType}
                    activeValue={activeValue}
                />
            </ProductModuled>
        </RecordSidebarSection>
    );

    const courtOrders = courtOrdersByReportId(reportId);
    const isPartiallySealed =
        isInvolvedPersonSealedForReportId(reportId) || doesSealedChargeExistForReportId(reportId);

    const sortedChargesWithDetentions = sortedChargesWithDetentionsForReportId(reportId);

    return (
        <>
            {!!sortedChargesWithDetentions.length && <DetentionSection reportId={reportId} />}
            {courtOrders &&
                !!courtOrders.length &&
                currentUserHasAbility(abilitiesEnum.REPORTING.VIEW_COURT_ORDERS) && (
                    <CourtOrdersSection courtOrders={courtOrders} reportId={reportId} />
                )}
            {!currentReport.isSealed && (
                <RoutingLabelsSection
                    labels={reportLabelAttributeViewModelsForReportId(currentReport.id)}
                    disableEditing={
                        disabled || currentReport.departmentId !== currentUserDepartmentId
                    }
                    recordId={currentReport.id}
                />
            )}
            {!currentReport.isSealed && !isMobile && (
                <RecordSidebarSection
                    title={strings.currentlyViewing}
                    sectionTestId={testIds.REPORT_SIDEBAR_CARD_SECTION}
                >
                    <ReportTitle className="report-sidebar-report-title">
                        <ApprovalStatusIcon
                            approvalStatus={approvalStatus}
                            isPartiallySealed={isPartiallySealed}
                            isVacated={doesVacatedChargeExistForReportId(reportId)}
                        />
                        <span className="report-type">Report</span>
                    </ReportTitle>
                    <FeatureFlagged
                        flag="RMS_DYNAMIC_REPORT_ENABLED"
                        fallback={
                            <ReportCards cards={reportCards} approvalStatus={approvalStatus} />
                        }
                    >
                        <ReportSidebarCardsScrollSpy
                            approvalStatus={approvalStatus}
                            allAnchors={allAnchors}
                            cards={reportCards}
                            embeddedReportShortTitles={embeddedReportShortTitles}
                        />
                    </FeatureFlagged>
                </RecordSidebarSection>
            )}
            {relatedRecords}
        </>
    );
}

function ReportCards({ cards, approvalStatus }) {
    let key = 0;
    const reportCards = map(cards, ({ display, anchor }) => {
        return (
            <ReportCard
                key={key++}
                display={display}
                anchor={anchor}
                approvalStatus={anchor === 'report-status-comments-card' && approvalStatus}
            />
        );
    });
    const anchors = map(cards, 'anchor');
    return (
        <LegacyReportSidebarScrollSpy
            constantOffset={SCROLL_SPY_OFFSET}
            anchors={anchors}
            className="report-sidebar-report-cards"
        >
            {reportCards}
        </LegacyReportSidebarScrollSpy>
    );
}

export default connect(reportSidebarSelector)(ReportSidebar);
