import { find, map } from 'lodash';
import { ReportDefinitionCard } from '@mark43/rms-api';

import reportCardEnum from '~/client-common/core/enums/universal/reportCardEnum';
import { isUndefinedOrNull } from '~/client-common/helpers/logicHelpers';

export const getInformationCardIdFromCardLinks = (cardLinks: ReportDefinitionCard[]) => {
    const eventInfoCardId = reportCardEnum.EVENT_INFO.id;
    const supplementInfoCardId = reportCardEnum.SUPPLEMENT_INFO.id;
    const migrationInfoCardId = reportCardEnum.LEGACY_INFO.id;
    const crashEventInfoCardId = reportCardEnum.CRASH_EVENT_INFO.id;

    const eventInfoCardLink = find(cardLinks, {
        cardId: eventInfoCardId,
    });
    const supplementInfoCardLink = find(cardLinks, {
        cardId: supplementInfoCardId,
    });
    const migrationInfoCardLink = find(cardLinks, {
        cardId: migrationInfoCardId,
    });
    const crashEventInfoCardLink = find(cardLinks, {
        cardId: crashEventInfoCardId,
    });

    if (!!eventInfoCardLink && !eventInfoCardLink.isHidden) {
        return eventInfoCardId;
    } else if (!!supplementInfoCardLink && !supplementInfoCardLink.isHidden) {
        return supplementInfoCardId;
    } else if (!!migrationInfoCardLink && !migrationInfoCardLink?.isHidden) {
        return migrationInfoCardId;
    } else if (!!crashEventInfoCardLink && !crashEventInfoCardLink?.isHidden) {
        return crashEventInfoCardId;
    } else {
        return undefined;
    }
};

export const augmentCardLinkFormsWithSelectedInformationCardInfo = (
    cardLinkForms: ReportDefinitionCard[],
    informationCardId?: number
) => {
    const eventInfoCardId = reportCardEnum.EVENT_INFO.id;
    const supplementInfoCardId = reportCardEnum.SUPPLEMENT_INFO.id;
    const migrationInfoCardId = reportCardEnum.LEGACY_INFO.id;
    const crashEventInfoCardId = reportCardEnum.CRASH_EVENT_INFO.id;
    const isInformationCardChosen = !isUndefinedOrNull(informationCardId);
    return map(cardLinkForms, (cardLinkForm) => {
        if (isInformationCardChosen && cardLinkForm.cardId === eventInfoCardId) {
            return {
                ...cardLinkForm,
                isHidden: informationCardId !== eventInfoCardId,
            };
        } else if (isInformationCardChosen && cardLinkForm.cardId === supplementInfoCardId) {
            return {
                ...cardLinkForm,
                isHidden: informationCardId !== supplementInfoCardId,
            };
        } else if (isInformationCardChosen && cardLinkForm.cardId === migrationInfoCardId) {
            return {
                ...cardLinkForm,
                isHidden: informationCardId !== migrationInfoCardId,
            };
        } else if (isInformationCardChosen && cardLinkForm.cardId === crashEventInfoCardId) {
            return {
                ...cardLinkForm,
                isHidden: informationCardId !== crashEventInfoCardId,
            };
        }
        return cardLinkForm;
    });
};
