import {
    AttributeView,
    CellValidationError,
    GeoCaution,
    LocationView,
    SimpleValidationErrorTypeEnum,
    SubPremiseDto,
} from '@mark43/rms-api';
import { isAfter, nowUtc } from '~/client-common/core/dates/utils/dateHelpers';
import { geoCautionLocationDataT } from '../../components/hooks/useGeoCautions';
import { GeoCautionAdminMFTFormDataShape } from '../../forms/geoCautionsAdminForm';
import {
    NewGeoCautionLocationT,
    UniqueLocationT,
} from '../../components/hooks/useLocationListItems';
import { CsvValidationError } from '../../../core/import-export/components/CsvValidationErrorTable';

export function prepareSubPremiseFields(
    fields: Partial<GeoCaution>,
    formModel: GeoCautionAdminMFTFormDataShape
): Partial<GeoCaution> {
    const newModel = { ...fields };

    for (let i = 1; i < 6; i++) {
        delete newModel[`subPremise${i}Name` as keyof GeoCaution];
        delete newModel[`subPremise${i}Value` as keyof GeoCaution];
    }

    return { ...newModel, ...transformSubPremiseDtosToFields(formModel.subPremiseDtos ?? []) };
}

function transformSubPremiseDtosToFields(dtos: SubPremiseDto[]): Partial<GeoCaution> {
    const fields: Record<`subPremise${number}${'Name' | 'Value'}`, string | undefined> = {};

    dtos.forEach((dto, index) => {
        fields[`subPremise${index + 1}Name`] = dto.subPremiseName;
        fields[`subPremise${index + 1}Value`] = dto.subPremiseValue;
    });

    return fields;
}

export function transformGeocautionToSubPremiseDtos(
    caution: GeoCautionAdminMFTFormDataShape | GeoCaution | LocationView
): SubPremiseDto[] {
    const dtos = [];
    for (let i = 1; i < 6; i++) {
        const subPremiseName = caution[
            `subPremise${i}Name` as keyof (
                | GeoCautionAdminMFTFormDataShape
                | GeoCaution
                | LocationView
            )
        ] as string | undefined;
        const subPremiseValue = caution[
            `subPremise${i}Value` as keyof (
                | GeoCautionAdminMFTFormDataShape
                | GeoCaution
                | LocationView
            )
        ] as string | undefined;

        if (subPremiseName || subPremiseValue) {
            dtos.push({
                subPremiseName,
                subPremiseValue,
            });
        }
    }
    return dtos;
}

export function getSubPremiseDisplayLine(
    geoCaution: geoCautionLocationDataT | LocationView
): string {
    const displays: string[] = [];
    for (let i = 1; i < 6; i++) {
        const name =
            geoCaution[`subPremise${i}Name` as keyof (geoCautionLocationDataT | LocationView)];
        const value =
            geoCaution[`subPremise${i}Value` as keyof (geoCautionLocationDataT | LocationView)];

        displays.push([name, value].filter((s) => !!s).join(' '));
    }
    return displays.filter((s) => !!s).join(', ');
}

export function getLocationQueryPrefill(
    location: UniqueLocationT | NewGeoCautionLocationT | undefined
): string | undefined {
    if (!location) {
        return undefined;
    }

    if ('locationData' in location) {
        return `${location.locationData.fullAddress?.split('\n').join(' ')} #`;
    }

    return `${location.location.displayLineFirst} #`;
}

/**
 * This helper function is when attemptin to create a new geoCaution in an
 */

export const getLocationDataFromGeoCaution = (geoCaution: GeoCaution) => {
    // When destructuring this way, we can spread the last item
    // and give it any name with want and it will include all of the
    // remaining properties which in this case will be of type
    // geoCautionLocationDataT.
    /* eslint-disable @typescript-eslint/no-unused-vars */
    const {
        createdBy,
        createdDateUtc,
        description,
        endDateUtc,
        geometry,
        guid,
        id,
        locationCautionAttrId,
        radius,
        rmsEventId,
        spatialReferenceId,
        updatedBy,
        ...geoCautionLocationData
    } = geoCaution;

    return geoCautionLocationData;
};

export const getGeoCautionPillData = (
    locationCautionAttrId: number,
    locationCautionAttributes: AttributeView[],
    locationCautionsCategoryAttributes: AttributeView[]
) => {
    const selectedCategory = locationCautionAttributes.find(
        (cautionCategory) => cautionCategory.id === locationCautionAttrId
    );
    let locationCautionCategory;
    if (selectedCategory) {
        locationCautionCategory = locationCautionsCategoryAttributes.find(
            (c) => c.id === selectedCategory?.parentId
        );
    }
    // Type is potentially Attribute which doesnt have
    // a val property but then when you convert it to AttributeView
    // it breaks src/scripts/modules/admin/geo-cautions/forms/geoCautionsAdminForm.ts
    // because it expects parentAttributeId which only exists in Attribute
    return {
        priority: locationCautionCategory?.parentId,
        locationCautionAttrName: selectedCategory?.val || '',
        locationCautionCategory: locationCautionCategory?.val,
    };
};

export const convertToTitleCase = (input: string) =>
    input
        .toLocaleLowerCase()
        .split(' ')
        .map((word) => {
            return word.charAt(0).toUpperCase() + word.slice(1);
        })
        .join(' ');

export const getFirstLevelError = (errors: CellValidationError[]) => {
    let firstLevelError: CellValidationError | undefined;
    // These are all considered First Level Validation Errors
    const { CSV_HEADER_ROW, FILE_EXTENSION, INCORRECT_TYPE, EMPTY_FILE } =
        SimpleValidationErrorTypeEnum;

    for (let i = 0; i < errors.length; i++) {
        const error = errors[i];
        switch (error.errorType) {
            case CSV_HEADER_ROW.name:
            case FILE_EXTENSION.name:
            case EMPTY_FILE.name:
            case INCORRECT_TYPE.name:
                firstLevelError = error;
                break;
            default:
                break;
        }
        if (firstLevelError) {
            break;
        }
    }

    return firstLevelError;
};

export const getSecondLevelErrors = (errors: CellValidationError[]): CsvValidationError[] => {
    return errors.map((err) => {
        const { errorMessage = '', rowNumber = '', columnName = '', actualValue = '' } = err;
        return {
            errorMessage,
            lineNumber: `${rowNumber}`,
            csvColumn: columnName,
            inputValue: actualValue,
        };
    });
};

export const generateDisplayDate = (
    startDateUtc: string,
    updatedDateUtc: string,
    createdDateUtc: string
) => {
    if (startDateUtc) {
        if (updatedDateUtc && isAfter(startDateUtc, updatedDateUtc)) {
            return updatedDateUtc;
        } else {
            return startDateUtc;
        }
    } else if (updatedDateUtc) {
        return updatedDateUtc;
    } else {
        return createdDateUtc || nowUtc();
    }
};
