import { RefContextEnum, RenConfigurationEnum } from '@mark43/rms-api';
import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { chain, map, filter } from 'lodash';
import { compose } from 'recompose';
import { withRegistry, lifecycleOptions } from 'markformythree';
import { withRouter } from 'react-router';
import { Icon as ArcIcon } from 'arc';

import componentStrings from '~/client-common/core/strings';
import recordSequenceTypeEnum from '~/client-common/core/enums/client/recordSequenceTypeEnum';
import { REPORT_REPORTING_EVENT_NUMBER } from '~/client-common/core/enums/universal/fields';
import { isUndefinedOrNull } from '~/client-common/helpers/logicHelpers';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import { IconButton } from '../../../core/components/IconButton';

import { SectionHeader } from '../../../../legacy-redux/components/core/Content';
import { PortalModal } from '../../../../legacy-redux/components/core/Modal';
import FilterableList from '../../../core/components/FilterableList';
import testIds from '../../../../core/testIds';
import { Tile } from '../../../core/components/Tile';

import Button from '../../../../legacy-redux/components/core/Button';
import ArbiterForm from '../../../core/markformythree-arbiter/ArbiterForm';
import { ArbiterMFTText } from '../../../core/forms/components/Text';
import {
    submitCreateReportModal,
    currentDepartmentReportShortTitleViewModelsWhereSelector,
    currentDepartmentReportShortTitleViewModelsMultiRenWhereSelector,
    submitRenSearch,
    submitFormSearch,
    chooseReportDefinition,
    onReportingEventNumberChange,
    onRecordNumberChange,
    onCadAgencyEventNumberChange,
    createReportModalFormConfiguration,
} from '../state/ui/createReportModal';
import LinkedCadReports from '../../../../legacy-redux/components/reports/LinkedCadReports';
import { ArbiterField } from '../../../core/arbiter';
import { OverlayBase } from '../../../../core/overlayManager';
import { Tooltip } from '../../../core/components/tooltip';
import EntitySummary from '../../../records/core/components/summaries/EntitySummary';
import EntitySummaryHeader from '../../../records/core/components/summaries/EntitySummaryHeader';
import { SummaryExpandCollapseButton } from '../../../records/core/components/summaries/buttons/SummaryExpandCollapseButton';
import LinkedReports from '../../../records/core/components/sidebar/LinkedReports';
import { AnalyticsPropertyEnum } from '../../../analytics/constants/analyticsEnum';
import { AnalyticsContextProviderWithAdditionalData } from '../../../core/context/AnalyticsContext';

const strings = componentStrings.components.reports.CreateReportModal;

const Header = styled(SectionHeader)`
    float: left;
    padding: 7px 0 4px 12px;
    margin: 0 -8px 16px -8px;
`;

const NewReportOptions = styled.div`
    width: 100%;
`;

const SearchButton = styled(Button)`
    margin-top: 20px;
    float: right;
    min-width: 0px;
    padding-right: 10px;
    padding-left: 10px;
`;

const GenerateRenButton = styled(Button)`
    &&& {
        // &&& needed to override Arc
        width: 98%;
    }
`;

const EventSearch = styled.div`
    display: block;
`;

const LinkedReportsWrapper = styled.div`
    transform: translateX(-28px);
`;

const RightReportTypesPanel = styled.div`
    height: 535px;
    overflow: auto;
    padding-left: 16px;
    flex: 1;
`;

const LeftEventInformationPanel = styled.div`
    width: 346px;
    height: 535px;
    overflow: auto;
    padding-right: 16px;
`;

const RenSearchSection = styled.div`
    margin-bottom: 20px;
`;

const reportDefinitionsOptionsMapper = (
    chooseReportDefinition,
    selectedDefinition,
    setSelectedDefinition
) => (definition) => {
    const { reportDefinitionDisabled, id, name } = definition;

    function renderTile() {
        return (
            <Tile
                key={id}
                isDisabled={reportDefinitionDisabled}
                title={name}
                media={<ArcIcon color="brand.default" icon="Add" size="md" />}
                onClick={() => {
                    chooseReportDefinition(definition);
                    setSelectedDefinition(definition);
                }}
                testId={testIds.NEW_REPORT_OPTION}
                isSelected={selectedDefinition?.id === definition.id}
            />
        );
    }
    return (
        <React.Fragment key={id}>
            {reportDefinitionDisabled ? (
                <Tooltip side="top" hasArrow content={strings.eventHasReportForReportType}>
                    {renderTile()}
                </Tooltip>
            ) : (
                <AnalyticsContextProviderWithAdditionalData
                    analyticsKeyToAdd={AnalyticsPropertyEnum.REPORT_DEFINITION}
                    analyticsValueToAdd={name}
                >
                    {renderTile()}
                </AnalyticsContextProviderWithAdditionalData>
            )}
        </React.Fragment>
    );
};

const ReportDefinitionList = ({ reportDefinitions, chooseReportDefinition }) => {
    const reportDefinitionsFilterableListOptions = chain(reportDefinitions)
        .filter((definition) => definition.isEnabled && definition.isActive)
        .sortBy('name')
        .map((definition) => {
            return {
                ...definition,
                display: definition.name,
            };
        })
        .value();
    const [selectedDefinition, setSelectedDefinition] = React.useState(null);

    return (
        <NewReportOptions>
            <FilterableList
                options={reportDefinitionsFilterableListOptions}
                optionsMapper={reportDefinitionsOptionsMapper(
                    chooseReportDefinition,
                    selectedDefinition,
                    setSelectedDefinition
                )}
                placeholderText={strings.filterPlaceholder}
                testId={testIds.NEW_REPORT_OPTIONS_LIST}
            />
        </NewReportOptions>
    );
};

const RenSummaryView = ({ viewModels, name }) => {
    return (
        <div className="linked-reports-section-members">
            <EntitySummary
                initiallyShowAll={true}
                collapsible={true}
                customStyles={{ marginBottom: '10px' }}
                renderTitle={() => (
                    <EntitySummaryHeader name={name} headerContentStyles={{ paddingLeft: '0px' }} />
                )}
                renderActionButtons={({ setCollapsed, collapsed }) => {
                    return (
                        <SummaryExpandCollapseButton
                            collapsed={collapsed}
                            setCollapsed={setCollapsed}
                        />
                    );
                }}
            >
                <LinkedReportsWrapper>
                    <LinkedReports
                        isCollapsible={false}
                        reportShortTitleViewModels={viewModels}
                        noReportsMessage={strings.noReportsExist}
                    />
                </LinkedReportsWrapper>
            </EntitySummary>
        </div>
    );
};

class _ReportSearchForm extends React.Component {
    componentWillUnmount() {
        const form = this.props.registry.get(RefContextEnum.FORM_REPORT_CREATE_REPORT.name);
        if (form) {
            this.props.registry.unregister(form);
        }
    }

    render() {
        const {
            submitRenSearch,
            submitFormSearch,
            currentDepartmentReportShortTitleViewModelsWhere,
            reportDefinition,
            overlayState,
            eventInfo,
            submitAutoCreateReportModal,
            reportingEventNumberChange,
            recordNumberChange,
            cadAgencyEventNumberChange,
        } = this.props;

        const {
            isLoadingSearch,
            hasFinishedSearch,
            lastSearchedCadAgencyEventNumber,
            disableRen = false,
            createdFromCadEvent,
        } = overlayState.customProperties;

        const allReportShortTitles = currentDepartmentReportShortTitleViewModelsWhere(
            eventInfo?.rens
        );

        const sortedRens = eventInfo?.rens
            ? eventInfo?.rens.sort((a, b) => {
                  return a.localeCompare(b, undefined, {
                      numeric: true,
                      sensitivity: 'base',
                  });
              })
            : [];

        return (
            <EventSearch>
                <ArbiterForm
                    lifecycle={lifecycleOptions.REGISTER_AND_RETAIN}
                    name={RefContextEnum.FORM_REPORT_CREATE_REPORT.name}
                    context={RefContextEnum.FORM_REPORT_CREATE_REPORT.name}
                    configuration={createReportModalFormConfiguration}
                    render={() => (
                        <ArbiterField fieldName={REPORT_REPORTING_EVENT_NUMBER}>
                            {({ fieldConfiguration }) => {
                                const label =
                                    reportDefinition?.renConfiguration ===
                                    RenConfigurationEnum.OPTIONAL.name
                                        ? `${fieldConfiguration.displayName} (${strings.optionalLabel})`
                                        : fieldConfiguration.displayName;
                                const showSearchButton =
                                    (reportDefinition?.recordSequenceType ===
                                        recordSequenceTypeEnum.CAD_EVENT &&
                                        reportDefinition?.renConfiguration ===
                                            RenConfigurationEnum.NONE.name) ||
                                    reportDefinition?.renConfiguration !==
                                        RenConfigurationEnum.NONE.name;

                                return (
                                    <>
                                        {!isUndefinedOrNull(eventInfo) ? (
                                            <RenSearchSection>
                                                {(reportDefinition?.recordSequenceType ===
                                                    recordSequenceTypeEnum.FREE_TEXT ||
                                                    reportDefinition?.recordSequenceType ===
                                                        recordSequenceTypeEnum.FORMATTED_FREE_TEXT) && (
                                                    <ArbiterMFTText
                                                        path="recordNumber"
                                                        label={`${reportDefinition?.name} #`}
                                                        length="md"
                                                        onChange={recordNumberChange}
                                                    />
                                                )}
                                                {reportDefinition?.recordSequenceType ===
                                                    recordSequenceTypeEnum.CAD_EVENT && (
                                                    <ArbiterMFTText
                                                        path="cadAgencyEventNumber"
                                                        disabled={createdFromCadEvent}
                                                        length="md"
                                                        loading={isLoadingSearch}
                                                        onPressEnter={submitFormSearch}
                                                        onChange={cadAgencyEventNumberChange}
                                                        forceShowError={true}
                                                    />
                                                )}

                                                <ArbiterMFTText
                                                    path="reportingEventNumber"
                                                    label={label}
                                                    disabled={
                                                        disableRen ||
                                                        reportDefinition?.recordSequenceType ===
                                                            recordSequenceTypeEnum.CAD_EVENT
                                                    }
                                                    length="md"
                                                    loading={isLoadingSearch}
                                                    onPressEnter={submitFormSearch}
                                                    onChange={(ren) =>
                                                        reportingEventNumberChange(
                                                            reportDefinition?.renConfiguration,
                                                            ren
                                                        )
                                                    }
                                                    forceShowError={true}
                                                />
                                                {showSearchButton && (
                                                    <IconButton
                                                        aria-label="Search"
                                                        size="sm"
                                                        icon="Search"
                                                        disabled={disableRen}
                                                        onClick={submitFormSearch}
                                                        testId={testIds.EVENT_SEARCH_BUTTON}
                                                        style={{
                                                            float: 'right',
                                                            marginTop: 20,
                                                        }}
                                                    />
                                                )}
                                                <FeatureFlagged flag="RMS_REPORT_RECORDS_WITHOUT_REN_ENABLED">
                                                    <FeatureFlagged flag="RMS_AUTO_GENERATED_REN_ENABLED">
                                                        <GenerateRenButton
                                                            onClick={submitAutoCreateReportModal(
                                                                reportDefinition?.id,
                                                                reportDefinition?.recordSequenceType ===
                                                                    recordSequenceTypeEnum.CAD_EVENT
                                                            )}
                                                            disabled={disableRen}
                                                            testId={
                                                                testIds.RMS_AUTOGENERATE_REN_BUTTON
                                                            }
                                                        >
                                                            {strings.autogenerateRenText(
                                                                fieldConfiguration.displayName
                                                            )}
                                                        </GenerateRenButton>
                                                    </FeatureFlagged>
                                                </FeatureFlagged>
                                            </RenSearchSection>
                                        ) : (
                                            !createdFromCadEvent && (
                                                <RenSearchSection>
                                                    <ArbiterMFTText
                                                        path="reportingEventNumber"
                                                        label={label}
                                                        disabled={disableRen}
                                                        length="md"
                                                        loading={isLoadingSearch}
                                                        onPressEnter={submitRenSearch}
                                                        onChange={reportingEventNumberChange}
                                                        forceShowError={true}
                                                    />
                                                    <SearchButton
                                                        onClick={submitRenSearch}
                                                        isDisabled={disableRen}
                                                        testId={testIds.EVENT_SEARCH_BUTTON}
                                                    >
                                                        {strings.goButtonText}
                                                    </SearchButton>
                                                </RenSearchSection>
                                            )
                                        )}
                                        {hasFinishedSearch && (
                                            <>
                                                <LinkedCadReports
                                                    title={strings.cadRelatedTitle}
                                                    cadAgencyEventNumber={
                                                        lastSearchedCadAgencyEventNumber ||
                                                        eventInfo?.cadAgencyEventNumber
                                                    }
                                                    noCadTicketsLabel={strings.noCadEvents}
                                                />
                                                <div className="linked-reports-section">
                                                    <div className="linked-reports-section-title">
                                                        Reports
                                                    </div>
                                                    {map(sortedRens, (ren) => (
                                                        <RenSummaryView
                                                            viewModels={filter(
                                                                allReportShortTitles,
                                                                {
                                                                    reportingEventNumber: ren,
                                                                }
                                                            )}
                                                            name={strings.renTitle(
                                                                fieldConfiguration.displayName,
                                                                ren
                                                            )}
                                                        />
                                                    ))}
                                                </div>
                                            </>
                                        )}
                                    </>
                                );
                            }}
                        </ArbiterField>
                    )}
                />
            </EventSearch>
        );
    }
}
const ReportSearchForm = withRegistry()(_ReportSearchForm);

/**
 * @description This will display when the CAD_MULTI_REN_GENERATION_ENABLED feature flag is turned on in launch darkly.
 * @summary The new 'Create Report' modal that will display when the
 * CAD_MULTI_REN_GENERATION_ENABLED feature flag is turned on in launch darkly. This new new modal fixes event search problems
 * and adds context to Report Types by having it be only 1 page. The legacy create report modal is CreateReportModal.js
 */

const CreateReportModalV2 = ({
    currentDepartmentReportShortTitleViewModelsMultiRenWhere,
    chooseReportDefinition,
    submitRenSearch,
    submitFormSearch,
    submitCreateReportModal,
    submitAutoCreateReportModal,
    buttonComponent,
    reportingEventNumberChange,
    recordNumberChange,
    cadAgencyEventNumberChange,
}) => (
    <OverlayBase id={overlayIdEnum.CREATE_REPORT_MODAL_V2}>
        {({ overlayState, close, ...otherProps }) => {
            const {
                reportDefinition,
                reportDefinitions,
                onCloseRef,
                recordFieldIsBlank,
                renFieldIsBlank,
                cadNotSearchedAndTouched,
                formIsBlankOrError,
                cadEventInfo,
                fromPrefill,
            } = overlayState.customProperties;

            const saveDisabled =
                isUndefinedOrNull(reportDefinition) ||
                recordFieldIsBlank ||
                renFieldIsBlank ||
                cadNotSearchedAndTouched ||
                formIsBlankOrError;

            return (
                <div>
                    {buttonComponent}
                    <PortalModal
                        {...otherProps}
                        id={overlayIdEnum.CREATE_REPORT_MODAL}
                        overlayState={overlayState}
                        okText={strings.createReportButton}
                        onClose={close}
                        onCloseRef={onCloseRef}
                        saveDisabled={saveDisabled}
                        shouldCloseOnOverlayClick={true}
                        title={strings.title}
                        onSave={
                            isUndefinedOrNull(reportDefinition)
                                ? null
                                : submitCreateReportModal(
                                      reportDefinition.id,
                                      reportDefinition.recordSequenceType ===
                                          recordSequenceTypeEnum.CAD_EVENT
                                  )
                        }
                        modalStyle={{
                            width: '930px',
                            height: '670px',
                        }}
                        contentStyle={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            height: '583px',
                            padding: '24px 16px',
                        }}
                        errorStyle={{
                            width: '100%',
                        }}
                    >
                        <LeftEventInformationPanel>
                            <Header>{strings.eventInformationHeader}</Header>
                            <ReportSearchForm
                                eventInfo={cadEventInfo}
                                submitRenSearch={submitRenSearch}
                                submitFormSearch={submitFormSearch}
                                submitAutoCreateReportModal={submitAutoCreateReportModal}
                                currentDepartmentReportShortTitleViewModelsWhere={
                                    currentDepartmentReportShortTitleViewModelsMultiRenWhere
                                }
                                reportDefinition={reportDefinition}
                                overlayState={overlayState}
                                reportingEventNumberChange={reportingEventNumberChange}
                                recordNumberChange={recordNumberChange}
                                cadAgencyEventNumberChange={cadAgencyEventNumberChange}
                                {...otherProps}
                            />
                        </LeftEventInformationPanel>
                        <RightReportTypesPanel>
                            <Header>{strings.reportTypeHeader}</Header>
                            <ReportDefinitionList
                                reportDefinitions={reportDefinitions}
                                reportDefinition={reportDefinition}
                                chooseReportDefinition={chooseReportDefinition}
                                fromPrefill={fromPrefill}
                            />
                        </RightReportTypesPanel>
                    </PortalModal>
                </div>
            );
        }}
    </OverlayBase>
);

const mapStateToProps = createStructuredSelector({
    currentDepartmentReportShortTitleViewModelsWhere: currentDepartmentReportShortTitleViewModelsWhereSelector,
    currentDepartmentReportShortTitleViewModelsMultiRenWhere: currentDepartmentReportShortTitleViewModelsMultiRenWhereSelector,
});

const mapDispatchToProps = (dispatch, props) => ({
    chooseReportDefinition: (reportDefinition) =>
        dispatch(
            chooseReportDefinition({
                reportDefinition,
                router: props.router,
            })
        ),
    submitRenSearch: () => dispatch(submitRenSearch()),
    submitFormSearch: () => dispatch(submitFormSearch()),
    submitCreateReportModal: (reportDefinitionId, useCadAsRecordNumber) => () =>
        dispatch(
            submitCreateReportModal({
                reportDefinitionId,
                useCadAsRecordNumber,
                router: props.router,
                isRenAutogenerated: false,
            })
        ),
    submitAutoCreateReportModal: (reportDefinitionId, useCadAsRecordNumber) => () =>
        dispatch(
            submitCreateReportModal({
                reportDefinitionId,
                useCadAsRecordNumber,
                router: props.router,
                isRenAutogenerated: true,
            })
        ),
    reportingEventNumberChange: (renConfiguration, ren) =>
        dispatch(onReportingEventNumberChange({ renConfiguration, ren })),
    recordNumberChange: () => dispatch(onRecordNumberChange()),
    cadAgencyEventNumberChange: () => dispatch(onCadAgencyEventNumberChange()),
});

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(CreateReportModalV2);
