import { Shapefile, ShapefileProperty } from '@mark43/rms-api';
import _, { groupBy, isArray, mapValues, noop, values } from 'lodash';
import { createSelector } from 'reselect';
import createNormalizedModule from '../../../../utils/createNormalizedModule';
import getShapefilesResource from '../../resources/shapefilesResource';
import { ClientCommonAction } from '../../../../../redux/types';

const shapefilesModule = createNormalizedModule<Shapefile>({
    type: 'shapefiles',
});

const LOAD_SHAPEFILES_START = 'shapefiles/LOAD_SHAPEFILES_START';
const LOAD_SHAPEFILES_SUCCESS = 'shapefiles/LOAD_SHAPEFILES_SUCCESS';
export const LOAD_SHAPEFILES_FAILURE = 'shapefiles/LOAD_SHAPEFILES_FAILURE';

const LOAD_SHAPEFILE_PROPERTIES_START = 'shapefiles/LOAD_SHAPEFILE_PROPERTIES_START';
export const LOAD_SHAPEFILE_PROPERTIES_SUCCESS = 'shapefiles/LOAD_SHAPEFILE_PROPERTIES_SUCCESS';
export const LOAD_SHAPEFILE_PROPERTIES_FAILURE = 'shapefiles/LOAD_SHAPEFILE_PROPERTIES_FAILURE';

const LOAD_SHAPEFILE_FEATURE_PROPERTIES_START =
    'shapefiles/LOAD_SHAPEFILE_FEATURE_PROPERTIES_START';
const LOAD_SHAPEFILE_FEATURE_PROPERTIES_SUCCESS =
    'shapefiles/LOAD_SHAPEFILE_FEATURE_PROPERTIES_SUCCESS';
const LOAD_SHAPEFILE_FEATURE_PROPERTIES_FAILURE =
    'shapefiles/LOAD_SHAPEFILE_FEATURE_PROPERTIES_FAILURE';

const CREATE_SHAPEFILE_START = 'shapefiles/CREATE_SHAPEFILE_START';
const CREATE_SHAPEFILE_SUCCESS = 'shapefiles/CREATE_SHAPEFILE_SUCCESS';
export const CREATE_SHAPEFILE_FAILURE = 'shapefiles/CREATE_SHAPEFILE_FAILURE';

const SAVE_SHAPEFILE_START = 'shapefiles/SAVE_SHAPEFILE_START';
const SAVE_SHAPEFILE_SUCCESS = 'shapefiles/SAVE_SHAPEFILE_SUCCESS';
export const SAVE_SHAPEFILE_FAILURE = 'shapefiles/SAVE_SHAPEFILE_FAILURE';

function loadShapefilesStart() {
    return {
        type: LOAD_SHAPEFILES_START,
    };
}

function loadShapefilesSuccess() {
    return {
        type: LOAD_SHAPEFILES_SUCCESS,
    };
}

function loadShapefilesFailure(err: { message?: string } = {}) {
    return {
        type: LOAD_SHAPEFILES_FAILURE,
        payload: err.message,
    };
}

function loadShapefilePropertiesStart() {
    return {
        type: LOAD_SHAPEFILE_PROPERTIES_START,
    };
}

function loadShapefilePropertiesFailure(err: { message?: string } = {}) {
    return {
        type: LOAD_SHAPEFILE_PROPERTIES_FAILURE,
        payload: err.message,
    };
}

function loadShapefilePropertiesSuccess(idOrIds: number | number[]) {
    return {
        type: LOAD_SHAPEFILE_PROPERTIES_SUCCESS,
        payload: { idOrIds },
    };
}

function loadShapefileFeaturePropertiesStart() {
    return {
        type: LOAD_SHAPEFILE_FEATURE_PROPERTIES_START,
    };
}

function loadShapefileFeaturePropertiesFailure(err: { message?: string } = {}) {
    return {
        type: LOAD_SHAPEFILE_FEATURE_PROPERTIES_FAILURE,
        payload: err.message,
    };
}

function loadShapefileFeaturePropertiesSuccess() {
    return {
        type: LOAD_SHAPEFILE_FEATURE_PROPERTIES_SUCCESS,
    };
}

function createShapefileStart() {
    return {
        type: CREATE_SHAPEFILE_START,
    };
}

function createShapefileSuccess() {
    return {
        type: CREATE_SHAPEFILE_SUCCESS,
    };
}

function createShapefileFailure(err: { message?: string } = {}) {
    return {
        type: CREATE_SHAPEFILE_FAILURE,
        payload: err.message,
    };
}

function saveShapefileStart() {
    return {
        type: SAVE_SHAPEFILE_START,
    };
}

function saveShapefileSucccess() {
    return {
        type: SAVE_SHAPEFILE_SUCCESS,
    };
}

function saveShapefileFailure(err: { message?: string } = {}) {
    return {
        type: SAVE_SHAPEFILE_FAILURE,
        payload: err.message,
    };
}

const storeShapefiles = shapefilesModule.actionCreators.storeEntities;

export const shapefilesSelector = shapefilesModule.selectors.entitiesSelector;
export const shapefileByIdSelector = shapefilesModule.selectors.entityByIdSelector;

export const shapefileOptionsSelector = createSelector(shapefilesSelector, (shapefiles) => {
    return _.map(shapefiles, (shapefile) => ({
        display: shapefile.displayName,
        value: shapefile.id,
    }));
});

export function loadShapefiles(): ClientCommonAction<Promise<Shapefile[]>> {
    const resource = getShapefilesResource();
    return (dispatch) => {
        dispatch(loadShapefilesStart());
        return resource
            .getShapefiles()
            .then((shapefiles: Shapefile[]) => {
                dispatch(storeShapefiles(shapefiles));
                dispatch(loadShapefilesSuccess());
                return shapefiles;
            })
            .catch((err: Error) => dispatch(loadShapefilesFailure(err)));
    };
}

/**
 * Shapefile properties used to be part of the shapefile object but since they
 * are dependent on ES we now have to manually request them.
 */
export function loadShapefilesProperties(
    shapefileIdOrIds: number | number[]
): ClientCommonAction<void> {
    const resource = getShapefilesResource();
    const shapefileIds = isArray(shapefileIdOrIds) ? shapefileIdOrIds : [shapefileIdOrIds];
    return (dispatch, getState) => {
        const getProperties = (shapefileId: number) =>
            resource
                .getShapefileProperties(shapefileId)
                .then((properties: string[]) => {
                    dispatch(loadShapefilePropertiesSuccess(shapefileIdOrIds));
                    return { shapefileId, properties };
                })
                .catch((err: Error) => dispatch(loadShapefilesFailure(err)));

        dispatch(loadShapefilePropertiesStart());
        return Promise.all(shapefileIds.map(getProperties))
            .then((results) => {
                const propertiesById: Record<number, string[]> = {};
                results.forEach(({ shapefileId, properties }) => {
                    propertiesById[shapefileId] = properties;
                });
                const shapefiles = shapefilesSelector(getState());
                const newShapefiles = mapValues(shapefiles, (shapefile) =>
                    propertiesById[shapefile.id]
                        ? {
                              ...shapefile,
                              properties: propertiesById[shapefile.id],
                          }
                        : shapefile
                );
                dispatch(storeShapefiles(values(newShapefiles)));
            })
            .catch((err: Error) => dispatch(loadShapefilePropertiesFailure(err)));
    };
}

/**
 * Must be called after shapefiles are loaded and stored, so feature properties
 * can be stored under their respective shapefiles.
 */
export function loadShapefileFeatureProperties(cb = noop): ClientCommonAction<Promise<void>> {
    const resource = getShapefilesResource();
    return (dispatch, getState) => {
        dispatch(loadShapefileFeaturePropertiesStart());
        return resource
            .getShapefileFeatureProperties()
            .then((shapefileFeatureProperties: ShapefileProperty[]) => {
                const sfpByShapefileId = groupBy(shapefileFeatureProperties, 'shapefileId');
                const shapefiles = shapefilesSelector(getState());
                const newShapefiles = mapValues(shapefiles, (shapefile) => ({
                    ...shapefile,
                    featureProperties: sfpByShapefileId[shapefile.id]
                        ? sfpByShapefileId[shapefile.id]
                        : [],
                }));
                dispatch(storeShapefiles(values(newShapefiles)));
                dispatch(loadShapefileFeaturePropertiesSuccess());
                cb(shapefileFeatureProperties);
            })
            .catch((err: Error) => dispatch(loadShapefileFeaturePropertiesFailure(err)));
    };
}

export function createShapefile(fileId: number): ClientCommonAction<Promise<Shapefile>> {
    const resource = getShapefilesResource();
    return (dispatch) => {
        dispatch(createShapefileStart());
        return resource
            .createShapefile(fileId)
            .then((shapefile: Shapefile) => {
                dispatch(storeShapefiles([shapefile]));
                dispatch(createShapefileSuccess());
                return shapefile;
            })
            .catch((err: Error) => dispatch(createShapefileFailure(err)));
    };
}

export function saveShapefile(shapefile: Shapefile): ClientCommonAction<Promise<void>> {
    const resource = getShapefilesResource();
    return (dispatch) => {
        dispatch(saveShapefileStart());
        return resource
            .upsertShapefile(shapefile)
            .then((shapefile: Shapefile) => {
                dispatch(storeShapefiles([shapefile]));
                dispatch(saveShapefileSucccess());
            })
            .catch((err: Error) => dispatch(saveShapefileFailure(err)));
    };
}

export default shapefilesModule.reducerConfig;
