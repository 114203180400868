import { RefContextEnum } from '@mark43/rms-api';
import { createField, createFormConfiguration, formEvents } from 'markformythree';

import * as fields from '~/client-common/core/enums/universal/fields';

export const formName = RefContextEnum.FORM_CRASH_EVENT_INFO.name;

export const trafficCrashEventInfoFormConfiguration = createFormConfiguration({
    crashEventDateUtc: createField<string>({ fieldName: fields.CRASH_DETAIL_CRASH_EVENT_DATE_UTC }),
    weatherConditionAttrIds: createField<number[]>({
        fieldName: fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_WEATHER_ATTRIBUTE_ID,
    }),
    weatherConditionOtherDescription: createField<number[]>({
        fieldName: fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_WEATHER_DESCRIPTION,
    }),
    roadConditionAttrIds: createField<number[]>({
        fieldName: fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_QC_ROADWAY_SURFACE_CONDITION_ATTRIBUTE_ID,
    }),
    roadConditionOtherDescription: createField<number[]>({
        fieldName: fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_QC_ROADWAY_SURFACE_CONDITION_DESCRIPTION,
    }),
    lightConditionAttrIds: createField<number[]>({
        fieldName: fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_QC_LIGHT_CONDITION_ATTRIBUTE_ID,
    }),
    lightConditionOtherDescription: createField<number[]>({
        fieldName: fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_QC_LIGHT_CONDITION_DESCRIPTION,
    }),
    crashSeverityAttrIds: createField<number[]>({
        fieldName: fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_QC_CRASH_SEVERITY_ATTRIBUTE_ID,
    }),
    crashSeverityOtherDescription: createField<number[]>({
        fieldName: fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_QC_CRASH_SEVERITY_DESCRIPTION,
    }),
    fixedObjectTypeAttrIds: createField<number[]>({
        fieldName: fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_CRASH_FIXED_OBJECT_TYPE_ATTRIBUTE_ID,
    }),
    fixedObjectTypeOtherDescription: createField<number[]>({
        fieldName: fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_CRASH_FIXED_OBJECT_TYPE_DESCRIPTION,
    }),
    firstHarmfulEventAttrIds: createField<number[]>({
        fieldName: fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_QC_FIRST_HARMFUL_EVENT_ATTRIBUTE_ID,
    }),
    firstHarmfulEventOtherDescription: createField<number[]>({
        fieldName: fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_QC_FIRST_HARMFUL_EVENT_DESCRIPTION,
    }),
    contributingFactorAttrIds: createField<number[]>({
        fieldName: fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_CRASH_CONTRIBUTING_FACTOR_ATTRIBUTE_ID,
    }),
    contributingFactorOtherDescription: createField<number[]>({
        fieldName: fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_CRASH_CONTRIBUTING_FACTOR_DESCRIPTION,
    }),
    municipalityCodeAttrIds: createField<number[]>({
        fieldName: fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_CRASH_MUNICIPALITY_CODE_ATTRIBUTE_ID,
    }),
    municipalityCodeOtherDescription: createField<number[]>({
        fieldName: fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_CRASH_MUNICIPALITY_CODE_DESCRIPTION,
    }),
    isTemporaryTrafficControl: createField<boolean>({
        fieldName: fields.CRASH_DETAIL_IS_TEMPORARY_TRAFFIC_CONTROL,
    }),
});

export type TrafficCrashEventInfoFormConfiguration = typeof trafficCrashEventInfoFormConfiguration;

export const validationEvents = [
    { eventType: formEvents.FORM_SUBMIT },
    { eventType: formEvents.INPUT_BLUR },
    { eventType: formEvents.INPUT_CHANGE },
];
