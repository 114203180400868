import React, { useState, useEffect, useCallback } from 'react';
import { map } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import {
    PersonProfile,
    ElasticPerson,
    EntityTypeEnum,
    EntityTypeEnumType,
    ProductModuleEnum,
    LinkTypesEnum,
} from '@mark43/rms-api';
import { Popover, PopoverContent, Divider, PopoverTrigger, cssVar } from 'arc';

import { FederatedSearchPerson } from 'mark43-federated-search';
import componentStrings from '~/client-common/core/strings/componentStrings';
import {
    formatFullName,
    formatHeightFull,
} from '~/client-common/core/domain/person-profiles/utils/personProfilesHelpers';
import { formatAttributeByIdSelector } from '~/client-common/core/domain/attributes/state/data';
import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';

import ProductModuled from '~/client-common/core/domain/product-modules/components/ProductModuled';
import {attachmentsByEntitySelector} from "~/client-common/core/domain/attachments/state/data";
import { abilitiesEnum, OnlyWithAbility } from '../../../../core/abilities';
import { FederatedSearchSidePanel } from '../../../../federated-search/components/FederatedSearchSidePanel';
import { useOverlayStore } from '../../../../core/overlays/hooks/useOverlayStore';
import { Button as _Button } from '../../../../core/components/Button';
import { SCREENS, cadPersonProfilesSelector } from '../../../../core/persons/state/data';
import { useFormGetter } from '../../../../core/forms/hooks/useFormGetter';
import { createDelimitedString, formatAge, formatDob } from '../../helpers/entityPrefillHelpers';
import {
    CAUTIONS,
    CONTACT_INFO,
    CORE_DETAILS,
    EVENT_SPECIFIC_INFO,
    MISC_INFO,
} from '../../../../core/persons/config/personProfileFormSections';
import {
    openSidePanel,
    openSearchResultsSidePanel,
    openCadSearchResultsSidePanel,
} from '../../state/ui/entityPrefill';
import useReferenceDate from '../../hooks/useReferenceDate';
import { createPersonProfileFormInitialAddState } from '../../../../core/persons/state/forms/personProfileForm';
import { NotepadLinksSidePanel } from '../../../../mobile/notepad/components/NotepadLinksSidePanel';
import { SuggestionListItemProps, SuggestionSearchList } from './SuggestionSearchList';
import PersonSearchForm, { SearchResults } from './PersonSearchForm';

const strings = componentStrings.reports.EntityPrefillPopover;

type PersonSuggestionPopoverProps = {
    buttonLabel: string;
    personOverlayId: string;
    contextType: EntityTypeEnumType;
    contextId: number;
};

const PersonSuggestionPopoverContent = styled(PopoverContent)`
    z-index: 2100;
`;

const Container = styled.div`
    padding: 8px;
    padding-bottom: 0;
    width: 375px;
    margin-bottom: ${cssVar('arc.space.2')};
`;

const Button = styled(_Button)`
    text-transform: 'none';
`;

export const PersonSuggestionPopover: React.FC<PersonSuggestionPopoverProps> = ({
    buttonLabel,
    personOverlayId,
    contextType,
    contextId,
}) => {
    const formatAttributeById = useSelector(formatAttributeByIdSelector);
    const cadPersonProfiles = useSelector(cadPersonProfilesSelector);
    const getAttachmentsByEntity = useSelector(attachmentsByEntitySelector);
    const { getForm } = useFormGetter();
    const form = getForm(formClientEnum.PERSON_SEARCH_TO_ADD_FORM);
    const dispatch = useDispatch();
    const defaultDate = new Date();
    const reportRefDate = useReferenceDate(contextType, contextId);
    const refDate = reportRefDate !== '' ? reportRefDate : defaultDate.toUTCString();
    const overlayStore = useOverlayStore();

    const hasSearchInput =
        !!form?.get('quickSearchQuery') || !!form?.get('dateOfBirthQuickSearchQuery');

    // cadPersonProfiles are PersonProfile, while rmsResults are ElasticPerson
    const getProfileText = useCallback(
        (profile: PersonProfile | ElasticPerson, referenceDate: string) => {
            const fullName = formatFullName(profile);
            const {
                dateOfBirth,
                dateOfBirthRangeStart,
                dateOfBirthRangeEnd,
                sexAttrId,
                raceAttrId,
                height,
                heightRangeMin,
                heightRangeMax,
                dlNumber,
                ssn,
                id,
            } = profile;

            const dobSexRaceHeight = createDelimitedString([
                `${formatDob(dateOfBirth, dateOfBirthRangeStart, dateOfBirthRangeEnd)}${formatAge(
                    referenceDate,
                    dateOfBirth,
                    dateOfBirthRangeStart,
                    dateOfBirthRangeEnd
                )}`,
                formatAttributeById(sexAttrId),
                formatAttributeById(raceAttrId),
                formatHeightFull({ height, heightRangeMin, heightRangeMax }),
            ]);
            const dlNumberSsn = strings.dlSsnText(dlNumber, ssn);

            let mugshotPath = '';
            if ('primaryMugshotPath' in profile) {
                mugshotPath = profile.primaryMugshotPath ?? '';
            }
            else {
                const attachments = getAttachmentsByEntity(EntityTypeEnum.PERSON_PROFILE.name, profile.id);
                const mugshot = attachments.filter(x => x.linkType === LinkTypesEnum.MUGSHOT).pop();
                const path = mugshot?.image?.originalFile?.fileWebServerPath ?? '';
                mugshotPath = path;
            }

            return {
                entityType: EntityTypeEnum.PERSON_PROFILE.name,
                avatarImgPath: mugshotPath,
                name: fullName,
                infoText: dobSexRaceHeight,
                infoText2: dlNumberSsn,
                id,
            };
        },
        [formatAttributeById, getAttachmentsByEntity]
    );

    // @ts-expect-error refDate is unknown due to MFT form get() not being typed
    const suggestedProfiles = map(cadPersonProfiles, (profile) => getProfileText(profile, refDate));

    const [searchResults, setSearchResults] = useState<SearchResults | undefined>(undefined);
    const [rmsResults, setRmsResults] = useState<SuggestionListItemProps[] | undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    useEffect(() => {
        const newResults = map(searchResults?.persons, (profile) => {
            // @ts-expect-error refDate is unknown due to MFT form get() not being typed
            return getProfileText(profile, refDate);
        });
        setRmsResults(newResults);
    }, [searchResults, getProfileText, refDate]);

    const clearSearch = () => {
        setRmsResults(undefined);
        form?.resetModel();
        form?.resetUi();
    };

    const openEditPersonSidePanel = (entityId: number) => {
        dispatch(
            openSidePanel({
                entityId,
                nameOverlayId: personOverlayId,
                screen: SCREENS.PROFILE_EDIT,
                formSectionState: {
                    [CAUTIONS]: true,
                    [CORE_DETAILS]: true,
                    [CONTACT_INFO]: true,
                    [EVENT_SPECIFIC_INFO]: true,
                    [MISC_INFO]: true,
                },
            })
        );
    };

    const openSuggestionSearchPersonSidePanel = (entityId: number) => {
        prefillSearchForm(entityId);

        dispatch(
            openCadSearchResultsSidePanel({
                cadProfileId: entityId,
                nameOverlayId: personOverlayId,
                screen: SCREENS.CAD_PROFILE_SEARCH,
                formSectionState: {
                    [CAUTIONS]: true,
                    [CORE_DETAILS]: true,
                    [CONTACT_INFO]: true,
                    [EVENT_SPECIFIC_INFO]: true,
                    [MISC_INFO]: true,
                },
            })
        );
    };

    const openViewAllPersonSidePanel = () => {
        dispatch(
            openSearchResultsSidePanel({
                nameOverlayId: personOverlayId,
                searchResultIds: !!rmsResults ? rmsResults.map((x) => x.id) : [],
                totalSearchResultCount: searchResults?.totalCount ?? 0,
            })
        );
    };

    const openAddUnknownPersonSidePanel = () => {
        dispatch(
            openSidePanel({
                nameOverlayId: personOverlayId,
                screen: SCREENS.PROFILE_ADD_UNKNOWN,
                formSectionState: {
                    [CAUTIONS]: true,
                    [CORE_DETAILS]: true,
                    [CONTACT_INFO]: true,
                    [EVENT_SPECIFIC_INFO]: true,
                    [MISC_INFO]: true,
                },
            })
        );
    };

    const openAddPersonSidePanel = () => {
        dispatch(
            openSidePanel({
                nameOverlayId: personOverlayId,
                screen: SCREENS.PROFILE_ADD_NEW,
                formSectionState: {
                    [CAUTIONS]: true,
                    [CORE_DETAILS]: true,
                    [CONTACT_INFO]: true,
                    [EVENT_SPECIFIC_INFO]: true,
                    [MISC_INFO]: true,
                },
            })
        );
    };

    const addDexPersonToReport = (dexPerson: FederatedSearchPerson) => {
        overlayStore.open(personOverlayId, {
            ownerId: contextId,
            dexPerson,
            screenStack: [
                {
                    screen: SCREENS.PROFILE_ADD_NEW,
                    screenState: {
                        currentScreen: SCREENS.PROFILE_ADD_NEW,
                    },
                },
            ],
        });
    };

    const prefillSearchForm = (entityId: number) => {
        const cadProfile = cadPersonProfiles.find((x) => x.id === entityId);
        const cadSuggestionProfile = suggestedProfiles.find((x) => x.id === entityId);

        if (cadProfile !== undefined && cadSuggestionProfile !== undefined) {
            // NOTE - CAD person profiles currently do not have address or phone # information sent/stored
            form?.set('quickSearchQuery', cadSuggestionProfile.name);
            form?.set('dateOfBirthQuickSearchQuery', cadProfile.dateOfBirth);
            form?.set('dlNumber', cadProfile.dlNumber);
            form?.set('dlStateAttrId', cadProfile.dlStateAttrId);
        }
    };

    const prefillAndCreateNewProfile = () => {
        dispatch(
            createPersonProfileFormInitialAddState({
                personType: undefined,
                nibrsOffenseCode: undefined,
            })
        );
        openAddPersonSidePanel();
    };

    return (
        <Popover hasArrow={false}>
            <PopoverTrigger asChild>
                <Button leadingVisual="Add" style={{ textTransform: 'none' }}>
                    {buttonLabel}
                </Button>
            </PopoverTrigger>
            <PersonSuggestionPopoverContent
                align="start"
                hasPadding={false}
                onOpenAutoFocus={clearSearch}
            >
                <Container>
                    <PersonSearchForm
                        setSearchResults={setSearchResults}
                        setIsLoading={setIsLoading}
                    />
                </Container>
                <SuggestionSearchList
                    suggestedProfiles={suggestedProfiles}
                    rmsResults={rmsResults}
                    onRmsResultClick={openEditPersonSidePanel}
                    onSuggestionClick={openSuggestionSearchPersonSidePanel}
                    onViewAllClick={openViewAllPersonSidePanel}
                    isLoading={isLoading}
                />
                <Divider style={{ width: '100%' }} />
                {hasSearchInput ? (
                    <Button
                        variant="ghost"
                        style={{ textTransform: 'none', margin: 8 }}
                        onClick={prefillAndCreateNewProfile}
                    >
                        {strings.createNewProfile}
                    </Button>
                ) : (
                    <div style={{ display: 'flex' }}>
                        <Button
                            variant="ghost"
                            style={{ textTransform: 'none', margin: 8 }}
                            onClick={openAddUnknownPersonSidePanel}
                        >
                            {strings.addUnknown}
                        </Button>
                        <NotepadLinksSidePanel
                            id={overlayIdEnum.NOTEPAD_LINKS_OVERLAY_PERSON_OVERLAY}
                            entityType={EntityTypeEnum.PERSON_PROFILE.name}
                            onEntityClick={({ id }) => openEditPersonSidePanel(id)}
                            renderButton={({ overlayBase: { open } }) => (
                                <Button
                                    variant="ghost"
                                    style={{ textTransform: 'none', margin: 8 }}
                                    onClick={open}
                                >
                                    {strings.viewMobileCollections}
                                </Button>
                            )}
                        />
                    </div>
                )}
                <ProductModuled productModule={ProductModuleEnum.RMS_DEX.name}>
                    <OnlyWithAbility has={abilitiesEnum.RMS_DEX.RMS_DEX_MAKE_INQUIRIES}>
                        <OnlyWithAbility has={abilitiesEnum.RMS_DEX.RMS_DEX_VIEW_RESULTS}>
                            <FederatedSearchSidePanel
                                addDexPersonToReport={addDexPersonToReport}
                                renderButton={() => (
                                    <Button
                                        variant="ghost"
                                        style={{ textTransform: 'none', margin: 8 }}
                                        leadingVisual="DataExchangeInquiries"
                                        onClick={() =>
                                            overlayStore.open(overlayIdEnum.DEX_SIDE_PANEL)
                                        }
                                    >
                                        {strings.addFromDexQuery}
                                    </Button>
                                )}
                            />
                        </OnlyWithAbility>
                    </OnlyWithAbility>
                </ProductModuled>
            </PersonSuggestionPopoverContent>
        </Popover>
    );
};
