import React, { useState, ReactNode, useMemo } from 'react';
import {
    EntityTypeEnumType,
    ClientApprovalStatusEnumType,
    EntityTypeEnum,
    HistoryEventTypeEnum,
} from '@mark43/rms-api';

import styled from 'styled-components';
import { withRouter, WithRouterProps } from 'react-router';
import { useSelector } from 'react-redux';
import { IconNameT } from 'arc';
import approvalStatusClientEnum from '~/client-common/core/enums/client/approvalStatusClientEnum';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';

import withFields from '~/client-common/core/fields/components/withFields';
import type { HistoryEventViewModel } from '~/client-common/core/domain/history-events/utils/historyEventHelpers';
import {
    DISPLAY_ONLY_REPORT_APPROVAL_STATUS_DRAFT,
    DISPLAY_ONLY_REPORT_APPROVAL_STATUS_REJECTED,
    DISPLAY_ONLY_REPORT_APPROVAL_STATUS_PENDING_SUPERVISOR_REVIEW,
    DISPLAY_ONLY_REPORT_APPROVAL_STATUS_PENDING_SECONDARY_REVIEW,
    DISPLAY_ONLY_REPORT_APPROVAL_STATUS_COMPLETED,
} from '~/client-common/core/enums/universal/fields';
import HistoryEventCategoryEnum from '~/client-common/core/enums/client/historyEventCategoryEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { FormattedDate } from '~/client-common/core/dates/components';
import {
    FormatFieldByName,
    formatFieldByNameSelector,
} from '~/client-common/core/fields/state/config';

import { convertReportApprovalStatusToArcIconName } from '../../../records/core/components/ApprovalStatusIcon';
import Select from '../../forms/components/selects/Select';
import { LegacySelectOption, LegacySelectOptions } from '../../forms/helpers/selectHelpers';
import { CustomLink } from '../../components/links/Link';
import { getReportSnapshotRoute } from '../helpers/historyHelpers';
import { ALL_EVENTS_OPTION, HistoryType, VehicleHistoryOptionsType } from '../config';

import HistoryEvent from './HistoryEvent';

const strings = componentStrings.reports.core.History;

const Wrapper = styled.div`
    margin-top: 44px;
    max-width: ${(props) => props.theme.widths.container}px;
`;

const HistoryTitle = styled.div`
    font-family: ${(props) => props.theme.fontFamilies.proximaNova};
    font-size: var(--arc-fontSizes-md);
    color: ${(props) => props.theme.colors.mediumGrey};
    border-bottom: 3px solid ${(props) => props.theme.colors.mediumGrey};
`;

const HistoryBucket = styled.div`
    clear: both;
    margin-left: 30px;
    font-size: var(--arc-fontSizes-md);
    float: left;
    margin-top: 20px;
    padding-left: 25px;
    border-left: 4px solid ${(props) => props.theme.colors.lightGrey};
`;

const FilterTimelineSelect = styled(Select)`
    margin-top: 20px;
` as typeof Select;

const NoEventsText = styled.p`
    width: 100%;
    font-size: var(--arc-fontSizes-sm);
    float: left;
`;

const SemiBold = styled.span`
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
`;

const showSecondaryName = (primaryType: EntityTypeEnumType) =>
    primaryType === EntityTypeEnum.DISPOSITION_EVENT.name ||
    primaryType === EntityTypeEnum.CHAIN_EVENT.name ||
    primaryType === EntityTypeEnum.EVIDENCE_STAFF_REMARK.name ||
    primaryType === EntityTypeEnum.ITEM_PROFILE.name ||
    primaryType === EntityTypeEnum.CONFIGURED_ENTITY_INSTANCE.name;

const ReportStatusContent = withFields([
    DISPLAY_ONLY_REPORT_APPROVAL_STATUS_DRAFT,
    DISPLAY_ONLY_REPORT_APPROVAL_STATUS_REJECTED,
    DISPLAY_ONLY_REPORT_APPROVAL_STATUS_PENDING_SUPERVISOR_REVIEW,
    DISPLAY_ONLY_REPORT_APPROVAL_STATUS_PENDING_SECONDARY_REVIEW,
    DISPLAY_ONLY_REPORT_APPROVAL_STATUS_COMPLETED,
])(
    ({
        clientApprovalStatus,
        user,
        fieldDisplayNames,
    }: {
        clientApprovalStatus?: ClientApprovalStatusEnumType;
        user?: string | ReactNode;
        fieldDisplayNames: Record<string, string>;
    }) => {
        const {
            DISPLAY_ONLY_REPORT_APPROVAL_STATUS_DRAFT,
            DISPLAY_ONLY_REPORT_APPROVAL_STATUS_REJECTED,
            DISPLAY_ONLY_REPORT_APPROVAL_STATUS_PENDING_SUPERVISOR_REVIEW,
            DISPLAY_ONLY_REPORT_APPROVAL_STATUS_PENDING_SECONDARY_REVIEW,
            DISPLAY_ONLY_REPORT_APPROVAL_STATUS_COMPLETED,
        } = fieldDisplayNames;

        let status: string;
        switch (clientApprovalStatus) {
            case approvalStatusClientEnum.DRAFT:
                status = DISPLAY_ONLY_REPORT_APPROVAL_STATUS_DRAFT;
                break;
            case approvalStatusClientEnum.REJECTED:
                status = DISPLAY_ONLY_REPORT_APPROVAL_STATUS_REJECTED;
                break;
            case approvalStatusClientEnum.PENDING_SUPERVISOR_REVIEW:
                status = DISPLAY_ONLY_REPORT_APPROVAL_STATUS_PENDING_SUPERVISOR_REVIEW;
                break;
            case approvalStatusClientEnum.PENDING_SECONDARY_REVIEW:
                status = DISPLAY_ONLY_REPORT_APPROVAL_STATUS_PENDING_SECONDARY_REVIEW;
                break;
            case approvalStatusClientEnum.COMPLETED:
                status = DISPLAY_ONLY_REPORT_APPROVAL_STATUS_COMPLETED;
                break;
            default:
                status = DISPLAY_ONLY_REPORT_APPROVAL_STATUS_DRAFT;
        }

        return (
            <div>
                <SemiBold>{user}</SemiBold> {`changed the report status to ${status.toLowerCase()}`}
            </div>
        );
    }
);

const FILTER_OPTIONS = {
    [HistoryType.DEFAULT]: [
        {
            display: strings.categoryTypes.allEvents,
            value: ALL_EVENTS_OPTION,
        },
        {
            display: strings.categoryTypes.reportContent,
            value: HistoryEventCategoryEnum.CONTENT,
        },
        {
            display: strings.categoryTypes.workflow,
            value: HistoryEventCategoryEnum.WORKFLOW,
        },
    ],
    [HistoryType.CASE]: [
        {
            display: strings.categoryTypes.allEvents,
            value: ALL_EVENTS_OPTION,
        },
        {
            display: strings.categoryTypes.reportContent,
            value: HistoryEventCategoryEnum.CONTENT,
        },
        {
            display: strings.categoryTypes.workflow,
            value: HistoryEventCategoryEnum.WORKFLOW,
        },
        {
            display: strings.categoryTypes.folderChanges,
            value: HistoryEventCategoryEnum.FOLDERS,
        },
    ],
    [HistoryType.VEHICLE]: [
        {
            display: strings.categoryTypes.allEvents,
            value: ALL_EVENTS_OPTION,
        },
        {
            display: strings.categoryTypes.anpr,
            value: VehicleHistoryOptionsType.ANPR,
        },
        {
            display: strings.categoryTypes.registrationChange,
            value: VehicleHistoryOptionsType.REGISTRATION_CHANGE,
        },
        {
            display: strings.categoryTypes.reportActivity,
            value: VehicleHistoryOptionsType.REPORT_ACTIVITY,
        },
        {
            display: strings.categoryTypes.otherActivity,
            value: VehicleHistoryOptionsType.OTHER_ACTIVITY,
        },
    ],
};

export type HistoryEventCategoryValue =
    | keyof typeof HistoryEventCategoryEnum
    | keyof typeof VehicleHistoryOptionsType
    | typeof ALL_EVENTS_OPTION;

const History: React.FC<
    {
        showFilter?: boolean;
        className?: string;
        historyEventViewModels: HistoryEventViewModel[];
        historyType?: HistoryType;
        customFilter?: (props: {
            item: HistoryEventViewModel;
            selectedValue: HistoryEventCategoryValue;
            formatFieldByName: FormatFieldByName;
        }) => boolean;
    } & WithRouterProps
> = ({
    showFilter,
    className,
    historyEventViewModels,
    router,
    historyType = HistoryType.DEFAULT,
    customFilter,
}) => {
    const applicationSettings = useSelector(applicationSettingsSelector);
    const formatFieldByName = useSelector(formatFieldByNameSelector);

    const [value, setValue] = useState<HistoryEventCategoryValue>(ALL_EVENTS_OPTION);

    let historyEventViewModelsFiltered = historyEventViewModels;

    if (showFilter) {
        historyEventViewModelsFiltered = customFilter
            ? historyEventViewModelsFiltered.filter((item) =>
                  customFilter({ item, selectedValue: value, formatFieldByName })
              )
            : historyEventViewModelsFiltered.filter(({ historyEventCategory }) => {
                  if (value === ALL_EVENTS_OPTION) {
                      return true;
                  }
                  return historyEventCategory === value;
              });
    }

    const options: LegacySelectOptions<LegacySelectOption> = useMemo(() => {
        switch (historyType) {
            case HistoryType.CASE:
                return applicationSettings.RMS_CASE_FOLDERING_ENABLED
                    ? FILTER_OPTIONS[HistoryType.CASE]
                    : FILTER_OPTIONS[HistoryType.DEFAULT];
            case HistoryType.VEHICLE:
                return FILTER_OPTIONS[historyType].filter(
                    (item) =>
                        item.value !== VehicleHistoryOptionsType.ANPR ||
                        applicationSettings.RMS_ANPR_IMPROVEMENTS_ENABLED
                );
            default:
                return FILTER_OPTIONS[historyType];
        }
    }, [
        applicationSettings.RMS_ANPR_IMPROVEMENTS_ENABLED,
        applicationSettings.RMS_CASE_FOLDERING_ENABLED,
        historyType,
    ]);

    return (
        <Wrapper className={className}>
            <HistoryTitle>
                <SemiBold>HISTORY</SemiBold>
            </HistoryTitle>
            {showFilter && (
                <FilterTimelineSelect
                    label={strings.categoryTypeSelectLabel}
                    value={value}
                    clearable={false}
                    width="auto"
                    // TODO: Update Select to accept a generic type which represents the type of its value, and remove this type assertion
                    onChange={(value) => setValue(value as HistoryEventCategoryValue)}
                    options={options}
                />
            )}
            {value !== ALL_EVENTS_OPTION && historyEventViewModelsFiltered.length === 0 && (
                <NoEventsText>{strings.noEvents}</NoEventsText>
            )}
            <HistoryBucket>
                {historyEventViewModelsFiltered.map((historyEventViewModel) => {
                    const {
                        user,
                        subject,
                        timestampUtc,
                        historyEventType,
                        subject2,
                        linkTypeName,
                        primaryName,
                        historyEventCategory,
                        reasonForDeletion,
                        secondaryName,
                        clientApprovalStatus,
                    } = historyEventViewModel;

                    let iconType: IconNameT;
                    let reverseIcon: boolean | undefined;
                    let message: ReactNode;
                    let showDetails = false;
                    let subBody: string | undefined;
                    let includeSecondaryName = false;
                    const date = (
                        <FormattedDate
                            date={timestampUtc}
                            format={FormattedDate.FORMATS.SUMMARY_DATE_TIME}
                        />
                    );

                    const isPrimaryTypeCaseReportLink =
                        historyEventViewModel.primaryType === EntityTypeEnum.CASE_REPORT_LINK.name;

                    switch (historyEventType) {
                        case HistoryEventTypeEnum.CHILD_PROFILE_CREATION.name:
                            iconType = 'Report';
                            showDetails = true;
                            message = (
                                <div>
                                    <SemiBold>{user}</SemiBold> added{' '}
                                    <SemiBold>{primaryName}</SemiBold> to{' '}
                                    <SemiBold>{secondaryName}</SemiBold>
                                </div>
                            );
                            break;
                        case HistoryEventTypeEnum.CHILD_PROFILE_DELETE.name:
                            iconType = 'Report';
                            message = (
                                <div>
                                    <SemiBold>{user}</SemiBold> removed{' '}
                                    <SemiBold>{primaryName}</SemiBold> from{' '}
                                    <SemiBold>{secondaryName}</SemiBold>
                                </div>
                            );
                            subBody = reasonForDeletion;
                            break;
                        case HistoryEventTypeEnum.ASSOCIATED_RECORD_CHANGED.name:
                            iconType = 'Report';
                            showDetails = true;
                            message = (
                                <div>
                                    <SemiBold>{user}</SemiBold> changed Associated Record details of{' '}
                                    {subject && <SemiBold>{subject}</SemiBold>}
                                </div>
                            );
                            break;
                        case HistoryEventTypeEnum.ENTITY_PERMISSION_CHANGED.name:
                            iconType = 'Permissions';
                            showDetails = true;
                            message = (
                                <div>
                                    <SemiBold>{user}</SemiBold> updated permissions for{' '}
                                    {subject && <SemiBold>{subject}</SemiBold>}
                                    {linkTypeName && (
                                        <span>
                                            {' '}
                                            on <SemiBold>{linkTypeName}</SemiBold>
                                        </span>
                                    )}
                                </div>
                            );
                            break;
                        case HistoryEventTypeEnum.FIELDS_CHANGED.name:
                            iconType = 'Report';
                            showDetails = true;
                            includeSecondaryName = showSecondaryName(
                                historyEventViewModel.primaryType
                            );
                            message = (
                                <div>
                                    <SemiBold>{user}</SemiBold> changed{' '}
                                    {subject && <SemiBold>{subject}</SemiBold>}
                                    {includeSecondaryName && secondaryName && (
                                        <span>
                                            {' '}
                                            on <SemiBold>{secondaryName}</SemiBold>
                                        </span>
                                    )}
                                </div>
                            );
                            break;
                        case HistoryEventTypeEnum.SEALING_ACTION.name:
                            iconType = 'Permissions';
                            showDetails = false;
                            message = (
                                <div>
                                    <SemiBold>{user}</SemiBold>
                                    {historyEventViewModel.changes.length > 0 &&
                                    historyEventViewModel.changes[0].newValue
                                        ? strings.privacy.sealing
                                        : strings.privacy.unsealing}
                                </div>
                            );
                            break;
                        case HistoryEventTypeEnum.VACATING_ACTION.name:
                            iconType = 'Permissions';
                            showDetails = false;
                            message = (
                                <div>
                                    <SemiBold>{user}</SemiBold>
                                    {historyEventViewModel.changes.length > 0 &&
                                    historyEventViewModel.changes[0].newValue
                                        ? strings.privacy.vacate
                                        : strings.privacy.unvacate}
                                </div>
                            );
                            break;
                        case HistoryEventTypeEnum.REPORT_STATUS.name:
                            reverseIcon = true;

                            iconType = convertReportApprovalStatusToArcIconName(
                                clientApprovalStatus
                            );

                            message = (
                                <ReportStatusContent
                                    user={user}
                                    clientApprovalStatus={clientApprovalStatus}
                                />
                            );

                            if (historyEventViewModel.primaryType === EntityTypeEnum.REPORT.name) {
                                message = (
                                    <>
                                        {message}{' '}
                                        <CustomLink
                                            onClick={() => {
                                                router.push(
                                                    getReportSnapshotRoute({
                                                        reportId: historyEventViewModel.primaryId,
                                                        rmsEventId: historyEventViewModel.id,
                                                    })
                                                );
                                                // force refresh the page to clear out current report data from state
                                                window.location.reload();
                                            }}
                                        >
                                            {strings.viewSnapshot}
                                        </CustomLink>
                                    </>
                                );
                            }
                            break;
                        case HistoryEventTypeEnum.ASSOCIATED_RECORD_CREATED.name:
                            message = (
                                <div>
                                    <SemiBold>{user}</SemiBold> added{' '}
                                    {subject && <SemiBold>{subject}</SemiBold>} as an Associated
                                    Record
                                </div>
                            );
                            iconType = 'Report';
                            break;
                        case HistoryEventTypeEnum.ENTITY_PERMISSION_CREATED.name:
                            message = (
                                <div>
                                    <SemiBold>{user}</SemiBold> granted{' '}
                                    {secondaryName && <SemiBold>{secondaryName}</SemiBold>}{' '}
                                    permission to {subject && <SemiBold>{subject}</SemiBold>}
                                    {linkTypeName && (
                                        <span>
                                            {' '}
                                            on <SemiBold>{linkTypeName}</SemiBold>
                                        </span>
                                    )}
                                </div>
                            );
                            iconType = 'Permissions';
                            break;
                        case HistoryEventTypeEnum.ENTITY_CREATION.name:
                            iconType = 'Report';
                            if (
                                historyEventViewModel.primaryType ===
                                EntityTypeEnum.EVIDENCE_STAFF_REMARK.name
                            ) {
                                iconType = 'Note';
                                showDetails = true;
                            }
                            includeSecondaryName = showSecondaryName(
                                historyEventViewModel.primaryType
                            );

                            if (historyEventViewModel.directionalPrefix) {
                                message = (
                                    <div>
                                        <SemiBold>{user}</SemiBold> added{' '}
                                        {subject && <SemiBold>{subject}</SemiBold>} to{' '}
                                        {historyEventViewModel.subject2 && (
                                            <SemiBold>{historyEventViewModel.subject2}</SemiBold>
                                        )}
                                        {includeSecondaryName && secondaryName && (
                                            <span>
                                                {' '}
                                                on <SemiBold>{secondaryName}</SemiBold>
                                            </span>
                                        )}
                                    </div>
                                );
                            } else {
                                message = (
                                    <div>
                                        <SemiBold>{user}</SemiBold> added{' '}
                                        {subject && <SemiBold>{subject}</SemiBold>}
                                        {includeSecondaryName && secondaryName && (
                                            <span>
                                                {' '}
                                                on <SemiBold>{secondaryName}</SemiBold>
                                            </span>
                                        )}
                                    </div>
                                );
                            }
                            break;
                        case HistoryEventTypeEnum.ASSOCIATED_RECORD_DELETED.name:
                            message = (
                                <div>
                                    <SemiBold>{user}</SemiBold> removed{' '}
                                    {subject && <SemiBold>{subject}</SemiBold>} as an Associated
                                    Record
                                </div>
                            );
                            iconType = 'Report';
                            break;
                        case HistoryEventTypeEnum.ENTITY_PERMISSION_DELETED.name:
                            message = (
                                <div>
                                    <SemiBold>{user}</SemiBold> removed{' '}
                                    <SemiBold>{historyEventViewModel.secondaryName}</SemiBold>{' '}
                                    permission from {subject && <SemiBold>{subject}</SemiBold>}
                                    {linkTypeName && (
                                        <span>
                                            {' '}
                                            on <SemiBold>{linkTypeName}</SemiBold>
                                        </span>
                                    )}
                                </div>
                            );
                            iconType = 'Permissions';
                            break;
                        case HistoryEventTypeEnum.ENTITY_DELETE.name:
                            iconType = 'Report';
                            if (
                                historyEventViewModel.primaryType ===
                                EntityTypeEnum.EVIDENCE_STAFF_REMARK.name
                            ) {
                                iconType = 'Note';
                                showDetails = true;
                            }
                            includeSecondaryName = showSecondaryName(
                                historyEventViewModel.primaryType
                            );

                            if (historyEventViewModel.directionalPrefix) {
                                message = (
                                    <div>
                                        {user && <SemiBold>{user}</SemiBold>} removed{' '}
                                        {subject && <SemiBold>{subject}</SemiBold>} from{' '}
                                        {subject2 && <SemiBold>{subject2}</SemiBold>}
                                        {includeSecondaryName && secondaryName && (
                                            <span>
                                                {' '}
                                                on <SemiBold>{secondaryName}</SemiBold>
                                            </span>
                                        )}
                                    </div>
                                );
                            } else {
                                message = (
                                    <div>
                                        <SemiBold>{user}</SemiBold> removed{' '}
                                        {subject && <SemiBold>{subject}</SemiBold>}
                                        {includeSecondaryName && secondaryName && (
                                            <span>
                                                {' '}
                                                on <SemiBold>{secondaryName}</SemiBold>
                                            </span>
                                        )}
                                    </div>
                                );
                            }
                            subBody = reasonForDeletion;
                            break;
                        case HistoryEventTypeEnum.LINK_CREATION.name:
                            if (isPrimaryTypeCaseReportLink) {
                                showDetails = historyEventViewModel.changes.length > 0;
                            }
                            iconType = 'Link';
                            message = (
                                <div>
                                    <SemiBold>{user}</SemiBold> added{' '}
                                    {subject2 && <SemiBold>{subject2}</SemiBold>} to{' '}
                                    {subject && <SemiBold>{subject}</SemiBold>}
                                    {strings.displayLinkAsText(isPrimaryTypeCaseReportLink)}
                                    <SemiBold>{linkTypeName}</SemiBold>
                                </div>
                            );
                            break;
                        case HistoryEventTypeEnum.LINK_DELETE.name:
                            if (isPrimaryTypeCaseReportLink) {
                                showDetails = historyEventViewModel.changes.length > 0;
                            }
                            iconType = 'Link';
                            message = (
                                <div>
                                    <SemiBold>{user}</SemiBold> removed the link between{' '}
                                    {subject && <SemiBold>{subject}</SemiBold>} to{' '}
                                    {subject2 && <SemiBold>{subject2}</SemiBold>}
                                    {strings.displayLinkAsText(isPrimaryTypeCaseReportLink)}
                                    <SemiBold>{linkTypeName}</SemiBold>
                                </div>
                            );
                            break;
                        case HistoryEventTypeEnum.ATTRIBUTE_LINK_CREATION.name:
                            iconType = 'Report';
                            showDetails = true;
                            message = (
                                <div>
                                    <SemiBold>{user}</SemiBold>{' '}
                                    {historyEventViewModel.changeSet.length > 0
                                        ? 'added attributes to'
                                        : 'added attribute to'}{' '}
                                    {subject && <SemiBold>{subject}</SemiBold>}
                                </div>
                            );
                            break;
                        case HistoryEventTypeEnum.ATTRIBUTE_LINK_DELETION.name:
                            iconType = 'Report';
                            showDetails = true;
                            message = (
                                <div>
                                    <SemiBold>{user}</SemiBold>{' '}
                                    {historyEventViewModel.changeSet.length > 0
                                        ? 'removed attributes from'
                                        : 'removed attribute from'}{' '}
                                    {subject && <SemiBold>{subject}</SemiBold>}
                                </div>
                            );
                            break;
                        case HistoryEventTypeEnum.LABEL_CREATION.name:
                            iconType = 'Label';
                            showDetails = true;
                            message = (
                                <div>
                                    <SemiBold>{user}</SemiBold> added <SemiBold>Labels</SemiBold> to{' '}
                                    <SemiBold>{primaryName}</SemiBold>
                                </div>
                            );
                            break;
                        case HistoryEventTypeEnum.LABEL_DELETION.name:
                            iconType = 'Label';
                            showDetails = true;
                            message = (
                                <div>
                                    <SemiBold>{user}</SemiBold> removed <SemiBold>Labels</SemiBold>{' '}
                                    from <SemiBold>{primaryName}</SemiBold>
                                </div>
                            );
                            break;
                        case HistoryEventTypeEnum.OWNER_CHANGED.name:
                            iconType = 'Edit';
                            if (historyEventViewModel.changes.length > 0) {
                                message = (
                                    <div>
                                        <SemiBold>{user}</SemiBold> changed the{' '}
                                        <SemiBold>Report Owner</SemiBold> from{' '}
                                        {historyEventViewModel.changes[0].diffSet && (
                                            <>
                                                <SemiBold>
                                                    {
                                                        historyEventViewModel.changes[0].diffSet[0]
                                                            .value
                                                    }
                                                </SemiBold>{' '}
                                                to{' '}
                                                <SemiBold>
                                                    {
                                                        historyEventViewModel.changes[0].diffSet[1]
                                                            .value
                                                    }
                                                </SemiBold>
                                            </>
                                        )}
                                    </div>
                                );
                            }
                            break;
                        case HistoryEventTypeEnum.ENTITY_MERGE.name:
                            iconType = 'Merge';
                            showDetails = false;
                            message = (
                                <div>
                                    <SemiBold>{user}</SemiBold> merged vehicle with ID{' '}
                                    <SemiBold>{secondaryName}</SemiBold> to this profile
                                </div>
                            );
                            break;

                        default:
                            return <div />;
                    }
                    return (
                        <HistoryEvent
                            reverseIcon={reverseIcon}
                            paper={historyEventCategory === HistoryEventCategoryEnum.CONTENT}
                            key={historyEventViewModel.id}
                            iconType={iconType}
                            message={message}
                            date={date}
                            showDetails={showDetails}
                            changes={historyEventViewModel.changes}
                            subBody={subBody}
                        />
                    );
                })}
            </HistoryBucket>
        </Wrapper>
    );
};

export default withRouter(History);
