import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import { SearchName } from '../../../search/core/components/SearchName';
import Column from '../../../core/components/Column';
import CasesSearchForm from '../../core/components/CasesSearchForm';
import { allCasesSearch, resetAllCasesSearchForm, updateCasesTabCounts } from '../state/ui';
import TargetProfileSearchFieldset from '../../target-profile/components/TargetProfileSearchFieldset';
import AllCasesSearchPersonnelFieldset from './AllCasesSearchPersonnelFieldset';
import AllCasesSearchCaseInformationFieldset from './AllCasesSearchCaseInformationFieldset';

function AllCasesSearchForm({ currentSavedSearch, onSubmit, onReset, query }) {
    return (
        <CasesSearchForm
            {...allCasesSearch.form}
            onSubmit={onSubmit}
            onReset={onReset}
            query={query}
            searchModule={allCasesSearch}
        >
            <SearchName currentSavedSearch={currentSavedSearch} isAdvancedSearch={false} />
            <Column width={270}>
                <AllCasesSearchPersonnelFieldset />
            </Column>
            <Column width={620} marginRight={0}>
                <AllCasesSearchCaseInformationFieldset />
            </Column>
            <FeatureFlagged flag="RMS_TARGET_PROFILES_ENABLED">
                <Column width={620} marginRight={0}>
                    <TargetProfileSearchFieldset />
                </Column>
            </FeatureFlagged>
        </CasesSearchForm>
    );
}

const mapStateToProps = createStructuredSelector({
    currentSavedSearch: allCasesSearch.selectors.currentSavedSearchSelector,
    query: allCasesSearch.selectors.currentQuerySelector,
});

const mapDispatchToProps = (dispatch) => ({
    onSubmit: () =>
        dispatch(
            allCasesSearch.form.actionCreators.submit((formData) => {
                const searchResult = dispatch(
                    allCasesSearch.actionCreators.search(
                        {
                            formData,
                            from: 0,
                        },
                        { cacheBust: true }
                    )
                );
                dispatch(updateCasesTabCounts(formData));
                return searchResult;
            })
        ),
    onReset: () => {
        dispatch(resetAllCasesSearchForm());
        dispatch(allCasesSearch.actionCreators.setIsSavedSearchUpdatable(false));
        dispatch(allCasesSearch.actionCreators.setExecutedSavedSearchToUpdate(null));
    },
});

/**
 * Search/filter form on the All Cases page. In the UI, this form appears
 *   when the filter button is clicked.
 */
export default connect(mapStateToProps, mapDispatchToProps)(AllCasesSearchForm);
