import fieldTypeClientEnum from '~/client-common/core/enums/client/fieldTypeClientEnum';
import rangeFieldKeyEnum from '~/client-common/core/enums/client/rangeFieldKeyEnum';
import rangeFieldTypeEnum from '~/client-common/core/enums/client/rangeFieldTypeEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import {
    CASE_CASE_DEFINITION_ID,
    DISPLAY_ONLY_CASE_TITLE_LABEL,
    DISPLAY_ONLY_CASE_SEARCH_CASE_APPROVAL_STATUS_LABEL,
    DISPLAY_ONLY_CASE_SEARCH_CASE_STATUS_LABEL,
    DISPLAY_ONLY_CASE_SEARCH_DATE_TIME_RANGE_LABEL,
    DISPLAY_ONLY_CASE_SEARCH_IS_OVERDUE_LABEL,
    DISPLAY_ONLY_CASE_SEARCH_LABELS_LABEL,
    DISPLAY_ONLY_DATE_TYPE_LABEL,
    OFFENSE_OFFENSE_CODE_ID,
} from '~/client-common/core/enums/universal/fields';

import { buildFlatFormFieldViewModels } from '../../../../../legacy-redux/helpers/formHelpers';
import { subdivisionAttrFieldsForViewModels } from '../../../../search/core/state/forms/subdivisionFields';

const {
    FIELDSET,
    ATTRIBUTE,
    CASE_DEFINITION,
    DATE_PRESET,
    RANGE,
    OFFENSE_CODE,
    LABEL,
} = fieldTypeClientEnum;
const { DATE_TIME_RANGE } = rangeFieldKeyEnum;
const { RANGE_START, RANGE_END, WITHIN_LAST_PERIOD, TO_DATE_PERIOD } = rangeFieldTypeEnum;
const strings = componentStrings.cases.allCases.AllCasesSearchCaseInformationFieldset;

/**
 * Fieldset view model.
 */
export default {
    type: FIELDSET,
    key: 'caseInformation',
    title: strings.title,
    fields: buildFlatFormFieldViewModels([
        {
            key: 'dateType',
            type: DATE_PRESET,
            fieldName: DISPLAY_ONLY_DATE_TYPE_LABEL,
        },
        {
            key: 'withinLastPeriod',
            type: RANGE,
            rangeKey: DATE_TIME_RANGE,
            rangeType: WITHIN_LAST_PERIOD,
        },
        {
            key: 'toDatePeriod',
            type: RANGE,
            rangeKey: DATE_TIME_RANGE,
            rangeType: TO_DATE_PERIOD,
        },
        {
            key: 'startDateUtc',
            type: RANGE,
            rangeKey: DATE_TIME_RANGE,
            rangeType: RANGE_START,
            fieldName: DISPLAY_ONLY_CASE_SEARCH_DATE_TIME_RANGE_LABEL,
        },
        {
            key: 'endDateUtc',
            type: RANGE,
            rangeKey: DATE_TIME_RANGE,
            rangeType: RANGE_END,
        },
        {
            key: 'isOverdue',
            fieldName: DISPLAY_ONLY_CASE_SEARCH_IS_OVERDUE_LABEL,
        },
        {
            key: 'caseDefinitionId',
            type: CASE_DEFINITION,
            fieldName: CASE_CASE_DEFINITION_ID,
        },
        { key: 'reportingEventNumber' }, // label uses fieldConfigs
        { key: 'reportIdentifier', label: '' }, // label uses fieldConfigs
        {
            key: 'offenseCodeIds',
            type: OFFENSE_CODE,
            fieldName: OFFENSE_OFFENSE_CODE_ID,
        },
        {
            key: 'approvalStatuses',
            fieldName: DISPLAY_ONLY_CASE_SEARCH_CASE_APPROVAL_STATUS_LABEL,
        },
        { key: 'subdivisionAttrIds', type: ATTRIBUTE },
        ...subdivisionAttrFieldsForViewModels,
        {
            key: 'caseStatusAttrIds',
            type: ATTRIBUTE,
            fieldName: DISPLAY_ONLY_CASE_SEARCH_CASE_STATUS_LABEL,
        },
        // This field is not represented by a form field in the ui, but instead
        // by tabs. It's value is changed when a tab is clicked
        { key: 'currentStatusAttrIds' },
        {
            key: 'routingLabelAttrIds',
            type: ATTRIBUTE,
            fieldName: DISPLAY_ONLY_CASE_SEARCH_LABELS_LABEL,
        },
        {
            key: 'hasRoutingLabel',
            type: LABEL,
        },
        { key: 'caseNumber', label: '' }, // label uses configurable field
        {
            key: 'caseTitle',
            type: ATTRIBUTE,
            fieldName: DISPLAY_ONLY_CASE_TITLE_LABEL,
        },
    ]),
};
