import React from 'react';
import { useSelector } from 'react-redux';
import { compact, map } from 'lodash';
import styled from 'styled-components';
import { mediaQueries } from 'arc';
import { EntityTypeEnum } from '@mark43/rms-api';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import componentStrings from '~/client-common/core/strings/componentStrings';
import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';
import { cadTicketsViewModelWhereSelector } from '~/client-common/core/domain/cad-tickets/state/ui';
import { currentUserHasAbilitySelector } from '../../../core/current-user/state/ui';
import { Tab, Tabs, TabList, TabPanel } from '../../../core/components/Tabs';
import ReportSidebar from '../../../../legacy-redux/components/reports/ReportSidebar';
import RecordsSidebar from '../../../records/core/components/sidebar/RecordsSidebar';
import LinkedTasksSection from '../../../records/core/components/sidebar/LinkedTasksSection';
import EntitySubmissionMessages from '../../../records/core/components/sidebar/EntitySubmissionMessages';
import testIds from '../../../../core/testIds';
import { InvolvedProfilesSidebar } from './InvolvedProfilesSidebar';
import EventInfo from './EventInfo';

const strings = componentStrings.reports.core.EntitySidebar;

// Overriding existing CSS
const StyledRecordsSidebar = styled(RecordsSidebar)`
    justify-content: stretch;
    padding: 0 var(--arc-space-2);
    height: unset;
    flex: 1;
    margin-left: 0;
    width: 100%;

    @media (min-width: ${mediaQueries.md}) {
        max-width: 356px;
        position: sticky;
        top: 0;
        padding: 0;
    }

    .report-sidebar {
        display: flex;
        margin-left: 0;
        width: 100%;
    }

    && .report-sidebar-content {
        display: flex;
        margin-top: 15px;
        width: 100%;
    }
`;

export const EntitySidebar = ({ router, disabled, reportId, reportingEventNumber }) => {
    const [activeTab, setActiveTab] = React.useState(0);
    const applicationSettings = useSelector(applicationSettingsSelector);
    const currentUserHasAbility = useSelector(currentUserHasAbilitySelector);
    const cadTicketsViewModelWhere = useSelector(cadTicketsViewModelWhereSelector);
    const cadTicketViewModels = cadTicketsViewModelWhere(reportingEventNumber, null);

    const tabConfig = compact([
        {
            title: strings.about,
            testId: testIds.REPORT_SIDEBAR_ABOUT_TAB,
            content: <ReportSidebar router={router} disabled={disabled} />,
        },
        {
            title: strings.involvedProfiles,
            testId: testIds.REPORT_SIDEBAR_INVOLVED_PROFILE_TAB,
            content: <InvolvedProfilesSidebar reportId={reportId} />,
        },
        applicationSettings.RMS_CAD_DATA_ENTITY_PREFILL_ENABLED && cadTicketViewModels.length
            ? {
                  title: strings.eventInfo,
                  testId: testIds.REPORT_SIDEBAR_EVENT_INFO_TAB,
                  content: <EventInfo reportId={reportId} />,
              }
            : undefined,
        applicationSettings.RMS_TASK_ENTITY_LINKS_ENABLED &&
        currentUserHasAbility(abilitiesEnum.CORE.VIEW_NON_CASE_TASKS)
            ? {
                  title: strings.tasks,
                  testId: testIds.REPORT_SIDEBAR_TASK_TAB,
                  content: (
                      <LinkedTasksSection
                          entityType={EntityTypeEnum.REPORT.name}
                          entityId={reportId}
                      />
                  ),
              }
            : undefined,
        applicationSettings.RMS_GENERIC_EXTERNAL_SUBMISSIONS_ENABLED
            ? {
                  title: strings.submissions,
                  testId: testIds.REPORT_SIDEBAR_SUBMISSIONS_TAB,
                  content: <EntitySubmissionMessages />,
              }
            : undefined,
    ]);

    return (
        <StyledRecordsSidebar>
            <Tabs
                data-test-id={testIds.ENTITY_SIDEBAR_TABS}
                selectedIndex={activeTab}
                onSelect={(index) => setActiveTab(index)}
            >
                <TabList>
                    {map(tabConfig, ({ title, testId }, idx) => (
                        <Tab data-test-id={testId} key={idx} active={idx === activeTab}>
                            {title}
                        </Tab>
                    ))}
                </TabList>
                {map(tabConfig, ({ content }, idx) => (
                    <TabPanel key={idx}>{content}</TabPanel>
                ))}
            </Tabs>
        </StyledRecordsSidebar>
    );
};
