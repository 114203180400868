import { createSelector } from 'reselect';
import { Vehicle } from '@mark43/rms-api';

import { vehicleMakeNameByMakeIdSelector } from '~/client-common/core/domain/vehicle-makes/state/data';
import { vehicleModelNameByModelIdSelector } from '~/client-common/core/domain/vehicle-models/state/data';
import { formatAttributeByIdSelector } from '~/client-common/core/domain/attributes/state/data';

export type VehiclViewType = Partial<{
    makeName: string;
    modelName: string;
    registrationState: string;
    primaryColor: string;
    vinNumber: string;
    tag: string;
    registrationYear: string;
}>;

export const vehicleViewSelector = createSelector(
    vehicleMakeNameByMakeIdSelector,
    vehicleModelNameByModelIdSelector,
    formatAttributeByIdSelector,
    (vehicleMakeNameByMakeId, vehicleModelNameByModelId, formatAttributeById) =>
        (vehicle: Partial<Vehicle>) => {
            let makeName;
            let modelName;
            let registrationState;
            let primaryColor;

            if (vehicle.vehicleMakeId) {
                makeName = vehicleMakeNameByMakeId(vehicle.vehicleMakeId);
            }

            if (vehicle.vehicleModelId) {
                modelName = vehicleModelNameByModelId(vehicle.vehicleModelId);
            }

            if (vehicle.registrationStateAttrId) {
                registrationState = formatAttributeById(vehicle.registrationStateAttrId);
            }

            if (vehicle.primaryColorAttrId) {
                primaryColor = formatAttributeById(vehicle.primaryColorAttrId);
            }

            const vehicleView: VehiclViewType = {
                makeName,
                modelName,
                registrationState,
                primaryColor,
                vinNumber: vehicle.vinNumber,
                tag: vehicle.tag,
                registrationYear: String(vehicle.registrationYear),
            };

            return vehicleView;
        }
);
