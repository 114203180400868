import React from 'react';
import styled from 'styled-components';
import { IntegrationStatusEnum } from '@mark43/rms-api';
import { chain } from 'lodash';
import { Card, CardHeader, CardBody, HStack, VStack } from 'arc';
import { formatFullName } from '~/client-common/core/domain/mini-users/utils/miniUsersHelpers';
import Icon, { iconTypes } from '../../core/components/Icon';
import { useDateTimeFormatter } from '../../core/current-user/hooks/dateTimeFormats';
import { useSubmissionMessages } from '../hooks';

const CardWrapper = styled.div`
    padding-top: 35px;
`;

const FailedSubmissionIcon = styled(Icon)`
    padding-right: 20px;
`;

const SubmissionTitle = styled.span`
    font-weight: bold;
`;

export const RecentErrorSubmissionCard: React.FC = () => {
    const {
        state: { submissionMessageViews },
    } = useSubmissionMessages();
    const dateTimeFormatters = useDateTimeFormatter();

    const latestErrorSubmissionMessageView = chain(submissionMessageViews)
        .filter((submissionMessageView) => {
            return submissionMessageView.status.status === IntegrationStatusEnum.ERROR.name;
        })
        .orderBy('message.updatedDateUtc', ['desc'])
        .first()
        .value();

    if (!latestErrorSubmissionMessageView) {
        return null;
    }

    return (
        <CardWrapper>
            <Card>
                <CardHeader title="Submission Status" />
                <CardBody>
                    <HStack>
                        <FailedSubmissionIcon size={30} type={iconTypes.ALERT} color={'red'} />
                        <VStack>
                            <div>
                                Failed to send latest submission{' '}
                                <SubmissionTitle>{`${latestErrorSubmissionMessageView.messageTypeDisplayName} (${latestErrorSubmissionMessageView.messageTypeDisplayCode})`}</SubmissionTitle>{' '}
                                on{' '}
                                {dateTimeFormatters.formatSummaryDateTime(
                                    latestErrorSubmissionMessageView.message.updatedDateUtc
                                )}{' '}
                                by {formatFullName(latestErrorSubmissionMessageView.creator)}
                            </div>
                        </VStack>
                    </HStack>
                </CardBody>
            </Card>
        </CardWrapper>
    );
};
