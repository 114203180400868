import { Switch as ArcSwitch } from 'arc';
import styled from 'styled-components';

const Switch = styled(ArcSwitch)`
    // override the box-sizing default in RMS that makes this component appear squished
    * {
        box-sizing: content-box;
    }
`;

export default Switch;
