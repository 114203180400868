import { AttributeTypeEnum } from '@mark43/rms-api';
import { get, isUndefined, concat } from 'lodash';
import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';

import classNames from 'classnames';
import {
    combinedSubdivisionsLabelSelector,
    globalSequenceNumberLabelSelector,
} from '~/client-common/core/fields/state/config';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import {
    reportDefinitionHasArrestCard,
    reportDefinitionHasCommunityInformation,
    reportDefinitionHasFieldContactCard,
    reportDefinitionHasMissingPersonsCard,
    reportDefinitionHasOffenseCard,
    reportDefinitionHasTowVehicle,
    reportDefinitionHasUseOfForceCard,
    reportDefinitionHasUseOfForceSubjectCard,
    reportDefinitionHasLegacyMigrationInfoCard,
} from '~/client-common/helpers/reportDefinitionsHelpers';
import { consortiumDepartmentLinksAvailableSelector } from '~/client-common/core/domain/consortium-link-view/state/ui';
import componentStrings from '~/client-common/core/strings/componentStrings';
import {
    OFFENSE_TYPE_OFFENSE_CODE_FLAGS,
    INCIDENT_TYPE_OFFENSE_CODE_FLAGS,
} from '~/client-common/core/domain/offense-codes/constants';
import dateTypeEnum from '~/client-common/core/enums/client/dateTypeEnum';
import {
    DISPLAY_ONLY_EVENT_DATE_TIME_RANGE_LABEL,
    DISPLAY_ONLY_OFFENSE,
    DISPLAY_ONLY_OFFENSE_GROUP_LABEL,
    DISPLAY_ONLY_OFFICER_YEARS_OF_SERVICE_LABEL,
    DISPLAY_ONLY_PERSON_AGE_RANGE_AT_REPORT_LABEL,
    DISPLAY_ONLY_REPORT_DETAILS_INCIDENT_OR_OFFENSE_CLASSIFICATION_LABEL,
    DISPLAY_ONLY_REPORT_DETAILS_LABELS,
    DISPLAY_ONLY_SHIFT_TIME_RANGE_LABEL,
    REPORT_UCR_UCR_SUMMARY_CODE,
    REPORT_REPORT_DEFINITION_ID,
    OFFENSE_CASE_STATUS_CASE_STATUS_ATTR_ID,
} from '~/client-common/core/enums/universal/fields';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import useFields from '~/client-common/core/fields/hooks/useFields';
import Row from '../../../core/components/Row';
import { RRFFieldset } from '../../../core/forms/components/Fieldset';
import { RRFNItems } from '../../../core/forms/components/NItems';
import { RRFText } from '../../../core/forms/components/Text';
import { RRFBooleanSelect } from '../../../core/forms/components/selects/BooleanSelect';
import { RRFOffenseGroupSelect } from '../../../core/forms/components/selects/OffenseGroupSelect';
import { RRFOffenseCodeSelect } from '../../../core/forms/components/selects/OffenseCodeSelect';
import { RRFNibrsOffenseCodeIdSelect } from '../../../core/forms/components/selects/NibrsCodeSelect';
import { RRFCustomReportClassificationAttrSelect } from '../../../core/forms/components/selects/CustomReportClassificationAttrSelect';
import { RRFOffenseCaseStatusAttrSelect } from '../../../core/forms/components/selects/OffenseCaseStatusSelect';
import { RRFLabelsWithNoneAttrSelect } from '../../../core/forms/components/selects/LabelsWithNoneAttrSelect';
import { RRFUcrCodeSelect } from '../../../core/forms/components/selects/UcrCodeSelect';
import { RRFUcrGroupSelect } from '../../../core/forms/components/selects/UcrGroupSelect';
import { RRFAttributeSelect } from '../../../core/forms/components/selects/AttributeSelect';
import { RRFApprovalStatusSelect } from '../../../core/forms/components/selects/ClientApprovalStatusSelect';
import { RRFReportDefinitionSelect } from '../../../core/forms/components/selects/ReportDefinitionSelect';
import { RRFDateRangePicker } from '../../../core/forms/components/DateRangePicker';
import { RRFAgeRangeSlider, RRFYearsOfServiceSlider } from '../../../core/forms/components/Slider';
import { RRFUserSelect } from '../../../core/forms/components/selects/UserSelect';
import { RRFDatePicker } from '../../../core/forms/components/DatePicker';
import RRFTimeRangeElement from '../../../core/forms/components/RRFTimeRangeElement';
import { RRFCaseStatusSelect } from '../../../core/forms/components/selects/CaseStatusSelect';
import { RRFAgencyProfileSelect } from '../../../core/forms/components/selects/AgencyProfileSelect';
import { RRFConsortiumDepartmentSelect } from '../../../core/forms/components/selects/ConsortiumDepartmentSelect';

import {
    reportDefinitionsForCurrentDepartmentSelector,
    supplementReportDefinitionForCurrentDepartmentSelector,
} from '../../../core/report-definitions/state/ui';

import reactReduxFormHelpers from '../../../../legacy-redux/helpers/reactReduxFormHelpers';
import SearchCapabilityIcon, {
    SEARCH_CAPABILITY_TYPE_ENUM,
} from '../../core/components/SearchCapabilityIcon';
import { SubdivisionsSearch } from '../../core/components/SubdivisionsSearch';
import testIds from '../../../../core/testIds';
import { RRFSelect } from '../../../core/forms/components/selects/Select';
import { ConfiguredEntityPropertyFieldset } from './dragon/ConfiguredEntityPropertyFieldset';

const { withRRFActions } = reactReduxFormHelpers;
// configs
const { helpTexts } = componentStrings.search.fieldsets.ReportDetailsFieldset;

const IconWrapper = styled.span`
    margin-left: 10px;
`;

const FlexRow = styled.div`
    display: flex;
`;

const FlexColumn = styled.div`
    display: flex;
    flex-direction: column;
`;

const ReportNItemsWrapper = styled.div`
    max-width: 45rem;
`;

const dateTypeOptions = [
    { display: 'Event', value: dateTypeEnum.EVENT },
    { display: 'Modified', value: dateTypeEnum.MODIFIED },
    { display: 'Created', value: dateTypeEnum.CREATED },
];

/**
 * An arbitrary number of report definition rows. Some form rows conditionally
 *   appear depending on the selected report definition.
 */
const ReportDefinitionsNItems = compose(
    connect(
        createStructuredSelector({
            reportDefinitions: reportDefinitionsForCurrentDepartmentSelector,
            supplementReportDefinitionForCurrentDepartment: supplementReportDefinitionForCurrentDepartmentSelector,
        })
    ),
    withRRFActions
)(function ReportDefinitionsNItems({
    reportDefinitions,
    supplementReportDefinitionForCurrentDepartment,
    formActions,
}) {
    const fieldsDisplayNames = useFields([
        DISPLAY_ONLY_OFFENSE,
        DISPLAY_ONLY_OFFENSE_GROUP_LABEL,
        DISPLAY_ONLY_PERSON_AGE_RANGE_AT_REPORT_LABEL,
        DISPLAY_ONLY_OFFICER_YEARS_OF_SERVICE_LABEL,
    ]);
    return (
        <RRFNItems path="reportDefinitions" addItemOnDirty={true}>
            {(item, index, deleteButton) => {
                const reportDefinitionIdRow = (
                    <FlexRow>
                        <RRFReportDefinitionSelect
                            length="md"
                            path="reportDefinitionId"
                            onChange={(value) => {
                                if (value !== item.reportDefinitionId) {
                                    // when the report type is changed, clear all
                                    // other field values in this item
                                    formActions.change(`reportDefinitions[${index}]`, {
                                        reportDefinitionId: value,
                                    });
                                }
                            }}
                            fieldNameAlternate={REPORT_REPORT_DEFINITION_ID}
                        />
                        {deleteButton}
                    </FlexRow>
                );

                const reportDefinition = reportDefinitions[item.reportDefinitionId];

                const communityInfoRow = reportDefinitionHasCommunityInformation(
                    reportDefinition
                ) && (
                    <RRFAttributeSelect
                        attributeType="COMMUNITY_INFORMATION_DISPOSITION"
                        multiple={true}
                        includeExpired={true}
                        length="md"
                        path="communityInformationDispositionAttrIds"
                    />
                );

                const fieldContactRow = reportDefinitionHasFieldContactCard(reportDefinition) && (
                    <FlexRow>
                        <RRFAttributeSelect
                            attributeType="FIELD_CONTACT_TYPE"
                            multiple={true}
                            includeExpired={true}
                            length="md"
                            path="fieldContactTypeAttrIds"
                        />
                        <RRFAttributeSelect
                            attributeType="FIELD_CONTACT_DISPOSITION"
                            multiple={true}
                            includeExpired={true}
                            length="md"
                            path="fieldContactDispositionAttrIds"
                        />
                    </FlexRow>
                );

                const legacyMigrationInfoRow = reportDefinitionHasLegacyMigrationInfoCard(
                    reportDefinition
                ) && (
                    <>
                        <RRFAttributeSelect
                            attributeType="LEGACY_SYSTEM"
                            multiple={true}
                            includeExpired={true}
                            length="md"
                            path="legacySystemAttrId"
                        />
                        <RRFAttributeSelect
                            attributeType="LEGACY_REPORT_TYPE"
                            multiple={true}
                            includeExpired={true}
                            length="md"
                            path="legacyReportTypeAttrId"
                        />
                        <RRFAttributeSelect
                            attributeType="LEGACY_REPORT_SUB_TYPE"
                            multiple={true}
                            includeExpired={true}
                            length="md"
                            path="legacyReportSubTypeAttrId"
                        />
                        <RRFAttributeSelect
                            attributeType="LEGACY_CLASSIFICATION_CODE"
                            multiple={true}
                            includeExpired={true}
                            length="md"
                            path="customReportClassificationAttrId"
                        />
                        <RRFAttributeSelect
                            attributeType="LEGACY_MIGRATION_STATISTIC"
                            multiple={true}
                            includeExpired={true}
                            length="md"
                            path="eventStatisticAttrIds"
                        />
                    </>
                );

                const missingPersonRow = reportDefinitionHasMissingPersonsCard(
                    reportDefinition
                ) && (
                    <FlexRow>
                        <RRFAttributeSelect
                            attributeType="MISSING_PERSON_TYPE"
                            multiple={true}
                            includeExpired={true}
                            length="md"
                            path="missingPersonTypeAttrIds"
                        />
                        <RRFAttributeSelect
                            attributeType="MISSING_PERSON_STATUS"
                            multiple={true}
                            includeExpired={true}
                            length="md"
                            path="missingPersonStatusAttrIds"
                        />
                    </FlexRow>
                );

                const offenseIncidentCodeSelect = (
                    <RRFOffenseCodeSelect
                        multiple={true}
                        flags={concat(
                            [],
                            OFFENSE_TYPE_OFFENSE_CODE_FLAGS,
                            INCIDENT_TYPE_OFFENSE_CODE_FLAGS
                        )}
                        includeExpired={true}
                        length="md"
                        path="offenseIncidentOffenseCodeIds"
                    />
                );

                const offenseAndIncidentRows = reportDefinitionHasOffenseCard(reportDefinition) && (
                    <FlexColumn>
                        <RRFOffenseGroupSelect
                            multiple={true}
                            length="md"
                            paths={{
                                nibrsGroups: 'offenseNibrsGroups',
                                ucrGroups: 'offenseUcrGroups',
                            }}
                        />
                        <FlexRow>
                            <RRFNibrsOffenseCodeIdSelect
                                multiple={true}
                                length="md"
                                path="offenseNibrsCodeIds"
                            />
                            <RRFUcrCodeSelect
                                label={fieldsDisplayNames[DISPLAY_ONLY_OFFENSE_GROUP_LABEL]}
                                helpText={helpTexts.offenseUcrCodes(
                                    fieldsDisplayNames[DISPLAY_ONLY_OFFENSE]
                                )}
                                multiple={true}
                                length="md"
                                paths={{
                                    hasUcrCode: 'offenseHasUcrCode',
                                    ucrCodes: 'offenseUcrCodes',
                                }}
                            />
                        </FlexRow>
                        {offenseIncidentCodeSelect}
                        <FlexRow>
                            <RRFAttributeSelect
                                attributeType="WEAPON_OR_FORCE_INVOLVED"
                                multiple={true}
                                includeExpired={true}
                                length="md"
                                path="offenseWeaponOrForceInvolvedAttrIds"
                            />
                            <RRFAttributeSelect
                                attributeType="MODUS_OPERANDI"
                                multiple={true}
                                grouped={true}
                                includeExpired={true}
                                length="md"
                                path="offenseModusOperandiAttrIds"
                            />
                        </FlexRow>
                        <FlexRow>
                            <RRFBooleanSelect length="sm" path="offenseIsDomesticViolence" />
                            <RRFBooleanSelect length="sm" path="offenseIsSuspectedHateCrime" />
                            <RRFBooleanSelect length="sm" path="offenseIsLeoka" />
                        </FlexRow>
                    </FlexColumn>
                );

                const arrestRows = reportDefinitionHasArrestCard(reportDefinition) && (
                    <div>
                        <FlexRow>
                            <RRFAttributeSelect
                                attributeType="ARREST_TYPE"
                                multiple={true}
                                includeExpired={true}
                                length="md"
                                path="arrestTypeAttrIds"
                            />
                            <RRFAttributeSelect
                                attributeType="ARRESTEE_WAS_ARMED_WITH"
                                multiple={true}
                                includeExpired={true}
                                length="md"
                                path="arresteeArmedWithAttrIds"
                            />
                            <RRFBooleanSelect length="sm" path="hasWarrants" />
                            <RRFBooleanSelect length="sm" path="isDetention" />
                        </FlexRow>
                        <FlexRow>
                            <RRFOffenseCodeSelect
                                flags={OFFENSE_TYPE_OFFENSE_CODE_FLAGS}
                                multiple={true}
                                includeExpired={true}
                                length="md"
                                path="arrestOffenseCodeIds"
                            />
                            <RRFText length="md" path="arrestLocalId" />
                        </FlexRow>
                    </div>
                );

                const useOfForceRows = reportDefinitionHasUseOfForceCard(reportDefinition) && (
                    <div>
                        <FlexRow>
                            <RRFAttributeSelect
                                attributeType="USE_OF_FORCE_REASON"
                                multiple={true}
                                includeExpired={true}
                                length="md"
                                path="useOfForceReasonAttrIds"
                            />
                            <RRFAttributeSelect
                                attributeType="USE_OF_FORCE_STATISTICS"
                                multiple={true}
                                includeExpired={true}
                                length="md"
                                path="useOfForceStatisticsAttrIds"
                            />
                        </FlexRow>
                        <FlexRow>
                            <RRFUserSelect
                                multiple={true}
                                length="md"
                                path="userProfileIds"
                                effectiveDate={null}
                            />
                            <RRFAttributeSelect
                                attributeType="SEX"
                                multiple={true}
                                includeExpired={true}
                                length="sm"
                                path="officerSexAttrIds"
                            />
                            <RRFAttributeSelect
                                attributeType="RACE"
                                multiple={true}
                                includeExpired={true}
                                length="md"
                                path="officerRaceAttrIds"
                            />
                        </FlexRow>
                        <FlexRow>
                            <RRFDatePicker
                                variant={RRFDatePicker.variants.LOCAL_DATE}
                                path="dateOfBirth"
                                onChange={(value) => {
                                    // when a date of birth is selected, clear the age range
                                    // fields
                                    if (value) {
                                        formActions.reset(
                                            `reportDefinitions[${index}].ageRangeStart`
                                        );
                                        formActions.reset(
                                            `reportDefinitions[${index}].ageRangeEnd`
                                        );
                                    }
                                }}
                            />
                            <RRFAgeRangeSlider
                                label={
                                    fieldsDisplayNames[
                                        DISPLAY_ONLY_PERSON_AGE_RANGE_AT_REPORT_LABEL
                                    ]
                                }
                                onChange={({ rangeStart, rangeEnd }) => {
                                    // when an age range is selected, clear the date of birth
                                    // field
                                    if (!isUndefined(rangeStart) || !isUndefined(rangeEnd)) {
                                        formActions.reset(
                                            `reportDefinitions[${index}].dateOfBirth`
                                        );
                                    }
                                }}
                            />
                            <RRFYearsOfServiceSlider
                                label={
                                    fieldsDisplayNames[DISPLAY_ONLY_OFFICER_YEARS_OF_SERVICE_LABEL]
                                }
                            />
                        </FlexRow>
                    </div>
                );

                const useOfForceSubjectRows = reportDefinitionHasUseOfForceSubjectCard(
                    reportDefinition
                ) && (
                    <FlexRow>
                        <RRFAttributeSelect
                            attributeType="USE_OF_FORCE_SUBJECT_DETAILS"
                            multiple={true}
                            includeExpired={true}
                            length="md"
                            path="useOfForceSubjectDetailsAttrIds"
                        />
                        <RRFAttributeSelect
                            attributeType="USE_OF_FORCE_SUBJECT_ACTIONS"
                            multiple={true}
                            includeExpired={true}
                            length="md"
                            path="useOfForceSubjectActionsAttrIds"
                        />
                        <RRFAttributeSelect
                            attributeType="USE_OF_FORCE_OFFICER_FORCE_TOWARDS_SUBJECT"
                            multiple={true}
                            includeExpired={true}
                            length="md"
                            path="useOfForceOfficerForceTowardsSubjectAttrIds"
                        />
                    </FlexRow>
                );

                const towVehicleRows = reportDefinitionHasTowVehicle(reportDefinition) && (
                    <RRFAttributeSelect
                        attributeType="TOW_VEHICLE_REASON_FOR_TOW"
                        multiple={true}
                        includeExpired={true}
                        length="md"
                        path="reasonForTowAttrIds"
                    />
                );

                // `Supplement Type` is only relevant for Supplement Reports.
                const supplementTypeRows = get(
                    supplementReportDefinitionForCurrentDepartment,
                    'id'
                ) === get(reportDefinition, 'id') && (
                    <RRFAttributeSelect
                        attributeType={AttributeTypeEnum.SUPPLEMENT_TYPE.name}
                        multiple={true}
                        includeExpired={true}
                        length="md"
                        path="supplementTypeAttrIds"
                    />
                );

                return (
                    <ReportNItemsWrapper>
                        {reportDefinitionIdRow}
                        {fieldContactRow}
                        {legacyMigrationInfoRow}
                        {missingPersonRow}
                        {offenseAndIncidentRows}
                        {arrestRows}
                        {useOfForceRows}
                        {useOfForceSubjectRows}
                        {towVehicleRows}
                        {communityInfoRow}
                        {supplementTypeRows}
                        {reportDefinition && (
                            <ConfiguredEntityPropertyFieldset
                                reportDefinitionId={reportDefinition.id}
                            />
                        )}
                    </ReportNItemsWrapper>
                );
            }}
        </RRFNItems>
    );
});

/**
 * The fieldset that is above `ReportDetailsFieldset` but whose fields (event
 *   date/time range and report type) are still considered report details for
 *   filtering purposes.
 * @param {Object} props
 */
export function ReportDetailsTopFieldset(props) {
    const fieldsDisplayNames = useFields([
        DISPLAY_ONLY_EVENT_DATE_TIME_RANGE_LABEL,
        DISPLAY_ONLY_SHIFT_TIME_RANGE_LABEL,
    ]);
    return (
        <RRFFieldset path="reportDetails" {...props}>
            <FlexRow>
                <RRFSelect
                    path="dateType"
                    options={dateTypeOptions}
                    length="md"
                    clearable={false}
                />
                <RRFDateRangePicker
                    label={fieldsDisplayNames[DISPLAY_ONLY_EVENT_DATE_TIME_RANGE_LABEL]}
                    length="md"
                    includeTime={true}
                    withinLastPeriodOptions={['PT12H', 'PT24H', 'PT48H', 'P7D', 'P28D']}
                    toDatePeriodOptions={['P1M', 'P1Y']}
                />
                <RRFTimeRangeElement
                    label={fieldsDisplayNames[DISPLAY_ONLY_SHIFT_TIME_RANGE_LABEL]}
                />
            </FlexRow>
            <ReportDefinitionsNItems />
        </RRFFieldset>
    );
}

const mapStateToProps = createStructuredSelector({
    combinedSubdivisionsLabel: combinedSubdivisionsLabelSelector,
    globalSequenceNumberLabel: globalSequenceNumberLabelSelector,
    consortiumDepartmentLinksAvailable: consortiumDepartmentLinksAvailableSelector,
    applicationSettings: applicationSettingsSelector,
});

/**
 * The Report Details section of the advanced search reports form contains two
 *   fieldsets: This one is the bottom one and is the only one that gets
 *   labelled (the label is applied outside this fieldset).
 * @param {boolean} [props.fuzzyMatchingEnabled]
 */
const ReportDetailsFieldset = function ({
    fuzzyMatchingEnabled,
    combinedSubdivisionsLabel,
    globalSequenceNumberLabel,
    consortiumDepartmentLinksAvailable,
    hideAgencySelect,
    applicationSettings,
    ...otherProps
}) {
    const narrativeField = (
        <RRFText
            length="md"
            className={classNames({
                'fuzzy-matching-enabled': fuzzyMatchingEnabled,
            })}
            extraLabelContent={
                <IconWrapper>
                    <SearchCapabilityIcon variant={SEARCH_CAPABILITY_TYPE_ENUM.BOOLEAN.name} />
                </IconWrapper>
            }
            labelClassName=""
            path="narrative"
        />
    );

    const recordNumberPath = applicationSettings.RMS_REPORT_RECORDS_WITHOUT_REN_ENABLED
        ? 'globalSequenceNumber'
        : 'reportingEventNumber';

    const fieldsDisplayNames = useFields([
        DISPLAY_ONLY_REPORT_DETAILS_LABELS,
        DISPLAY_ONLY_REPORT_DETAILS_INCIDENT_OR_OFFENSE_CLASSIFICATION_LABEL,
        REPORT_UCR_UCR_SUMMARY_CODE,
        OFFENSE_CASE_STATUS_CASE_STATUS_ATTR_ID,
    ]);

    return (
        <RRFFieldset path="reportDetails" {...otherProps}>
            <FlexRow style={{ gap: 'var(--arc-space-3)' }}>
                <div data-test-id={testIds.ADVANCED_SEARCH_REPORT_REN}>
                    <RRFText
                        label={globalSequenceNumberLabel}
                        className={classNames({
                            'fuzzy-matching-enabled': fuzzyMatchingEnabled,
                        })}
                        path={recordNumberPath}
                    />
                </div>
                <RRFApprovalStatusSelect
                    path="clientApprovalStatuses"
                    multiple={true}
                    length="md"
                />
            </FlexRow>
            <SubdivisionsSearch length="sm" />
            <FlexRow>
                <FeatureFlagged
                    flag="RMS_SUBDIVISION_SEARCH_ENHANCEMENTS_ENABLED"
                    fallback={
                        <RRFAttributeSelect
                            attributeType={[
                                'SUBDIVISION_DEPTH_1',
                                'SUBDIVISION_DEPTH_2',
                                'SUBDIVISION_DEPTH_3',
                                'SUBDIVISION_DEPTH_4',
                                'SUBDIVISION_DEPTH_5',
                            ]}
                            label={combinedSubdivisionsLabel}
                            multiple={true}
                            includeExpired={true}
                            length="md"
                            path="subdivisionAttrIds"
                        />
                    }
                />
                <RRFLabelsWithNoneAttrSelect
                    multiple={true}
                    length="md"
                    label={fieldsDisplayNames[DISPLAY_ONLY_REPORT_DETAILS_LABELS]}
                />
                <RRFAttributeSelect
                    attributeType={AttributeTypeEnum.EVENT_STATISTICS.name}
                    multiple={true}
                    includeExpired={true}
                    length="md"
                    path="eventStatisticAttrIds"
                />
            </FlexRow>
            <FlexRow>
                <RRFCustomReportClassificationAttrSelect
                    multiple={true}
                    length="md"
                    label={
                        fieldsDisplayNames[
                            DISPLAY_ONLY_REPORT_DETAILS_INCIDENT_OR_OFFENSE_CLASSIFICATION_LABEL
                        ]
                    }
                />
                <RRFUcrCodeSelect
                    multiple={true}
                    length="md"
                    label={fieldsDisplayNames[REPORT_UCR_UCR_SUMMARY_CODE]}
                />
                <RRFUcrGroupSelect path="ucrGroup" multiple={true} length="md" />
            </FlexRow>
            <FlexRow>
                {consortiumDepartmentLinksAvailable && (
                    <RRFConsortiumDepartmentSelect
                        path="departmentIds"
                        multiple={true}
                        length="md"
                    />
                )}
                {!hideAgencySelect && (
                    <RRFAgencyProfileSelect path="agencyIds" multiple={true} length="md" />
                )}
            </FlexRow>
            <FlexRow>
                <RRFCaseStatusSelect includeExpired={true} length="md" />
                <RRFOffenseCaseStatusAttrSelect
                    includeExpired={true}
                    length="md"
                    label={fieldsDisplayNames[OFFENSE_CASE_STATUS_CASE_STATUS_ATTR_ID]}
                />
                {((consortiumDepartmentLinksAvailable && hideAgencySelect) ||
                    !consortiumDepartmentLinksAvailable) &&
                    narrativeField}
            </FlexRow>
            <FlexRow>
                <RRFBooleanSelect path="isSchoolRelated" length="md" />
            </FlexRow>
            <FlexRow>
                <RRFBooleanSelect path="isArrestOrCustodialRelatedDeath" length="md" />
            </FlexRow>
            {consortiumDepartmentLinksAvailable && !hideAgencySelect && <Row>{narrativeField}</Row>}
        </RRFFieldset>
    );
};

export default connect(mapStateToProps)(ReportDetailsFieldset);
