import React from 'react';
import moment from 'moment';
import { snakeCase } from 'lodash';
import invariant from 'invariant';

import { getChildTypes } from '../../../helpers/reactHelpers';
import { dateTimeFormats, DateTimeFormatKeys } from '../utils/dateHelpers';
import type { FCWithoutChildren } from '../../../types';
import { useFormattedDate } from '../hooks';

const NODE_ENV = process.env.NODE_ENV;

interface FormattedDateProps {
    date?: moment.MomentInput;
    format: DateTimeFormatKeys;
    className?: string;
    children?: (formattedDate: string | null) => JSX.Element;
    testId?: string;
}

// todo: find a way for typescript to get the correct datetime keys
// convert keys to all caps snake case
// so signify that these are constants
const FORMATS = (Object.keys(dateTimeFormats) as DateTimeFormatKeys[]).reduce<{
    [index: string]: DateTimeFormatKeys;
}>((carry, key) => {
    carry[snakeCase(key).toUpperCase()] = key;
    return carry;
}, {});

type FormattedDateExport = FCWithoutChildren<FormattedDateProps> & { FORMATS: typeof FORMATS };

// TODO
// 1. investigate whether we can cache the moment instance
// and just swap the date. If we cannot and this becomes a
// performance issue, we might be able to use a different
// library like https://github.com/gosquared/speed-date

// 2. If a date needs to appear multiple times on the same
// page in the same format, this component has to format it `n` times.
// This issue could be alleviated or completely solved by caching the
// formatting results for the last `x` formats, keyed by stringified date and type.

// 3. In order to prevent re-renders it's possible to wrap this component in
// recompose's `pure` HoC. This might or might not be an improvement and
// has to be tested.
const FormattedDate: FormattedDateExport = ({ date, format, children, className, testId }) => {
    const formattedDate = useFormattedDate(date, format);

    const childIsFunction = typeof children === 'function';
    if (NODE_ENV !== 'production') {
        const types = children ? getChildTypes(children).join(', ') : '';
        invariant(
            children === undefined || childIsFunction,
            `FormattedDate only accepts a function as child, but you have nested "${types}".`
        );
    }
    // If a function is provided as a child, we call it with the formatted value.
    // Useful for cases where the date is supposed to be injected into a string.
    // If provided, this function has to always be called, since it could
    // render something even if there is no date.
    if (typeof children === 'function') {
        return children(formattedDate);
    }

    if (!formattedDate) {
        return null;
    }

    return className ? (
        <span className={className} data-test-id={testId}>
            {formattedDate}
        </span>
    ) : (
        <span data-test-id={testId}>{formattedDate}</span>
    );
};

FormattedDate.FORMATS = FORMATS;

export default FormattedDate;
