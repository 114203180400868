import keyMirror from 'keymirror';

const OverlayIdEnum = keyMirror({
    LOCATION_SUMMARY_VIEW: null,

    NOTIFICATION_BULK_UPDATE_MODAL: null,
    MFA_CONFIG_BULK_DELETE_MODAL: null,

    PERSON_OVERLAY_ENTITY_PROFILE_PERSON_HEADER: null,
    ORGANIZATION_OVERLAY_ENTITY_PROFILE_ORGANIZATION_HEADER: null,

    PERSON_OVERLAY_WARRANT_DETAILS_FORM: null,

    PERSON_OVERLAY_OWNER_CLAIMAINT_N_ITEMS: null,
    ORGANIZATION_OVERLAY_OWNER_CLAIMAINT_N_ITEMS: null,

    PERSON_OVERLAY_DEFENDANT_SECTION: null,
    PERSON_OVERLAY_CODEFENDANT: null,
    PERSON_OVERLAY_COMPLAINANT: null,

    PERSON_OVERLAY_SIDE_PANEL_NAME_SUMMARY: null,
    ORGANIZATION_OVERLAY_SIDE_PANEL_NAME_SUMMARY: null,

    PERSON_OVERLAY_NAME_LINE: null,
    ORGANIZATION_OVERLAY_NAME_LINE: null,

    PERSON_OVERLAY_NAME_SUMMARY_VIEW_WRAPPER: null,
    ORGANIZATION_OVERLAY_NAME_SUMMARY_VIEW_WRAPPER: null,

    PERSON_OVERLAY_USE_OF_FORCE_CARD: null,
    ORGANIZATION_OVERLAY_USE_OF_FORCE_CARD: null,

    PERSON_OVERLAY_OFFENSE_CARD: null,
    ORGANIZATION_OVERLAY_OFFENSE_CARD: null,

    PERSON_OVERLAY_INCIDENT_CARD: null,
    ORGANIZATION_OVERLAY_INCIDENT_CARD: null,

    PERSON_OVERLAY_CAUTIONS: null,

    LOCATION_OVERLAY_CAD_SERVICE_PROVIDER: null,
    LOCATION_OVERLAY_CAD_ROTATION_LIST: null,
    LOCATION_OVERLAY_CUSTODIAL_PROPERTY_SUMMARY_RECOVERED_LOCATION: null,
    LOCATION_OVERLAY_DEPARTMENT_PROFILE_ADMIN_FORM: null,
    LOCATION_OVERLAY_LOCATION_ALIAS_NEW_ALIAS: null,
    LOCATION_OVERLAY_GEO_ALIAS_NEW_ALIAS: null,
    LOCATION_OVERLAY_LOCATION_CAUTIONS_ADMIN: null,
    LOCATION_OVERLAY_GEO_CAUTIONS_ADMIN: null,
    LOCATION_OVERLAY_GEO_CAUTIONS_ADMIN_SUBPREMISE: null,
    LOCATION_OVERLAY_GEO_CAUTIONS_ADMIN_DISCARD_CHANGES: null,
    LOCATION_OVERLAY_ORGANIZATION_SIDE_PANEL_MAILING_ADDRESS: null,
    LOCATION_OVERLAY_ORGANIZATION_SIDE_PANEL_PHYSICAL_ADDRESS: null,
    LOCATION_OVERLAY_PERSON_SIDE_PANEL_HOME_ADDRESS: null,
    LOCATION_OVERLAY_PERSON_SIDE_PANEL_WORK_ADDRESS: null,
    LOCATION_OVERLAY_PERSON_SIDE_PANEL_OTHER_ADDRESS: null,
    LOCATION_OVERLAY_STATIONS_ADMIN_FORM: null,
    LOCATION_OVERLAY_USER_PROFILE_ADMIN_FORM: null,
    LOCATION_OVERLAY_CASE_NOTES: null,
    LOCATION_OVERLAY_STREET_CENTERLINES_MODAL: null,
    LOCATION_OVERLAY_ITEM_LOCATIONS: null,

    // Hospitals
    LOCATION_OVERLAY_HOSPITAL_ADMIN_FORM: null,

    ARREST_CHARGES_SIDE_PANEL_CHARGES_CARD: null,
    ARREST_CHARGES_SIDE_PANEL_DROPDOWN_MENU: null,
    ARREST_CHARGES_STUB_OFFENSE_LOCATION_SIDE_PANEL: null,

    TOW_VEHICLE_CHECK_IN_SIDE_PANEL: null,
    TOW_VEHICLE_RELEASE_SIDE_PANEL: null,

    TOW_VEHICLE_RELEASE_PERSON: null,
    TOW_VEHICLE_RELEASE_ORGANIZATION: null,

    // Permissions Modals
    CASE_PERMISSIONS_OVERLAY: null,
    REPORT_PERMISSIONS_OVERLAY: null,
    WARRANT_PERMISSIONS_OVERLAY: null,
    ENTITY_PROFILE_PERMISSIONS_OVERLAY: null,
    EVIDENCE_STORAGE_LOCATION_PERMISSIONS_OVERLAY: null,
    APPLY_CASE_PERMISSIONS_TO_REPORTS_OVERLAY: null,
    CREATE_NEW_PERMISSIONS_OVERLAY: null,
    REMOVE_DEPARTMENT_PERMISSIONS_OVERLAY: null,

    NOTEPAD_LINKS_OVERLAY_PERSON_OVERLAY: null,
    NOTEPAD_LINKS_OVERLAY_LOCATION_OVERLAY: null,

    CREATE_REPORT_MODAL: null,
    CREATE_REPORT_MODAL_V2: null,
    REPORT_LOADING_MODAL: null,
    REPORT_PREFILL_MODAL: null,
    SEQUENCE_INCREASE_MODAL: null,
    REMOVE_REN_MODAL: null,
    SUBMISSION_ERROR_MODAL: null,
    SEPARATE_EMBEDDED_REPORT_MODAL: null,

    BULK_MANAGE_CASES_SIDEPANEL: null,
    WILL_NOT_INVESTIGATE_MODAL: null,
    DELETE_CASE_NOTE_MODAL: null,

    EXPORTS_ATTACHMENT_SIDEPANEL: null,
    EXPORTS_REDACTION_SIDEPANEL: null,
    DELETE_USE_OF_FORCE_SUBJECT_MODAL: null,

    CREATE_MANAGE_CASE_MODAL: null,

    RESCIND_STAFF_REVIEW_MODAL: null,

    PERSONAL_DASHBOARD_SETTINGS_SIDEPANEL: null,

    CREATE_ANALYSIS_DASHBOARD_MODAL: null,

    SHOW_ALL_FIELDS_CONFIRMATION_MODAL: null,

    // Evidence
    CUSTODIAL_PROPERTY_CARD_OVERLAY: null,
    DISPOSITION_ACTION_SIDE_PANEL: null,
    DELETE_CHAIN_OF_CUSTODY_EVENT_OVERLAY: null,
    EVIDENCE_SET_HIGH_VALUE_MODAL: null,
    DELETE_CUSTOM_LABEL_MODAL: null,

    // Tasks
    TASK_ENTITIES_LINK_SIDE_PANEL: null,
    TASK_OVERLAY: null,
    TASK_ERROR_OVERLAY: null,
    TASK_ADD_UPDATE_OVERLAY: null,

    // Attachments
    ATTACHMENTS_CARD_ATTACHMENTS_SIDE_PANEL: null,
    CASE_ATTACHMENTS_SIDE_PANEL: null,
    CASE_NOTE_ATTACHMENTS_SIDE_PANEL: null,
    CASE_TASK_ATTACHMENTS_SIDE_PANEL: null,
    DELETE_ATTACHMENTS_CONFIRMATION_MODAL: null,
    ENTITY_PROFILE_HEADER_ATTACHMENTS_SIDE_PANEL: null,
    INLINE_ATTACHMENTS_ATTACHMENTS_SIDE_PANEL: null,
    REPORT_HEADER_ATTACHMENTS_SIDE_PANEL: null,
    INFECTED_FILES_FOUND_MODAL: null,

    // Saved Search
    SAVED_SEARCH_MODAL: null,
    SAVED_SEARCH_CREATE_MODAL: null,
    SAVED_SEARCH_SHARE_MODAL: null,
    COPY_SAVED_SEARCH_CONFIRMATION_MODAL: null,
    DELETE_SAVED_SEARCH_CONFIRMATION_MODAL: null,
    UPDATE_SAVED_SEARCH_CONFIRMATION_MODAL: null,

    KEYBOARD_SHORTCUTS_SIDE_PANEL: null,

    ITEM_SIDE_PANEL_ASSOCIATED_PERSONS: null,
    ITEM_SIDE_PANEL_ASSOCIATED_ORGANIZATIONS: null,
    REMOVE_DEX_DEVICE_CONFIRMATION_MODAL: null,

    // Merge Entities
    MERGE_ENTITIES_CONFIRMATION_MODAL: null,

    ROUTING_LABELS_FOR_EMBEDDED_ARREST_MODAL: null,
    ROUTING_LABELS_FOR_KEYBOARD_SHORTCUT: null,
    ROUTING_LABELS_FOR_REPORT_STATUS_COMMENTS_CARD_MODAL: null,
    ROUTING_LABELS_FOR_SIDEBAR_MODAL: null,

    DELETE_REPORT_FOR_REPORT_HEADER_MODAL: null,
    DELETE_REPORT_FOR_EMBEDDED_REPORT_MODAL: null,

    // Fillable PDF Admin
    ADMIN_TEST_HANDLEBARS_MODAL: null,

    // Resubmission Workflow
    COMPLIANCE_RESUBMISSION_MODAL: null,
    COMPLIANCE_RESUBMISSION_EXPORT_MODAL: null,

    // CLIPS
    WARRANT_SEND_TO_CLIPS_MODAL: null,
    // CLIPS Prefill
    CLIPS_PREFILL_SENDING_MODAL: null,

    // Case Folder
    DELETE_CASE_FOLDER_WARNING_MODAL: null,
    CREATE_FOLDER_MODAL: null,
    UPDATE_FOLDER_MODAL: null,
    DELETE_CASE_ATTACHMENTS_FOLDER_MODAL: null,

    // Photo Lineups
    CREATE_LINEUP_MODAL: null,
    DELETE_LINEUP_MODAL: null,
    MANUAL_LINEUP_MODAL: null,
    ERROR_MANUAL_LINEUP_MODAL: null,
    MAXIMUM_LINEUP_MODAL: null,

    // Report Level Segment Generation
    GENERATED_SEGMENTS_ERRORS_WRAPPER: null,

    // Case Report Links
    CREATE_CASE_FROM_MULTI_REPORTS_MODAL: null,
    REASON_FOR_RELATION_MODAL: null,
    REASON_FOR_RELATION_SIDE_PANEL: null,
    CREATE_NEW_REPORT_FROM_MULTI_REN_CASE_MODAL: null,
    REASON_FOR_REMOVAL_MODAL: null,
    INDIVIDUAL_REPORT_SELECTION_SIDE_PANEL: null,

    // Case Linked Profiles
    CASE_LINKED_PROFILES_SIDE_PANEL: null,

    // E-Files
    E_FILE_CREATION_MODAL: null,
    UPSERT_E_FILE_MODAL: null,
    E_FILE_IMPORT_INVOLVED_PROFILES_SIDE_PANEL: null,
    E_FILE_IMPORT_ITEMS_SIDE_PANEL: null,
    E_FILE_IMPORT_ATTACHMENTS_SIDE_PANEL: null,

    // Submissions
    CREATE_GENERIC_SUBMISSIONS_MODAL: null,

    // Briefings
    BRIEFING_ROUTING_MODAL: null,
    BRIEFING_ATTACHMENTS_SIDE_PANEL: null,
    BRIEFING_PERMISSIONS_MODAL: null,

    // Emails
    SEND_EMAIL_MODAL: null,

    // CAD Service Rotation
    CAD_SERVICE_PROVIDER_SUSPENSIONS_HISTORY_DRAWER: null,
    CAD_SERVICE_PROVIDER_ADD_OR_EDIT_SUSPENSION_DRAWER: null,
    CAD_SERVICE_PROVIDER_RESUME_SERVICE_MODAL: null,

    // DEx
    DEX_SIDE_PANEL: null,

    // Booking
    BOOKING_DETAILS_MODAL: null,
});

export type OverlayIdEnumType =
    | keyof typeof OverlayIdEnum
    | `SIGNATURE_CAPTURE_MODAL_${string}`
    | `CRASH_DIAGRAM_MODAL_${string}`;

export default OverlayIdEnum;
