import { EntityTypeEnum } from '@mark43/rms-api';
import React from 'react';
import styled from 'styled-components';

import pluralize from 'pluralize';
import notificationTypeEnum from '~/client-common/core/enums/universal/notificationTypeEnum';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import { joinTruthyValues } from '~/client-common/helpers/stringHelpers';
import { useCaseFieldName } from '~/client-common/core/fields/hooks/useFields';

const SemiBold = styled.span`
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
`;

export function getReportTitleWithRen(reportingEventNumber, reportTitle) {
    if (reportingEventNumber) {
        reportingEventNumber = `#${reportingEventNumber}`;
    }
    return joinTruthyValues([reportingEventNumber, reportTitle], ' ');
}

/**
 * The text in a summary row of a single UserNotification.
 * @param {Object} userNotificationViewModel
 */
export default function NotificationSummaryText({ userNotificationViewModel }) {
    const { notificationType, entityId, dataBlob } = userNotificationViewModel;
    const { userNotificationCreatedBy } = getViewModelProperties(userNotificationViewModel);
    const reportTitle = getReportTitleWithRen(
        dataBlob.reportingEventNumber,
        dataBlob.reportShortTitle
    );

    const associatedToReportTitle = getReportTitleWithRen(
        dataBlob.associatedToReportREN,
        dataBlob.associatedToReportTitle
    );
    const associatedFromReportTitle = getReportTitleWithRen(
        dataBlob.associatedFromReportREN,
        dataBlob.associatedFromReportTitle
    );

    const { singularCaseFieldName: caseDisplayName } = useCaseFieldName();

    switch (notificationType) {
        // Evidence Notifications

        case notificationTypeEnum.EVIDENCE_ITEM_OVERDUE_USER.name:
            return (
                <div>
                    <SemiBold>
                        Item #{dataBlob.reportingEventNumber}-{dataBlob.sequenceNumber}{' '}
                        {dataBlob.itemDescription}
                    </SemiBold>{' '}
                    is overdue. Please return it to{' '}
                    <SemiBold>{dataBlob.facilityLocationName}</SemiBold> at your earliest
                    convenience.
                </div>
            );
        case notificationTypeEnum.EVIDENCE_ITEM_OVERDUE_CLERK.name:
            // TODO: update and use the notified user view model from the
            // dataBlob when BE is done
            return (
                <div>
                    <SemiBold>
                        Item #{dataBlob.reportingEventNumber}-{dataBlob.sequenceNumber}{' '}
                        {dataBlob.itemDescription}
                    </SemiBold>{' '}
                    is overdue.
                    <SemiBold>{userNotificationCreatedBy.fullNameWithFirstInitial}</SemiBold> has
                    been notified.
                </div>
            );
        case notificationTypeEnum.EVIDENCE_REVIEW_ITEM_FOR_DISPOSITION.name:
            return (
                <div>
                    <SemiBold>
                        Item #{dataBlob.reportingEventNumber}-{dataBlob.sequenceNumber}{' '}
                        {dataBlob.itemDescription}
                    </SemiBold>{' '}
                    is ready for disposition review.
                </div>
            );
        case notificationTypeEnum.EVIDENCE_ITEM_APPROVED_FOR_DISPOSITION.name:
            return (
                <div>
                    <SemiBold>
                        Item #{dataBlob.reportingEventNumber}-{dataBlob.sequenceNumber}{' '}
                        {dataBlob.itemDescription}
                    </SemiBold>{' '}
                    has been approved for disposition.
                </div>
            );
        case notificationTypeEnum.EVIDENCE_ITEM_APPROVED_FOR_RELEASE.name:
            return (
                <div>
                    <SemiBold>
                        Item #{dataBlob.reportingEventNumber}-{dataBlob.sequenceNumber}{' '}
                        {dataBlob.itemDescription}
                    </SemiBold>{' '}
                    has been approved for release.
                </div>
            );

        // Reports Notifications

        case notificationTypeEnum.REPORT_RETURNED_TO_DRAFT_AFTER_SECONDARY_APPROVAL.name:
            return (
                <div>
                    <SemiBold>{reportTitle}</SemiBold> has been returned to draft by{' '}
                    <SemiBold>{dataBlob.actorFullNameWithBadge}</SemiBold>.
                </div>
            );
        case notificationTypeEnum.REPORT_IN_CASE_RETURNED_TO_DRAFT.name:
            return (
                <div>
                    <SemiBold>Report #{dataBlob.reportingEventNumber}</SemiBold> in{' '}
                    <SemiBold>{dataBlob.caseDisplayTitle}</SemiBold> has been returned to draft by{' '}
                    <SemiBold>{dataBlob.actorFullNameWithBadge}</SemiBold>.
                </div>
            );
        case notificationTypeEnum.REPORT_IN_CASE_APPROVED.name:
            return (
                <div>
                    <SemiBold>Report #{dataBlob.reportingEventNumber}</SemiBold> in{' '}
                    <SemiBold>{dataBlob.caseDisplayTitle}</SemiBold> has been approved by{' '}
                    <SemiBold>{dataBlob.actorFullNameWithBadge}</SemiBold>.
                </div>
            );
        case notificationTypeEnum.REPORT_IN_CASE_REJECTED.name:
            return (
                <div>
                    <SemiBold>Report #{dataBlob.reportingEventNumber}</SemiBold> in{' '}
                    <SemiBold>{dataBlob.caseDisplayTitle}</SemiBold> has been rejected by{' '}
                    <SemiBold>{dataBlob.actorFullNameWithBadge}</SemiBold>.
                </div>
            );
        case notificationTypeEnum.REPORT_REN_UPDATED.name:
            return (
                <div>
                    <SemiBold>Report #{dataBlob.oldReportingEventNumber}</SemiBold> has been updated
                    to <SemiBold>Report #{dataBlob.newReportingEventNumber}</SemiBold>.
                </div>
            );
        case notificationTypeEnum.REPORT_APPROVED.name:
            return (
                <div>
                    <SemiBold>{reportTitle}</SemiBold> has been approved by{' '}
                    <SemiBold>{dataBlob.actorFullNameWithBadge}</SemiBold>.
                </div>
            );
        case notificationTypeEnum.REPORT_REJECTED.name:
            return (
                <div>
                    <SemiBold>{reportTitle}</SemiBold> has been rejected by{' '}
                    <SemiBold>{dataBlob.actorFullNameWithBadge}</SemiBold>,{' '}
                    <SemiBold>{dataBlob.comments}</SemiBold>
                </div>
            );
        case notificationTypeEnum.REPORT_REJECTED_BY_SECONDARY.name:
            return (
                <div>
                    <SemiBold>{reportTitle}</SemiBold> has been rejected by UCR,{' '}
                    <SemiBold>{dataBlob.comments}</SemiBold>
                </div>
            );
        case notificationTypeEnum.REPORT_APPROVAL_REQUESTED.name:
            return (
                <div>
                    <SemiBold>{reportTitle}</SemiBold> was submitted by{' '}
                    <SemiBold>{dataBlob.actorFullNameWithBadge}</SemiBold>.
                </div>
            );
        case notificationTypeEnum.ASSOCIATED_RECORD_ADDED_TO_REPORT.name:
            if (dataBlob.reportInCase === true) {
                return (
                    <div>
                        <SemiBold>{associatedToReportTitle}</SemiBold> was added as an associated
                        record to <SemiBold>{associatedFromReportTitle}</SemiBold> in your{' '}
                        <SemiBold>
                            {caseDisplayName} #{dataBlob.caseLocalId}
                        </SemiBold>
                    </div>
                );
            }

            return (
                <div>
                    <SemiBold>{associatedToReportTitle}</SemiBold> was added as an associated record
                    to a report for which you're an author{' '}
                    <SemiBold>{associatedFromReportTitle}</SemiBold>
                </div>
            );
        case notificationTypeEnum.SUGGESTED_ASSOCIATED_RECORD_ADDED_TO_REPORT.name:
            return (
                <div>
                    <SemiBold>{associatedToReportTitle}</SemiBold> was added as a suggested
                    associated record to a report for which you're an author{' '}
                    <SemiBold>{associatedFromReportTitle}</SemiBold>
                </div>
            );
        case notificationTypeEnum.REPORT_COMMENT_CREATED.name:
            return (
                <div>
                    <SemiBold>1 new narrative comment</SemiBold> by{' '}
                    <SemiBold>{dataBlob.commenterDisplayName}</SemiBold> on report{' '}
                    <SemiBold>{dataBlob.reportingEventNumber}</SemiBold>
                </div>
            );
        case notificationTypeEnum.REPORT_DELETED.name:
            const reportTitleWithDisplayName = getReportTitleWithRen(
                dataBlob.reportDisplayName,
                dataBlob.reportShortTitle
            );
            return (
                <div>
                    <SemiBold>{reportTitleWithDisplayName}</SemiBold> has been deleted by{' '}
                    <SemiBold>{dataBlob.actorFullNameWithBadge}</SemiBold>.
                </div>
            );
        case notificationTypeEnum.REPORT_PERMISSION_MODIFIED.name:
            return (
                <div>
                    <SemiBold>{reportTitle}</SemiBold> permission has been modified by{' '}
                    <SemiBold>{dataBlob.actorFullNameWithBadge}</SemiBold>.
                </div>
            );
        case notificationTypeEnum.REPORT_AUTHOR_ADDED.name:
            return (
                <div>
                    <SemiBold>{dataBlob.actorFullNameWithBadge}</SemiBold> has been added as an
                    Author on Report <SemiBold>{reportTitle}</SemiBold>.
                </div>
            );
        case notificationTypeEnum.REPORT_OWNER_MODIFIED.name:
            return (
                <div>
                    <SemiBold>{reportTitle}</SemiBold> owner has been modified from{' '}
                    <SemiBold>{dataBlob.oldOwnerFullName}</SemiBold> to{' '}
                    <SemiBold>{dataBlob.newOwnerFullName}</SemiBold>.
                </div>
            );

        // Cases Notifications

        case notificationTypeEnum.REPORT_ADDED_TO_CASE.name:
            return (
                <div>
                    <SemiBold>Report #{dataBlob.reportingEventNumber}</SemiBold> has been added to{' '}
                    <SemiBold>{dataBlob.caseDisplayTitle}</SemiBold> by{' '}
                    <SemiBold>{dataBlob.actorFullNameWithBadge}</SemiBold>.
                </div>
            );
        case notificationTypeEnum.REPORT_REMOVED_FROM_CASE.name:
            return (
                <div>
                    <SemiBold>Report #{dataBlob.reportingEventNumber}</SemiBold> has been removed
                    from <SemiBold>{dataBlob.caseDisplayTitle}</SemiBold> by{' '}
                    <SemiBold>{dataBlob.actorFullNameWithBadge}</SemiBold>.
                </div>
            );
        case notificationTypeEnum.USER_CASE_ASSIGNMENT.name:
            return (
                <div>
                    You have been {dataBlob.caseRoleName}{' '}
                    <SemiBold>
                        {caseDisplayName} #{dataBlob.caseLocalId}
                    </SemiBold>
                    .
                </div>
            );
        case notificationTypeEnum.USER_CASE_UNASSIGNMENT.name:
            return (
                <div>
                    You are no longer the {dataBlob.departmentAssigneeDisplayName} on{' '}
                    <SemiBold>
                        {caseDisplayName} #{dataBlob.caseLocalId}
                    </SemiBold>
                    .
                </div>
            );
        case notificationTypeEnum.USER_CASE_ASSIGNMENT_UPDATED_SUPERVISOR.name:
            if (dataBlob.assignmentUpdated === true) {
                return (
                    <div>
                        <SemiBold>
                            {caseDisplayName} #{dataBlob.caseLocalId}
                        </SemiBold>{' '}
                        {dataBlob.departmentAssigneeDisplayName} has been updated to{' '}
                        <SemiBold>{dataBlob.assigneeFullname}</SemiBold>.
                    </div>
                );
            }

            return (
                <div>
                    <SemiBold>{dataBlob.assigneeFullname}</SemiBold> was removed as{' '}
                    {dataBlob.departmentAssigneeDisplayName} on{' '}
                    <SemiBold>
                        {caseDisplayName} #{dataBlob.caseLocalId}
                    </SemiBold>
                    .
                </div>
            );
        case notificationTypeEnum.UNIT_CASE_ASSIGNMENT.name:
            return (
                <div>
                    <SemiBold>
                        {caseDisplayName} #{dataBlob.caseLocalId}
                    </SemiBold>{' '}
                    has been assigned to your unit for investigation.
                </div>
            );
        case notificationTypeEnum.CASE_NOTE_ADDED.name:
            return (
                <div>
                    <SemiBold>{dataBlob.actorFullNameWithBadge}</SemiBold> added a{' '}
                    {caseDisplayName.toLowerCase()} note to{' '}
                    <SemiBold>
                        {caseDisplayName} #{dataBlob.caseLocalId}
                    </SemiBold>
                    .
                </div>
            );
        case notificationTypeEnum.CASE_NOTE_DELETED.name:
            return (
                <div>
                    <SemiBold>{dataBlob.actorFullNameWithBadge}</SemiBold> removed a{' '}
                    {caseDisplayName.toLowerCase()} note from{' '}
                    <SemiBold>
                        {caseDisplayName} #{dataBlob.caseLocalId}
                    </SemiBold>
                    .
                </div>
            );
        case notificationTypeEnum.CASE_STATUS_CHANGED.name:
            return (
                <div>
                    <SemiBold>
                        {caseDisplayName} #{dataBlob.caseLocalId}
                    </SemiBold>{' '}
                    status changed to <SemiBold>{dataBlob.status}</SemiBold> by{' '}
                    <SemiBold>{dataBlob.actorFullNameWithBadge}</SemiBold>.
                </div>
            );
        case notificationTypeEnum.CASE_REVIEW_REQUESTED.name:
            return (
                <div>
                    <SemiBold>{dataBlob.actorFullNameWithBadge}</SemiBold> has requested you review{' '}
                    <SemiBold>
                        {caseDisplayName} #{dataBlob.caseLocalId}
                    </SemiBold>{' '}
                    <SemiBold>{dataBlob.body}</SemiBold>.
                </div>
            );
        case notificationTypeEnum.CASE_REVIEWED.name:
            return (
                <div>
                    Review of{' '}
                    <SemiBold>
                        {caseDisplayName} #{dataBlob.caseLocalId}
                    </SemiBold>{' '}
                    from <SemiBold>{dataBlob.actorFullNameWithBadge}</SemiBold>{' '}
                    <SemiBold>{dataBlob.body}</SemiBold>.
                </div>
            );
        case notificationTypeEnum.CASE_APPROVED.name:
            return (
                <div>
                    <SemiBold>
                        {caseDisplayName} #{dataBlob.caseLocalId}
                    </SemiBold>{' '}
                    was approved by <SemiBold>{dataBlob.actorFullNameWithBadge}</SemiBold>.
                </div>
            );
        case notificationTypeEnum.CASE_REJECTED.name:
            return (
                <div>
                    <SemiBold>
                        {caseDisplayName} #{dataBlob.caseLocalId}
                    </SemiBold>{' '}
                    was rejected by <SemiBold>{dataBlob.actorFullNameWithBadge}</SemiBold>{' '}
                    <SemiBold>{dataBlob.body}</SemiBold>.
                </div>
            );
        case notificationTypeEnum.REPORT_CASE_STATUS_CHANGED.name:
            return (
                <div>
                    <SemiBold>
                        Report #{dataBlob.reportingEventNumber} {dataBlob.reportDefinition}
                    </SemiBold>{' '}
                    in <SemiBold>{dataBlob.caseDisplayTitle}</SemiBold> status changed to{' '}
                    <SemiBold>{dataBlob.status}</SemiBold> by{' '}
                    <SemiBold>{dataBlob.actorFullNameWithBadge}</SemiBold>.
                </div>
            );

        // Tasks Notifications
        case notificationTypeEnum.USER_TASK_ASSIGNMENT.name:
            switch (dataBlob.ownerType) {
                case EntityTypeEnum.CASE.name:
                    return (
                        <div>
                            You have been assigned a Task for {caseDisplayName} #
                            <SemiBold>{dataBlob.ownerTitle}</SemiBold>
                        </div>
                    );
                case EntityTypeEnum.REPORT.name:
                    return (
                        <div>
                            You have been assigned a Task for{' '}
                            <SemiBold>{dataBlob.ownerTitle}</SemiBold>
                        </div>
                    );
                default:
                    return <div>You have been assigned a Task</div>;
            }
        case notificationTypeEnum.TASK_STATUS_CHANGED.name:
            switch (dataBlob.ownerType) {
                case EntityTypeEnum.CASE.name:
                    return (
                        <div>
                            Your task <SemiBold>{dataBlob.title}</SemiBold> on {caseDisplayName} #
                            <SemiBold>{dataBlob.ownerTitle}</SemiBold> has been updated to{' '}
                            <SemiBold>{dataBlob.status}</SemiBold>
                        </div>
                    );
                case EntityTypeEnum.REPORT.name:
                    return (
                        <div>
                            Your task <SemiBold>{dataBlob.title}</SemiBold> on{' '}
                            <SemiBold>{dataBlob.ownerTitle}</SemiBold> has been updated to{' '}
                            <SemiBold>{dataBlob.status}</SemiBold>
                        </div>
                    );
                default:
                    return (
                        <div>
                            Your task <SemiBold>{dataBlob.title}</SemiBold> has been updated to{' '}
                            <SemiBold>{dataBlob.status}</SemiBold>
                        </div>
                    );
            }
        case notificationTypeEnum.TASK_DUE_TODAY.name:
            switch (dataBlob.ownerType) {
                case EntityTypeEnum.CASE.name:
                    return (
                        <div>
                            Your task <SemiBold>{dataBlob.title}</SemiBold> for {caseDisplayName} #{' '}
                            <SemiBold>{dataBlob.ownerTitle}</SemiBold> is due{' '}
                            <SemiBold>{dataBlob.today}</SemiBold>
                        </div>
                    );
                case EntityTypeEnum.REPORT.name:
                    return (
                        <div>
                            Your task <SemiBold>{dataBlob.title}</SemiBold> for{' '}
                            <SemiBold>{dataBlob.ownerTitle}</SemiBold> is due{' '}
                            <SemiBold>{dataBlob.today}</SemiBold>
                        </div>
                    );
                default:
                    return (
                        <div>
                            Your task <SemiBold>{dataBlob.title}</SemiBold> is due{' '}
                            <SemiBold>{dataBlob.today}</SemiBold>
                        </div>
                    );
            }
        // Subscriptions
        case notificationTypeEnum.SUBSCRIPTION_HIT.name:
            return (
                <div>
                    There {pluralize('is', dataBlob.resultCount)}{' '}
                    <SemiBold>{dataBlob.resultCount}</SemiBold> new{' '}
                    {pluralize('result', dataBlob.resultCount)} for your{' '}
                    <SemiBold>{dataBlob.elasticSearchTypeDisplayName}</SemiBold> saved search{' '}
                    <SemiBold>{dataBlob.savedSearchName}</SemiBold>
                </div>
            );
        // Saved Searches
        case notificationTypeEnum.SHARED_SAVED_SEARCH.name:
            return (
                <div>
                    A saved search <SemiBold>{dataBlob.savedSearchName}</SemiBold> has been shared
                    with you
                </div>
            );
        case notificationTypeEnum.SHARED_SAVED_SEARCH_UPDATED.name:
            return (
                <div>
                    Saved Search <SemiBold>{dataBlob.savedSearchName}</SemiBold> has been updated
                </div>
            );
        case notificationTypeEnum.SHARED_SAVED_SEARCH_DELETED.name:
            return (
                <div>
                    Saved Search <SemiBold>{dataBlob.savedSearchName}</SemiBold> has been deleted
                </div>
            );

        case notificationTypeEnum.TEST.name:
            return (
                <div>
                    <SemiBold>This is a test notification message</SemiBold> this is a test
                    notification message this is a test notification message this is a test
                    notification message this is a test notification message this is a test
                    notification message this is a test notification message this is a test
                    notification message this is a test notification message this is a test
                    notification message this is a test notification message this is a test
                    notification message this is a test notification message this is a test
                    notification message this is a test notification message this is a test
                    notification message this is a test notification message this is a test
                    notification message by{' '}
                    <SemiBold>{userNotificationCreatedBy.fullNameWithFirstInitial}</SemiBold>
                </div>
            );
        // CAD
        case notificationTypeEnum.TEST_CAD_HIGH.name:
            return <div>Test CAD Notification - High Priority</div>;
        case notificationTypeEnum.TEST_CAD_MEDIUM.name:
            return <div>Test CAD Notification - Medium Priority</div>;
        case notificationTypeEnum.BOLO.name:
            return (
                <div>
                    BOLO{' '}
                    <SemiBold>
                        {dataBlob.boloReportingNumber} {dataBlob.categoryDisplay}
                    </SemiBold>
                </div>
            );
        case notificationTypeEnum.OFFICER_EMERGENCY.name:
            return <div>Officer Emergency</div>;
        case notificationTypeEnum.INCOMING_ASSIGNMENT.name:
            return <div>Incoming Assignment</div>;
        case notificationTypeEnum.HIGH_PRIORITY_NARRATIVE.name:
            return <div>High Priority Narrative</div>;
        case notificationTypeEnum.RFA_URGENT_MESSAGE.name:
        case notificationTypeEnum.RFA_TIMED_OUT.name:
        case notificationTypeEnum.RFA_FAILURE.name:
            return (
                <div>
                    <SemiBold>Event #{entityId}</SemiBold> {dataBlob.title}
                </div>
            );
        default:
            return null;
    }
}
