import React, { createContext, useCallback, useEffect, useMemo, useReducer } from 'react';
import { logError } from '../../../../core/logging';

interface State {
    goodDataIframeLoaded: boolean;
    goodDataEventSubscriptions: { [eventType: string]: ((eventData: GoodDataEventData) => void)[] };
}

interface Action {
    type:
        | 'SET_GOODDATA_IFRAME_LOADED'
        | 'ADD_GOODDATA_EVENT_CALLBACK'
        | 'REMOVE_GOODDATA_EVENT_CALLBACK';
    loaded?: boolean;
    eventName?: string;
    callback?: (eventData: GoodDataEventData) => void;
}

interface GoodDataEventData {
    gdc?: {
        event: {
            name: string;
        };
    };
}

export const InsightsContext = createContext<{
    setGoodDataIframeLoaded: (loaded: boolean) => void;
    addGoodDataEventCallback: (
        eventType: string,
        callback: (eventData: GoodDataEventData) => void
    ) => void;
    removeGoodDataEventCallback: (
        eventType: string,
        callback: (eventData: GoodDataEventData) => void
    ) => void;
} | null>(null);

function reducer(state: State, action: Action): State {
    switch (action.type) {
        case 'SET_GOODDATA_IFRAME_LOADED':
            return { ...state, goodDataIframeLoaded: action.loaded ?? false };
        case 'ADD_GOODDATA_EVENT_CALLBACK':
            if (!action.eventName || !action.callback) {
                return state;
            }
            return {
                ...state,
                goodDataEventSubscriptions: {
                    ...state.goodDataEventSubscriptions,
                    [action.eventName]: [
                        ...(state.goodDataEventSubscriptions[action.eventName] || []),
                        action.callback,
                    ],
                },
            };
        case 'REMOVE_GOODDATA_EVENT_CALLBACK':
            if (!action.eventName || !action.callback) {
                return state;
            }
            return {
                ...state,
                goodDataEventSubscriptions: {
                    ...state.goodDataEventSubscriptions,
                    [action.eventName]: state.goodDataEventSubscriptions[action.eventName].filter(
                        (cb) => cb !== action.callback
                    ),
                },
            };
        default:
            return state;
    }
}

const setGoodDataIframeLoaded = (loaded: boolean): Action => ({
    type: 'SET_GOODDATA_IFRAME_LOADED',
    loaded,
});

const addGoodDataEventCallback = (
    eventName: string,
    callback: (eventData: GoodDataEventData) => void
): Action => ({
    type: 'ADD_GOODDATA_EVENT_CALLBACK',
    eventName,
    callback,
});

const removeGoodDataEventCallback = (
    eventName: string,
    callback: (eventData: GoodDataEventData) => void
): Action => ({
    type: 'REMOVE_GOODDATA_EVENT_CALLBACK',
    eventName,
    callback,
});

function useActions(dispatch: React.Dispatch<Action>) {
    const handleSetGoodDataIframeLoaded = useCallback(
        (loaded: boolean) => {
            dispatch(setGoodDataIframeLoaded(loaded));
        },
        [dispatch]
    );

    const handleAddGoodDataEventCallback = useCallback(
        (eventType: string, callback: (eventData: GoodDataEventData) => void) => {
            dispatch(addGoodDataEventCallback(eventType, callback));
        },
        [dispatch]
    );

    const handleRemoveGoodDataEventCallback = useCallback(
        (eventType: string, callback: (eventData: GoodDataEventData) => void) => {
            dispatch(removeGoodDataEventCallback(eventType, callback));
        },
        [dispatch]
    );

    return {
        setGoodDataIframeLoaded: handleSetGoodDataIframeLoaded,
        addGoodDataEventCallback: handleAddGoodDataEventCallback,
        removeGoodDataEventCallback: handleRemoveGoodDataEventCallback,
    };
}

export const InsightsContextProvider: React.FC = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, {
        goodDataIframeLoaded: false,
        goodDataEventSubscriptions: {},
    });
    const actions = useActions(dispatch);

    useEffect(() => {
        if (!state.goodDataIframeLoaded) {
            return;
        }

        const handleMessage = (event: MessageEvent) => {
            const goodDataIframe = document.getElementsByClassName(
                'insights-dashboard-frame'
            )[0] as HTMLIFrameElement;

            if (goodDataIframe?.contentWindow && event.source === goodDataIframe.contentWindow) {
                let goodDataEventData: GoodDataEventData;
                try {
                    goodDataEventData = JSON.parse(event.data);
                } catch (error) {
                    logError(`Failed to parse gooddata event data: ${error}`);
                    return;
                }
                if (goodDataEventData.gdc) {
                    state.goodDataEventSubscriptions[goodDataEventData.gdc.event.name]?.forEach(
                        (callback) => callback(goodDataEventData)
                    );
                }
            }
        };

        window.addEventListener('message', handleMessage);

        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, [state.goodDataIframeLoaded, state.goodDataEventSubscriptions, actions]);

    const contextValue = useMemo(
        () => ({
            ...actions,
        }),
        [actions]
    );

    return <InsightsContext.Provider value={contextValue}>{children}</InsightsContext.Provider>;
};
