import { EntityTypeEnum, DynamicCardTypeEnum, LinkTypesEnum } from '@mark43/rms-api';
import React, { useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { FederatedSearchExternalWriteDataContext } from 'mark43-federated-search';

import styled from 'styled-components';
import { filter, map } from 'lodash';
import { mediaQueries } from 'arc';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import reportCardEnum from '~/client-common/core/enums/universal/reportCardEnum';
import { arrestForReportIdSelector } from '~/client-common/core/domain/arrests/state/data';
import { formatReportTitleForReportIdSelector } from '~/client-common/core/domain/reports/state/ui';
import {
    reportDefinitionHasCardSelector,
    reportDefinitionByIdSelector,
} from '~/client-common/core/domain/report-definitions/state/data';

import {
    currentReportSelector,
    canEditReportCardStatusSelector,
    currentReportIsSnapshotSelector,
    offenseModifyingSupplementRenOffenseReportIdSelector,
    isCurrentReportNewSelector,
} from '../../../../legacy-redux/selectors/reportSelectors';
import AttachmentsCard from '../../../core/components/cards/AttachmentsCard';
import { editMediator } from '../state/ui/submissions';
import ReportSidebar from '../../../../legacy-redux/components/reports/ReportSidebar';
import RecordsSidebar from '../../../records/core/components/sidebar/RecordsSidebar';
import CustodialPropertySummary from '../../custodial-property-summary/components/CustodialPropertySummary';
import AssociatedRecordsSection from '../../../records/associated-records/components/AssociatedRecordsSection';
import DownloadablesCard from '../../../../legacy-redux/components/reports/DownloadablesCard';
import FocusWrapper from '../../../core/components/FocusWrapper';
import { LocationQuickAddContext } from '../../../core/context/LocationQuickAddContext';
import { PersonQuickAddContext } from '../../../core/context/PersonQuickAddContext';
import { OrganizationQuickAddContext } from '../../../core/context/OrganizationQuickAddContext';
import { Main as DragonMain } from '../../../dragon/main';

import { EntitySidebar } from '../../entity-sidebar/components/EntitySidebar';
import TrafficCrashCard from '../../traffic-crash/components/TrafficCrashCard';
import QuickCrashLinkCard from '../../traffic-crash/components/QuickCrashLinkCard';
import { RecentErrorSubmissionCard } from '../../../submissions/components';
import TrafficCrashEventInfoCard from './traffic-crash-event-info/TrafficCrashEventInfoCard';
import ChargesCard from './charges/ChargesCard';
import UseOfForceCard from './use-of-force/UseOfForceCard';
import NarrativeCard from './narrative/NarrativeCard';
import CitationCard from './citation/CitationCard';
import ImpoundCard from './impound/ImpoundCard';
import CommunityInfoCard from './community-info/CommunityInfoCard';
import MissingPersonsCard from './missing-persons/MissingPersonsCard';
import FieldContactCard from './field-contact/FieldContactCard';
import CourtCaseCard from './court-case/CourtCaseCard';
import TowVehicleCard from './tow-vehicle/TowVehicleCard';
import ReportSupplementInfoMessage from './offense/ReportSupplementInfoMessage';
import BookingCard from './booking/BookingCard';
import SealedReportCard from './sealed/SealedReportCard';
import ArrestCard from './arrest/ArrestCard';
import PropertyCard from './items/PropertyCard';
import VehicleCard from './items/VehicleCard';
import StopCard from './stop/StopCard';
import TowVehicleReleaseCard from './tow-vehicle-release/TowVehicleReleaseCard';
import TowVehicleCheckInCard from './tow-vehicle-check-in/TowVehicleCheckInCard';
import TowVehicleImpoundCard from './tow-vehicle/TowVehicleImpoundCard';
import BehavioralCrisisCard from './behavioral-crisis/BehavioralCrisisCard';
import UseOfForceSubjectCardsWrapper from './use-of-force-subject/UseOfForceSubjectCardsWrapper';
import RelationshipsCardWrapper from './relationships/RelationshipsCardWrapper';
import InvolvedProfilesCard from './involved-profiles/InvolvedProfilesCard';
import ReportStatusCommentsCard from './report-status-comments/ReportStatusCommentsCard';
import EventInfoCard from './event-info/EventInfoCard';
import OffenseIncidentCardsWrapper from './OffenseIncidentCardsWrapper';
import SupplementInfoCard from './supplement-info/SupplementInfoCard';
import SummaryNarrativeCardWrapper from './summary-narrative/SummaryNarrativeCard';
import TrafficCrashLocationCard from './traffic-crash-location/TrafficCrashLocationCard';
import ArrestBlock from './ArrestBlock';
import ReportLegacyMetadataCardWrapper from './report-legacy-metadata/ReportLegacyMetadataCard';

// this is acting as the scrollable under subheader component
const StyledFocusWrapper = styled(FocusWrapper)`
    && {
        gap: 24px;
        flex-direction: column;
        justify-content: stretch;
        position: relative;
        padding: 0 var(--arc-space-3);

        @media (min-width: ${mediaQueries.md}) {
            flex-direction: row;
            justify-content: center;
        }

        @media (min-width: ${mediaQueries.lg}) {
            padding: 0;
        }
    }
`;

const CardContainer = styled.div`
    width: 100%;
    justify-content: stretch;
    display: flex;
    flex-direction: column;
    height: fit-content;

    @media (min-width: ${mediaQueries.md}) {
        width: 55%;
        max-width: 590px;
        margin-bottom: 48px;
    }
`;

// Passing in router and store to ReportSidebar because it is only rendered by backbone
function ReportPage({
    currentReport,
    reportDefinitionHasCard,
    router,
    formatReportTitleForReportId,
    canEditReportCardStatus,
    editMediator,
    currentReportIsSnapshot,
    reportDefinitionById,
    arrestForReportId,
    offenseModifyingSupplementRenOffenseReportId,
    isNewReport,
}) {
    /**
     * When the report page unmounts, we want to reset the state
     * for quick add so that we re-fetch recent entities when
     * we leave a report
     */
    const { resetState: resetLocationQuickAddState } = LocationQuickAddContext.useContainer();
    const { resetState: resetPersonQuickAddState } = PersonQuickAddContext.useContainer();
    const { resetState: resetOrgQuickAddState } = OrganizationQuickAddContext.useContainer();
    const { resetResultForConsumerApp } = useContext(FederatedSearchExternalWriteDataContext) || {};

    useEffect(() => {
        return () => {
            resetLocationQuickAddState();
            resetPersonQuickAddState();
            resetOrgQuickAddState();
            resetResultForConsumerApp?.();
        };
    }, [
        resetLocationQuickAddState,
        resetPersonQuickAddState,
        resetOrgQuickAddState,
        resetResultForConsumerApp,
    ]);

    if (!currentReport) {
        return null;
    }

    const { reportDefinitionId, id, reportingEventNumber } = currentReport;

    if (reportDefinitionHasCard(reportDefinitionId, reportCardEnum.CUSTODIAL.id)) {
        return <CustodialPropertySummary router={router} />;
    }
    if (currentReport.isSealed) {
        return (
            <FocusWrapper className="mark43-scrollable-under-subheader">
                <CardContainer>
                    <SealedReportCard />
                </CardContainer>
                <RecordsSidebar>
                    <ReportSidebar router={router} />
                </RecordsSidebar>
            </FocusWrapper>
        );
    }

    const { canEditReportCard, errorMessage } = canEditReportCardStatus;
    const editCallback = (callback) => editMediator(callback);
    const reportDefinition = reportDefinitionById(reportDefinitionId);
    const downloadableCards = filter(
        reportDefinition.cards,
        (card) => card.dynamicCardType === DynamicCardTypeEnum.DOWNLOADABLES.name
    );
    const arrest = arrestForReportId(id);
    const baseReportId = offenseModifyingSupplementRenOffenseReportId || currentReport.id;

    return (
        <StyledFocusWrapper className="mark43-scrollable-under-subheader">
            <CardContainer>
                <ReportSupplementInfoMessage
                    isSnapshot={currentReportIsSnapshot}
                    reportId={id}
                    router={router}
                />
                <QuickCrashLinkCard />

                <FeatureFlagged flag="RMS_GENERIC_EXTERNAL_SUBMISSIONS_ENABLED">
                    <RecentErrorSubmissionCard />
                </FeatureFlagged>

                {reportDefinitionHasCard(reportDefinitionId, reportCardEnum.EVENT_INFO.id) && (
                    <EventInfoCard router={router} editCallback={editCallback} />
                )}

                <FeatureFlagged flag="RMS_CRASH_DIAGRAM_ENABLED">
                    {reportDefinitionHasCard(
                        reportDefinitionId,
                        reportCardEnum.CRASH_EVENT_INFO.id
                    ) && (
                        <TrafficCrashEventInfoCard
                            router={router}
                            currentReportId={id}
                            editCallback={editCallback}
                        />
                    )}

                    {reportDefinitionHasCard(
                        reportDefinitionId,
                        reportCardEnum.CRASH_LOCATION_INFO.id
                    ) && (
                        <TrafficCrashLocationCard
                            router={router}
                            currentReportId={currentReport.id}
                            editCallback={editCallback}
                        />
                    )}
                </FeatureFlagged>

                {reportDefinitionHasCard(reportDefinitionId, reportCardEnum.SUPPLEMENT_INFO.id) && (
                    <SupplementInfoCard
                        editCallback={editCallback}
                        currentReportId={id}
                        router={router}
                    />
                )}
                <FeatureFlagged flag="RMS_LEGACY_MIGRATION_INFO_CARD_ENABLED">
                    {reportDefinitionHasCard(reportDefinitionId, reportCardEnum.LEGACY_INFO.id) && (
                        <ReportLegacyMetadataCardWrapper currentReportId={id} />
                    )}
                </FeatureFlagged>

                {map(downloadableCards, (card) => {
                    return <DownloadablesCard router={router} key={card.id} cardDetails={card} />;
                })}
                {reportDefinitionHasCard(
                    reportDefinitionId,
                    reportCardEnum.BEHAVIORAL_CRISIS.id
                ) && (
                    <BehavioralCrisisCard
                        editCallback={editCallback}
                        currentReportId={id}
                        router={router}
                    />
                )}
                {reportDefinitionHasCard(reportDefinitionId, reportCardEnum.FIELD_CONTACT.id) && (
                    <FieldContactCard
                        editCallback={editCallback}
                        currentReportId={id}
                        router={router}
                    />
                )}
                {reportDefinitionHasCard(
                    reportDefinitionId,
                    reportCardEnum.COMMUNITY_INFORMATION.id
                ) && (
                    <CommunityInfoCard
                        editCallback={editCallback}
                        currentReportId={id}
                        router={router}
                    />
                )}
                {reportDefinitionHasCard(reportDefinitionId, reportCardEnum.MISSING_PERSONS.id) && (
                    <MissingPersonsCard
                        editCallback={editCallback}
                        currentReportId={id}
                        router={router}
                    />
                )}
                {reportDefinitionHasCard(
                    reportDefinitionId,
                    reportCardEnum.INVOLVED_PROFILES.id
                ) && (
                    <InvolvedProfilesCard
                        editCallback={editCallback}
                        currentReportId={id}
                        router={router}
                    />
                )}
                {reportDefinitionHasCard(reportDefinitionId, reportCardEnum.CITATION.id) && (
                    <CitationCard
                        editCallback={editCallback}
                        currentReportId={id}
                        router={router}
                    />
                )}
                {reportDefinitionHasCard(reportDefinitionId, reportCardEnum.TRAFFIC_CRASH.id) && (
                    <TrafficCrashCard
                        editCallback={editCallback}
                        currentReportId={id}
                        router={router}
                    />
                )}
                {reportDefinitionHasCard(reportDefinitionId, reportCardEnum.USE_OF_FORCE.id) && (
                    <UseOfForceCard
                        editCallback={editCallback}
                        currentReportId={id}
                        router={router}
                    />
                )}
                {reportDefinitionHasCard(
                    reportDefinitionId,
                    reportCardEnum.USE_OF_FORCE_SUBJECT.id
                ) && (
                    <UseOfForceSubjectCardsWrapper
                        editMode={false}
                        editCallback={editCallback}
                        reportId={id}
                        router={router}
                        currentReportREN={reportingEventNumber}
                    />
                )}
                {reportDefinitionHasCard(reportDefinitionId, reportCardEnum.ARREST.id) && (
                    <ArrestCard editCallback={editCallback} currentReportId={id} router={router} />
                )}
                {reportDefinitionHasCard(reportDefinitionId, reportCardEnum.PROPERTY.id) && (
                    <PropertyCard
                        ownerType={EntityTypeEnum.REPORT.name}
                        editCallback={editCallback}
                        currentReportId={id}
                        ownerId={baseReportId}
                        reportingEventNumber={reportingEventNumber}
                        router={router}
                    />
                )}
                {reportDefinitionHasCard(reportDefinitionId, reportCardEnum.VEHICLE.id) && (
                    <VehicleCard
                        ownerType={EntityTypeEnum.REPORT.name}
                        editCallback={editCallback}
                        currentReportId={id}
                        ownerId={baseReportId}
                        reportingEventNumber={reportingEventNumber}
                        router={router}
                        index={id}
                    />
                )}
                {reportDefinitionHasCard(reportDefinitionId, reportCardEnum.STOP.id) && (
                    <StopCard editCallback={editCallback} currentReportId={id} router={router} />
                )}
                {reportDefinitionHasCard(reportDefinitionId, reportCardEnum.IMPOUND.id) && (
                    <ImpoundCard editCallback={editCallback} currentReportId={id} router={router} />
                )}
                {reportDefinitionHasCard(reportDefinitionId, reportCardEnum.TOW_VEHICLE.id) && (
                    <TowVehicleCard
                        editCallback={editCallback}
                        currentReportId={id}
                        router={router}
                    />
                )}
                {reportDefinitionHasCard(
                    reportDefinitionId,
                    reportCardEnum.TOW_VEHICLE_IMPOUND.id
                ) && (
                    <TowVehicleImpoundCard
                        editCallback={editCallback}
                        currentReportId={id}
                        router={router}
                    />
                )}
                {reportDefinitionHasCard(
                    reportDefinitionId,
                    reportCardEnum.CHECK_IN_INFORMATION.id
                ) && <TowVehicleCheckInCard reportId={id} router={router} />}
                {reportDefinitionHasCard(
                    reportDefinitionId,
                    reportCardEnum.RELEASE_INFORMATION.id
                ) && <TowVehicleReleaseCard reportId={id} router={router} />}
                {reportDefinitionHasCard(reportDefinitionId, reportCardEnum.CHARGES.id) &&
                    arrest && (
                        <ChargesCard editCallback={editCallback} reportId={id} router={router} />
                    )}
                {reportDefinitionHasCard(reportDefinitionId, reportCardEnum.BOOKING.id) && (
                    <BookingCard editCallback={editCallback} currentReportId={id} router={router} />
                )}
                {reportDefinitionHasCard(reportDefinitionId, reportCardEnum.COURT_CASE.id) && (
                    <CourtCaseCard
                        editCallback={editCallback}
                        currentReportId={id}
                        router={router}
                    />
                )}
                {(reportDefinitionHasCard(reportDefinitionId, reportCardEnum.OFFENSE.id) ||
                    reportDefinitionHasCard(reportDefinitionId, reportCardEnum.INCIDENT.id) ||
                    reportDefinitionHasCard(
                        reportDefinitionId,
                        reportCardEnum.SUPPLEMENT_OFFENSE.id
                    ) ||
                    reportDefinitionHasCard(
                        reportDefinitionId,
                        reportCardEnum.SUPPLEMENT_INCIDENT.id
                    )) && (
                    <OffenseIncidentCardsWrapper
                        editCallback={editCallback}
                        offenseReportId={baseReportId}
                        router={router}
                        currentReportREN={reportingEventNumber}
                        isNewReport={isNewReport}
                        reportDefinitionId={reportDefinitionId}
                    />
                )}
                {reportDefinitionHasCard(reportDefinitionId, reportCardEnum.OFFENSE.id) && (
                    <FeatureFlagged flag="RMS_DYNAMIC_REPORT_ENABLED">
                        <ArrestBlock offenseReportId={id} />
                    </FeatureFlagged>
                )}
                {reportDefinitionHasCard(reportDefinitionId, reportCardEnum.RELATIONSHIPS.id) && (
                    <RelationshipsCardWrapper
                        editCallback={(callback) => editMediator(callback)}
                        router={router}
                    />
                )}
                <DragonMain isNewReport={isNewReport} reportId={id} />
                {reportDefinitionHasCard(
                    reportDefinitionId,
                    reportCardEnum.SUMMARY_NARRATIVE.id
                ) && (
                    <SummaryNarrativeCardWrapper
                        editCallback={editCallback}
                        currentReportId={id}
                        router={router}
                        index={id}
                    />
                )}
                {reportDefinitionHasCard(reportDefinitionId, reportCardEnum.NARRATIVE.id) && (
                    <NarrativeCard
                        editCallback={editCallback}
                        currentReportId={id}
                        index={id}
                        router={router}
                    />
                )}
                {reportDefinitionHasCard(reportDefinitionId, reportCardEnum.ATTACHMENTS.id) && (
                    <AttachmentsCard
                        entityTitle={formatReportTitleForReportId(
                            (id, { includeRenDisplayName: false })
                        )}
                        entityType={EntityTypeEnum.REPORT.name}
                        entityId={currentReport.id}
                        index={currentReport.id}
                        editCallback={editCallback}
                        linkType={LinkTypesEnum.REPORT_ATTACHMENT}
                        canEdit={canEditReportCard}
                        canEditErrorMessage={errorMessage}
                        router={router}
                        emailsEnabled={true}
                    />
                )}
                {reportDefinitionHasCard(
                    reportDefinitionId,
                    reportCardEnum.REPORT_STATUS_COMMENTS.id
                ) && (
                    <ReportStatusCommentsCard
                        editCallback={editCallback}
                        currentReportId={id}
                        router={router}
                    />
                )}
                {!currentReportIsSnapshot && (
                    <AssociatedRecordsSection reportId={id} router={router} />
                )}
            </CardContainer>
            <EntitySidebar
                router={router}
                disabled={currentReportIsSnapshot}
                reportId={baseReportId}
                reportingEventNumber={reportingEventNumber}
            />
        </StyledFocusWrapper>
    );
}

const mapStateToProps = createStructuredSelector({
    currentReport: currentReportSelector,
    reportDefinitionHasCard: reportDefinitionHasCardSelector,
    formatReportTitleForReportId: formatReportTitleForReportIdSelector,
    canEditReportCardStatus: canEditReportCardStatusSelector,
    currentReportIsSnapshot: currentReportIsSnapshotSelector,
    reportDefinitionById: reportDefinitionByIdSelector,
    arrestForReportId: arrestForReportIdSelector,
    offenseModifyingSupplementRenOffenseReportId:
        offenseModifyingSupplementRenOffenseReportIdSelector,
    isNewReport: isCurrentReportNewSelector,
});

const mapDispatchToProps = {
    editMediator,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportPage);
