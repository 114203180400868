import {
    PersonProfile,
    RmsHydratedPerson,
    EntityTypeEnumType,
    EntityTypeEnum,
} from '@mark43/rms-api';
import { concat, first, flatMap, forEach, map, reduce } from 'lodash';
import { Action } from 'redux';

import { NEXUS_STATE_PROP as EMPLOYMENT_HISTORIES_NEXUS_STATE_PROP } from '../../../employment-histories/state/data';
import { NEXUS_STATE_PROP as IDENTIFYING_MARKS_NEXUS_STATE_PROP } from '../../../identifying-marks/state/data';
import { NEXUS_STATE_PROP as LOCATION_ENTITY_LINKS_NEXUS_STATE_PROP } from '../../../location-entity-links/state/data';
import { NEXUS_STATE_PROP as LOCATIONS_NEXUS_STATE_PROP } from '../../../locations/state/data';
import { NEXUS_STATE_PROP as NAME_ATTRIBUTES_NEXUS_STATE_PROP } from '../../../name-attributes/state/data';
import { NEXUS_STATE_PROP as NAME_EMAILS_NEXUS_STATE_PROP } from '../../../name-emails/state/data';
import { NEXUS_STATE_PROP as NAME_IDENTIFIERS_NEXUS_STATE_PROP } from '../../../name-identifiers/state/data';
import { NEXUS_STATE_PROP as NAME_MONIKERS_NEXUS_STATE_PROP } from '../../../name-monikers/state/data';
import { NEXUS_STATE_PROP as NAME_NAME_LINKS_NEXUS_STATE_PROP } from '../../../name-name-links/state/data';
import { NEXUS_STATE_PROP as NAME_PHONES_NEXUS_STATE_PROP } from '../../../name-phones/state/data';
import { NEXUS_STATE_PROP as SCHOOL_HISTORIES_NEXUS_STATE_PROP } from '../../../school-histories/state/data';
import { NEXUS_STATE_PROP as IMAGES_NEXUS_STATE_PROP } from '../../../images/state/data';
import { NEXUS_STATE_PROP as PERSON_EMERGENCY_CONTACTS_NEXUS_STATE_PROP } from '../../../person-emergency-contacts/state/data';
import { NEXUS_STATE_PROP as PASSPORTS_NEXUS_STATE_PROP } from '../../../passports/state/data';
import { NEXUS_STATE_PROP as PERSON_PROBATIONS_NEXUS_STATE_PROP } from '../../../person-probations/state/data';
import { NEXUS_STATE_PROP as PERSON_GANG_TRACKINGS_NEXUS_STATE_PROP } from '../../../person-gang-trackings/state/data';
import { NEXUS_STATE_PROP as PERSON_INJURIES_NEXUS_STATE_PROP } from '../../../person-injuries/state/data';
import { NEXUS_STATE_PROP as NAME_REPORT_LINKS_NEXUS_STATE_PROP } from '../../../name-report-links/state/data';
import { NEXUS_STATE_PROP as ATTACHMENTS_NEXUS_STATE_PROP } from '../../../attachments/state/data';
import { NEXUS_STATE_PROP as CAUTIONS_NEXUS_STATE_PROP } from '../../../cautions/state/data';
import { NEXUS_STATE_PROP as ATTRIBUTES_NEXUS_STATE_PROP } from '../../../attributes/state/data';
import { convertAttributeToAttributeView } from '../../../attributes/utils/attributesHelpers';

import getPersonProfileResource from '../../resources/personProfileResource';
import createNormalizedModule from '../../../../utils/createNormalizedModule';
import { convertLocationBundlesToLocationViews } from '../../../locations/utils/locationHelpers';

import { ClientCommonAction } from '../../../../../redux/types';

export const NEXUS_STATE_PROP = 'personProfiles';

const personProfilesModule = createNormalizedModule<PersonProfile>({
    type: NEXUS_STATE_PROP,
});

const LOAD_RMS_HYDRATED_PERSON_START = 'person-profiles/LOAD_RMS_HYDRATED_PERSON_START';
const LOAD_RMS_HYDRATED_PERSON_SUCCESS = 'person-profiles/LOAD_RMS_HYDRATED_PERSON_SUCCESS';
const LOAD_RMS_HYDRATED_PERSON_FAILURE = 'person-profiles/LOAD_RMS_HYDRATED_PERSON_FAILURE';

export const SAVE_PERSON_PROFILE_SUCCESS = 'person-profiles/SAVE_PERSON_PROFILE_SUCCESS';

function loadRmsHydratedPersonStart(personProfileId: number) {
    return {
        type: LOAD_RMS_HYDRATED_PERSON_START,
        payload: personProfileId,
    };
}

function loadRmsHydratedPersonSuccess(rmsHydratedPerson: RmsHydratedPerson) {
    return {
        type: LOAD_RMS_HYDRATED_PERSON_SUCCESS,
        payload: rmsHydratedPerson,
    };
}

function loadRmsHydratedPersonFailure(errorMessage: string) {
    return {
        type: LOAD_RMS_HYDRATED_PERSON_FAILURE,
        payload: errorMessage,
    };
}

export function getContextedOrMasterPersonProfile({
    masterOrContextedProfileId,
    ownerType,
    ownerId,
}: {
    masterOrContextedProfileId: number;
    ownerType: EntityTypeEnumType;
    ownerId: number;
}): ClientCommonAction<Promise<RmsHydratedPerson>> {
    return (dispatch) => {
        const personProfileResource = getPersonProfileResource();
        dispatch(loadRmsHydratedPersonStart(masterOrContextedProfileId));
        return personProfileResource
            .getContextedOrMasterPersonProfile({
                masterOrContextedProfileId,
                ownerType,
                ownerId,
            })
            .then((rmsHydratedPerson: RmsHydratedPerson) =>
                first(
                    dispatch(
                        augmentActionAndStoreRmsHydratedPersonProfiles(
                            loadRmsHydratedPersonSuccess(rmsHydratedPerson),
                            [rmsHydratedPerson]
                        )
                    )
                )
            )
            .catch((err: Error) => {
                dispatch(loadRmsHydratedPersonFailure(err.message));
                throw err;
            });
    };
}

const LOAD_PERSON_PROFILE_START = 'person-profiles/LOAD_PERSON_PROFILE_START';
const LOAD_PERSON_PROFILE_SUCCESS = 'person-profiles/LOAD_PERSON_PROFILE_SUCCESS';
const LOAD_PERSON_PROFILE_FAILURE = 'person-profiles/LOAD_PERSON_PROFILE_FAILURE';

function loadPersonProfileStart(personProfileId: number) {
    return {
        type: LOAD_PERSON_PROFILE_START,
        payload: personProfileId,
    };
}

function loadPersonProfileSuccess(personProfile: RmsHydratedPerson) {
    return {
        type: LOAD_PERSON_PROFILE_SUCCESS,
        payload: personProfile,
    };
}

function loadPersonProfileFailure(errorMessage: string) {
    return {
        type: LOAD_PERSON_PROFILE_FAILURE,
        payload: errorMessage,
    };
}

export function getPersonProfileById(
    personProfileId: number
): ClientCommonAction<Promise<RmsHydratedPerson>> {
    return (dispatch) => {
        const personProfileResource = getPersonProfileResource();
        dispatch(loadPersonProfileStart(personProfileId));
        return personProfileResource
            .getPersonProfile(personProfileId)
            .then((hydratedPerson: RmsHydratedPerson) =>
                first(
                    dispatch(
                        augmentActionAndStoreRmsHydratedPersonProfiles(
                            loadPersonProfileSuccess(hydratedPerson),
                            [hydratedPerson]
                        )
                    )
                )
            )
            .catch((err: Error) => {
                dispatch(loadPersonProfileFailure(err.message));
                throw err;
            });
    };
}

/**
 * This function takes in an array of hydratedPerson models
 * and reduces the data into a nexus friendly
 * data structure for easier usage with
 * `withEntityItems` and `withRemove`
 *
 * @param  hydratedPeople
 *      An array of hydratedPeople models
 *
 * @return
 *      Returns an object with keys:
 *          withEntityItems - should be passed directly to
 *              nexus's `withEntityItems` helper
 *          withRemove - must be iterated over through
 *              nexus's `withRemove` helper
 */
function createNexusConfigForHydratedPeople(hydratedPeople: RmsHydratedPerson[]) {
    return reduce<
        RmsHydratedPerson,
        {
            withEntityItems: Record<string, unknown[]>;
            withRemove: Record<string, unknown[]>;
        }
    >(
        hydratedPeople,
        (acc, result) => {
            const locationEntityLinks = flatMap(result.locations, 'entityLinks');
            const hydratedPersonId = result.id;
            forEach(
                {
                    [PERSON_INJURIES_NEXUS_STATE_PROP]: result.injuries,
                    [ATTACHMENTS_NEXUS_STATE_PROP]: result.attachments,
                    [PERSON_EMERGENCY_CONTACTS_NEXUS_STATE_PROP]: result.personEmergencyContacts,
                    [PASSPORTS_NEXUS_STATE_PROP]: result.passports,
                    [PERSON_PROBATIONS_NEXUS_STATE_PROP]: result.personProbations,
                    [EMPLOYMENT_HISTORIES_NEXUS_STATE_PROP]: result.employmentHistories,
                    [IDENTIFYING_MARKS_NEXUS_STATE_PROP]: result.identifyingMarks,
                    [IMAGES_NEXUS_STATE_PROP]: result.images,
                    [LOCATION_ENTITY_LINKS_NEXUS_STATE_PROP]: locationEntityLinks,
                    [LOCATIONS_NEXUS_STATE_PROP]: convertLocationBundlesToLocationViews(
                        result.locations
                    ),
                    [NAME_ATTRIBUTES_NEXUS_STATE_PROP]: result.nameAttributes,
                    [NAME_EMAILS_NEXUS_STATE_PROP]: result.emails,
                    [NAME_IDENTIFIERS_NEXUS_STATE_PROP]: result.identifiers,
                    [NAME_MONIKERS_NEXUS_STATE_PROP]: result.monikers,
                    [NAME_PHONES_NEXUS_STATE_PROP]: result.phones,
                    [NAME_REPORT_LINKS_NEXUS_STATE_PROP]: result.reportLinks,
                    [PERSON_GANG_TRACKINGS_NEXUS_STATE_PROP]: result.personGangTrackings,
                    [NEXUS_STATE_PROP]: result.personProfiles,
                    [SCHOOL_HISTORIES_NEXUS_STATE_PROP]: result.schoolHistories,
                    [NAME_NAME_LINKS_NEXUS_STATE_PROP]: result.nameNameLinks,
                    [ATTRIBUTES_NEXUS_STATE_PROP]: map(
                        result.attributes,
                        convertAttributeToAttributeView
                    ),
                    [CAUTIONS_NEXUS_STATE_PROP]: result.cautions,
                },
                (items, entityType) => {
                    if (!acc.withEntityItems[entityType]) {
                        acc.withEntityItems[entityType] = [];
                    }
                    acc.withEntityItems[entityType] = concat(
                        acc.withEntityItems[entityType],
                        items
                    );
                }
            );

            // we remove all non-normalized values for the given profile id,
            // so we don't end up with duplicate values.
            // we can do this because the backend sends back values for all entities
            // related to the person profile for the given report
            const nameId = { nameId: hydratedPersonId };
            const nameFromId = { nameToId: hydratedPersonId };
            const personProfileId = { personProfileId: hydratedPersonId };
            const entityTypeAndId = {
                entityType: EntityTypeEnum.PERSON_PROFILE.name,
                entityId: hydratedPersonId,
            };
            forEach(
                {
                    [PERSON_INJURIES_NEXUS_STATE_PROP]: personProfileId,
                    [LOCATION_ENTITY_LINKS_NEXUS_STATE_PROP]: entityTypeAndId,
                    [NAME_ATTRIBUTES_NEXUS_STATE_PROP]: nameId,
                    [NAME_EMAILS_NEXUS_STATE_PROP]: nameId,
                    [NAME_IDENTIFIERS_NEXUS_STATE_PROP]: nameId,
                    [NAME_MONIKERS_NEXUS_STATE_PROP]: nameId,
                    [NAME_PHONES_NEXUS_STATE_PROP]: nameId,
                    [NAME_REPORT_LINKS_NEXUS_STATE_PROP]: nameId,
                    [SCHOOL_HISTORIES_NEXUS_STATE_PROP]: personProfileId,
                    [IDENTIFYING_MARKS_NEXUS_STATE_PROP]: personProfileId,
                    [PERSON_EMERGENCY_CONTACTS_NEXUS_STATE_PROP]: personProfileId,
                    [PASSPORTS_NEXUS_STATE_PROP]: personProfileId,
                    [PERSON_PROBATIONS_NEXUS_STATE_PROP]: personProfileId,
                    [EMPLOYMENT_HISTORIES_NEXUS_STATE_PROP]: personProfileId,
                    [NAME_NAME_LINKS_NEXUS_STATE_PROP]: nameFromId,
                    [PERSON_GANG_TRACKINGS_NEXUS_STATE_PROP]: personProfileId,
                    [CAUTIONS_NEXUS_STATE_PROP]: entityTypeAndId,
                },
                (predicate, entityType) => {
                    if (!acc.withRemove[entityType]) {
                        acc.withRemove[entityType] = [];
                    }
                    acc.withRemove[entityType] = concat(acc.withRemove[entityType], predicate);
                }
            );
            return acc;
        },
        {
            withEntityItems: {},
            withRemove: {},
        }
    );
}

// ACTIONS

export function augmentActionAndStoreRmsHydratedPersonProfiles(
    actionToAugment: Action,
    rmsHydratedPersonProfiles: RmsHydratedPerson[]
): ClientCommonAction<RmsHydratedPerson[]> {
    return (dispatch, getState, dependencies) => {
        const nexusConfigForHydratedPeople = createNexusConfigForHydratedPeople(
            rmsHydratedPersonProfiles
        );
        dispatch(
            dependencies.nexus.withEntityItems(
                nexusConfigForHydratedPeople.withEntityItems,
                reduce(
                    nexusConfigForHydratedPeople.withRemove,
                    (acc, predicate, entityType) =>
                        dependencies.nexus.withRemove(entityType, predicate, acc),
                    actionToAugment
                )
            )
        );

        return rmsHydratedPersonProfiles;
    };
}

// SELECTORS
export const personProfilesSelector = personProfilesModule.selectors.entitiesSelector;
export const personProfileByIdSelector = personProfilesModule.selectors.entityByIdSelector;
export const personProfilesWhereSelector = personProfilesModule.selectors.entitiesWhereSelector;

// REDUCER
export default personProfilesModule.reducerConfig;
