import React from 'react';
import invariant from 'invariant';
import { useCurrencyFormatter } from '../../../../../modules/core/current-user/hooks/useCurrencyFormatter';
import { getChildTypes } from '../../../../helpers/reactHelpers';
import withFields from '../../../fields/components/withFields';
import { PROPERTY_STATUS_DECLARED_VALUE_UNKNOWN } from '../../../enums/universal/fields';
import { formatDeclaredValue } from '../utils/propertyStatusHelpers';

const NODE_ENV = process.env.NODE_ENV;

/**
 * Use this component to format the declared value for a given property status.
 * @param  {Object} props.propertyStatus
 */
export default withFields([PROPERTY_STATUS_DECLARED_VALUE_UNKNOWN])(
    function FormattedPropertyStatusDeclaredValue({
        propertyStatus,
        fieldDisplayNames,
        children,
        className,
    }) {
        const { formatCurrency } = useCurrencyFormatter();

        if (!propertyStatus) {
            return null;
        }

        const childIsFunction = typeof children === 'function';

        if (NODE_ENV !== 'production') {
            const types = children ? getChildTypes(children).join(', ') : '';
            invariant(
                children === undefined || childIsFunction,
                `FormattedPropertyStatusDeclaredValue only accepts a function as child, but you have nested "${types}".`
            );
        }

        const formattedDeclaredValue = formatDeclaredValue(
            propertyStatus,
            fieldDisplayNames.PROPERTY_STATUS_DECLARED_VALUE_UNKNOWN,
            formatCurrency
        );

        if (childIsFunction) {
            return children(formattedDeclaredValue);
        }

        return formattedDeclaredValue ? (
            <span className={className}>{formattedDeclaredValue}</span>
        ) : null;
    }
);
