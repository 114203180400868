import moment from 'moment';
import { useSelector } from 'react-redux';

import { currentDepartmentDateFormatsSelector } from '../../domain/current-user/state/ui';
import { DateTimeFormatKeys } from '../utils/dateHelpers';

export const useFormattedDate = (date: moment.MomentInput, format: DateTimeFormatKeys) => {
    const dateTimeFormats = useSelector(currentDepartmentDateFormatsSelector);
    return date ? moment(date).format(dateTimeFormats[format]) : null;
};
