import { EntityTypeEnum, DynamicCardTypeEnum, LinkTypesEnum } from '@mark43/rms-api';
import { compact, reduce, isEmpty, map, get, size } from 'lodash';
import { createSelector } from 'reselect';
import {
    reportDefinitionsSelector,
    reportDefinitionHasCardSelector,
    formatCardNameWithRecordNumberByReportIdSelector,
    reportCardTitleByReportIdAndCardIdSelector,
} from '~/client-common/core/domain/report-definitions/state/data';
import reportCardEnum from '~/client-common/core/enums/universal/reportCardEnum';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import { clientApprovalStatusToFieldNames } from '~/client-common/helpers/approvalStatusHelpers';
import {
    formatFieldContactTitleByReportIdSelector,
    formatCommunityInfoTitleByReportIdSelector,
} from '~/client-common/core/domain/field-contacts/state/ui';
import { attachmentsWhereSelector } from '~/client-common/core/domain/attachments/state/data';
import { formatArrestTitleByReportIdSelector } from '~/client-common/core/domain/arrests/state/ui';
import { sortedUseOfForceSubjectTitlesForReportIdSelector } from '~/client-common/core/domain/use-of-force-subjects/state/ui';
import stringsConfig from '~/client-common/core/strings';
import { reportStatusHistoriesByReportIdSelector } from '~/client-common/core/domain/report-status-histories/state/data';
import { reportCommentsByReportIdSelector } from '~/client-common/core/domain/report-comments/state/data';

import { formatStrings } from '../../../../../legacy-redux/helpers/reportHelpers';
import { involvedNameViewModelsForDataSelector } from '../../../../../legacy-redux/selectors/involvedNamesSelectors';
import {
    offenseModifyingSupplementRenOffenseReportIdSelector,
    currentReportIsSnapshotSelector,
    approvalStatusSelector,
} from '../../../../../legacy-redux/selectors/reportSelectors';
import { dragonDataSelector } from '../../../../dragon/dragonRedux';
import { mapDragonFormsForReportSidebar } from '../../../../dragon/components/rms/dragonReportSidebar';
import { relationshipsDataSelector } from './relationships';
import { formattedTowVehicleCardTitleSelector } from './towVehicle';
import { sortedOffenseIncidentTitlesForReportIdSelector } from './offense';

const cardListForVisibleCardsSelector = createSelector(
    formatCardNameWithRecordNumberByReportIdSelector,
    reportStatusHistoriesByReportIdSelector,
    reportCommentsByReportIdSelector,
    formattedTowVehicleCardTitleSelector,
    approvalStatusSelector,
    formatFieldByNameSelector,
    dragonDataSelector,
    reportCardTitleByReportIdAndCardIdSelector,
    (
        formatCardNameWithRecordNumberByReportId,
        reportStatusHistoriesByReportId,
        reportCommentsByReportId,
        formattedTowVehicleCardTitle,
        clientApprovalStatus,
        formatFieldByName,
        dragonData,
        reportCardTitleByReportIdAndCardId
    ) =>
        ({
            currentReportId,
            dynamicCardInfo = {},
            reportApprovalStatus = null,
            showNarrative = false,
            showSummaryNarrative = false,
            showReportLegacyMetadataCard = false,
            showEventInfoCard = false,
            showSupplementInfoCard = false,
            showAttachmentsCard = false,
            showMissingPersonsCard = false,
            showInvolvedProfilesCard = false,
            showInvolvedNamesCard = false,
            showUseOfForceCard = false,
            showImpoundCard = false,
            showFieldContactCard = false,
            fieldContactTitle,
            showCommunityInfoCard = false,
            communityInfoTitle,
            sortedOffenseIncidentTitles = [],
            showBookingCard = false,
            showPropertyCard = false,
            showVehicleCard = false,
            showStopCard = false,
            showCourtCaseCard = false,
            showTowVehicleCard = false,
            showTowVehicleImpoundCard = false,
            showTowVehicleReleaseCard = false,
            showTowVehicleCheckInCard = false,
            showTrafficCrashCard = false,
            showCitationCard = false,
            sortedUseOfForceSubjectTitles,
            showChargesCard = false,
            showArrestCard,
            showBehavioralCrisisCard = false,
            arrestTitle,
            attachments,
            showAssociatedRecordsSection,
            showReportStatusCommentsCard = false,
            isEmbeddedReport,
            showTrafficCrashLocationCard = false,
        }) => {
            const reportDefinitionWithRecordNumberDisplayName = (cardNumberId) =>
                formatCardNameWithRecordNumberByReportId(currentReportId, cardNumberId);
            const reportStatusHistories = reportStatusHistoriesByReportId(currentReportId);
            const reportComments = reportCommentsByReportId(currentReportId);
            const reportHasCommentsHistory =
                !isEmpty(reportStatusHistories) || !isEmpty(reportComments);

            const clientApprovalStatusFieldName =
                clientApprovalStatusToFieldNames[clientApprovalStatus];
            const clientApprovalStatusFieldDisplayName = formatFieldByName(
                clientApprovalStatusFieldName
            );
            const dragonDataByReportId = dragonData[currentReportId];
            return compact(
                []
                    .concat(
                        showReportLegacyMetadataCard
                            ? {
                                  display: reportCardTitleByReportIdAndCardId(
                                      currentReportId,
                                      reportCardEnum.LEGACY_INFO.id
                                  ),
                                  anchor: 'report-legacy-metadata-card',
                                  cardName: reportCardEnum.LEGACY_INFO.name,
                              }
                            : null
                    )
                    .concat(
                        showEventInfoCard
                            ? {
                                  display: reportCardTitleByReportIdAndCardId(
                                      currentReportId,
                                      reportCardEnum.EVENT_INFO.id
                                  ),
                                  anchor: 'event-card',
                                  cardName: reportCardEnum.EVENT_INFO.name,
                              }
                            : null
                    )
                    .concat(
                        showTrafficCrashLocationCard
                            ? {
                                  display: reportCardTitleByReportIdAndCardId(
                                      currentReportId,
                                      reportCardEnum.CRASH_LOCATION_INFO.id
                                  ),
                                  anchor: 'traffic-crash-location-card',
                                  cardName: reportCardEnum.CRASH_LOCATION_INFO.name,
                              }
                            : null
                    )
                    .concat(
                        showSupplementInfoCard
                            ? {
                                  display: reportCardTitleByReportIdAndCardId(
                                      currentReportId,
                                      reportCardEnum.SUPPLEMENT_INFO.id
                                  ),
                                  anchor: 'supplement-info-card',
                                  cardName: reportCardEnum.SUPPLEMENT_INFO.name,
                              }
                            : null
                    )
                    .concat(
                        dynamicCardInfo.downloadables
                            ? {
                                  display: reportCardTitleByReportIdAndCardId(
                                      currentReportId,
                                      dynamicCardInfo.cardId
                                  ),
                                  anchor: 'downloadables-card',
                              }
                            : null
                    )
                    .concat(
                        showBehavioralCrisisCard
                            ? {
                                  display: reportCardTitleByReportIdAndCardId(
                                      currentReportId,
                                      reportCardEnum.BEHAVIORAL_CRISIS.id
                                  ),
                                  anchor: 'behavioral-crisis-card',
                                  cardName: reportCardEnum.BEHAVIORAL_CRISIS.name,
                              }
                            : null
                    )
                    .concat(
                        showFieldContactCard
                            ? {
                                  display: fieldContactTitle,
                                  anchor: 'field-contact-card',
                                  cardName: reportCardEnum.FIELD_CONTACT.name,
                              }
                            : null
                    )
                    .concat(
                        showCommunityInfoCard
                            ? {
                                  display: communityInfoTitle,
                                  anchor: 'community-info-card',
                                  cardName: reportCardEnum.COMMUNITY_INFORMATION.name,
                              }
                            : null
                    )
                    .concat(
                        showMissingPersonsCard
                            ? {
                                  display: reportCardTitleByReportIdAndCardId(
                                      currentReportId,
                                      reportCardEnum.MISSING_PERSONS.id
                                  ),
                                  anchor: 'missing-persons-card',
                                  cardName: reportCardEnum.MISSING_PERSONS.name,
                              }
                            : null
                    )
                    .concat(
                        showInvolvedProfilesCard
                            ? {
                                  display: reportCardTitleByReportIdAndCardId(
                                      currentReportId,
                                      reportCardEnum.INVOLVED_PROFILES.id
                                  ),
                                  anchor: 'involved-profiles-card',
                                  cardName: reportCardEnum.INVOLVED_PROFILES.name,
                              }
                            : null
                    )
                    .concat(
                        showCitationCard
                            ? {
                                  display: reportCardTitleByReportIdAndCardId(
                                      currentReportId,
                                      reportCardEnum.CITATION.id
                                  ),
                                  anchor: 'citation-card',
                                  cardName: reportCardEnum.CITATION.name,
                              }
                            : null
                    )
                    .concat(
                        showTrafficCrashCard
                            ? {
                                  display: reportDefinitionWithRecordNumberDisplayName(
                                      reportCardEnum.TRAFFIC_CRASH.id
                                  ),
                                  anchor: 'traffic-crash-card',
                                  cardName: reportCardEnum.TRAFFIC_CRASH.name,
                              }
                            : null
                    )
                    .concat(
                        showUseOfForceCard
                            ? {
                                  display: reportCardTitleByReportIdAndCardId(
                                      currentReportId,
                                      reportCardEnum.USE_OF_FORCE.id
                                  ),
                                  anchor: 'use-of-force-card',
                                  cardName: reportCardEnum.USE_OF_FORCE.name,
                              }
                            : null
                    )
                    .concat(
                        sortedUseOfForceSubjectTitles
                            ? map(
                                  sortedUseOfForceSubjectTitles,
                                  ({ title, useOfForceSubjectId }) => ({
                                      display: title,
                                      anchor: `use-of-force-subject-card-${useOfForceSubjectId}`,
                                      cardName: reportCardEnum.USE_OF_FORCE_SUBJECT.name,
                                  })
                              )
                            : null
                    )
                    .concat(
                        showArrestCard
                            ? {
                                  display: arrestTitle,
                                  anchor: `${
                                      isEmbeddedReport
                                          ? `arrest-card-${currentReportId}`
                                          : 'arrest-card'
                                  }`,
                                  cardName: reportCardEnum.ARREST.name,
                              }
                            : null
                    )
                    .concat(
                        showPropertyCard
                            ? {
                                  display: reportCardTitleByReportIdAndCardId(
                                      currentReportId,
                                      reportCardEnum.PROPERTY.id
                                  ),
                                  anchor: 'property-card',
                                  cardName: reportCardEnum.PROPERTY.name,
                              }
                            : null
                    )
                    .concat(
                        showVehicleCard
                            ? {
                                  display: reportCardTitleByReportIdAndCardId(
                                      currentReportId,
                                      reportCardEnum.VEHICLE.id
                                  ),
                                  anchor: `vehicle-card-${currentReportId}`,
                                  cardName: reportCardEnum.VEHICLE.name,
                              }
                            : null
                    )
                    .concat(
                        showStopCard
                            ? {
                                  display: reportCardTitleByReportIdAndCardId(
                                      currentReportId,
                                      reportCardEnum.STOP.id
                                  ),
                                  anchor: 'stop-card',
                                  cardName: reportCardEnum.STOP.name,
                              }
                            : null
                    )
                    .concat(
                        showImpoundCard
                            ? {
                                  display: reportCardTitleByReportIdAndCardId(
                                      currentReportId,
                                      reportCardEnum.IMPOUND.id
                                  ),
                                  anchor: 'impound-card',
                                  cardName: reportCardEnum.IMPOUND.name,
                              }
                            : null
                    )
                    .concat(
                        showTowVehicleCard
                            ? {
                                  display: formattedTowVehicleCardTitle,
                                  anchor: 'tow-vehicle-card',
                                  cardName: reportCardEnum.TOW_VEHICLE.name,
                              }
                            : null
                    )
                    .concat(
                        showTowVehicleImpoundCard
                            ? {
                                  display: reportCardTitleByReportIdAndCardId(
                                      currentReportId,
                                      reportCardEnum.TOW_VEHICLE_IMPOUND.id
                                  ),
                                  anchor: 'tow-vehicle-impound-card',
                                  cardName: reportCardEnum.TOW_VEHICLE_IMPOUND.name,
                              }
                            : null
                    )
                    .concat(
                        showTowVehicleCheckInCard
                            ? {
                                  display: reportCardTitleByReportIdAndCardId(
                                      currentReportId,
                                      reportCardEnum.CHECK_IN_INFORMATION.id
                                  ),
                                  anchor: 'tow-vehicle-check-in-card',
                                  cardName: reportCardEnum.CHECK_IN_INFORMATION.name,
                              }
                            : null
                    )
                    .concat(
                        showTowVehicleReleaseCard
                            ? {
                                  display: reportCardTitleByReportIdAndCardId(
                                      currentReportId,
                                      reportCardEnum.RELEASE_INFORMATION.id
                                  ),
                                  anchor: 'tow-vehicle-release-card',
                                  cardName: reportCardEnum.RELEASE_INFORMATION.name,
                              }
                            : null
                    )
                    .concat(
                        showChargesCard
                            ? {
                                  display: reportCardTitleByReportIdAndCardId(
                                      currentReportId,
                                      reportCardEnum.CHARGES.id
                                  ),
                                  anchor: `${
                                      isEmbeddedReport
                                          ? `charges-card-${currentReportId}`
                                          : 'charges-card'
                                  }`,
                                  cardName: reportCardEnum.CHARGES.name,
                              }
                            : null
                    )
                    .concat(
                        showBookingCard
                            ? {
                                  display: reportCardTitleByReportIdAndCardId(
                                      currentReportId,
                                      reportCardEnum.BOOKING.id
                                  ),
                                  anchor: `${
                                      isEmbeddedReport
                                          ? `booking-card-${currentReportId}`
                                          : 'booking-card'
                                  }`,
                                  cardName: reportCardEnum.BOOKING.name,
                              }
                            : null
                    )
                    .concat(
                        showCourtCaseCard
                            ? {
                                  display: reportCardTitleByReportIdAndCardId(
                                      currentReportId,
                                      reportCardEnum.COURT_CASE.id
                                  ),
                                  anchor: `${
                                      isEmbeddedReport
                                          ? `court-case-card-${currentReportId}`
                                          : 'court-case-card'
                                  }`,
                                  cardName: reportCardEnum.COURT_CASE.name,
                              }
                            : null
                    )
                    // offenses/incidents from "legacy reports" - TODO remove this after switching to react report
                    .concat(sortedOffenseIncidentTitles)
                    .concat(
                        showInvolvedNamesCard
                            ? {
                                  display: reportCardTitleByReportIdAndCardId(
                                      currentReportId,
                                      reportCardEnum.RELATIONSHIPS.id
                                  ),
                                  anchor: 'relationships-card',
                                  cardName: reportCardEnum.RELATIONSHIPS.name,
                              }
                            : null
                    )
                    .concat(
                        dragonDataByReportId
                            ? mapDragonFormsForReportSidebar(dragonDataByReportId.form)
                            : null
                    )
                    .concat(
                        showSummaryNarrative
                            ? {
                                  display: reportCardTitleByReportIdAndCardId(
                                      currentReportId,
                                      reportCardEnum.SUMMARY_NARRATIVE.id
                                  ),
                                  anchor: `summary-narrative-card-${currentReportId}`,
                                  cardName: reportCardEnum.SUMMARY_NARRATIVE.name,
                              }
                            : null
                    )
                    .concat(
                        showNarrative
                            ? {
                                  display: reportCardTitleByReportIdAndCardId(
                                      currentReportId,
                                      reportCardEnum.NARRATIVE.id
                                  ),
                                  anchor: `narrative-card-${currentReportId}`,
                                  cardName: reportCardEnum.NARRATIVE.name,
                              }
                            : null
                    )
                    .concat(
                        showAttachmentsCard
                            ? {
                                  display: `${reportCardTitleByReportIdAndCardId(
                                      currentReportId,
                                      reportCardEnum.ATTACHMENTS.id
                                  )} (${attachments.length})`,
                                  anchor: `attachments-card-${currentReportId}`,
                                  cardName: reportCardEnum.ATTACHMENTS.name,
                              }
                            : null
                    )
                    .concat(
                        showReportStatusCommentsCard
                            ? {
                                  display: formatStrings.formatApprovalStatusForSidebar(
                                      reportApprovalStatus,
                                      reportHasCommentsHistory,
                                      clientApprovalStatusFieldDisplayName,
                                      reportCardTitleByReportIdAndCardId(
                                          currentReportId,
                                          reportCardEnum.REPORT_STATUS_COMMENTS.id
                                      )
                                  ),
                                  anchor: 'report-status-comments-card',
                                  cardName: reportCardEnum.REPORT_STATUS_COMMENTS.name,
                              }
                            : null
                    )
                    .concat(
                        showAssociatedRecordsSection
                            ? {
                                  display: stringsConfig.components.records.associatedRecords.title,
                                  anchor: 'associated-records',
                              }
                            : null
                    )
            );
        }
);

export const reportCardsSidebarSelector = createSelector(
    reportDefinitionsSelector,
    currentReportIsSnapshotSelector,
    reportDefinitionHasCardSelector,
    offenseModifyingSupplementRenOffenseReportIdSelector,
    formatArrestTitleByReportIdSelector,
    sortedUseOfForceSubjectTitlesForReportIdSelector,
    sortedOffenseIncidentTitlesForReportIdSelector,
    attachmentsWhereSelector,
    involvedNameViewModelsForDataSelector,
    relationshipsDataSelector,
    formatFieldContactTitleByReportIdSelector,
    formatCommunityInfoTitleByReportIdSelector,
    cardListForVisibleCardsSelector,
    (
        reportDefinitions,
        currentReportIsSnapshot,
        reportDefinitionHasCard,
        offenseModifyingSupplementRenOffenseReportId,
        formatArrestTitleByReportId,
        sortedUseOfForceSubjectTitlesForReportId,
        sortedOffenseIncidentTitlesForReportId,
        attachmentsWhere,
        involvedNameViewModelsForData,
        relationshipsData,
        formatFieldContactTitleByReportId,
        formatCommunityInfoTitleByReportId,
        cardListForVisibleCards
    ) =>
        (currentReport, isEmbeddedArrestReport = false) => {
            const reportId = isEmbeddedArrestReport ? currentReport.reportId : currentReport.id;
            const currentReportDefinition = reportDefinitions[currentReport.reportDefinitionId];
            const baseReportId = offenseModifyingSupplementRenOffenseReportId || reportId;

            const arrestTitle = formatArrestTitleByReportId(reportId);
            const sortedUseOfForceSubjectTitles =
                sortedUseOfForceSubjectTitlesForReportId(baseReportId);

            // offenses
            const sortedOffenseIncidentTitles =
                sortedOffenseIncidentTitlesForReportId(baseReportId);

            // attachments
            const attachments = attachmentsWhere({
                entityId: reportId,
                entityType: EntityTypeEnum.REPORT.name,
                linkType: LinkTypesEnum.REPORT_ATTACHMENT,
            });

            // involved names
            const involvedNameViewModels = involvedNameViewModelsForData(relationshipsData);

            const involvedNamesIncludeRelationships = !isEmpty(involvedNameViewModels);

            // dynamic cards stuff
            const currentReportDynamicCards = compact(
                currentReportDefinition.cards,
                (card) => card.dynamicCardType
            );
            const dynamicCardSidebarInfo = reduce(
                currentReportDynamicCards,
                (acc, card) => {
                    if (card.dynamicCardType === DynamicCardTypeEnum.DOWNLOADABLES.name) {
                        return {
                            ...acc,
                            downloadables: size(get(card, 'dynamicProperties.downloadables')) > 0,
                            cardId: card.id,
                        };
                    }
                    return acc;
                },
                {}
            );

            const cardList = isEmbeddedArrestReport
                ? cardListForVisibleCards({
                      reportApprovalStatus: currentReport.approvalStatus,
                      showNarrative: reportDefinitionHasCard(
                          currentReportDefinition.id,
                          reportCardEnum.NARRATIVE.id
                      ),
                      showSummaryNarrative: reportDefinitionHasCard(
                          currentReportDefinition.id,
                          reportCardEnum.SUMMARY_NARRATIVE.id
                      ),
                      showAttachmentsCard: reportDefinitionHasCard(
                          currentReportDefinition.id,
                          reportCardEnum.ATTACHMENTS.id
                      ),
                      showArrestCard: reportDefinitionHasCard(
                          currentReportDefinition.id,
                          reportCardEnum.ARREST.id
                      ),
                      arrestTitle,
                      attachments,
                      showBookingCard: reportDefinitionHasCard(
                          currentReportDefinition.id,
                          reportCardEnum.BOOKING.id
                      ),
                      showChargesCard: reportDefinitionHasCard(
                          currentReportDefinition.id,
                          reportCardEnum.CHARGES.id
                      ),
                      showCourtCaseCard: reportDefinitionHasCard(
                          currentReportDefinition.id,
                          reportCardEnum.COURT_CASE.id
                      ),
                      showVehicleCard: reportDefinitionHasCard(
                          currentReportDefinition.id,
                          reportCardEnum.VEHICLE.id
                      ),
                      dynamicCardInfo: dynamicCardSidebarInfo,
                      currentReportId: reportId,
                      isEmbeddedReport: isEmbeddedArrestReport,
                  })
                : cardListForVisibleCards({
                      reportApprovalStatus: currentReport.approvalStatus,
                      showSummaryNarrative: reportDefinitionHasCard(
                          currentReportDefinition.id,
                          reportCardEnum.SUMMARY_NARRATIVE.id
                      ),
                      showNarrative: reportDefinitionHasCard(
                          currentReportDefinition.id,
                          reportCardEnum.NARRATIVE.id
                      ),
                      showReportLegacyMetadataCard: reportDefinitionHasCard(
                          currentReportDefinition.id,
                          reportCardEnum.LEGACY_INFO.id
                      ),
                      showEventInfoCard: reportDefinitionHasCard(
                          currentReportDefinition.id,
                          reportCardEnum.EVENT_INFO.id
                      ),
                      showSupplementInfoCard: reportDefinitionHasCard(
                          currentReportDefinition.id,
                          reportCardEnum.SUPPLEMENT_INFO.id
                      ),
                      showAttachmentsCard: reportDefinitionHasCard(
                          currentReportDefinition.id,
                          reportCardEnum.ATTACHMENTS.id
                      ),
                      showIncidentCard: reportDefinitionHasCard(
                          currentReportDefinition.id,
                          reportCardEnum.INCIDENT.id
                      ),
                      showUseOfForceCard: reportDefinitionHasCard(
                          currentReportDefinition.id,
                          reportCardEnum.USE_OF_FORCE.id
                      ),
                      showMissingPersonsCard: reportDefinitionHasCard(
                          currentReportDefinition.id,
                          reportCardEnum.MISSING_PERSONS.id
                      ), // hiding for now
                      showInvolvedProfilesCard: reportDefinitionHasCard(
                          currentReportDefinition.id,
                          reportCardEnum.INVOLVED_PROFILES.id
                      ),
                      showInvolvedNamesCard:
                          reportDefinitionHasCard(
                              currentReportDefinition.id,
                              reportCardEnum.RELATIONSHIPS.id
                          ) &&
                          // this condition must match the one in `InvolvedNamesCard`
                          involvedNamesIncludeRelationships,
                      showImpoundCard: reportDefinitionHasCard(
                          currentReportDefinition.id,
                          reportCardEnum.IMPOUND.id
                      ),
                      showReportStatusCommentsCard: reportDefinitionHasCard(
                          currentReportDefinition.id,
                          reportCardEnum.REPORT_STATUS_COMMENTS.id
                      ),
                      showTowVehicleCard: reportDefinitionHasCard(
                          currentReportDefinition.id,
                          reportCardEnum.TOW_VEHICLE.id
                      ),
                      showTowVehicleImpoundCard: reportDefinitionHasCard(
                          currentReportDefinition.id,
                          reportCardEnum.TOW_VEHICLE_IMPOUND.id
                      ),
                      showTowVehicleCheckInCard: reportDefinitionHasCard(
                          currentReportDefinition.id,
                          reportCardEnum.CHECK_IN_INFORMATION.id
                      ),
                      showTowVehicleReleaseCard: reportDefinitionHasCard(
                          currentReportDefinition.id,
                          reportCardEnum.RELEASE_INFORMATION.id
                      ),
                      showTrafficCrashCard: reportDefinitionHasCard(
                          currentReportDefinition.id,
                          reportCardEnum.TRAFFIC_CRASH.id
                      ),
                      showBehavioralCrisisCard: reportDefinitionHasCard(
                          currentReportDefinition.id,
                          reportCardEnum.BEHAVIORAL_CRISIS.id
                      ),
                      showCitationCard: reportDefinitionHasCard(
                          currentReportDefinition.id,
                          reportCardEnum.CITATION.id
                      ),
                      sortedUseOfForceSubjectTitles,
                      sortedOffenseIncidentTitles,
                      showArrestCard: reportDefinitionHasCard(
                          currentReportDefinition.id,
                          reportCardEnum.ARREST.id
                      ),
                      arrestTitle,
                      attachments,
                      showBookingCard: reportDefinitionHasCard(
                          currentReportDefinition.id,
                          reportCardEnum.BOOKING.id
                      ),
                      showChargesCard: reportDefinitionHasCard(
                          currentReportDefinition.id,
                          reportCardEnum.CHARGES.id
                      ),
                      showCourtCaseCard: reportDefinitionHasCard(
                          currentReportDefinition.id,
                          reportCardEnum.COURT_CASE.id
                      ),
                      showPropertyCard: reportDefinitionHasCard(
                          currentReportDefinition.id,
                          reportCardEnum.PROPERTY.id
                      ),
                      showVehicleCard: reportDefinitionHasCard(
                          currentReportDefinition.id,
                          reportCardEnum.VEHICLE.id
                      ),
                      showStopCard: reportDefinitionHasCard(
                          currentReportDefinition.id,
                          reportCardEnum.STOP.id
                      ),
                      showFieldContactCard: reportDefinitionHasCard(
                          currentReportDefinition.id,
                          reportCardEnum.FIELD_CONTACT.id
                      ),
                      fieldContactTitle: formatFieldContactTitleByReportId(reportId),
                      showCommunityInfoCard: reportDefinitionHasCard(
                          currentReportDefinition.id,
                          reportCardEnum.COMMUNITY_INFORMATION.id
                      ),
                      communityInfoTitle: formatCommunityInfoTitleByReportId(reportId),
                      dynamicCardInfo: dynamicCardSidebarInfo,
                      showAssociatedRecordsSection: !currentReportIsSnapshot,
                      currentReportId: reportId,
                      showTrafficCrashLocationCard: reportDefinitionHasCard(
                          currentReportDefinition.id,
                          reportCardEnum.CRASH_LOCATION_INFO.id
                      ),
                  });

            return cardList;
        }
);
