import { createFormConfiguration } from 'markformythree';
import {
    DISPOSITION_EVENT_COMMENT,
    DISPOSITION_EVENT_MANUAL_RETENTION_PERIOD,
    CREATE_DISPOSITION_EVENT_VIEW_MANUAL_RETENTION_PERIOD,
    CREATE_DISPOSITION_EVENT_VIEW_SPECIFIC_DATE,
    DISPLAY_ONLY_PERIOD_UNIT,
} from '~/client-common/core/enums/universal/fields';

export const initialState = {
    comment: undefined,
    manualRetentionDays: undefined,
    periodUnit: undefined,
    periodAmount: undefined,
    specificDate: undefined,
    isSpecificDate: undefined,
    isDuration: undefined,
};

export const formConfiguration = createFormConfiguration({
    comment: {
        fieldName: DISPOSITION_EVENT_COMMENT,
    },
    manualRetentionDays: {
        fieldName: DISPOSITION_EVENT_MANUAL_RETENTION_PERIOD,
    },
    specificDate: { fieldName: CREATE_DISPOSITION_EVENT_VIEW_SPECIFIC_DATE },
    periodUnit: { fieldName: DISPLAY_ONLY_PERIOD_UNIT },
    periodAmount: { fieldName: CREATE_DISPOSITION_EVENT_VIEW_MANUAL_RETENTION_PERIOD },
    isSpecificDate: {},
    isDuration: {},
});
