import _, { get, parseInt } from 'lodash';
import moment from 'moment';
import { createSelector } from 'reselect';
import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';
import { cadServiceTypesSelector } from '~/client-common/core/domain/cad-service-rotation-types/state/data';
import {
    LOAD_SHAPEFILE_PROPERTIES_SUCCESS,
    shapefileByIdSelector,
    loadShapefilesProperties,
} from '~/client-common/core/domain/shapefiles/state/data';

import {
    processHistoryEvent,
    processChangeSet,
} from '~/client-common/core/domain/history-events/utils/historyEventHelpers';
import {
    formatAttributeByIdSelector,
    getAttributeByIdSelector,
} from '~/client-common/core/domain/attributes/state/data';
import { currentDepartmentDateFormatsSelector } from '~/client-common/core/domain/current-user/state/ui';
import { currentUserHasAbilitySelector } from '../../../../core/current-user/state/ui';
import { routeNameSelector } from '../../../../../routing/routerModule';
import routesConfig from '../../../../../routing/routesConfig';

import cadServiceTypesAdminForm, { validate } from '../forms/AdminForm';
import {
    LOAD_CAD_SERVICE_TYPES_START,
    LOAD_CAD_SERVICE_TYPES_SUCCESS,
    LOAD_CAD_SERVICE_TYPES_FAILURE,
    SAVE_CAD_SERVICE_TYPE_START,
    SAVE_CAD_SERVICE_TYPE_SUCCESS,
    SAVE_CAD_SERVICE_TYPE_FAILURE,
    FETCH_CAD_SERVICE_TYPE_HISTORY_SUCCESS,
    FETCH_ALL_CAD_SERVICE_TYPES_HISTORY_SUCCESS,
    saveCadServiceType,
} from '../data';
import authActionTypes from '../../../../../legacy-redux/actions/types/authActionTypes';
import { closeConfirmationModal } from '../../../../../legacy-redux/actions/boxActions';
import { formatUserByIdSelector } from '../../../../../legacy-redux/selectors/userSelectors';

const { BOOTSTRAP_SUCCESS } = authActionTypes;
const SELECT_CAD_SERVICE_TYPE_START = 'cad-service-rotation-types/SELECT_CAD_SERVICE_TYPE_START';
const SELECT_CAD_SERVICE_TYPE_SUCCESS =
    'cad-service-rotation-types/SELECT_CAD_SERVICE_TYPE_SUCCESS';
const SELECT_CAD_SERVICE_TYPE_FAILURE =
    'cad-service-rotation-types/SELECT_CAD_SERVICE_TYPE_FAILURE';
const OPEN_NEW_CAD_SERVICE_TYPE_FORM = 'cad-service-rotation-types/OPEN_NEW_CAD_SERVICE_TYPE_FORM';
const CLEAR_ERRORS = 'cad-service-rotation-types/CLEAR_ERRORS';

function selectCadServiceTypeStart() {
    return {
        type: SELECT_CAD_SERVICE_TYPE_START,
    };
}

function selectCadServiceTypeSuccess(serviceType) {
    return {
        type: SELECT_CAD_SERVICE_TYPE_SUCCESS,
        payload: serviceType,
    };
}

function selectCadServiceTypeFailure() {
    return {
        type: SELECT_CAD_SERVICE_TYPE_FAILURE,
    };
}

function clearErrors() {
    return {
        type: CLEAR_ERRORS,
    };
}

export function selectCadServiceType(id) {
    return (dispatch, getState) => {
        dispatch(selectCadServiceTypeStart());
        const state = getState();
        const cadServiceType = cadServiceTypesSelector(state)[id];

        if (cadServiceType) {
            cadServiceType.includeLocation =
                !!cadServiceType.includeLocation ||
                !!cadServiceType.shapefileId ||
                !!cadServiceType.propertyName;
            if (cadServiceType.shapefileId) {
                dispatch(loadShapefilesProperties(cadServiceType.shapefileId));
            }
            dispatch(selectCadServiceTypeSuccess(cadServiceType));
            dispatch(cadServiceTypesAdminForm.actionCreators.change({ cadServiceType }));
        } else {
            // TODO: remove this crappy interval when migrating the router, this is
            // a workaround for onEnter/onLeave hooks not respecting parent/child relationships
            let attempts = 0;

            const intervalId = window.setInterval(() => {
                const state = getState();
                const cadServiceType = cadServiceTypesSelector(state)[id];

                if (cadServiceType) {
                    cadServiceType.includeLocation =
                        !!cadServiceType.includeLocation ||
                        !!cadServiceType.shapefileId ||
                        !!cadServiceType.propertyName;
                    window.clearInterval(intervalId);
                    if (cadServiceType.shapefileId) {
                        dispatch(loadShapefilesProperties(cadServiceType.shapefileId));
                    }

                    dispatch(selectCadServiceTypeSuccess(cadServiceType));
                    dispatch(cadServiceTypesAdminForm.actionCreators.change({ cadServiceType }));
                }

                attempts++;
                if (attempts > 300) {
                    window.clearInterval(intervalId);

                    return dispatch(selectCadServiceTypeFailure('Failed to get cad service type.'));
                }
            }, 1000);
        }
    };
}

export function openNewCadServiceTypeForm() {
    return {
        type: OPEN_NEW_CAD_SERVICE_TYPE_FORM,
    };
}

const initialUiState = {
    serviceTypes: [],
    selectedCadServiceType: null,
    selectedCadServiceTypeId: null,
    selectShapefileProperties: [],
    loadingList: false,
    listError: null,
    submittingForm: false,
    formError: null,
    cadServiceTypeLoadError: null,
    shapefileId: null,
};

export default function cadServiceTypesAdminUiReducer(state = initialUiState, action) {
    switch (action.type) {
        case BOOTSTRAP_SUCCESS:
            return {
                ...state,
            };
        case FETCH_CAD_SERVICE_TYPE_HISTORY_SUCCESS:
            return {
                ...state,
                histories: {
                    ...state.histories,
                    [action.payload.serviceTypeId]: action.payload.histories,
                },
            };
        case FETCH_ALL_CAD_SERVICE_TYPES_HISTORY_SUCCESS:
            return {
                ...state,
                allHistories: action.payload.histories,
            };
        case LOAD_CAD_SERVICE_TYPES_START:
            return {
                ...state,
                loadingList: true,
                selectedCadServiceTypeId: null,
                selectedCadServiceType: null,
                listError: null,
            };
        case LOAD_CAD_SERVICE_TYPES_SUCCESS:
            return {
                ...state,
                serviceTypes: action.payload,
                loadingList: false,
            };
        case LOAD_CAD_SERVICE_TYPES_FAILURE:
            return {
                ...state,
                loadingList: false,
                listError: action.payload,
            };
        case SAVE_CAD_SERVICE_TYPE_START:
            return {
                ...state,
                submittingForm: true,
            };
        case SAVE_CAD_SERVICE_TYPE_SUCCESS:
            return {
                ...state,
                submittingForm: false,
            };
        case SAVE_CAD_SERVICE_TYPE_FAILURE:
            return {
                ...state,
                submittingForm: false,
                formError: action.payload,
            };
        case SELECT_CAD_SERVICE_TYPE_SUCCESS:
            return {
                ...state,
                cadServiceTypeLoadError: null,
                selectedCadServiceTypeId: parseInt(action.payload.id),
                selectedCadServiceType: action.payload,
            };
        case SELECT_CAD_SERVICE_TYPE_FAILURE:
            return {
                ...state,
                cadServiceTypeLoadError: action.payload,
            };
        case OPEN_NEW_CAD_SERVICE_TYPE_FORM:
            return {
                ...state,
                formError: null,
                cadServiceTypeLoadError: null,
                selectedCadServiceTypeId: null,
                selectedCadServiceType: null,
            };
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null,
            };
        case LOAD_SHAPEFILE_PROPERTIES_SUCCESS:
            return {
                ...state,
                // should always be a single id here
                shapefileId: action.payload.idOrIds,
            };

        default:
            return state;
    }
}

export const uiSelector = (state) => state.ui.cadServiceTypes;

export const cadServiceTypeViewModelByIdSelector = createSelector(
    cadServiceTypesSelector,
    (cadServiceTypes) => (id) => {
        return cadServiceTypes[id]
            ? {
                  ...cadServiceTypes[id],
                  status: cadServiceTypes[id].isActive ? 'ACTIVE' : 'DISABLED',
              }
            : undefined;
    }
);

// list of cad serviceTypes
export const serviceTypesListItemsSelector = createSelector(
    cadServiceTypesSelector,
    uiSelector,
    routeNameSelector,
    (cadServiceTypes, ui, routeName) => {
        return _(cadServiceTypes)
            .map((serviceType) => {
                const historyView =
                    routeName === routesConfig.ADMIN_CAD_SERVICE_TYPES_HISTORY.name ||
                    routeName === routesConfig.ADMIN_CAD_ALL_SERVICE_TYPES_HISTORY.name;

                const path = `/admin/cad/cad-service-rotation-types/${serviceType.id}${
                    historyView ? '/history' : ''
                }`;
                return serviceType
                    ? {
                          path,
                          title: serviceType.name,
                          subtitle: serviceType.description,
                          key: serviceType.id,
                          status: serviceType.isActive ? 'ACTIVE' : 'DISABLED',
                          selected: serviceType.id === ui.selectedCadServiceTypeId,
                      }
                    : null;
            })
            .sortBy('title')
            .value();
    }
);

export const shapefilePropertyOptionsSelector = createSelector(
    uiSelector,
    shapefileByIdSelector,
    (ui, shapefileById) => {
        const shapefileId = get(ui, 'shapefileId');
        if (shapefileId) {
            const shapefile = shapefileById(shapefileId);
            if (shapefile) {
                const shapefilePropertyOptions = [];
                shapefile.properties.forEach((propertyEntry) => {
                    Object.keys(propertyEntry).forEach((key) => {
                        if (!shapefilePropertyOptions.find((x) => x.display === key)) {
                            shapefilePropertyOptions.push({
                                display: key,
                                value: key,
                            });
                        }
                    });
                });
                return shapefilePropertyOptions;
            }
        }

        return [];
    }
);

export const historiesForCadServiceTypeSelector = createSelector(
    uiSelector,
    formatAttributeByIdSelector,
    formatUserByIdSelector,
    getAttributeByIdSelector,
    currentDepartmentDateFormatsSelector,
    (ui, formatAttributeById, userById, getAttributeById, dateTimeFormats) => (id) => {
        const histories = id ? ui.histories[id] : ui.allHistories;

        const displayDate = (date) => {
            if (date) {
                return moment(date).format(dateTimeFormats.summaryDate);
            }
            return '';
        };

        const displayDateTime = (date) => {
            if (date) {
                return moment(date).format(dateTimeFormats.summaryDateTime);
            }
            return '';
        };

        const args = {
            formatAttributeById,
            userById,
            displayDate,
            displayDateTime,
            attributeById: getAttributeById,
        };

        return _.map(_.sortBy(histories, 'timestampUtc'), (history) => {
            const processed = processHistoryEvent(history, args);
            const newObj = {
                ...processed,
                user: userById(processed.changedBy),
                changes: _.map(processed.changeSet, (changeSet) =>
                    processChangeSet(changeSet, args, processed)
                ),
            };

            return newObj;
        }).reverse();
    }
);

export const hasImportExportServiceTypePermissions = createSelector(
    currentUserHasAbilitySelector,
    (currentUserHasAbility) => currentUserHasAbility(abilitiesEnum.CAD.MANAGE_CAD_SERVICE_ROTATION)
);

export function submitCadServiceTypeAdminForm(router) {
    return (dispatch) => {
        if (dispatch(validate())) {
            return;
        } else {
            dispatch(clearErrors());

            dispatch(
                cadServiceTypesAdminForm.actionCreators.submit((formModel) =>
                    dispatch(
                        saveCadServiceType(cadServiceTypesAdminForm.convertFromFormModel(formModel))
                    ).tap((action) => {
                        const serviceType = action.payload;
                        const path = `/admin/cad/cad-service-rotation-types/${serviceType.id}`;
                        router.push(path);
                    })
                )
            ).then(() => {
                dispatch(closeConfirmationModal());
            });
        }
    };
}
