import React from 'react';
import styled from 'styled-components';
import { Textarea as ArcTextarea } from 'arc';
import { toString } from 'lodash';
import classNames from 'classnames';
import { simpleControl } from 'markformythree';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import reactReduxFormHelpers from '../../../../legacy-redux/helpers/reactReduxFormHelpers';
import testIds from '../../../../core/testIds';
import { arbiterMFTInput } from '../../arbiter';
import { ValueType, DeprecatedInput } from './Text';
import FormElement from './FormElement';

const { connectRRFInput } = reactReduxFormHelpers;

const StyledArcTextarea = styled(ArcTextarea)`
    width: 100%;
    /**
     * Fixes a bug in arc
     */
    .arc-control__control {
        width: 100%;
    }
`;

export default class TextArea extends React.Component<TextAreaProps> {
    static displayName = 'TextArea';

    textInput: HTMLTextAreaElement | null = null;

    resizeHeight() {
        if (this.props.autoResize && this.textInput) {
            this.textInput.style.height = 'auto';
            this.textInput.style.height = `${
                this.textInput.scrollHeight +
                (this.textInput.offsetHeight - this.textInput.clientHeight)
            }px`;
        }
    }

    componentDidMount() {
        this.resizeHeight();
    }

    onChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
        const value = e.target.value;

        // call custom handler
        if (this.props.onChange) {
            this.props.onChange(value);
        }
        // call context handler
        if (this.context.onInputChange) {
            this.context.onInputChange(value);
        }

        this.resizeHeight();
    }

    onFocus() {
        this.props.onFocus?.(this.props.value); // call custom handler
        if (this.context.onInputFocus) {
            // call context handler
            this.context.onInputFocus(this.props.value);
        }
    }

    onBlur() {
        this.props.onBlur?.(this.props.value); // call custom handler
        if (this.context.onInputBlur) {
            // call context handler
            this.context.onInputBlur(this.props.value);
        }
    }

    render() {
        const { resizable = true } = this.props;
        const error = this.props.touched && this.props.error;

        // Note: When ARC_RELEASE_CYCLE_THREE_COMPONENTS is enabled everywhere
        // delete the `error` class here, since we pass it in
        // as a prop for this component now
        //
        // Also delete the `mark43-form-text` class, since we don't
        // need this for styling anymore
        const classes = classNames('mark43-form-text', {
            error,
        });

        const dataAttrs = {
            'data-test-id': testIds.TEXTAREA_INPUT,
        };

        return (
            <FormElement {...this.props}>
                <FeatureFlagged
                    flag="ARC_RELEASE_CYCLE_THREE_COMPONENTS"
                    fallback={
                        <DeprecatedInput
                            as="textarea"
                            autoFocus={this.props.autoFocus}
                            // When ARC_RELEASE_CYCLE_THREE_COMPONENTS is enabled everywhere,
                            // remove this `style` prop
                            style={!resizable ? { resize: 'none' } : {}}
                            ref={(input: HTMLTextAreaElement) => {
                                this.textInput = input;
                                this.props.setRef?.(input);
                            }}
                            className={classes}
                            rows={this.props.rows}
                            // ghetto fix for textarea react bug here: https://github.com/facebook/react/issues/2533
                            value={toString(this.props.value) || ''}
                            onChange={this.onChange.bind(this)}
                            onFocus={this.onFocus.bind(this)}
                            onBlur={this.onBlur.bind(this)}
                            maxLength={this.props.maxLength}
                            disabled={this.props.disabled}
                            placeholder={this.props.placeholder}
                            {...dataAttrs}
                        />
                    }
                >
                    <StyledArcTextarea
                        autoFocus={this.props.autoFocus}
                        validationType={error ? 'error' : undefined}
                        required={this.props.isRequired}
                        resizable={resizable}
                        inputRef={(input: HTMLTextAreaElement | HTMLInputElement | null) => {
                            this.textInput = input as HTMLTextAreaElement;
                            this.props.setRef?.(input as HTMLTextAreaElement);
                        }}
                        rows={this.props.rows}
                        type="textarea"
                        // ghetto fix for textarea react bug here: https://github.com/facebook/react/issues/2533
                        value={toString(this.props.value)}
                        onChange={this.onChange.bind(this)}
                        onFocus={this.onFocus.bind(this)}
                        onBlur={this.onBlur.bind(this)}
                        maxLength={this.props.maxLength}
                        disabled={this.props.disabled}
                        placeholder={this.props.placeholder}
                        {...dataAttrs}
                    />
                </FeatureFlagged>
            </FormElement>
        );
    }
}

type TextAreaProps = {
    value?: ValueType;
    label?: string;
    resizable?: boolean;
    autoResize?: boolean;
    rows?: number;
    onChange?: (value: ValueType) => void;
    onFocus?: (value: ValueType) => void;
    onBlur?: (value: ValueType) => void;
    maxLength?: number;
    width: string | number;
    setRef?: (textarea: HTMLTextAreaElement | null) => void;
    isRequired?: boolean;
    testId?: string;
    touched?: boolean;
    error?: boolean;
    disabled?: boolean;
    placeholder?: string;
    autoFocus?: boolean;
};

// @ts-expect-error client-common to client RND-7529
export const RRFTextArea = connectRRFInput(TextArea);
export const ArbiterMFTTextArea = arbiterMFTInput(TextArea);
export const MFTTextArea = simpleControl(TextArea);
