import type { Editor as TinyEditorType, Ui } from 'tinymce';
import type { IAllProps as TinyEditorProps } from '@tinymce/tinymce-react';
import { Editor as TinyEditor } from '@tinymce/tinymce-react';

import React, { useState } from 'react';

import styled from 'styled-components';

import { Box } from '@arc/layout';
import { SkeletonText } from '@arc/skeleton';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { filter, includes, map, noop } from 'lodash';
import { _Form, MFTFormConfiguration } from 'markformythree';
import { EntityTypeEnum } from '@mark43/rms-api';
import { narrativeGuidesByEntityTypeSelecor } from '~/client-common/core/domain/narrative-guides/state/data';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { formatMiniUserByIdSelector } from '~/client-common/core/domain/mini-users/state/data';
import { reportInlineCommentsViewSelector } from '~/client-common/core/domain/inline-report-comments/state/data';
import { RootState } from '../../../../legacy-redux/reducers/rootReducer';
import { ErrorBoundary } from '../../errors/components/ErrorBoundary';
import { InlineBanner } from '../../components/InlineBanner';
import getTinyMCEBaseUrl from '../utils/getTinyMCEBaseUrl';
import {
    createMentionsFetchHandler,
    hideTinyHeader,
    insertMention,
    maybeOpenMentionsMenu,
    maybeOpenMentionsMenuAndMoveCursorToEndOfMention,
    migratePreExistingComments,
    preprocessHtml,
    showTinyHeader,
    useAddMark43CommentMetadata,
} from '../utils/tinyEditor';
import { parseReportCommentId } from '../utils/tinyMCEConversations';
import updateCommentSidebarPosition from '../utils/updateCommentSidebarPosition';
import createSelectionHandler from '../utils/createSelectionHandler';
import {
    canResolveInlineCommentsSelector,
    canViewInlineCommentsSelector,
    enableAddInlineCommentsSelector,
} from '../../../reports/core/state/ui/inlineComments';
import { updateNarrativeHtmlWithComments } from '../../../reports/core/state/ui/inlineCommentActions';
import { currentUserProfileSelector } from '../../current-user/state/ui';
import {
    canEditReportCardStatusSelector,
    currentReportSelector,
} from '../../../../legacy-redux/selectors/reportSelectors';
import { hasBannerSelector } from '../../../../legacy-redux/selectors/alertsSelectors';
import zIndexes from '../../styles/zIndexes';
import { currentThemeSelector } from '../../styles/state';
import errorToMessage from '../../errors/utils/errorToMessage';
import {
    clearDeferredOperationsForTinyEditor,
    executeDeferredOperationsForTinyEditor,
} from '../utils/withTinyEditor';
import powerPastePostProcess from '../utils/powerPastePostProcess';
import initLogRocketForTinyEditor from '../utils/initLogRocketForTinyEditor';
import { useEditorImage } from '../hooks/useEditorImage';
import { useMentionsForBriefing } from '../plugins/mentions/briefing/hooks/useMentionsForBriefing';
import { setupLinkPlugin } from '../utils/setupLinkPlugin';
import { useIsComponentTestEnvironment } from '../../context/E2ETestingContext';
import { TINYMCE_DEFAULT_INIT_TIMEOUT } from '../constants';

const strings = componentStrings.reports.core;
const errorStrings = componentStrings.core.Editor.errors;

const initStates = {
    INITIALIZING: 'INITIALIZING',
    INITIALIZED: 'INITIALIZED',
    FAILED: 'FAILED',
} as const;

type InitState = typeof initStates[keyof typeof initStates];

const getBaseContentStyles = (
    theme: ReturnType<typeof currentThemeSelector>,
    inlineCommentsEnabled: boolean
): string => {
    let css = `body {
        background-color: ${theme.colors.white};
        color: ${theme.colors.darkGrey};
        font-family: 'jaf-facitweb', sans-serif;
        font-size: var(--arc-fontSizes-md);
        font-feature-settings: 'kern';
        line-height: 1.5;
        -webkit-text-size-adjust: 100%;
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizeLegibility;
    }

    p {
        margin: 1em 0;
    }`;

    if (inlineCommentsEnabled) {
        // highlighted text for inline comments
        // override the darker yellow (#ffe89d) from `.tox-comments-visible span.tox-comment:not([data-mce-selected])`
        // and override the blue (#b4d7ff) for the currently selected text from `.mce-content-body [data-mce-selected=inline-boundary]`
        css += `
            .highlight {
                background-color: ${theme.colors.lightYellow} !important;
            }
        `;
    }

    return css;
};

/**
 * Pretty hacky way to select the narrative guides button,
 * but tinyMCE has removed the ability to add custom classes to the button
 *
 * https://github.com/tinymce/tinymce/issues/5040
 */
const narrativeGuidesButtonSelector = `.tox-tbtn[title="${strings.NarrativeCard.guideDropdownLabel}"]`;

const TinyWrapper = styled.div<{
    canViewComments?: boolean;
    displayCommentSection?: boolean;
    canResolveComments?: boolean;
    showNarrativeGuidesButton?: boolean;
    isScrollable?: boolean;
    hasBanner: boolean;
    summaryMode: boolean;
}>`
    ${narrativeGuidesButtonSelector} {
        display: ${(props) => (props.showNarrativeGuidesButton ? 'flex' : 'none')};
    }
    height: 100%;
    display: flex;
    flex-direction: column;

    // the topmost container element created by TinyMCE
    .tox.tox-tinymce {
        // we style our own border around the TinyMCE editor
        border: 0;
        // allow the inline comment sidebar to appear to the right of the editor
        overflow: visible;
    }

    .tox .tox-editor-container {
        ${({ isScrollable }) =>
            isScrollable &&
            `
                // allow text area to be scrollable
                height: inherit;
            `}
        // override overflow: hidden; in order to allow the header toolbar header to become sticky
        overflow: unset;
    }

    // fullscreen plugin
    .tox.tox-tinymce.tox-fullscreen {
        /**
         * Override TinyMCE's styling of the full screen version of the editor. We want the expanded narrative editor to
         * be displayed normally within the document flow with position: static; instead of position: fixed; in order
         * for the narrative card header and footer to be sticky at the top and bottom of the screen without needing to
         * adjust the editor position.
         */
        position: static;
        /**
         * The !important override is because TinyMCE sets the style attribute to this div, .tox-fullscreen, with px
         * values for width and height. Width is unset to 100%. TinyMCE's dynamic height works within the document flow.
         */
        width: 100% !important;
    }

    // sidebar which contains inline comments
    .tox .tox-sidebar {
        /**
         * Take the sidebar out of the normal flow of the document. Otherwise, the sidebar would take up the full height
         * of the narrative card (making it a lot taller than it should be), and disrupt how the narrative content does
         * word wrapping (the total width of the editor should not be impacted by the appearance of the sidebar). This
         * also helps us position the comment vertically to align with the highlighted text.
         */
        position: absolute;
        z-index: ${zIndexes.narrativeInlineComment};
        // 20px of horizontal spacing between the narrative card and the inline comment
        left: calc(100% + 20px);
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
        background-color: ${(props) => props.theme.colors.white};
        box-sizing: border-box;
        border-radius: 5px;
        color: ${(props) => props.theme.colors.darkGrey};

        .tox-textarea {
            background-color: ${(props) => props.theme.colors.fieldBg};
            color: ${(props) => props.theme.colors.darkGrey};
            font-size: var(--arc-fontSizes-md);
        }

        .tox-textarea:focus {
            box-shadow: 0 0 0 1px var(--arc-colors-brand-default);
            border-color: var(--arc-colors-brand-default);
            outline: none;
        }
    }

    .tox .tox-sidebar--sliding-growing,
    .tox .tox-sidebar--sliding-shrinking {
        /**
         * When the sidebar is opened, it has a sliding animation by default. This value of 1ms effectively disables
         * that animation to the human eye. A duration of 0s doesn't work because the transition still needs to happen
         * in order to display the sidebar. This is the simplest solution without having to customize our own skin or
         * patch the TinyMCE source.
         */
        transition-duration: 1ms;
    }

    // comments
    .tox-conversations {
        overflow-y: auto;
    }
    // hide the "Comments" header, which is not in our design
    .tox-conversations__header {
        display: none !important;
    }
    // hide the avatar, since our user profiles have no avatars
    .tox-user__avatar {
        display: none;
    }
    .tox-conversations .tox-collection__item:nth-child(odd) {
        display: none;
    }

    // in a long comment, hide the gradient
    .tox .tox-comment__gradient::after {
        display: none;
    }
    // below a long comment, this is the clickable element that says "SHOW MORE" or "SHOW LESS"
    .tox .tox-comment__expander p {
        color: ${(props) => props.theme.colors.darkGrey};
    }

    .tox-comment__reply .tox-button {
        ${({ canViewComments, displayCommentSection }) =>
            canViewComments &&
            !displayCommentSection &&
            `
            display: none;
        `}
    }
    .tox-comment__reply textarea {
        ${({ canViewComments, displayCommentSection }) =>
            canViewComments &&
            !displayCommentSection &&
            `
            display: none;
        `}
    }

    // Resolve Button
    .tox-editor-header .tox .tox-button--icon,
    .tox .tox-button.tox-button--icon,
    .tox .tox-button.tox-button--secondary.tox-button--icon {
        ${({ canResolveComments, canViewComments }) =>
            !canResolveComments &&
            canViewComments &&
            `
            display: none;
        `}
    }
    .tox-conversations :nth-child(3).tox-comment__reply {
        display: flex !important;
    }
    .tox-comment__reply {
        margin: 0 !important;
        padding: 7px;
        ${({ canViewComments, displayCommentSection }) =>
            canViewComments &&
            !displayCommentSection &&
            `
            display: none !important;
        `}
    }
    .tox-conversations .tox-comment__reply {
        display: none;
    }
    .tox-comment__reply .tox-button {
        // style the "Comment" button like the Arc primary button
        display: inline-flex;
        align-items: center;
        justify-content: center;
        user-select: none;
        white-space: nowrap;
        vertical-align: middle;
        outline: transparent solid 2px;
        outline-offset: 2px;
        width: auto;
        line-height: 1.2;
        border-radius: var(--arc-radii-md);
        font-weight: var(--arc-fontWeights-semibold);
        color: var(--arc-colors-brand-emphasisContent);
        height: var(--arc-sizes-control-md);
        min-width: var(--arc-sizes-control-md);
        font-size: var(--arc-fontSizes-md);
        padding-inline-start: var(--arc-space-3);
        padding-inline-end: var(--arc-space-3);
        background: var(--arc-colors-brand-emphasis);

        &.tox-button--secondary {
            // hide the "Clear" button, which is not in our design
            display: none;
        }
    }

    .tox .tox-tbtn {
        background: var(--arc-colors-surface-foreground);
    }

    .tox .tox-tbtn:hover {
        background: var(--arc-colors-interactive-hover);
    }

    button.tox-tbtn.tox-tbtn--select {
        color: var(--arc-colors-text-primary);
        font-size: 20px;
        font-weight: ${(props) => props.theme.fontWeights.semiBold};
    }
    button.tox-tbtn.tox-tbtn--select.tox-tbtn--bespoke {
        width: 70px;
        background: var(--arc-colors-surface-foreground);
    }

    /* Toolbar Menu Buttons */
    button.tox-tbtn[aria-disabled='false'] svg,
    button.tox-tbtn.tox-tbtn--select svg {
        fill: var(--arc-colors-text-primary);
    }

    button.tox-tbtn[aria-disabled='true'] svg {
        fill: var(--arc-colors-text-tertiary);
    }

    .tox .tox-tbtn--disabled {
        pointer-events: none;
    }

    /* Toolbar Menu */
    div.tox-toolbar__primary,
    div.tox-editor-header,
    div.tox:not(.tox-tinymce-inline) .tox-editor-header {
        background-color: ${(props) => props.theme.colors.white};
    }

    .tox .tox-menubar + .tox-toolbar-overlord {
        border-top: none;
        padding-top: 0;
        padding-bottom: 0;
        border-bottom: 0;
    }

    /* Never show TinyMCE's comment view, since we show it with TinyInlineComments.tsx */
    div.tox .tox-comment-thread {
        display: none;
    }
    div.tox .tox-comment-thread,
    div.tox .tox-comment-thread .tox-comment,
    div.tox-conversations .tox-collection__item {
        background-color: ${(props) => props.theme.colors.white};
    }
    div.tox .tox-comment-thread .tox-comment {
        border: 0;
    }
    div.tox .tox-user__name,
    div.tox .tox-comment__date,
    div.tox .tox-comment__body,
    div.tox-conversations .tox-collection__item,
    div.tox
        .tox-collection--list
        .tox-collection__item--active:not(.tox-collection__item--state-disabled) {
        color: ${(props) => props.theme.colors.darkGrey};
        background-color: ${(props) => props.theme.colors.mediumBlue};
    }

    div.tox .tox-collection--list .tox-collection__group {
        padding: 2px 0;
    }
    div.tox .tox-menu.tox-collection.tox-collection--list {
        padding: 0 2px;
    }

    div.tox .tox-button--icon .tox-icon svg,
    .tox .tox-button.tox-button--icon .tox-icon svg {
        fill: ${(props) => props.theme.colors.mediumGrey};
    }

    .tox-tooltip-worker-container {
        z-index: 2000;
    }
`;

type TinyEditorExtension = {
    name: string;
    enabled: boolean;
};

type EditorEntityType =
    | typeof EntityTypeEnum.REPORT.name
    | typeof EntityTypeEnum.CASE_NOTE.name
    | typeof EntityTypeEnum.BRIEFING.name;

export type EditorProps = {
    id: string;
    entityType?: EditorEntityType;
    entityId?: number;
    onChange?: (value: string) => void;
    value?: string;
    // TODO: Typescriptify narrativeForm.js
    form?: _Form<MFTFormConfiguration>;
    hideNarrativeGuide: boolean;
    mentionsEnabled?: boolean;
    inlineCommentsEnabled?: boolean;
    imagesEnabled?: boolean;
    setCurrentAutosaveValue: (value: string) => void;
    className?: string;
    testId?: string;
    fieldName?: string;
    setError: (error: string) => void;
    getSummaryMode?: () => boolean;
    editorRef?: React.MutableRefObject<TinyEditorType | null>;
    onValueChange?: (value: string) => void;
    onToggleComments?: (commentIsSelected: boolean, selectedCommentId?: number) => void;
    onFullScreenToggle: () => void;
    setEditor?: (editor: TinyEditorType) => void;
    isScrollable?: boolean;
};

const NoNarrativeGuides: Ui.Menu.MenuItemSpec[] = [
    {
        type: 'menuitem',
        text: strings.NarrativeVariables.noResults,
        onAction: noop,
    },
];

const getEnabledExtensions = (items: TinyEditorExtension[]) =>
    items
        .filter(({ enabled }) => enabled)
        .map(({ name }) => name)
        .join(' ');

/**
 * In order to support `react-hotkeys` we have to propagate events from the tinyMCE container inside the
 * iframe to the main document on the page.
 */
function setupReactHotKeyIntegration(editor: TinyEditorType) {
    // We need to propagate both `keyDown` and `keyUp` because
    // if react-hotkey never receives the `keyUp` signal,
    // it will think that we are stringing together a combination (when really two separate keys were pressed)
    // We additionally propagate the `keyPress` as well because I saw that react-hotkeys
    // listens for this in its sourcecode (though I don't think it really makes a difference for us)
    editor.on('keyDown', (event) => {
        const keyboardEvent = new KeyboardEvent(event.type, event);
        editor?.iframeElement?.dispatchEvent(keyboardEvent);
    });
    editor.on('keyUp', (event) => {
        const keyboardEvent = new KeyboardEvent(event.type, event);
        editor?.iframeElement?.dispatchEvent(keyboardEvent);
    });
    editor.on('keyPress', (event) => {
        const keyboardEvent = new KeyboardEvent(event.type, event);
        editor?.iframeElement?.dispatchEvent(keyboardEvent);
    });
    // This method propagates focus events from the iframe up to the parent document
    //
    // We need to do this because the internals of `react-hotkeys`
    // rely on the bubbling of `focus` events to determine whether or not we should
    // listen for `keyDown` events on the current container
    //
    // E.g. if the tinyMCE editor never tells the parent document that it has been focused,
    // then react-hotkeys will not listen for any keyDown events from it
    //
    // We do the same for the blur event.
    //
    // Note, however, that there is a subtle bug here that I could not figure out.
    // The next card that we focus on needs to be focused twice in order for the card level
    // hotkeys to apply to them
    //
    // For instance, clicking from the tinyMCE card into the event card will not immediately bind the hotkeys
    // to the event card. You need to interact with the event card one more time before hotkeys get bound to it
    editor.on('focus', (event) => {
        const focusEvent = new FocusEvent('focus', {
            ...event,
            relatedTarget: window.document,
        });
        editor?.iframeElement?.dispatchEvent(focusEvent);
    });
    editor.on('blur', (event) => {
        const blurEvent = new FocusEvent('blur', {
            ...event,
            relatedTarget: event.target.iframeElement,
        });
        editor?.iframeElement?.dispatchEvent(blurEvent);
    });
}

const BaseTinyEditor = ({
    id,
    value,
    form,
    hideNarrativeGuide,
    mentionsEnabled = false,
    inlineCommentsEnabled,
    className,
    setError,
    testId,
    fieldName,
    getSummaryMode = () => false,
    setCurrentAutosaveValue,
    onToggleComments,
    onFullScreenToggle,
    setEditor,
    editorRef,
    entityId,
    entityType = EntityTypeEnum.REPORT.name,
    imagesEnabled = false,
    isScrollable,
}: EditorProps) => {
    const dispatch = useDispatch();
    const addMark43CommentMetadata = useAddMark43CommentMetadata();

    const [initState, setInitState] = useState<InitState>(initStates.INITIALIZING);
    const [showAddCommentSection, setShowAddCommentSection] = useState(false);

    const guides = useSelector(narrativeGuidesByEntityTypeSelecor)(entityType);
    const currentTheme = useSelector(currentThemeSelector);
    const reportInlineCommentsView = useSelector(reportInlineCommentsViewSelector);
    const currentReportId = useSelector(currentReportSelector)?.id;
    const hasBanner = useSelector(hasBannerSelector);

    const canEditReportCardStatus = useSelector(canEditReportCardStatusSelector);

    const isDisabled = getSummaryMode();

    const currentUser = useSelector(currentUserProfileSelector);

    const formatMiniUserById = useSelector(formatMiniUserByIdSelector);

    const currentUserFullName = formatMiniUserById(currentUser?.userId || 0);

    const store = useStore<RootState>();

    const applicationSettings = useSelector(applicationSettingsSelector);

    const canViewInlineComments =
        useSelector(canViewInlineCommentsSelector) && !!inlineCommentsEnabled;
    const canResolveInlineComments =
        useSelector(canResolveInlineCommentsSelector) && !!inlineCommentsEnabled;

    const initialValue = migratePreExistingComments(
        preprocessHtml(value || ''),
        formatMiniUserById,
        reportInlineCommentsView
    );

    const initTimeout =
        applicationSettings.RMS_TINYMCE_INIT_TIMEOUT || TINYMCE_DEFAULT_INIT_TIMEOUT;
    React.useEffect(() => {
        // prevent the TinyMCE editor from taking an indefinitely long time to initialize
        const timeout =
            initState === initStates.INITIALIZING
                ? setTimeout(() => {
                      setInitState((prevInitState) => {
                          if (prevInitState === initStates.INITIALIZING) {
                              setError(errorStrings.failedToInitialize(entityType));
                          }
                          clearDeferredOperationsForTinyEditor(id);
                          return initStates.FAILED;
                      });
                  }, initTimeout)
                : undefined;

        return () => {
            if (timeout) {
                clearTimeout(timeout);
            }
        };
    }, [id, initState, setInitState, initTimeout, setError, entityType]);

    React.useEffect(() => {
        return () => {
            clearDeferredOperationsForTinyEditor(id);
        };
    }, [id]);

    const baseUrl = getTinyMCEBaseUrl(useIsComponentTestEnvironment());

    const isImageUploadEnabled = imagesEnabled && Boolean(entityId);
    const isBriefingEditor = entityType === EntityTypeEnum.BRIEFING.name;
    const isMentionsForBriefingEnabled = mentionsEnabled && isBriefingEditor;
    const isMentionsForNarrativeEnabled = mentionsEnabled && !isBriefingEditor;

    const plugins = getEnabledExtensions([
        { name: 'lists', enabled: true },
        { name: 'powerpaste', enabled: true },
        { name: 'fullscreen', enabled: true },
        { name: 'tinycomments', enabled: Boolean(inlineCommentsEnabled) },
        { name: 'mentions', enabled: mentionsEnabled },
        { name: 'autoresize', enabled: !isBriefingEditor },
        { name: 'link', enabled: true },
        { name: 'autolink', enabled: true },
        { name: 'image', enabled: isImageUploadEnabled },
    ]);

    const toolbar = getEnabledExtensions([
        { name: 'undo', enabled: true },
        { name: 'redo', enabled: true },
        { name: 'mentions', enabled: mentionsEnabled },
        { name: 'narrativeguides', enabled: !hideNarrativeGuide },
        { name: 'bold', enabled: true },
        { name: 'italic', enabled: true },
        { name: 'underline', enabled: true },
        { name: 'fontsize', enabled: true },
        { name: 'align', enabled: true },
        { name: 'numlist', enabled: true },
        { name: 'bullist', enabled: true },
        { name: 'outdent', enabled: true },
        { name: 'indent', enabled: true },
        { name: 'link', enabled: true },
        { name: 'unlink', enabled: true },
        { name: 'image', enabled: isImageUploadEnabled },
    ]);

    const handleImageUpload = useEditorImage({
        entityId,
        entityType,
    });

    const mentionsForBriefing = useMentionsForBriefing({
        isEnabled: isMentionsForBriefingEnabled,
        isViewMode: isDisabled,
        onError: setError,
    });

    const initOptions: TinyEditorProps['init'] = {
        setup: (editor) => {
            setupReactHotKeyIntegration(editor);

            /**
             * Always add the narrative guides but hide them if there are no narrative guides
             */
            if (!hideNarrativeGuide) {
                editor.ui.registry.addMenuButton('narrativeguides', {
                    text: strings.NarrativeVariables.g,
                    tooltip: strings.NarrativeCard.guideDropdownLabel,
                    search: {
                        placeholder: strings.NarrativeVariables.guideSearchPlaceholder,
                    },
                    fetch(callback, fetchContext) {
                        const guides = narrativeGuidesByEntityTypeSelecor(store.getState())(
                            entityType
                        );

                        const filteredGuides =
                            fetchContext.pattern.length > 0
                                ? filter(guides, ({ name }) =>
                                      includes(
                                          name.toLowerCase(),
                                          fetchContext.pattern.toLowerCase()
                                      )
                                  )
                                : guides;

                        const items =
                            filteredGuides.length > 0
                                ? map(filteredGuides, ({ content, name }) => {
                                      const item: Ui.Menu.MenuItemSpec = {
                                          type: 'menuitem',
                                          text: name,
                                          onAction() {
                                              const bookmark = editor.selection.getBookmark(
                                                  2, // Magic number to get a working bookmark, it seems like no enum or docs are provided for this.
                                                  true
                                              );
                                              editor.insertContent(content);
                                              // Restore caret position back to previous bookmark.
                                              editor.selection.moveToBookmark(bookmark);
                                              // If we do not collapse the selection prior to scrolling we can end up in a situation
                                              // where a user selects a block of text and replaces it with a narrative guide,
                                              // but the caret won't get properly scrolled into view.
                                              editor.selection.collapse(true);
                                              // For long narrative guides the caret is now not visible anymore, so we have
                                              // to scroll it into view. This will scroll the node into the center of the screen.
                                              // If this is too jarring, other options can be investigated in the future.
                                              const selectionNode = editor.selection.getNode();
                                              // We have an edge case where someone could select all the text in the editor and replace
                                              // it with a narrative guide. In this case the selection node will be the editor's body
                                              // node, in which case scrolling won't properly work. As a workaround we will select the first
                                              // child and scroll to it instead.
                                              const scrollNode =
                                                  selectionNode === editor.getBody()
                                                      ? selectionNode.firstChild
                                                      : selectionNode;
                                              // Since we are dealing with iframes, we cannot use a normal `instanceof` check as classes
                                              // do not cross realms. We can get a hold of the realm's constructor though and check against it.
                                              const OwnerDocumentHTMLElement =
                                                  scrollNode?.ownerDocument?.defaultView
                                                      ?.HTMLElement;
                                              if (
                                                  OwnerDocumentHTMLElement &&
                                                  scrollNode instanceof OwnerDocumentHTMLElement
                                              ) {
                                                  scrollNode.scrollIntoView({
                                                      block: 'center',
                                                      behavior: 'smooth',
                                                      inline: 'center',
                                                  });
                                              }
                                          },
                                      };

                                      return item;
                                  })
                                : NoNarrativeGuides;

                        callback(items);
                    },
                });
            }

            if (isMentionsForNarrativeEnabled) {
                editor.ui.registry.addButton('mentions', {
                    text: strings.NarrativeVariables.at,
                    onAction() {
                        editor.insertContent('@');
                        maybeOpenMentionsMenu(editor);
                    },
                });
            }

            if (setEditor) {
                setEditor(editor);
            }

            /**
             * TinyMCE comes with 2 editor modes: 'readonly' and 'design'.
             *
             * Comments are not supported in 'readonly' mode (they do not appear at all), but the narrative card needs
             *   to support inline comments even when it is in summary mode (provided the user is allowed to view
             *   comments).
             *
             * So we register a 3rd mode 'summary' to support inline comments.
             * - It represents our concept of summary mode.
             * - It effectively replaces 'readonly' mode. We always change the mode from 'readonly' to 'summary' here.
             *     We never explicitly set the mode to 'readonly' anywhere. The `disabled` boolean prop on TinyEditor
             *     initializes the mode as either 'readonly' or 'design'. When the `disabled` value changes, TinyMCE
             *     automatically switches the mode between 'readonly' and 'design'.
             * - It behaves like 'design' mode in order to support comments, except we make the iframe body uneditable,
             *     which prevents the user from typing in the editor.
             * - If the user can't view comments, this mode still works without comments.
             * - If your usage of the editor doesn't have a summary mode, then don't use this mode and set
             *     disabled={false}.
             */
            editor.mode.register('summary', {
                activate: noop,
                deactivate: noop,
                editorReadOnly: false,
            });
            editor.off('SwitchMode');
            editor.on('SwitchMode', (event) => {
                if (event.mode === 'readonly') {
                    editor.mode.set('summary');
                } else if (event.mode === 'summary') {
                    editor.getBody().setAttribute('contenteditable', 'false');
                    hideTinyHeader(editor);
                } else {
                    // This mode is 'design', which corresponds to our concept of edit mode
                    editor.getBody().setAttribute('contenteditable', 'true');
                    showTinyHeader(editor);
                }
            });

            // This is a fix for the dropdown menus in the toolbar not sticking to the toolbar when you scroll
            // The fix was shared here: https://github.com/tinymce/tinymce/issues/5097#issuecomment-663360737
            // It should be included in a future version of tinymce (presumably 6.4, but yet to be seen)
            editor.off('PostRender');
            editor.on('PostRender', () => {
                const container = editor.getContainer();
                const uiContainer = document.querySelector('body > .tox.tox-tinymce-aux');
                container?.parentNode?.appendChild(uiContainer as Node);
            });
            editor.off('FullscreenStateChanged');
            editor.on('FullscreenStateChanged', onFullScreenToggle);

            /**
             * https://www.tiny.cloud/docs/tinymce/6/events/#editor-core-events
             * The TinyMCE events happen in this order:
             * 1. `ScriptsLoaded` event, after all scripts including plugins have loaded
             *        if this fails, the next events aren't fired, and we handle it with the `onScriptsLoadError` prop
             * 2. `Load` event, after the iframe has loaded
             * 3. `PreInit` event, after the editor has loaded and before the content has loaded
             * 3. `LoadContent` event, after the initial content has loaded into the editor
             * 4. `init` event, after the editor is fully initialized
             *        we wait for this last event to run our init code
             */
            editor.on('init', (e) => {
                initLogRocketForTinyEditor(editor, baseUrl);

                // Since the `onSelectionChange` prop does not run when the editor is in readonly/summary mode, we bind
                // a selection handler directly into the iframe document.
                e.target.getDoc().onselectionchange = createSelectionHandler({
                    editor,
                    getSummaryMode,
                    getCanAddInlineComments: () => {
                        return (
                            canViewInlineComments &&
                            enableAddInlineCommentsSelector(store.getState())(
                                currentReportId || 0
                            ) &&
                            canEditReportCardStatus &&
                            canEditReportCardStatus.canEditReportCard
                        );
                    },
                });

                if (inlineCommentsEnabled && onToggleComments) {
                    /**
                     * This callback runs whenever the currently selected comment (annotation) has changed.
                     * https://www.tiny.cloud/docs/tinymce/6/comments-commands-events-apis/#tinycomments-annotator
                     * https://www.tiny.cloud/docs/tinymce/6/apis/tinymce.annotator/#annotationChanged
                     *
                     * The transitions can be split into 3 meaningful types:
                     * 1. When no comment is selected, the user clicks on highlighted text.
                     *      `selected` is true, and `context` represents the selected comment.
                     *      All comments will be shown. The selected comment will be indented.
                     * 2. When a comment is selected, the user clicks on non-highlighted text.
                     *      `selected` is false, and `context` is undefined.
                     *      All comments will be hidden.
                     * 3. When a comment is selected, the user clicks on highlighted text for a different comment.
                     *      `selected` is true, and `context` represents the newly selected comment.
                     *      The newly selected comment will be indented.
                     *      The previously selected comment will be unindented.
                     *
                     * This means:
                     *   This callback does not run when the user repeatedly clicks the highlighted text for the same comment.
                     *   This callback does not run when the user repeatedly clicks non-highlighted text.
                     *   This callback does not handle showing the box for adding a new inline comment.
                     */
                    editor.annotator.annotationChanged(
                        'tinycomments',
                        (selected, annotatorName, context) => {
                            if (selected) {
                                const selectedReportCommentId = context?.uid
                                    ? parseReportCommentId(context?.uid)
                                    : undefined;
                                onToggleComments(true, selectedReportCommentId);
                            } else {
                                onToggleComments(false, undefined);
                            }
                        }
                    );
                }

                setupLinkPlugin(editor);

                mentionsForBriefing.setupEditor(editor);

                setInitState(initStates.INITIALIZED);
                if (editorRef) {
                    // set the ref only after initialization is successful, because the editor is useless otherwise
                    editorRef.current = editor;
                }
                executeDeferredOperationsForTinyEditor(id, editor);
            });
        },
        // see the webpack config for how tinymce files are built to this directory
        base_url: baseUrl,
        promotion: false,
        menubar: '',
        content_style: [
            getBaseContentStyles(
                currentTheme,
                !!applicationSettings.RMS_INLINE_NARRATIVE_COMMENTS_ENABLED
            ),
            mentionsForBriefing.contentStyles(),
        ].join('\n'),
        statusbar: false,
        inline: false,
        browser_spellcheck: true,
        contextmenu: 'false',
        font_size_formats: '12px 13px 14px 16px 18px 24px 36px',
        toolbar,
        plugins,
        min_height: 200,
        ...(isBriefingEditor
            ? {
                  height: '100%',
              }
            : {}),
        autoresize_bottom_margin: 0,
        tinycomments_author: currentUserFullName,
        tinycomments_author_name: currentUserFullName,
        tinycomments_mode: 'embedded',
        mergetags_list: [
            { value: 'First.Name', title: 'First Name' },
            { value: 'Email', title: 'Email' },
        ],
        ...(isMentionsForNarrativeEnabled
            ? {
                  mentions_fetch: createMentionsFetchHandler(store),
                  mentions_min_chars: 0,
                  mentions_selector: 'span.mention',
                  mentions_item_type: 'name',
                  mentions_menu_complete: insertMention,
              }
            : {}),
        ...mentionsForBriefing.pluginConfiguration(),
        // These options apply to the core copy & paste functionality in TinyMCE
        // https://www.tiny.cloud/docs/tinymce/6/copy-and-paste/
        paste_block_drop: true,
        paste_merge_formats: true,
        smart_paste: false,
        paste_data_images: false,
        paste_remove_styles_if_webkit: true,
        // These options apply the premium PowerPaste plugin
        // https://www.tiny.cloud/docs/tinymce/6/powerpaste-options/
        powerpaste_word_import: 'merge',
        powerpaste_googledocs_import: 'merge',
        powerpaste_html_import: 'merge',
        powerpaste_allow_local_images: false,
        powerpaste_block_drop: true,
        // When the user pastes HTML into the editor, restrict elements and styles which our narratives don't support
        // https://www.tiny.cloud/docs/tinymce/6/content-filtering/#valid_elements
        valid_elements: [
            // This alphabetical list of HTML elements is almost the same as sanitizeHtmlString in HtmlTextElf.java,
            // with the following differences.
            // When the `class` attribute is pasted on any of these elements, it should have no effect.
            'a[href|target]',
            'b',
            'blockquote',
            'br',
            'cite',
            // <code> is excluded because:
            // - It's easy to unintentionally paste in. For example, pasting a Google Doc includes some empty <code>s,
            //   which take time to delete.
            // - Its default styling with a grey background color is very different from the other elements.
            // - When the user moves the cursor to any part of the <code>, the entire element is highlighted, which may
            //   be confused with the highlight of an inline comment.
            'dd',
            // <div> cannot be created when typing into the editor, but can be pasted from an external source with
            // inline styles.
            'div[style]',
            'dl',
            'dt',
            'em',
            // <h1>, <h2>, <h3>, <h4>, <h5>, <h6> are not allowed in sanitizeHtmlString. Since we exclude them here, the
            // editor converts them to <span>s with font-size, with the same result as using the font size tool in the
            // TinyMCE toolbar.
            'i',
            'img[src|alt|width|height]',
            'li',
            'ol',
            // <p> has an inline style when the text is aligned right, center, justified, or left
            'p[style]',
            // <pre> is excluded for the same reason as <code>
            'q',
            's',
            'small',
            // <span> is used for both styling and inline comments
            `span[style${
                inlineCommentsEnabled
                    ? // inline narrative comments use these 3 attributes
                      '|class|data-id|data-custom-element'
                    : ''
            }${mentionsForBriefing.validElements()}]`,
            'strike',
            'strong',
            'sub',
            'sup',
            'u',
            'ul',
        ].join(','),
        valid_styles:
            'font-size font-style font-weight padding-left text-align text-decoration text-decoration-line',
        paste_postprocess: powerPastePostProcess,
        link_default_target: '_blank',
        images_upload_handler: handleImageUpload,
    };

    return (
        <TinyWrapper
            className={className}
            data-test-id={testId}
            data-test-field-name={fieldName}
            showNarrativeGuidesButton={!hideNarrativeGuide && !!guides.length}
            hasBanner={hasBanner}
            summaryMode={isDisabled}
            canViewComments={canViewInlineComments}
            displayCommentSection={showAddCommentSection}
            canResolveComments={canResolveInlineComments}
            isScrollable={isScrollable}
        >
            {initState === initStates.INITIALIZING ? (
                <Box padding="4">
                    <SkeletonText noOfLines={5} />
                </Box>
            ) : undefined}
            {initState !== initStates.FAILED ? (
                // stop rendering the editor if it fails to initialize, otherwise the editor UI will appear with empty
                // content and the user will be able to type in it, even though they won't be able to save the content
                <TinyEditor
                    tinymceScriptSrc={`${baseUrl}tinymce.min.js`}
                    id={id}
                    onScriptsLoadError={(err) => {
                        // `err` comes from TinyMCE and may be an Error object or an Event object with no error message
                        setError(`${errorStrings.failedToLoad} ${errorToMessage(err)}`);
                        setInitState(initStates.FAILED);
                    }}
                    initialValue={initialValue}
                    disabled={isDisabled}
                    onClick={(e, editor) => {
                        // check if there is inline comments and hide reply section
                        const conversationElement = editor
                            .getContainer()
                            .getElementsByClassName('tox-comment__scroll');

                        if (mentionsEnabled) {
                            maybeOpenMentionsMenuAndMoveCursorToEndOfMention(editor);
                        }

                        if (conversationElement.length > 0) {
                            setShowAddCommentSection(false);
                            const replySection = editor
                                .getContainer()
                                .getElementsByClassName('tox-comment__reply');
                            if (replySection.length > 0) {
                                replySection.item(0)?.remove();
                            }
                            updateCommentSidebarPosition(editor);
                        } else {
                            setShowAddCommentSection(true);
                        }
                    }}
                    onCommentChange={(e, editor) => {
                        const incomingHTML = editor.getContent();
                        addMark43CommentMetadata(
                            incomingHTML,
                            currentReportId,
                            setCurrentAutosaveValue,
                            setError,
                            dispatch,
                            editor
                        ).then((result) => {
                            if (result?.narrativeHtml) {
                                form?.set('narrative', result.narrativeHtml);
                                dispatch(
                                    updateNarrativeHtmlWithComments(editor, result.narrativeHtml)
                                );
                                setCurrentAutosaveValue(result.narrativeHtml);

                                if (result.reportCommentId && onToggleComments) {
                                    // Create a DOM selection of the highlighted element in order to indirectly trigger
                                    // `onToggleComments` and select the new inline comment. This is the same thing that
                                    // happens when the user clicks on the highlighted text. We don't directly call
                                    // `onToggleComments` here because the TinyMCE annotator wouldn't have the correct state
                                    // of which annotation is selected, and there is no API to update its internal state.
                                    const doc = editor.getDoc();
                                    const element = doc.querySelector<HTMLElement>(
                                        `.highlight[data-id="${result.reportCommentId}"]`
                                    );
                                    if (element) {
                                        const range = doc.createRange();
                                        // Instead of selecting the entire first node with `range.selectNode(element)`,
                                        // which would add blue highlighting, it's a better visual result to avoid that and
                                        // instead limit the selection to the start of the node.
                                        range.setStart(element, 0);
                                        range.setEnd(element, 0);
                                        doc.getSelection()?.removeAllRanges();
                                        doc.getSelection()?.addRange(range);
                                    }
                                }
                            }
                        });
                    }}
                    init={initOptions}
                />
            ) : undefined}
        </TinyWrapper>
    );
};

export function Editor(props: EditorProps): JSX.Element {
    return (
        <ErrorBoundary
            fallback={(errorMessage: string) => (
                <InlineBanner status="error">{errorMessage}</InlineBanner>
            )}
        >
            <BaseTinyEditor {...props} />
        </ErrorBoundary>
    );
}
