import { RootState } from "../../../../../legacy-redux/reducers/rootReducer";
import {
  RmsDispatch,
  DependenciesArg,
} from "../../../../../core/typings/redux";
import { SCREENS } from "../../../../core/persons/state/data";

type ValueOf<T> = T[keyof T];
type ScreenType = ValueOf<typeof SCREENS>;

export function openSidePanel({
  entityId,
  nameOverlayId,
  screen,
  formSectionState,
}: {
  entityId?: number;
  nameOverlayId: string;
  screen: ScreenType;
  formSectionState: Record<string, boolean>;
}) {
  return (
    dispatch: RmsDispatch,
    getState: () => RootState,
    dependencies: DependenciesArg
  ) => {
    dependencies.overlayStore.open(nameOverlayId, {
      entityId,
      screenStack: [
        {
          screen,
          screenState: {
            selectedId: entityId,
          },
          formSectionState,
        },
      ],
    });
  };
}

export function openSearchResultsSidePanel({
  nameOverlayId,
  searchResultIds,
  totalSearchResultCount,
}: {
  nameOverlayId: string;
  searchResultIds?: number[];
  totalSearchResultCount: number;
}) {
  return (
    dispatch: RmsDispatch,
    getState: () => RootState,
    dependencies: DependenciesArg
  ) => {
    dependencies.overlayStore.open(nameOverlayId, {
      screenStack: [
        {
          screen: SCREENS.SEARCH_FORM,
          screenState: {},
        },
        {
          screen: SCREENS.SEARCH_RESULTS,
          screenState: {
            searchResultIds,
            totalSearchResultCount,
          },
        },
      ],
    });
  };
}

  export function openCadSearchResultsSidePanel({
    cadProfileId,
    nameOverlayId,
    screen,
    formSectionState,
  }: {
    cadProfileId: number;
    nameOverlayId: string;
    screen: ScreenType;
    formSectionState: Record<string, boolean>;
  }) {
    return (
        dispatch: RmsDispatch,
        getState: () => RootState,
        dependencies: DependenciesArg
      ) => {
        dependencies.overlayStore.open(nameOverlayId, {
          cadProfileId,
          autoSearch: true,
          screenStack: [
            {
              screen,
              screenState: {
              },
              formSectionState,
            },
          ],
        });
      };
  }
