import { EntityTypeEnum, AttributeTypeEnum } from '@mark43/rms-api';
import { TableColumn as Column } from 'components-mark43';
import { chain, get, map, size, thru, isEmpty, filter, includes } from 'lodash';
import React from 'react';
import { connect, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import ConnectedFormattedAttribute from '~/client-common/core/domain/attributes/components/ConnectedFormattedAttribute';
import {
    formatAttributeWithOtherSelector,
    formatAttributeByIdSelector,
} from '~/client-common/core/domain/attributes/state/data';
import { personProfileViewModelByIdSelector } from '~/client-common/core/domain/person-profiles/state/ui/';
import { organizationProfileViewModelByIdSelector } from '~/client-common/core/domain/organization-profiles/state/ui/';
import { formatElasticStorageLocationByIdSelector } from '~/client-common/core/domain/elastic-storage-locations/state/ui';
import { FormattedDate } from '~/client-common/core/dates/components';
import { formatNameItemAssociationAttrByNameItemLinkIdSelector } from '~/client-common/core/domain/name-item-links/state/ui';
import { formatDeclaredValue } from '~/client-common/core/domain/property-statuses/utils/propertyStatusHelpers';
import * as fields from '~/client-common/core/enums/universal/fields';
import withFields from '~/client-common/core/fields/components/withFields';
import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import componentStrings from '~/client-common/core/strings/componentStrings';
import {
    recoveredLocationViewModelPropertiesByItemProfileIdSelector,
    sortedLocationBundlesForLocationEntityLinksWhereSelector,
} from '~/client-common/core/domain/locations/state/ui';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import { joinTruthyValues } from '~/client-common/helpers/stringHelpers';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import {
    allItemLocationTypes,
    fieldByItemLocationType,
} from '~/client-common/helpers/itemLocationLinksHelper';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import _SummaryList from '../../../../../../legacy-redux/components/summaries/SummaryList';
import SummaryRow from '../../../../../../legacy-redux/components/summaries/SummaryRow';
import _SummarySectionDivider from '../../../../../../legacy-redux/components/summaries/SummarySectionDivider';
import { useCurrencyFormatter } from '../../../../../core/current-user/hooks/useCurrencyFormatter';
import { custodyTypeIsUser } from '../../../../../core/forms/components/selects/CustodyTypeSelect';
import LegacyEntityDetailSummaryRow from '../../../../../records/core/components/summaries/LegacyEntityDetailSummaryRow';
import TrafficCrashVehicleSummary from '../../../../traffic-crash/components/TrafficCrashVehicleSummary';
import { FileGrid } from '../../../../../../legacy-redux/components/core/Files';

/**
 * Labels take precedence over fields
 * @param fields
 * @param labels
 * @param values
 * @param index
 * @param showDivider
 */
const renderDetailsContent = ({ fields, labels, values, index = 0, showDivider = true }) =>
    chain(labels || fields)
        .zip(values)
        .filter(([, value]) => value)
        .map(([label, value], _index) => {
            const props = labels ? { label } : { fieldName: label };

            return (
                <SummaryRow key={`summary-row_${_index}`} {...props}>
                    {value}
                </SummaryRow>
            );
        })
        .thru((content) =>
            !size(content) ? null : (
                <Column key={`column_${index}`}>
                    {showDivider && <SummarySectionDivider />}
                    <SummaryList labelWidth={150} contentWidth={250}>
                        {content}
                    </SummaryList>
                </Column>
            )
        )
        .value();

const strings = componentStrings.reports.core.ItemCard;

const SummaryList = styled(_SummaryList)`
    margin: 10px;
    word-break: break-word;
`;

const SummarySectionDivider = styled(_SummarySectionDivider)`
    float: none;
    margin: 0 15px 0 10px;
    width: auto;
`;

const Identifiers = connect(
    createStructuredSelector({
        formatAttributeWithOther: formatAttributeWithOtherSelector,
    })
)(({ formatAttributeWithOther, data }) =>
    renderDetailsContent({
        labels: map(
            data,
            ({ itemIdentifierTypeAttrId: attributeId, itemIdentifierTypeOtherDesc: other }) =>
                formatAttributeWithOther({
                    attributeId,
                    other,
                    joinWith: ' - ',
                })
        ),
        values: map(data, 'identifier'),
    })
);

const renderQuantityContent = ({ quantity, measurementUnitsAttrId, fallback = null }) => {
    if (!quantity) {
        return fallback;
    }
    return (
        <span>
            {[quantity, ''].join(' ')}
            <ConnectedFormattedAttribute attributeId={measurementUnitsAttrId} />
        </span>
    );
};

const PropertyStatuses = withFields([fields.PROPERTY_STATUS_DECLARED_VALUE_UNKNOWN])(
    ({ propertyStatuses, itemAttributes, fieldDisplayNames }) => {
        const formatAttributeById = useSelector(formatAttributeByIdSelector);
        const sourceOfDrugData = formatAttributeById(
            map(
                filter(
                    itemAttributes,
                    (pa) => pa.attributeType === AttributeTypeEnum.DRUGS_DATA_SOURCE.name
                ),
                'attributeId'
            )
        );

        const { formatCurrency } = useCurrencyFormatter();

        return chain(propertyStatuses)
            .map((propertyStatus, index) => {
                const {
                    propertyStatusAttrId,
                    vehicleRecoveryTypeAttrId,
                    wasContainerOpen,
                    wasNcicCheckMadeForIdentifiableProperty,
                } = getViewModelProperties(propertyStatus);
                const quantityContent = renderQuantityContent({
                    quantity: propertyStatus.quantity,
                    measurementUnitsAttrId: propertyStatus.measurementUnitsAttrId,
                    fallback: componentStrings.reports.core.ItemCard.unknownQuantity,
                });
                const quantityTwoContent = renderQuantityContent({
                    quantity: propertyStatus.propertyStatusQuantities?.quantityTwo,
                    measurementUnitsAttrId:
                        propertyStatus.propertyStatusQuantities?.measurementUnitsTwoAttrId,
                });
                const quantityThreeContent = renderQuantityContent({
                    quantity: propertyStatus.propertyStatusQuantities?.quantityThree,
                    measurementUnitsAttrId:
                        propertyStatus.propertyStatusQuantities?.measurementUnitsThreeAttrId,
                });

                return renderDetailsContent({
                    fields: [
                        fields.PROPERTY_STATUS_PROPERTY_STATUS_ATTR_ID,
                        fields.PROPERTY_STATUS_VEHICLE_RECOVERY_TYPE_ATTR_ID,
                        fields.PROPERTY_STATUS_STATUS_DATE_UTC,
                        fields.PROPERTY_STATUS_QUANTITY, // TODO KRA-2334
                        fields.PROPERTY_STATUS_QUANTITIES_ITEM_TYPE_DRUGS_QUANTITY_TWO,
                        fields.PROPERTY_STATUS_QUANTITIES_ITEM_TYPE_DRUGS_QUANTITY_THREE,
                        fields.ITEM_ATTRIBUTE_ATTRIBUTE_TYPE_DRUGS_DATA_SOURCE_ATTRIBUTE_ID,
                        fields.PROPERTY_STATUS_WAS_CONTAINER_OPEN,
                        fields.PROPERTY_STATUS_CONTAINER_AMOUNT_REMAINING,
                        fields.PROPERTY_STATUS_WAS_NCIC_CHECK_MADE_FOR_IDENTIFIABLE_PROPERTY,
                        fields.PROPERTY_STATUS_NCIC_SEARCH_NUMBERS,
                        fields.PROPERTY_STATUS_NCIC_NUMBER,
                        fields.PROPERTY_STATUS_DECLARED_VALUE,
                        fields.PROPERTY_STATUS_FORFEITURE_VALUE,
                    ],
                    values: [
                        propertyStatusAttrId,
                        vehicleRecoveryTypeAttrId,
                        propertyStatus.statusDateUtc ? (
                            <FormattedDate
                                date={propertyStatus.statusDateUtc}
                                format={FormattedDate.FORMATS.SUMMARY_DATE}
                            />
                        ) : null,
                        quantityContent,
                        quantityTwoContent,
                        quantityThreeContent,
                        sourceOfDrugData,
                        wasContainerOpen,
                        propertyStatus.containerAmountRemaining,
                        wasNcicCheckMadeForIdentifiableProperty,
                        propertyStatus.ncicSearchNumbers,
                        propertyStatus.ncicNumber,
                        formatDeclaredValue(
                            propertyStatus,
                            fieldDisplayNames.PROPERTY_STATUS_DECLARED_VALUE_UNKNOWN,
                            formatCurrency
                        ),
                        formatCurrency(propertyStatus.forfeitureValue),
                    ],
                    index,
                });
            })
            .thru((content) => (!size(content) ? null : <Column>{content}</Column>))
            .value();
    }
);

const MarijuanaTypeInfo = ({ data }) =>
    chain(data)
        .map((propertyStatus, index) => {
            const { marijuanaTypeAttrId } = getViewModelProperties(propertyStatus);

            return renderDetailsContent({
                fields: [
                    fields.PROPERTY_STATUS_MARIJUANA_TYPE_ATTR_ID,
                    fields.PROPERTY_STATUS_MARIJUANA_QUANTITY,
                ],
                values: [marijuanaTypeAttrId, propertyStatus.marijuanaQuantity],
                index,
            });
        })
        .thru((content) => (!size(content) ? null : <Column>{content}</Column>))
        .value();

const AssociatedNamesInfo = connect(
    createStructuredSelector({
        formatNameItemAssociationAttrByNameItemLinkId: formatNameItemAssociationAttrByNameItemLinkIdSelector,
        personProfileViewModelById: personProfileViewModelByIdSelector,
        organizationProfileViewModelById: organizationProfileViewModelByIdSelector,
        applicationSettings: applicationSettingsSelector,
    })
)(function _AssociatedNamesInfo({
    formatNameItemAssociationAttrByNameItemLinkId,
    personProfileViewModelById,
    organizationProfileViewModelById,
    data,
    applicationSettings,
}) {
    const isPoleEnabled = applicationSettings['RMS_POLE_DATA_CAPTURE_ENABLED'];
    return size(data)
        ? renderDetailsContent({
              labels: [strings.associatedNames],
              values: [
                  map(data, (associatedName, index) =>
                      thru(
                          [
                              associatedName.entityType === EntityTypeEnum.PERSON_PROFILE.name
                                  ? getViewModelProperties(
                                        personProfileViewModelById(associatedName.nameId)
                                    ).fullName
                                  : organizationProfileViewModelById(associatedName.nameId).name,
                              formatNameItemAssociationAttrByNameItemLinkId(associatedName.id),
                              associatedName.dateEffectiveFrom,
                              associatedName.dateEffectiveTo,
                          ],
                          ([name, attributeName, dateEffectiveFrom, dateEffectiveTo]) => {
                              const dateEffectiveFromDisplay =
                                  dateEffectiveFrom &&
                                  isPoleEnabled &&
                                  `${strings.dateEffectiveFrom} ${dateEffectiveFrom}`;
                              const dateEffectiveToDisplay =
                                  dateEffectiveTo &&
                                  isPoleEnabled &&
                                  `${strings.dateEffectiveTo} ${dateEffectiveTo}`;

                              const associatedNameParams = joinTruthyValues([
                                  attributeName,
                                  dateEffectiveFromDisplay,
                                  dateEffectiveToDisplay,
                              ]);
                              return (
                                  name && (
                                      <div key={index}>
                                          {name}
                                          {!!associatedNameParams
                                              ? ` (${associatedNameParams})`
                                              : ''}
                                      </div>
                                  )
                              );
                          }
                      )
                  ),
              ],
          })
        : null;
});

const ImpoundInfo = ({ data }) => {
    const {
        isImpounded,
        towingCompanyAttrId,
        wasVehicleSearched,
        wasVehicleSearchConsentedTo,
    } = getViewModelProperties(data.mergedPropertyStatus);
    const { towingCompanyOther, towingLocation, towingNumber } = data.mergedPropertyStatus;

    return renderDetailsContent({
        fields: [
            fields.PROPERTY_STATUS_IS_IMPOUNDED,
            fields.PROPERTY_STATUS_TOWING_COMPANY_ATTR_ID,
            fields.PROPERTY_STATUS_TOWING_COMPANY_OTHER,
            fields.PROPERTY_STATUS_TOWING_NUMBER,
            fields.PROPERTY_STATUS_TOWING_LOCATION,
            fields.PROPERTY_STATUS_WAS_VEHICLE_SEARCHED,
            fields.PROPERTY_STATUS_WAS_VEHICLE_SEARCH_CONSENTED_TO,
        ],
        values: [
            isImpounded,
            towingCompanyAttrId,
            towingCompanyOther,
            towingNumber,
            towingLocation,
            wasVehicleSearched,
            wasVehicleSearchConsentedTo,
        ],
    });
};

const CustodyStatus = connect(
    createStructuredSelector({
        formatElasticStorageLocationById: formatElasticStorageLocationByIdSelector,
        getFieldDisplayNames: formatFieldByNameSelector,
    })
)(({ data, formatElasticStorageLocationById, getFieldDisplayNames }) => {
    const {
        storageFacility,
        storageLocation,
        policeCustodyTypeEntityType,
    } = data.mergedPropertyStatus;
    const {
        isInPoliceCustody,
        reasonForPoliceCustodyAttrId,
        infieldTransferByOfficerId: infieldTransferByOfficer,
    } = getViewModelProperties(data.mergedPropertyStatus);

    // if not policeCustodyTypeEntityType, it will display nothing as before
    const custodyType = !!policeCustodyTypeEntityType
        ? {
              field: fields.PROPERTY_STATUS_POLICE_CUSTODY_TYPE_ENTITY_TYPE,
              value: custodyTypeIsUser(policeCustodyTypeEntityType)
                  ? getFieldDisplayNames(fields.DISPLAY_ONLY_OFFICER_POSSESSION)
                  : getFieldDisplayNames(fields.DISPLAY_ONLY_DROP_OFF_LOCATION),
          }
        : {};

    // TODO write a FormattedElasticStorageLocation component
    const elasticStorageLocation = formatElasticStorageLocationById(data.storageLocationId);
    const custodyLocationOrPerson = custodyTypeIsUser(policeCustodyTypeEntityType)
        ? {
              field: fields.PROPERTY_STATUS_INFIELD_TRANSFER_BY_OFFICER_ID,
              value: get(infieldTransferByOfficer, 'fullName'),
          }
        : {
              field: fields.ITEM_FACILITY_LINK_STORAGE_LOCATION_ID,
              value: elasticStorageLocation,
          };

    return renderDetailsContent({
        fields: [
            fields.PROPERTY_STATUS_IS_IN_POLICE_CUSTODY,
            fields.PROPERTY_STATUS_REASON_FOR_POLICE_CUSTODY_ATTR_ID,
            custodyType.field,
            fields.PROPERTY_STATUS_STORAGE_FACILITY,
            fields.PROPERTY_STATUS_STORAGE_LOCATION,
            custodyLocationOrPerson.field,
            fields.ITEM_PROFILE_EXHIBIT_NUMBER,
        ],
        values: [
            isInPoliceCustody,
            reasonForPoliceCustodyAttrId,
            custodyType.value,
            // these 2 string fields are backfilled by elasticStorageLocation when it exists
            !elasticStorageLocation ? storageFacility : undefined,
            !elasticStorageLocation ? storageLocation : undefined,
            custodyLocationOrPerson.value,
            data.exhibitNumber,
        ],
    });
});

const RecoveryInfo = connect(
    createStructuredSelector({
        recoveredLocationViewModelPropertiesByItemProfileId: recoveredLocationViewModelPropertiesByItemProfileIdSelector,
    })
)(({ data, recoveredLocationViewModelPropertiesByItemProfileId }) => {
    const {
        recoveredByOtherName,
        recoveredDateUtc,
        statementOfFacts,
        intakePerson,
        notifiedDateUtc,
        notifierName,
    } = data.mergedPropertyStatus;
    const { ownerNotified, recoveredByOfficerId } = getViewModelProperties(
        data.mergedPropertyStatus
    );

    const recoveredLocation = chain(data)
        .get('id')
        .thru(recoveredLocationViewModelPropertiesByItemProfileId)
        .value();

    return renderDetailsContent({
        fields: [
            fields.PROPERTY_STATUS_RECOVERED_BY_OFFICER_ID,
            fields.PROPERTY_STATUS_RECOVERED_BY_OTHER_NAME,
            fields.PROPERTY_STATUS_RECOVERED_DATE_UTC,
            fields.LOCATION_ENTITY_LINK_LINK_TYPE_PROPERTY_RECOVERED_LOCATION_LOCATION_ID,
            fields.LOCATION_ENTITY_LINK_LINK_TYPE_PROPERTY_RECOVERED_LOCATION_DESCRIPTION,
            fields.PROPERTY_STATUS_INTAKE_PERSON,
            fields.PROPERTY_STATUS_OWNER_NOTIFIED,
            fields.PROPERTY_STATUS_NOTIFIED_DATE_UTC,
            fields.PROPERTY_STATUS_NOTIFIER_NAME,
            fields.PROPERTY_STATUS_STATEMENT_OF_FACTS,
        ],
        values: [
            get(recoveredByOfficerId, 'fullName'),
            recoveredByOtherName,
            recoveredDateUtc ? (
                <FormattedDate
                    date={recoveredDateUtc}
                    format={FormattedDate.FORMATS.SUMMARY_DATE_TIME}
                />
            ) : null,
            get(recoveredLocation, 'location.names.full'),
            get(recoveredLocation, 'locationEntityLink.description'),
            intakePerson,
            ownerNotified,
            notifiedDateUtc ? (
                <FormattedDate date={notifiedDateUtc} format={FormattedDate.FORMATS.SUMMARY_DATE} />
            ) : null,
            notifierName,
            statementOfFacts,
        ],
    });
});

const ItemLocations = connect(
    createStructuredSelector({
        sortedLocationBundlesForLocationEntityLinksWhere: sortedLocationBundlesForLocationEntityLinksWhereSelector,
    })
)(({ data, sortedLocationBundlesForLocationEntityLinksWhere }) => {
    const formatAttributeById = useSelector(formatAttributeByIdSelector);
    const locations = sortedLocationBundlesForLocationEntityLinksWhere(
        ({ entityType, entityId, linkType }) =>
            entityType === EntityTypeEnum.ITEM_PROFILE.name &&
            entityId === data.id &&
            includes(allItemLocationTypes, linkType)
    );

    return map(locations, (locationBundle, index) => {
        const locationViewProperties = getViewModelProperties(locationBundle.location);
        const locationEntityLink = locationBundle?.locationEntityLink;
        const enteredDateUtc = locationEntityLink?.enteredDateUtc;
        const itemLocationField = fieldByItemLocationType[locationEntityLink?.linkType];
        const positionAttrId = locationEntityLink?.positionAttrId;

        return renderDetailsContent({
            fields: [
                itemLocationField,
                fields.LOCATION_ENTITY_LINK_ENTERED_DATE_UTC,
                fields.LOCATION_ENTITY_LINK_ITEM_LOCATION_POSITION_ATTR_ID,
                fields.LOCATION_ENTITY_LINK_ITEM_LOCATION_DESCRIPTION,
            ],
            values: [
                locationViewProperties?.names?.full,
                enteredDateUtc ? (
                    <FormattedDate
                        date={enteredDateUtc}
                        format={FormattedDate.FORMATS.SUMMARY_DATE}
                    />
                ) : null,
                positionAttrId ? formatAttributeById(positionAttrId) : null,
                locationEntityLink?.description,
            ],
            index,
        });
    });
});

/**
 * @typedef Props
 * @prop {ItemProfile} data  A view model of an ItemProfile.
 * @prop {boolean}     [hidePropertyStatus]
 * @prop {string}      [className]
 *
 * @param {Props} props
 */
export default function ItemDetails({ data, hidePropertyStatus, className }) {
    const formatAttributeWithOther = useSelector(formatAttributeWithOtherSelector);
    const formatAttributeById = useSelector(formatAttributeByIdSelector);

    const {
        identifiers,
        isBiohazard,
        isPrecursorChemical,
        isCounterfeit,
        returnToOwnerAttrId,
    } = getViewModelProperties(data);
    const { biohazardDescription } = data;

    let fieldNames = [];
    let fieldValues = [];

    if (data.itemTypeAttrId === globalAttributes.itemType.firearm) {
        const {
            alterationIndicated,
            finishAttrId,
            gripAttrId,
            stockAttrId,
            ncicFirearmCaliberAttrId,
        } = getViewModelProperties(data);

        fieldNames = [
            fields.ITEM_PROFILE_ITEM_TYPE_FIREARM_SERIAL_NUMBER,
            fields.ITEM_PROFILE_ITEM_TYPE_FIREARM_DESCRIPTION,
            fields.ITEM_PROFILE_ITEM_TYPE_FIREARM_ITEM_MODEL,
            fields.FIREARM_REGISTRATION_NUMBER,
            fields.FIREARM_FINISH_ATTR_ID,
            fields.FIREARM_STOCK_ATTR_ID,
            fields.FIREARM_GRIP_ATTR_ID,
            fields.FIREARM_CALIBER,
            fields.FIREARM_NUMBER_OF_SHOTS,
            fields.FIREARM_BARREL_LENGTH,
            fields.FIREARM_ALTERATION_INDICATED,
            fields.FIREARM_NCIC_FIREARM_CALIBER_ATTR_ID,
        ];

        fieldValues = [
            data.serialNumber,
            data.description,
            data.itemModel,
            data.registrationNumber,
            finishAttrId,
            stockAttrId,
            gripAttrId,
            data.caliber,
            data.numberOfShots,
            data.barrelLength,
            alterationIndicated,
            ncicFirearmCaliberAttrId,
        ];
    } else if (data.itemTypeAttrId === globalAttributes.itemType.vehicle) {
        const {
            primaryColorAttrId,
            registrationStateAttrId,
            secondaryColorAttrId,
            wasAntifreezeInVehicle,
            wasRadiatorTaggedAndDrained,
        } = getViewModelProperties(data);

        const additionalSecondaryColors = formatAttributeById(
            map(
                filter(
                    data.itemAttributes,
                    (pa) => pa.attributeType === AttributeTypeEnum.ITEM_COLOR.name
                ),
                'attributeId'
            )
        );

        const dateOfInsurancePolicyExpiration = get(
            data,
            'trafficCrashVehicle.dateOfInsurancePolicyExpiration'
        );

        fieldNames = [
            fields.VEHICLE_VIN_NUMBER,
            fields.VEHICLE_TAG,
            fields.ITEM_PROFILE_ITEM_TYPE_VEHICLE_DESCRIPTION,
            fields.VEHICLE_YEAR_OF_MANUFACTURE,
            fields.ITEM_PROFILE_ITEM_TYPE_VEHICLE_PRIMARY_COLOR_ATTR_ID,
            fields.ITEM_PROFILE_ITEM_TYPE_VEHICLE_SECONDARY_COLOR_ATTR_ID,
            fields.ITEM_PROFILE_ITEM_TYPE_VEHICLE_ADDITIONAL_SECONDARY_COLORS_ATTR_ID_LINK,
            fields.VEHICLE_BODY_STYLE_ATTR_ID,
            fields.VEHICLE_REGISTRATION_YEAR,
            fields.VEHICLE_REGISTRATION_STATE_ATTR_ID,
            fields.VEHICLE_REGISTRATION_TYPE,
            fields.VEHICLE_MILEAGE,
            fields.VEHICLE_INSURANCE_PROVIDER_NAME,
            fields.VEHICLE_INSURANCE_POLICY_NUMBER,
            fields.TRAFFIC_CRASH_VEHICLE_DATE_OF_INSURANCE_POLICY_EXPIRATION,
            fields.VEHICLE_WAS_ANTIFREEZE_IN_VEHICLE,
            fields.VEHICLE_WAS_RADIATOR_TAGGED_AND_DRAINED,
        ];

        fieldValues = [
            data.vinNumber,
            data.tag,
            data.description,
            data.yearOfManufacture,
            primaryColorAttrId,
            secondaryColorAttrId,
            additionalSecondaryColors,
            formatAttributeWithOther({
                attributeId: data.bodyStyleAttrId,
                joinWith: ' - ',
                other: data.bodyStyleOther,
            }),
            data.registrationYear,
            registrationStateAttrId,
            data.registrationType,
            data.mileage,
            data.insuranceProviderName,
            data.insurancePolicyNumber,
            dateOfInsurancePolicyExpiration,
            wasAntifreezeInVehicle,
            wasRadiatorTaggedAndDrained,
        ];
    } else if (data.itemTypeAttrId === globalAttributes.itemType.item) {
        const { primaryColorAttrId, secondaryColorAttrId } = getViewModelProperties(data);

        fieldNames = [
            fields.ITEM_PROFILE_ITEM_TYPE_ITEM_PRIMARY_COLOR_ATTR_ID,
            fields.ITEM_PROFILE_ITEM_TYPE_ITEM_SECONDARY_COLOR_ATTR_ID,
            fields.ITEM_PROFILE_SIZE,
            fields.ITEM_PROFILE_ITEM_TYPE_ITEM_SERIAL_NUMBER,
            fields.ITEM_PROFILE_ITEM_TYPE_ITEM_ITEM_MAKE,
            fields.ITEM_PROFILE_ITEM_TYPE_ITEM_ITEM_MODEL,
        ];

        fieldValues = [
            primaryColorAttrId,
            secondaryColorAttrId,
            data.size,
            data.serialNumber,
            data.itemMake,
            data.itemModel,
        ];
    } else if (data.itemTypeAttrId === globalAttributes.itemType.drugs) {
        fieldNames = [
            fields.ITEM_PROFILE_IS_PRECURSOR_CHEMICAL,
            fields.ITEM_PROFILE_IS_COUNTERFEIT,
        ];

        fieldValues = [isPrecursorChemical, isCounterfeit];
    }

    fieldNames.push(fields.ITEM_PROFILE_RETURN_TO_OWNER_ATTR_ID);
    fieldValues.push(returnToOwnerAttrId);
    fieldNames.push(fields.ITEM_PROFILE_IS_BIOHAZARD);
    fieldValues.push(isBiohazard);
    fieldNames.push(fields.ITEM_PROFILE_BIOHAZARD_DESCRIPTION);
    fieldValues.push(biohazardDescription);

    const trafficCrashVehicle = data.trafficCrashVehicle;
    const attachmentViewModels = data.attachmentViewModels;

    return (
        <Column style={{ marginTop: '15px' }} className={className}>
            {renderDetailsContent({
                fields: fieldNames,
                values: fieldValues,
                showDivider: false,
            })}
            <Identifiers data={identifiers} />
            {!hidePropertyStatus && (
                <PropertyStatuses
                    propertyStatuses={data.propertyStatus}
                    itemAttributes={data.itemAttributes}
                />
            )}
            {!hidePropertyStatus && <MarijuanaTypeInfo data={data.propertyStatus} />}
            <AssociatedNamesInfo data={data.nameItemLinks} />
            {data.itemTypeAttrId === globalAttributes.itemType.vehicle && (
                <ImpoundInfo data={data} />
            )}
            <CustodyStatus data={data} />
            <RecoveryInfo data={data} />
            {size(data.legacyEntityDetails) > 0 && (
                <div>
                    <SummarySectionDivider />
                    <SummaryList labelWidth={100} contentWidth={380}>
                        <LegacyEntityDetailSummaryRow
                            legacyEntityDetails={data.legacyEntityDetails}
                        />
                    </SummaryList>
                </div>
            )}
            {!isEmpty(attachmentViewModels) && (
                <>
                    <SummarySectionDivider />
                    <SummaryList>
                        <SummaryRow label={strings.attachments}>
                            <FileGrid fileLinks={attachmentViewModels} numOfColumns={1} />
                        </SummaryRow>
                    </SummaryList>
                </>
            )}
            {!!trafficCrashVehicle && (
                <>
                    <SummarySectionDivider />
                    <TrafficCrashVehicleSummary viewModel={trafficCrashVehicle} />
                </>
            )}
            <FeatureFlagged flag={'RMS_ANPR_IMPROVEMENTS_ENABLED'}>
                <ItemLocations data={data} />
            </FeatureFlagged>
        </Column>
    );
}
