import { Reducer } from 'redux';
import { createEntityReducer } from 'nexus-mark43';
import { groupByKeyStrategy, normalizedModuleCompatStrategy } from 'probes-mark43';
import { DataNexusState } from '~/client-common/redux/state';

// reducers configs
import arrestReducerConfig from '~/client-common/core/domain/arrests/state/data';
import rolesReducerConfig from '~/client-common/core/domain/roles/state/data';
import agencyProfilesReducerConfig from '~/client-common/core/domain/agency-profiles/state/data';
import fieldConfigurationsReducerConfig from '~/client-common/core/domain/field-configurations/state/data';
import fieldDetailsReducerConfig from '~/client-common/core/domain/field-details/state/data';
import offenseCodesReducerConfig from '~/client-common/core/domain/offense-codes/state/data';
import dojSchoolsReducerConfig from '~/client-common/core/domain/doj-schools/state/data';
import atfManufacturersReducerConfig from '~/client-common/core/domain/etrace-atf/state/data';
import detentions from '~/client-common/core/domain/detentions/state/data';
import coreModelConfigurations from '~/client-common/core/domain/core-model-configurations/state/data';
import ripaOffenseCodesReducerConfig from '~/client-common/core/domain/ripa-offense-codes/state/data';
import ripaSubjectOffenseCodesReducerConfig from '~/client-common/core/domain/ripa-subject-offense-codes/state/data';
import locationsReducerConfig from '~/client-common/core/domain/locations/state/data';
import pointLocationsReducerConfig from '~/client-common/core/domain/point-locations/state/data';
import locationCautionsReducerConfig from '~/client-common/core/domain/location-cautions/state/data';
import reportsReducerConfig from '~/client-common/core/domain/reports/state/data';
import personProfilesReducerConfig from '~/client-common/core/domain/person-profiles/state/data';
import personInjuriesReducerConfig from '~/client-common/core/domain/person-injuries/state/data';
import organizationProfilesReducerConfig from '~/client-common/core/domain/organization-profiles/state/data';
import attributesReducerConfig from '~/client-common/core/domain/attributes/state/data';
import attributeCodesReducerConfig from '~/client-common/core/domain/attribute-codes/state/data';
import firearmsReducerConfig from '~/client-common/core/domain/firearms/state/data';
import vehiclesReducerConfig from '~/client-common/core/domain/vehicles/state/data';
import itemProfilesReducerConfig from '~/client-common/core/domain/item-profiles/state/data';
import propertyStatusesReducerConfig from '~/client-common/core/domain/property-statuses/state/data';
import itemReportingEventLinksReducerConfig from '~/client-common/core/domain/item-reporting-event-links/state/data';
import itemReportLinksReducerConfig from '~/client-common/core/domain/item-report-links/state/data';
import elasticPersonsReducerConfig from '~/client-common/core/domain/elastic-persons/state/data';
import elasticInvolvedLocationsReducerConfig from '~/client-common/core/domain/elastic-involved-locations/state/data';
import elasticReportsReducerConfig from '~/client-common/core/domain/elastic-reports/state/data';
import profileReportTypesReducerConfig from '~/client-common/core/domain/profile-report-types/state/data';
import elasticOrganizationsReducerConfig from '~/client-common/core/domain/elastic-organizations/state/data';
import elasticPropertyReducerConfig from '~/client-common/core/domain/elastic-property/state/data';
import elasticVehiclesReducerConfig from '~/client-common/core/domain/elastic-vehicles/state/data';
import elasticCourtOrdersReducerConfig from '~/client-common/core/domain/elastic-court-orders/state/data';
import exportedAttachmentsReducerConfig from '~/client-common/core/domain/exported-attachments/state/data';
import exportReleasesReducerConfig from '~/client-common/core/domain/export-releases/state/data';
import exportReleaseTypesReducerConfig from '~/client-common/core/domain/export-release-types/state/data';
import exportReleaseEntitiesReducerConfig from '~/client-common/core/domain/export-release-entities/state/data';
import emailExportReleasesReducerConfig from '~/client-common/core/domain/email-export-releases/state/data';
import emailExportReleaseEntitiesReducerConfig from '~/client-common/core/domain/email-export-release-entities/state/data';
import userSearchResultsReducerConfig from '~/client-common/core/domain/user-search-results/state/data';
import imagesReducerConfig from '~/client-common/core/domain/images/state/data';
import attachmentsReducerConfig from '~/client-common/core/domain/attachments/state/data';
import filesReducerConfig from '~/client-common/core/domain/files/state/data';
import miniUsersReducerConfig from '~/client-common/core/domain/mini-users/state/data';
import labelPrintersReducerConfig from '~/client-common/core/domain/label-printers/state/data';
import chainEventTypesReducerConfig from '~/client-common/core/domain/chain-event-types/state/data';
import chainEventsReducerConfig from '~/client-common/core/domain/chain-events/state/data';
import chainOfCustodiesReducerConfig from '~/client-common/core/domain/chain-of-custodies/state/data';
import retentionPoliciesReducerConfig from '~/client-common/core/domain/retention-policies/state/data';
import dispositionEventsReducerConfig from '~/client-common/core/domain/disposition-events/state/data';
import staffRemarksReducerConfig from '~/client-common/core/domain/staff-remarks/state/data';
import facilitiesReducerConfig from '~/client-common/core/domain/facilities/state/data';
import storageLocationsReducerConfig from '~/client-common/core/domain/storage-locations/state/data';
import elasticStorageLocationsReducerConfig from '~/client-common/core/domain/elastic-storage-locations/state/data';
import evidenceReportsReducerConfig from '~/client-common/core/domain/evidence-reports/state/data';
import userAttributesReducerConfig from '~/client-common/core/domain/user-attributes/state/data';
import codesReducerConfig from '~/client-common/core/domain/codes/state/data';
import offensesReducerConfig from '~/client-common/core/domain/offenses/state/data';
import userProfilesReducerConfig from '~/client-common/core/domain/user-profiles/state/data';
import departmentProfilesReducerConfig from '~/client-common/core/domain/department-profiles/state/data';
import courtCasesReducerConfig from '~/client-common/core/domain/court-cases/state/data';
import printingTemplatesReducerConfig from '~/client-common/core/domain/printing-templates/state/data';
import reportPrintingTemplatesReducerConfig from '~/client-common/core/domain/report-printing-templates/state/data';
import casePrintingTemplatesReducerConfig from '~/client-common/core/domain/case-printing-templates/state/data';
import userOptOutsReducerConfig from '~/client-common/core/domain/user-opt-outs/state/data';
import eventDetailsReducerConfig from '~/client-common/core/domain/event-details/state/data';
import custodialResponsibleOfficersReducerConfig from '~/client-common/core/domain/custodial-responsible-officers/state/data';
import chargesReducerConfig from '~/client-common/core/domain/charges/state/data';
import reportCaseStatusesReducerConfig from '~/client-common/core/domain/report-case-statuses/state/data';
import reportShortTitlesReducerConfig from '~/client-common/core/domain/report-short-titles/state/data';
import reportStatusHistoriesReducerConfig from '~/client-common/core/domain/report-status-histories/state/data';
import historyEventsReducerConfig from '~/client-common/core/domain/history-events/state/data';
import itemAttributeReducerConfig from '~/client-common/core/domain/item-attributes/state/data';
import itemEvidenceStatesReducerConfig from '~/client-common/core/domain/item-evidence-states/state/data';
import applicationSettingsReducerConfig from '~/client-common/core/domain/application-settings/state/data';
import citationsReducerConfig from '~/client-common/core/domain/citations/state/data';
import countriesReducerConfig from '~/client-common/core/domain/countries/state/data';
import reportDefinitionsReducerConfig from '~/client-common/core/domain/report-definitions/state/data';
import reportDefinitionTitleFormatsReducerConfig from '~/client-common/core/domain/report-definition-title-formats/state/data';
import reportExternalLinksReducerConfig from '~/client-common/core/domain/report-external-links/state/data';
import reportReportLinksReducerConfig from '~/client-common/core/domain/report-report-links/state/data';
import evidencePrintingTemplatesReducerConfig from '~/client-common/core/domain/evidence-printing-templates/state/data';
import subdivisionsReducerConfig from '~/client-common/core/domain/subdivisions/state/data';
import shapefilesReducerConfig from '~/client-common/core/domain/shapefiles/state/data';
import attachmentFolderPrintablesReducerConfig from '~/client-common/core/domain/attachment-folder-printables/state/data';
import evidencePrintablesReducerConfig from '~/client-common/core/domain/evidence-printables/state/data';
import nibrsOffenseCodesReducerConfig from '~/client-common/core/domain/nibrs-offense-codes/state/data';
import ucrSummaryOffenseCodesReducerConfig from '~/client-common/core/domain/ucr-summary-offense-codes/state/data';
import alertSchedulesReducerConfig from '~/client-common/core/domain/alert-schedules/state/data';
import entityPermissionsReducerConfig from '~/client-common/core/domain/entity-permissions/state/data';
import reportSubmissionAuthorsReducerConfig from '~/client-common/core/domain/report-submission-authors/state/data';
import externalLinksReducerConfig from '~/client-common/core/domain/external-links/state/data';
import reportCommentsReducerConfig from '~/client-common/core/domain/report-comments/state/data';
import reportInlineCommentsReducerConfig from '~/client-common/core/domain/inline-report-comments/state/data';
import elasticAttributeDetailsReducerConfig from '~/client-common/core/domain/elastic-attribute-details/state/data';
import narrativeGuidesReducerConfig from '~/client-common/core/domain/narrative-guides/state/data';
import summaryNarrativesReducerConfig from '~/client-common/core/domain/summary-narratives/state/data';
import exportPresetsReducerConfig from '~/client-common/core/domain/export-presets/state/data';
import hiddenDepartmentLinkTypesReducerConfig from '~/client-common/core/domain/hidden-department-link-types/state/data';
import primarySubdivisionsReducerConfig from '~/client-common/core/domain/primary-subdivisions/state/data';
import trafficCrashesReducerConfig from '~/client-common/core/domain/traffic-crashes/state/data';
import trafficCrashRoadwayReducerConfig from '~/client-common/core/domain/traffic-crash-roadways/state/data';
import trafficCrashRoadwayEntityLinksReducerConfig from '~/client-common/core/domain/traffic-crash-roadway-entity-links/state/data';
import trafficCrashAttributesReducerConfig from '~/client-common/core/domain/traffic-crash-attributes/state/data';
import trafficCrashPersonsReducerConfig from '~/client-common/core/domain/traffic-crash-persons/state/data';
import trafficCrashPersonOffensesReducerConfig from '~/client-common/core/domain/traffic-crash-person-offenses/state/data';
import trafficCrashVehiclesReducerConfig from '~/client-common/core/domain/traffic-crash-vehicles/state/data';
import trafficCrashEntityDetailsReducerConfig from '~/client-common/core/domain/traffic-crash-entity-details/state/data';
import reportingEventsReducerConfig from '~/client-common/core/domain/reporting-events/state/data';
import entityOrderedAttributesReducer from '~/client-common/core/domain/entity-ordered-attributes/state/data';
import trafficCrashCustomFieldsReducerConfig from '~/client-common/core/domain/traffic-crash-custom-fields/state/data';
import trafficCrashCustomAttributesReducerConfig from '~/client-common/core/domain/traffic-crash-custom-attributes/state/data';
import eventOtherInvolvedAgenciesReducerConfig from '~/client-common/core/domain/other-involved-agencies/state/data';
import evidenceLocationPermissionsReducerConfig from '~/client-common/core/domain/evidence-location-permissions/state/data';
import offenseCodeNibrsCodeLinksReducerConfig from '~/client-common/core/domain/offense-code-nibrs-code-links/state/data';
import offenseCodeHocCategoryLinksReducerConfig from '~/client-common/core/domain/offense-code-hoc-category-links/state/data';
import { ukOffenseCodeExtensionsReducerConfig } from '~/client-common/core/domain/uk-offense-code-extensions/state/data';

// cases
import casesReducerConfig from '~/client-common/core/domain/cases/state/data';
import caseTitlesReducerConfig from '~/client-common/core/domain/case-titles/state/data';
import caseDefinitionsReducerConfig from '~/client-common/core/domain/case-definitions/state/data';
import cadUnitsReducerConfig from '~/client-common/core/domain/cad-units/state/data';

import cadDesktopConfigsReducerConfig from '~/client-common/core/domain/cad-desktop-configs/state/data';
import cadMessagingGroupsReducerConfig from '~/client-common/core/domain/cad-messaging-groups/state/data';
import cadServiceTypesReducerConfig from '~/client-common/core/domain/cad-service-rotation-types/state/data';
import cadRotationListsReducerConfig from '~/client-common/core/domain/cad-service-rotation-lists/state/data';
import cadServiceProvidersReducerConfig from '~/client-common/core/domain/cad-service-rotation-providers/state/data';
import cadCallTakerStationsReducerConfig from '~/client-common/core/domain/cad-call-taker-stations/state/data';
import fillablePdfTemplatesReducerConfig from '~/client-common/core/domain/fillable-pdf-templates/state/data';
import departmentPreferencesReducerConfig from '~/client-common/core/domain/department-preferences/state/data';
import commandLineReducerConfig from '~/client-common/core/domain/command-line/state/data';
import dispatchAreasReducerConfig from '~/client-common/core/domain/dispatch-areas/state/data';
import stationsReducerConfig from '~/client-common/core/domain/stations/state/data';
import duplicateEventSearchConfigsReducerConfig from '~/client-common/core/domain/duplicate-event-search-configs/state/data';
import shortcutKeysReducerConfig from '~/client-common/core/domain/shortcut-keys/state/data';
import callForServicesReducerConfig from '~/client-common/core/domain/call-for-services/state/data';
import preferenceLinksReducerConfig from '~/client-common/core/domain/preference-links/state/data';
import caseNotesReducerConfig from '~/client-common/core/domain/case-notes/state/data';
import caseStatusesReducerConfig from '~/client-common/core/domain/case-statuses/state/data';
import caseAttributesReducerConfig from '~/client-common/core/domain/case-attributes/state/data';
import caseApprovalStatusesReducerConfig from '~/client-common/core/domain/case-approval-statuses/state/data';
import caseReviewsReducerConfig from '~/client-common/core/domain/case-reviews/state/data';
import caseDefaultTasksReducerConfig from '~/client-common/core/domain/case-default-tasks/state/data';
import defaultFoldersReducerConfig from '~/client-common/core/domain/default-folders/state/data';
import foldersReducerConfig from '~/client-common/core/domain/folders/state/data';
import folderContentViewsReducerConfig from '~/client-common/core/domain/folder-content-views/state/data';

import reportNotificationsReducerConfig from '~/client-common/core/domain/report-notifications/state/data';
import societyProfilesReducerConfig from '~/client-common/core/domain/society-profiles/state/data';

import tasksReducerConfig from '~/client-common/core/domain/tasks/state/data';
import abilitiesReducerConfig from '~/client-common/core/domain/abilities/state/data';
import consortiumProfilesReducerConfig from '~/client-common/core/domain/consortium-profiles/state/data';
import modulesReducerConfig from '~/client-common/core/domain/modules/state/data';
import roleApprovalsReducerConfig from '~/client-common/core/domain/role-approvals/state/data';
import userAssignmentsReducerConfig from '~/client-common/core/domain/user-assignments/state/data';
import towVehiclesReducerConfig from '~/client-common/core/domain/tow-vehicles/state/data';
import towVehicleReleasesReducerConfig from '~/client-common/core/domain/tow-vehicle-releases/state/data';
import towVehicleCheckInsReducerConfig from '~/client-common/core/domain/tow-vehicle-check-ins/state/data';
import impoundsReducerConfig from '~/client-common/core/domain/impounds/state/data';
import cadTicketsReducerConfig from '~/client-common/core/domain/cad-tickets/state/data';
import cadTicketsUnitsAndMembersReducerConfig from '~/client-common/core/domain/cad-tickets-units-and-members/state/data';
import streetsReducerConfig from '~/client-common/core/domain/streets/state/data';
import streetSegmentsReducerConfig from '~/client-common/core/domain/street-segments/state/data';
import streetAliasesReducerConfig from '~/client-common/core/domain/street-aliases/state/data';
import rulesReducerConfig from '~/client-common/core/domain/rules/state/data';
import ruleConditionsReducerConfig from '~/client-common/core/domain/rule-conditions/state/data';
import ruleConditionArgsReducerConfig from '~/client-common/core/domain/rule-condition-args/state/data';
import cadUnitStatusesReducerConfig from '~/client-common/core/domain/cad-unit-statuses/state/data';
import cadUnitStatusTransitionsReducerConfig from '~/client-common/core/domain/cad-unit-status-transitions/state/data';
import fieldContactsReducerConfig from '~/client-common/core/domain/field-contacts/state/data';
import reportLegacyMetadatasReducerConfig from '~/client-common/core/domain/report-legacy-metadatas/state/data';
import stopsReducerConfig from '~/client-common/core/domain/stops/state/data';
import missingPersonsReducerConfig from '~/client-common/core/domain/missing-persons/state/data';
import useOfForcesReducerConfig from '~/client-common/core/domain/use-of-forces/state/data';
import useOfForceSubjectsReducerConfig from '~/client-common/core/domain/use-of-force-subjects/state/data';
import useOfForceSubjectDeEscalationsReducerConfig from '~/client-common/core/domain/use-of-force-subject-de-escalations/state/data';
import behavioralCrisesReducerConfig from '~/client-common/core/domain/behavioral-crises/state/data';
import personEmergencyContactsReducerConfig from '~/client-common/core/domain/person-emergency-contacts/state/data';
import passportsReducerConfig from '~/client-common/core/domain/passports/state/data';
import personGangTrackingsReducerConfig from '~/client-common/core/domain/person-gang-trackings/state/data';
import personProbationReducerConfig from '~/client-common/core/domain/person-probations/state/data';

// vehicles
import vehicleMakesReducerConfig from '~/client-common/core/domain/vehicle-makes/state/data';
import vehicleModelsReducerConfig from '~/client-common/core/domain/vehicle-models/state/data';

// warrants
import warrantsReducerConfig from '~/client-common/core/domain/warrants/state/data';
import warrantStatusesReducerConfig from '~/client-common/core/domain/warrant-statuses/state/data';
import warrantTitlesReducerConfig from '~/client-common/core/domain/warrant-titles/state/data';
import warrantActivitiesReducerConfig from '~/client-common/core/domain/warrant-activities/state/data';
import warrantChargesReducerConfig from '~/client-common/core/domain/warrant-charges/state/data';
import warrantDexSubmissionReducerConfig from '~/client-common/core/domain/warrant-dex-submissions/state/data';
import warrantDexSubmissionHistoriesReducerConfig from '~/client-common/core/domain/warrant-dex-submission-histories/state/data';
import warrantDexReturnsReducerConfig from '~/client-common/core/domain/warrant-dex-returns/state/data';
import warrantActivityStatusLinksReducerConfig from '~/client-common/core/domain/warrant-activity-status-links/state/data';
import elasticWarrantsReducerConfig from '~/client-common/core/domain/elastic-warrants/state/data';

// analysis
import lookerDashboardsReducerConfig from '~/client-common/core/domain/looker-dashboards/state/data';

// notifications
import userNotificationsReducerConfig from '~/client-common/core/domain/user-notifications/state/data';

// support
import idFormatConfigurationReducerConfig from '~/client-common/core/domain/id-format-configurations/state/data';
import idGeneratorReducerConfig from '~/client-common/core/domain/id-generator/state/data';
import integrationReducerConfig from '~/client-common/core/domain/integrations/state/data';
import commandRecordReducerConfig from '~/client-common/core/domain/command-records/state/data';
import integrationJobRunReducerConfig from '~/client-common/core/domain/integration-job-runs/state/data';
import scheduleReducerConfig from '~/client-common/core/domain/schedules/state/data';
import commandStatusHistoryReducerConfig from '~/client-common/core/domain/command-status-histories/state/data';

// mobile
import fieldNotesReducerConfig from '~/client-common/core/domain/field-notes/state/data';
import fieldNoteEntityLinksReducerConfig from '~/client-common/core/domain/field-note-entity-links/state/data';

import fieldConfigurationContextsReducerConfig from '~/client-common/core/domain/field-configuration-contexts/state/data';
import fieldDetailContextsReducerConfig from '~/client-common/core/domain/field-detail-contexts/state/data';
import nameItemLinksReducerConfig from '~/client-common/core/domain/name-item-links/state/data';
import nameNameLinksReducerConfig from '~/client-common/core/domain/name-name-links/state/data';
import locationEntityLinksReducerConfig from '~/client-common/core/domain/location-entity-links/state/data';
import locationExternalLinksReducerConfig from '~/client-common/core/domain/location-external-links/state/data';
import nameReportLinksReducerConfig from '~/client-common/core/domain/name-report-links/state/data';
import nameCaseLinksReducerConfig from '~/client-common/core/domain/name-case-links/state/data';
import itemCaseLinksReducerConfig from '~/client-common/core/domain/item-case-links/state/data';
import stopAnonymousSubjectsReducerConfig from '~/client-common/core/domain/stop-anonymous-subjects/state/data';
import stopEntityAttributesReducerConfig from '~/client-common/core/domain/stop-entity-attributes/state/data';
import assistingOfficersReducerConfig from '~/client-common/core/domain/assisting-officers/state/data';
import currentViewersReducerConfig from '~/client-common/core/domain/current-viewers/state/data';
import itemFacilityLinksReducerConfig from '~/client-common/core/domain/item-facility-links/state/data';
import legacyEntityDetailsReducerConfig from '~/client-common/core/domain/legacy-entity-details/state/data';
import citationChargesReducerConfig from '~/client-common/core/domain/citation-charges/state/data';
import futureEntityPermissionsReducerConfig from '~/client-common/core/domain/future-entity-permissions/state/data';
import caseRoleLinksReducerConfig from '~/client-common/core/domain/case-role-links/state/data';
import caseDefaultRoleLinksReducerConfig from '~/client-common/core/domain/case-default-role-links/state/data';
import userRolesReducerConfig from '~/client-common/core/domain/user-roles/state/data';
import abilityRoleLinksReducerConfig from '~/client-common/core/domain/ability-role-links/state/data';
import consortiumDepartmentLinksReducerConfig from '~/client-common/core/domain/consortium-link-view/state/data';
import reportDefaultRoleLinksReducerConfig from '~/client-common/core/domain/report-default-role-links/state/data';
import arrestAttributesReducerConfig from '~/client-common/core/domain/arrest-attributes/state/data';
import offenseAttributesReducerConfig from '~/client-common/core/domain/offense-attributes/state/data';
import offenseInvolvedChildrenReducerConfig from '~/client-common/core/domain/offense-involved-children/state/data';
import offenseSubCrimeLinksReducerConfig from '~/client-common/core/domain/offense-sub-crime-links/state/data';
import reportAttributesReducerConfig from '~/client-common/core/domain/report-attributes/state/data';
import attributeTypeCodesTypeLinksReducerConfig from '~/client-common/core/domain/attribute-type-codes-type-links/state/data';
import ruleActionedFieldsReducerConfig from '~/client-common/core/domain/rule-actioned-fields/state/data';
import ruleConfigurationContextsReducerConfig from '~/client-common/core/domain/rule-configuration-contexts/state/data';
import vehicleModelCodeLinksReducerConfig from '~/client-common/core/domain/vehicle-model-code-links/state/data';
import vehicleModelYearsReducerConfig from '~/client-common/core/domain/vehicle-model-years/state/data';
import warrantAttributesReducerConfig from '~/client-common/core/domain/warrant-attributes/state/data';
import templateFileLinksReducerConfig from '~/client-common/core/domain/template-file-links/state/data';

import itemIdentifiersReducerConfig from '~/client-common/core/domain/item-identifiers/state/data';
import nameAttributesReducerConfig from '~/client-common/core/domain/name-attributes/state/data';
import nameMonikersReducerConfig from '~/client-common/core/domain/name-monikers/state/data';
import nameIdentifiersReducerConfig from '~/client-common/core/domain/name-identifiers/state/data';
import nameEmailsReducerConfig from '~/client-common/core/domain/name-emails/state/data';
import courOrdersReducerConfig from '~/client-common/core/domain/court-orders/state/data';
import namePhonesReducerConfig from '~/client-common/core/domain/name-phones/state/data';
import identifyingMarksReducerConfig from '~/client-common/core/domain/identifying-marks/state/data';
import employmentHistoriesReducerConfig from '~/client-common/core/domain/employment-histories/state/data';
import schoolHistoriesReducerConfig from '~/client-common/core/domain/school-histories/state/data';
import paramedicsReducerConfig from '~/client-common/core/domain/paramedics/state/data';
import reportUcrsReducerConfig from '~/client-common/core/domain/report-ucrs/state/data';
import ucrEventsReducerConfig from '~/client-common/core/domain/ucr-events/state/data';
import ucrPropertyReducerConfig from '~/client-common/core/domain/ucr-property/state/data';
import ucrOffensesReducerConfig from '~/client-common/core/domain/ucr-offenses/state/data';
import inventoriesReducerConfig from '~/client-common/core/domain/inventories/state/data';
import inventoryItemsReducerConfig from '~/client-common/core/domain/inventory-items/state/data';
import narrativeAutosaveReducerConfig from '~/client-common/core/domain/narrative-autosave/state/data';
import csvExportColumnsReducerConfig from '~/client-common/core/domain/csv-export-columns/state/data';
import dexDevicesReducerConfig from '~/client-common/core/domain/dex-devices/state/data';
import taskViewsReducerConfig from '~/client-common/core/domain/taskViews/state/data';
import taskCommentsReducerConfig from '~/client-common/core/domain/task-comments/state/data';
import taskEntityLinksReducerConfig from '~/client-common/core/domain/task-entity-links/state/data';
import externalReportStatusesReducerConfig from '~/client-common/core/domain/external-report-statuses/state/data';
import offenseCaseStatusesReducerConfig from '~/client-common/core/domain/offense-case-statuses/state/data';
import courtsReducerConfig from '~/client-common/core/domain/courts/state/data';
import briefingsReducerConfig from '~/client-common/core/domain/briefings/state/data';
import gpsRetentionReducerConfig from '~/client-common/core/domain/cad-gps-retention/state/data';
import cautionsReducerConfig from '~/client-common/core/domain/cautions/state/data';
import dragonProtectedEntityOptionDisplayViewModule from '~/client-common/core/domain/dragon-rms-integration/state/data/protected-option-display-views';
import dragonSearchableConfiguredPropertyOptionViewModule from '~/client-common/core/domain/dragon-rms-integration/state/data/searchable-configured-property-option-views';

import reportModulesReducerConfig from '../../modules/reports/core/state/data/reportModules';
import complianceResubmissionReducerConfig from '../../modules/reports/core/state/data/complianceResubmission';
import mentionsConfigurationReducerConfig from '../../modules/admin/mentions-configuration/state/data';

import { reportDefinitionDataReducerNexusWrapper } from './reportDefinitionDataReducerNexusWrapper';

const normalizedMerge = normalizedModuleCompatStrategy({
    key: 'id',
    metaKey: 'meta',
    tagsKey: 'tags',
});

interface NexusEntityObject {
    items: unknown[];
    config?: { remove: Record<string, unknown> };
}

const nexus = createEntityReducer({
    baseGroupStrategy: groupByKeyStrategy(() => 'global'),
    baseIndexStrategy: normalizedMerge.indexStrategy,
    baseMergeStrategy: normalizedMerge,
    baseEntityItemsGetterStrategy: (obj: NexusEntityObject) => obj.items,
    baseEntityConfigGetterStrategy: (obj: NexusEntityObject) => obj.config,
    entities: {
        departments: {},
        // group modules
        ...itemIdentifiersReducerConfig,
        ...nameMonikersReducerConfig,
        ...nameIdentifiersReducerConfig,
        ...nameEmailsReducerConfig,
        ...courOrdersReducerConfig,
        ...namePhonesReducerConfig,
        ...identifyingMarksReducerConfig,
        ...employmentHistoriesReducerConfig,
        ...narrativeAutosaveReducerConfig,
        ...schoolHistoriesReducerConfig,
        ...paramedicsReducerConfig,
        ...reportUcrsReducerConfig,
        ...ucrEventsReducerConfig,
        ...ucrOffensesReducerConfig,
        ...ucrPropertyReducerConfig,
        // link modules
        ...fieldConfigurationContextsReducerConfig,
        ...fieldDetailContextsReducerConfig,
        ...nameItemLinksReducerConfig,
        ...nameNameLinksReducerConfig,
        ...locationEntityLinksReducerConfig,
        ...locationExternalLinksReducerConfig,
        ...nameReportLinksReducerConfig,
        ...nameCaseLinksReducerConfig,
        ...itemCaseLinksReducerConfig,
        ...assistingOfficersReducerConfig,
        ...currentViewersReducerConfig,
        ...itemFacilityLinksReducerConfig,
        ...legacyEntityDetailsReducerConfig,
        ...citationChargesReducerConfig,
        ...futureEntityPermissionsReducerConfig,
        ...caseRoleLinksReducerConfig,
        ...caseDefaultRoleLinksReducerConfig,
        ...userRolesReducerConfig,
        ...abilityRoleLinksReducerConfig,
        ...consortiumDepartmentLinksReducerConfig,
        ...reportDefaultRoleLinksReducerConfig,
        ...arrestAttributesReducerConfig,
        ...nameAttributesReducerConfig,
        ...offenseAttributesReducerConfig,
        ...offenseInvolvedChildrenReducerConfig,
        ...reportAttributesReducerConfig,
        ...attributeTypeCodesTypeLinksReducerConfig,
        ...ruleActionedFieldsReducerConfig,
        ...ruleConfigurationContextsReducerConfig,
        ...vehicleModelCodeLinksReducerConfig,
        ...vehicleModelYearsReducerConfig,
        ...warrantAttributesReducerConfig,
        // NOTE: there is a server AttributeCode model that links an attributeId to codeId
        // but also has other data we don't need to support de-normalization in our tables.
        // Our attributeCodes in the FE only contain the data we need: attributeId and codeId
        ...attributeCodesReducerConfig,
        // normalized modules
        ...arrestReducerConfig,
        ...reportDefinitionsReducerConfig,
        ...reportDefinitionTitleFormatsReducerConfig,
        ...reportExternalLinksReducerConfig,
        ...reportReportLinksReducerConfig,
        ...rolesReducerConfig,
        ...agencyProfilesReducerConfig,
        ...fieldConfigurationsReducerConfig,
        ...fieldDetailsReducerConfig,
        ...offenseCodesReducerConfig,
        ...dojSchoolsReducerConfig,
        ...ripaOffenseCodesReducerConfig,
        ...ripaSubjectOffenseCodesReducerConfig,
        ...locationsReducerConfig,
        ...pointLocationsReducerConfig,
        ...locationCautionsReducerConfig,
        ...reportsReducerConfig,
        ...reportModulesReducerConfig,
        ...personProfilesReducerConfig,
        ...personInjuriesReducerConfig,
        ...organizationProfilesReducerConfig,
        ...attributesReducerConfig,
        ...firearmsReducerConfig,
        ...vehiclesReducerConfig,
        ...itemProfilesReducerConfig,
        ...propertyStatusesReducerConfig,
        ...itemReportingEventLinksReducerConfig,
        ...itemReportLinksReducerConfig,
        ...elasticPersonsReducerConfig,
        ...elasticInvolvedLocationsReducerConfig,
        ...elasticReportsReducerConfig,
        ...elasticOrganizationsReducerConfig,
        ...elasticPropertyReducerConfig,
        ...elasticVehiclesReducerConfig,
        ...elasticCourtOrdersReducerConfig,
        ...exportedAttachmentsReducerConfig,
        ...exportReleasesReducerConfig,
        ...exportReleaseTypesReducerConfig,
        ...exportReleaseEntitiesReducerConfig,
        ...emailExportReleasesReducerConfig,
        ...emailExportReleaseEntitiesReducerConfig,
        ...userSearchResultsReducerConfig,
        ...imagesReducerConfig,
        ...attachmentsReducerConfig,
        ...filesReducerConfig,
        ...miniUsersReducerConfig,
        ...labelPrintersReducerConfig,
        ...chainEventTypesReducerConfig,
        ...chainEventsReducerConfig,
        ...chainOfCustodiesReducerConfig,
        ...retentionPoliciesReducerConfig,
        ...dispositionEventsReducerConfig,
        ...staffRemarksReducerConfig,
        ...facilitiesReducerConfig,
        ...storageLocationsReducerConfig,
        ...elasticStorageLocationsReducerConfig,
        ...evidenceReportsReducerConfig,
        ...userAttributesReducerConfig,
        ...codesReducerConfig,
        ...offensesReducerConfig,
        ...userProfilesReducerConfig,
        ...departmentProfilesReducerConfig,
        ...courtCasesReducerConfig,
        ...printingTemplatesReducerConfig,
        ...reportPrintingTemplatesReducerConfig,
        ...casePrintingTemplatesReducerConfig,
        ...userOptOutsReducerConfig,
        ...eventDetailsReducerConfig,
        ...custodialResponsibleOfficersReducerConfig,
        ...chargesReducerConfig,
        ...reportCaseStatusesReducerConfig,
        ...externalReportStatusesReducerConfig,
        ...offenseCaseStatusesReducerConfig,
        ...reportShortTitlesReducerConfig,
        ...reportStatusHistoriesReducerConfig,
        ...historyEventsReducerConfig,
        ...itemEvidenceStatesReducerConfig,
        ...applicationSettingsReducerConfig,
        ...citationsReducerConfig,
        ...countriesReducerConfig,
        ...casesReducerConfig,
        ...caseTitlesReducerConfig,
        ...caseDefinitionsReducerConfig,
        ...cadUnitsReducerConfig,
        ...cadDesktopConfigsReducerConfig,
        ...cadServiceTypesReducerConfig,
        ...cadRotationListsReducerConfig,
        ...cadServiceProvidersReducerConfig,
        ...cadMessagingGroupsReducerConfig,
        ...cadCallTakerStationsReducerConfig,
        ...departmentPreferencesReducerConfig,
        ...commandLineReducerConfig,
        ...dispatchAreasReducerConfig,
        ...stationsReducerConfig,
        ...duplicateEventSearchConfigsReducerConfig,
        ...preferenceLinksReducerConfig,
        ...shortcutKeysReducerConfig,
        ...callForServicesReducerConfig,
        ...caseNotesReducerConfig,
        ...caseStatusesReducerConfig,
        ...caseAttributesReducerConfig,
        ...caseApprovalStatusesReducerConfig,
        ...caseReviewsReducerConfig,
        ...caseDefaultTasksReducerConfig,
        ...defaultFoldersReducerConfig,
        ...foldersReducerConfig,
        ...folderContentViewsReducerConfig,
        ...reportNotificationsReducerConfig,
        ...societyProfilesReducerConfig,
        ...tasksReducerConfig,
        ...abilitiesReducerConfig,
        ...consortiumProfilesReducerConfig,
        ...modulesReducerConfig,
        ...roleApprovalsReducerConfig,
        ...userAssignmentsReducerConfig,
        ...towVehiclesReducerConfig,
        ...towVehicleReleasesReducerConfig,
        ...towVehicleCheckInsReducerConfig,
        ...impoundsReducerConfig,
        ...cadTicketsReducerConfig,
        ...cadTicketsUnitsAndMembersReducerConfig,
        ...streetsReducerConfig,
        ...streetSegmentsReducerConfig,
        ...streetAliasesReducerConfig,
        ...rulesReducerConfig,
        ...ruleConditionsReducerConfig,
        ...ruleConditionArgsReducerConfig,
        ...cadUnitStatusesReducerConfig,
        ...cadUnitStatusTransitionsReducerConfig,
        ...vehicleMakesReducerConfig,
        ...vehicleModelsReducerConfig,
        ...warrantsReducerConfig,
        ...warrantStatusesReducerConfig,
        ...warrantTitlesReducerConfig,
        ...warrantActivitiesReducerConfig,
        ...warrantChargesReducerConfig,
        ...warrantDexSubmissionReducerConfig,
        ...warrantDexSubmissionHistoriesReducerConfig,
        ...warrantDexReturnsReducerConfig,
        ...warrantActivityStatusLinksReducerConfig,
        ...elasticWarrantsReducerConfig,
        ...lookerDashboardsReducerConfig,
        ...userNotificationsReducerConfig,
        ...idFormatConfigurationReducerConfig,
        ...idGeneratorReducerConfig,
        ...fieldNotesReducerConfig,
        ...fieldNoteEntityLinksReducerConfig,
        ...evidencePrintingTemplatesReducerConfig,
        ...templateFileLinksReducerConfig,
        ...subdivisionsReducerConfig,
        ...primarySubdivisionsReducerConfig,
        ...shapefilesReducerConfig,
        ...inventoriesReducerConfig,
        ...inventoryItemsReducerConfig,
        ...integrationReducerConfig,
        ...commandRecordReducerConfig,
        ...integrationJobRunReducerConfig,
        ...scheduleReducerConfig,
        ...commandStatusHistoryReducerConfig,
        ...attachmentFolderPrintablesReducerConfig,
        ...evidencePrintablesReducerConfig,
        ...nibrsOffenseCodesReducerConfig,
        ...ucrSummaryOffenseCodesReducerConfig,
        ...fieldContactsReducerConfig,
        ...reportLegacyMetadatasReducerConfig,
        ...stopsReducerConfig,
        ...missingPersonsReducerConfig,
        ...useOfForcesReducerConfig,
        ...useOfForceSubjectsReducerConfig,
        ...useOfForceSubjectDeEscalationsReducerConfig,
        ...behavioralCrisesReducerConfig,
        ...csvExportColumnsReducerConfig,
        ...alertSchedulesReducerConfig,
        ...personEmergencyContactsReducerConfig,
        ...passportsReducerConfig,
        ...personGangTrackingsReducerConfig,
        ...personProbationReducerConfig,
        ...entityPermissionsReducerConfig,
        ...dexDevicesReducerConfig,
        ...reportSubmissionAuthorsReducerConfig,
        ...externalLinksReducerConfig,
        ...reportCommentsReducerConfig,
        ...reportInlineCommentsReducerConfig,
        ...taskViewsReducerConfig,
        ...taskCommentsReducerConfig,
        ...taskEntityLinksReducerConfig,
        ...elasticAttributeDetailsReducerConfig,
        ...narrativeGuidesReducerConfig,
        ...fillablePdfTemplatesReducerConfig,
        ...summaryNarrativesReducerConfig,
        ...exportPresetsReducerConfig,
        ...hiddenDepartmentLinkTypesReducerConfig,
        ...trafficCrashesReducerConfig,
        ...trafficCrashAttributesReducerConfig,
        ...trafficCrashCustomFieldsReducerConfig,
        ...trafficCrashCustomAttributesReducerConfig,
        ...trafficCrashEntityDetailsReducerConfig,
        ...trafficCrashPersonsReducerConfig,
        ...trafficCrashPersonOffensesReducerConfig,
        ...trafficCrashRoadwayReducerConfig,
        ...trafficCrashRoadwayEntityLinksReducerConfig,
        ...trafficCrashVehiclesReducerConfig,
        ...reportingEventsReducerConfig,
        ...stopAnonymousSubjectsReducerConfig,
        ...stopEntityAttributesReducerConfig,
        ...itemAttributeReducerConfig,
        ...entityOrderedAttributesReducer,
        ...eventOtherInvolvedAgenciesReducerConfig,
        ...mentionsConfigurationReducerConfig,
        ...complianceResubmissionReducerConfig,
        ...evidenceLocationPermissionsReducerConfig,
        ...profileReportTypesReducerConfig,
        ...offenseCodeNibrsCodeLinksReducerConfig,
        ...offenseCodeHocCategoryLinksReducerConfig,
        ...ukOffenseCodeExtensionsReducerConfig,
        ...courtsReducerConfig,
        ...briefingsReducerConfig,
        ...gpsRetentionReducerConfig,
        ...atfManufacturersReducerConfig,
        ...detentions,
        ...coreModelConfigurations,
        ...offenseSubCrimeLinksReducerConfig,
        ...cautionsReducerConfig,
        ...dragonProtectedEntityOptionDisplayViewModule,
        ...dragonSearchableConfiguredPropertyOptionViewModule,
    },
});

// report definitions use a custom reducer but relies on selectors
// created by `createNormalizedModule`. In order to get this to work
// we have to wrap nexus, intercept certain actions and then
// augment the nexus shape
export default reportDefinitionDataReducerNexusWrapper(nexus) as Reducer<DataNexusState>;
