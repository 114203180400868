import { OperationTypeEnum } from '@mark43/rms-api';
import React from 'react';
import { includes } from 'lodash';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { compose } from 'recompose';
import withFields from '~/client-common/core/fields/components/withFields';
import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import {
    CASE_LOCAL_ID,
    REPORT_REPORTING_EVENT_NUMBER,
} from '~/client-common/core/enums/universal/fields';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import { useCaseFieldName } from '~/client-common/core/fields/hooks/useFields';
import { currentUserHasAbilitySelector } from '../../../core/current-user/state/ui';
import { openDeleteCaseModal, openEditCaseRenModal } from '../state/ui';
import RecordsHeaderHamburgerMenu from '../../../records/core/components/header/RecordsHeaderHamburgerMenu';

const strings = componentStrings.cases.core.CaseHeader;

function CaseHeaderHamburgerMenu({
    permissionSet,
    fieldDisplayNames,
    currentUserHasAbility,
    openDeleteCaseModal,
    openEditCaseRenModal,
}) {
    const { singularCaseFieldName: caseDisplayName } = useCaseFieldName();
    const hasWriteEntityPerm = includes(permissionSet, OperationTypeEnum.WRITE.name);
    const canEditRen =
        currentUserHasAbility(abilitiesEnum.CASES.EDIT_GENERAL) &&
        currentUserHasAbility(abilitiesEnum.REPORTING.EDIT_REPORTING_EVENT_NUMBER);
    return (
        <RecordsHeaderHamburgerMenu>
            <FeatureFlagged
                flag="RMS_INVESTIGATION_ENHANCEMENTS_PHASE_ONE"
                fallback={
                    hasWriteEntityPerm &&
                    canEditRen && (
                        <div className="dropdown-menu-option" onClick={openEditCaseRenModal}>
                            {strings.editCaseRen(fieldDisplayNames.REPORT_REPORTING_EVENT_NUMBER)}
                        </div>
                    )
                }
            />
            {hasWriteEntityPerm && currentUserHasAbility(abilitiesEnum.CASES.DELETE_GENERAL) && (
                <div className="dropdown-menu-option" onClick={openDeleteCaseModal}>
                    {strings.deleteCase(caseDisplayName)}
                </div>
            )}
        </RecordsHeaderHamburgerMenu>
    );
}

const mapStateToProps = createStructuredSelector({
    currentUserHasAbility: currentUserHasAbilitySelector,
});

const mapDispatchToProps = (dispatch) => ({
    openDeleteCaseModal: () => dispatch(openDeleteCaseModal()),
    openEditCaseRenModal: () => dispatch(openEditCaseRenModal()),
});

/**
 * Hamburger menu in the case header for actions that don't belong in the
 *   other menus. This is rendered only if the current user has permission to
 *   take at least one of the actions.
 */
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withFields([CASE_LOCAL_ID, REPORT_REPORTING_EVENT_NUMBER])
)(CaseHeaderHamburgerMenu);
