import * as React from 'react';
import { useSelector } from 'react-redux';
import { Fieldset } from 'markformythree';
import { InputTypeEnum } from '@mark43/rms-api';
import { FieldsetUiOptions, FieldUiOptions } from 'dragon-react';
import { EmptyState } from '@arc/empty-state';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import CheckboxColumns from '../../../../core/forms/components/checkboxes/CheckboxColumns';
import { MFTTextArea } from '../../../../core/forms/components/TextArea';
import { MFTText } from '../../../../core/forms/components/Text';
import { MFTDatePicker } from '../../../../core/forms/components/DatePicker';
import Select, { MFTSelect } from '../../../../core/forms/components/selects/Select';
import AsyncSelect from '../../../../core/forms/components/selects/AsyncSelect';
import Row from '../../../../core/components/Row';

import { DragonConfiguredEntityInstanceControl } from '../../core/selects/dragon-configured-entity-instance-control';
import { DragonUserSelect } from '../../core/selects/dragon-user-select';

// dependencies for checkboxes control
import { SimpleLoading } from '../../../../../legacy-redux/components/core/Loading';
import { InlineBanner } from '../../../../core/components/InlineBanner';
import { useDragonConfigDataContext } from '../../../context/dragon-config-data';
import { DragonOption } from '../../core/selects/use-dragon-select-base';
import { RMSDragonConfigurationExtensions } from '../../../rms-types';
import { getDataAttributes } from '../utils/get-data-attributes';
import { simpleRequiredRuleExistsForField } from '../utils/simple-required-rule-exists-for-field';

import { DragonReportCoreEntitySelect } from '../../core/selects/dragon-report-core-entity-select';
import { RMSDragonFieldsetHelper } from './shared';
import { SignatureFormField } from './signature/components/signature-form-field';
import { CrashDiagramFormField } from './crash-diagram/components/CrashDiagramFormField';

const fieldStyle = { minWidth: 300, maxWidth: 350 };

export const Field = ({
    props,
    configuration,
    fullyQualifiedPath,
}: FieldUiOptions<RMSDragonConfigurationExtensions>): JSX.Element | null => {
    const dataAttrs = getDataAttributes({
        label: configuration.ui.label,
        fullyQualifiedPath,
    });
    const configData = useDragonConfigDataContext();
    const applicationSettings = useSelector(applicationSettingsSelector);
    const isRequired = applicationSettings.RMS_REPORT_WRITING_SHOW_REQUIRED_FIELDS_ENABLED
        ? simpleRequiredRuleExistsForField(configuration.id, configData)
        : false;
    switch (configuration.ui.inputType) {
        case InputTypeEnum.CHECKBOXES.name:
        case InputTypeEnum.BOOLEAN_SELECT.name:
        case InputTypeEnum.MULTISELECT.name:
        case InputTypeEnum.SELECT.name: {
            let children: React.ReactElement;
            const isMultiSelect =
                configuration.ui.inputType === InputTypeEnum.MULTISELECT.name ||
                configuration.ui.inputType === InputTypeEnum.CHECKBOXES.name;
            if (configuration.ui.selectConfiguredEntityTypeId) {
                if (
                    !(
                        configuration.ui.selectConfiguredEntityValuePropertyId &&
                        configuration.ui.selectConfiguredEntityDisplayPropertyId
                    )
                ) {
                    throw new Error('Invalid select ui configuration');
                }

                children = (
                    <DragonConfiguredEntityInstanceControl
                        {...props}
                        // TODO we should really find a better way to accomodate multiple values.
                        // Maybe this should be purely driven by the type of the provided value?
                        isMultiple={isMultiSelect}
                        valuePropertyId={configuration.ui.selectConfiguredEntityValuePropertyId}
                        displayPropertyId={configuration.ui.selectConfiguredEntityDisplayPropertyId}
                        sortPropertyId={configuration.ui.selectConfiguredEntitySortPropertyId}
                        uiConfigurationId={configuration.id}
                    >
                        {({ options, value, onChange, onBlur, isLoading, error }) =>
                            configuration.ui.inputType === InputTypeEnum.CHECKBOXES.name ? (
                                <DragonCheckboxColumns
                                    label={props.label}
                                    options={options}
                                    multiple={isMultiSelect}
                                    value={value}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    loading={isLoading}
                                    error={error}
                                />
                            ) : (
                                <Select
                                    label={props.label}
                                    helpText={props.helpText}
                                    options={options}
                                    multiple={isMultiSelect}
                                    value={value}
                                    // @ts-expect-error Type '(value: number | number[]) => void' is not assignable to type '(value: string | number | boolean | (string | number | boolean)[]) => void'.
                                    // The left type comes from `UseDragonSelectBaseResult`.
                                    // The right type comes from `Select`, and is narrowable using the `Multi` generic type.
                                    // TODO is to update this component (`field`) to accept generic types.
                                    onChange={onChange}
                                    loading={isLoading}
                                    isRequired={isRequired}
                                    error={error}
                                    forceShowError={!!error}
                                    style={fieldStyle}
                                />
                            )
                        }
                    </DragonConfiguredEntityInstanceControl>
                );
            } else {
                children = (
                    <MFTSelect
                        {...props}
                        multiple={isMultiSelect}
                        isRequired={isRequired}
                        style={fieldStyle}
                    />
                );
            }
            return (
                <Row className="field" key={props.key} {...dataAttrs}>
                    {children}
                </Row>
            );
        }
        case InputTypeEnum.USER_SELECT.name: {
            if (!configuration.ui.selectConfiguredEntityTypeId) {
                throw new Error('User select is missing entity configuration');
            } else if (
                !(
                    configuration.ui.selectConfiguredEntityValuePropertyId &&
                    configuration.ui.selectConfiguredEntityDisplayPropertyId
                )
            ) {
                throw new Error('Invalid select ui configuration');
            }
            return (
                <Row className="field" key={props.key} {...dataAttrs}>
                    <DragonUserSelect
                        {...props}
                        valuePropertyId={configuration.ui.selectConfiguredEntityValuePropertyId}
                        displayPropertyId={configuration.ui.selectConfiguredEntityDisplayPropertyId}
                        uiConfigurationId={configuration.id}
                    >
                        {({ options, value, onChange, isLoading, error, setQuery }) => (
                            <AsyncSelect
                                label={props.label}
                                helpText={props.helpText}
                                options={options}
                                multiple={false}
                                value={value}
                                onChange={onChange}
                                loading={isLoading}
                                isRequired={isRequired}
                                error={error}
                                forceShowError={!!error}
                                asyncAction={setQuery}
                                style={fieldStyle}
                            />
                        )}
                    </DragonUserSelect>
                </Row>
            );
        }
        case InputTypeEnum.FIREARM_SELECT.name:
        case InputTypeEnum.ITEM_PROFILE_SELECT.name:
        case InputTypeEnum.VEHICLE_SELECT.name:
        case InputTypeEnum.COURT_SELECT.name: {
            if (!configuration.ui.selectConfiguredEntityTypeId) {
                throw new Error('User select is missing entity configuration');
            } else if (
                !(
                    configuration.ui.selectConfiguredEntityValuePropertyId &&
                    configuration.ui.selectConfiguredEntityDisplayPropertyId
                )
            ) {
                throw new Error('Invalid select ui configuration');
            }

            return (
                <Row className="field" key={props.key} {...dataAttrs}>
                    <DragonReportCoreEntitySelect
                        {...props}
                        entityType={
                            configuration.ui.inputType === InputTypeEnum.FIREARM_SELECT.name
                                ? 'FIREARM'
                                : configuration.ui.inputType ===
                                  InputTypeEnum.ITEM_PROFILE_SELECT.name
                                ? 'ITEM_PROFILE'
                                : configuration.ui.inputType === InputTypeEnum.COURT_SELECT.name
                                ? 'COURT'
                                : 'VEHICLE'
                        }
                        valuePropertyId={configuration.ui.selectConfiguredEntityValuePropertyId}
                        displayPropertyId={configuration.ui.selectConfiguredEntityDisplayPropertyId}
                        uiConfigurationId={configuration.id}
                    >
                        {({ options, value, onChange, isLoading, error }) => (
                            <Select
                                label={props.label}
                                helpText={props.helpText}
                                options={options}
                                multiple={false}
                                value={value}
                                // @ts-expect-error Type '(value: number | number[]) => void' is not assignable to type '(value: string | number | boolean | (string | number | boolean)[]) => void'.
                                // The left type comes from `UseDragonSelectBaseResult`.
                                // The right type comes from `Select`, and is narrowable using the `Multi` generic type.
                                // TODO is to update this component (`field`) to accept generic types.
                                onChange={onChange}
                                loading={isLoading}
                                isRequired={isRequired}
                                error={error}
                                forceShowError={!!error}
                                style={fieldStyle}
                            />
                        )}
                    </DragonReportCoreEntitySelect>
                </Row>
            );
        }
        case InputTypeEnum.DATE.name:
        case InputTypeEnum.DATETIME.name:
            return (
                <Row className="field" key={props.key} {...dataAttrs}>
                    <MFTDatePicker
                        {...props}
                        includeTime={configuration.ui.inputType === InputTypeEnum.DATETIME.name}
                        isRequired={isRequired}
                        style={fieldStyle}
                    />
                </Row>
            );
        case InputTypeEnum.TEXT.name:
            return (
                <Row className="field" key={props.key} {...dataAttrs}>
                    <MFTText {...props} isRequired={isRequired} style={fieldStyle} />
                </Row>
            );
        case InputTypeEnum.TEXTAREA.name:
            return (
                <Row className="field" key={props.key} {...dataAttrs}>
                    <MFTTextArea {...props} isRequired={isRequired} width={300} />
                </Row>
            );
        case InputTypeEnum.INTERNAL_DATA.name:
            // internal data isn't rendered
            return null;
        case InputTypeEnum.SIGNATURE.name:
            return (
                <FeatureFlagged
                    flag="RMS_DIGITAL_SIGNATURES_ENABLED"
                    fallback={
                        <EmptyState
                            title=""
                            headingLevel="h2"
                            subtitle="Signature Capture is currently turned off for your department."
                        />
                    }
                >
                    <Row className="field" key={props.key} {...dataAttrs}>
                        <SignatureFormField
                            path={props.path}
                            identifier={props.key}
                            fullyQualifiedPath={fullyQualifiedPath}
                        />
                    </Row>
                </FeatureFlagged>
            );
        case InputTypeEnum.CRASH_DIAGRAM.name:
            return (
                <FeatureFlagged
                    flag="RMS_CRASH_DIAGRAM_ENABLED"
                    fallback={
                        <EmptyState
                            title=""
                            headingLevel="h2"
                            subtitle="Crash Diagram is currently turned off for your department."
                        />
                    }
                >
                    <Row className="field" key={props.key} {...dataAttrs}>
                        <CrashDiagramFormField
                            path={props.path}
                            identifier={props.key}
                            fullyQualifiedPath={fullyQualifiedPath}
                        />
                    </Row>
                </FeatureFlagged>
            );
        default:
            // @ts-expect-error we get an error here because this case should never occur (all inputTypes are covered above)
            // but let's leave this here just in case our full stack type safety fails
            throw new Error(`Unsupported UI input type "${configuration.ui.inputType}"`);
    }
};

type DragonCheckboxColumnProps = {
    label: string | undefined;
    options: DragonOption[];
    value: number | number[] | undefined;
    onChange: (val: number | number[]) => void;
    onBlur?: (val: number | number[]) => void;
    loading: boolean;
    error: string | undefined;
    multiple: boolean;
};

const DragonCheckboxColumns: React.FC<DragonCheckboxColumnProps> = ({
    label,
    options,
    value,
    onChange,
    onBlur,
    loading,
    error,
    multiple,
}) => {
    const labelElement = label ? (
        <label className="mark43-form-label mark43-form-row-label">{label}</label>
    ) : null;
    // will inline this later after solving type issues during PR review
    const checkboxColumns = (
        // @ts-expect-error: CheckboxColumns is a JS component and TS infers that all properties are required, even the ones which are optional.
        // There are too many props to set them all to undefined as I've done below.
        <CheckboxColumns
            columns={2}
            columnWidth={200}
            options={options}
            multiple={multiple}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            forceShowError={!!error}
            style={fieldStyle}
        />
    );
    return (
        <>
            <div>
                {labelElement}
                {loading && <SimpleLoading />}
                {error && (
                    <Row>
                        <InlineBanner status="error">{error}</InlineBanner>
                    </Row>
                )}
            </div>
            {checkboxColumns}
        </>
    );
};

export const fieldset = ({
    props,
    renderChildren,
    configuration,
    fullyQualifiedPath,
}: FieldsetUiOptions<RMSDragonConfigurationExtensions>): JSX.Element => (
    <RMSDragonFieldsetHelper
        key={props.key}
        label={props.label}
        {...getDataAttributes({
            label: configuration.ui.label,
            fullyQualifiedPath,
        })}
        render={() => <Fieldset {...props}>{renderChildren()}</Fieldset>}
    />
);
