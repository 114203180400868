let resource;

export function buildResource(Resource, req) {
    resource = new Resource({
        name: 'Shapfiles Resource',
        methods: {
            getShapefiles() {
                return req({
                    method: 'GET',
                    url: 'master/locations/shapefiles',
                });
            },
            getShapefileProperties(shapefileId) {
                return req({
                    method: 'GET',
                    url: `master/locations/shapefiles/${shapefileId}/properties`,
                });
            },
            getShapefileFeatureProperties() {
                return req({
                    method: 'GET',
                    url: 'master/locations/shapefiles/properties',
                });
            },
            createShapefile(fileId) {
                return req({
                    method: 'POST',
                    url: `master/locations/shapefiles?file_id=${fileId}`,
                });
            },
            upsertShapefile(shapefile) {
                return req({
                    method: 'PUT',
                    url: `master/locations/shapefiles/${shapefile.id}`,
                    data: shapefile,
                });
            },
            createStreetCenterLineShapefile(fileId) {
                return req({
                    method: 'POST',
                    url: 'master/locations/shapefiles/street_segments',
                    params: {
                        file_id: fileId,
                    },
                });
            },
            createCenterLinesFromShapefile(streetSegmentCreationView) {
                return req({
                    method: 'POST',
                    url: 'master/locations/street_centerline/create_from_shapefile',
                    data: streetSegmentCreationView,
                });
            },
        },
    });
}

const resourceGetter = () => resource;
export default resourceGetter;
